import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AdvertisementHttp } from '../../../http/advertisement'
import { Advertise } from '../../../types/types'
import { indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconServicesAllPoint } from '../../svg/IconServicesAll'
import { DarkBg } from '../../ui/DarkBg'
import { ActiveModal } from './ActiveModal'

export const AdvertisementMainUser = () => {
  const [ads, setAds] = useState<Advertise[]>([])
  const navigate = useNavigate()
  const { role, _id, avatarFileName, fullName } = useAppSelector((state) => state.user)
  const [isOpen, setIsOpen] = useState(false)
  const [currentItem, setCurrentItem] = useState<Advertise>()
  useEffect(() => {
    const effect = async () => {
      const res = await AdvertisementHttp.getAdvertisementAll({ ownerId: _id })
      setAds(res)
    }

    effect()
  }, [])
  const calculateTime = (item: Advertise) => {
    let isValid = false
    if (item.duration.length) {
      const date = new Date()
      console.log(moment(item.duration[1]).date() >= moment(date).date())
      if (
        moment(item.duration[1]).year() >= moment(date).year() &&
        moment(item.duration[1]).month() >= moment(date).month() &&
        moment(item.duration[1]).day() >= moment(date).day()
      ) {
        isValid = true
      }
    }
    return isValid
  }
  const toAdvertiseActive = (_id: string) => {
    navigate(`${indexPathByRole(role)}/advertisement/active?adId=${_id}`, {
      state: { adId: _id },
    })
  }
  const toAdvertisePast = (_id: string) => {
    navigate(`${indexPathByRole(role)}/advertisement/past?adId=${_id}`, {
      state: { adId: _id },
    })
  }
  return (
    <>
      {ads ? (
        <div className="ads__container">
          <h4>Campaigns</h4>
          {ads.map((item, index) => (
            <div
              className="ads__container-item"
              onClick={() => {
                if (calculateTime(item)) {
                  toAdvertiseActive(item._id)
                } else {
                  toAdvertisePast(item._id)
                }
              }}
            >
              <div className="ads__container-item-menu">
                <h5>{item.title}</h5>
                <button
                  onClick={(e) => {
                    e.stopPropagation()
                    setIsOpen(true)
                    setCurrentItem(item)
                  }}
                >
                  <IconServicesAllPoint />
                </button>
              </div>
              {calculateTime(item) && (
                <>
                  <div className="ads__container-item-costs">
                    <div className="ads__container-item-costs-text">
                      <h6>Total Expenses</h6>
                      <h5>{item.budget}$</h5>
                    </div>
                    <div className="ads__container-item-costs-text">
                      <h6>CPC</h6>
                      <h5>0.17$</h5>
                    </div>
                    <div className="ads__container-item-costs-text">
                      <h6>Expenses</h6>
                      <h5>0.34$</h5>
                    </div>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      ) : (
        ''
      )}
      {isOpen && currentItem && (
        <>
          <ActiveModal
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            item={currentItem}
            active={calculateTime(currentItem)}
            ads={ads}
            setAds={setAds}
          />
          <DarkBg />
        </>
      )}
    </>
  )
}
