import { PieChart } from '@mui/x-charts'

export const AdvertisementPine = ({ men, women }: { men: number; women: number }) => {
  const data = [
    { label: `men \n  ${men}`, value: men },
    { label: `women \n ${women}`, value: women },
  ]
  return (
    <>
      <div className="advertisement__pine">
        <PieChart
          height={100}
          series={[
            {
              data: data,
              innerRadius: 25,
            },
          ]}
          skipAnimation={true}
        />
      </div>
    </>
  )
}
