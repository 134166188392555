import { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import $api from '../../../http'
import { ActivitiesHttp } from '../../../http/activities-http'
import { PublishPostHttp } from '../../../http/publish-post-http'
import { ServiceHttp } from '../../../http/service-http'
import {
  PostUserInterface,
  PublishEventItemInterface,
  PublishServiceItemInterface,
  SearchType,
} from '../../../types/types'
import { indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconAdminClose } from '../../svg/IconAdminHeader'
import { IconLeftChevrons } from '../../svg/IconChevrons'
import { IconInputSearch } from '../../svg/IconInputSearch'
import { UserHeader } from '../header/UserHeader'

export interface SavedFiltersType {
  ownerId: string
  userId: string
  when: string
  where: string
  what: string
  seen: boolean
  category: string
  rating: number
  far: number
  gender: string
  orientation: string
  nationality: string
  age: number[]
  profession: string
  education: string
  skills: string
  interests: string
}

export const ExploreFilters = () => {
  const navigate = useNavigate()
  const [saved, setSaved] = useState<SearchType[]>([])
  const [savedNormal, setSavedNormal] = useState<SearchType[]>([])
  const { _id } = useAppSelector((state) => state.user)
  useEffect(() => {
    const body = async () => {
      const res: AxiosResponse<SearchType[]> = await $api.post('search/get', {
        userId: _id,
      })
      setSaved(res.data)
    }
    body()
  }, [])
  useEffect(() => {
    if (saved) {
      const uniqSet = new Set(saved)
      console.log(uniqSet)
      setSavedNormal([...uniqSet])
    }
  }, [saved])

  const removeSearch = (item: SearchType) => {
    let arr: SearchType[] = [...saved]
    let index = arr.indexOf(item)
    if (index !== -1) {
      arr.splice(index, 1)
      setSaved(arr)
    }
  }
  const { role } = useAppSelector((state) => state.user)
  const toSearch = (item: SearchType) => {
    if (item.eventId) {
      navigate(`${indexPathByRole(role)}/search/find`, {
        state: { category: 'events', search: item.eventId.title.replaceAll(' ', '') },
      })
    }
    if (item.postId) {
      navigate(`${indexPathByRole(role)}/search/find`, {
        state: { category: 'posts', search: item.postId.title.replaceAll(' ', '') },
      })
    }
    if (item.serviceId) {
      navigate(`${indexPathByRole(role)}/search/find`, {
        state: { category: 'services', search: item.serviceId.title.replaceAll(' ', '') },
      })
    }
  }
  const [services, setServices] = useState<PublishServiceItemInterface[]>([])
  const [events, setEvents] = useState<PublishEventItemInterface[]>([])
  const [posts, setPosts] = useState<PostUserInterface[]>([])
  useEffect(() => {
    // services
    ServiceHttp.getTenPublishService().then((res) => setServices(res))
    // events
    ActivitiesHttp.getTenPublishActivities({ userId: _id }).then((res) => setEvents(res))
    // posts
    PublishPostHttp.getTenPosts().then((res) => setPosts(res))
  }, [])

  return (
    <>
      <UserHeader>
        <div className="user__header-messages explore__header">
          <button
            onClick={() => navigate(-1)}
            className="user__header-chatuser user__header-messages-exit"
          >
            <IconLeftChevrons />
            <h5 className="user__header-title">Saved search</h5>
          </button>
        </div>
      </UserHeader>
      <>
        <div className="saved__search">
          <h5>Saved Search</h5>
          <div className="saved__search-list">
            {savedNormal.map((item) => (
              <>
                <div
                  style={{ margin: '20px 0' }}
                  className="saved__search-list-item"
                  onClick={() => toSearch(item)}
                >
                  <div>
                    <div className="saved__search-list-item-text">
                      <IconInputSearch />
                      {item.eventId && <h6>"{item.eventId.title}"</h6>}
                      {item.postId && <h6>"{item.postId.title}"</h6>}
                      {item.serviceId && <h6>"{item.serviceId.title}"</h6>}
                    </div>
                    {item.eventId && (
                      <>
                        {!events.filter((it) =>
                          it.title
                            .toLowerCase()
                            .includes(item.eventId.title.toLowerCase()),
                        ).length ? (
                          <div className="explore__region-text-updates-no">
                            no new results
                          </div>
                        ) : (
                          <div className="explore__region-text-updates">
                            {
                              events.filter((it) =>
                                it.title
                                  .toLowerCase()
                                  .includes(item.eventId.title.toLowerCase()),
                              ).length
                            }{' '}
                            new results
                          </div>
                        )}
                      </>
                    )}
                    {item.serviceId && (
                      <>
                        {!services.filter((it) =>
                          it.title
                            .toLowerCase()
                            .includes(item.serviceId.title.toLowerCase()),
                        ).length ? (
                          <div className="explore__region-text-updates-no">
                            no new results
                          </div>
                        ) : (
                          <div className="explore__region-text-updates">
                            {
                              services.filter((it) =>
                                it.title
                                  .toLowerCase()
                                  .includes(item.serviceId.title.toLowerCase()),
                              ).length
                            }{' '}
                            new results
                          </div>
                        )}
                      </>
                    )}
                    {item.postId && (
                      <>
                        {!posts.filter((it) =>
                          it.title
                            .toLowerCase()
                            .includes(item.postId.title.toLowerCase()),
                        ).length ? (
                          <div className="explore__region-text-updates-no">
                            no new results
                          </div>
                        ) : (
                          <div className="explore__region-text-updates">
                            {
                              posts.filter((it) =>
                                it.title
                                  .toLowerCase()
                                  .includes(item.postId.title.toLowerCase()),
                              ).length
                            }{' '}
                            new results
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <button
                    onClick={(e) => {
                      e.stopPropagation()
                      removeSearch(item)
                    }}
                  >
                    <IconAdminClose />
                  </button>
                </div>
              </>
            ))}
          </div>
        </div>
      </>
    </>
  )
}
