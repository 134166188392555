import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { AdvertisementActive } from '../../admin-components/advertisement/AdvertisementActive'
import { AdvertisementAdd } from '../../admin-components/advertisement/AdvertisementAdd'
import { AdvertisementAddCommunity } from '../../admin-components/advertisement/AdvertisementAddCommunity'
import { AdvertisementAddText } from '../../admin-components/advertisement/AdvertisementAddText'
import { AdvertisementBudget } from '../../admin-components/advertisement/AdvertisementBudget'
import { AdvertisementFinish } from '../../admin-components/advertisement/AdvertisementFinish'
import { AdvertisementGender } from '../../admin-components/advertisement/AdvertisementGender'
import { AdvertisementInterests } from '../../admin-components/advertisement/AdvertisementInterests'
import { AdvertisementItem } from '../../admin-components/advertisement/AdvertisementItem'
import { AdvertisementMainUser } from '../../admin-components/advertisement/AdvertisementMainUser'
import { AdvertisementMap } from '../../admin-components/advertisement/AdvertisementMap'
import { AdvertisementPreview } from '../../admin-components/advertisement/AdvertisementPreview'
import { IconLeftChevrons } from '../../svg/IconChevrons'
import { IconTicket } from '../../svg/IconTicket'
import { IconsNewsfeedPlus } from '../../svg/IconsNewsfeed'
import { UserHeader } from '../../user-components/header/UserHeader'
import { PaymentGateway } from '../../admin-components/advertisement/AdvertisementAddPaymentMethod'
import { AdvertisementRequestMoney } from '../../admin-components/advertisement/AdvertisementRequestMoney'
import { AdvertisementTransactions } from '../../admin-components/advertisement/AdvertisementTransactions'
import { AdvertisementPayment } from '../../admin-components/advertisement/AdvertisementPayment'
// import '../../../style/admin/index.scss';

export const Promotion = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { role } = useAppSelector((state) => state.user)
  return (
    <>
      {location.pathname === '/user/advertisement/ad' ? (
        ''
      ) : (
        <UserHeader>
          <div className="advertisement__header">
            <div className="profileinfo__header">
              <button
                className="profileinfo__header-chevron"
                onClick={() => {
                  navigate(-1)
                }}
              >
                <IconLeftChevrons />
              </button>
              <h5 className="profileinfo__header-text">
                {location.pathname === '/user/advertisement/request-money'
                  ? 'Request Money'
                  : location.pathname === '/user/advertisement/transactions'
                    ? 'Last Transactions'
                    : 'Advertisement'}
              </h5>
            </div>
            <div className="advertisement__header-content-btns">
              <button
                className="user__header-main-button"
                onClick={() =>
                  navigate(`${indexPathByRole(role)}/advertisement/request-money`)
                }
              >
                <IconTicket />
              </button>
              <button
                className="user__header-main-button"
                onClick={() => navigate(`${indexPathByRole(role)}/advertisement/add`)}
              >
                <IconsNewsfeedPlus />
              </button>
            </div>
          </div>
        </UserHeader>
      )}
      <div className="user">
        <Routes>
          <Route path="add" element={<AdvertisementAdd />} />
          <Route path="add-text-data" element={<AdvertisementAddText />} />
          <Route path="budget" element={<AdvertisementBudget />} />
          <Route path="finish" element={<AdvertisementFinish />} />
          <Route path="finish/preview" element={<AdvertisementPreview />} />
          <Route path="add-community-selection" element={<AdvertisementAddCommunity />} />
          <Route path="add-community-selection/map" element={<AdvertisementMap />} />
          <Route
            path="add-community-selection/interests"
            element={<AdvertisementInterests />}
          />
          <Route
            path="add-community-selection/age-gender"
            element={<AdvertisementGender />}
          />
          <Route path="ad" element={<AdvertisementItem />} />
          <Route path="request-money" element={<AdvertisementRequestMoney />} />
          <Route path="transactions" element={<AdvertisementTransactions />} />
          <Route path="active/*" element={<AdvertisementActive />} />
          <Route path="past/*" element={<AdvertisementActive past={true} />} />
          <Route path="payment-method" element={<PaymentGateway />} />
          <Route path="advertisement-payment" element={<AdvertisementPayment />} />
          <Route index element={<AdvertisementMainUser />} />
        </Routes>
      </div>
    </>
  )
}
