import { useRef, useState } from 'react'
import { IconVoice } from '../../svg/IconVoice'
import { IconVoicePause } from '../../svg/IconVoicePause'
import './../../../style/general/voiceMessage.scss'

export const VoiceMessage = (props: { Audio: string; Side: string }) => {
  const [Duration, setDuration] = useState<number>(0)
  const [Moment, setMoment] = useState<number>(0)
  const [IsPlaying, setIsPlaying] = useState<boolean>(false)
  const [CurrentTime, setCurrentTime] = useState<number>(0)
  const [Percentage, setPercentage] = useState<number>(0)
  const audioRef = useRef<HTMLAudioElement>(null!)

  const play = () => {
    const audio = audioRef.current
    audio.volume = 0.1

    if (!IsPlaying) {
      setIsPlaying(true)
      audio.play()
    }
    if (IsPlaying) {
      setIsPlaying(false)
      audio.pause()
    }
  }
  return (
    <div className="voice__message__wrapper">
      <button
        className={`voice__message__listen__button ${props.Side}`}
        onClick={(e) => {
          e.stopPropagation()
          play()
        }}
      >
        {!IsPlaying ? (
          <IconVoice className="voice__icon" />
        ) : (
          <IconVoicePause className="voice__icon" />
        )}
      </button>
      <div className={`voice__message__duration__bar__wrapper ${props.Side}`}>
        <input
          value={Percentage}
          type="range"
          step={0.01}
          className="voice__message__input__bar"
          onChange={(e) => {
            const audio = audioRef.current
            audio.currentTime = (audio.duration / 100) * Number(e.target.value)
            setMoment(Number(e.target.value))
          }}
        />
        <div className="voice__message__time__container">
          <span style={{ margin: '0 31px' }}>
            {`${Math.round(CurrentTime / 60)}:${Math.round(CurrentTime % 60) < 10 ? `0${Math.round(CurrentTime % 60)}` : `${Math.round(CurrentTime % 60)}`}`}
          </span>
          {/* <span style={{margin: "0 6px"}}>
                    {`${Math.round(Duration / 60)}:${Math.round(Duration % 60) < 10 ?`0${Math.round(Duration%60)}` : `${Math.round(Duration%60)}`}`}
                    </span> */}
        </div>
        <audio
          src={props.Audio}
          ref={audioRef}
          onLoadedData={(e) => {
            setDuration(Number(e.currentTarget.duration.toFixed(2)))
          }}
          onTimeUpdate={(e) => {
            const p =
              (Number(e.currentTarget.currentTime) / Number(e.currentTarget.duration)) *
              100
            const time = Number(e.currentTarget.currentTime)

            if (Number(e.currentTarget.currentTime) == Number(e.currentTarget.duration)) {
              setIsPlaying(false)
            }

            setPercentage(+p)
            setCurrentTime(time)
          }}
        ></audio>
      </div>
    </div>
  )
}
