import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import $api from '../../../http'
import { UserHttp } from '../../../http/user-http'
import { ChatType, ParticipantType, FriendTypeResponse } from '../../../types/types'
import { apiBaseURL } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconProfileCircleFilled, IconProfileCircle } from '../../svg/IconProfile'
import { InputSearch } from '../../ui/InputSearch'
import { success } from '../../ui/LoadSuccess'
import { Modal } from '../../ui/Modal'

export const AddNeibsModal = ({
  setIsOpen,
  isOpen,
  chat,
  setChat,
}: {
  setIsOpen: (s: boolean) => void
  isOpen: boolean
  chat: ChatType
  setChat: (s: ChatType) => void
}) => {
  const [search, setSearch] = useState('')
  const [ownerId, setOwnerId] = useState<ParticipantType>()
  const { _id, role } = useAppSelector((state) => state.user)
  const [friends, setFriends] = useState<FriendTypeResponse[]>([])
  const navigate = useNavigate()
  const validate = !Boolean(ownerId)
  useEffect(() => {
    UserHttp.getMyFriends({ _id }).then((res) => setFriends(res))
  }, [])

  const addNeib = async () => {
    if (chat.participants && ownerId) {
      await $api.post('messenger/leave-group', {
        chatId: chat?.chatId,
        ownerId: chat?.groupOwner,
        participants: [
          ...chat.participants.map((it) => {
            return { userId: it.userId._id }
          }),
          { userId: ownerId.userId._id },
          { userId: _id },
        ],
      })
      setChat({ ...chat, participants: [...chat.participants, ownerId] })

      success()
      setIsOpen(false)
    }
  }
  const [exeption, setExeption] = useState<string[]>([])
  useEffect(() => {
    let array: string[] = []
    chat.participants.map((it) => array.push(it.userId._id))
    setExeption(array)
  }, [friends])

  return (
    <div
      className={`activities__favor-modal  ${
        isOpen ? 'activities__favor-modal--open' : ''
      }`}
    >
      <Modal className="" setIsOpen={(s: boolean) => setIsOpen(s)}>
        <>
          <div className="publish__modaladd post__modal">
            <h5
              className="post__modal_title"
              style={{
                fontWeight: '600',
                color: 'rgba(21, 16, 77, 1)',
                fontSize: '24px',
              }}
            >
              Add neibs
            </h5>
            <h6 style={{ color: 'rgba(125, 143, 179, 1)', fontSize: '16px' }}>
              Select a new group member
            </h6>
            <InputSearch
              value={search}
              changeValue={setSearch}
              placeholder={
                <>
                  Search <b>Neib</b>
                </>
              }
            />
            <div style={{ maxHeight: '200px', overflowY: 'scroll' }}>
              {friends
                .filter((it) => !exeption.includes(it?.friendId?._id))
                .filter((it) =>
                  it.friendId.fullName
                    .toLowerCase()
                    .includes(search.replaceAll(' ', '').toLowerCase()),
                )
                .map((item) => (
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                    onClick={() =>
                      setOwnerId({
                        userId: {
                          _id: item?.friendId?._id,
                          fullName: item?.friendId?.fullName,
                          avatarFileName: item?.friendId?.avatarFileName,
                        },
                      })
                    }
                  >
                    <div className="group__info-user">
                      {item?.friendId?.avatarFileName ? (
                        <img
                          style={{ width: '60px', height: '60px', objectFit: 'cover' }}
                          src={`${apiBaseURL}/uploads/avatar/${item?.friendId?.avatarFileName}`}
                        />
                      ) : (
                        <div
                          style={{
                            backgroundColor: 'rgba(41, 52, 65, 1)',
                            borderRadius: '100%',
                            width: '60px',
                            height: '60px',
                            color: '#fff',
                            textAlign: 'center',
                            position: 'relative',
                          }}
                        >
                          <span
                            style={{ position: 'absolute', left: '20px', top: '18px' }}
                          >
                            {item?.friendId?.fullName.slice(0, 2)}
                          </span>
                        </div>
                      )}
                      <div className="group__info-user-data">
                        <h5>{item?.friendId?.fullName}</h5>
                      </div>
                    </div>
                    <div style={{ margin: 'auto 0' }}>
                      {ownerId?.userId._id === item?.friendId?._id ? (
                        <IconProfileCircleFilled />
                      ) : (
                        <IconProfileCircle />
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <button
            disabled={validate}
            style={{ margin: '5%' }}
            className={` explore__modal-button ${validate && 'explore__modal-button-disabled'}`}
            onClick={addNeib}
          >
            Add
          </button>
        </>
      </Modal>
    </div>
  )
}
