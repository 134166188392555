import { useState } from 'react'
import { IconBottomChevrons, IconRightChevrons } from '../../svg/IconChevrons'

export const Acerdeon = () => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <div className="profileinfo__aboutnb-item" onClick={() => setIsOpen(!isOpen)}>
        <p>What is NeighborHarbor</p>
        {isOpen ? <IconBottomChevrons /> : <IconRightChevrons />}
      </div>
      {isOpen && (
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          <b />
          Vero impedit nesciunt aut architecto optio molestias numquam
          <b />
          voluptatum quia earum, dolorum excepturi tempora explicabo!
          <b />
          Eligendi nesciunt veniam voluptates, excepturi pariatur eum.
        </p>
      )}
    </>
  )
}
