import { ReactNode, useState } from 'react'
import { ParticipantType } from '../../types/types'
import { IconMark } from '../svg/IconMark'

export const UserElement = (props: {
  removeParticipant: (obj: ParticipantType[]) => void
  participants: ParticipantType[]
  item: ParticipantType[]
  updateParticipians: (obj: ParticipantType[]) => void
  children: ReactNode
}) => {
  const [userSelected, setuserSelected] = useState<boolean>(false)
  return (
    <div
      className="el"
      style={{ justifyContent: 'flex-start' }}
      onClick={(e) => {
        if (!userSelected) {
          setuserSelected(true)
          props.updateParticipians(props.item)
        } else {
          setuserSelected(false)
          props.removeParticipant(props.item)
        }
      }}
    >
      {props.item.length == 2 && userSelected && (
        <div className="user__selected">
          <IconMark />
        </div>
      )}
      {props.children}
    </div>
  )
}
