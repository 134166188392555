import { useNavigate } from 'react-router-dom'
import { EDUCATION, ORIENTATION, SEX } from '../../../../types/enum'
import { useAppDispatch, useAppSelector } from '../../../../utils/hooks'
import { AutoComplete, Button, Flex, Form, Radio, Space } from 'antd'
import {
  EditUserProfileDTO,
  PROFILE_STEP,
} from '../../../../types/server-communication/user'
import { stepDefinitions } from '../../../../utils/hooks/useNextSetUpProfilePath'
import { attemptUpdateEducation } from '../../../../redux/userNew'
import { indexPathByRole } from '../../../route/indexPathByRole'
import { MdOutlineArrowForwardIos } from 'react-icons/md'
import { useDev, useAttemptListener } from 'dev-masters-react-kit'
import FormSectionDivider from '../../../ui/new-components/FormSectionDivider'
import { StandaloneScreenLayout } from '../../../ui/Layout/StandaloneScreenLayout'
import { store } from '../../../../store'
import { useTranslation } from 'react-i18next'

interface FormValues extends Pick<EditUserProfileDTO, 'education' | 'studySchool'> {}
export const ProfileEducation = () => {
  const [educationForm] = Form.useForm<FormValues>()
  const { education, studySchool, role } = useAppSelector((state) => state.userNew)
  const currentEducation = Form.useWatch('education', educationForm)
  const currentStudySchool = Form.useWatch('studySchool', educationForm)
  const { t } = useTranslation('profile')

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const isLoading = useAttemptListener({
    store,
    attempt: attemptUpdateEducation,
    onFulfilled: () => {
      navigate('/profile/' + stepDefinitions[PROFILE_STEP.FAMILY_STATUS].path)
    },
  })
  function handleSubmitForm(values: FormValues) {
    dispatch(attemptUpdateEducation(values))
  }

  const { options: educationOptions, filterOption } = useDev().useFakeAutocomplete([
    'Massachusetts Institute of Technology (MIT)',
    'Stanford University',
    'Harvard University',
  ])

  return (
    <StandaloneScreenLayout
      title="Education"
      subTitle="By showcasing your educational journey, you provide others with valuable insights"
      onPrimaryButtonClicked={educationForm.submit}
      isDisabled={isLoading || !currentEducation || !currentStudySchool}
    >
      <Form
        form={educationForm}
        name="basic"
        layout="vertical"
        initialValues={{ education: education, studySchool: studySchool }}
        onFinish={handleSubmitForm}
      >
        <Form.Item name="education">
          <Radio.Group buttonStyle="solid" optionType="button">
            <Space wrap>
              {Object.entries(EDUCATION).map(([key, value]) => (
                <Radio key={key} value={key}>
                  {t(value)}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
        <FormSectionDivider title="Where did you study?" />

        <Form.Item name="studySchool">
          <AutoComplete
            placeholder={'Educational institution'}
            options={educationOptions}
            filterOption={filterOption}
            suffixIcon={<MdOutlineArrowForwardIos />}
            allowClear
          />
        </Form.Item>
      </Form>
    </StandaloneScreenLayout>
  )
}
