import moment from 'moment'
import { ReactNode, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ServiceHttp } from '../../../../http/service-http'
import { UserHttp } from '../../../../http/user-http'
import { Review } from '../../../../types/types'
import { apiBaseURL, indexPathByRole } from '../../../route/indexPathByRole'
import { useAppSelector } from '../../../../utils/hooks'
import { IconAdsManager } from '../../../svg/IconAdsManager'
import { IconCalendar } from '../../../svg/IconCalendar'
import { IconEducation } from '../../../svg/IconEducation'
import { IconFamily } from '../../../svg/IconFamily'
import { IconStars } from '../../../svg/IconFavor'
import { IconFlag } from '../../../svg/IconFlag'
import { IconNeibs } from '../../../svg/IconPassEye'
import { IconTime } from '../../../svg/IconTime'
import { IconUser } from '../../../svg/IconUser'
import { UserItem } from '../ProfileInfo'
import $api from '../../../../http'
import { success } from '../../../ui/LoadSuccess'
import { Avatar, notification } from 'antd'
import { ROLE } from '../../../../types/enum'
import { Tag } from 'antd'
import { getInitials } from '../../../../utils/displayName'
import { useTranslation } from 'react-i18next'
import nationalities from 'i18n-nationality'
import FormSectionDivider from '../../../ui/new-components/FormSectionDivider'
import { underDevelopmentNotification } from '../../../../utils/dev'

export const GeneralInfo = ({ props }: { props?: UserItem }) => {
  const {
    fullName,
    avatarFileName,
    familyStatus,
    dateBirth,
    professions,
    education,
    studySchool,
    nationality,
    cityBirth,
    sex,
    rate,
    reviews,
    orientation,
  } = useAppSelector((state) => state.userNew)

  const { createdUserDate } = useAppSelector((state) => state.profile)

  const { t, i18n } = useTranslation('professionsList')

  return (
    <div className="profileinfo__avatar">
      <div className="profileinfo__avatar-block">
        <Avatar
          size={150}
          icon={fullName && getInitials(fullName)}
          src={`${process.env.REACT_APP_UPLOADED_BASE_URL}/avatar/${avatarFileName}`}
        />
        {rate && (
          <div
            className="profileinfo__avatar-block-mark"
            onClick={underDevelopmentNotification}
          >
            <IconStars />
            <b>{rate}</b>
            <span>{`(${reviews?.length})`}</span>
          </div>
        )}
      </div>
      <h5 className="profileinfo__avatar-title">{fullName}</h5>
      <h6 className="profileinfo__avatar-subtitle">
        {professions?.map((profession) => <span>{t(profession)}; </span>)}
      </h6>
      <ProfileActions viewingProfileId="" />
      <FormSectionDivider title={'Information'} />
      <div className="profileinfo__avatar-item">
        <IconCalendar />
        <span style={{ margin: '0 -8px' }}>
          Date of Birth: &nbsp;
          <EmptyInfoHolder
            text={dateBirth ? moment(dateBirth).format('DD/MM/YYYY') : undefined}
            placeholder="dd/mm/yyyy"
          />
        </span>
      </div>
      <div className="profileinfo__avatar-item">
        <IconEducation />
        <span>
          <EmptyInfoHolder text={education} placeholder="graduated" />
          &nbsp; in &nbsp;
          <EmptyInfoHolder text={studySchool} placeholder="institution" />
        </span>
      </div>
      <div className="profileinfo__avatar-item">
        <IconFlag />
        <span>
          <EmptyInfoHolder
            text={nationality && nationalities.getName(nationality, i18n.language)}
            placeholder="nationality"
          />
          <br />
          born in &nbsp;
          <EmptyInfoHolder text={cityBirth} placeholder="city" />
        </span>
      </div>
      <div className="profileinfo__avatar-item">
        <IconUser />
        <span style={{ margin: '0 -8px' }}>
          I am: &nbsp; <EmptyInfoHolder text={sex} placeholder="sex" />, my orientation:
          <br />
          <EmptyInfoHolder text={orientation} placeholder="orientation" />
        </span>
      </div>
      <div className="profileinfo__avatar-item">
        <IconFamily />
        <span>
          Family status: &nbsp;{' '}
          <EmptyInfoHolder text={familyStatus} placeholder="marital status" />
        </span>
      </div>
      <div className="profileinfo__avatar-item">
        <IconTime />
        <span>
          On NeighborHarbor:
          <EmptyInfoHolder
            text={moment(createdUserDate).format('MMM D, YYYY')}
            placeholder=""
          />
        </span>
      </div>
    </div>
  )
}

//TODO: completely refactor this component
function ProfileActions({ viewingProfileId }: { viewingProfileId: string }) {
  const {
    id: myId,
    role,
    fullName,
    avatarFileName,
  } = useAppSelector((state) => state.userNew)

  const {
    coordinates: { lat, lng },
  } = useAppSelector((state) => state.profile)

  const navigate = useNavigate()

  const isMyProfile = true // FIXME myId === viewingProfileId
  useEffect(() => {
    if (isMyProfile || !myId) return
    UserHttp.checkMyFriend({
      _id: myId,
      friendId: viewingProfileId,
    }).then((res) => setIsMyFriend(res))
  }, [])

  const [isMyFriend, setIsMyFriend] = useState(false)

  const toFriend = async () => {
    if (isMyProfile || !myId) return

    const payload = {
      _id: myId,
      friendId: viewingProfileId,
    }

    if (isMyFriend) {
      await UserHttp.deleteMyFriend(payload).then(() => setIsMyFriend(false))
    } else {
      await UserHttp.addMyFriend(payload).then(() => setIsMyFriend(true))
    }
  }

  const writeMessage = async () => {
    if (isMyProfile) return

    const chat = await $api.post('messenger/get-chat', {
      participants: [
        {
          userId: myId,
        },
        {
          userId: viewingProfileId,
        },
      ],
    })
    if (chat && chat?.data?.participants?.length < 3) {
      openChat()
    } else {
      addRequest()
    }
  }

  const openChatAdmin = async (roleSuppors: ROLE) => {
    const res = await UserHttp.getClosestUser({
      role: roleSuppors,
      myLat: lat,
      myLng: lng,
    })

    const openChatUser = async (adminId: string) => {
      await $api
        .post('messenger/open-chat', {
          isSupport: true,
          participants: [
            {
              userId: adminId,
            },
            {
              userId: myId,
            },
          ],
        })
        .then((res) => {
          if (res.data) {
            role &&
              navigate(`${indexPathByRole(role)}/messeges/chat`, {
                state: {
                  chat: res.data,
                },
              })
          }
        })
    }

    openChatUser(res.userId)
  }

  const openChat = async () => {
    await $api
      .post('messenger/open-chat', {
        participants: [
          {
            userId: myId,
          },
          {
            userId: viewingProfileId,
          },
        ],
      })
      .then((res) => {
        console.log(res)
        if (res.data) {
          role &&
            navigate(`${indexPathByRole(role)}/messeges/chat`, {
              state: {
                chat: res.data,
              },
            })
        }
      })
  }

  const addRequest = async () => {
    await $api.post('messenger/add-request', {
      myId: viewingProfileId,
      senderId: myId,
    })
    success()
    // navigate(-1)
  }

  if (isMyProfile)
    return (
      <div className="profileinfo__avatar-icons">
        <div
          className="profileinfo__avatar-icons-item"
          onClick={() => role && navigate(`${indexPathByRole(role)}/advertisement`)}
        >
          <IconAdsManager /> Ads Manager
        </div>
        <div
          className="profileinfo__avatar-icons-item"
          onClick={underDevelopmentNotification}
          // onClick={() => openChatAdmin(ROLE.LOCAL_ADMIN)}
        >
          <img src="/Images/help.png" alt="" />
          Help Center
        </div>
      </div>
    )
  else
    return (
      <div className="profileinfo__avatar-buttons">
        <button className="profileinfo__avatar-buttons-button" onClick={toFriend}>
          <IconNeibs />
          {isMyFriend ? 'Neib' : 'Follow'}
        </button>
        <button
          className="profileinfo__avatar-buttons-button profileinfo__avatar-buttons-button--inheritbody"
          onClick={writeMessage}
        >
          Message
        </button>
      </div>
    )
}

interface EmptyInfoHolderProps {
  text?: string | null
  placeholder: string
}
function EmptyInfoHolder({ text, placeholder }: EmptyInfoHolderProps) {
  const { t } = useTranslation('profile')
  return text ? <b>{t(text)}</b> : <Tag style={{ display: 'inline' }}>{placeholder}</Tag>
}
