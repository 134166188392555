import { useThrottle } from '@uidotdev/usehooks'
import axios from 'axios'
import L, { LatLng, LatLngExpression } from 'leaflet'
import { useEffect, useRef, useState } from 'react'
import { Circle, MapContainer, TileLayer } from 'react-leaflet'
import { useLocation } from 'react-router-dom'
import { IconLocationAim } from '../../svg/IconsLocation'
import { NominatimSearchResponse } from '../../../redux/statelessRequests/address'
import { Button, Spin } from 'antd'
import { IoMdArrowBack } from 'react-icons/io'
import { MdMyLocation } from 'react-icons/md'
import { GEOLOCATION_STATUS } from '../../../utils/GeolocationStatus'

const $api = axios.create({})

interface Props {
  onBack: () => void
  onConfirm: (address: NominatimSearchResponse) => void
}

const SetAddressGeolocation = ({ onBack, onConfirm }: Props) => {
  const [geolocationStatus, setGeolocationStatus] = useState<GEOLOCATION_STATUS>()
  const [currentAddress, setCurrentAddress] = useState<
    NominatimSearchResponse | undefined
  >()
  const [nothingFound, setNothingFound] = useState<boolean>(false)
  const mapRef = useRef<L.Map | null>(null)
  const [mapCenter, setMapCenter] = useState<LatLngExpression>()
  const [mapMovingCenter, setMapMovingCenter] = useState<LatLngExpression>()
  const drawingMapMovingCenter = useThrottle(mapMovingCenter, 50)
  const lookupMapMovingCenter = useThrottle(mapMovingCenter, 500)
  const [mapReady, setMapReady] = useState(false)

  const updateAddress = async ({ lat, lon }: { lat: string; lon: string }) => {
    const url = new URL('https://nominatim.environs.life/reverse')
    const queryParams = new URLSearchParams({
      format: 'json',
      lat,
      lon,
    })
    url.search = queryParams.toString()

    const result = await $api.get(url.toString())
    const data: NominatimSearchResponse = result.data
    if (data.display_name) {
      setCurrentAddress(data)
      setNothingFound(false)
    } else {
      setCurrentAddress(undefined)
      setNothingFound(true)
    }
  }

  const updateUserPos = () => {
    if (navigator.geolocation) {
      setGeolocationStatus('ONGOING')
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords

          setMapCenter({
            lat: latitude,
            lng: longitude,
          })
          setGeolocationStatus('SUCCESS')

          updateAddress({
            lat: latitude.toString(),
            lon: longitude.toString(),
          })
        },
        (e) => {
          console.error(e)
          setGeolocationStatus('FAILED')
        },
      )
    } else {
      setGeolocationStatus('NOT_SUPPORTED')
    }
  }

  useEffect(() => {
    updateUserPos()
  }, [])

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.on('move', (e) => {
        const center = mapRef.current?.getCenter()
        if (center) {
          setMapMovingCenter(mapRef.current?.getCenter())
        }
      })

      return () => {
        mapRef.current?.off('move')
      }
    }
  }, [mapRef.current])

  useEffect(() => {
    if (lookupMapMovingCenter) {
      updateAddress({
        lat: (lookupMapMovingCenter as LatLng).lat.toString(),
        lon: (lookupMapMovingCenter as LatLng).lng.toString(),
      })
    }
  }, [lookupMapMovingCenter])

  const location = useLocation()

  return (
    <>
      <div className="location__current">
        <div
          className="location__current__map_button location__current__back"
          onClick={onBack}
        >
          <IoMdArrowBack />
        </div>

        {geolocationStatus === 'SUCCESS' && (
          <div
            className="location__current__map_button location__current__geolocation"
            onClick={updateUserPos}
          >
            <MdMyLocation />
          </div>
        )}

        <div className="location__current-map">
          {geolocationStatus === 'NOT_SUPPORTED' && (
            <div>Geolocation is not supported</div>
          )}
          {geolocationStatus === 'FAILED' && <div>Geolocation has failed</div>}
          {geolocationStatus === 'ONGOING' && <Spin />}
          {geolocationStatus === 'SUCCESS' && mapCenter && (
            <>
              {!mapReady && <Spin />}
              <MapContainer
                zoomControl={false}
                center={location.state?.center || mapCenter}
                minZoom={12}
                maxZoom={12}
                zoom={12}
                scrollWheelZoom={true}
                ref={mapRef}
                whenReady={() => setMapReady(true)}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                  url="https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png"
                  subdomains="abcd"
                />
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                  url="https://{s}.basemaps.cartocdn.com/dark_only_labels/{z}/{x}/{y}{r}.png"
                  subdomains="abcd"
                />

                <Circle center={drawingMapMovingCenter || mapCenter} radius={5} />
                <Circle
                  center={drawingMapMovingCenter || mapCenter}
                  radius={5000}
                  pathOptions={{ color: 'blue', opacity: 0.5 }}
                />
              </MapContainer>
            </>
          )}
        </div>
      </div>

      <div className="location__current-wrapper">
        <div className="location__current-button">
          {nothingFound
            ? `Nothing found`
            : currentAddress && (
                <>
                  <h5 className="location__current-title">
                    <IconLocationAim /> {currentAddress.display_name}
                  </h5>
                </>
              )}

          <Button
            className="location__bt-continue login__button"
            disabled={!currentAddress}
            onClick={() => currentAddress && onConfirm(currentAddress)}
          >
            Confirm Location
          </Button>
        </div>
      </div>
    </>
  )
}

export default SetAddressGeolocation
