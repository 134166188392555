import { useNavigate } from 'react-router-dom'
import { ChatType } from '../../../types/types'
import { indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconFlag } from '../../svg/IconFlag'
import { IconLeave } from '../../svg/IconLeave'
import { IconPencil } from '../../svg/IconPencil'
import { IconPostModalDelete } from '../../svg/IconPostModal'
import { Modal } from '../../ui/Modal'

export const LeaveModalAdmin = ({
  isOpen,
  setIsOpen,
  reportGroup,
  deleteGroup,
  setAdminLeave,
  chat,
}: {
  isOpen: boolean
  setIsOpen: (s: boolean) => void
  reportGroup: () => {}
  deleteGroup: () => {}
  setAdminLeave: (s: boolean) => void
  chat: ChatType
}) => {
  const navigate = useNavigate()
  const { role } = useAppSelector((state) => state.user)
  return (
    <div
      className={`activities__favor-modal  ${
        isOpen ? 'activities__favor-modal--open' : ''
      }`}
    >
      <Modal className="" setIsOpen={(s: boolean) => setIsOpen(s)}>
        <>
          <div
            onClick={(e) => {
              e.stopPropagation()
              setIsOpen(false)
            }}
            className="activities__favor-modal-linebody"
          >
            <button className="activities__favor-modal-line" />
          </div>
          <div className="publish__modaladd post__modal">
            <div className="post__modal_itembg">
              <div
                className="post__modal_item"
                onClick={() =>
                  navigate(`${indexPathByRole(role)}/messeges/group-edit`, {
                    state: { chat },
                  })
                }
              >
                <div style={{ padding: '0 0px' }}>
                  <IconPencil color="#000" />
                </div>
                <div>
                  <h5 className="post__modal_title">Edit</h5>
                  <h6 className="post__modal-subtitle">
                    Edit group name | description | photo
                  </h6>
                </div>
              </div>
            </div>
            <div className="post__modal_itembg">
              <div className="post__modal_item" onClick={reportGroup}>
                <div style={{ padding: '0 10px' }}>
                  <IconFlag />
                </div>
                <div>
                  <h5 className="post__modal_title">Report</h5>
                  <h6 className="post__modal-subtitle">Flag for review</h6>
                </div>
              </div>
              <div
                className="post__modal_item"
                onClick={(e) => {
                  e.preventDefault()
                  setAdminLeave(true)
                  setIsOpen(false)
                }}
              >
                <div style={{ padding: '0 0px' }}>
                  <IconLeave />
                </div>
                <div>
                  <h5 className="post__modal_title">Leave Group</h5>
                  <h6 className="post__modal-subtitle">Leave this group</h6>
                </div>
              </div>
              <div className="post__modal_item" onClick={deleteGroup}>
                <div style={{ padding: '0 5px' }}>
                  <IconPostModalDelete />
                </div>
                <div>
                  <h5 className="post__modal_title">Delete Group</h5>
                  <h6 className="post__modal-subtitle">Delete this group forever</h6>
                </div>
              </div>
            </div>
          </div>
        </>
      </Modal>
    </div>
  )
}
