import moment from 'moment'
import { FeedBackHttp } from '../../../http/feedback-http'
import { CommentInterface } from '../../../types/types'
import { apiBaseURL } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconPostsLike, IconPostsRedLike } from '../../svg/IconPosts'

export const PublicationPostCommentsList = ({
  comments,
  setComments,
  setIsreply = () => {},
  setReplyData = () => {},
}: {
  comments: CommentInterface[]
  setComments: (s: CommentInterface[]) => void
  setIsreply?: (s: boolean) => void
  setReplyData?: (s: {
    user: {
      _id: string
      fullName: string
      avatarFileName: string
    }
    commentText: string
    commentId: string
  }) => void
}) => {
  const { _id } = useAppSelector((state) => state.user)

  const updateLike = async (likeId: string, commnetId: string) => {
    await FeedBackHttp.updateLike({
      likeId,
      userId: _id,
    })
    setComments(
      comments.map((p) => {
        if (p._id === commnetId) {
          return {
            ...p,
            isLiked: !p.isLiked,
            likes: p.isLiked ? p.likes - 1 : p.likes + 1,
          }
        } else {
          return p
        }
      }),
    )
  }
  const returnDate = (d: Date) => {
    const date = moment(d)
    return `${date.format('MMM D,')} ${date.hour()}:${date.minute()}`
  }

  return (
    <>
      {comments.map((it) => (
        <>
          {it.userReplyId ? (
            <>
              <div className="replied">
                <div className="replied-row1">
                  {/* <button
                                                    className="commenst__item-like"
                                                    onClick={() => updateLike(it.likeId, it._id)}
                                                >
                                                    {it.isLiked ? <IconPostsRedLike /> : <IconPostsLike />}
                                            </button> */}
                  <div className="commenst__user-img">
                    {it.userReplyId.avatarFileName ? (
                      <img
                        src={`${apiBaseURL}/uploads/avatar/${it.userReplyId.avatarFileName}`}
                      />
                    ) : (
                      <div
                        style={{
                          backgroundColor: 'rgba(41, 52, 65, 1)',
                          borderRadius: '100%',
                          width: '100%',
                          height: '100%',
                          color: '#fff',
                          textAlign: 'center',
                          position: 'relative',
                        }}
                      >
                        <span style={{ position: 'absolute', left: '13px', top: '12px' }}>
                          {it.userReplyId.fullName.slice(0, 2)}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="replied-row1-body">
                    <p className="commenst__user-info-name commenst__item_body-user">
                      {it.userReplyId.fullName}{' '}
                      <span className="commenst__user-info-time commenst__item_body-user">
                        {returnDate(it.createdDateComment)}
                      </span>
                    </p>
                    <div>
                      <p className="commenst__item_body-text">{it.replyText}</p>
                    </div>
                  </div>
                </div>
                <div className="commenst__item replied-row2">
                  <div className="commenst__user-img replied-row2-img">
                    {it.userId.avatarFileName ? (
                      <img
                        src={`${apiBaseURL}/uploads/avatar/${it.userId.avatarFileName}`}
                      />
                    ) : (
                      <div
                        style={{
                          backgroundColor: 'rgba(41, 52, 65, 1)',
                          borderRadius: '100%',
                          width: '100%',
                          height: '100%',
                          color: '#fff',
                          textAlign: 'center',
                          position: 'relative',
                        }}
                      >
                        <span style={{ position: 'absolute', left: '10px', top: '9px' }}>
                          {it.userId.fullName.slice(0, 2)}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="replied-row2-body">
                    <p className="commenst__user-info-name commenst__item_body-user  replied-row2-body-time">
                      Reply To: {it.userId.fullName}{' '}
                      <span className="commenst__user-info-time commenst__item_body-user">
                        {returnDate(it.createdDateComment)}
                      </span>
                    </p>
                    <div>
                      <p className="commenst__item_body-text">{it.text}</p>
                    </div>
                  </div>
                </div>
                {/* <div className="commenst__item_body-likes">
                                                <button className="commenst__user-info-time">
                                                    Like {it.likes}
                                                </button>
                                            </div> */}
              </div>
            </>
          ) : (
            <>
              <div className="commenst__user commenst__item">
                <button
                  className="commenst__item-like"
                  onClick={() => updateLike(it.likeId, it._id)}
                >
                  {it.isLiked ? <IconPostsRedLike /> : <IconPostsLike />}
                </button>
                <div className="commenst__user-img">
                  {it.userId.avatarFileName ? (
                    <img
                      src={`${apiBaseURL}/uploads/avatar/${it.userId.avatarFileName}`}
                    />
                  ) : (
                    <div
                      style={{
                        backgroundColor: 'rgba(41, 52, 65, 1)',
                        borderRadius: '100%',
                        width: '100%',
                        height: '100%',
                        color: '#fff',
                        textAlign: 'center',
                        position: 'relative',
                      }}
                    >
                      <span style={{ position: 'absolute', left: '13px', top: '12px' }}>
                        {it.userId.fullName.slice(0, 2)}
                      </span>
                    </div>
                  )}
                </div>
                <div className="commenst__item-body">
                  <p className="commenst__user-info-name commenst__item_body-user">
                    {it.userId.fullName}{' '}
                    <span className="commenst__user-info-time commenst__item_body-user">
                      {returnDate(it.createdDateComment)}
                    </span>
                  </p>
                  <div>
                    <p className="commenst__item_body-text">{it.text}</p>
                    <div className="commenst__item_body-likes">
                      <button className="commenst__user-info-time">
                        Like {it.likes}
                      </button>
                      <button
                        className="commenst__user-info-time"
                        onClick={() => {
                          setIsreply(true)
                          setReplyData({
                            user: it.userId,
                            commentText: it.text,
                            commentId: it._id,
                          })
                        }}
                      >
                        Reply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      ))}
    </>
  )
}
