import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ServiceHttp } from '../../../http/service-http'
import { PublishServiceItemInterface } from '../../../types/types'
import { apiBaseURL } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconStars } from '../../svg/IconFavor'
import { Loader } from '../../ui/Loader'
import { UserHeaderChat } from '../header/UserHeaderChat'
import { calculateRate } from '../../../utils/calculateRate'

export const MessegesServices = () => {
  const navigate = useNavigate()
  const [search, setSearch] = useState('')
  const [isActiveSearch, setIsActiveSearch] = useState(false)
  const [chats, setChats] = useState<PublishServiceItemInterface[]>([])
  const [load, setLoad] = useState(false)
  const { publishServices } = useAppSelector((state) => state.categories)
  useEffect(() => {
    setLoad(true)
    setChats(publishServices)
    setLoad(false)
  }, [publishServices])
  const toPublishService = (_id: string) => {
    navigate(`/user/service/publish-service?publishServiceId=${_id}`)
  }

  return !load ? (
    <div className={`user__chat ${isActiveSearch && 'user__chat--active'}`}>
      <UserHeaderChat
        search={search}
        setSearch={setSearch}
        isActiveSearch={isActiveSearch}
        setIsActiveSearch={setIsActiveSearch}
        chats={chats}
        index={2}
      />
      {chats
        .filter((it) =>
          it.title
            .toLowerCase()
            .replaceAll(' ', '')
            .includes(search.toLowerCase().replaceAll(' ', '')),
        )
        .map((item) => (
          <>
            <div
              className="user__services-last-item"
              onClick={() => toPublishService(item._id)}
              style={{ margin: '20px 0' }}
            >
              <div className="user__services-last-item-img">
                <div className="user__services-last-item-img-1 user__services-last-item-img-info">
                  <div className="user__services-last-item-img-text">
                    {item.categoryId.name}
                  </div>
                </div>
                {item.filesName.length > 0 ? (
                  <img
                    src={`${apiBaseURL}/uploads/publish_services/${item.filesName[0]}`}
                    alt=""
                  />
                ) : (
                  <img className="posts__bg" src={`/Images/postsBG.png`} alt="" />
                )}
                <div className="user__services-last-item-img-2 user__services-last-item-img-info">
                  <div className="user__services-last-item-img-text">
                    {item.reviews.length ? (
                      <>
                        <IconStars />
                        <b>{calculateRate(item)}</b>
                        <span>{`(${item.reviews.length})`}</span>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
              <div className="user__services-last-item-info">
                <h5 className="user__services-last-item-title">{item.title}</h5>
                <h6 className="user__services-last-item-text">{item.text}</h6>
                {item.reviews ? (
                  <div className="user__services-last-item-foot">
                    {item.reviews.length} new{' '}
                    {item.reviews.length > 1 ? 'updates' : 'update'}
                  </div>
                ) : (
                  'no updates'
                )}
              </div>
            </div>
          </>
        ))}
    </div>
  ) : (
    <Loader />
  )
}
