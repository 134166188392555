import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { PublishPostHttp } from '../../../http/publish-post-http'
import { UserHttp } from '../../../http/user-http'
import {
  CommentInterface,
  HeaderMessageType,
  PostUserInterface,
} from '../../../types/types'
import { apiBaseURL } from '../../route/indexPathByRole'
import { IconComment } from '../../svg/IconFavor'
import { IconPostsLike, IconPostsRedLike, IconPostsRepost } from '../../svg/IconPosts'
import { IconProfileInfoBookmark } from '../../svg/IconProfileInfo'
import { ButtonLeftChevron } from '../../ui/ButtonLeftChevron'
import { Loader } from '../../ui/Loader'
import { PostSlick } from '../../ui/PostSlick'
import { UserServiceMap } from '../../user-components/services/UserServiceMap'
import { PublicationPostCommentsList } from '../publication-lists/PublicationPostCommentsList'
import { PublicationPostCommentsView } from '../publication-lists/PublicationPostCommentsView'

export const PublicationPostCommentsMedia = () => {
  const [searchParams] = useSearchParams()
  const [post, setPost] = useState<PostUserInterface>()
  const [comments, setComments] = useState<CommentInterface[]>([])
  const [countComments, setCountComments] = useState(0)
  const navigate = useNavigate()

  const getPostData = async () => {
    const postId = searchParams.get('postId') || ''
    console.log(postId)
    const post = await PublishPostHttp.getPostMedia({
      postId,
    })
    setPost(post)
  }

  // const getCommentData = async () => {
  //     const postId = searchParams.get("postId") || ""
  //     const { comments, countComments } = await PublishPostHttp.getComments({
  //         postId,
  //         userId: _id,
  //     })
  //     console.log(comments)
  //     setCountComments(countComments)
  //     setComments(comments)
  // }

  useEffect(() => {
    const bodyEffect = async () => {
      await getPostData()
    }
    bodyEffect()
  }, [])

  const getUser = async (item: string) => {
    const user = await UserHttp.getUserById({ userId: item })
    return user
  }

  const [userAvatars, setUserAvatars] = useState<HeaderMessageType[]>([])
  useEffect(() => {
    const EffectBody = async () => {
      let array: HeaderMessageType[] = []

      if (post) {
        if (post.peopleSaw.length > 3) {
          post?.peopleSaw
            .slice(post?.peopleSaw.length - 3, post?.peopleSaw.length)
            .map(async (item) => {
              let user = getUser(item)
              array.push(await user)
            })
        } else {
          post?.peopleSaw.map(async (item) => {
            let user = getUser(item)
            array.push(await user)
          })
        }
      }
      setUserAvatars(array)
    }
    EffectBody()
  }, [post])

  const [isreply, setIsreply] = useState<boolean>(false)
  const [replyData, setReplyData] = useState<{
    user: {
      _id: string
      fullName: string
      avatarFileName: string
    }
    commentText: string
    commentId: string
  }>({
    user: { _id: '', fullName: '', avatarFileName: '' },
    commentText: '',
    commentId: '',
  })
  const returnDate = (d: Date) => {
    const date = moment(d)
    return `${date.format('MMM D,')} ${date.hour()}:${date.minute()}`
  }
  return post ? (
    <div className="commenst">
      <div className="commenst__slick">
        <div className="commenst__back">
          <ButtonLeftChevron onClick={() => navigate(-1)} />
        </div>

        {post?.filesName && (
          <PostSlick list={post?.filesName}>
            {post.filesName.length > 0 ? (
              post?.filesName?.map((it) => (
                <div className="admin__posts-list-row2-img">
                  <img src={`${apiBaseURL}/uploads/publish_post/${it}`} alt="" />
                </div>
              ))
            ) : (
              <div className="admin__posts-list-row2-img">
                <img className="posts__bg" src={`/Images/postsBG.png`} alt="" />
              </div>
            )}
          </PostSlick>
        )}
        <div className="commenst__slick-bookmark">
          <button
            className={`ui-button-back-route ${
              post.isMarked && 'admin__posts-list-row4-repost--active'
            }`}
          >
            <IconProfileInfoBookmark />
          </button>
        </div>
      </div>
      <div className="commenst__content">
        <div className="commenst__user">
          <div className="commenst__user-img">
            {post.userId.avatarFileName ? (
              <img src={`${apiBaseURL}/uploads/avatar/${post.userId.avatarFileName}`} />
            ) : (
              <div
                style={{
                  backgroundColor: 'rgba(41, 52, 65, 1)',
                  borderRadius: '100%',
                  width: '100%',
                  height: '100%',
                  color: '#fff',
                  textAlign: 'center',
                  position: 'relative',
                }}
              >
                <span style={{ position: 'absolute', left: '13px', top: '12px' }}>
                  {post.userId.fullName.slice(0, 2)}
                </span>
              </div>
            )}
          </div>
          <div>
            <p className="commenst__user-info-name">{post.userId.fullName}</p>
            <p className="commenst__user-info-time">{returnDate(post.createdPostDate)}</p>
          </div>
        </div>
        <h5 className="commenst-title">{post.title}</h5>
        <p className="commenst-subtitle">{post.text}</p>
        <div className="commenst__viewmap">
          <PublicationPostCommentsView user={userAvatars} />
          <p className="commenst__view-text">
            Views <b>{post.peopleSaw.length}</b>
          </p>
          <UserServiceMap type="publish_post" item={post} />
        </div>
        <div>
          <h5 className="commenst-title">Comments</h5>
          <PublicationPostCommentsList
            comments={comments}
            setComments={setComments}
            setReplyData={setReplyData}
            setIsreply={setIsreply}
          />
        </div>
        <div className="admin__posts-list-row4">
          <button>
            {post.isLiked ? <IconPostsRedLike /> : <IconPostsLike />}

            <span>{post.likes}</span>
          </button>
          <button>
            <IconComment />
            <span>{countComments}</span>
          </button>
          <button>
            <IconPostsRepost />
            <span>{post.countReposts}</span>
          </button>
          <div className={`${post.isMarked && 'admin__posts-list-row4-repost--active'}`}>
            <IconProfileInfoBookmark />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Loader />
  )
}
