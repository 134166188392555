export const IconAddImage = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.875 9.625V15.2312C5.875 15.2312 9.60625 15.25 9.625 15.2312V9.625H15.25C15.25 9.625 15.2313 5.89375 15.25 5.875H9.625V0.25H5.875V5.875H0.25V9.625H5.875ZM11.5 17.125V11.5H17.125V5.875H32.125C34.1875 5.875 35.875 7.5625 35.875 9.625V32.125C35.875 34.1875 34.1875 35.875 32.125 35.875H9.625C7.5625 35.875 5.875 34.1875 5.875 32.125V17.125H11.5ZM32.125 32.125L26.5 24.625L22.75 30.25L17.125 22.75L9.625 32.125H32.125Z"
        fill="#333333"
      />
    </svg>
  )
}
