import styled from '@mui/material/styles/styled'
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers'
import { DemoItem } from '@mui/x-date-pickers/internals/demo'
import dayjs from 'dayjs'
import { IconPicker } from '../../svg/IconFavor'
import { Modal } from '../../ui/Modal'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
const StyledMobileDateTimePicker = styled(MobileDateTimePicker)(({ theme }) => ({
  '& input': {
    backgroundColor: 'white',
    border: 'none',
    outline: 'none',
    color: '#15104D',
    padding: '10px',
  },
  '& fieldset': {
    border: 'none',
    outline: 'none',
    boxShadow: '0px 2px 5px 0px rgba(38, 51, 77, 0.03)',
    color: '#15104D',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '140%',
  },
}))

const DateModal = ({
  setIsOpen,
  isOpen,
  startDate,
  setStartDate,
}: {
  setIsOpen: (s: boolean) => void
  isOpen: boolean
  startDate: dayjs.Dayjs
  setStartDate: (d: dayjs.Dayjs) => void
}) => {
  return (
    <div
      className={`activities__favor-modal  ${
        isOpen ? 'activities__favor-modal--open' : ''
      }`}
    >
      <Modal className="" setIsOpen={(s: boolean) => setIsOpen(s)}>
        <div
          onClick={(e) => {
            e.stopPropagation()
            setIsOpen(false)
          }}
          className="activities__favor-modal-linebody"
        >
          <button className="activities__favor-modal-line" />
        </div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoItem>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'white',
                borderRadius: '8px',
                paddingLeft: '10px',
                height: '50px',
              }}
            >
              <IconPicker />
              <StyledMobileDateTimePicker
                disableFuture
                defaultValue={startDate}
                value={startDate}
                onChange={(d) => setStartDate(d as dayjs.Dayjs)}
              />
            </div>
          </DemoItem>
        </LocalizationProvider>
      </Modal>
    </div>
  )
}
export default DateModal
