export const IconProfessions = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path d="M22 2H2V22H22V2Z" fill="url(#pattern0_3101_16394)" />
      <defs>
        <pattern
          id="pattern0_3101_16394"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image0_3101_16394" transform="scale(0.01)" />
        </pattern>
        <image
          id="image0_3101_16394"
          width="100"
          height="100"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAADOUlEQVR4nO2cO2sUURiGnwQMxE4CEdGAoliY7SzUFBJcsJDF1jaFRRovoH9A1EaIrq7a+AckVoobBW+Ff0CIWnirbEXMBrWIjgwcYYvsXHZu3555H/jaOTPvc87Mzs45B4QQQgghhBBCCCGEEEIIIYQQIooG0AbeAutAoCJNBusuu7bLcmgmgDvAHwkgr064AXRctqllvJAIirobPE8rJRwZujVRaAa3kspouKElIRSaQZjxbBIhNyWDsjrj9SRC3kkIZQlZTSKkJyGUJWQtiRA9Oyg1AwnBVqeTEKqXICFUH7yEUH3YEkL1AY+UkO/ADeA4sMP9nzMFNIFHBsKplZAHwLaYti8ZCKgWQp4A40kaB7YCJ4D3BsLyUsgvYC/p2Q78MBCYd0IeMjxXDQTmnZAzGYQ0DQTmnZCTGYTsyaH9LrCT4tkFrIyCkPmMFxlkrPAYZTEzCkJaGS6wISH5CzmdQUgrh/ZXSholM+7nvfkRspThIq8UcD7WqnQhrzMIeWUgMO+E3Msg5L6BwLwTcjCDkFMGApOQPo4ZCMw7IRcYnssGAvNOyLchZ303gZ85tK839U1CeZNSxhjwNacOoTf1AcEkmsfqOJTjCJWQAcG8TPiRaiznpRB6U48Ip+MCj5JxrYDnmOWKpegTWIhoe8FAQLUTcjai7XMGApKQPs4bCKh2QhYj2l40EFCthITrIXbHfLLtGQipFkI+AIcTtD8HfMyx3cclflPvjoKQz+7/rMkUFzcJXAS+5NC+XgyBv+5zZivFjMXNGHfHeOqOKSEpQwh3fVjOuoXEABpufnBaMd0Sv6mbmgb0CThS/HUz526DgScVyzAHDTdXmaY8pj1avh3LMJOp91E++4HfBgI1J+Q21XHXQKDmhBylOuYNBGpOSNyqqCKZMhCoOSEqJCTwqCNohFC9BAmh+uAlhOrDlhA8FLJm4CSDmlS47DsWX/4jCnzZ4q9t4ESDmlSi1WXaJpbStok9QEI6BnpP4HmF2/EmZsJth131SQee1jNgSxoh/6WE22Frl2tyE7HhRkZqGf3Muh2YV2s4ZyrIoXouu6U0zwwhhBBCCCGEEEIIIYQQQgghBDXkHwXwQ8aUvGcJAAAAAElFTkSuQmCC"
        />
      </defs>
    </svg>
  )
}
