import React from 'react'

export const IconSvgPrivacyPublishCircle = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <circle cx="10" cy="10" r="9.5" stroke="#C3CAD9" />
    </svg>
  )
}

export const IconSvgPrivacyPublishCheckCircle = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <circle cx="10" cy="10" r="9.5" stroke="#0F1419" />
      <circle cx="10" cy="10" r="5" fill="#0F1419" />
    </svg>
  )
}
