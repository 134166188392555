import React from 'react'
import { Circle, MapContainer, TileLayer } from 'react-leaflet'
import { IconLocationKey } from '../../svg/IconsLocation'
import { LocationButtonBack } from './LocationButtonBack'
import { NominatimSearchResponse } from '../../../redux/statelessRequests/address'

interface Props {
  place: NominatimSearchResponse
  onBack: () => void
  onConfirm: () => void
}

export default function SetAddressConfirm({ place, onBack, onConfirm }: Props) {
  return (
    <div className="forget">
      <div className="location__body">
        <LocationButtonBack onButtonClick={onBack} />
        <h4 className="forget__title">Enter your adress</h4>
        <h5 className="forget__subtitle location__subtitle">
          <IconLocationKey />
          <div>
            <p>Your adress won’t be seen by others. </p>
            <p>You can change this setting anytime</p>
          </div>
        </h5>
      </div>

      <MapContainer
        dragging={false}
        zoomControl={false}
        center={{
          lat: Number(place.lat),
          lng: Number(place.lon),
        }}
        minZoom={13}
        maxZoom={13}
        zoom={13}
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
          url="https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png"
          subdomains="abcd"
        />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
          url="https://{s}.basemaps.cartocdn.com/dark_only_labels/{z}/{x}/{y}{r}.png"
          subdomains="abcd"
        />
        <Circle
          center={{
            lat: Number(place.lat),
            lng: Number(place.lon),
          }}
          radius={5}
        />

        <Circle
          center={{
            lat: Number(place.lat),
            lng: Number(place.lon),
          }}
          radius={5000}
          pathOptions={{ color: 'blue', opacity: 0.5 }}
        />
      </MapContainer>

      <p className="location__mess">
        You will see all the news and updates within a radius of 5 km You can personalize
        your feed later in your profile settings.
      </p>

      <button className="location__bt-continue login__button" onClick={onConfirm}>
        Confirm Location
      </button>
    </div>
  )
}
