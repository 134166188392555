import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ActivitiesHttp } from '../../../http/activities-http'
import { UserHttp } from '../../../http/user-http'
import {
  HeaderMessageType,
  PublishActivitiesOneItemInterface,
} from '../../../types/types'
import { apiBaseURL } from '../../route/indexPathByRole'
import { PublicationPostCommentsView } from '../../general-components/publication-lists/PublicationPostCommentsView'
import { IconComment } from '../../svg/IconFavor'
import { IconPostsRepost } from '../../svg/IconPosts'
import { IconTicket } from '../../svg/IconTicket'
import { ButtonLeftChevron } from '../../ui/ButtonLeftChevron'
import { Loader } from '../../ui/Loader'
import { PostSlick } from '../../ui/PostSlick'
import { UserServiceMap } from '../../user-components/services/UserServiceMap'

export const UserPublishActivitiesItemMedia = () => {
  const [searchParams] = useSearchParams()
  const [publishService, setPublishService] =
    useState<PublishActivitiesOneItemInterface>()

  // const [comments, setComments] = useState<CommentInterface[]>([])
  const [countComments, setCountComments] = useState(0)
  const navigate = useNavigate()

  const [loaded, setLoaded] = useState(false)
  const [userAvatars, setUserAvatars] = useState<HeaderMessageType[]>([])
  const [users, setUsers] = useState<HeaderMessageType[]>([])

  const getPublishServiceData = async () => {
    const publishActivitiesId = searchParams.get('publishServiceId') || ''
    const publish = await ActivitiesHttp.getOnePublsihActivity({
      publishActivitiesId,
    })
    console.log(publishActivitiesId)
    if (publish) {
      setPublishService(publish)
    }
  }

  useEffect(() => {
    const bodyEffect = async () => {
      await getPublishServiceData()
      setLoaded(true)
      // await getCommentData()
    }
    bodyEffect()
  }, [])

  // const getCommentData = async () => {
  //     const eventId = searchParams.get("publishActivitiesId") || ""
  //     const { comments, countComments } = await ActivitiesHttp.getComments({
  //         eventId,
  //         userId: _id,
  //     })
  //     setUpdate(true)
  //     setCountComments(countComments)
  //     setComments(comments)
  // }
  // useEffect(() => {
  //     getCommentData()
  // }, [])

  useEffect(() => {
    if (publishService && loaded) {
      if (publishService.peopleSaw.length > 3) {
        publishService?.peopleSaw
          .slice(publishService?.peopleSaw.length - 3, publishService?.peopleSaw.length)
          .map((item) => {
            UserHttp.getUserById({ userId: item }).then((res) => {
              setUsers((s) => [...s, res])
            })
          })
      } else {
        publishService?.peopleSaw.map((item) => {
          UserHttp.getUserById({ userId: item }).then((res) => {
            setUsers((s) => [...s, res])
          })
        })
      }
    }
  }, [loaded])
  useEffect(() => {
    if (users.length) setUserAvatars(users)
  }, [users])

  return publishService ? (
    <div className="commenst user__publish-service">
      <div className="commenst__slick">
        <div
          className="user__services-last-item-img-1 user__services-last-item-img-info"
          style={{ width: '100px', height: '35px', top: '95%' }}
        >
          <div className="user__services-last-item-img-text">
            <span
              className="admin__posts-list-row1-text"
              style={{ fontSize: '12px', color: 'rgba(21, 16, 77, 1)' }}
            >
              {moment(publishService?.startDate).format('DD/MM/YYYY')}
            </span>{' '}
          </div>
        </div>
        <div className="commenst__back">
          <ButtonLeftChevron onClick={() => navigate(-1)} />
        </div>
        {publishService?.filesName.length > 0 ? (
          <PostSlick list={publishService?.filesName}>
            {publishService?.filesName?.map((it) => (
              <div className="commenst__slick-img">
                <img src={`${apiBaseURL}/uploads/publish_activities/${it}`} alt="" />
              </div>
            ))}
          </PostSlick>
        ) : (
          <div className="commenst__slick-img">
            <img className="posts__bg" src={`/Images/postsBG.png`} alt="" />
          </div>
        )}
        {/* <div className="commenst__slick-bookmark">
                    <button
                        onClick={() => updateMarkEvent(publishService._id, publishService.isMarked)}
                        className={`ui-button-back-route ${
                            publishService.isMarked &&
                            "admin__posts-list-row4-repost--active"
                        }`}
                    >
                        <IconProfileInfoBookmark />
                    </button>
                </div> */}
      </div>
      <div className="commenst__content">
        <div className="commenst__user">
          {publishService.userId && (
            <div className="commenst__user-img">
              {publishService.userId.avatarFileName ? (
                <img
                  src={`${apiBaseURL}/uploads/avatar/${publishService.userId.avatarFileName}`}
                />
              ) : (
                <div
                  style={{
                    backgroundColor: 'rgba(41, 52, 65, 1)',
                    borderRadius: '100%',
                    width: '100%',
                    height: '100%',
                    color: '#fff',
                    textAlign: 'center',
                    position: 'relative',
                  }}
                >
                  <span style={{ position: 'absolute', left: '13px', top: '12px' }}>
                    {publishService.userId.fullName.slice(0, 2)}
                  </span>
                </div>
              )}
            </div>
          )}
          <div>
            <p className="commenst__user-info-name">{publishService.userId?.fullName}</p>
            <p className="commenst__user-info-time">
              {moment(publishService.createEventDate).format('MMM D, h:mm a')}
            </p>
          </div>
        </div>
        <h5 className="commenst-title">{publishService.title}</h5>
        <p className="commenst-subtitle">{publishService.text}</p>
        <br />
        <div className="commenst__viewmap">
          <div className="commenst__map">
            <PublicationPostCommentsView user={userAvatars} />
            <p className="commenst__view-text">
              Views <b>{publishService.peopleSaw.length}</b>
            </p>
            <UserServiceMap type="publish_activities" item={publishService} />
          </div>
        </div>
        {publishService.url && (
          <div className="user__event__page-tickets">
            <h5 className="commenst-title">Tickets</h5>
            <div className="user__event__page-tickets-btn">
              <IconTicket />
              <span>Get Tickets</span>
            </div>
          </div>
        )}
        <div className="user__event__page-comments">
          {/* <div>
                    <h5 className="commenst-title">Comments</h5>
                    <PublicationPostCommentsList
                        comments={comments}
                        setComments={setComments}
                        setReplyData={setReplyData}
                        setIsreply={setIsreply}
                    />
                </div> */}
          <div className="admin__posts-list-row4">
            <button>
              <span>{publishService.countLikes}</span>
            </button>
            <button>
              <IconComment />
              <span>{countComments}</span>
            </button>
            <button>
              <IconPostsRepost />
              <span>{publishService.countShare}</span>
            </button>
            {/* <div
                        onClick={() => updateMarkEvent(publishService._id, publishService.isMarked)}
                        className={`${
                            publishService.isMarked &&
                            "admin__posts-list-row4-repost--active"
                        }`}
                    >
                        <IconProfileInfoBookmark />
                    </div> */}
          </div>
          {/* {isreply && 
                        <div className="commenst__reply">
                            <div className="commenst__reply-row1">
                                <span className="commenst__user-info-time commenst__reply-row1-text">
                                    Reply To: <b>{replyData.user.fullName}</b>
                                </span>
                                <button
                                    className=""
                                    onClick={() => setIsreply(false)}
                                >
                                    <IconAdminClose />
                                </button>
                            </div>
                            <div className="commenst__reply-row2">
                                <span className="commenst__user-info-time">
                                    On Comment: <b style={{fontStyle:"italic"}}>"{replyData.commentText.length<20 ? replyData.commentText : `${replyData.commentText.slice(0,20)}...`}"</b>
                                </span>
                            </div>
                        </div>
                    } */}
        </div>
        <div style={{ padding: '30px 0' }} />
      </div>
    </div>
  ) : (
    <Loader />
  )
}
