import { AxiosResponse } from 'axios'
import L, { LatLng } from 'leaflet'
import { useEffect, useState } from 'react'
import { MapContainer, Polygon, TileLayer } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-cluster'
import { useNavigate } from 'react-router-dom'
import $api from '../../../http'
import { UserHttp } from '../../../http/user-http'
import { UserInitialStateInterface } from '../../../redux/users'
import { ROLE } from '../../../types/enum'
import {
  LocationType,
  PostUserInterface,
  PublishEventItemInterface,
  PublishServiceItemInterface,
} from '../../../types/types'
import { useAppSelector } from '../../../utils/hooks'
import { DrawModal } from '../../ui/DrawModal'
import { LocationMarker } from '../../ui/LocationMarker'
import { insideCircle } from '../../../utils/inside'
import { EventMark } from '../../user-components/explore/EventMark'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../store'
import { setCoordinates } from '../../../redux/profile'

export const AdminPanelMap = ({
  setLoad,
  events,
  posts,
  users,
  admins,
  publishService,
}: {
  setLoad: (s: boolean) => void
  events: PublishEventItemInterface[]
  posts: PostUserInterface[]
  users: UserInitialStateInterface[]
  admins: UserInitialStateInterface[]
  publishService: PublishServiceItemInterface[]
}) => {
  const dispatch = useDispatch<AppDispatch>()

  const { fullName, role, polygonCoords, _id } = useAppSelector((state) => state.user)
  const { coordinates, step } = useAppSelector((state) => state.profile)

  const [mousePos, setMousePos] = useState<[number, number]>([0, 0])
  const [IsOpenMarkers, setIsOpenMarkers] = useState<boolean>(false)

  const [MarkerMode, setMarkerMode] = useState<boolean>(false)
  const [MarksList, setMarksList] = useState<LocationType[]>([])

  const [drawMode, setDrawMode] = useState<boolean>(false)
  const [radius, setRadius] = useState<boolean>(false)
  const [radiusValue, setRadiusValue] = useState<number>(1)
  const [draw, setDraw] = useState<boolean>(false)

  const [polyline, setPolyline] = useState<L.Polyline | null>()
  const [paint, setPaint] = useState<boolean>(false)
  const [startPos, setStartPos] = useState<[number, number]>()
  const [drawed, setDrawed] = useState<boolean>(false)
  const [polygonCords, setPolygonCords] = useState<
    LatLng[] | LatLng[][] | LatLng[][][] | undefined
  >([])

  const navigate = useNavigate()

  const [map, setMap] = useState<L.Map | null>(null)

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        const { latitude: lat, longitude: lng } = pos.coords
        dispatch(setCoordinates({ lat, lng }))
      },
      () => console.log('Cannot get user location'),
    )
  }

  useEffect(() => {
    const effectBody = async () => {
      const resMarksList: AxiosResponse<LocationType[]> =
        await $api.post('map/list-marks')
      console.log(resMarksList)
      setMarksList(resMarksList.data)
    }
    effectBody()
  }, [])
  map?.on('click', (e) => {
    if (MarkerMode) {
      setMousePos([e.latlng.lat, e.latlng.lng])
      setIsOpenMarkers(true)
    } else {
      if (role === ROLE.ADMIN) navigate(`map`)
      // if(role===ROLES.LOCAL_ADMIN && polygonCoords){
      //     map.flyTo(L.polygon(polygonCoords).getCenter(), 10)
      // }
    }
    // if( !startPos && !polygonCords?.length && !paint && !drawed){
    //     setPaint(true)
    //     setStartPos([e.latlng.lat, e.latlng.lng])
    //     setPolyline(L.polyline([]).addTo(map))
    //     // setPolygonCords([]);
    //   }
  })
  // const handleClick = () => {
  //     // setMarkerMode(!MarkerMode);
  //     setIsOpenMarkers(!IsOpenMarkers);
  // }
  const mapElement = document.querySelector('.leaflet-container')
  polyline?.setStyle({
    weight: 5,
  })
  let d = false
  {
    !drawed &&
      !d &&
      mapElement?.addEventListener('touchmove', (e) => {
        if (!polygonCords?.length && map && draw && !drawed) {
          map.dragging.disable()
          let point = L.point(
            (e as TouchEvent).targetTouches[0].clientX,
            (e as TouchEvent).targetTouches[0].clientY,
          )
          let pointlatlng = map?.layerPointToLatLng(point)
          if (paint && startPos && pointlatlng && !drawed) {
            polyline?.addLatLng(pointlatlng)
            if (
              (insideCircle([pointlatlng.lat, pointlatlng.lng], 0.01, startPos) ||
                (pointlatlng.lat === startPos[0] && pointlatlng.lng === startPos[1])) &&
              map &&
              polyline?.getLatLngs().length
            ) {
              setPolygonCords(polyline?.getLatLngs())
              polyline?.removeFrom(map)
              polyline?.setLatLngs([])
              setPaint(false)
              setDraw(false)
              map?.dragging.enable()
              setStartPos(undefined)
              d = true
              setDrawed(true)
              e.stopImmediatePropagation()
            }
          }
        }
      })
  }
  const [userAdmins, setUserAdmins] = useState<UserInitialStateInterface[]>([])
  useEffect(() => {
    UserHttp.getAllUsers().then((res) => {
      setUserAdmins(
        res.filter(
          (it) => it.role === ROLE.LOCAL_ADMIN || it.role === ROLE.REGIONAL_ADMIN,
        ),
      )
      console.log(
        res.filter(
          (it) => it.role === ROLE.LOCAL_ADMIN || it.role === ROLE.REGIONAL_ADMIN,
        ),
      )
    })
  }, [])

  return (
    <div>
      <div className="admin__panel-name">Hello, {fullName}</div>
      <div className="admin__panel-title"> My working area </div>
      <div className="admin__panel__map" style={{ position: 'relative' }}>
        {/* {<div style={{position:"absolute", zIndex:"20", right:"10px", top:"-10px"}} className={!drawMode ? "admin__panel-addMarker explore__location__follow":"admin__panel-addMarker explore__location__follow clicked"} id="draw" onClick={()=>{
                      setDrawMode(!drawMode);

                  }}>
                      <IconPencil color="#fff"/>
                  </div> }    */}
        <MapContainer
          center={[coordinates.lat, coordinates.lng]}
          zoom={10}
          maxZoom={19}
          minZoom={8}
          scrollWheelZoom={true}
          zoomControl={false}
          ref={setMap}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
            url="https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png"
            subdomains="abcd"
          />
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
            url="https://{s}.basemaps.cartocdn.com/dark_only_labels/{z}/{x}/{y}{r}.png"
            subdomains="abcd"
          />
          {/* <AdminMarker enable={MarkerMode} setCoords={setMousePos} setIsOpen={setIsOpenMarkers}/> */}
          {polygonCords && <Polygon positions={polygonCords} />}
          {polygonCoords && <Polygon positions={polygonCoords} />}
          {/* {admins.map((item)=>(
                        <>
                            {item.polygonCoords&&<Polygon positions={item.polygonCoords} />}
                        </>
                    ))} */}
          {userAdmins.map((item) => (
            <>{item.polygonCoords && <Polygon positions={item.polygonCoords} />}</>
          ))}
          <MarkerClusterGroup
            chunkedLoading
            spiderLegPolylineOptions={{
              opacity: 0,
            }}
          >
            {MarksList &&
              MarksList.map((item) => (
                <LocationMarker markerData={item} handler={() => {}} />
              ))}
            <>
              {/* {publishService && publishService?.map((item)=>(
                                <EventMark 
                                    position={[item.coordinates.lat, item.coordinates.lng]}
                                    title={item.title}
                                    img="/Images/serviceMark.svg"
                                    file={item.filesName[0]}
                                    type="publish_services"
                                    handler={()=>{}}
                                    onclick={()=>{
                                    }}
                                />
                            ))}
                            {events && events?.map((item)=>(
                                <EventMark 
                                position={[item.coordinates.lat, item.coordinates.lng]}
                                title={item.title}
                                img="/Images/eventMark.svg"
                                file={item.filesName[0]}
                                type="publish_activities"
                                handler={()=>{}}
                                onclick={()=>{}}
                                />
                            ))}
                            {posts && posts?.map((item)=>(
                                <EventMark 
                                position={[item.coordinates.lat, item.coordinates.lng]}
                                title={item.title}
                                img="/Images/postMark.svg"
                                file={item.filesName[0]}
                                type="publish_post"
                                handler={()=>{}}
                                onclick={()=>{}}
                                />
                            ))} */}
            </>
          </MarkerClusterGroup>
        </MapContainer>
      </div>
      {MarkerMode && <span>Marker mode activated</span>}
      {drawMode && map && (
        <DrawModal
          map={map}
          setPolyline={setPolyline}
          draw={draw}
          setDraw={setDraw}
          setRadius={setRadius}
          radius={radius}
          setRadiusSize={setRadiusValue}
          radiusSize={radiusValue}
          isOpen={drawMode}
          setIsOpen={setDrawMode}
        />
      )}
    </div>
  )
}
