import { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { UserHttp } from '../../../http/user-http'
import { ChatType, HeaderMessageType } from '../../../types/types'
import { indexPathByRole, apiBaseURL } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconLeftChevrons } from '../../svg/IconChevrons'
import { IconServicesAllPoint } from '../../svg/IconServicesAll'
import { UserHeader } from './UserHeader'
import { ROLE } from '../../../types/enum'

export const UserHeaderUserChatMessage = ({ chat }: { chat: ChatType }) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { role } = useAppSelector((state) => state.user)
  const [user, setUser] = useState<HeaderMessageType>()
  const toProfileInfo = (prop: {
    _id: string
    email: string
    role: string
    fullName: string
    avatarFileName: string
  }) => {
    navigate('/profileinfo', {
      state: prop,
    })
  }
  useEffect(() => {
    if (!chat.participants[0].userId._id) {
      const userId = chat.participants[0].userId
      if (userId) {
        UserHttp.getUserById({ userId }).then((s) => setUser(s))
      }
    } else {
      const userId = chat.participants[0].userId._id
      if (userId) {
        UserHttp.getUserById({ userId }).then((s) => setUser(s))
      }
    }
  }, [])

  return (
    <>
      {user ? (
        <UserHeader>
          <div className="user__header-messages">
            <button
              onClick={() => navigate(`${indexPathByRole(role)}/messeges/`)}
              className="user__header-chatuser user__header-messages-exit"
            >
              <IconLeftChevrons />
            </button>
            <div className="user__header-messages-img">
              {chat?.participants.length > 2 ? (
                <img
                  src={
                    chat.groupImage
                      ? `${apiBaseURL}/uploads/messenger/${chat.groupImage}`
                      : '/Images/postsBG.png'
                  }
                  alt=""
                />
              ) : (
                <>
                  {user?.avatarFileName ? (
                    <img
                      onClick={() =>
                        toProfileInfo({
                          avatarFileName: user.avatarFileName,
                          _id: user._id,
                          email: user.email,
                          fullName: user.fullName,
                          role: ROLE.USER,
                        })
                      }
                      src={`${apiBaseURL}/uploads/avatar/${user?.avatarFileName}`}
                    />
                  ) : (
                    <div
                      onClick={() =>
                        toProfileInfo({
                          avatarFileName: user.avatarFileName,
                          _id: user._id,
                          email: user.email,
                          fullName: user.fullName,
                          role: ROLE.USER,
                        })
                      }
                      style={{
                        backgroundColor: 'rgba(41, 52, 65, 1)',
                        borderRadius: '100%',
                        width: '100%',
                        height: '100%',
                        color: '#fff',
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <span style={{ margin: 'auto', color: '#fff', fontSize: '14px' }}>
                        {user?.fullName.slice(0, 2)}
                      </span>
                    </div>
                  )}
                </>
              )}
            </div>
            <div>
              {chat?.participants.length > 2 ? (
                <div className="user__header-messages-name" style={{ margin: '10px 0' }}>
                  {chat.name.length > 25 ? chat.name.slice(0, 25) + '...' : chat.name}
                </div>
              ) : (
                <>
                  <div
                    className="user__header-messages-name"
                    style={{ margin: '10px 0' }}
                  >
                    {user?.fullName}
                  </div>
                </>
              )}
            </div>
            <button
              onClick={() =>
                navigate('chat-info/neibs', {
                  state: { chat },
                })
              }
            >
              <IconServicesAllPoint />
            </button>
          </div>
        </UserHeader>
      ) : (
        ''
      )}
    </>
  )
}
