import { Route, Routes } from 'react-router-dom'
import { UserPublishActivitiesItemMedia } from './UserPublishActivitiesItemMedia'
import { UserPublishServicesItemMedia } from './PublishServiceItemMedia'
import { PublicationPostCommentsMedia } from './PostCommentsMedia'

export const Preview = () => {
  return (
    <Routes>
      <Route path="activity/*" element={<UserPublishActivitiesItemMedia />} />
      <Route path="posts/*" element={<PublicationPostCommentsMedia />} />
      <Route path="service/*" element={<UserPublishServicesItemMedia />} />
    </Routes>
  )
}
