import { Button, Flex, Form, Radio, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { attemptUpdateFamilyStatus } from '../../../../redux/userNew'
import { FAMILY_STATUS } from '../../../../types/enum'
import { EditUserProfileDTO } from '../../../../types/server-communication/user'
import { useAppDispatch, useAppSelector } from '../../../../utils/hooks'
import { useAttemptListener } from 'dev-masters-react-kit'
import { indexPathByRole } from '../../../route/indexPathByRole'
import { StandaloneScreenLayout } from '../../../ui/Layout/StandaloneScreenLayout'
import { store } from '../../../../store'

interface FormValues extends Pick<EditUserProfileDTO, 'familyStatus'> {}
export const ProfileFamilyStatus = () => {
  const [familyStatusForm] = Form.useForm<FormValues>()
  const { familyStatus, role } = useAppSelector((state) => state.userNew)
  const currentFamilyStatus = Form.useWatch('familyStatus', familyStatusForm)

  const { t } = useTranslation('profile')

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const isLoading = useAttemptListener({
    store,
    attempt: attemptUpdateFamilyStatus,
    onFulfilled: () => {
      navigate('/profile/stay-touch')
    },
  })
  function handleSubmitForm(values: FormValues) {
    dispatch(attemptUpdateFamilyStatus(values))
  }

  return (
    <StandaloneScreenLayout
      title="Family Status"
      subTitle="We appreciate the diverse forms of family and relationships"
      onPrimaryButtonClicked={familyStatusForm.submit}
      isDisabled={isLoading || !currentFamilyStatus}
    >
      <Form
        form={familyStatusForm}
        name="basic"
        layout="vertical"
        initialValues={{ familyStatus: familyStatus }}
        onFinish={handleSubmitForm}
      >
        <Form.Item name="familyStatus">
          <Radio.Group>
            <Space direction="vertical">
              {Object.entries(FAMILY_STATUS).map(([key, value]) => (
                <Radio key={key} value={key}>
                  {t(value)}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
      </Form>
    </StandaloneScreenLayout>
  )
}
