export const IconVisa = () => {
  return (
    <svg
      width="20"
      height="8"
      viewBox="0 0 20 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3435 2.82256C10.3321 3.72225 11.1453 4.22428 11.7579 4.52279C12.3873 4.82904 12.5987 5.02545 12.5962 5.29936C12.5915 5.7185 12.0941 5.9035 11.6288 5.91068C10.8168 5.92326 10.3447 5.69146 9.96938 5.51615L9.67688 6.88482C10.0534 7.05834 10.7507 7.20967 11.4738 7.21631C13.171 7.21631 14.2814 6.3785 14.2874 5.07951C14.2941 3.43092 12.0071 3.33967 12.0227 2.60279C12.0281 2.37935 12.2413 2.14092 12.7085 2.08029C12.9398 2.04967 13.5781 2.02623 14.3019 2.35951L14.5859 1.03529C14.1967 0.893574 13.6965 0.757871 13.0737 0.757871C11.4762 0.757871 10.3526 1.60709 10.3435 2.82256ZM17.3155 0.871933C17.0055 0.871933 16.7444 1.05271 16.6278 1.33014L14.2034 7.11904H15.8994L16.2369 6.18631H18.3094L18.5052 7.11904L20 7.11904L18.6955 0.871933H17.3155ZM17.5527 2.55951L18.0422 4.90537L16.7017 4.90537L17.5527 2.55951ZM8.28719 0.872011L6.95031 7.11896L8.56648 7.11896L9.90273 0.871855H8.28719V0.872011ZM5.89633 0.871855L4.21414 5.12396L3.53367 1.50857C3.45383 1.10498 3.13852 0.871933 2.78836 0.871933L0.0385156 0.871933L0 1.05334C0.564531 1.17584 1.20594 1.37342 1.59453 1.58482C1.83234 1.71396 1.90016 1.82686 1.97828 2.13373L3.26711 7.11904H4.975L7.59344 0.871933H5.89633"
        fill="url(#paint0_linear_1528_20981)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1528_20981"
          x1="9.1948"
          y1="7.34586"
          x2="9.38174"
          y2="0.712955"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#222357" />
          <stop offset="1" stopColor="#254AA5" />
        </linearGradient>
      </defs>
    </svg>
  )
}
