import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import $api from '../../../http'
import { ParticipantType, RequestType } from '../../../types/types'
import { apiBaseURL, indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconLeftChevrons } from '../../svg/IconChevrons'
import { success } from '../../ui/LoadSuccess'
import { UserHeader } from '../header/UserHeader'

export const MessegesRequests = () => {
  const navigate = useNavigate()
  const { _id, role, avatarFileName, fullName } = useAppSelector((state) => state.user)
  const [requests, setRequests] = useState<RequestType[]>([])
  useEffect(() => {
    const body = async () => {
      const res = await $api.post('messenger/get-request', {
        userId: _id,
      })
      setRequests(res.data)
    }
    body()
  }, [])
  const openChat = async (item: RequestType) => {
    await $api
      .post('messenger/open-chat', {
        participants: [
          {
            userId: item.senderId._id,
          },
          {
            userId: _id,
          },
        ],
        isPersonal: true,
      })
      .then((res) => {
        console.log(res)
        if (res.data) {
          navigate(
            `${indexPathByRole(role)}/messeges/chat?chatId=${JSON.stringify(res.data.chatId)}`,
            {
              state: {
                participants: [
                  {
                    userId: item.senderId,
                  },
                  {
                    userId: {
                      _id,
                      avatarFileName,
                      fullName,
                    },
                  },
                ],
                chat: {
                  ...res.data,
                  participants: [
                    {
                      userId: item.senderId,
                    },
                    {
                      userId: {
                        _id,
                        avatarFileName,
                        fullName,
                      },
                    },
                  ],
                },
              },
            },
          )
        }
      })
  }
  const openChatGroup = async (
    chatId: string,
    participants: ParticipantType[],
    groupOwner: string,
    senderId: string,
  ) => {
    await $api
      .post('messenger/leave-group', {
        chatId: chatId,
        ownerId: groupOwner,
        participants: [
          ...participants.map((it) => {
            return { userId: it.userId._id }
          }),
          { userId: senderId },
        ],
      })
      .then((res) => {
        navigate(`${indexPathByRole(role)}/messeges/`)
      })
  }
  const acceptRequest = async (item: RequestType) => {
    await $api
      .post('messenger/delete-request', {
        requestId: item._id,
      })
      .then(() => {
        if (item.chatId) {
          openChatGroup(
            item.chatId._id,
            item.chatId.participants,
            item.chatId.groupOwner,
            item.senderId._id,
          )
        } else {
          openChat(item)
        }
      })
  }
  const rejectRequest = async (item: RequestType) => {
    await $api.post('messenger/delete-request', {
      requestId: item._id,
    })
    success()
    navigate(-1)
  }
  return (
    <>
      <UserHeader>
        <div className="user__header-messages explore__header">
          <button
            onClick={() => navigate(-1)}
            className="user__header-chatuser user__header-messages-exit"
          >
            <IconLeftChevrons />
          </button>
          <h5 className="user__header-title" style={{ margin: '40px 0' }}>
            Requests
          </h5>
        </div>
      </UserHeader>
      <div className="requests__container">
        {requests.map((item) => (
          <div className="requests__container-item">
            <div className="requests__container-item-user">
              <div className="requests__container-item-img">
                {item.senderId.avatarFileName ? (
                  <img
                    src={`${apiBaseURL}/uploads/avatar/${item.senderId.avatarFileName}`}
                    style={{
                      borderRadius: '100%',
                      maxWidth: '48px',
                      height: '48px',
                      objectFit: 'cover',
                    }}
                  />
                ) : (
                  <div
                    style={{
                      backgroundColor: 'rgba(41, 52, 65, 1)',
                      borderRadius: '100%',
                      width: '40px',
                      height: '40px',
                      color: '#fff',
                      textAlign: 'center',
                      position: 'relative',
                      margin: '5px 0',
                    }}
                  >
                    <span style={{ position: 'absolute', left: '10px', top: '8px' }}>
                      {item.senderId.fullName.slice(0, 2)}
                    </span>
                  </div>
                )}
              </div>
              <div className="requests__container-item-user-text">
                <h5>{item.senderId.fullName}</h5>
                {item.chatId && (
                  <h6>
                    Wants to join <b>{item.chatId.groupName}</b>
                  </h6>
                )}
              </div>
            </div>
            <div className="requests__container-item-btns">
              <button
                className="requests__container-item-btns-accept"
                onClick={() => acceptRequest(item)}
              >
                Accept
              </button>
              <button
                className="requests__container-item-btns-reject"
                onClick={() => rejectRequest(item)}
              >
                Reject
              </button>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
