import { Route, Routes } from 'react-router-dom'
import { Forward } from '../../general-components/messenger/Forward'
import { MessegesChatList } from './MessegesChatList'
import { MessegesChatMessage } from './MessegesChatMessage'
import { MessegesEvents } from './MessegesEvents'
import { MessegesFrinedList } from './MessegesFrinedList'
import { AddGroupName } from './MessegesGroup'
import { MessegesGroupEdit } from './MessegesGroupEdit'
import { MessegesGroupInfo } from './MessegesGroupInfo'
import { MessegesGroupInterests } from './MessegesGroupInterests'
import { MessegesGroupLocation } from './MessegesGroupLocation'
import { MessegesGroups } from './MessegesGroups'
import { MessegesRequests } from './MessegesRequests'
import { MessegesServices } from './MessegesServices'
import { MessegesUserChatList } from './MessegesUserChatList'
import { MessegesGroupMembers } from './MessegesGroupMembers'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { useEffect } from 'react'
import { getUserPublishService } from '../../../redux/categories'
import { UserPublishEvent } from '../../../redux/activities'
import { getUserChats, getUserRequests } from '../../../redux/messanger'
import { MessegesOpenChats } from './MessegesOpenChats'
import { MessegesMap } from './MessegesMap'
import { MessegesSupport } from './MessegesSupport'

export const Messeges = () => {
  const dispatch = useAppDispatch()
  const { _id } = useAppSelector((state) => state.user)
  useEffect(() => {
    dispatch(getUserPublishService({ userId: _id }))
    dispatch(UserPublishEvent({ userId: _id }))
    dispatch(getUserChats({ _id, isSupport: false }))
    dispatch(getUserRequests({ userId: _id }))
  }, [])

  return (
    <div className="user user--body">
      <Routes>
        <Route path="comments-support" element={<MessegesSupport />} />
        <Route path="comments-personal" element={<MessegesChatList />} />
        <Route path="comments-groups" element={<MessegesGroups />} />
        <Route path="comments-services" element={<MessegesServices />} />
        <Route path="comments-activities" element={<MessegesEvents />} />
        <Route path="comments-open-chats" element={<MessegesOpenChats />} />

        <Route path="user-requests" element={<MessegesRequests />} />
        <Route path="friend-user-chat" element={<MessegesFrinedList />} />
        <Route path="all-user-chat" element={<MessegesUserChatList />} />
        <Route path="chat" element={<MessegesChatMessage />} />
        <Route path="forwardpage" element={<Forward />} />
        <Route path="grouppage" element={<AddGroupName />} />
        <Route path="group-edit" element={<MessegesGroupEdit />} />
        <Route path="group-location" element={<MessegesGroupLocation />} />
        <Route path="group-interests" element={<MessegesGroupInterests />} />
        <Route path="groupmembers" element={<MessegesGroupMembers />} />
        <Route path="group-map" element={<MessegesMap />} />
        <Route path="chat/chat-info/*" element={<MessegesGroupInfo />} />
        <Route path="*" element={<MessegesChatList />} />
      </Routes>
    </div>
  )
}
