import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import $api from '../../../http'
import { ChatType } from '../../../types/types'
import { indexPathByRole, apiBaseURL } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconProfileCircleFilled, IconProfileCircle } from '../../svg/IconProfile'
import { InputSearch } from '../../ui/InputSearch'
import { success } from '../../ui/LoadSuccess'
import { Modal } from '../../ui/Modal'

export const ChooseNewAdmin = ({
  setIsOpen,
  isOpen,
  chat,
  setChatList,
  chatList,
}: {
  setIsOpen: (s: boolean) => void
  isOpen: boolean
  chat: ChatType
  setChatList: (s: ChatType[]) => void
  chatList: ChatType[]
}) => {
  const [search, setSearch] = useState('')
  const [ownerId, setOwnerId] = useState('')
  const { _id, role } = useAppSelector((state) => state.user)
  const navigate = useNavigate()
  const validate = !Boolean(ownerId.length)
  const leaveGroup = async () => {
    await $api.post('messenger/leave-group', {
      chatId: chat?.chatId,
      userId: ownerId,
      participants: [
        ...chat.participants
          .filter((it) => it.userId._id !== _id)
          .map((it) => {
            return { userId: it.userId._id }
          }),
      ],
    })
    let arr: ChatType[] = [...chatList.filter((it) => it.chatId !== chat.chatId)]
    setChatList(arr)
    success()
    navigate(`${indexPathByRole(role)}/messeges`)
  }
  return (
    <div
      className={`activities__favor-modal  ${
        isOpen ? 'activities__favor-modal--open' : ''
      }`}
    >
      <Modal className="" setIsOpen={(s: boolean) => setIsOpen(s)}>
        <>
          <div className="publish__modaladd post__modal">
            <h5
              className="post__modal_title"
              style={{
                fontWeight: '600',
                color: 'rgba(21, 16, 77, 1)',
                fontSize: '24px',
              }}
            >
              Choose group owner
            </h5>
            <h6 style={{ color: 'rgba(125, 143, 179, 1)', fontSize: '16px' }}>
              Select a responsible leader for your community group
            </h6>
            <InputSearch
              value={search}
              changeValue={setSearch}
              placeholder={
                <>
                  Search <b>Neib</b>
                </>
              }
            />
            <div style={{ maxHeight: '200px', overflowY: 'scroll' }}>
              {chat?.participants
                .filter((it) =>
                  it.userId.fullName
                    .toLowerCase()
                    .includes(search.replaceAll(' ', '').toLowerCase()),
                )
                .map((item) => (
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                    onClick={() => setOwnerId(item.userId._id)}
                  >
                    <div className="group__info-user">
                      {item.userId.avatarFileName ? (
                        <img
                          style={{ width: '60px', height: '60px', objectFit: 'cover' }}
                          src={`${apiBaseURL}/uploads/avatar/${item.userId.avatarFileName}`}
                        />
                      ) : (
                        <div
                          style={{
                            backgroundColor: 'rgba(41, 52, 65, 1)',
                            borderRadius: '100%',
                            width: '60px',
                            height: '60px',
                            color: '#fff',
                            textAlign: 'center',
                            position: 'relative',
                          }}
                        >
                          <span
                            style={{ position: 'absolute', left: '20px', top: '18px' }}
                          >
                            {item.userId.fullName.slice(0, 2)}
                          </span>
                        </div>
                      )}
                      <div className="group__info-user-data">
                        <h5>{item.userId.fullName}</h5>
                      </div>
                    </div>
                    <div style={{ margin: 'auto 0' }}>
                      {ownerId === item.userId._id ? (
                        <IconProfileCircleFilled />
                      ) : (
                        <IconProfileCircle />
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <button
            disabled={validate}
            style={{ margin: '5%' }}
            className={` explore__modal-button ${validate && 'explore__modal-button-disabled'}`}
            onClick={leaveGroup}
          >
            Choose new owner
          </button>
        </>
      </Modal>
    </div>
  )
}
