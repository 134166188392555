import React from 'react'
import { Button, notification, Space, Tabs, Image, Flex } from 'antd'
import type { TabsProps } from 'antd'
import FormSectionDivider from '../../../ui/new-components/FormSectionDivider'
import { UnderDevelopmentComponent } from '../../../../utils/dev'

export const LatestActivity = () => {
  return (
    <>
      <FormSectionDivider title="Latest Activity" />
      <UnderDevelopmentComponent />
    </>
  )
}
