export const IconVoiceStop = () => {
  return (
    <svg
      style={{ width: '60px', height: '60px', margin: '0px 0' }}
      xmlns="http://www.w3.org/2000/svg"
      xlinkActuate="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 1024 1280"
      enableBackground="new 0 0 1024 1024"
      xmlSpace="preserve"
    >
      <path d="M897.3,349.2c-21.1-49.8-51.2-94.5-89.6-132.9c-38.4-38.4-83.1-68.5-132.9-89.6  C623.2,104.9,568.5,93.8,512,93.8s-111.2,11.1-162.8,32.9c-49.8,21.1-94.5,51.2-132.9,89.6c-38.4,38.4-68.5,83.1-89.6,132.9  C104.9,400.8,93.8,455.6,93.8,512c0,56.5,11,111.2,32.9,162.8c21.1,49.8,51.2,94.5,89.6,132.9c38.4,38.4,83.1,68.5,132.9,89.6  c51.6,21.8,106.3,32.9,162.8,32.9s111.2-11.1,162.8-32.9c49.8-21.1,94.5-51.2,132.9-89.6c38.4-38.4,68.5-83.1,89.6-132.9  c21.8-51.6,32.9-106.3,32.9-162.8C930.2,455.6,919.1,400.8,897.3,349.2z M648.1,621.1c0,14.9-12.1,27-27,27H402.9  c-14.9,0-27-12.1-27-27V402.9c0-14.9,12.1-27,27-27h218.2c14.9,0,27,12.1,27,27V621.1z" />
      <text
        x="0"
        y="1039"
        fill="#000000"
        fontSize="5px"
        fontWeight="bold"
        fontFamily="'Helvetica Neue', Helvetica, Arial-Unicode, Arial, Sans-serif"
      >
        Created by IconPai
      </text>
      <text
        x="0"
        y="1044"
        fill="#000000"
        fontSize="5px"
        fontWeight="bold"
        fontFamily="'Helvetica Neue', Helvetica, Arial-Unicode, Arial, Sans-serif"
      >
        from the Noun Project
      </text>
    </svg>
  )
}
