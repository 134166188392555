import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export interface NominatimSearchResponse {
  place_id: string
  lat: string
  lon: string
  addresstype:
    | 'house'
    | 'street'
    | 'district'
    | 'city'
    | 'county'
    | 'state'
    | 'country'
    | 'locality'
    | 'peak'
  display_name: string
  address: {
    hamlet?: string
    city_district?: string
    municipality?: string
    village?: string
    house_number?: string
    road?: string
    postcode?: string
    town?: string
    city?: string
    county?: string
    state?: string
    country?: string
    country_code?: string
  }
}

export const attemptSearchAddress = createAsyncThunk<
  NominatimSearchResponse[],
  { searchQuarry: string; signal?: AbortSignal }
>('address/search', async ({ searchQuarry, signal }) => {
  if (!searchQuarry) return []
  const response = await axios.get<NominatimSearchResponse[]>(
    process.env.REACT_APP_ADDRESS_SEARCH_URL || 'undefined',
    {
      params: {
        q: searchQuarry,
        format: 'json',
        addressdetails: 1,
        limit: 5,
        layer: 'address',
      },
      signal,
    },
  )
  return response.data
})
