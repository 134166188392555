import { notification } from 'antd'
import { useEffect, useState } from 'react'
import { AuthHttp } from '../../../http/auth'
import { registrationConfirmAccount } from '../../../redux/auth'
import { useAppDispatch } from '../../../utils/hooks'
//TODO: Rahman replace with https://ant.design/components/input#:~:text=With%20formatter%20(Upcase)
import { useTranslation } from 'react-i18next'
import { useAttemptListener } from 'dev-masters-react-kit'
import { CodeInput } from '../../ui/CodeInput'
import { store } from '../../../store'

export const AuthRegistrationConfirmCode = () => {
  const { t } = useTranslation('auth')
  const [code, setCode] = useState('')
  const [seconds, setSeconds] = useState(30)
  const [showResendButton, setShowResendButton] = useState(false)
  const dispatch = useAppDispatch()

  useAttemptListener({
    store,
    attempt: registrationConfirmAccount,
    onRejected: (action) => {
      notification.error({ message: t(action.error.message) })
    },
  })

  useEffect(() => {
    const timer = setTimeout(() => {
      if (seconds > 0 && !showResendButton) {
        setSeconds((prevSeconds) => prevSeconds - 1)
      } else {
        setSeconds(30)
        setShowResendButton(true)
      }
    }, 1000)

    return () => clearTimeout(timer)
  }, [seconds, showResendButton])

  const handleResendClick = async () => {
    await AuthHttp.registrationRegenerateCode()
    setShowResendButton(false)
  }

  const handlerCheckedCode = () => {
    dispatch(
      registrationConfirmAccount({
        code: Number(code),
      }),
    )
  }

  //TODO: refactor this component, replace element by antd components
  // use antd form to handle submit and to get inputs
  // 2 step
  return (
    <div className="forget">
      <div className="forget__phone">
        <h4 className="forget__title">Verification code</h4>
        <h5 className="forget__subtitle">
          Please enter the confirmation code from the received message
        </h5>
        <CodeInput change={setCode} />
        <button className="forget__phone-but" onClick={handlerCheckedCode}>
          Submit
        </button>
        {showResendButton ? (
          <button
            className="forget__phone-but"
            style={{ marginTop: '25px' }}
            onClick={handleResendClick}
          >
            Create New Code
          </button>
        ) : (
          <h6 className="forget__phone-resend">
            Re-send code in <span>00:{seconds.toString().padStart(2, '0')}</span>
          </h6>
        )}
      </div>
    </div>
  )
}
