import { AxiosResponse } from 'axios'
import { useState, useContext } from 'react'
import { SocketContext } from '../../context/SocketContext'
import $api from '../../http'
import { SOCKET_MESSENDER_EVENT } from '../../types/enum'
import { MessageType } from '../../types/types'
import { IconVoiceButton } from '../svg/IconVoiceButton'
import { IconVoiceStop } from '../svg/IconVoiceStop'

export const VoiceButton = (props: {
  updateList: (NewMessage: MessageType) => void
  setIsOpenVoice: (o: boolean) => void
  IsOpenVoice: boolean
  chatId: string
  _id: string
  fullName: string
  avatarFileName: string | null
}) => {
  const [Permission, setPermission] = useState<PermissionState>()
  // const [AudioFile, setAudioFile] = useState<Blob>();
  const { socket } = useContext(SocketContext)
  const [Recorder, setRecorder] = useState<MediaRecorder>()

  let mediaRecorder: any = undefined

  function startRecording() {
    // if(Permission==="granted"){
    let chunks: any[] = []
    props.setIsOpenVoice(true)
    navigator.mediaDevices.getUserMedia({ audio: true, video: false }).then((stream) => {
      const options = { mimeType: 'audio/webm' }
      const optionsIos = { mimeType: 'video/mp4' }
      try {
        mediaRecorder = new MediaRecorder(stream, options)
        setRecorder(mediaRecorder)
        mediaRecorder.addEventListener('dataavailable', (e: any) => {
          chunks.push(e.data)
        })
        mediaRecorder.addEventListener('stop', () => {
          sendData(new Blob(chunks, { type: options.mimeType }))
          console.log(new Blob(chunks, { type: options.mimeType }))
          stream.getTracks().forEach((t) => {
            t.stop()
          })
        })
        mediaRecorder.start(1000)
      } catch (error) {
        mediaRecorder = new MediaRecorder(stream, optionsIos)
        setRecorder(mediaRecorder)
        mediaRecorder.addEventListener('dataavailable', (e: any) => {
          chunks.push(e.data)
        })
        mediaRecorder.addEventListener('stop', () => {
          sendData(new Blob(chunks, { type: optionsIos.mimeType }))
          console.log(new Blob(chunks, { type: optionsIos.mimeType }))
          stream.getTracks().forEach((t) => {
            t.stop()
          })
        })
        mediaRecorder.start(1000)
      }
    })
    // }
  }
  async function sendData(AudioFile: Blob) {
    let fileName: null | string = null
    if (AudioFile) {
      const formData = new FormData()

      // const file = new File([AudioFile], 'webm.webm');

      formData.append('file', AudioFile)
      const res: AxiosResponse<string> = await $api.post(
        'messenger/file-message',
        formData,
      )
      fileName = res.data
    }
    if (socket) {
      socket.current?.emit(SOCKET_MESSENDER_EVENT.SEND_PRIVATE_VOICE_MESSAGE, {
        chatId: props.chatId,
        senderId: props._id,
        timestamp: new Date(),
        isRead: true,
        file: fileName,
      })
      if (fileName) {
        props.updateList({
          chatId: props.chatId,
          senderId: {
            _id: props._id,
            fullName: props.fullName,
            avatarFileName: props.avatarFileName,
          },
          content: '',
          timestamp: new Date(),
          isRead: true,
          file: fileName,
          forward: false,
          senderIdold: '',
          audio: true,
          like: '',
          messageId: null,
          _id: '',
        })
      }
    }
    // console.log(AudioFile)
  }
  function stopRecording() {
    if (Recorder) Recorder.stop()
    props.setIsOpenVoice(false)
  }

  navigator.permissions
    .query({ name: 'microphone' as PermissionName })
    .then(function (res) {
      setPermission(res.state)
      res.onchange = function (result) {
        if (result.target) {
          setPermission((result.target as PermissionStatus).state)
        }
      }
    })

  if (!props.IsOpenVoice) {
    return (
      <div className="voice__message__button__wrapper" onClick={startRecording}>
        <IconVoiceButton className="voice__message__button__img " />
      </div>
    )
  } else {
    return (
      <div className="voice__message__button__wrapper__recording" onClick={stopRecording}>
        <IconVoiceStop />
      </div>
    )
  }
}
