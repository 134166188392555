import { useEffect } from 'react'
import { Button, message, notification } from 'antd'
import { IoCloseCircleOutline } from 'react-icons/io5'
import { useAppSelector } from '../hooks'
import { useNavigate } from 'react-router-dom'
import { TbSettingsShare } from 'react-icons/tb'
import useNextSetUpProfilePath from './useNextSetUpProfilePath'

export default function useCompleteProfileNotification() {
  const navigate = useNavigate()

  const nextStep = useNextSetUpProfilePath()
  useEffect(() => {
    nextStep &&
      notification.open({
        message: (
          <div className="message-container">
            <span className="message-text">Complete your profile </span>
            <TbSettingsShare
              size={30}
              className="button"
              onClick={() => {
                console.log('nextStep:', nextStep)

                notification.destroy(`complete_profile_${nextStep}`)
                navigate('/profile/' + nextStep)
              }}
            />
            <IoCloseCircleOutline
              className="button"
              size={30}
              onClick={() => notification.destroy(`complete_profile_${nextStep}`)}
            />
          </div>
        ),
        closeIcon: null,
        placement: 'bottom',
        duration: 8,
        showProgress: true,
        pauseOnHover: true,
        className: 'complete_profile_toaster',
        key: `complete_profile_${nextStep}`,
      })
  }, [nextStep])
}
