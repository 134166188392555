export const IconLink = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.909 2.90901C12.7877 2.03033 14.2123 2.03033 15.091 2.90901C15.9697 3.78769 15.9697 5.21231 15.091 6.09099L11.716 9.46599C10.8373 10.3447 9.41268 10.3447 8.534 9.46599C8.09466 9.02665 7.38235 9.02665 6.94301 9.46599C6.50367 9.90533 6.50367 10.6176 6.94301 11.057C8.70037 12.8143 11.5496 12.8143 13.307 11.057L16.682 7.68198C18.4393 5.92462 18.4393 3.07538 16.682 1.31802C14.9246 -0.43934 12.0754 -0.43934 10.318 1.31802L8.63051 3.00552C8.19117 3.44486 8.19117 4.15717 8.63051 4.59651C9.06985 5.03585 9.78216 5.03585 10.2215 4.59651L11.909 2.90901Z"
        fill="#C3CAD9"
      />
      <path
        d="M6.28402 8.53401C7.1627 7.65533 8.58732 7.65533 9.466 8.53401C9.90534 8.97335 10.6177 8.97335 11.057 8.53401C11.4963 8.09467 11.4963 7.38236 11.057 6.94302C9.29963 5.18566 6.45039 5.18566 4.69302 6.94302L1.31802 10.318C-0.43934 12.0754 -0.43934 14.9246 1.31802 16.682C3.07538 18.4393 5.92463 18.4393 7.68199 16.682L9.36949 14.9945C9.80883 14.5551 9.80883 13.8428 9.36949 13.4035C8.93015 12.9641 8.21784 12.9641 7.7785 13.4035L6.091 15.091C5.21232 15.9697 3.78769 15.9697 2.90901 15.091C2.03033 14.2123 2.03033 12.7877 2.90901 11.909L6.28402 8.53401Z"
        fill="#C3CAD9"
      />
    </svg>
  )
}
