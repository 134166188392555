import { AxiosResponse } from 'axios'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import $api from '../../../http'
import { ChatType, ParticipantType, MessageType } from '../../../types/types'
import { indexPathByRole, apiBaseURL } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconMute } from '../../svg/IconMute'
import { IconNotification } from '../../svg/IconNotification'
import { IconPostModalPin } from '../../svg/IconPostModal'
import { success } from '../../ui/LoadSuccess'
import { Modal } from '../../ui/Modal'

export const ChatModalGlobal = ({
  isOpen,
  setIsOpen,
  item,
  setIsOpenAdmin,
  setChatList,
  chatList,
}: {
  isOpen: boolean
  setIsOpen: (s: boolean) => void
  item: ChatType
  setIsOpenAdmin: (s: boolean) => void
  setChatList: (s: ChatType[]) => void
  chatList: ChatType[]
}) => {
  const [user, setUser] = useState<ParticipantType>()
  const { _id, role } = useAppSelector((state) => state.user)
  const navigate = useNavigate()

  useEffect(() => {
    if (item.participants.length < 3) {
      let arr: ParticipantType[] = [...item.participants]
      arr.map((it) => {
        if (it.userId._id !== _id) {
          setUser(it)
        }
      })
    }
  }, [])
  const deleteGroup = async () => {
    await $api.post('messenger/delete-group', {
      chatId: item?.chatId,
    })
    let arr: ChatType[] = [...chatList.filter((it) => it.chatId !== item.chatId)]
    setChatList(arr)
    success()
    navigate(`${indexPathByRole(role)}/messeges`)
  }
  const leaveGroup = async () => {
    if (item.participants.length > 2) {
      if (item.groupOwner !== _id) {
        await $api.post('messenger/leave-group', {
          chatId: item?.chatId,
          participants: [
            ...item.participants
              .filter((it) => it.userId._id !== _id)
              .map((it) => {
                return { userId: it.userId._id }
              }),
          ],
        })
        let arr: ChatType[] = [...chatList.filter((it) => it.chatId !== item.chatId)]
        setChatList(arr)
        setIsOpen(false)
        success()
        navigate(`${indexPathByRole(role)}/messeges`)
      } else {
        setIsOpenAdmin(true)
        setIsOpen(false)
      }
    } else {
      deleteGroup()
      setIsOpen(false)
    }
  }
  const [notification, setNotification] = useState(true)
  const updateNotification = async () => {
    await $api.post('messenger/update-notification', {
      chatId: item?.chatId,
      userId: _id,
      isNotificated: item?.isNotificated,
    })
    setNotification(!notification)
    setIsOpen(false)
  }
  const markAsRead = async () => {
    const resMessageList: AxiosResponse<MessageType[]> = await $api.post(
      'messenger/list-message',
      {
        chatId: item?.chatId,
      },
    )

    resMessageList.data.forEach((item) => {
      if (!item.isRead && item.senderId._id !== _id) {
        $api.post('messenger/read-message', { messageId: item?._id })
      }
    })

    let chats: ChatType[] = []
    chatList.map((it) => {
      if (it.chatId === item.chatId) {
        chats.push({ ...item, notReadingMessage: [] })
      } else {
        chats.push(it)
      }
    })
    setChatList(chats)
    setIsOpen(false)
  }

  const updatePin = async () => {
    await $api.post('messenger/pin-chat', {
      chatId: item?.chatId,
      userId: _id,
    })
    let array = [...chatList]
    array.splice(chatList.indexOf(item))
    array.push({ ...item, isPined: !item.isPined })
    setChatList(array)
    setIsOpen(false)
  }

  return (
    <>
      <div
        className={`activities__favor-modal  ${
          isOpen ? 'activities__favor-modal--open ' : ''
        }`}
      >
        <Modal setIsOpen={setIsOpen} className={''}>
          <div
            onClick={(e) => {
              e.stopPropagation()
              setIsOpen(false)
            }}
            className="activities__favor-modal-linebody"
          >
            <button className="activities__favor-modal-line" />
          </div>
          {user && (
            <div style={{ margin: '10px' }} className="delete__modal-container">
              <img
                style={{ objectFit: 'cover', width: '40px', height: '40px' }}
                src={
                  user.userId.avatarFileName
                    ? `${apiBaseURL}/uploads/avatar/${user.userId.avatarFileName}`
                    : '/Images/Profile.jpg'
                }
                alt=""
              />
              <h5>
                Chat with <b>{user.userId.fullName}</b>
              </h5>
            </div>
          )}
          {!user && (
            <div style={{ margin: '10px' }} className="delete__modal-container">
              <img
                style={{ objectFit: 'cover', width: '40px', height: '40px' }}
                src={
                  item.groupImage
                    ? `${apiBaseURL}/uploads/messenger/${item.groupImage}`
                    : '/Images/postsBG.png'
                }
                alt=""
              />
              <h5>
                Chat in <b>{item.name}</b>
              </h5>
            </div>
          )}
          <div className="post__modal_itembg">
            <div
              className="post__modal_item"
              onClick={() => {
                markAsRead()
                let chats = [...chatList, { ...item, notReadingMessage: [] }]
                console.log(chats)
                setChatList(chats)
              }}
            >
              <div style={{ padding: '0 0px' }}>
                <IconNotification fill={'rgba(195, 202, 217, 1)'} />
              </div>
              <div>
                <h5 className="post__modal_title">Mark as read</h5>
              </div>
            </div>
            <div className="post__modal_item" onClick={updatePin}>
              <div style={{ padding: '0 0px' }}>
                <IconPostModalPin fill="rgba(195, 202, 217, 1)" />
              </div>
              <div>
                <h5 className="post__modal_title">Pin</h5>
              </div>
            </div>
            <div className="post__modal_item" onClick={updateNotification}>
              <div style={{ padding: '0 0px' }}>
                <img src="/Images/BoxComment.png" />
              </div>
              <div>
                <h5 className="post__modal_title">Mute</h5>
              </div>
            </div>
            <div className="post__modal_item" onClick={leaveGroup}>
              <div style={{ padding: '0 0px' }}>
                <IconMute />
              </div>
              <div>
                <h5 className="post__modal_title">Delete</h5>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  )
}
