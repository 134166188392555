export const ProfileInfoPrivacyPolicy = () => {
  return (
    <>
      <h5 className="profileinfo__edit-title " style={{ marginTop: '20px' }}>
        Privacy Policy
      </h5>
      <div className="profileinfo__edit-body-items">
        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sequi ipsum
          asperiores, provident quos saepe est vel laborum iusto similique eligendi
          dolorem quia voluptatum iure ea error ipsam maxime, accusamus dignissimos.
        </p>
        <p>
          {' '}
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum nostrum quas velit
          natus illo vero corporis quasi consectetur placeat culpa, fuga quo odit odio vel
          corrupti deleniti, quod quaerat? Doloremque?
        </p>
        <p>
          {' '}
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum nostrum quas velit
          natus illo vero corporis quasi consectetur placeat culpa, fuga quo odit odio vel
          corrupti deleniti, quod quaerat? Doloremque?
        </p>
        <p>
          {' '}
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum nostrum quas velit
          natus illo vero corporis quasi consectetur placeat culpa, fuga quo odit odio vel
          corrupti deleniti, quod quaerat? Doloremque?
        </p>
      </div>
    </>
  )
}
