import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import $api from '../../../http'
import { UserHttp } from '../../../http/user-http'
import { ROLE } from '../../../types/enum'
import { ChatType, HeaderMessageType } from '../../../types/types'
import { indexPathByRole, apiBaseURL } from '../../route/indexPathByRole'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { IconLeftChevrons } from '../../svg/IconChevrons'
import { IconInputSearch } from '../../svg/IconInputSearch'
import { IconLeave } from '../../svg/IconLeave'
import { IconNotification } from '../../svg/IconNotification'
import { IconNeibs } from '../../svg/IconNeibs'
import { IconServicesAllPoint } from '../../svg/IconServicesAll'
import { DarkBg } from '../../ui/DarkBg'
import { success } from '../../ui/LoadSuccess'
import { UserHeader } from '../header/UserHeader'
import { AddNeibsModal } from './AddNeibsModal'
import { ChooseNewAdminModal } from './ChooseNewAdminModal'
import { LeaveModalAdmin } from './LeaveModalAdmin'
import { LeaveModalUser } from './LeaveModalUser'
import { getUserChats } from '../../../redux/messanger'

export const MessegesGroupInfoMain = ({
  setIsOpen,
}: {
  setIsOpen: (s: boolean) => void
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const [chat, setChat] = useState<ChatType>()
  const { avatarFileName, fullName } = useAppSelector((state) => state.user)
  const [adminLeave, setAdminLeave] = useState(false)
  const [addNeibs, setAddNeibs] = useState(false)
  useEffect(() => {
    if (location.state.chat) {
      setChat(location.state.chat)
    }
  }, [])
  const [user, setUser] = useState<HeaderMessageType>()
  useEffect(() => {
    if (chat) {
      if (!chat.participants[0].userId._id) {
        const userId = chat.participants[0].userId
        if (userId) {
          UserHttp.getUserById({ userId }).then((s) => setUser(s))
        }
      } else {
        const userId = chat.participants[0].userId._id
        if (userId) {
          UserHttp.getUserById({ userId }).then((s) => setUser(s))
        }
      }
    }
  }, [chat])

  const { role, _id } = useAppSelector((state) => state.user)

  const toMedia = () => {
    navigate(`${indexPathByRole(role)}/messeges/chat/chat-info/media`, {
      state: {
        chat: location.state.chat,
      },
    })
  }
  const [IsOpenAdmin, setIsOpenAdmin] = useState(false)
  const [IsOpenUser, setIsOpenUser] = useState(false)

  const leaveGroup = async () => {
    if (chat)
      await $api.post('messenger/leave-group', {
        chatId: chat?.chatId,
        participants: [
          ...chat.participants
            .filter((it) => it.userId._id !== _id)
            .map((it) => {
              return { userId: it.userId._id }
            }),
        ],
      })
    success()
    dispatch(getUserChats({ _id, isSupport: false }))
    navigate(`${indexPathByRole(role)}/messeges`)
  }
  const deleteGroup = async () => {
    await $api.post('messenger/delete-group', {
      chatId: chat?.chatId,
    })
    success()
    dispatch(getUserChats({ _id, isSupport: false }))
    navigate(`${indexPathByRole(role)}/messeges`)
  }
  const reportGroup = async () => {
    await $api.post('messenger/report-group', {
      chatId: chat?.chatId,
      userId: _id,
    })
    success()
    setIsOpenAdmin(false)
    setIsOpenUser(false)
  }
  const [notification, setNotification] = useState(true)
  const updateNotification = async () => {
    await $api.post('messenger/update-notification', {
      chatId: chat?.chatId,
      userId: _id,
      isNotificated: chat?.isNotificated,
    })
    setNotification(!notification)
  }
  const valid = () => {
    if (chat) {
      return chat.participants.length > 2
    } else {
      return false
    }
  }

  const toProfileInfo = (prop: {
    _id: string
    email: string
    role: string
    fullName: string
    avatarFileName: string
  }) => {
    navigate('/profileinfo', {
      state: prop,
    })
  }
  return (
    <div>
      <UserHeader>
        <div
          className="user__header-messages"
          style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
        >
          {chat && (
            <div style={{ display: 'flex', gap: '10px' }}>
              <button
                onClick={() =>
                  navigate(
                    `${indexPathByRole(role)}/messeges/chat?chatId=${chat.chatId}`,
                    {
                      state: { chat },
                    },
                  )
                }
                className="user__header-chatuser user__header-messages-exit"
              >
                <IconLeftChevrons />
              </button>
              <div className="user__header-messages-img">
                {chat?.participants.length > 2 ? (
                  <img
                    src={
                      chat.groupImage
                        ? `${apiBaseURL}/uploads/messenger/${chat.groupImage}`
                        : '/Images/postsBG.png'
                    }
                    alt=""
                  />
                ) : (
                  <>
                    {user?.avatarFileName ? (
                      <img src={`${apiBaseURL}/uploads/avatar/${user?.avatarFileName}`} />
                    ) : (
                      <div
                        style={{
                          backgroundColor: 'rgba(41, 52, 65, 1)',
                          borderRadius: '100%',
                          width: '100%',
                          height: '100%',
                          color: '#fff',
                          textAlign: 'center',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        <span style={{ margin: 'auto', color: '#fff', fontSize: '14px' }}>
                          {user?.fullName.slice(0, 2)}
                        </span>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div>
                {chat.name ? (
                  <div
                    className="user__header-messages-name"
                    style={{ margin: '10px 0' }}
                  >
                    {chat.name.length > 25 ? chat.name.slice(0, 25) + '...' : chat.name}
                  </div>
                ) : (
                  <>
                    {user && (
                      <div
                        className="user__header-messages-name"
                        style={{ margin: '10px 0' }}
                      >
                        {user.fullName.length > 25
                          ? chat.name.slice(0, 25) + '...'
                          : user.fullName}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
          <button
            onClick={() => {
              console.log(chat)
              if (chat?.groupOwner === _id) {
                setIsOpenAdmin(true)
              } else {
                setIsOpenUser(true)
              }
            }}
          >
            <IconServicesAllPoint />
          </button>
        </div>
      </UserHeader>
      <div className="group__info-content">
        <div className="group__info-content__main">
          <div className="group__info-content__main-img">
            {chat?.name ? (
              <>
                {chat.groupImage ? (
                  <img
                    src={`${apiBaseURL}/uploads/messenger/${chat.groupImage}`}
                    alt={chat.name}
                  />
                ) : (
                  <div className="group__info-content__main-img-fake">
                    {chat.name.charAt(0).toUpperCase()}
                  </div>
                )}
              </>
            ) : (
              <img
                src={
                  user?.avatarFileName
                    ? `${apiBaseURL}/uploads/avatar/${user?.avatarFileName}`
                    : '/Images/Profile.jpg'
                }
                alt={user?.fullName}
              />
            )}
          </div>
        </div>
        <div className="group__info-content-header">
          {chat && user && (
            <h5 className="group__info-content-title">
              <b>
                {chat?.name
                  ? chat.name.length > 25
                    ? chat.name.slice(0, 25) + '...'
                    : chat.name
                  : user.fullName.length > 25
                    ? user.fullName.slice(0, 25) + '...'
                    : user.fullName}
              </b>
            </h5>
          )}
          <span className="group__info-content-neibs">
            {chat?.participants.length && chat?.participants.length + 1} neibs
          </span>
        </div>
        {chat && (
          <div className="group__info-content__buttons">
            <div
              className="group__info-content__buttons-item"
              onClick={updateNotification}
            >
              <IconNotification
                fill={!notification ? 'rgb(36, 107, 253)' : 'rgba(15, 20, 25, 1)'}
              />
              Notifications
            </div>
            <div
              className="group__info-content__buttons-item"
              onClick={() => {
                toMedia()
                setIsOpen(true)
              }}
            >
              <IconInputSearch />
              Search
            </div>
            {chat.groupOwner === _id ? (
              <div
                className="group__info-content__buttons-item"
                onClick={(e) => {
                  e.preventDefault()
                  setAdminLeave(true)
                  setIsOpen(false)
                }}
              >
                <IconLeave />
                Leave
              </div>
            ) : (
              <div className="group__info-content__buttons-item" onClick={leaveGroup}>
                <IconLeave />
                Leave
              </div>
            )}
          </div>
        )}
        <div style={{ margin: '10px 0' }} />
        {chat?.groupDescription && (
          <div className="group__info-content__description">
            <b>
              <span>Description</span>
            </b>
            <h6>{chat.groupDescription}</h6>
          </div>
        )}
        <div className="group__info-content__neibs">
          {
            <div
              className="group__info-content__neibs-btn"
              onClick={() => setAddNeibs(true)}
            >
              <IconNeibs filling="rgba(36, 107, 253, 1)" />
              Add neibs
            </div>
          }
          {chat && chat?.participants?.length > 2 ? (
            chat?.participants.map((item) => (
              <div
                className="group__info-user"
                onClick={() =>
                  toProfileInfo({
                    _id: item?.userId?._id || '',
                    email: '',
                    role: ROLE.USER,
                    fullName: item?.userId?.fullName || '',
                    avatarFileName: item?.userId?.avatarFileName || '',
                  })
                }
              >
                {item?.userId?.avatarFileName ? (
                  <img
                    src={`${apiBaseURL}/uploads/avatar/${item?.userId?.avatarFileName}`}
                  />
                ) : (
                  <div
                    style={{
                      backgroundColor: 'rgba(41, 52, 65, 1)',
                      borderRadius: '100%',
                      width: '40px',
                      height: '40px',
                      color: '#fff',
                      textAlign: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <span style={{ margin: 'auto', color: '#fff', fontSize: '14px' }}>
                      {item?.userId?.fullName?.slice(0, 2)}
                    </span>
                  </div>
                )}
                <div style={{ margin: '8px 0' }} className="group__info-user-data">
                  <h5>{item.userId.fullName}</h5>
                </div>
              </div>
            ))
          ) : (
            <>
              {chat?.participants[0].userId._id && (
                <>
                  <div
                    className="group__info-user"
                    onClick={() =>
                      toProfileInfo({
                        _id: user?._id || '',
                        email: '',
                        role: ROLE.USER,
                        fullName: user?.fullName || '',
                        avatarFileName: user?.avatarFileName || '',
                      })
                    }
                  >
                    {user?.avatarFileName ? (
                      <img src={`${apiBaseURL}/uploads/avatar/${user?.avatarFileName}`} />
                    ) : (
                      <div
                        style={{
                          backgroundColor: 'rgba(41, 52, 65, 1)',
                          borderRadius: '100%',
                          width: '40px',
                          height: '40px',
                          color: '#fff',
                          textAlign: 'center',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        <span style={{ margin: 'auto', color: '#fff', fontSize: '14px' }}>
                          {user?.fullName.slice(0, 2)}
                        </span>
                      </div>
                    )}
                    <div className="group__info-user-data">
                      <h5>{user?.fullName}</h5>
                    </div>
                  </div>
                  <div
                    className="group__info-user"
                    onClick={() =>
                      toProfileInfo({
                        _id: _id || '',
                        email: '',
                        role: ROLE.USER,
                        fullName: fullName || '',
                        avatarFileName: avatarFileName || '',
                      })
                    }
                  >
                    {avatarFileName ? (
                      <img src={`${apiBaseURL}/uploads/avatar/${avatarFileName}`} />
                    ) : (
                      <div
                        style={{
                          backgroundColor: 'rgba(41, 52, 65, 1)',
                          borderRadius: '100%',
                          width: '40px',
                          height: '40px',
                          color: '#fff',
                          textAlign: 'center',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        <span style={{ margin: 'auto', color: '#fff', fontSize: '14px' }}>
                          {fullName.slice(0, 2)}
                        </span>
                      </div>
                    )}
                    <div className="group__info-user-data">
                      <h5>{fullName}</h5>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          {chat &&
            chat?.participants.length > 1 &&
            !chat?.participants.find((it) => it.userId._id === _id) && (
              <div
                className="group__info-user"
                onClick={() =>
                  toProfileInfo({
                    _id: _id || '',
                    email: '',
                    role: ROLE.USER,
                    fullName: fullName || '',
                    avatarFileName: avatarFileName || '',
                  })
                }
              >
                {avatarFileName ? (
                  <img src={`${apiBaseURL}/uploads/avatar/${avatarFileName}`} />
                ) : (
                  <div
                    style={{
                      backgroundColor: 'rgba(41, 52, 65, 1)',
                      borderRadius: '100%',
                      width: '40px',
                      height: '40px',
                      color: '#fff',
                      textAlign: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <span style={{ margin: 'auto', color: '#fff', fontSize: '14px' }}>
                      {fullName.slice(0, 2)}
                    </span>
                  </div>
                )}
                <div className="group__info-user-data">
                  <h5>{fullName}</h5>
                </div>
              </div>
            )}
        </div>
      </div>
      {IsOpenAdmin && chat && (
        <LeaveModalAdmin
          setIsOpen={setIsOpenAdmin}
          isOpen={IsOpenAdmin}
          reportGroup={reportGroup}
          deleteGroup={deleteGroup}
          setAdminLeave={setAdminLeave}
          chat={chat}
        />
      )}
      {adminLeave && chat && (
        <ChooseNewAdminModal setIsOpen={setAdminLeave} isOpen={adminLeave} chat={chat} />
      )}
      {addNeibs && chat && (
        <AddNeibsModal
          setIsOpen={setAddNeibs}
          isOpen={addNeibs}
          setChat={setChat}
          chat={chat}
        />
      )}
      {chat && (
        <LeaveModalUser
          setIsOpen={setIsOpenUser}
          isOpen={IsOpenUser}
          reportGroup={reportGroup}
          leaveGroup={leaveGroup}
        />
      )}
      {(IsOpenAdmin || IsOpenUser || addNeibs) && chat && <DarkBg />}
    </div>
  )
}
