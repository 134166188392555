import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  PostUserInterface,
  PublishServiceItemInterface,
  PublishEventItemInterface,
  CoordinatsInterface,
} from '../../../types/types'
import { indexPathByRole, apiBaseURL } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { PublicationPostsPanel } from '../../general-components/publication-lists/PublicationPostsPanel'
import { Modal } from '../../ui/Modal'

export const ExploreModal = ({
  postItem,
  serviceItem,
  eventItem,
  setIsOpen,
  isOpen,
  updateLike,
  updateMark,
  updateRepost,
  setIt,
}: {
  setIsOpen: (s: boolean) => void
  isOpen: boolean
  postItem?: PostUserInterface | null
  serviceItem?: PublishServiceItemInterface | null
  eventItem?: PublishEventItemInterface | null
  updateLike: (likeId: string, postId: string) => void
  updateMark: (postId: string, isMarked: boolean) => void
  updateRepost: ({ postId, isReposted }: { postId: string; isReposted: boolean }) => void
  setIt: (s: CoordinatsInterface) => void
}) => {
  const [type, setType] = useState<string>('')
  const [isOpenMark, setIsOpenMark] = useState<boolean>(false)
  const [anyType, setAnyType] = useState<
    PostUserInterface | PublishServiceItemInterface | PublishEventItemInterface
  >()
  const { _id, role } = useAppSelector((state) => state.user)
  const navigate = useNavigate()
  useEffect(() => {
    if (eventItem) {
      setType('publish_activities')
      setAnyType(eventItem)
    }
    if (serviceItem) {
      setType('publish_services')
      setAnyType(serviceItem)
    }
    if (postItem) {
      setType('publish_post')
      setAnyType(postItem)
    }
  }, [])

  const navigateToComments = (postId: string) => {
    navigate(`${indexPathByRole(role)}/comments?postId=${postId}`)
  }

  const toPublishActivities = (_id: string) => {
    navigate(`/user/activities/publish-activities-item?publishActivitiesId=${_id}`)
  }

  const toPublishService = (_id: string) => {
    navigate(`/user/service/publish-service?publishServiceId=${_id}`)
  }

  useEffect(() => {
    if (eventItem) {
      setIt(eventItem.coordinates)
    }
  }, [eventItem])
  useEffect(() => {
    if (postItem) {
      setIt(postItem.coordinates)
    }
  }, [postItem])
  useEffect(() => {
    if (serviceItem) {
      setIt(serviceItem.coordinates)
    }
  }, [serviceItem])

  return (
    <Modal className="explore__modal" setIsOpen={setIsOpen}>
      <div
        className="explore__modal-content"
        onMouseDown={(e) => {
          if (eventItem) {
            toPublishActivities(eventItem?._id)
          }
          if (serviceItem) {
            toPublishService(serviceItem?._id)
          }
          if (postItem) {
            navigateToComments(postItem?._id)
          }
        }}
      >
        <div className="explore__modal-img">
          {anyType?.filesName.length ? (
            <img
              style={{
                backgroundColor: '#fff',
                objectFit: 'cover',
                width: '80px',
                height: '80px',
              }}
              alt={anyType?.title}
              src={`${apiBaseURL}/uploads/${type}/${anyType?.filesName[0]}`}
            />
          ) : (
            <img alt={anyType?.title} className="posts__bg" src="/Images/postsBG.png" />
          )}
        </div>
        <h5 className="explore__modal-title">{anyType?.title}</h5>
        {type === 'publish_services' ? (
          <span
            className="explore__modal-text"
            style={{ fontWeight: '600', textTransform: 'capitalize' }}
          >
            {`${serviceItem?.categoryId.name} | ${serviceItem?.subCategoryId.name}`}
          </span>
        ) : (
          <span className="explore__modal-location">{anyType?.addressLocation}</span>
        )}
        <h6 className="explore__modal-text">{anyType?.text}</h6>
        <div className="explore__modal-last">
          {eventItem && (
            <div
              className="explore__modal-button"
              onClick={() => toPublishActivities(eventItem?._id)}
            >
              {`${eventItem?.startDate.toString().split('T')[0]}`}{' '}
              <b>{`${eventItem?.startDate.toString().split('T')[1].split('.')[0]}`}</b>
            </div>
          )}
          {postItem && (
            <div className="explore__modal-post-menu">
              <PublicationPostsPanel
                item={postItem}
                updateLike={updateLike}
                navigateToComments={navigateToComments}
                updateMark={updateMark}
                updateRepost={updateRepost}
                isOpen={isOpenMark}
                setIsOpen={setIsOpenMark}
                down={true}
              />
            </div>
          )}
          {serviceItem && (
            <div
              className="explore__modal-button"
              onClick={() => toPublishService(serviceItem?._id)}
            >{`${serviceItem?.addressLocation}`}</div>
          )}
        </div>
      </div>
    </Modal>
  )
}
