import { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import { allActivities, getTenPublishActivities } from '../../../redux/activities'
import { allCategories, getTenPublishService } from '../../../redux/categories'
import { getPosts, getTenPosts } from '../../../redux/posts'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import './../../../style/user/explore.scss'
import { ExploreChats } from './ExploreChats'
import { ExploreCity } from './ExploreCity'
import { ExploreDistrict } from './ExploreDistrict'
import { ExploreInvite } from './ExploreInvite'
import { ExploreInvites } from './ExploreInvites'
import { ExploreLocation } from './ExploreLocation'
import { ExploreMain } from './ExploreMain'
import { ExploreMap } from './ExploreMap'
import { ExploreMapCountry } from './ExploreMapCountry'
import { ExploreSearch } from './ExploreSearch'
import { ExploreSearchMain } from './ExploreSearchMain'
import { setLoader } from '../../../redux/posts'

export const Explore = () => {
  const dispatch = useAppDispatch()
  const { _id } = useAppSelector((state) => state.user)
  const [load, setLoad] = useState(false)

  useEffect(() => {
    setLoad(true)
    dispatch(getTenPublishActivities({ userId: _id }))
    dispatch(getTenPublishService())
    dispatch(getTenPosts())
    dispatch(allActivities())
    dispatch(allCategories())
    dispatch(setLoader(true))
    setLoad(false)
  }, [])
  return (
    <Routes>
      <Route index element={<ExploreMain />} />
      <Route path="map-country" element={<ExploreMapCountry />} />
      <Route path="map-chats" element={<ExploreChats />} />
      <Route path="map-invites" element={<ExploreInvites />} />
      <Route path="invites" element={<ExploreInvite />} />
      <Route path="district/*" element={<ExploreDistrict />} />
      <Route path="map-city" element={<ExploreCity />} />
      <Route path="map" element={<ExploreMap />} />
      <Route path="search" element={<ExploreSearchMain />} />
      <Route path="search-find/*" element={<ExploreSearch />} />
      <Route path="location/*" element={<ExploreLocation />} />
    </Routes>
  )
}
