import { TextareaAutosize } from '@mui/material'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AdvertisementHttp } from '../../../http/advertisement'
import { UserHttp } from '../../../http/user-http'
import { UserInitialStateInterface } from '../../../redux/users'
import { ROLE } from '../../../types/enum'
import { indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconLeftChevrons } from '../../svg/IconChevrons'
import { IconLink } from '../../svg/IconLink'
import { InputSearch } from '../../ui/InputSearch'
import { success } from '../../ui/LoadSuccess'
import { AdvertisementStepper } from './AdvertisementStepper'

export const AdvertisementAddText = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { isLoad, _id, email, role, fullName, phone, avatarFileName } = useAppSelector(
    (state) => state.user,
  )
  const [search, setSearch] = useState<string>('')
  const [users, setUsers] = useState<UserInitialStateInterface[]>([])
  const [usersNormal, setUsersNormal] = useState<UserInitialStateInterface[]>([])
  const [ownerId, setOwnerId] = useState<UserInitialStateInterface | null>()
  const [title, setTitle] = useState<string>('')
  const [text, setText] = useState<string>('')
  const [Url, setUrl] = useState('')
  const [adId, setAdId] = useState<string>()
  function isValidURL(str: string) {
    if (!str) {
      return true
    } else {
      let res = str.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
      )
      return res !== null
    }
  }
  const validate = !Boolean(isValidURL(Url) && text && title && ownerId)
  useEffect(() => {
    if (role === ROLE.USER) {
      setOwnerId({
        isLoad,
        _id,
        email,
        role,
        fullName,
        phone,
        avatarFileName,
      })
    }
  }, [])

  useEffect(() => {
    const effect = async () => {
      const res = await UserHttp.getAllUsers()
      setUsers(res)
      setAdId(location.state.adId)
    }
    effect()
  }, [])
  useEffect(() => {
    let array: UserInitialStateInterface[] = []
    if (search.replace('@', '').length && !ownerId) {
      users.map((item) => {
        if (item.fullName.toLowerCase().includes(search.replace('@', '').toLowerCase())) {
          array.push(item)
        }
      })
    }
    setUsersNormal(array)
  }, [search])
  const addText = async () => {
    console.log(ownerId, adId)
    if (ownerId && adId) {
      await AdvertisementHttp.updateText({
        adId,
        text,
        title,
        ownerId: ownerId._id,
        link: Url,
      })
      success()
      navigate(`${indexPathByRole(role)}/advertisement/add-community-selection`, {
        state: { adId },
      })
    }
  }
  return (
    <div className="advertisement__add">
      <div className="advertisement__add-header">
        <div className="advertisement__add-header-btn" onClick={() => navigate(-2)}>
          <IconLeftChevrons fill="rgba(21, 16, 77, 1)" />
          <span style={{ textTransform: 'capitalize' }}>
            Create {location.state.page}
          </span>
        </div>
      </div>
      <AdvertisementStepper i={1} />
      <div className="advertisement__add-main">
        {role === ROLE.USER && (
          <>
            <h5 className="advertisement__add-main-title">Advertiser:</h5>
            <span style={{ color: 'rgba(51, 97, 255, 1)' }}>@{fullName}</span>
          </>
        )}
        {role === ROLE.ADMIN && (
          <>
            <h5 className="advertisement__add-main-title">
              Advertiser:{' '}
              {ownerId ? (
                <span
                  onClick={() => setOwnerId(null)}
                  style={{ color: 'rgba(51, 97, 255, 1)' }}
                >
                  @{ownerId.fullName}
                </span>
              ) : (
                ''
              )}
            </h5>
            {!ownerId && (
              <>
                <InputSearch
                  value={search}
                  changeValue={setSearch}
                  placeholder={'@ Username'}
                />
                <div className="advertisement__add-main-results">
                  {usersNormal.map((item) => (
                    <span
                      onClick={() => {
                        setOwnerId(item)
                        setSearch(item.fullName)
                      }}
                    >
                      {item.fullName}
                    </span>
                  ))}
                </div>
              </>
            )}
          </>
        )}
        <h5 className="advertisement__add-main-title">Title</h5>
        <InputSearch
          search={false}
          value={title}
          changeValue={setTitle}
          placeholder={'Advertisement Title'}
        />
        <h5 className="advertisement__add-main-title">Description</h5>
        <div className="profile__about-body">
          <TextareaAutosize
            value={text}
            onChange={(e) => setText(e.target.value)}
            className="profile__about-autoresize"
            minRows={1}
            placeholder={`Description`}
          />
        </div>
        <h5 className="advertisement__add-main-title">Link leads to</h5>
        <div className="publish__activities-ticket">
          <IconLink />
          <input
            type="url"
            placeholder="website.com/here"
            onChange={(e) => {
              setUrl(e.target.value)
            }}
          />
        </div>
        <button
          className={`advertisement__add-main-btn ${
            validate && 'advertisement__add-main-btn-disabled'
          }`}
          onClick={addText}
          disabled={validate}
        >
          Continue
        </button>
      </div>
    </div>
  )
}
