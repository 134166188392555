import { AxiosResponse } from 'axios'
import L, { LatLng, LatLngExpression } from 'leaflet'
import { useEffect, useState } from 'react'
import { renderToString } from 'react-dom/server'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'
import { useLocation, useNavigate } from 'react-router-dom'
import $api from '../../../http'
import { PAGES } from '../../../types/enum'
import { LocationDistanceType, LocationType } from '../../../types/types'
import { apiBaseURL, indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconAddMarker } from '../../svg/IconAddMarker'
import { IconLeftChevrons } from '../../svg/IconChevrons'
import { IconLikeMap } from '../../svg/IconLikeMap'
import { IconMessege } from '../../svg/IconMessege'
import { IconNightMode } from '../../svg/IconNightMode'
import { IconNotification } from '../../svg/IconNotification'
import { IconSearchMap } from '../../svg/IconSearchMap'
import { DarkBg } from '../../ui/DarkBg'
import { Loader } from '../../ui/Loader'
import { ExploreHeader } from './ExploreHeader'
import { ExploreModalSearch } from './ExploreModalSearch'
import FR from './FR-regions.json'
import UA from './UA-regions.json'
import { insidePolygon } from '../../../utils/inside'
import { AddressMarkerCity } from './AddressMarkerCity'
import { ExploreFilterModal } from './ExploreFilterModal'
import { calculateDistance } from '../../../utils/calculateRate'

export interface RegInterface {
  name: string
  coordinates: number[][]
  center: [number, number]
}

export const ExploreCity = () => {
  const location = useLocation()
  const [load, setLoad] = useState(false)
  const { role, avatarFileName, fullName } = useAppSelector((state) => state.user)
  const { coordinates, street, houseNumber, country } = useAppSelector(
    (state) => state.profile,
  )
  const navigate = useNavigate()
  const [search, setSearch] = useState('')
  const [map, setMap] = useState<L.Map | null>()
  const [Night, setNight] = useState<boolean>(true)
  const [MarkerMode, setMarkerMode] = useState<boolean>(false)
  const [myPos, setMyPos] = useState<GeolocationPosition>()
  const [isOpenSearch, setIsOpenSearch] = useState(false)
  const [isOpenFilter, setIsOpenFilter] = useState(false)
  const [filter, setFilter] = useState<string>(PAGES.MAIN)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const [myReg, setMyReg] = useState<RegInterface | null>(null)
  const [myPolygon, setMyPolygon] = useState<L.Polygon | null>(null)
  const [myPolyline, setMyPolyline] = useState<L.Polyline | null>(null)
  const [MarksList, setMarksList] = useState<LocationType[]>([])

  const getRegionNearby = () => {
    let arr: LocationDistanceType[] = []
    if (myPos) {
      MarksList.map((item) => {
        arr.push({
          ...item,
          distance: calculateDistance(item.center, {
            lat: myPos?.coords.latitude,
            lng: myPos?.coords.longitude,
          }),
        })
      })
    }
    return arr.filter((it) => it.distance).slice(0, 2)
  }

  useEffect(() => {
    const body = async () => {
      // marks
      const resMarksList: AxiosResponse<LocationType[]> =
        await $api.post('map/list-marks')
      const marks: LocationType[] = []
      setMarksList(resMarksList.data)
    }
    body()
  }, [])
  useEffect(() => {
    navigator.geolocation.getCurrentPosition((pos) => {
      setMyPos(pos)
    })
  }, [])
  useEffect(() => {
    if (location.state.type === 'UA' && myPos) {
      let array: LatLng[] = []
      UA.features.map((reg) => {
        if (
          insidePolygon(
            [myPos.coords.latitude, myPos.coords.longitude],
            reg.geometry.coordinates[0],
          )
        ) {
          reg.geometry.coordinates[0].map((item) => {
            let latlng = new LatLng(item[1], item[0])
            array.push(latlng)
          })
          if (array.length && map) {
            setMyPolygon(
              L.polygon(array).setStyle({ opacity: 0, color: '#fff' }).addTo(map),
            )
            setMyPolyline(L.polyline(array).setStyle({ color: '#fff' }).addTo(map))
          }
          setMyReg({
            name: reg.properties.name,
            coordinates: reg.geometry.coordinates[0],
            center: [myPos?.coords.latitude, myPos.coords.longitude],
          })
        }
      })
    }
    // if(location.state.type==="CA" && myPos){
    //     let array:LatLng[] = [];
    //     CA.features.map((reg)=>{
    //          if(insidePolygon([myPos.coords.latitude, myPos.coords.longitude], reg.geometry.coordinates[0][0])){
    //             reg.geometry.coordinates[0].map((item)=>{
    //                 let latlng = new LatLng(item[0][1], item[0][0]);
    //                 array.push(latlng)
    //             })
    //             if(array.length && map){
    //                 setMyPolygon(L.polygon(array).setStyle({opacity:0, color:"#fff"}).addTo(map))
    //                 setMyPolyline(L.polyline(array).setStyle({color:"#fff"}).addTo(map))
    //             }
    //             setMyReg({name:reg.properties.name, coordinates:reg.geometry.coordinates[0][0], center:[myPos?.coords.latitude, myPos.coords.longitude]});

    //         }
    //     })
    // }
    if (location.state.type === 'FR' && myPos) {
      let array: LatLng[] = []
      FR.features.map((reg) => {
        if (
          insidePolygon(
            [myPos.coords.latitude, myPos.coords.longitude],
            reg.geometry.coordinates[0][0],
          )
        ) {
          reg.geometry.coordinates[0].map((item) => {
            let latlng = new LatLng(item[0][1], item[0][0])
            array.push(latlng)
          })
          if (array.length && map) {
            setMyPolygon(
              L.polygon(array).setStyle({ opacity: 0, color: '#fff' }).addTo(map),
            )
            setMyPolyline(L.polyline(array).setStyle({ color: '#fff' }).addTo(map))
          }
          setMyReg({
            name: reg.properties.name,
            coordinates: reg.geometry.coordinates[0][0],
            center: [myPos?.coords.latitude, myPos.coords.longitude],
          })
        }
      })
    }
  }, [myPos, map])
  useEffect(() => {
    if (myPolygon && myPolyline && map && myReg) {
      map.setView(myPolygon.getCenter())
      setMyReg({
        name: myReg.name,
        coordinates: myReg.coordinates,
        center: [myPolygon.getCenter().lat, myPolygon.getCenter().lng],
      })
    }
  }, [myPolygon, myPolyline, map, myReg])
  useEffect(() => {
    if (myPolygon && myPolyline) {
      if (Night) {
        myPolygon.setStyle({ opacity: 0, color: '#fff' })
        myPolyline.setStyle({ color: '#fff' })
      } else {
        myPolygon.setStyle({ opacity: 0, color: 'blue' })
        myPolyline.setStyle({ color: 'blue' })
      }
    }
  }, [Night])

  return (
    <>
      {!load ? (
        <div className="explore__country">
          <ExploreHeader
            style={
              !Night
                ? {
                    background:
                      'linear-gradient(rgba(245, 246, 247, 1), rgba(245, 246, 247, 0))',
                  }
                : {
                    background:
                      'linear-gradient(rgba(15, 20, 25, 1), rgba(15, 20, 25, 0))',
                  }
            }
          >
            <div className="explore__main-header">
              <div className="explore__main-header-user" style={{ margin: 'auto 0' }}>
                <button onClick={() => navigate(-1)}>
                  {Night ? (
                    <IconLeftChevrons />
                  ) : (
                    <IconLeftChevrons fill="rgba(38, 38, 38, 1)" />
                  )}
                </button>
                {avatarFileName ? (
                  <img src={`${apiBaseURL}/uploads/avatar/${avatarFileName}`} />
                ) : (
                  <div
                    style={{
                      backgroundColor: 'rgba(41, 52, 65, 1)',
                      borderRadius: '100%',
                      width: '40px',
                      height: '40px',
                      color: '#fff',
                      textAlign: 'center',
                      position: 'relative',
                    }}
                  >
                    <span style={{ position: 'absolute', left: '10px', top: '8px' }}>
                      {fullName.slice(0, 2)}
                    </span>
                  </div>
                )}
                {!Night ? (
                  <div className="explore__main-header-user-location">
                    <h6 style={{ color: 'rgba(99, 106, 117, 1)' }}>current location</h6>
                    <h5 style={{ color: 'rgba(31, 32, 36, 1)' }}>
                      {houseNumber ? houseNumber + ',' : ''} {street ? street + ',' : ''}{' '}
                      {country}
                    </h5>
                  </div>
                ) : (
                  <div className="explore__main-header-user-location">
                    <h6>current location</h6>
                    <h5>
                      {houseNumber ? houseNumber + ',' : ''} {street ? street + ',' : ''}{' '}
                      {country}
                    </h5>
                  </div>
                )}
              </div>
              <div
                className={
                  !MarkerMode
                    ? 'admin__panel-addMarker explore__location__follow'
                    : 'admin__panel-addMarker explore__location__follow clicked'
                }
                onClick={() => {
                  setMarkerMode(!MarkerMode)
                  if (myPos)
                    map?.flyTo(
                      { lat: myPos?.coords.latitude, lng: myPos?.coords.longitude },
                      16,
                    )
                }}
              >
                <IconAddMarker />
              </div>
            </div>
          </ExploreHeader>
          <div className="explore__country-map">
            {myPos && (
              <MapContainer
                dragging={false}
                tap={false}
                zoomControl={false}
                center={{ lat: myPos?.coords.latitude, lng: myPos?.coords.longitude }}
                zoom={7}
                maxZoom={7}
                minZoom={7}
                scrollWheelZoom={true}
                ref={setMap}
              >
                <>
                  {!Night ? (
                    <>
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                        url="https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png"
                        subdomains="abcd"
                      />
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                        url="https://{s}.basemaps.cartocdn.com/dark_only_labels/{z}/{x}/{y}{r}.png"
                        subdomains="abcd"
                      />
                    </>
                  ) : (
                    <>
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                        url="https://{s}.basemaps.cartocdn.com/dark_nolabels/{z}/{x}/{y}{r}.png"
                      />
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                        url="https://{s}.basemaps.cartocdn.com/dark_only_labels/{z}/{x}/{y}{r}.png"
                        subdomains="abcd"
                      />
                    </>
                  )}
                </>
                {myPolygon && myReg && map && (
                  <AddressMarkerCity
                    night={Night}
                    coords={myPolygon.getCenter()}
                    reg={myReg}
                    text={myReg?.name.split(' ')[0]}
                  />
                )}
              </MapContainer>
            )}
            {!isOpen && !isOpenSearch && (
              <div className="map__menu" style={{ marginTop: '10%' }}>
                <div
                  style={
                    Night
                      ? { backgroundColor: 'rgba(41, 52, 65, 1)' }
                      : { backgroundColor: '#fff' }
                  }
                  className="map__menu-item"
                  onClick={() => {
                    setNight(!Night)
                  }}
                >
                  <IconNightMode fill={Night ? '#fff' : '#262626'} />
                </div>
                <div
                  style={
                    Night
                      ? { backgroundColor: 'rgba(41, 52, 65, 1)' }
                      : { backgroundColor: '#fff' }
                  }
                  className="map__menu-item"
                  onClick={() => setIsOpenSearch(true)}
                >
                  <IconSearchMap fill={Night ? '#fff' : '#262626'} />
                </div>
                <div
                  style={
                    Night
                      ? { backgroundColor: 'rgba(41, 52, 65, 1)', color: '#fff' }
                      : { backgroundColor: '#fff', color: '#262626' }
                  }
                  className="map__menu-item"
                >
                  {Night ? (
                    <b>
                      <span>
                        <IconMessege fill="#fff" />
                      </span>
                    </b>
                  ) : (
                    <b>
                      <span>
                        <IconMessege />
                      </span>
                    </b>
                  )}
                  <div className="blue"></div>
                </div>
                <div
                  style={
                    Night
                      ? { backgroundColor: 'rgba(41, 52, 65, 1)' }
                      : { backgroundColor: '#fff' }
                  }
                  className="map__menu-item"
                >
                  <IconLikeMap fill={Night ? '#fff' : '#262626'} />
                </div>
                <div
                  style={
                    Night
                      ? { backgroundColor: 'rgba(41, 52, 65, 1)' }
                      : { backgroundColor: '#fff' }
                  }
                  className="map__menu-item"
                  onClick={() => navigate(`${indexPathByRole(role)}/notification`)}
                >
                  <IconNotification fill={Night ? '#fff' : '#262626'} />
                </div>
              </div>
            )}
          </div>
          {isOpenSearch && map && (
            <ExploreModalSearch
              setIsOpen={setIsOpenSearch}
              isOpen={isOpenSearch}
              setIsOpenFilter={setIsOpenFilter}
              setLoad={setLoad}
              filter={filter}
              regions={getRegionNearby()}
              map={map}
            />
          )}
          {isOpenFilter && (
            <ExploreFilterModal
              isOpen={isOpenFilter}
              setIsOpen={setIsOpenFilter}
              setFilter={setFilter}
              filter={filter}
              setIsOpenSearch={setIsOpenSearch}
            />
          )}
          {(isOpenFilter || isOpenSearch) && <DarkBg />}
        </div>
      ) : (
        <Loader />
      )}
    </>
  )
}
