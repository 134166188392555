import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ChatType } from '../../../types/types'
import { ChatList } from '../../general-components/messenger/ChatList'
import { UserHeaderChat } from '../header/UserHeaderChat'
import { getUserChats } from '../../../redux/messanger'
import { useAppSelector, useAppDispatch } from '../../../utils/hooks'

export const MessegesChatList = () => {
  const [search, setSearch] = useState('')
  const [isActiveSearch, setIsActiveSearch] = useState(false)
  const [chats, setChats] = useState<ChatType[]>([])
  const { _id } = useAppSelector((state) => state.user)
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(getUserChats({ _id, isSupport: false }))
  }, [])
  return (
    <div className={`user__chat ${isActiveSearch && 'user__chat--active'}`}>
      <UserHeaderChat
        chats={chats}
        search={search}
        setSearch={setSearch}
        isActiveSearch={isActiveSearch}
        setIsActiveSearch={setIsActiveSearch}
      />
      <ChatList search={search} setChats={setChats} isSupport={false} />
    </div>
  )
}
