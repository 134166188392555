import { useNavigate } from 'react-router-dom'
import { attemptUpdateInterests } from '../../../../redux/userNew'
import { PROFILE_STEP } from '../../../../types/server-communication/user'
import { INTERESTS_LIMIT } from '../../../../utils/appConstants'
import { useAppSelector } from '../../../../utils/hooks'
import interestsJson from '../../../../utils/localization/locales/datasets/interests.en.json'
import {
  ProfileSearchSelect,
  ProfileSearchSelectFormValues,
} from '../layout/ProfileSearchSelect'
import { useAttemptListener } from 'dev-masters-react-kit'
import { stepDefinitions } from '../../../../utils/hooks/useNextSetUpProfilePath'
import { Form } from 'antd'
import { StandaloneScreenLayout } from '../../../ui/Layout/StandaloneScreenLayout'
import { store } from '../../../../store'

export const ProfileInterests = () => {
  const { interests } = useAppSelector((state) => state.userNew)

  const navigate = useNavigate()
  const isLoading = useAttemptListener({
    store,
    attempt: attemptUpdateInterests,
    onFulfilled: () => {
      navigate('/profile/' + stepDefinitions[PROFILE_STEP.CERTIFICATES].path)
    },
  })
  const [form] = Form.useForm<ProfileSearchSelectFormValues<'interests'>>()
  const currentSelection = Form.useWatch('interests', form)

  return (
    <StandaloneScreenLayout
      title="Interests"
      subTitle="Discover like-minded individuals who share similar interests"
      onPrimaryButtonClicked={form.submit}
      isDisabled={isLoading || !currentSelection || currentSelection?.length === 0}
    >
      <ProfileSearchSelect
        translationNamespace="interestsList"
        formFieldName="interests"
        optionList={interestsJson}
        form={form}
        attemptUpdateAction={attemptUpdateInterests}
        placeholderLabel="Interests"
        initialValues={interests}
        maxCount={INTERESTS_LIMIT}
      />
    </StandaloneScreenLayout>
  )
}
