import { useState } from 'react'
import { InputSearch } from '../../ui/InputSearch'
export const AdvertisementTransactions = () => {
  const [sent, setSent] = useState<boolean>(true)
  const [received, setReceived] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const date = new Date()
  return (
    <div className="advertisement__add" style={{ margin: '100px 20px' }}>
      {/* <div className="advertisement__add-header">
                <div className="advertisement__add-header-btn" onClick={()=>navigate(-1)}>
                    <IconLeftChevrons fill="rgba(21, 16, 77, 1)"/>
                    <span style={{textTransform:"capitalize"}}>Request Money</span>
                </div>
            </div> */}
      <div className="advertisement__menu">
        <h5
          className={sent ? 'advertisement__menu-active' : 'advertisement__menu-text'}
          onClick={() => {
            setSent(true)
            setReceived(false)
          }}
        >
          Sent
        </h5>
        <h5
          className={received ? 'advertisement__menu-active' : 'advertisement__menu-text'}
          onClick={() => {
            setSent(false)
            setReceived(true)
          }}
        >
          Received
        </h5>
      </div>
      <div style={{ margin: '20px 0' }}>
        <InputSearch
          value={search}
          changeValue={setSearch}
          placeholder={
            <>
              Search <b>Transactions</b>
            </>
          }
        />
      </div>
      <h6 className="advertisement__add-transactions-date">
        {date.toLocaleString('default', { dateStyle: 'long' })}
      </h6>
      <div className="advertisement__add-transactions-list">
        {sent && (
          <div className="advertisement__add-transactions-list-item">
            <img src="/Images/Profile.jpg" alt="" />
            <div className="advertisement__add-transactions-list-item-text">
              <h5>User Name</h5>
              <p>Comment for transaction</p>
              <h6>10:51</h6>
            </div>
            <h6
              style={{ color: 'rgba(202, 57, 57, 1)' }}
              className="advertisement__add-transactions-list-item-sum"
            >
              -1500,00 $
            </h6>
          </div>
        )}
        {received && (
          <div className="advertisement__add-transactions-list-item">
            <img src="/Images/Profile.jpg" alt="" />
            <div className="advertisement__add-transactions-list-item-text">
              <h5>User Name</h5>
              <p>Comment for transaction</p>
              <h6>10:51</h6>
            </div>
            <h6 className="advertisement__add-transactions-list-item-sum">+1500,00 $</h6>
          </div>
        )}
      </div>
    </div>
  )
}
