import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Slider from 'react-slick'
import $api from '../../../http'
import { ActivitiesHttp } from '../../../http/activities-http'
import { FeedBackHttp } from '../../../http/feedback-http'
import { ServiceHttp } from '../../../http/service-http'
import { UserHttp } from '../../../http/user-http'
import { PRIVACY } from '../../../types/enum'
import {
  PublishEventItemInterface,
  PublishServiceItemInterface,
} from '../../../types/types'
import { apiBaseURL, indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { PublicationEventPanel } from '../../general-components/publication-lists/PublicationEventPanel'
import { PublishModalRoute } from '../../general-components/publication/PublishModalRoute'
import { IconAdminClose } from '../../svg/IconAdminHeader'
import { IconArrowRight } from '../../svg/IconArrow'
import { IconStars } from '../../svg/IconFavor'
import { IconNeibs } from '../../svg/IconPassEye'
import {
  IconProfileInfoBookmark,
  IconProfileInfoBookmarkCopy,
} from '../../svg/IconProfileInfo'
import { IconServicesAllPoint } from '../../svg/IconServicesAll'
import { IconLocationPoint } from '../../svg/IconsLocation'
import { DarkBg } from '../../ui/DarkBg'
import { Loader } from '../../ui/Loader'
import { ActivitiesMyModal } from '../activities/ActivitiesMyModal'
import { ExploreHeaderMain } from './ExploreHeaderMain'
import { ExploreMiniMap } from './ExploreMiniMap'
import { ActivitiesNeibModalMany } from '../activities/ActivitiesNeibModalMany'
import { inside, insideCircle } from '../../../utils/inside'
import { calculateDistance } from '../../../utils/calculateRate'

const dayTime = [
  [5, 11, 'morning'],
  [12, 17, 'afternoon'],
  [18, 24, 'night'],
  [0, 4, 'night'],
]

const settingsSecond = {
  infinite: false,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  className: 'user__services-category-slick-ins',
}

export const ExploreMain = () => {
  const { fullName, _id, role } = useAppSelector((state) => state.user)
  const { coordinates, polygonCoords, radiusSize } = useAppSelector(
    (state) => state.profile,
  )

  const time = new Date().getHours()

  const [currentTime, setCurrentTime] = useState<string>('')
  const [search, setSearch] = useState<string>('')

  const { publishActivities } = useAppSelector((s) => s.activities)
  const { publishServices } = useAppSelector((s) => s.categories)
  const [events, setEvents] = useState<PublishEventItemInterface[]>()
  const [services, setServices] = useState<PublishServiceItemInterface[]>()
  const [users, setUsers] =
    useState<
      { fullName: string; distance: number; avatarFileName: string | null; _id: string }[]
    >()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [currentItem, setCurrentItem] = useState<PublishEventItemInterface>()
  const { publishAllPosts } = useAppSelector((s) => s.posts)
  const [load, setLoad] = useState(false)

  const navigate = useNavigate()
  useEffect(() => {
    setLoad(true)
    setEvents(publishActivities)
    setServices(publishServices)
    setLoad(false)
    console.log(events)
  }, [publishActivities, publishServices, publishAllPosts, users])

  const sortServices = () => {
    let eSorted: PublishServiceItemInterface[] = []
    services?.filter((item) => {
      if (item.userId._id !== _id) {
        if (
          polygonCoords &&
          inside([item.coordinates.lat, item.coordinates.lng], polygonCoords)
        ) {
          eSorted.push(item)
        } else {
          if (
            !polygonCoords &&
            radiusSize &&
            insideCircle([item.coordinates.lat, item.coordinates.lng], radiusSize, [
              coordinates.lat,
              coordinates.lng,
            ])
          ) {
            eSorted.push(item)
          }
        }
      } else {
        eSorted.push(item)
      }
    })
    return eSorted
  }

  const sortEvents = () => {
    let eSorted: PublishEventItemInterface[] = []
    events?.filter((item) => {
      if (item.userId._id !== _id) {
        if (
          polygonCoords &&
          inside([item.coordinates.lat, item.coordinates.lng], polygonCoords)
        ) {
          eSorted.push(item)
        } else {
          if (
            !polygonCoords &&
            radiusSize &&
            insideCircle([item.coordinates.lat, item.coordinates.lng], radiusSize, [
              coordinates.lat,
              coordinates.lng,
            ])
          ) {
            eSorted.push(item)
          }
        }
      } else {
        eSorted.push(item)
      }
    })
    return eSorted
  }

  useEffect(() => {
    const body = async () => {
      let dist: {
        fullName: string
        distance: number
        avatarFileName: string | null
        _id: string
      }[] = []
      await UserHttp.getAllUsers().then((res) => {
        res.filter((item) => {
          console.log(item)
          if (item.coords) {
            let distance = calculateDistance(coordinates, item.coords)
            dist.push({
              distance,
              fullName: item.fullName,
              avatarFileName: item.avatarFileName,
              _id: item?._id,
            })
          }
        })
        setUsers(dist.sort((f, s) => f.distance - s.distance).slice(0, 10))
      })
    }
    body()
  }, [])

  useEffect(() => {
    for (let i = 0; i < dayTime.length; i++) {
      if (time > Number(dayTime[i][0]) && time < Number(dayTime[i][1])) {
        setCurrentTime(String(dayTime[i][2]))
      }
    }
  }, [time])

  const toPublishActivities = (_id: string) => {
    navigate(`/user/activities/publish-activities-item?publishActivitiesId=${_id}`)
  }

  const toPublishService = (_id: string) => {
    navigate(`/user/service/publish-service?publishServiceId=${_id}`)
  }

  const validatePrivacy = (item: PublishServiceItemInterface) => {
    if (item.privacyPublishService === PRIVACY.NEIBS) {
      return item.allowList.find((id) => id === _id) || item.userId._id === _id
    }
    if (item.privacyPublishService === PRIVACY.ONLYME) {
      return item.userId._id === _id
    }
    if (item.privacyPublishService === PRIVACY.EVERYONE) {
      return true
    }
  }

  const validatePrivacyEvent = (item: PublishEventItemInterface) => {
    if (item.privacyEvent === PRIVACY.NEIBS) {
      return item.allowList.find((id) => id === _id) || item.userId._id === _id
    }
    if (item.privacyEvent === PRIVACY.ONLYME) {
      return item.userId._id === _id
    }
    if (item.privacyEvent === PRIVACY.EVERYONE) {
      return true
    }
  }

  const calculateRate = (item: PublishServiceItemInterface) => {
    let rate: number[] = []
    item.reviews.map((review) => {
      rate.push(review.rate)
    })
    return rate.reduce((partialSum, a) => partialSum + a, 0) / rate.length
  }

  const toProfileInfo = async (_id: string) => {
    const prop = await UserHttp.getUserById({ userId: _id })
    navigate('/profileinfo', {
      state: prop,
    })
  }
  const openChat = async (userId: string) => {
    await $api
      .post('messenger/open-chat', {
        participants: [
          {
            userId,
          },
          {
            userId: _id,
          },
        ],
      })
      .then((res) => {
        console.log(res)
        if (res.data) {
          navigate(`${indexPathByRole(role)}/messeges/chat`, {
            state: {
              chat: res.data,
            },
          })
        }
      })
  }

  const updateNotification = async (serviceId: string) => {
    if (events) {
      await ActivitiesHttp.updateNotification({
        eventId: serviceId,
        userId: _id,
      })

      let arr: PublishEventItemInterface[] = []
      events?.map((item) => {
        if (item._id === serviceId) {
          arr.push({ ...item, isNotificatedEvent: !item.isNotificatedEvent })
        } else {
          arr.push(item)
        }
      })
      setEvents(arr)
    }
  }

  const updateLikeEvent = async (likeId: string, serviceId: string) => {
    await FeedBackHttp.updateLike({
      likeId,
      userId: _id,
    })
    let arr: PublishEventItemInterface[] = []
    events?.map((item) => {
      if (item._id === serviceId) {
        arr.push({
          ...item,
          isLiked: !item?.isLiked,
          countLikes: item.isLiked ? item?.countLikes - 1 : item?.countLikes + 1,
        })
      } else {
        arr.push(item)
      }
    })
    setEvents(arr)
  }
  const updateMarkEvent = async (eventId: string, isMarked: boolean) => {
    if (isMarked) {
      await ActivitiesHttp.deleteMark({
        eventId,
        marckedUserId: _id,
      })
    } else {
      await ActivitiesHttp.addMark({
        eventId,
        marckedUserId: _id,
      })
      setIsOpenSave(true)
    }
    let arr: PublishEventItemInterface[] = []
    events?.map((item) => {
      if (item._id === eventId) {
        arr.push({
          ...item,
          isMarked: !item?.isMarked,
        })
      } else {
        arr.push(item)
      }
    })
    setEvents(arr)
  }

  const copyLink = async (item: string) => {
    const text = `${window.location.href}/publish-activities-item?publishActivitiesId=${item}`
    navigate(`${indexPathByRole(role)}/service/share-list`, { state: { text } })
  }

  const deleteUser = (item: {
    fullName: string
    distance: number
    avatarFileName: string | null
    _id: string
  }) => {
    if (users) {
      let arr: {
        fullName: string
        distance: number
        avatarFileName: string | null
        _id: string
      }[] = [...users]
      let index = arr.indexOf(item)
      if (index !== -1) {
        arr.splice(index, 1)
        setUsers(arr)
      }
    }
  }
  const [ssOpenSave, setIsOpenSave] = useState(false)
  const updateMarkService = async (serviceId: string, isMarked: boolean) => {
    if (isMarked) {
      await ServiceHttp.deleteMark({
        serviceId,
        marckedUserId: _id,
      })
    } else {
      await ServiceHttp.addMark({
        serviceId,
        marckedUserId: _id,
      })
      setIsOpenSave(true)
    }

    if (services) {
      let array: PublishServiceItemInterface[] = []
      services.map((item) => {
        if (item._id === serviceId) {
          array.push({
            ...item,
            isMarked: !item?.isMarked,
          })
        } else {
          array.push(item)
        }
      })
      setServices(array)
    }
  }

  const [isOpen, setIsOpen] = useState(false)
  const [isOpenPublish, setIsOpenPublish] = useState(false)
  return !load ? (
    <div className="explore__main">
      <ExploreHeaderMain
        setIsOpenPublish={setIsOpenPublish}
        search={search}
        setSearch={setSearch}
      />
      <div className="explore__main-content">
        <h6>
          Good {currentTime}, {fullName}
        </h6>
        <h5>Explore on the map</h5>
        <ExploreMiniMap />
        {sortEvents().length ? (
          <>
            <div style={{ padding: '40px 0 20px 0' }}>
              <h5>Discover Events</h5>
              <h6>
                Selection of captivating activities that align with your location and
                schedule
              </h6>
            </div>
            <div className="explore__main-content-events">
              {sortEvents()?.map((item) => (
                <>
                  {validatePrivacyEvent(item) && (
                    <>
                      <div
                        className="user__event-item"
                        onClick={() => toPublishActivities(item._id)}
                      >
                        <div className="user__event-item-row1">
                          <div className="user__event-item-row1-text">
                            <h5 style={{ fontSize: '16px', fontWeight: '500' }}>
                              {item.title}
                            </h5>
                            <h6>{item.addressLocation}</h6>
                          </div>
                        </div>
                        <div className="explore__event-item-row2" onClick={() => {}}>
                          {item.filesName.length ? (
                            item?.filesName?.map((it) => (
                              <>
                                <div
                                  style={{ position: 'relative' }}
                                  className="explore__event-item-row2-img"
                                  onClick={() => {}}
                                >
                                  <div className="explore__event-item-row2-img-last">
                                    <div className="explore__event-item-row2-img-last-text">
                                      {moment(item?.startDate).format('DD/MM/YYYY')}
                                    </div>
                                  </div>
                                  <div className="user__event-item-row2">
                                    <IconServicesAllPoint />
                                  </div>
                                  <img
                                    src={`${apiBaseURL}/uploads/publish_activities/${it}`}
                                    alt=""
                                  />
                                  <div
                                    className="user__services-last-item-img-1 user__services-last-item-img-info"
                                    style={{ width: '100px', height: '35px', top: '85%' }}
                                  >
                                    <div className="user__services-last-item-img-text">
                                      <span
                                        className="admin__posts-list-row1-text"
                                        style={{ color: 'rgba(21, 16, 77, 1)' }}
                                      >
                                        {item.activitiesId.name}
                                      </span>{' '}
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))
                          ) : (
                            <div
                              style={{ position: 'relative' }}
                              className="explore__event-item-row2-img"
                              onClick={() => toPublishActivities(item._id)}
                            >
                              <div className="explore__event-item-row2-img-last">
                                <div className="explore__event-item-row2-img-last-text">
                                  {moment(item?.startDate).format('DD/MM/YYYY')}
                                </div>
                              </div>
                              <div
                                className="user__event-item-row2"
                                onClick={(e) => {
                                  e.stopPropagation()
                                  setIsOpenModal(true)
                                  setCurrentItem(item)
                                }}
                              >
                                <IconServicesAllPoint />
                              </div>
                              <img
                                className="posts__bg"
                                src={`/Images/postsBG.png`}
                                alt=""
                              />
                              <div className="explore__event-item-row2-img-last-bottom">
                                <div className="explore__event-item-row2-img-last-bottom-text">
                                  <span
                                    className="admin__posts-list-row1-text"
                                    style={{ color: 'rgba(21, 16, 77, 1)' }}
                                  >
                                    {item.activitiesId.name}
                                  </span>{' '}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="user__event-item-row3">
                          <h6>{item.text}</h6>
                          {/* <div className="user__event-item-row3-button">
                                {item.startDate.toString().split(".")[0].split("T")[0].replaceAll("-", "/") + " " }{ <b>{item.startDate.toString().split(".")[0].split("T")[1].split(":")[0] + ":" + item.startDate.toString().split(".")[0].split("T")[1].split(":")[1]}</b> }
                            </div> */}
                        </div>
                        <div className="user__event-item-row4">
                          <PublicationEventPanel
                            item={item}
                            updateLike={() => updateLikeEvent(item.likes, item._id)}
                            navigateToComments={() => toPublishActivities(item._id)}
                            updateMark={() => updateMarkEvent(item._id, item.isMarked)}
                            updateRepost={() => copyLink(item._id)}
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                          />
                          {/* {<button onClick={() => updateLikeEvent(item.likeId, item._id)}>
                            {item.isLiked ? <IconPostsRedLike /> : <IconPostsLike />}

                        </button>}
                        <div onClick={()=>updateMarkEvent(item._id, item.isMarked)} className={item.isMarked ? "user__event-item-row4-bookmark-active" :"user__event-item-row4-bookmark"}>
                            <IconProfileInfoBookmark />
                        </div> */}
                        </div>
                      </div>
                    </>
                  )}
                </>
              ))}
            </div>
          </>
        ) : (
          ''
        )}
        {sortEvents()?.length ? (
          <button
            style={{ width: '100%' }}
            className="user__services-last-button user__services-last-button"
            onClick={() => navigate(`/user/activities/user-last-publish-activities`)}
          >
            View all
          </button>
        ) : (
          ''
        )}
        {sortServices()?.length ? (
          <>
            <div style={{ padding: '40px 0 20px 0' }}>
              <h5>Find Service</h5>
              <h6>
                Find top-rated specialists based on their expertise and proximity to you
              </h6>
            </div>
            <div className="explore__main-content-events">
              {sortServices()
                .slice(0, 3)
                ?.map((item) => (
                  <>
                    {validatePrivacy(item) && (
                      <div
                        style={{ margin: '20px 0' }}
                        className="user__services-last-item"
                        onClick={() => toPublishService(item._id)}
                      >
                        <div className="user__services-last-item-img">
                          <div className="user__services-last-item-img-1 user__services-last-item-img-info">
                            <div className="user__services-last-item-img-text">
                              {item.categoryId.name}
                            </div>
                          </div>
                          {item.filesName.length > 0 ? (
                            <img
                              src={`${apiBaseURL}/uploads/publish_services/${item.filesName[0]}`}
                              alt=""
                            />
                          ) : (
                            <img
                              className="posts__bg"
                              src={`/Images/postsBG.png`}
                              alt=""
                            />
                          )}

                          {item.reviews.length ? (
                            <div className="user__services-last-item-img-2 user__services-last-item-img-info">
                              <div className="user__services-last-item-img-text">
                                {item.reviews.length ? (
                                  <>
                                    <IconStars />
                                    <b>{calculateRate(item)}</b>
                                    <span>{`(${item.reviews.length})`}</span>
                                  </>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                        <div
                          className="user__services-last-item-info"
                          style={{ position: 'relative' }}
                        >
                          <div className="user__services-last-item-row1">
                            <h5
                              className="user__services-last-item-title"
                              style={{
                                color: 'rgb(41, 45, 50)',
                                fontSize: '16px',
                                fontWeight: '500',
                              }}
                            >
                              {item.title}
                            </h5>
                            <button
                              className={`${'admin__posts-list-row4-repost--active'}`}
                              onClick={(e) => {
                                e.stopPropagation()
                                updateMarkService(item._id, item?.isMarked)
                              }}
                            >
                              {item.isMarked ? (
                                <IconProfileInfoBookmarkCopy fill="#3361FF" />
                              ) : (
                                <IconProfileInfoBookmark />
                              )}
                            </button>
                          </div>
                          <h6
                            style={{ margin: '4px 0' }}
                            className="user__services-last-item-text"
                          >
                            {item.text}
                          </h6>
                          <div
                            style={{ position: 'absolute', right: '10px', bottom: '0' }}
                            className="user__services-last-item-foot"
                          >
                            {Math.floor(
                              calculateDistance(coordinates, item.coordinates) / 1000,
                            )}{' '}
                            km
                            <IconArrowRight />
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ))}
            </div>
          </>
        ) : (
          ''
        )}
        {sortServices().length ? (
          <button
            style={{ width: '100%' }}
            className="user__services-last-button user__services-last-button"
            onClick={() => navigate(`/user/service/user-last-publish-service`)}
          >
            View all
          </button>
        ) : (
          ''
        )}
        {users?.length ? (
          <>
            <div style={{ margin: '40px 0' }} />
            <h5>People nearby</h5>
            <h6>Discover people in your area based on your interests and skills</h6>
            <div className="user__services-category-slick">
              <Slider {...settingsSecond}>
                {users
                  .filter((it) => it._id !== _id)
                  .map((item) => (
                    <div className="user__services-category-item-body">
                      <div className="user__services-users-item">
                        <button
                          className="user__services-users-item-close"
                          onClick={(e) => {
                            deleteUser(item)
                          }}
                        >
                          <IconAdminClose />
                        </button>
                        <div className="user__services-users-item-avatar">
                          {item?.avatarFileName ? (
                            <img
                              src={`${apiBaseURL}/uploads/avatar/${item?.avatarFileName}`}
                            />
                          ) : (
                            <div
                              style={{
                                backgroundColor: 'rgba(41, 52, 65, 1)',
                                borderRadius: '100%',
                                width: '100%',
                                height: '100%',
                                color: '#fff',
                                textAlign: 'center',
                                position: 'relative',
                              }}
                            >
                              <span
                                style={{ position: 'absolute', left: '35%', top: '35%' }}
                              >
                                {item?.fullName.slice(0, 2)}
                              </span>
                            </div>
                          )}
                        </div>
                        <div>
                          <h5 className="user__services-users-item-name">
                            {item?.fullName}{' '}
                          </h5>
                          <div className="user__services-users-item-location">
                            <IconLocationPoint />{' '}
                            <b>{Math.round(item.distance / 1000)} km </b>
                            from you
                          </div>
                        </div>
                        <button
                          className="user__services-users-item-message"
                          onClick={() => openChat(item._id)}
                        >
                          <IconNeibs />
                          Message
                        </button>
                      </div>
                    </div>
                  ))}
              </Slider>
            </div>
          </>
        ) : (
          ''
        )}
      </div>
      {isOpenPublish && (
        <>
          <DarkBg />
          <PublishModalRoute isOpen={isOpenPublish} setIsOpen={setIsOpenPublish} />
        </>
      )}
      {(isOpenPublish || isOpenModal) && <DarkBg />}
      {isOpenModal && currentItem?.userId._id === _id && currentItem && (
        <ActivitiesMyModal
          setIsOpen={setIsOpenModal}
          isOpen={isOpenModal}
          item={currentItem}
          type="activity"
          updateNotification={() => updateNotification(currentItem._id)}
        />
      )}
      {isOpenModal && currentItem?.userId._id !== _id && currentItem && (
        <ActivitiesNeibModalMany
          setIsOpen={setIsOpenModal}
          isOpen={isOpenModal}
          item={currentItem}
          type="activity"
          updateNotification={() => updateNotification(currentItem._id)}
          setPublishService={setCurrentItem}
        />
      )}
    </div>
  ) : (
    <Loader />
  )
}
