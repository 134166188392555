import { indexPathByRole } from '../../route/indexPathByRole'
import { Modal } from '../../ui/Modal'

export const HideGetStartedModal = ({
  setIsOpen,
  isOpen,
  setHidden,
}: {
  setIsOpen: (s: boolean) => void
  isOpen: boolean
  setHidden: (s: boolean) => void
}) => {
  return (
    <>
      <div
        className={`activities__favor-modal  ${
          isOpen ? 'activities__favor-modal--open ' : ''
        }`}
      >
        <Modal setIsOpen={setIsOpen} className={''}>
          <div
            onClick={(e) => {
              e.stopPropagation()
              setIsOpen(false)
            }}
            className="activities__favor-modal-linebody"
          >
            <button className="activities__favor-modal-line" />
          </div>
          <div className="discard__modal-container">
            <div className="modal__get-bg">
              <h5>
                Hide <b>Get Started</b> menu <br />
                It will be stored in <b>Help & Support</b> tab
              </h5>
              <button
                className={`profile__method-btlater`}
                style={{ margin: '15px 0' }}
                onClick={() => {
                  setIsOpen(false)
                  setHidden(true)
                }}
              >
                Hide
              </button>
            </div>
            <div className="discard__modal-btns">
              <button
                className="profile__method-btlater profile__method-btlater--inherit"
                style={{ boxShadow: 'none' }}
                onClick={() => setIsOpen(false)}
              >
                <span>Cancel</span>
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  )
}
