import { HeaderMessageType } from '../../../types/types'
import { apiBaseURL } from '../../route/indexPathByRole'

export const PublicationPostCommentsView = ({ user }: { user: HeaderMessageType[] }) => {
  return (
    <div className="commenst__view">
      {user?.map((item) => (
        <div className="commenst__user-img">
          {item?.avatarFileName ? (
            <img src={`${apiBaseURL}/uploads/avatar/${item?.avatarFileName}`} />
          ) : (
            <div
              style={{
                backgroundColor: 'rgba(41, 52, 65, 1)',
                borderRadius: '100%',
                width: '100%',
                height: '100%',
                color: '#fff',
                textAlign: 'center',
                position: 'relative',
              }}
            >
              <span style={{ position: 'absolute', left: '13px', top: '12px' }}>
                {item?.fullName.slice(0, 2)}
              </span>
            </div>
          )}
        </div>
      ))}
      {/* <div className="commenst__user-img">
                <img
                    src={`${baseURL}/uploads/avatar/${avatar[0]}`}
                    alt=""
                />
            </div>
            <div className="commenst__user-img">
                <img
                    src={`${baseURL}/uploads/avatar/${avatar[1]}`}
                    alt=""
                />
            </div>
            <div className="commenst__user-img">
                <img
                    src={`${baseURL}/uploads/avatar/${avatar[2]}`}
                    alt=""
                />
            </div> */}
    </div>
  )
}
