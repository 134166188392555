import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Form, Input, Button, Checkbox, message, notification, Row, Flex } from 'antd'
import { attemptLogin } from '../../../redux/auth'
import { addListener } from '@reduxjs/toolkit'
import { useAppDispatch } from '../../../utils/hooks'
import { useAttemptListener } from 'dev-masters-react-kit'
import { useTranslation } from 'react-i18next'
import { AuthDTO } from '../../../types/server-communication/auth'
import { AuthLayout } from './AuthLayout'
import { store } from '../../../store'

const SignIn = () => {
  const { t } = useTranslation('auth')
  const dispatch = useAppDispatch()
  const [checked, setChecked] = useState(true)
  const navigate = useNavigate()
  const [signInForm] = Form.useForm<AuthDTO>()
  const currentEmail = Form.useWatch('email', signInForm)

  const isLoading = useAttemptListener({
    store,
    attempt: attemptLogin,
    onRejected: (action) => {
      notification.error({ message: t(action.error.message), key: action.error.message })
      switch (
        action.error.message //TODO [DTO]: add errors enum
      ) {
        case 'BAD_PASSWORD': //TODO: security check, limit auth attempts by ip
          signInForm.setFields([
            {
              name: 'password',
              errors: [t('Please check provided password')],
            },
          ])
          break
        case 'USER_NOT_FOUND':
          signInForm.setFields([
            {
              name: 'email',
              errors: [t('This email is not associated with any user')],
            },
          ])
          break
      }
    },
  })

  const onFinish = (values: AuthDTO) => {
    dispatch(attemptLogin(values))
  }

  const forgetPass = async () => {
    try {
      await signInForm.validateFields(['email'])
      navigate(`/forget-pass?emailAddress=${currentEmail}`)
    } catch (errorInfo) {
      console.log('errorInfo:', errorInfo)
    }
  }

  return (
    <AuthLayout isLogin={true}>
      <Form
        form={signInForm}
        onFinish={onFinish}
        className="login"
        initialValues={{
          email: process.env.REACT_APP_DEV_LOGIN,
          password: process.env.REACT_APP_DEV_PASSWORD,
        }}
      >
        <Form.Item
          name="email"
          rules={[
            { required: true, message: 'Please input your email!' },
            { type: 'email', message: 'Invalid email, example: user@gmail.com!' },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>

        <Form.Item>
          <Flex justify="space-between" align="center">
            <Checkbox checked={!checked} onChange={() => setChecked(!checked)}>
              Remember me
            </Checkbox>
            <Button type="link" onClick={forgetPass}>
              Forget password?
            </Button>
          </Flex>
          <Button
            type="primary"
            // className="login__button"
            htmlType="submit"
            disabled={isLoading}
            block
          >
            Log In
          </Button>
        </Form.Item>
      </Form>
    </AuthLayout>
  )
}

export default SignIn
