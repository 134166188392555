import { useState } from 'react'
import { UploadProps, message, notification } from 'antd'
import { useAppSelector } from '../hooks'
import { UploadChangeParam, UploadFile } from 'antd/es/upload'
import { useTranslation } from 'react-i18next'
import { UploadRef } from 'antd/es/upload/Upload'
import RcUpload from 'rc-upload'

export interface ExtendedUploadRef<T = any> extends Omit<UploadRef<T>, 'upload'> {
  upload: Omit<RcUpload, 'uploader'> & {
    uploader: any
  }
}

interface Props {
  destination: string
  onUploaded?: (info: UploadChangeParam<UploadFile<any>>) => void
}
export default function useUploadImages({ destination, onUploaded }: Props) {
  const { t } = useTranslation('auth')
  const { accessToken } = useAppSelector((state) => state.auth)
  const [isUploading, setIsUploading] = useState(false)
  const uploadProps: UploadProps = {
    accept: 'image/jpeg, image/png, image/jpg',
    multiple: false,
    showUploadList: false,
    action: destination,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    onChange: (info) => {
      if (info.file.status === 'uploading') {
        setIsUploading(true)
      } else if (info.file.status === 'done') {
        onUploaded && onUploaded(info)
      } else if (info.file.status === 'error') {
        if (info.file.response.statusCode === 401)
          notification.error({
            message: t(`Your session has expired, please re login and try again`),
          })
        else
          notification.error({
            message: t(
              `Could not upload ${info.file.name} at this moment, please try again later`,
            ),
          }) //TODO: add variable to translation
      }

      setIsUploading(false)
    },
  }

  return { uploadProps, isUploading }
}
