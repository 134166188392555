import { useLocation, useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../utils/hooks'
import { useEffect, useState } from 'react'
import { StripeHttp } from '../../../http/stripe-http'
import { success } from '../../ui/LoadSuccess'
import { IconLeftChevrons } from '../../svg/IconChevrons'
import { BankCard } from '../../../types/types'
import { Loader } from '../../ui/Loader'
import { CARD } from '../../../types/enum'
import { IconMaster } from '../../svg/IconMaster'
import { IconVisa } from '../../svg/IconVisa'

export const AdvertisementPayment = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const { _id } = useAppSelector((state) => state.user)
  const [cardId, setCardId] = useState<string>()

  const props: {
    adId: string
    amount: number
  } = location.state

  const validate = !Boolean(cardId && props.amount)

  const [cards, setCards] = useState<BankCard[]>()

  useEffect(() => {
    const body = async () => {
      const res: BankCard[] = await StripeHttp.getCards({ userId: _id })
      setCards(res)
    }
    body()
  }, [])

  // const confirmPayment = async() => {
  //     try {
  //         if(cardId && props.amount){
  //             await StripeHttp.payWithCard({cardId, amount:props.amount})

  //             success()
  //             navigate(-1)
  //         }
  //     } catch (error) {
  //         console.log(`Server error: ${error}`)
  //     }
  // }

  return (
    <>
      {cards ? (
        <div className="advertisement__add">
          <div className="advertisement__add-header">
            <div className="advertisement__add-header-btn" onClick={() => navigate(-1)}>
              <IconLeftChevrons fill="rgba(21, 16, 77, 1)" />
              <span>Payment</span>
            </div>
          </div>
          <h5 className="advertisement__add-main-title-bold">Choose card</h5>
          <div className="advertisement__add-cards">
            {cards.map((card) => (
              <div
                className={`advertisement__add-cards-item ${
                  cardId === card._id ? 'advertisement__add-cards-item-active' : ''
                }`}
                onClick={() => setCardId(card._id)}
              >
                {card.cardType === CARD.MASTER ? <IconMaster /> : <IconVisa />}
                <p>**** {`${card.cardNumber}`.slice(11, 15)}</p>
              </div>
            ))}
          </div>
          <button
            className={`advertisement__add-main-btn 
                    ${validate && 'advertisement__add-main-btn-disabled'}
                    `}
            style={{ margin: '60px 0' }}
            onClick={() => {}}
            disabled={validate}
          >
            Pay
          </button>
        </div>
      ) : (
        <Loader />
      )}
    </>
  )
}
