import { IconFlag } from '../../svg/IconFlag'
import { IconLeave } from '../../svg/IconLeave'
import { Modal } from '../../ui/Modal'

export const LeaveModalUser = ({
  isOpen,
  setIsOpen,
  reportGroup,
  leaveGroup,
}: {
  isOpen: boolean
  setIsOpen: (s: boolean) => void
  reportGroup: () => {}
  leaveGroup: () => {}
}) => {
  return (
    <div
      className={`activities__favor-modal  ${
        isOpen ? 'activities__favor-modal--open' : ''
      }`}
    >
      <Modal className="" setIsOpen={(s: boolean) => setIsOpen(s)}>
        <>
          <div
            onClick={(e) => {
              e.stopPropagation()
              setIsOpen(false)
            }}
            className="activities__favor-modal-linebody"
          >
            <button className="activities__favor-modal-line" />
          </div>
          <div className="publish__modaladd post__modal">
            <div className="post__modal_itembg">
              <div className="post__modal_item" onClick={reportGroup}>
                <div style={{ padding: '0 10px' }}>
                  <IconFlag />
                </div>
                <div>
                  <h5 className="post__modal_title">Report</h5>
                  <h6 className="post__modal-subtitle">Flag for review</h6>
                </div>
              </div>
              <div className="post__modal_item" onClick={leaveGroup}>
                <div style={{ padding: '0 0px' }}>
                  <IconLeave />
                </div>
                <div>
                  <h5 className="post__modal_title">Leave Group</h5>
                  <h6 className="post__modal-subtitle">Leave this group</h6>
                </div>
              </div>
            </div>
          </div>
        </>
      </Modal>
    </div>
  )
}
