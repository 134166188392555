import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { apiBaseURL } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { InputSearch } from '../../ui/InputSearch'
import { UserHeaderUserChatList } from '../header/UserHeaderChatList'

export const UserActivitiesCategory = () => {
  const { activities } = useAppSelector((state) => state.activities)
  const navigate = useNavigate()
  const [searsh, setSearch] = useState('')

  const toNextLink = ({ id }: { id: string }) => {
    navigate(`/user/activities/user-publish-activities-list?id=${id}`)
  }

  return (
    <>
      <UserHeaderUserChatList headerTitle={'Choose Activities'} />
      <div className="user__newsfeed-search">
        <InputSearch
          placeholder={
            <>
              Search<span> NeightborHarbor</span>
            </>
          }
          value={searsh}
          changeValue={setSearch}
        />
      </div>
      <div className="user__category">
        {activities
          .filter((item) => item.name.includes(searsh))
          .map((item) => (
            <div
              className="user__services-category-item-body"
              onClick={() =>
                toNextLink({
                  id: item._id,
                })
              }
            >
              <div className="user__services-category-item">
                <div className="user__services-category-item-img">
                  <img src={`${apiBaseURL}/uploads/activities/${item.fileName}`} alt="" />
                </div>
                <div className="user__services-category-item-text">
                  <b>{item.name}</b> <span>{item.numberView}</span>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  )
}
