import { useEffect } from 'react'
import { attemptFacebook } from '../../../redux/auth'
import { useAppDispatch } from '../../../utils/hooks'

export default function FacebookRedirect() {
  const dispatch = useAppDispatch()
  useEffect(() => {
    const queryParams = window.location.search
    dispatch(attemptFacebook(queryParams))
  }, [])

  return <></>
}
