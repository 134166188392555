import Select from '@mui/material/Select'
import { IconLeftChevrons } from '../../svg/IconChevrons'
import MenuItem from '@mui/material/MenuItem'
import { useState } from 'react'
import { ADVERTISEMENT, PAGES } from '../../../types/enum'
import { FileButton } from '../../ui/FileButton'
import { apiBaseURL, indexPathByRole } from '../../route/indexPathByRole'
import { IconAdminClose } from '../../svg/IconAdminHeader'
import { AdvertisementHttp } from '../../../http/advertisement'
import { Advertise } from '../../../types/types'
import { success } from '../../ui/LoadSuccess'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../utils/hooks'
import { SlickCategories } from '../../ui/SlickCategories'
import { AdvertisementStepper } from './AdvertisementStepper'

export const AdvertisementAdd = () => {
  const [page, setPage] = useState<string>(PAGES.MAIN)
  const [image, setImage] = useState<File | null>(null)
  const [fileName, setFileName] = useState<string>()
  const [type, setType] = useState<string>(ADVERTISEMENT.BANNER)
  const handlerChangeFileCategory = (file: File) => {
    setImage(file)
    setFileName('')
  }
  const validate = !Boolean(image && page && type)
  const navigate = useNavigate()
  const { role } = useAppSelector((state) => state.user)
  const createAdvertisement = async () => {
    try {
      const formCatData = new FormData()
      let payload: {
        type: string
        position: string
      } = {
        type,
        position: page,
      }
      formCatData.append('payload', JSON.stringify(payload))
      if (image) {
        formCatData.append('file', image)
      }
      const res = await AdvertisementHttp.addAdvertise(formCatData)
      success()
      navigate(`${indexPathByRole(role)}/advertisement/add-text-data`, {
        state: { page, adId: res._id },
      })
    } catch (error) {
      console.log('publication error' + error)
    }
  }
  return (
    <div className="advertisement__add">
      <div className="advertisement__add-header">
        <div className="advertisement__add-header-btn" onClick={() => navigate(-1)}>
          <IconLeftChevrons fill="rgba(21, 16, 77, 1)" />
          <span>Choose Medium</span>
        </div>
      </div>
      <AdvertisementStepper i={0} />
      <div className="advertisement__add-main">
        <h5 className="advertisement__add-main-title">Medium</h5>
        <SlickCategories show={2} scroll={1} className="advertisement__add-main-line">
          <div
            className={`advertisement__add-main-line-item ${type === ADVERTISEMENT.PUBLICATION ? 'advertisement__add-main-line-item-active' : ''}`}
            onClick={() => setType(ADVERTISEMENT.PUBLICATION)}
          >
            <img width={98} height={94} alt="" src="/Images/Megapone.png" />
            <span>Newsfeed</span>
          </div>
          <div
            style={{ margin: '0 10px' }}
            className={`advertisement__add-main-line-item ${type === ADVERTISEMENT.PROFILE ? 'advertisement__add-main-line-item-active' : ''}`}
            onClick={() => setType(ADVERTISEMENT.PROFILE)}
          >
            <img width={98} height={94} alt="" src="/Images/profileAd.png" />
            <span>Profile</span>
          </div>
          <div
            style={{ margin: '0 10px' }}
            className={`advertisement__add-main-line-item ${type === ADVERTISEMENT.BANNER ? 'advertisement__add-main-line-item-active' : ''}`}
            onClick={() => setType(ADVERTISEMENT.BANNER)}
          >
            <img
              style={{ padding: '3px 0' }}
              width={109}
              height={92}
              alt=""
              src="/Images/banner.png"
            />
            <span>Banner</span>
          </div>
        </SlickCategories>
        <h5 className="advertisement__add-main-title">Advertisement Position</h5>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={page}
          label="Age"
          onChange={(e) => setPage(e.target.value)}
        >
          <MenuItem value={PAGES.MAIN}>{PAGES.MAIN}</MenuItem>
          <MenuItem value={PAGES.SERVICES}>{PAGES.SERVICES}</MenuItem>
          <MenuItem value={PAGES.ACTIVITIES}>{PAGES.ACTIVITIES}</MenuItem>
        </Select>
        <h5 className="advertisement__add-main-title">Add file</h5>
        {fileName ? (
          <>
            <button
              className="services__add-remove"
              onClick={() => handlerChangeFileCategory(null as any)}
            >
              <IconAdminClose />
            </button>
            <img
              src={`${apiBaseURL}/uploads/categories/${fileName}`}
              alt=""
              className="services__add-linkimage"
            />
          </>
        ) : (
          <FileButton
            getFile={(file: File) => handlerChangeFileCategory(file)}
            image={image}
          />
        )}
      </div>
      <button
        className={`advertisement__add-main-btn ${
          validate && 'advertisement__add-main-btn-disabled'
        }`}
        onClick={createAdvertisement}
        disabled={validate}
      >
        Continue
      </button>
    </div>
  )
}
