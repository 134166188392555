export const IconCertificate = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path d="M22 2H2V22H22V2Z" fill="url(#pattern0_3101_16392)" />
      <defs>
        <pattern
          id="pattern0_3101_16392"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image0_3101_16392" transform="scale(0.01)" />
        </pattern>
        <image
          id="image0_3101_16392"
          width="100"
          height="100"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEIUlEQVR4nO2dzYscRRjGf5s1wV1NJAqCrmchqKCgETzuKV4ksK6nvUTBmA8Q8g8o4iGQVXMIIgRyyTmHrCuua/y6aCDrHpPFg4TEQ65J/MhFSyq8C0vvTHdnZrrfmq7ngRcGZrremufX3dVVXV0NkiSNl3YBx4HLwJ9AGHHEMn8BjlkuqURPA+sNQOgXvwJPiUj/I2O9RRibsQbsFJTtOu4AYzOOCMh2XS6Y9CUw04BRM1b21lw/C8h23S2Y1ASMTT1TyHVHQLYrFKJr+cZOQUDSUhCQtBQEJC0FAUlLQUD663ngW+Afx85a6GhET1eB5+ruqS8IBG2BiTt+pb5PYC8KmcR3dYDoNEVrQKLXlfLea0JmISD4QxAQ/I0XEPzNFhD8DRYQ/E0VEPyNFBD8zRMQ/A0TEPxNEpAEjAkC4m9GSCA0dII/BAHB33gBwd9sAcHfYAHB31QBwd9IAcHfPAHB3zABob4JN4A5YLfFQWAjAZOz7BjeAB7vUf+99p13/bIDMlfyH+YTqF92QHaX/Ic9CdRPQLbosQSMzg7IwZL/8FYC9csOyIY14EU9AdxMoH7ZAQl2NTVvbcYeOzLGCUbngIQOhIAwZkBWEqhkyCS+rgNkB7AAXAJuJ1Dp0LG4bY8LLpjXkiQNpfjQp5SQ138Dp4CXgKnm65SdpszbRfO6Ut6NXsgsBAR/CAKCv/ECgr/ZAoK/wQKCv6kCgr+RAkI68ZOFjhD8YfwAPApM27pXOmXhB2MZeHhLTy5+/mrIMtUxpNyguP78UeAT4CJwFbgHXOjzNoVd9t09++1F2/ZozbXsBYRygz7r4VC8bzFZ4txkn3sbsSwBYbhTyCFGp7cFhKHbiVdGCCSWpSOEwWH8CzwyQiDTVqbaEAYDEucRjFqXKnIeqCogZBgbNuFuogEgE1Z22XMrSwLCfSP+AN4FHqJ57TAwv/cA8pGAcN+IWdrXbI+XycT5yH0VMooV2tc3hTqcrNogZBav0Z5eLeT+C3iyaqOQ4fhUW1ou5P60zkYhw3i5eRa8CPxXWGY8vkizUt7mBAHJezH+JRI/ZeX2uor9JN6o5/RClxXG4LIXe/PLqs09DR2OWcagY9hVfdwDyM1xGDrpqpZKjpSrtlRHU4OLbwLXErmwSEqvV9znbnv4fd3qlLUm7NTxm+MNqut2qiy7T5+ddgKHgVst3sK9ZTljbqmPPmxwksM7hbI/GGHZndVcwbQ4p6qpaUBla3xJpmcLpq0B7w05UW7RyiheQMRcUoUmbQhj0KmkxfGpfhFzqBGvqbUH6N3/OOBk6yt1KyPBuRYeR4g5pJp634Y04h7/OXACeAPYZ0fC+RqGn7ff7rNtT1hZq1Z2zCGNsDN5pgTGWS0k4wPldA8YXwhGOqPGiw0NSEoDQIkhSRJt6n9SMXDnC7BOYQAAAABJRU5ErkJggg=="
        />
      </defs>
    </svg>
  )
}
