import moment from 'moment'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import $api from '../../../http'
import { ActivitiesHttp } from '../../../http/activities-http'
import { FeedBackHttp } from '../../../http/feedback-http'
import { PublishPostHttp } from '../../../http/publish-post-http'
import { ServiceHttp } from '../../../http/service-http'
import { NOTIFICATION_POST, ROLE } from '../../../types/enum'
import {
  LocationType,
  PostUserInterface,
  PublishEventItemInterface,
  PublishServiceItemInterface,
} from '../../../types/types'
import { apiBaseURL, indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { PublicationEventPanel } from '../../general-components/publication-lists/PublicationEventPanel'
import { PublicationPostsPanel } from '../../general-components/publication-lists/PublicationPostsPanel'
import { IconArrowRight } from '../../svg/IconArrow'
import { IconLeftChevrons } from '../../svg/IconChevrons'
import { IconStars } from '../../svg/IconFavor'
import { IconPostModalPin } from '../../svg/IconPostModal'
import { IconProfileInfoBookmark } from '../../svg/IconProfileInfo'
import { IconServicesAllPoint } from '../../svg/IconServicesAll'
import { PostSlick } from '../../ui/PostSlick'
import { UserHeader } from '../header/UserHeader'
import { calculateDistance, calculateRate } from '../../../utils/calculateRate'
import { inside } from '../../../utils/inside'

export const ExploreDistrict = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const props: { regId: string } = location.state
  const [region, setRegion] = useState<LocationType>()
  const { fullName } = useAppSelector((state) => state.user)
  const [publishService, setPublishService] = useState<PublishServiceItemInterface[]>([])
  const [posts, setPosts] = useState<PostUserInterface[]>([])
  const [events, setEvents] = useState<PublishEventItemInterface[]>([])
  const [publishServiceNormal, setPublishServiceNormal] = useState<
    PublishServiceItemInterface[]
  >([])
  const [postsNormal, setPostsNormal] = useState<PostUserInterface[]>([])
  const [eventsNormal, setEventsNormal] = useState<PublishEventItemInterface[]>([])
  useEffect(() => {
    //services
    ServiceHttp.getTenPublishService().then((res) => setPublishService(res))
    //posts
    PublishPostHttp.getTenPosts().then((res) => setPosts(res))
    //events
    ActivitiesHttp.getTenPublishActivities({ userId: _id }).then((res) => {
      setEvents(res)
    })
  }, [])
  const { coordinates } = useAppSelector((state) => state.profile)

  useEffect(() => {
    if (region) {
      let arr: PublishEventItemInterface[] = []
      events.map((item) => {
        if (inside([item.coordinates.lat, item.coordinates.lng], region?.polygonCoords)) {
          arr.push(item)
        }
      })
      setEventsNormal(arr)
      console.log(arr)
    }
  }, [events])
  useEffect(() => {
    if (region) {
      let arr: PublishServiceItemInterface[] = []
      publishService.map((item) => {
        if (inside([item.coordinates.lat, item.coordinates.lng], region?.polygonCoords)) {
          arr.push(item)
        }
      })
      setPublishServiceNormal(arr)
      console.log(arr)
    }
  }, [publishService])
  useEffect(() => {
    if (region) {
      let arr: PostUserInterface[] = []
      posts.map((item) => {
        if (inside([item.coordinates.lat, item.coordinates.lng], region?.polygonCoords)) {
          arr.push(item)
        }
      })
      setPostsNormal(arr)
      console.log(arr)
    }
  }, [posts])

  useEffect(() => {
    if (props.regId) {
      $api
        .post('map/get-one-mark', { markId: props.regId })
        .then((res) => setRegion(res.data))
    }
  }, [])
  const [isOpen, setIsOpen] = useState(false)
  const { _id } = useAppSelector((state) => state.user)
  const toPublishActivities = (_id: string) => {
    navigate(`/user/activities/publish-activities-item?publishActivitiesId=${_id}`)
  }
  const toPublishService = (_id: string) => {
    navigate(`/user/service/publish-service?publishServiceId=${_id}`)
  }
  const updateLike = async (likeId: string, postId: string) => {
    await FeedBackHttp.updateLike({
      likeId,
      userId: _id,
    })
    setPosts((s) =>
      s.map((p) => {
        if (p._id === postId) {
          return {
            ...p,
            isLiked: !p.isLiked,
            likes: p.isLiked ? p.likes - 1 : p.likes + 1,
          }
        } else {
          return p
        }
      }),
    )
  }
  const { role } = useAppSelector((state) => state.user)
  const navigateToComments = (postId: string) => {
    navigate(`${indexPathByRole(role)}/comments?postId=${postId}`)
  }

  const toProfileInfo = (prop: {
    _id: string
    email: string
    role: string
    fullName: string
    avatarFileName: string
  }) => {
    navigate('/profileinfo', {
      state: prop,
    })
  }

  const updateRepost = async ({
    postId,
    isReposted,
  }: {
    postId: string
    isReposted: boolean
  }) => {
    await PublishPostHttp.updateRepost({
      postId,
      repostedUserId: _id,
      shareList: [],
    })
  }

  const updateMark = async (postId: string, isMarked: boolean) => {
    if (isMarked) {
      await PublishPostHttp.deleteMark({
        postId,
        marckedUserId: _id,
      })
    } else {
      await PublishPostHttp.addMark({
        postId,
        marckedUserId: _id,
      })
      setIsOpen(true)
    }

    setPosts((s) =>
      s.map((p) => {
        if (p._id === postId) {
          const newItem = {
            ...p,
            isMarked: !p.isMarked,
          }
          return newItem
        } else {
          return p
        }
      }),
    )
  }

  const updateNotification = async (
    postId: string,
    typeNotification: NOTIFICATION_POST,
  ) => {
    await PublishPostHttp.updateNotification({
      postId: postId,
      userId: _id,
      typeNotification,
    })

    setPostsNormal((s) =>
      s.map((p) => {
        if (p._id === postId) {
          const newItem = {
            ...p,
            [NOTIFICATION_POST.COMMENT === typeNotification
              ? 'isNotificatedComment'
              : 'isNotificatedPost']:
              NOTIFICATION_POST.COMMENT === typeNotification
                ? !p.isNotificatedComment
                : !p.isNotificatedPost,
          }
          return newItem
        } else {
          return p
        }
      }),
    )
  }
  return (
    <>
      <UserHeader>
        <div className="explore__invites-header">
          <button onClick={() => navigate(-1)}>
            <IconLeftChevrons />
          </button>
          <div className="explore__invites-header-text">
            <h5>
              <b>{region?.title}</b>
            </h5>
            <h6>
              <b>{fullName}</b> there are <b>31 new results</b>
            </h6>
          </div>
        </div>
      </UserHeader>
      <div style={{ margin: '100px 0', padding: '10px' }}>
        <h5 style={{ fontSize: '20px', color: 'rgba(21, 16, 77, 1)' }}>
          <b>Events</b> ({eventsNormal.length})
        </h5>
        {eventsNormal.map((item) => (
          <div
            className="user__event-item"
            onClick={() => toPublishActivities(item._id)}
            style={
              !item.peopleSaw.includes(_id)
                ? { border: '2px solid rgba(51, 97, 255, 0.5)' }
                : { border: '0' }
            }
          >
            <div className="user__event-item-row1">
              <div className="user__event-item-row1-text">
                <h5>{item.title}</h5>
                <h6>{item.addressLocation}</h6>
              </div>
            </div>
            <div className="admin__posts-list-row2" onClick={() => {}}>
              <PostSlick off={true} list={item?.filesName}>
                {item.filesName.length ? (
                  item?.filesName?.map((it) => (
                    <>
                      <div className="admin__posts-list-row2-img" onClick={() => {}}>
                        <div className="user__event-item-row2">
                          <IconServicesAllPoint />
                        </div>
                        <div className="user__services-last-item-img-1 user__services-last-item-img-info">
                          <span
                            className="admin__posts-list-row1-text"
                            style={{ color: 'rgba(21, 16, 77, 1)', padding: '20px' }}
                          >
                            {moment(item?.startDate).format('DD/MM/YYYY HH:mm')}
                          </span>{' '}
                        </div>
                        <img src={`${apiBaseURL}/uploads/publish_post/${it}`} alt="" />
                        <div
                          className="user__services-last-item-img-1 user__services-last-item-img-info"
                          style={{ width: '100px', height: '35px', top: '85%' }}
                        >
                          <div className="user__services-last-item-img-text">
                            <span
                              className="admin__posts-list-row1-text"
                              style={{ color: 'rgba(21, 16, 77, 1)' }}
                            >
                              {item.activitiesId.name}
                            </span>{' '}
                          </div>
                        </div>
                      </div>
                    </>
                  ))
                ) : (
                  <div
                    className="admin__posts-list-row2-img"
                    onClick={() => toPublishActivities(item._id)}
                  >
                    <div className="user__event-item-row2">
                      <IconServicesAllPoint />
                    </div>
                    <div
                      className="user__services-last-item-img-1 user__services-last-item-img-info"
                      style={{ width: 'auto', height: 'auto', padding: '0 10px' }}
                    >
                      <div className="user__services-last-item-img-text">
                        <span
                          className="admin__posts-list-row1-text"
                          style={{ color: 'rgba(21, 16, 77, 1)', margin: '10px 0' }}
                        >
                          {moment(item?.startDate).format('DD/MM/YYYY HH:mm')}
                        </span>{' '}
                      </div>
                    </div>
                    <img className="posts__bg" src={`/Images/postsBG.png`} alt="" />
                    <div
                      className="user__services-last-item-img-1 user__services-last-item-img-info"
                      style={{ width: '100px', height: '35px', top: '85%' }}
                    >
                      <div className="user__services-last-item-img-text">
                        <span
                          className="admin__posts-list-row1-text"
                          style={{ color: 'rgba(21, 16, 77, 1)' }}
                        >
                          {item.activitiesId.name}
                        </span>{' '}
                      </div>
                    </div>
                  </div>
                )}
              </PostSlick>
            </div>
            <div className="user__event-item-row3">
              <h6>{item.text}</h6>
            </div>
            <div className="user__event-item-row4">
              <PublicationEventPanel
                item={item}
                updateLike={() => {}}
                navigateToComments={() => {}}
                updateMark={() => {}}
                updateRepost={() => {}}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              />
            </div>
          </div>
        ))}
        <h5 style={{ fontSize: '20px', color: 'rgba(21, 16, 77, 1)', margin: '20px 0' }}>
          <b>Services</b> ({publishServiceNormal.length})
        </h5>
        {publishServiceNormal.map((item) => (
          <>
            <div
              style={
                !item?.peopleSaw?.includes(_id)
                  ? { border: '2px solid rgba(51, 97, 255, 0.5)' }
                  : { border: '0' }
              }
              className="user__services-last-item"
              onClick={() => toPublishService(item._id)}
            >
              <div className="user__services-last-item-img">
                <div className="user__services-last-item-img-1 user__services-last-item-img-info">
                  <div className="user__services-last-item-img-text">
                    {item.categoryId.name}
                  </div>
                </div>
                {item.filesName.length > 0 ? (
                  <img
                    src={`${apiBaseURL}/uploads/publish_services/${item.filesName[0]}`}
                    alt=""
                  />
                ) : (
                  <img className="posts__bg" src={`/Images/postsBG.png`} alt="" />
                )}

                {item.reviews.length ? (
                  <div className="user__services-last-item-img-2 user__services-last-item-img-info">
                    <div className="user__services-last-item-img-text">
                      {item.reviews.length ? (
                        <>
                          <IconStars />
                          <b>{calculateRate(item)}</b>
                          <span>{`(${item.reviews.length})`}</span>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className="user__services-last-item-info">
                <div className="user__services-last-item-row1">
                  <h5 className="user__services-last-item-title">{item.title}</h5>
                  <button>
                    <IconProfileInfoBookmark />
                  </button>
                </div>
                <h6 className="user__services-last-item-text">{item.text}</h6>
                <div className="user__services-last-item-foot">
                  {Math.floor(calculateDistance(coordinates, item.coordinates) / 1000)} km
                  <IconArrowRight />
                </div>
              </div>
            </div>
          </>
        ))}
        <h5 style={{ fontSize: '20px', color: 'rgba(21, 16, 77, 1)', margin: '20px 0' }}>
          <b>Posts</b> ({postsNormal.length})
        </h5>
        {postsNormal.map((item) => (
          <>
            {item.repostedUserId &&
            item?.shareList?.find(
              (it) => it.friendId._id === _id || item.repostedUserId?._id === _id,
            ) ? (
              <div
                style={
                  !item?.peopleSaw?.includes(_id)
                    ? { border: '2px solid rgba(51, 97, 255, 0.5)' }
                    : { border: '0' }
                }
                className="admin__posts-list-item"
                key={item?._id}
              >
                <div className="admin__posts-list-repost">
                  <div className="admin__posts-list-repost-profileInfo">
                    <div
                      className="admin__posts-list-row1-img"
                      onClick={() =>
                        toProfileInfo({
                          _id: item.repostedUserId?._id || '',
                          email: '',
                          role: ROLE.USER,
                          fullName: item.repostedUserId?.fullName || '',
                          avatarFileName: item?.repostedUserId?.avatarFileName || '',
                        })
                      }
                    >
                      {item.repostedUserId.avatarFileName ? (
                        <img
                          src={`${apiBaseURL}/uploads/avatar/${item.repostedUserId.avatarFileName}`}
                        />
                      ) : (
                        <div
                          style={{
                            backgroundColor: 'rgba(41, 52, 65, 1)',
                            borderRadius: '100%',
                            width: '40px',
                            height: '40px',
                            color: '#fff',
                            textAlign: 'center',
                            position: 'relative',
                            margin: '5px 0',
                          }}
                        >
                          <span
                            style={{ position: 'absolute', left: '10px', top: '8px' }}
                          >
                            {item.repostedUserId.fullName.slice(0, 2)}
                          </span>
                        </div>
                      )}
                    </div>
                    <div onClick={() => navigateToComments(item._id)}>
                      <div className="admin__posts-list-row1-name">
                        {item.repostedUserId?.fullName}
                      </div>
                      <div>
                        <span className="admin__posts-list-row1-text">
                          {moment(item?.createdRepostDate).format('DD MMM YYYY HH:mm')}
                        </span>
                      </div>
                    </div>
                  </div>
                  <button onClick={() => {}}>
                    <IconServicesAllPoint />
                  </button>
                </div>
                <div className="reposted">
                  <div className="admin__posts-list-row1" style={{ padding: '10px' }}>
                    <div
                      className="admin__posts-list-row1-img"
                      onClick={() =>
                        toProfileInfo({
                          _id: item.userId?._id,
                          email: '',
                          role: ROLE.USER,
                          fullName: item.userId?.fullName,
                          avatarFileName: item?.userId?.avatarFileName || '',
                        })
                      }
                    >
                      {item.userId.avatarFileName ? (
                        <img
                          src={`${apiBaseURL}/uploads/avatar/${item.userId.avatarFileName}`}
                        />
                      ) : (
                        <div
                          style={{
                            backgroundColor: 'rgba(41, 52, 65, 1)',
                            borderRadius: '100%',
                            width: '40px',
                            height: '40px',
                            color: '#fff',
                            textAlign: 'center',
                            position: 'relative',
                            margin: '5px 0',
                          }}
                        >
                          <span
                            style={{ position: 'absolute', left: '10px', top: '8px' }}
                          >
                            {item.userId.fullName.slice(0, 2)}
                          </span>
                        </div>
                      )}
                    </div>
                    <div onClick={() => navigateToComments(item._id)}>
                      <div className="admin__posts-list-row1-name">
                        {item.userId?.fullName}
                        {item.isPinedPostFlag && (
                          <span className="admin__posts-list-row1-pin">
                            <IconPostModalPin />
                          </span>
                        )}
                      </div>
                      <div>
                        <span className="admin__posts-list-row1-text">
                          {moment(item?.createdPostDate).format('DD MMM YYYY HH:mm')}
                        </span>{' '}
                        <span className="admin__posts-list-row1-text admin__posts-list-row1-textunder">
                          {item?.addressLocation}
                        </span>
                      </div>
                    </div>
                    {/* <button onClick={() => openModal(item)}>
                                    <IconServicesAllPoint />
                                </button> */}
                  </div>
                  {item.filesName.length > 0 ? (
                    <div
                      className="admin__posts-list-row2"
                      style={{ padding: '10px' }}
                      onClick={() => navigateToComments(item._id)}
                    >
                      <PostSlick list={item?.filesName}>
                        {item?.filesName?.map((it) => (
                          <div
                            className="admin__posts-list-row2-img"
                            onClick={() => navigateToComments(item._id)}
                          >
                            <img
                              src={`${apiBaseURL}/uploads/publish_post/${it}`}
                              alt=""
                            />
                          </div>
                        ))}
                      </PostSlick>
                    </div>
                  ) : (
                    ''
                  )}
                  <div
                    style={{ padding: '10px' }}
                    className="admin__posts-list-row3"
                    onClick={() => navigateToComments(item._id)}
                  >
                    <h5>{item.title}</h5>
                    <h6>{item.text}</h6>
                  </div>
                </div>
                <PublicationPostsPanel
                  item={item}
                  updateLike={updateLike}
                  navigateToComments={navigateToComments}
                  updateMark={updateMark}
                  updateRepost={updateRepost}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                />
                {/* <PublicationPostsPanel
                                item={item}
                                updateLike={updateLike}
                                navigateToComments={navigateToComments}
                                updateMark={updateMark}
                                updateRepost={updateRepost}
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                            /> */}
              </div>
            ) : (
              <>
                {!item?.shareList?.length && (
                  <>
                    <div className="admin__posts-list-item" key={item?._id}>
                      <div className="admin__posts-list-row1" style={{ padding: '10px' }}>
                        <div
                          className="admin__posts-list-row1-img"
                          onClick={() =>
                            toProfileInfo({
                              _id: item.userId?._id,
                              email: '',
                              role: ROLE.USER,
                              fullName: item.userId?.fullName,
                              avatarFileName: item?.userId?.avatarFileName || '',
                            })
                          }
                        >
                          {item.userId.avatarFileName ? (
                            <img
                              src={`${apiBaseURL}/uploads/avatar/${item.userId.avatarFileName}`}
                            />
                          ) : (
                            <div
                              style={{
                                backgroundColor: 'rgba(41, 52, 65, 1)',
                                borderRadius: '100%',
                                width: '40px',
                                height: '40px',
                                color: '#fff',
                                textAlign: 'center',
                                position: 'relative',
                                margin: '5px 0',
                              }}
                            >
                              <span
                                style={{ position: 'absolute', left: '10px', top: '8px' }}
                              >
                                {item.userId.fullName.slice(0, 2)}
                              </span>
                            </div>
                          )}
                        </div>
                        <div onClick={() => navigateToComments(item._id)}>
                          <div className="admin__posts-list-row1-name">
                            {item.userId?.fullName}
                            {item.isPinedPostFlag && (
                              <span className="admin__posts-list-row1-pin">
                                <IconPostModalPin />
                              </span>
                            )}
                          </div>
                          <div>
                            <span className="admin__posts-list-row1-text">
                              {moment(item?.createdPostDate).format('DD MMM YYYY HH:mm')}
                            </span>{' '}
                            <span className="admin__posts-list-row1-text admin__posts-list-row1-textunder">
                              {item?.addressLocation}
                            </span>
                          </div>
                        </div>
                        <button onClick={() => {}}>
                          <IconServicesAllPoint />
                        </button>
                      </div>
                      {item.filesName.length > 0 ? (
                        <div
                          className="admin__posts-list-row2"
                          style={{ padding: '10px' }}
                          onClick={() => navigateToComments(item._id)}
                        >
                          <PostSlick list={item?.filesName}>
                            {item?.filesName?.map((it) => (
                              <div
                                className="admin__posts-list-row2-img"
                                onClick={() => navigateToComments(item._id)}
                              >
                                <img
                                  src={`${apiBaseURL}/uploads/publish_post/${it}`}
                                  alt=""
                                />
                              </div>
                            ))}
                          </PostSlick>
                        </div>
                      ) : (
                        ''
                      )}
                      <div
                        style={{ padding: '10px' }}
                        className="admin__posts-list-row3"
                        onClick={() => navigateToComments(item._id)}
                      >
                        <h5>{item.title}</h5>
                        <h6>{item.text}</h6>
                      </div>
                      <PublicationPostsPanel
                        item={item}
                        updateLike={updateLike}
                        navigateToComments={navigateToComments}
                        updateMark={updateMark}
                        updateRepost={updateRepost}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </>
        ))}
      </div>
    </>
  )
}
