import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ServiceHttp } from '../../../http/service-http'
import { PRIVACY } from '../../../types/enum'
import { PublishServiceItemInterface } from '../../../types/types'
import { indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { PublishPrivacyModal } from '../../general-components/publication/PublishPrivacyModal'
import { IconOpenEye } from '../../svg/IconPassEye'
import { IconPostModalDelete, IconPostModalPromote } from '../../svg/IconPostModal'
import { IconPostsRepost } from '../../svg/IconPosts'
import {
  IconProfileInfoNotification,
  IconProfileInfoPen,
} from '../../svg/IconProfileInfo'
import { Modal } from '../../ui/Modal'
import { PreviewModal } from '../../general-components/preview/PreviewModal'

export const UserServiceMyModal = ({
  isOpen,
  setIsOpen,
  item,
  type,
  updateNotification,
}: {
  isOpen: boolean
  setIsOpen: (s: boolean) => void
  item: PublishServiceItemInterface
  type: string
  updateNotification: (serviceId: string) => void
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { role } = useAppSelector((state) => state.user)

  const [openPrivacy, setOpenPrivacy] = useState(false)
  const [openPrivacyComment, setOpenPrivacyComment] = useState(false)

  const [currentPrivacy, setCurrentPrivacy] = useState(
    item?.privacyPublishService || PRIVACY.EVERYONE,
  )
  const [isOpenShare, setIsOpenShare] = useState(false)
  const copy = () => {
    const url = `${window.location.host}/preview/service?publishServiceId=${item?._id}`
    navigator.clipboard.writeText(url)
  }

  const editService = () => {
    navigate(`/publish/service`, {
      state: {
        props: {
          item,
        },
      },
    })
  }

  const openChangePrivacy = () => {
    setOpenPrivacy(true)
    setIsOpen(false)
  }

  const changePrivacy = async () => {
    if (item?._id) {
      ServiceHttp.changeServicePrivacy({
        serviceId: item?._id,
        privacyService: currentPrivacy,
      })
    }
  }

  const deleteService = async () => {
    if (item?._id) {
      await ServiceHttp.deletePublishService({ _id: item._id })
    }
    setIsOpen(false)
    if (location.pathname === `${indexPathByRole(role)}/service/publish-service`) {
      navigate(-1)
    }
  }

  const copyLink = async () => {
    const text = window.location.href
    navigate(`${indexPathByRole(role)}/service/share-list`, { state: { text } })
  }

  return (
    <>
      <div
        className={`activities__favor-modal  ${
          isOpen ? 'activities__favor-modal--open' : ''
        }`}
      >
        <Modal className="" setIsOpen={(s: boolean) => setIsOpen(s)}>
          <div
            onClick={(e) => {
              e.stopPropagation()
              setIsOpen(false)
            }}
            className="activities__favor-modal-linebody"
          >
            <button className="activities__favor-modal-line" />
          </div>
          <div className="publish__modaladd post__modal">
            <div className="post__modal_itembg">
              <div
                className="post__modal_item"
                onClick={() => navigate(`${indexPathByRole(role)}/advertisement/add`)}
              >
                <div>
                  <IconPostModalPromote />
                </div>
                <div>
                  <h5 className="post__modal_title">Promote {type}</h5>
                  <h6 className="post__modal-subtitle">Advertise your {type}</h6>
                </div>
              </div>
            </div>

            <div className="post__modal_itembg">
              {/* {updatePin} */}

              <div className="post__modal_item" onClick={() => setIsOpenShare(true)}>
                <div className={`post__modal_item-button`}>
                  <IconPostsRepost color="rgba(15, 20, 25, 1)" />
                </div>
                <div>
                  <h5 className="post__modal_title">Share</h5>
                  <h6 className="post__modal-subtitle">Share {type} to your neighbors</h6>
                </div>
              </div>

              <div className="post__modal_item" onClick={editService}>
                <div className="post__modal_item-button-1">
                  <IconProfileInfoPen />
                </div>
                <div>
                  <h5 className="post__modal_title">Edit {type}</h5>
                  <h6 className="post__modal-subtitle">Make changes of this {type}</h6>
                </div>
              </div>

              <div className="post__modal_item" onClick={openChangePrivacy}>
                <div className="post__modal_item-button-1">
                  <IconOpenEye />
                </div>
                <div>
                  <h5 className="post__modal_title">Change Privacy</h5>
                  <h6 className="post__modal-subtitle">
                    Control who can see your {type}
                  </h6>
                </div>
              </div>

              <div
                className="post__modal_item"
                onClick={() => updateNotification(item._id)}
              >
                <div className={`post__modal_item-button-1 `}>
                  <IconProfileInfoNotification />
                </div>
                <div>
                  <h5 className="post__modal_title">Turn on Notifications</h5>
                  <h6 className="post__modal-subtitle">
                    Get notified about new comments
                  </h6>
                </div>
              </div>

              <div className="post__modal_item" onClick={deleteService}>
                <div className="post__modal_item-button-1">
                  <IconPostModalDelete />
                </div>
                <div>
                  <h5 className="post__modal_title">Delete {type}</h5>
                  <h6 className="post__modal-subtitle">Delete this {type} forever</h6>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>

      <PublishPrivacyModal
        isOpen={openPrivacy}
        setIsOpen={setOpenPrivacy}
        currentPrivacy={currentPrivacy}
        setCurrentPrivacy={setCurrentPrivacy}
        applyMethod={changePrivacy}
        subtitle={'Control who can see your service'}
      />
      <PreviewModal
        setIsOpen={setIsOpenShare}
        isOpen={isOpenShare}
        copy={copy}
        apply={copyLink}
      />
    </>
  )
}
