import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Slider from 'react-slick'
import { ActivitiesHttp } from '../../../http/activities-http'
import { FeedBackHttp } from '../../../http/feedback-http'
import { PublishPostHttp } from '../../../http/publish-post-http'
import { ServiceHttp } from '../../../http/service-http'
import { NOTIFICATION_POST, ROLE } from '../../../types/enum'
import {
  PostUserInterface,
  PublishEventItemInterface,
  PublishServiceItemInterface,
  ServiceInterfaceMarked,
} from '../../../types/types'
import { apiBaseURL, indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconArrowRight } from '../../svg/IconArrow'
import { IconLeftChevrons } from '../../svg/IconChevrons'
import { IconStars } from '../../svg/IconFavor'
import { IconPostModalPin } from '../../svg/IconPostModal'
import {
  IconProfileInfoBookmark,
  IconProfileInfoBookmarkCopy,
} from '../../svg/IconProfileInfo'
import { IconServicesAllPoint } from '../../svg/IconServicesAll'
import { DarkBg } from '../../ui/DarkBg'
import { Loader } from '../../ui/Loader'
import { PostSlick } from '../../ui/PostSlick'
import { ActivitiesMyModal } from '../../user-components/activities/ActivitiesMyModal'
import { UserHeader } from '../../user-components/header/UserHeader'
import { PublicationEventPanel } from '../publication-lists/PublicationEventPanel'
import { PublicationPostMyModal } from '../publication-lists/PublicationPostMyModal'
import { PublicationPostNeibModal } from '../publication-lists/PublicationPostNeibModal'
import { PublicationPostsPanel } from '../publication-lists/PublicationPostsPanel'
import { ActivitiesNeibModalMany } from '../../user-components/activities/ActivitiesNeibModalMany'
import { calculateDistance } from '../../../utils/calculateRate'

export const ProfileInfoBookMark = () => {
  const { _id, role } = useAppSelector((state) => state.user)
  const navigate = useNavigate()
  const [posts, setPosts] = useState<PostUserInterface[]>()
  const [services, setServices] = useState<ServiceInterfaceMarked[]>()
  const [currnetItem, setCurrentItem] = useState<PostUserInterface | null>(null)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [currentItemEvent, setCurrentItemEvent] = useState<PublishEventItemInterface>()
  const { publishActivities } = useAppSelector((state) => state.activities)
  const { publishPostsWithMark: publishPosts } = useAppSelector((state) => state.posts)
  const { markedServices } = useAppSelector((state) => state.categories)
  const [IsOpen, setIsOpen] = useState(false)
  const [myModalOpen, setMyModalOpen] = useState(false)
  const [neibModalOpen, setNeibModalOpen] = useState(false)
  const [isOpenSave, setIsOpenSave] = useState(false)
  const [publishActivitiesList, setPublishActivitiesList] = useState<
    PublishEventItemInterface[]
  >([])

  useEffect(() => {
    setPublishActivitiesList(publishActivities.filter((it) => it.isMarked))
    setPosts(publishPosts)
    setServices(markedServices.filter((it) => it.marckedUserId === _id))
    console.log(markedServices)
  }, [publishActivities, publishPosts, markedServices])
  const toProfileInfo = (prop: {
    _id: string
    email: string
    role: string
    fullName: string
    avatarFileName: string
  }) => {
    navigate('/profileinfo', {
      state: prop,
    })
  }
  const toPublishActivities = (_id: string) => {
    navigate(`/user/activities/publish-activities-item?publishActivitiesId=${_id}`)
  }
  const toPublishService = (_id: string) => {
    navigate(`/user/service/publish-service?publishServiceId=${_id}`)
  }
  const deletePost = (item: PostUserInterface) => {
    if (posts) {
      const arr = posts.filter((it) => it !== item)
      setPosts(arr)
    }
  }
  const openModal = (item: PostUserInterface) => {
    setCurrentItem(item)
    if (item.userId._id === _id) {
      setMyModalOpen(true)
    } else {
      setNeibModalOpen(true)
    }
  }

  const toSkills = () => {
    navigate(`interestsskills`, { state: { skills: true } })
  }
  const toInterests = () => {
    navigate(`interestsskills`, { state: { skills: false } })
  }

  const calculateRate = (item: PublishServiceItemInterface) => {
    let rate: number[] = []
    item.reviews.map((review) => {
      rate.push(review.rate)
    })
    return rate.reduce((partialSum, a) => partialSum + a, 0) / rate.length
  }

  const updateMarkService = async (
    serviceId: string,
    isMarked: boolean,
    item: ServiceInterfaceMarked,
  ) => {
    await ServiceHttp.deleteMark({
      serviceId,
      marckedUserId: _id,
    })

    if (services) {
      let array: ServiceInterfaceMarked[] = [...services]
      let index = array.indexOf(item)
      if (index !== -1) {
        array.splice(index, 1)
        setServices(array)
      }
    }
  }

  const updateRepost = async ({
    postId,
    isReposted,
  }: {
    postId: string
    isReposted: boolean
  }) => {
    await PublishPostHttp.updateRepost({
      postId,
      repostedUserId: _id,
      shareList: [],
    })
  }

  const navigateToComments = (postId: string) => {
    navigate(`${indexPathByRole(role)}/comments?postId=${postId}`)
  }
  const updateLike = async (likeId: string, postId: string) => {
    await FeedBackHttp.updateLike({
      likeId,
      userId: _id,
    })
    setPosts((s) =>
      s?.map((p) => {
        if (p?._id === postId) {
          return {
            ...p,
            isLiked: !p?.isLiked,
            countLikes: p?.isLiked ? p?.countLikes - 1 : p?.countLikes + 1,
          }
        } else {
          return p
        }
      }),
    )
  }
  const [content, setContent] = useState<{ postId: string; content: boolean }[]>([])
  const updateContent = (item: PostUserInterface, val: boolean) => {
    setContent([...content, { postId: item._id, content: val }])
  }

  const updateLikeEvent = async (likeId: string, serviceId: string) => {
    await FeedBackHttp.updateLike({
      likeId,
      userId: _id,
    })
    let arr: PublishEventItemInterface[] = []
    publishActivitiesList.map((item) => {
      if (item._id === serviceId) {
        arr.push({
          ...item,
          isLiked: !item?.isLiked,
          countLikes: item.isLiked ? item.countLikes - 1 : item.countLikes + 1,
        })
      } else {
        arr.push(item)
      }
    })
    setPublishActivitiesList(arr)
  }
  const updateMarkEvent = async (
    eventId: string,
    isMarked: boolean,
    item: PublishEventItemInterface,
  ) => {
    if (isMarked) {
      await ActivitiesHttp.deleteMark({
        eventId,
        marckedUserId: _id,
      })
    } else {
      await ActivitiesHttp.addMark({
        eventId,
        marckedUserId: _id,
      })
      setIsOpenSave(true)
    }
    if (publishActivitiesList) {
      let array: PublishEventItemInterface[] = [...publishActivitiesList]
      let index = array.indexOf(item)
      if (index !== -1) {
        array.splice(index, 1)
        setPublishActivitiesList(array)
      }
    }
  }

  const copyLink = async (item: string) => {
    const text = `${window.location.href}/publish-activities-item?publishActivitiesId=${item}`
    navigate(`${indexPathByRole(role)}/service/share-list`, { state: { text } })
  }
  const updatePin = async (repostId: string) => {
    await PublishPostHttp.updatePin({
      repostId: repostId,
      userId: _id,
    })

    setPosts((s) =>
      s?.map((p) => {
        if (p.repostId === repostId) {
          const newItem = {
            ...p,
            isPined: !p.isPined,
          }
          setCurrentItem(newItem)
          return newItem
        } else {
          return p
        }
      }),
    )
  }
  const updateNotification = async (serviceId: string) => {
    if (publishActivitiesList) {
      await ActivitiesHttp.updateNotification({
        eventId: serviceId,
        userId: _id,
      })

      setPublishActivitiesList((s) =>
        s.map((p) => {
          if (p._id === serviceId) {
            return {
              ...p,
              isNotificatedEvent: !p.isNotificatedEvent,
            }
          } else {
            return p
          }
        }),
      )
    }
  }

  const hidePost = async (body: { hideUserId?: string; hideRepostId?: string }) => {
    await PublishPostHttp.hidePost({
      ownerId: _id,
      ...body,
    })

    setPosts((s) =>
      s?.filter(
        (item) =>
          !(
            item?.repostId === body?.hideRepostId || item?.userId._id === body?.hideUserId
          ),
      ),
    )

    setNeibModalOpen(false)
  }
  const updateNotificationPost = async (
    postId: string,
    typeNotification: NOTIFICATION_POST,
  ) => {
    await PublishPostHttp.updateNotification({
      postId: postId,
      userId: _id,
      typeNotification,
    })

    setPosts((s) =>
      s?.map((p) => {
        if (p._id === postId) {
          const newItem = {
            ...p,
            [NOTIFICATION_POST.COMMENT === typeNotification
              ? 'isNotificatedComment'
              : 'isNotificatedPost']:
              NOTIFICATION_POST.COMMENT === typeNotification
                ? !p.isNotificatedComment
                : !p.isNotificatedPost,
          }
          setCurrentItem(newItem)
          return newItem
        } else {
          return p
        }
      }),
    )
  }
  const updateMark = async (isMarked: boolean, item: PostUserInterface) => {
    await PublishPostHttp.deleteMark({
      postId: item._id,
      marckedUserId: _id,
    })

    if (posts && item) {
      let array: PostUserInterface[] = [...posts]
      array.filter((it) => {
        if (it === item) {
          let index = array.indexOf(it)
          if (index !== -1) {
            array.splice(index, 1)
            setPosts(array)
          }
        }
      })
    }
  }
  const { coordinates } = useAppSelector((state) => state.profile)

  const [currentSlide, setCurrentSlide] = useState(0)
  const [filter, setFilter] = useState<string>('posts')
  const toComments = (index: number, item: string) => {
    setFilter(item)
    setCurrentSlide(index)
  }
  const categories = ['Posts', 'Events', 'Services']
  var settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    // afterChange: (n:number) => setCurrentSlide(n)
  }

  return (
    <>
      {services ? (
        <div className="profileinfo__bookmark">
          <UserHeader>
            <div className="profileinfo__bookmark-header" style={{ margin: '0 20px' }}>
              <button onClick={() => navigate(-1)}>
                <IconLeftChevrons />
              </button>
              <h5 onClick={() => navigate(-1)}>BookMark</h5>
            </div>
          </UserHeader>
          <div className="user__event__filter" style={{ margin: '10px -10px' }}>
            <div className="activities__filter">
              <Slider {...settings}>
                {categories.map((item, index) => (
                  <div
                    className={`activities__filter-item ${
                      index === currentSlide && 'activities__filter-item--active'
                    }`}
                    key={index}
                    onClick={() => toComments(index, item.toLocaleLowerCase())}
                  >
                    <span style={{ textTransform: 'capitalize' }}>{item}</span>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
          <div className="profileinfo__bookmark-container">
            {posts && filter === 'posts' && (
              <>
                {filter === 'posts' && <h5>Latest Posts ({posts?.length})</h5>}
                {posts?.map((item) => (
                  <>
                    {item && (
                      <div
                        style={{
                          margin: '10px 0',
                          backgroundColor: '#fff',
                          padding: '10px',
                        }}
                      >
                        <div
                          className="admin__posts-list-row1"
                          style={{ padding: '10px' }}
                        >
                          <div
                            className="admin__posts-list-row1-img"
                            onClick={() =>
                              toProfileInfo({
                                _id: item?.userId?._id,
                                email: '',
                                role: ROLE.USER,
                                fullName: item?.userId?.fullName,
                                avatarFileName: item?.userId?.avatarFileName || '',
                              })
                            }
                          >
                            {item?.userId?.avatarFileName ? (
                              <img
                                src={`${apiBaseURL}/uploads/avatar/${item?.userId?.avatarFileName}`}
                              />
                            ) : (
                              <div
                                style={{
                                  backgroundColor: 'rgba(41, 52, 65, 1)',
                                  borderRadius: '100%',
                                  width: '100%',
                                  height: '100%',
                                  color: '#fff',
                                  textAlign: 'center',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                }}
                              >
                                <span style={{ margin: 'auto', fontSize: '14px' }}>
                                  {item?.userId?.fullName.slice(0, 2)}
                                </span>
                              </div>
                            )}
                          </div>
                          <div onClick={() => navigateToComments(item._id)}>
                            <div className="admin__posts-list-row1-name">
                              {item?.userId?.fullName}
                              {item?.isPinedPostFlag && (
                                <span className="admin__posts-list-row1-pin">
                                  <IconPostModalPin />
                                </span>
                              )}
                            </div>
                            <div>
                              <span className="admin__posts-list-row1-text">
                                {moment(item?.createdPostDate).format(
                                  'DD MMM YYYY HH:mm',
                                )}
                              </span>{' '}
                              <span className="admin__posts-list-row1-text admin__posts-list-row1-textunder">
                                {item?.addressLocation}
                              </span>
                            </div>
                          </div>
                          <button onClick={() => openModal(item)}>
                            <IconServicesAllPoint />
                          </button>
                        </div>
                        <div
                          className="admin__posts-list-row2"
                          onClick={() => navigateToComments(item?._id)}
                        >
                          <PostSlick list={item?.filesName}>
                            {item?.filesName.length ? (
                              item?.filesName?.map((it) => (
                                <div
                                  className="admin__posts-list-row2-img"
                                  onClick={() => navigateToComments(item?._id)}
                                >
                                  <img
                                    src={`${apiBaseURL}/uploads/publish_post/${it}`}
                                    alt=""
                                  />
                                </div>
                              ))
                            ) : (
                              <div
                                className="admin__posts-list-row2-img"
                                onClick={() => navigateToComments(item?._id)}
                              >
                                <img
                                  className="posts__bg"
                                  src={`/Images/postsBG.png`}
                                  alt=""
                                />
                              </div>
                            )}
                          </PostSlick>
                        </div>
                        <div
                          className="admin__posts-list-row3"
                          onClick={() => navigateToComments(item?._id)}
                        >
                          <h5>{item?.title}</h5>
                          <h6>{item?.text}</h6>
                        </div>
                        <div style={{ margin: '20px' }} />
                        <PublicationPostsPanel
                          item={item}
                          updateLike={() => updateLike(item.likeId, item._id)}
                          navigateToComments={() => navigateToComments(item._id)}
                          updateMark={() => updateMark(item.isMarked, item)}
                          updateRepost={() =>
                            updateRepost({
                              postId: item._id,
                              isReposted: item.isReposted,
                            })
                          }
                          isOpen={IsOpen}
                          setIsOpen={setIsOpen}
                          active={true}
                        />
                      </div>
                    )}
                  </>
                ))}
              </>
            )}
            {services && filter === 'services' && (
              <>
                {filter === 'services' && <h5>Latest Services ({services?.length})</h5>}
                {services?.map((item) => (
                  <>
                    {item?.serviceId && (
                      <div
                        style={{ margin: '20px 0' }}
                        className="user__services-last-item"
                        onClick={() => toPublishService(item.serviceId._id)}
                      >
                        <div className="user__services-last-item-img">
                          <div className="user__services-last-item-img-1 user__services-last-item-img-info">
                            <div className="user__services-last-item-img-text">
                              {item.serviceId.categoryId.name}
                            </div>
                          </div>
                          {item.serviceId.filesName.length > 0 ? (
                            <img
                              src={`${apiBaseURL}/uploads/publish_services/${item.serviceId.filesName[0]}`}
                              alt=""
                            />
                          ) : (
                            <img
                              className="posts__bg"
                              src={`/Images/postsBG.png`}
                              alt=""
                            />
                          )}

                          {item.serviceId.reviews.length ? (
                            <div className="user__services-last-item-img-2 user__services-last-item-img-info">
                              <div className="user__services-last-item-img-text">
                                {item.serviceId.reviews.length ? (
                                  <>
                                    <IconStars />
                                    <b>{calculateRate(item.serviceId)}</b>
                                    <span>{`(${item.serviceId.reviews.length})`}</span>
                                  </>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                        <div
                          className="user__services-last-item-info"
                          style={{ position: 'relative' }}
                        >
                          <div className="user__services-last-item-row1">
                            <h5
                              className="user__services-last-item-title"
                              style={{
                                color: 'rgb(41, 45, 50)',
                                fontSize: '16px',
                                fontWeight: '500',
                              }}
                            >
                              {item.serviceId.title}
                            </h5>
                            <button
                              className={`${'admin__posts-list-row4-repost--active'}`}
                              onClick={(e) => {
                                e.stopPropagation()
                                updateMarkService(
                                  item.serviceId._id,
                                  item.serviceId?.isMarked,
                                  item,
                                )
                              }}
                            >
                              {item.serviceId.isMarked ? (
                                <IconProfileInfoBookmarkCopy fill="#3361FF" />
                              ) : (
                                <IconProfileInfoBookmark />
                              )}
                            </button>
                          </div>
                          <h6
                            style={{ margin: '4px 0' }}
                            className="user__services-last-item-text"
                          >
                            {item.serviceId.text}
                          </h6>
                          <div
                            style={{ position: 'absolute', right: '10px', bottom: '0' }}
                            className="user__services-last-item-foot"
                          >
                            {Math.floor(
                              calculateDistance(coordinates, item.serviceId.coordinates) /
                                1000,
                            )}{' '}
                            km
                            <IconArrowRight />
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </>
            )}
            {
              <>
                {filter === 'events' && (
                  <h5>Latest Events ({publishActivitiesList.length})</h5>
                )}
                {publishActivitiesList &&
                  filter === 'events' &&
                  publishActivitiesList.map((item) => (
                    <div
                      className="user__event-item"
                      onClick={() => toPublishActivities(item._id)}
                    >
                      <div className="user__event-item-row1">
                        <div className="user__event-item-row1-text">
                          <h5>{item?.title}</h5>
                          <h6>{item?.addressLocation}</h6>
                        </div>
                      </div>
                      <div className="explore__event-item-row2" onClick={() => {}}>
                        {item?.filesName.length ? (
                          item?.filesName?.map((it) => (
                            <>
                              <div
                                style={{ position: 'relative' }}
                                className="explore__event-item-row2-img"
                                onClick={() => {}}
                              >
                                <div className="explore__event-item-row2-img-last">
                                  <div className="explore__event-item-row2-img-last-text">
                                    {moment(item?.startDate).format('DD/MM/YYYY')}
                                  </div>
                                </div>
                                <div
                                  className="user__event-item-row2"
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    setIsOpenModal(true)
                                    setCurrentItemEvent(item)
                                  }}
                                >
                                  <IconServicesAllPoint />
                                </div>
                                <img
                                  src={`${apiBaseURL}/uploads/publish_activities/${it}`}
                                  alt=""
                                />
                                <div
                                  className="user__services-last-item-img-1 user__services-last-item-img-info"
                                  style={{ width: '100px', height: '35px', top: '85%' }}
                                >
                                  <div className="user__services-last-item-img-text">
                                    <span
                                      className="admin__posts-list-row1-text"
                                      style={{ color: 'rgba(21, 16, 77, 1)' }}
                                    >
                                      {item?.activitiesId.name}
                                    </span>{' '}
                                  </div>
                                </div>
                              </div>
                            </>
                          ))
                        ) : (
                          <div
                            style={{ position: 'relative' }}
                            className="explore__event-item-row2-img"
                            onClick={() => toPublishActivities(item?._id)}
                          >
                            <div className="explore__event-item-row2-img-last">
                              <div className="explore__event-item-row2-img-last-text">
                                {moment(item?.startDate).format('DD/MM/YYYY')}
                              </div>
                            </div>
                            <div
                              className="user__event-item-row2"
                              onClick={(e) => {
                                e.stopPropagation()
                                setIsOpenModal(true)
                                setCurrentItemEvent(item)
                              }}
                            >
                              <IconServicesAllPoint />
                            </div>
                            <img
                              className="posts__bg"
                              src={`/Images/postsBG.png`}
                              alt=""
                            />
                            <div className="explore__event-item-row2-img-last-bottom">
                              <div className="explore__event-item-row2-img-last-bottom-text">
                                <span
                                  className="admin__posts-list-row1-text"
                                  style={{ color: 'rgba(21, 16, 77, 1)' }}
                                >
                                  {item?.activitiesId.name}
                                </span>{' '}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="user__event-item-row3">
                        <h6>{item?.text}</h6>
                        {/* <div className="user__event-item-row3-button">
                                        {item.startDate.toString().split(".")[0].split("T")[0].replaceAll("-", "/") + " " }{ <b>{item.startDate.toString().split(".")[0].split("T")[1].split(":")[0] + ":" + item.startDate.toString().split(".")[0].split("T")[1].split(":")[1]}</b> }
                                    </div> */}
                      </div>
                      <div className="user__event-item-row4">
                        <PublicationEventPanel
                          item={item}
                          updateLike={() => updateLikeEvent(item.likes, item._id)}
                          navigateToComments={() => toPublishActivities(item._id)}
                          updateMark={() =>
                            updateMarkEvent(item._id, item.isMarked, item)
                          }
                          updateRepost={() => copyLink(item._id)}
                          active={true}
                        />
                        {/* {<button onClick={() => updateLikeEvent(item.likeId, item._id)}>
                                    {item.isLiked ? <IconPostsRedLike /> : <IconPostsLike />}
        
                                </button>}
                                <div onClick={()=>updateMarkEvent(item._id, item.isMarked)} className={item.isMarked ? "user__event-item-row4-bookmark-active" :"user__event-item-row4-bookmark"}>
                                    <IconProfileInfoBookmark />
                                </div> */}
                      </div>
                    </div>
                  ))}
              </>
            }
          </div>
          {(isOpenModal || myModalOpen || neibModalOpen) && <DarkBg />}
          {currnetItem && myModalOpen && (
            <PublicationPostMyModal
              deletePostList={deletePost}
              item={currnetItem}
              isOpen={myModalOpen}
              setIsOpen={setMyModalOpen}
              updateMark={() => updateMark(true, currnetItem)}
              updateNotification={updateNotificationPost}
              updatePin={updatePin}
            />
          )}
          {currnetItem && neibModalOpen && (
            <PublicationPostNeibModal
              item={currnetItem}
              isOpen={neibModalOpen}
              setIsOpen={setNeibModalOpen}
              updateMark={() => updateMark(true, currnetItem)}
              updateNotification={updateNotificationPost}
              toProfileInfo={toProfileInfo}
              hidePost={hidePost}
              updateContent={updateContent}
              content={content}
            />
          )}
          {isOpenModal && currentItemEvent?.userId._id === _id && (
            <ActivitiesMyModal
              setIsOpen={setIsOpenModal}
              isOpen={isOpenModal}
              item={currentItemEvent}
              type="activity"
              updateNotification={() => updateNotification(currentItemEvent._id)}
            />
          )}
          {isOpenModal && currentItemEvent?.userId._id !== _id && currentItemEvent && (
            <ActivitiesNeibModalMany
              setIsOpen={setIsOpenModal}
              isOpen={isOpenModal}
              item={currentItemEvent}
              type="activity"
              updateNotification={() => updateNotification(currentItemEvent._id)}
              setPublishService={setCurrentItemEvent}
            />
          )}
        </div>
      ) : (
        <Loader />
      )}
    </>
  )
}
