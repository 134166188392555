import L, { LatLng } from 'leaflet'
import { renderToString } from 'react-dom/server'
import { Marker } from 'react-leaflet'
import { IconMapPost } from '../svg/IconMapPost'

export const MapDefaultMarker = ({ coords }: { coords: [number, number] | LatLng }) => {
  const markDivIcon = new L.DivIcon({
    html: renderToString(
      <div
        style={{
          borderRadius: '3px',
          padding: '3px',
          height: '42px',
          width: '42px',
          position: 'absolute',
          left: '-10px',
          top: '-22px',
        }}
      >
        <div className="image" style={{ backgroundColor: 'rgb(15, 20, 25)' }}>
          <img src={'/Images/postsBG.png'} />
        </div>
        <IconMapPost />
      </div>,
    ),
    iconSize: [32, 32],
    className: 'leaflet-div-icon',
  })
  return coords ? <Marker position={coords} icon={markDivIcon}></Marker> : <></>
}
