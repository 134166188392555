import L from 'leaflet'
import { renderToString } from 'react-dom/server'
import { Marker } from 'react-leaflet'
import { IconCancel } from '../../svg/IconCancel'

export const ExploreCancelIcon = ({
  handler,
  position,
}: {
  handler: () => void
  position: [number, number]
}) => {
  const markDivIcon = new L.DivIcon({
    html: renderToString(
      <div
        style={{
          borderRadius: '3px',
          padding: '3px',
          height: '42px',
          width: '42px',
          position: 'absolute',
          left: '-10px',
          top: '-22px',
        }}
      >
        <div className="image" style={{ backgroundColor: '#fff' }}>
          <IconCancel className="image__svg" />
        </div>
      </div>,
    ),

    iconSize: [32, 32],
    className: 'leaflet-cancel-icon',
  })
  return (
    <Marker
      position={position}
      icon={markDivIcon}
      eventHandlers={{ click: handler }}
    ></Marker>
  )
}
