import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ServiceHttp } from '../../../http/service-http'
import { ROLE } from '../../../types/enum'
import { PublishServiceOneItemInterface } from '../../../types/types'
import { apiBaseURL, indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconPostModalHide } from '../../svg/IconPostModal'
import { IconPostsRepost } from '../../svg/IconPosts'
import { IconProfileInfoBookmark, IconProfileInfoFlag } from '../../svg/IconProfileInfo'
import { Modal } from '../../ui/Modal'
import { PreviewModal } from '../../general-components/preview/PreviewModal'

export const UserServiceOtherModal = ({
  isOpen,
  setIsOpen,
  item,
  type,
  setPublishService,
}: {
  isOpen: boolean
  setIsOpen: (s: boolean) => void
  item: PublishServiceOneItemInterface | null
  type: string
  setPublishService: (s: PublishServiceOneItemInterface) => void
}) => {
  const navigate = useNavigate()
  const [isOpenSave, setIsOpenSave] = useState(false)
  const { _id } = useAppSelector((state) => state.user)
  const [isOpenShare, setIsOpenShare] = useState(false)
  const copy = () => {
    const url = `${window.location.host}/preview/service?publishServiceId=${item?._id}`
    navigator.clipboard.writeText(url)
  }
  const toProfileInfo = (prop: {
    _id: string
    email: string
    role: string
    fullName: string
    avatarFileName: string
  }) => {
    navigate('/profileinfo', {
      state: prop,
    })
  }
  const { role } = useAppSelector((state) => state.user)

  const copyLink = async () => {
    const text = window.location.href
    navigate(`${indexPathByRole(role)}/service/share-list`, { state: { text } })
  }

  const hideService = async () => {
    if (item) {
      await ServiceHttp.hideService({
        userId: _id,
        serviceId: item?._id,
      })
      navigate(-1)
    }
  }

  const updateNotification = async (serviceId: string) => {
    if (item) {
      await ServiceHttp.updateNotification({
        serviceId: serviceId,
        userId: _id,
      })

      setPublishService({
        ...item,
        isNotificatedService: !item.isNotificatedService,
      })
    }
  }

  const updateMark = async (serviceId: string, isMarked: boolean) => {
    if (isMarked) {
      await ServiceHttp.deleteMark({
        serviceId,
        marckedUserId: _id,
      })
    } else {
      await ServiceHttp.addMark({
        serviceId,
        marckedUserId: _id,
      })
      setIsOpenSave(true)
    }

    if (item) {
      setPublishService({
        ...item,
        isMarked: !item.isMarked,
      })
    }
  }
  const toReport = () => {
    navigate(`${indexPathByRole(role)}/service/send-report?serviceId=${item?._id}`, {
      state: { _id: item?._id },
    })
  }
  return (
    <>
      <div
        className={`activities__favor-modal  ${
          isOpen ? 'activities__favor-modal--open' : ''
        }`}
      >
        <Modal className="" setIsOpen={(s: boolean) => setIsOpen(s)}>
          <div
            onClick={(e) => {
              e.stopPropagation()
              setIsOpen(false)
            }}
            className="activities__favor-modal-linebody"
          >
            <button className="activities__favor-modal-line" />
          </div>
          <div className="publish__modaladd post__modal">
            {item ? (
              <div
                className="post__modal_itembg"
                onClick={() =>
                  toProfileInfo({
                    _id: item?.userId?._id || '',
                    email: '',
                    role: ROLE.USER,
                    fullName: item?.userId?.fullName || '',
                    avatarFileName: item?.userId?.avatarFileName || '',
                  })
                }
              >
                <div className="post__modal_item post__modal_item-img">
                  <div>
                    <img
                      src={`${apiBaseURL}/uploads/avatar/${item?.userId.avatarFileName}`}
                      alt=""
                    />
                  </div>
                  <div>
                    <h5 className="post__modal_title">Connect</h5>
                    <h6 className="post__modal-subtitle">
                      Follow <b>{item?.userId.fullName}</b> {type}s
                    </h6>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}

            <div className="post__modal_itembg">
              <div className="post__modal_item" onClick={() => setIsOpenShare(true)}>
                <div className={`post__modal_item-button-1 `}>
                  <IconPostsRepost color="rgba(15, 20, 25, 1)" />
                </div>
                <div>
                  <h5 className="post__modal_title">Share</h5>
                  <h6 className="post__modal-subtitle">Share {type} to your neighbors</h6>
                </div>
              </div>

              <div className="post__modal_item" onClick={toReport}>
                <div className={`post__modal_item-button-1 `}>
                  <IconProfileInfoFlag />
                </div>
                <div>
                  <h5 className="post__modal_title">Report</h5>
                  <h6 className="post__modal-subtitle">Flag for review</h6>
                </div>
              </div>

              <div className="post__modal_item" onClick={hideService}>
                <div className="post__modal_item-button-1">
                  <IconPostModalHide />
                </div>
                <div>
                  <h5 className="post__modal_title">Hide</h5>
                  <h6 className="post__modal-subtitle">Remove {type} from your feed</h6>
                </div>
              </div>

              <div
                className={`post__modal_item`}
                onClick={() => {
                  if (item) updateMark(item?._id, item?.isMarked)
                }}
              >
                <div
                  style={{ background: 'none', boxShadow: 'none' }}
                  className={`post__modal_item ui-button-back-route 
                                 ${
                                   item?.isMarked &&
                                   'admin__posts-list-row4-repost--active'
                                 }
                             `}
                >
                  <IconProfileInfoBookmark />
                </div>
                <div>
                  <h5 className="post__modal_title">Bookmark</h5>
                  <h6 className="post__modal-subtitle">Save {type} for later</h6>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      <PreviewModal
        setIsOpen={setIsOpenShare}
        isOpen={isOpenShare}
        copy={copy}
        apply={copyLink}
      />
    </>
  )
}
