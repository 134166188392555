import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ActivitiesHttp } from '../../../http/activities-http'
import {
  NotificationHttp,
  NotificationListInterface,
} from '../../../http/notification-http'
import { PublishPostHttp } from '../../../http/publish-post-http'
import { ServiceHttp } from '../../../http/service-http'
import { NOTIFICATION_EVENT } from '../../../types/enum'
import { PublishServiceItemInterface } from '../../../types/types'
import { useAppSelector } from '../../../utils/hooks'
import { notificationTypeTitle } from '../../../utils/titles'
import { IconArrowRight } from '../../svg/IconArrow'

export const AdminNotificationList = () => {
  const navigate = useNavigate()
  const [notificationList, setNotificationList] = useState<NotificationListInterface[]>(
    [],
  )
  const { _id } = useAppSelector((state) => state.user)
  useEffect(() => {
    NotificationHttp.getNotificationList({ userId: _id }).then((s) =>
      setNotificationList(s),
    )
  }, [])

  const navigateTo = async (item: NotificationListInterface) => {
    switch (item.event) {
      case NOTIFICATION_EVENT.NOTIFICATION_SERVICE:
        const res: PublishServiceItemInterface[] =
          await ServiceHttp.getUserPublishService({ userId: item.ownerId._id })
        res.find((s) => {
          if (
            s.createdPublishServiceDate.toString().slice(0, 18) ==
            item.dateNotificationCreated.toString().slice(0, 18)
          ) {
            navigate(
              `/user/${notificationTypeTitle(item.event).link}/publish-service?publishServiceId=${s._id}`,
            )
          }
        })
        break
      case NOTIFICATION_EVENT.NOTIFICATION_NEWS:
        const posts = await PublishPostHttp.getPostsByUserId({ userId: item.ownerId._id })
        posts.find((s) => {
          if (
            s.createdPostDate.toString().slice(0, 18) ==
            item.dateNotificationCreated.toString().slice(0, 18)
          ) {
            navigate(`/user/comments?postId=${s._id}`)
          }
        })
        break
      case NOTIFICATION_EVENT.NOTIFICATION_ACTIVITIES:
        const events = await ActivitiesHttp.getUserPublishEvent({
          userId: item.ownerId._id,
        })
        events.find((s) => {
          if (
            s.createEventDate.toString().slice(0, 18) ==
            item.dateNotificationCreated.toString().slice(0, 18)
          ) {
            navigate(
              `/user/${notificationTypeTitle(item.event).link}/publish-activities-item?publishActivitiesId=${s._id}`,
            )
          }
        })
        break
      // case NOTIFICATION_EVENT.NOTIFICATION_MESSAGE:
      //     $api.post("messenger/list-chat", { _id, isSupport:false }).then((res:AxiosResponse<ChatType[]>)=>{
      //         res?.data.filter((s)=>{
      //             if(s?.lastMessage.timestamp.toString().slice(0, 18)==item.dateNotificationCreated.toString().slice(0, 18)){
      //                 navigate(`/user/messeges/chat?chatId=${item}`)
      //             }
      //         })
      //     })
      //     break;
      default:
        navigate(`/user/${notificationTypeTitle(item.event).link}/`)
        break
    }
  }
  return (
    <>
      <div className="notification__list" style={{ margin: '100px 20px' }}>
        {notificationList.map((item, index) => (
          <>
            {!index && (
              <div className="notification__list-date">
                {notificationList[0].dateNotificationCreated === new Date()
                  ? 'Today'
                  : moment(item?.dateNotificationCreated).format('DD MMM YYYY HH:mm')}
              </div>
            )}

            {moment(item?.dateNotificationCreated).format('DD MMM YYYY') !==
              moment(notificationList[index - 1]?.dateNotificationCreated).format(
                'DD MMM YYYY',
              ) && index ? (
              <div className="notification__list-date">
                {item?.dateNotificationCreated === new Date()
                  ? 'Today'
                  : moment(item?.dateNotificationCreated).format('DD MMM YYYY HH:mm')}
              </div>
            ) : (
              <></>
            )}

            <div
              className="notification__list-item"
              onClick={() => navigateTo(item)}
              style={{ position: 'relative' }}
            >
              <div className="admin__panel-events-last-date">
                <div className="notification__list-item-time">
                  {moment(item?.dateNotificationCreated).format('DD MMMM h:mm A')}
                </div>
              </div>
              <div className="notification__list-item-img admin__panel-events-last-img">
                {notificationTypeTitle(item.event).label()}
              </div>
              <div className="notification__list-item-bodyline">
                <div className="notification__list-item-text admin__panel-events-last-text">
                  <b>
                    {item.ownerId?.fullName ? item.ownerId?.fullName : 'Deleted account'}
                  </b>{' '}
                  <span>{notificationTypeTitle(item.event).text}</span>{' '}
                  <b>{item?.name}</b>
                </div>
              </div>
              <div className="notification__list-item-arrow">
                <IconArrowRight />
              </div>
            </div>
          </>
        ))}
      </div>
    </>
  )
}
