export const IconSharp = () => {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.28988 0.0257941C7.79218 0.140603 8.09759 0.60596 7.97201 1.0652L7.5601 2.57154H10.3149L10.8405 0.649436C10.9661 0.190199 11.4751 -0.0890153 11.9774 0.0257941C12.4797 0.140603 12.7851 0.60596 12.6595 1.0652L12.2476 2.57154H14.5625C15.0803 2.57154 15.5 2.95529 15.5 3.42866C15.5 3.90203 15.0803 4.28577 14.5625 4.28577H11.7789L10.8414 7.71423H12.6875C13.2053 7.71423 13.625 8.09797 13.625 8.57134C13.625 9.04471 13.2053 9.42846 12.6875 9.42846H10.3726L9.84701 11.3506C9.72143 11.8098 9.21243 12.089 8.71012 11.9742C8.20782 11.8594 7.90241 11.394 8.02799 10.9348L8.4399 9.42846H5.6851L5.15951 11.3506C5.03393 11.8098 4.52493 12.089 4.02262 11.9742C3.52032 11.8594 3.21491 11.394 3.34049 10.9348L3.7524 9.42846H1.4375C0.919733 9.42846 0.5 9.04471 0.5 8.57134C0.5 8.09797 0.919733 7.71423 1.4375 7.71423H4.22115L5.15865 4.28577H3.3125C2.79473 4.28577 2.375 3.90203 2.375 3.42866C2.375 2.95529 2.79473 2.57154 3.3125 2.57154H5.6274L6.15299 0.649436C6.27857 0.190199 6.78757 -0.0890153 7.28988 0.0257941ZM7.09135 4.28577L6.15385 7.71423H8.90865L9.84615 4.28577H7.09135Z"
        fill="#C3CAD9"
      />
    </svg>
  )
}
