import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AdvertisementHttp } from '../../../http/advertisement'
import { UserHttp } from '../../../http/user-http'
import { ROLE } from '../../../types/enum'
import { Advertise, HeaderMessageType } from '../../../types/types'
import { apiBaseURL } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconLeftChevrons } from '../../svg/IconChevrons'
import { IconServicesAllPoint } from '../../svg/IconServicesAll'

export const AdvertisementPreview = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [ads, setAd] = useState<Advertise>()
  const [user, setUser] = useState<HeaderMessageType>()
  const { role, avatarFileName, fullName } = useAppSelector((state) => state.user)
  useEffect(() => {
    const effect = async () => {
      const res = await AdvertisementHttp.getAdvertisementById({
        adId: location.state.adId,
      })
      setAd(res)
      if (res && role === ROLE.ADMIN) {
        await UserHttp.getUserById({ userId: res.userId }).then((u) => {
          setUser(u)
        })
      }
    }
    effect()
  }, [])
  return (
    <div className="advertisement__add">
      <div className="advertisement__add-header">
        <div className="advertisement__add-header-btn" onClick={() => navigate(-1)}>
          <IconLeftChevrons fill="rgba(21, 16, 77, 1)" />
          <span>Live Preview</span>
        </div>
      </div>
      {role === ROLE.ADMIN && (
        <div className="advertisement__add-preview">
          {ads && user && (
            <div className="advertisement-item">
              <div className="advertisement-item-sponsor">
                <div style={{ display: 'flex', gap: '10px' }}>
                  <div className="admin__posts-list-row1-img" onClick={() => {}}>
                    <img
                      src={
                        user.avatarFileName
                          ? `${apiBaseURL}/uploads/avatar/${user.avatarFileName}`
                          : '/Images/Profile.jpg'
                      }
                      alt=""
                    />
                  </div>
                  <div className="advertisement-item-sponsor-user">
                    <h5>{user.fullName}</h5>
                    <h6>Sponsored</h6>
                  </div>
                </div>
                <button onClick={() => {}}>
                  <IconServicesAllPoint />
                </button>
              </div>
              <div className="advertisement-item-img" style={{ width: '100%' }}>
                <img
                  src={`${apiBaseURL}/uploads/advertisement/${ads.fileName}`}
                  style={{ margin: 'auto' }}
                />
              </div>
              <div className="advertisement-item-title">
                <h5>{ads.title}</h5>
                <h6>{ads.text}</h6>
              </div>
              <button className={`advertisement__add-main-btn`} onClick={() => {}}>
                Action Button
              </button>
            </div>
          )}
        </div>
      )}
      {role === ROLE.USER && ads && (
        <>
          <div className="advertisement-item-bg">
            {/* <img src="/Images/adPreview.png" alt="" /> */}
            <div className="advertisement-item">
              <div className="advertisement-item-sponsor">
                <div style={{ display: 'flex', gap: '10px' }}>
                  <div className="admin__posts-list-row1-img" onClick={() => {}}>
                    <img
                      src={
                        avatarFileName
                          ? `${apiBaseURL}/uploads/avatar/${avatarFileName}`
                          : '/Images/Profile.jpg'
                      }
                      alt=""
                    />
                  </div>
                  <div className="advertisement-item-sponsor-user">
                    <h5>{fullName}</h5>
                    <h6>Sponsored</h6>
                  </div>
                </div>
                <button onClick={() => {}}>
                  <IconServicesAllPoint />
                </button>
              </div>
              <div className="advertisement-item-img">
                <img src={`${apiBaseURL}/uploads/advertisement/${ads.fileName}`} />
              </div>
              <div className="advertisement-item-title">
                <h5>{ads.title}</h5>
                <h6>{ads.text}</h6>
              </div>
              <button className={`advertisement__add-main-btn`} onClick={() => {}}>
                Action Button
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
