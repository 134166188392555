export const IconUser = () => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 9C10.525 9 12.5714 6.98555 12.5714 4.5C12.5714 2.01445 10.525 0 8 0C5.475 0 3.42857 2.01445 3.42857 4.5C3.42857 6.98555 5.475 9 8 9ZM11.2 10.125H10.6036C9.81071 10.4836 8.92857 10.6875 8 10.6875C7.07143 10.6875 6.19286 10.4836 5.39643 10.125H4.8C2.15 10.125 0 12.2414 0 14.85V16.3125C0 17.2441 0.767857 18 1.71429 18H14.2857C15.2321 18 16 17.2441 16 16.3125V14.85C16 12.2414 13.85 10.125 11.2 10.125Z"
        fill="#0F1419"
      />
    </svg>
  )
}
