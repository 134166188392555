import { ReactNode } from 'react'
import { Modal } from './Modal'

export const YoutubeVideoModal = ({
  setIsOpen,
  isOpen,
  children,
}: {
  setIsOpen: (s: boolean) => void
  isOpen: boolean
  children: ReactNode
}) => {
  return (
    <>
      <Modal className="youtube__modal" setIsOpen={setIsOpen}>
        {children}
      </Modal>
    </>
  )
}
