import { useDebounce } from '@uidotdev/usehooks'
import { Input } from 'antd'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import {
  IconLocationAim,
  IconLocationKey,
  IconLocationPoint,
} from '../../svg/IconsLocation'
import axios from 'axios'
import { NominatimSearchResponse } from '../../../redux/statelessRequests/address'
import { logout } from '../../../redux/auth'
import { useAppDispatch } from '../../../utils/hooks'

const $api = axios.create({})

interface Props {
  onConfirm: (place: NominatimSearchResponse) => void
  onGeolocation: () => void
}

export default function SetAddressAutocomplete({ onConfirm, onGeolocation }: Props) {
  const [searchResults, setSearchResults] = useState<
    NominatimSearchResponse[] | undefined
  >()
  const [chosenAddress, setChosenAddress] = useState<
    NominatimSearchResponse | undefined
  >()
  const [cityInput, setCityInput] = useState('')
  const debouncedQ = useDebounce(cityInput, 200)

  async function search(url: string) {
    const result = await $api.get(url)
    setSearchResults(result.data)
  }

  useEffect(() => {
    setChosenAddress(undefined)
  }, [cityInput])

  useEffect(() => {
    if (!debouncedQ) {
      setSearchResults(undefined)
      return
    }

    const searchUrl = new URL('https://nominatim.environs.life/search')
    const queryParams = new URLSearchParams({
      format: 'json',
      q: debouncedQ,
      addressdetails: '1',
      layer: 'address',
    })
    searchUrl.search = queryParams.toString()

    search(searchUrl.toString())
  }, [debouncedQ])

  const dispatch = useAppDispatch()

  return (
    <div className="forget">
      <button onClick={() => dispatch(logout())}>logout</button>
      <div className="location__body">
        <h4 className="forget__title">Enter your adress</h4>
        <h5 className="forget__subtitle location__subtitle">
          <IconLocationKey />
          <div>
            <p>Your adress won’t be seen by others. </p>
            <p>You can change this setting anytime</p>
          </div>
        </h5>
      </div>

      <button
        className="location__but location__but--inheritbody"
        onClick={onGeolocation}
      >
        <IconLocationAim /> Use current location
      </button>
      <div className="location__fields">
        <Input
          addonBefore={<IconLocationPoint />}
          placeholder="Search city, street, index"
          value={chosenAddress?.display_name || cityInput}
          onChange={(e) => setCityInput(e.target.value)}
        />
      </div>

      <div className="location__autocomplete-results">
        {searchResults !== undefined &&
          (searchResults.length === 0 ? (
            <div>No results</div>
          ) : (
            searchResults.map((p) => (
              <div
                key={p.place_id}
                onClick={() => setChosenAddress(p)}
                className={classNames({
                  'location__autocomplete-results__place': true,
                  'location__autocomplete-results__place--chosen':
                    chosenAddress?.place_id === p.place_id,
                })}
              >
                <div className="location__autocomplete-results__place__inner">
                  {p.display_name}
                </div>
              </div>
            ))
          ))}
      </div>

      <p className="location__mess">
        You will see all the news and updates within a radius of 5 km You can personalize
        your feed later in your profile settings.
      </p>

      <button
        className="location__bt-continue login__button"
        onClick={() => chosenAddress && onConfirm(chosenAddress)}
        disabled={!chosenAddress}
      >
        Continue
      </button>
    </div>
  )
}
