import { PROFILE_STEP } from '../../types/server-communication/user'
import { RootState } from '../../store'
import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'

export const stepDefinitions: { [key in PROFILE_STEP]: { queue: number; path: string } } =
  {
    [PROFILE_STEP.AVATAR]: {
      queue: 1,
      path: 'avatar',
    },
    [PROFILE_STEP.INTEREST_ZONE]: {
      //TODO:
      queue: 2,
      path: 'interest-zone',
    },
    [PROFILE_STEP.PRIVACY]: {
      //TODO:
      queue: 3,
      path: 'privacy',
    },
    [PROFILE_STEP.ABOUT_ME]: {
      queue: 4,
      path: 'about',
    },
    [PROFILE_STEP.PROFESSION]: {
      queue: 5,
      path: 'profession',
    },
    [PROFILE_STEP.SKILLS]: {
      queue: 6,
      path: 'skills',
    },
    [PROFILE_STEP.INTERESTS]: {
      queue: 7,
      path: 'interests',
    },
    [PROFILE_STEP.CERTIFICATES]: {
      //TODO:
      queue: 8,
      path: 'certificates',
    },
    [PROFILE_STEP.DATE_PLACE_OF_BIRTH]: {
      queue: 9,
      path: 'birth',
    },
    [PROFILE_STEP.NATIONALITY]: {
      queue: 10,
      path: 'nationality',
    },
    [PROFILE_STEP.SEX]: {
      queue: 11,
      path: 'sex',
    },
    [PROFILE_STEP.EDUCATION]: {
      queue: 12,
      path: 'education',
    },
    [PROFILE_STEP.FAMILY_STATUS]: {
      queue: 13,
      path: 'family-status',
    },
  }

export default function useNextSetUpProfilePath() {
  const selectUser = (state: RootState) => state.userNew

  const currentStepSelector = createSelector([selectUser], ({ stepsToComplete }) => {
    const getCurrentStep = (steps: PROFILE_STEP[]) => {
      return steps.reduce((highestPriorityStep, currentStep) => {
        return stepDefinitions[currentStep].queue <
          stepDefinitions[highestPriorityStep].queue
          ? currentStep
          : highestPriorityStep
      })
    }

    const stepPath = stepsToComplete
      ? stepDefinitions[getCurrentStep(stepsToComplete)].path
      : undefined

    return stepPath
  })

  const currentStep = useSelector(currentStepSelector)
  return currentStep
}
