import { AxiosResponse } from 'axios'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { SocketContext } from '../../../context/SocketContext'
import $api from '../../../http'
import { SOCKET_MESSENDER_EVENT } from '../../../types/enum'
import { ChatType } from '../../../types/types'
import { apiBaseURL, indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconLeftChevrons } from '../../svg/IconChevrons'
import { InputSearch } from '../../ui/InputSearch'
import { Loader } from '../../ui/Loader'
import { UserHeader } from '../../user-components/header/UserHeader'

export const Forward = () =>
  //     props:{
  // senderIdold:string,
  // message:string,
  // time:string,
  // fileName:string,
  // audio:boolean

  // }
  {
    const [search, setSearch] = useState<string>('')
    const [load, setLoad] = useState(true)
    const [users, setUsers] = useState<ChatType[]>([])
    const { socket } = useContext(SocketContext)
    const location = useLocation()
    const navigate = useNavigate()

    const { _id, role, fullName } = useAppSelector((state) => state.user)
    useEffect(() => {
      $api
        .post('messenger/list-chat', { _id, isSupport: false })
        .then((r: AxiosResponse<ChatType[]>) => {
          const list = r.data.map((item) => ({
            ...item,
            participants: item.participants.filter((p) => p.userId._id !== _id),
          }))
          setUsers(list)
          setLoad(false)
        })
        .catch(() => {
          setLoad(false)
        })
    }, [])
    const forwardMessage = (item: ChatType) => {
      if (socket) {
        socket.current?.emit(SOCKET_MESSENDER_EVENT.FORWARD_PRIVATE_MESSAGE, {
          chatId: item.chatId,
          senderIdold: location.state.senderIdold,
          content: location.state.message,
          timestamp: location.state.time,
          senderId: _id,
          file: location.state.fileName,
          audio: location.state.audio,
        })
      }
    }
    const openChat = (item: ChatType) => {
      navigate(
        `${indexPathByRole(role)}/messeges/chat?chatId=${JSON.stringify(item.chatId)}`,
        {
          state: {
            chat: item,
          },
        },
      )
    }
    return (
      <div className="forward">
        <UserHeader>
          <div className="user__header-messages explore__header">
            <>
              <button
                onClick={() => navigate(-1)}
                className="user__header-chatuser user__header-messages-exit"
              >
                <IconLeftChevrons />
              </button>
              <h5 className="user__header-title" style={{ margin: '40px 0' }}>
                Forward
              </h5>
            </>
          </div>
        </UserHeader>
        <div className="forward__content">
          <div>
            <div className="user__newsfeed-search">
              <InputSearch
                placeholder={
                  <>
                    Search <b>NightborChats</b>
                  </>
                }
                value={search}
                changeValue={setSearch}
              />
            </div>
            <div className="messenger__alluser">
              <>
                {load ? (
                  <Loader />
                ) : (
                  <>
                    {users.length ? (
                      <div className="messenger">
                        <div className="messenger__list">
                          {(search
                            ? users.filter((item) =>
                                item.participants[0].userId.fullName
                                  .toLocaleLowerCase()
                                  .includes(search.toLocaleLowerCase()),
                              )
                            : users
                          ).map((item, key) => (
                            <>
                              {item?.lastMessage && item.lastMessage?.content && (
                                <div
                                  className="messenger__list-item"
                                  onClick={
                                    () => {
                                      if (item?.participants) {
                                        // openChat(item?.participants)
                                        forwardMessage(item)
                                        openChat(item)
                                      }
                                    }
                                    // openChat({
                                    //     userId: item
                                    //         ?.participants?.[0]
                                    //         ?.userId._id,
                                    // })
                                  }
                                >
                                  <img
                                    src={
                                      item?.participants[0].userId?.avatarFileName
                                        ? `${apiBaseURL}/uploads/avatar/${item?.participants[0].userId?.avatarFileName}`
                                        : '/Images/Profile.jpg'
                                    }
                                    alt=""
                                  />
                                  <div>
                                    <h5 className="messenger__list-item-name">
                                      {item.name
                                        ? item.name
                                        : item?.participants[0]?.userId.fullName}
                                      {/* {
                                                                                item
                                                                                    ?.participants?.[0]
                                                                                    ?.userId
                                                                                    ?.fullName
                                                                            } */}
                                    </h5>
                                    <p
                                      className={`messenger__list-item-message
                                                            ${
                                                              // !item.lastMessage?.isRead &&
                                                              // !item.notReadingMessage.length &&
                                                              'messenger__list-item-message--notread'
                                                            }`}
                                    >
                                      {item.lastMessage?.content}
                                    </p>
                                  </div>
                                  <div>
                                    <div className="messenger__list-item-time">
                                      {moment(item.lastMessage?.timestamp).format(
                                        'MMM D, h:mm A',
                                      )}
                                    </div>
                                    {Boolean(item.notReadingMessage.length) && (
                                      <div className="messenger__list-item-nummes">
                                        {item.notReadingMessage.length}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            </>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    )
  }
