import { FormEvent, useEffect, useState } from 'react'
import { IconMaster } from '../../svg/IconMaster'
import { IconProfileInfoKey } from '../../svg/IconProfileInfo'
import { IconVisa } from '../../svg/IconVisa'
import { TextField } from '@mui/material'
import { CARD } from '../../../types/enum'
import { StripeHttp } from '../../../http/stripe-http'
import { success } from '../../ui/LoadSuccess'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../utils/hooks'
import { loadStripe } from '@stripe/stripe-js'
import { Elements, CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { Loader } from '../../ui/Loader'
import { Advertise } from '../../../types/types'
import { AdvertisementHttp } from '../../../http/advertisement'
import { indexPathByRole } from '../../route/indexPathByRole'

export const AdvertisementAddPaymentMethod = () => {
  const maxLength = 40
  const { _id, role } = useAppSelector((state) => state.user)
  const location = useLocation()
  const [cardNumber, setCardNumber] = useState('')
  const [nameOnCard, setNameOnCard] = useState('')
  const [cardExpire, setCardExpire] = useState('')
  const [cardCvv, setCardCvv] = useState('')
  const [cardType, setCardType] = useState<CARD>()
  const navigate = useNavigate()
  const [isProcessing, setIsProcessing] = useState(false)
  const [paymentStatus, setPaymentStatus] = useState('')
  const [ad, setAd] = useState<Advertise>()

  const props: {
    adId: string
    amount: number
  } = location.state

  const stripe = useStripe()
  const elements = useElements()

  const validate = !Boolean(
    //cardNumber.replaceAll(" ", "").length===16 && nameOnCard.length>=2 && cardCvv.length && cardExpire.replaceAll("/", "").length && cardType
    !isProcessing,
  )
  useEffect(() => {
    const body = async () => {
      AdvertisementHttp.getAdvertisementById({ adId: props.adId }).then((res) =>
        setAd(res),
      )
    }
    body()
  }, [props])

  // useEffect(() => {
  //     if(parseInt(cardNumber.replaceAll(" ", "")[0])===4){
  //         setCardType(CARD.VISA)
  //     }
  //     if(parseInt(cardNumber.replaceAll(" ", "")[0])===5){
  //         setCardType(CARD.MASTER)
  //     }
  // }, [cardNumber])
  // useEffect(() => {
  //     console.log({
  //         userId:_id,
  //         cardCvv:parseInt(cardCvv),
  //         cardHolder:nameOnCard,
  //         cardMonth:parseInt(cardExpire.split("/")[0]),
  //         cardYear:parseInt(cardExpire.split("/")[1]),
  //         cardNumber:parseInt(cardNumber.replaceAll(" ", "")),
  //         cardType
  //     })
  //     console.log(validate)
  // }, [cardCvv])

  // const formatCardNumber = (str:string) => {
  //     if(str.replaceAll(" ", "").length<=16  && parseInt(str.replaceAll(" ", ""))){
  //         if(str.replaceAll(" ", "").length%4===0){
  //             setCardNumber(str + " ");
  //         }else{
  //             setCardNumber(str);
  //         }
  //     }
  // }
  // const formatCardExpire = (str:string) => {
  //     if(str.replaceAll("/", "").length<=4 && parseInt(str.replaceAll("/", ""))){
  //         if(str.replaceAll("/", "").length%2===0 && str.length<4){
  //             setCardExpire(str + "/")
  //         }else{
  //             setCardExpire(str);
  //         }
  //     }
  // }
  // const addCard = () => {
  //     try {
  //         if(!validate && cardType){
  //             StripeHttp.addCard({
  //                 userId:_id,
  //                 cardCvv:parseInt(cardCvv),
  //                 cardHolder:nameOnCard,
  //                 cardMonth:parseInt(cardExpire.split("/")[0]),
  //                 cardYear:parseInt(cardExpire.split("/")[1]),
  //                 cardNumber:parseInt(cardNumber.replaceAll(" ", "")),
  //                 cardType
  //             })

  //             success()
  //             navigate(-1)
  //         }else{
  //             console.log("Invalid card. Visa or Master card must be provided")
  //         }
  //     } catch (error) {
  //         console.log(`Server Error: ${error}`)
  //     }
  // }
  const submitForm = async (e: FormEvent<HTMLElement>) => {
    try {
      e.preventDefault()
      if (stripe && elements && ad?.budget) {
        const cardEl = elements.getElement(CardElement)
        setIsProcessing(true)
        const res = await StripeHttp.payWithCard({
          amount: ad.budget * 100,
          adId: props.adId,
        })

        if (!res) return
        const { client_secret: clientSecret } = res

        const paymentIntent = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: cardEl!,
          },
        })
        if (!paymentIntent) {
          console.log('Your postal code is incomplete')
          setIsProcessing(false)
        } else {
          setIsProcessing(false)
          success()
          navigate(`${indexPathByRole(role)}/advertisement`)
        }
      }
    } catch (error) {
      console.log(`Server Error: ${error}`)
      setPaymentStatus('Payment failed')
      setIsProcessing(false)
    }
  }
  return (
    <div className="advertisement__add">
      {/* <div className="advertisement__add-header">
                <div className="advertisement__add-header-btn" onClick={()=>navigate(-1)}>
                    <IconLeftChevrons fill="rgba(21, 16, 77, 1)"/>
                    <span style={{textTransform:"capitalize"}}>Request Money</span>
                </div>
            </div> */}
      <h5 className="advertisement__add-main-title-bold advertisement__add-main-payment-subtitle">
        Card Info
      </h5>
      <div className="advertisement__add-main-payment-subtitle">
        <IconProfileInfoKey />
        <h6>
          Your data is securely encrypted for <br /> maximum protection
        </h6>
      </div>
      <div style={{ margin: '30px 0' }} />

      {/* <TextField 
                        value={nameOnCard}
                        onChange={(e)=>{
                            if(e.target.value.length<=maxLength){
                                setNameOnCard(e.target.value)
                            }
                        }}  
                        placeholder="Name on card"
                        className="ad__payment-input"
                    />


            <TextField 
                value={cardNumber}
                onChange={(e)=>formatCardNumber(e.target.value)}
                placeholder="Card number"
                className="ad__payment-input"
            />

            <TextField 
                value={cardExpire}
                onChange={(e)=>formatCardExpire(e.target.value)}
                placeholder="MM/YY"
                className="ad__payment-input"
            />

            <TextField 
                value={cardCvv}
                onChange={(e)=>{
                    if(e.target.value.length<=3){
                        setCardCvv(e.target.value)
                    }
                }}
                placeholder="Cvv"
                className="ad__payment-input"
            /> */}
      <form onSubmit={submitForm} id="payment-form">
        <label htmlFor="card-element">Card </label>
        <div style={{ margin: '20px 0' }} />
        <CardElement id="card-element" />
        {!isProcessing ? (
          <button
            className={`advertisement__add-main-btn 
                            ${validate && 'advertisement__add-main-btn-disabled'}
                            `}
            style={{ margin: '60px 0' }}
            onClick={submitForm}
            disabled={validate}
          >
            {/* Add Payment Method */}
            Pay
          </button>
        ) : (
          <Loader />
        )}
      </form>
    </div>
  )
}
export const PaymentGateway = () => {
  const stripePromise = loadStripe(
    'pk_test_51OeKpUC657m1Xop5NiBhhbv4O4wHibankSo052QykpGUJ8smcMpqpib7KNPHVc5fS7Si2SwEaokKGAqp0ICSrX8p00lvlnhk68',
  )

  return (
    <Elements stripe={stripePromise}>
      <AdvertisementAddPaymentMethod />
    </Elements>
  )
}
