import { useState } from 'react'
import { FriendTypeResponse } from '../../../types/types'
import { apiBaseURL } from '../../route/indexPathByRole'
import { IconProfileCircle, IconProfileCircleFilled } from '../../svg/IconProfile'

export const MessageUserItem = ({
  item,
  setParticipians,
  Participians,
}: {
  item: FriendTypeResponse
  setParticipians: (s: FriendTypeResponse[]) => void
  Participians: FriendTypeResponse[]
}) => {
  const [share, setShare] = useState<boolean>(false)
  return (
    <div
      className="share-friend-item"
      onClick={() => {
        if (!share) {
          let array: FriendTypeResponse[] = [...Participians]
          array.push(item)
          setParticipians(array)
          setShare(!share)
        } else {
          let array = [...Participians]
          let index = array.indexOf(item)
          if (index !== -1) {
            array.splice(index, 1)
            setParticipians(array)
          }
          setShare(!share)
        }
      }}
    >
      <div className="share-friend-item-content">
        {item?.friendId?.avatarFileName ? (
          <img
            style={{ width: '60px', height: '60px', objectFit: 'cover' }}
            src={`${apiBaseURL}/uploads/avatar/${item?.friendId?.avatarFileName}`}
          />
        ) : (
          <div
            style={{
              backgroundColor: 'rgba(41, 52, 65, 1)',
              borderRadius: '100%',
              width: '60px',
              height: '60px',
              color: '#fff',
              textAlign: 'center',
              position: 'relative',
            }}
          >
            <span style={{ position: 'absolute', left: '20px', top: '18px' }}>
              {item?.friendId?.fullName.slice(0, 2)}
            </span>
          </div>
        )}
        <div className="share-friend-item-content-user">
          <h5>{item?.friendId?.fullName}</h5>
          {/* <h6>{"online"}</h6> */}
        </div>
      </div>
      <div className="share-friend-item-radio">
        {!share ? <IconProfileCircle /> : <IconProfileCircleFilled />}
      </div>
    </div>
  )
}
