import React, { useRef, useState } from 'react'
import { useAppSelector } from '../../../utils/hooks'
import { IconAdminClose } from '../../svg/IconAdminHeader'
import { AutoResize } from '../../ui/AutoResize'
import { PublishAttachButton } from './PublishAttachButton'
import { PublishModalAddFile } from './PublishModalAddFile'

const maxLength = 300

export const PublicationMainComponent = ({
  files,
  setFiles,
  text,
  setText,
  title,
  setTitle,
  placeholderTitle,
  placeholderText,
}: {
  files: (File | string)[]
  setFiles: (f: (File | string)[]) => void
  initUrlFiles?: string[]
  setInitUrlFiles?: (f: string[]) => void
  text: string
  setText: (s: string) => void
  title: string
  setTitle: (s: string) => void
  placeholderTitle?: string
  placeholderText?: string
}) => {
  const { fullName } = useAppSelector((state) => state.user)

  const [isOpen, setIsOpen] = useState(false)

  const getFile = (f: File) => {
    setFiles([...files, f])
  }

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newText = event.target.value
    if (newText.length <= maxLength) {
      setText(newText)
    }
  }

  const handlerDeleteFile = (index: number) => {
    setFiles(files.filter((item, id) => id !== index))
  }

  const myRef = useRef<null | HTMLDivElement>(null)
  const maxCountFile = 10 - files.length
  return (
    <div className="publish__main">
      <input
        className="services__add-input"
        placeholder={placeholderTitle}
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <div className="profile__about-body">
        {/* <TextareaAutosize
                    value={text}
                    onChange={handleChange}
                    className="profile__about-autoresize"
                    minRows={1}
                    placeholder={`${fullName}, what’s in your mind ?`}
                /> */}
        <AutoResize
          value={text}
          changeValue={setText}
          placeholder={
            <>
              <b>{fullName}</b>, what’s in your mind ?
            </>
          }
        />
      </div>

      <div className="publish__main-list">
        {files.map((item, index) => (
          <div className="publish__main-list-item">
            <button
              className=" publish__main-list-item-remove"
              onClick={() => handlerDeleteFile(index)}
            >
              <IconAdminClose />
            </button>
            <img
              src={typeof item === 'string' ? item : URL.createObjectURL(item)}
              alt="Вибране зображення"
            />
          </div>
        ))}
        {Boolean(maxCountFile) && (
          <PublishAttachButton
            onClick={() => setIsOpen(true)}
            counterFile={files.length && maxCountFile}
          />
        )}
        <div ref={myRef} />
      </div>
      <PublishModalAddFile isOpen={isOpen} setIsOpen={setIsOpen} getFile={getFile} />
    </div>
  )
}
