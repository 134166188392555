import { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Slider from 'react-slick'
import $api from '../../../http'
import { IdentityHttp } from '../../../http/identity-http'
import { UserIdentityInterface } from '../../../redux/profile'
import { PRIVACY } from '../../../types/enum'
import { ChatType, LocationType, OptionsType } from '../../../types/types'
import { apiBaseURL, indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconLeftChevrons } from '../../svg/IconChevrons'
import { IconProfileInfoBookmark } from '../../svg/IconProfileInfo'
import { IconsNewsfeedPlus } from '../../svg/IconsNewsfeed'
import { InputSearch } from '../../ui/InputSearch'
import { success } from '../../ui/LoadSuccess'
import { UserHeader } from '../header/UserHeader'
import { SliderItemChat } from './SliderItemChat'
import { calculateDistance } from '../../../utils/calculateRate'
import { inside, insideCircle } from '../../../utils/inside'

let settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
}

export interface LocationWithDistanceType extends LocationType {
  distance: number
}

export const ExploreChats = () => {
  const { _id, role, avatarFileName, fullName } = useAppSelector((state) => state.user)
  const { country, street, houseNumber, polygonCoords, radiusSize } = useAppSelector(
    (state) => state.profile,
  )

  const locationPath = useLocation()
  const navigate = useNavigate()

  const [search, setSearch] = useState('')
  const [regions, setRegions] = useState<LocationWithDistanceType[]>()
  const [location, setLocation] = useState<LocationWithDistanceType>()
  const [chatsList, setChatsList] = useState<ChatType[]>()
  const [chatsListNormal, setChatsListNormal] = useState<ChatType[]>()
  const [value, setValue] = useState<OptionsType>([])

  useEffect(() => {
    IdentityHttp.getUserIdentity({ _id }).then((res) => {
      setValue(res.interests)
    })
  }, [])
  const [profile__identity, setProfile__identity] = useState<UserIdentityInterface>()

  const [open, setOpen] = useState(true)

  const reg: LocationWithDistanceType[] = locationPath.state.reg
  const city: string = locationPath.state.city
  const { coordinates } = useAppSelector((state) => state.profile)

  useEffect(() => {
    let dist: LocationWithDistanceType[] = []
    reg.filter((it) => {
      if (
        it.center &&
        it.city.replaceAll(' ', '').replaceAll('Oblast', '') ===
          city.replaceAll(' ', '').replaceAll('Oblast', '')
      ) {
        let distance = calculateDistance(coordinates, it.center)
        dist.push({ ...it, distance })
      }
    })
    setRegions(dist.sort((f, s) => f.distance - s.distance).slice(0, 10))
  }, [])
  useEffect(() => {
    if (regions) setLocation(regions[0])
  }, [regions])
  useEffect(() => {
    let c: ChatType[] = []
    const body = async () => {
      $api.post('messenger/list-chat-all').then((r: AxiosResponse<ChatType[]>) => {
        setChatsList(r.data)
        console.log(r.data, coordinates)
      })
    }
    body()
  }, [])
  useEffect(() => {
    const body = async () => {
      const res = await IdentityHttp.getUserIdentity({ _id })
      setProfile__identity(res)
    }
    body()
  }, [])

  useEffect(() => {
    let array: ChatType[] = []
    if (chatsList?.length) {
      chatsList
        .filter((it) => it.participants.length > 2)
        ?.map((item) => {
          array.push(item)
        })
    }
    setChatsListNormal(array)
  }, [chatsList])

  const [isActiveSearch, setIsActiveSearch] = useState(false)

  const onFocus = () => {
    setIsActiveSearch(true)
  }
  const onBlur = () => {
    setIsActiveSearch(false)
  }
  const openChat = async (item: ChatType) => {
    await $api
      .post('messenger/update-chat', {
        participants: [
          ...item.participants.map((it) => {
            return { userId: it.userId._id }
          }),
          {
            userId: _id,
          },
        ],
        chatId: item._id,
      })
      .then(() => {
        navigate(
          `${indexPathByRole(role)}/messeges/chat?chatId=${JSON.stringify(item._id)}`,
          {
            state: {
              participants: [
                ...item.participants.map((it) => {
                  return { userId: it.userId._id }
                }),
                {
                  userId: _id,
                },
              ],
              chat: { ...item, chatId: item?._id },
            },
          },
        )
      })
  }
  const addRequest = async (item: ChatType) => {
    await $api.post('messenger/add-request', {
      myId: item.groupOwner,
      senderId: _id,
      chatId: item._id,
    })
    success()
    if (chatsList) {
      let array: ChatType[] = [...chatsList]
      let index = array.indexOf(item)
      if (index !== -1) {
        array.splice(index, 1)
        setChatsList(array)
      }
    }
  }
  const joinGroup = async (item: ChatType) => {
    if (item.privacy === PRIVACY.EVERYONE) {
      openChat(item)
    } else {
      addRequest(item)
    }
  }
  const openMyChat = (item: ChatType) => {
    navigate(
      `${indexPathByRole(role)}/messeges/chat?chatId=${JSON.stringify(item._id)}`,
      {
        state: {
          participants: item.participants,
          chat: { ...item, chatId: item._id },
        },
      },
    )
  }

  return (
    <div className="explore__chats">
      <UserHeader>
        <div className="explore__main-header">
          <div className="explore__main-header-user">
            <button onClick={() => navigate(-1)}>
              <IconLeftChevrons />
            </button>
            {avatarFileName ? (
              <img src={`${apiBaseURL}/uploads/avatar/${avatarFileName}`} />
            ) : (
              <div
                style={{
                  backgroundColor: 'rgba(41, 52, 65, 1)',
                  borderRadius: '100%',
                  width: '40px',
                  height: '40px',
                  color: '#fff',
                  textAlign: 'center',
                  position: 'relative',
                }}
              >
                <span style={{ position: 'absolute', left: '10px', top: '8px' }}>
                  {fullName.slice(0, 2)}
                </span>
              </div>
            )}
            <div className="explore__main-header-user-location">
              <h6>current location</h6>
              <h5>
                {houseNumber ? houseNumber + ',' : ''} {street ? street + ',' : ''}{' '}
                {country}
              </h5>
            </div>
          </div>
          <div className="explore__main-header-btns">
            <button className="user__header-main-button" onClick={() => {}}>
              <IconProfileInfoBookmark />
            </button>
            <button
              className="user__header-main-button"
              onClick={() =>
                navigate(`${indexPathByRole(role)}/messeges/grouppage`, {
                  state: { privacy: PRIVACY.EVERYONE },
                })
              }
            >
              <IconsNewsfeedPlus />
            </button>
          </div>
        </div>
        <div
          className={`user__header-main-search ${
            isActiveSearch && 'user__header-main-search--active'
          }`}
        >
          <div className="user__header-main-search-input">
            <InputSearch
              placeholder={
                <>
                  Search <span>Chats</span>
                </>
              }
              value={search}
              changeValue={setSearch}
              onFocus={onFocus}
              onBlur={onBlur}
            />
            {isActiveSearch && (
              <button className="user__header-main-search--cancel">Cancel</button>
            )}
          </div>
        </div>
      </UserHeader>
      <div className="explore__chats-container">
        <h5
          style={{
            padding: '5px 20px',
            fontWeight: '600',
            fontSize: '24px',
            color: 'rgba(21, 16, 77, 1)',
          }}
        >
          Choose area
        </h5>
        {/* <div className="explore__chats-container-slider">
                    <Slider {...settings}>
                        {location&& regions && regions?.map((item)=>(
                            <SliderItemChat item={item} setLocation={setLocation} location={location} />
                        ))}
                    </Slider>
                </div> */}
        <div className="explore__chats-container-menu">
          <h5 onClick={() => setOpen(true)}>
            {open ? (
              <b>
                Open chats (
                {
                  chatsListNormal
                    ?.filter((it) => it.privacy === PRIVACY.EVERYONE)
                    ?.filter(
                      (it) =>
                        it?.coordinates?.lat &&
                        !it.participants.find((user) => user.userId?._id === _id) &&
                        ((polygonCoords &&
                          inside(
                            [it.coordinates?.lat, it.coordinates.lng],
                            polygonCoords,
                          )) ||
                          (radiusSize &&
                            insideCircle(
                              [it.coordinates.lat, it.coordinates.lng],
                              radiusSize,
                              [coordinates?.lat, coordinates?.lng],
                            ))),
                    )?.length
                }
                )
              </b>
            ) : (
              `Open chats (${
                chatsListNormal
                  ?.filter((it) => it.privacy === PRIVACY.EVERYONE)
                  ?.filter(
                    (it) =>
                      it?.coordinates?.lat &&
                      !it.participants.find((user) => user.userId?._id === _id) &&
                      ((polygonCoords &&
                        inside(
                          [it.coordinates?.lat, it.coordinates.lng],
                          polygonCoords,
                        )) ||
                        (radiusSize &&
                          insideCircle(
                            [it.coordinates.lat, it.coordinates.lng],
                            radiusSize,
                            [coordinates?.lat, coordinates?.lng],
                          ))),
                  )?.length
              })`
            )}
          </h5>
          <h5 onClick={() => setOpen(false)}>
            {!open ? (
              <b>
                My chats (
                {
                  chatsListNormal
                    ?.filter((it) => it.privacy === PRIVACY.EVERYONE)
                    ?.filter((it) =>
                      it.participants.find((user) => user.userId?._id === _id),
                    )?.length
                }
                )
              </b>
            ) : (
              `My chats (${
                chatsListNormal
                  ?.filter((it) => it.privacy === PRIVACY.EVERYONE)
                  ?.filter((it) =>
                    it.participants.find((user) => user.userId?._id === _id),
                  )?.length
              })`
            )}
          </h5>
        </div>
        <div className="explore__chats-container-list">
          {open && (
            <div className="messenger__list">
              {chatsListNormal
                ?.filter((it) => it.privacy === PRIVACY.EVERYONE)
                ?.filter(
                  (it) =>
                    it?.coordinates?.lat &&
                    !it.participants.find((user) => user.userId?._id === _id) &&
                    ((polygonCoords &&
                      inside([it.coordinates?.lat, it.coordinates.lng], polygonCoords)) ||
                      (radiusSize &&
                        insideCircle(
                          [it.coordinates.lat, it.coordinates.lng],
                          radiusSize,
                          [coordinates?.lat, coordinates?.lng],
                        ))),
                )
                ?.map((item, key) => (
                  <>
                    <div
                      onClick={() => openChat(item)}
                      className="user__chat-groups-item"
                      style={{ margin: '0 15px', borderRadius: '12px' }}
                    >
                      <div className="user__chat-groups-item-info">
                        <div className="user__chat-groups-item-info-g">
                          <img
                            style={{
                              minWidth: '70px',
                              height: '70px',
                              objectFit: 'cover',
                            }}
                            src={
                              item?.groupImage
                                ? `${apiBaseURL}/uploads/messenger/${item?.groupImage}`
                                : '/Images/postsBG.png'
                            }
                            alt=""
                          />
                          <div className="user__chat-groups-item-info-text">
                            <h5 style={{ fontSize: '16px' }}>
                              {item.name.length > 25
                                ? item.name.slice(0, 18) + '...'
                                : item.name}
                            </h5>
                            <h6 style={{ fontSize: '14px' }}>
                              {item.participants.length} neibs
                            </h6>
                          </div>
                        </div>
                        {!item.participants.find((it) => it.userId?._id === _id) && (
                          <span
                            onClick={() => joinGroup(item)}
                            className="user__chat-groups-item-info-join"
                          >
                            Join
                          </span>
                        )}
                      </div>
                      {item.interests && (
                        <div className="user__chat-groups-item-interests profile__identity-list-body">
                          {item?.interests?.slice(0, 7).map((item, index) => (
                            <div
                              className={`user__chat-groups-item-interests-item 
                                                ${
                                                  value.find(
                                                    (it) => it.title === item.title,
                                                  )
                                                    ? 'user__chat-groups-item-interests-item-active'
                                                    : ''
                                                }  
                                            `}
                              key={index}
                            >
                              <div className="profile__identity-list-item-text">
                                {item.title}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </>
                ))}
            </div>
          )}
          {!open && (
            <div className="messenger__list">
              {chatsListNormal
                ?.filter((it) => it.privacy === PRIVACY.EVERYONE)
                ?.filter((it) => it.participants.find((user) => user.userId?._id === _id))
                ?.map((item, key) => (
                  <>
                    <div
                      onClick={() => openMyChat(item)}
                      className="user__chat-groups-item"
                      style={{ margin: '0 15px', borderRadius: '12px' }}
                    >
                      <div className="user__chat-groups-item-info">
                        <div className="user__chat-groups-item-info-g">
                          <img
                            src={
                              item?.groupImage
                                ? `${apiBaseURL}/uploads/messenger/${item?.groupImage}`
                                : '/Images/postsBG.png'
                            }
                            alt=""
                          />
                          <div className="user__chat-groups-item-info-text">
                            <h5 style={{ fontSize: '16px' }}>
                              {item.name.length > 25
                                ? item.name.slice(0, 18) + '...'
                                : item.name}
                            </h5>
                            <h6 style={{ fontSize: '14px' }}>
                              {item.participants.length} neibs
                            </h6>
                          </div>
                        </div>
                        {!item.participants.find((it) => it.userId._id === _id) && (
                          <span
                            onClick={() => joinGroup(item)}
                            className="user__chat-groups-item-info-join"
                          >
                            Join
                          </span>
                        )}
                      </div>
                      {item.interests && (
                        <div className="user__chat-groups-item-interests profile__identity-list-body">
                          {item?.interests?.slice(0, 7).map((item, index) => (
                            <div
                              className={`user__chat-groups-item-interests-item 
                                        ${
                                          value.find((it) => it.title === item.title)
                                            ? 'user__chat-groups-item-interests-item-active'
                                            : ''
                                        }  
                                    `}
                              key={index}
                            >
                              <div className="profile__identity-list-item-text">
                                {item.title}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </>
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
