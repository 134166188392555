export const SelectSticker = ({
  selectSmile,
}: {
  selectSmile: (smile: string) => {}
}) => {
  return (
    <div className="sticker">
      <div
        className="sticker-item"
        onClick={(e) => {
          e.stopPropagation()
          selectSmile('❤️️')
        }}
      >
        ❤️️
      </div>
      <div
        className="sticker-item"
        onClick={(e) => {
          e.stopPropagation()
          selectSmile('👍')
        }}
      >
        👍
      </div>
      <div
        className="sticker-item"
        onClick={(e) => {
          e.stopPropagation()
          selectSmile('👎')
        }}
      >
        👎
      </div>
      <div
        className="sticker-item"
        onClick={(e) => {
          e.stopPropagation()
          selectSmile('👏')
        }}
      >
        👏
      </div>
      <div
        className="sticker-item"
        onClick={(e) => {
          e.stopPropagation()
          selectSmile('🔥')
        }}
      >
        🔥
      </div>
      <div
        className="sticker-item"
        onClick={(e) => {
          e.stopPropagation()
          selectSmile('😡')
        }}
      >
        😡
      </div>
      <div
        className="sticker-item"
        onClick={(e) => {
          e.stopPropagation()
          selectSmile('🤬')
        }}
      >
        🤬
      </div>
    </div>
  )
}
