export const IconTime = () => {
  return (
    <svg
      style={{ margin: 'auto 0' }}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C4.47581 0 0 4.47581 0 10C0 15.5242 4.47581 20 10 20C15.5242 20 20 15.5242 20 10C20 4.47581 15.5242 0 10 0ZM13.7294 12.621L12.923 13.629C12.8701 13.6952 12.8046 13.7503 12.7304 13.7912C12.6562 13.832 12.5746 13.8579 12.4904 13.8673C12.4062 13.8766 12.321 13.8693 12.2396 13.8457C12.1582 13.8221 12.0823 13.7828 12.0161 13.7298L9.31452 11.725C9.12575 11.5739 8.97338 11.3822 8.86869 11.1642C8.76399 10.9463 8.70965 10.7075 8.70968 10.4657V4.19355C8.70968 4.02244 8.77765 3.85834 8.89864 3.73735C9.01963 3.61636 9.18373 3.54839 9.35484 3.54839H10.6452C10.8163 3.54839 10.9804 3.61636 11.1014 3.73735C11.2224 3.85834 11.2903 4.02244 11.2903 4.19355V10L13.629 11.7137C13.6952 11.7667 13.7503 11.8321 13.7912 11.9064C13.8321 11.9807 13.8579 12.0623 13.8672 12.1465C13.8766 12.2308 13.8692 12.3161 13.8456 12.3975C13.8219 12.4789 13.7824 12.5548 13.7294 12.621Z"
        fill="#0F1419"
      />
    </svg>
  )
}
