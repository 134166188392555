import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { PublishEventItemInterface } from '../../../types/types'
import { useAppSelector } from '../../../utils/hooks'
import { IconBookMark } from '../../svg/IconBookMark'
import { IconComment } from '../../svg/IconFavor'
import { IconPostsLike, IconPostsRedLike, IconPostsRepost } from '../../svg/IconPosts'
import { IconProfileInfoBookmark } from '../../svg/IconProfileInfo'
import { TempNotification } from '../../ui/TempNotification'
export const PublicationEventPanel = ({
  item,
  updateLike,
  navigateToComments,
  updateMark,
  updateRepost,
  setIsOpen,
  isOpen,
  active,
}: {
  item: PublishEventItemInterface
  updateLike: (likeId: string, postId: string) => void
  navigateToComments: (postId: string) => void
  updateMark: (postId: string, isMarked: boolean) => void
  updateRepost: (url?: string) => void
  setIsOpen?: ((o: boolean) => void) | any
  isOpen?: boolean
  active?: boolean
}) => {
  const { _id, role } = useAppSelector((state) => state.user)
  const navigate = useNavigate()
  useEffect(() => {
    console.log(item)
  }, [item])

  return (
    <div className="admin__posts-list-row4">
      {
        <button
          onClick={(e) => {
            e.stopPropagation()
            updateLike(item?.likeId, item._id)
          }}
        >
          {item?.isLiked ? <IconPostsRedLike /> : <IconPostsLike />}

          <span>{item?.countLikes}</span>
        </button>
      }
      <button
        onClick={(e) => {
          e.stopPropagation()
          navigateToComments(item?._id)
        }}
      >
        <IconComment />
        <span>{item?.countComments}</span>
      </button>
      <button
        onClick={(e) => {
          e.stopPropagation()
          updateRepost(
            `${window.location.host}/user/activities/publish-activities-item?publishActivitiesId=${item._id}`,
          )
        }}
      >
        <IconPostsRepost />
        {<span>{item?.countShare}</span>}
      </button>
      <div
        onClick={(e) => {
          if (active) {
            e.stopPropagation()
            updateMark(item._id, active)
          } else {
            e.stopPropagation()
            updateMark(item._id, item?.isMarked)
          }
        }}
        className={`${
          (item?.isMarked || active) && 'admin__posts-list-row4-repost--active'
        }`}
      >
        <IconProfileInfoBookmark />
      </div>
      {isOpen && (
        <TempNotification setIsOpen={setIsOpen} isOpen={isOpen}>
          <IconBookMark />
        </TempNotification>
      )}
    </div>
  )
}
