import {
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { useNavigate, Outlet, useOutletContext } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../store'
import { setIsWelcome } from '../../../redux/app'
import { Button, Layout } from 'antd'
import { IconWelcomeLogo } from '../../svg/IconWelcomeLogo'

const { Header, Footer, Content } = Layout

export interface WelcomeProps {
  image: ReactElement
  title: string
  subTitle: string
  nextPath: string
  nextButtonTitle?: string
  isFirstStep?: true
  isLastStep?: true
}

export default function Welcome({
  image,
  nextPath,
  title,
  subTitle,
  nextButtonTitle,
  isFirstStep,
  isLastStep,
}: WelcomeProps) {
  const [isLogo, setIsLogo] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setIsLogo(false)
    }, 3000)
  }, [])

  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  const handleNext = () => {
    isLastStep && dispatch(setIsWelcome(true))
    navigate(nextPath)
  }

  return (
    <Layout
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundImage: `url(/Images/welcomebg.png)`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    >
      {isLogo ? (
        <IconWelcomeLogo />
      ) : (
        <>
          <Header
            style={{
              justifyContent: 'center',
              display: 'flex',
              height: 'auto',
              backgroundColor: 'transparent',
            }}
          >
            <img src="/Images/logo.png" alt="" style={{ margin: '3em' }} />
          </Header>
          <Content
            style={{
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
              padding: '0 1.25em 0 1.25em',
            }}
          >
            {image}
            <div>
              <h4 className="welcome-btitle">{title}</h4>
              <h5 className="welcome-subtitle">{subTitle}</h5>
            </div>
          </Content>
          <Footer
            style={{
              width: '100%',
              padding: '0.5em',
              display: 'flex',
              flexDirection: 'row',
              gap: '0.5em',
            }}
          >
            {!isFirstStep && (
              <Button block onClick={() => navigate(-1)}>
                Back
              </Button>
            )}
            <Button onClick={handleNext} block type="primary">
              {nextButtonTitle || 'Next'}
            </Button>
          </Footer>
        </>
      )}
    </Layout>
  )
}
