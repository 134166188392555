export const IconSearchMap = ({ fill }: { fill: string }) => {
  return (
    <svg
      style={{ left: '20%' }}
      width="28"
      height="24"
      viewBox="0 0 36 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.6675 22.6675H23.6142L23.2408 22.3075C24.8408 20.4408 25.6675 17.8942 25.2142 15.1875C24.5875 11.4808 21.4942 8.52083 17.7608 8.0675C12.1208 7.37417 7.37417 12.1208 8.0675 17.7608C8.52083 21.4942 11.4808 24.5875 15.1875 25.2142C17.8942 25.6675 20.4408 24.8408 22.3075 23.2408L22.6675 23.6142V24.6675L28.3342 30.3342C28.8808 30.8808 29.7742 30.8808 30.3208 30.3342C30.8675 29.7875 30.8675 28.8942 30.3208 28.3475L24.6675 22.6675ZM16.6675 22.6675C13.3475 22.6675 10.6675 19.9875 10.6675 16.6675C10.6675 13.3475 13.3475 10.6675 16.6675 10.6675C19.9875 10.6675 22.6675 13.3475 22.6675 16.6675C22.6675 19.9875 19.9875 22.6675 16.6675 22.6675Z"
        fill={fill}
      />
    </svg>
  )
}
export const IconSearchMapGray = () => {
  return (
    <svg
      style={{ left: '20%' }}
      width="28"
      height="24"
      viewBox="0 0 36 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.6675 22.6675H23.6142L23.2408 22.3075C24.8408 20.4408 25.6675 17.8942 25.2142 15.1875C24.5875 11.4808 21.4942 8.52083 17.7608 8.0675C12.1208 7.37417 7.37417 12.1208 8.0675 17.7608C8.52083 21.4942 11.4808 24.5875 15.1875 25.2142C17.8942 25.6675 20.4408 24.8408 22.3075 23.2408L22.6675 23.6142V24.6675L28.3342 30.3342C28.8808 30.8808 29.7742 30.8808 30.3208 30.3342C30.8675 29.7875 30.8675 28.8942 30.3208 28.3475L24.6675 22.6675ZM16.6675 22.6675C13.3475 22.6675 10.6675 19.9875 10.6675 16.6675C10.6675 13.3475 13.3475 10.6675 16.6675 10.6675C19.9875 10.6675 22.6675 13.3475 22.6675 16.6675C22.6675 19.9875 19.9875 22.6675 16.6675 22.6675Z"
        fill={'rgba(195, 202, 217, 1)'}
      />
    </svg>
  )
}
