import moment from 'moment'
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import $api from '../../../http'
import { ActivitiesHttp } from '../../../http/activities-http'
import { FeedBackHttp } from '../../../http/feedback-http'
import { IdentityHttp } from '../../../http/identity-http'
import { PublishPostHttp } from '../../../http/publish-post-http'
import { ServiceHttp } from '../../../http/service-http'
import { UserHttp } from '../../../http/user-http'
import { UserInitialStateInterface, setLoader } from '../../../redux/users'
import { UserIdentityInterface } from '../../../redux/profile'
import { EDUCATION, NOTIFICATION_POST, ORIENTATION, ROLE, SEX } from '../../../types/enum'
import {
  EventFilterType,
  NeibsFilterType,
  OptionsType,
  PostFilterType,
  PostUserInterface,
  PublishEventItemInterface,
  PublishServiceItemInterface,
  ServiceFilterType,
} from '../../../types/types'
import { calculateDistance, calculateRate } from '../../../utils/calculateRate'
import { apiBaseURL, indexPathByRole } from '../../route/indexPathByRole'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { PublicationEventPanel } from '../../general-components/publication-lists/PublicationEventPanel'
import { PublicationPostMyModal } from '../../general-components/publication-lists/PublicationPostMyModal'
import { PublicationPostNeibModal } from '../../general-components/publication-lists/PublicationPostNeibModal'
import { PublicationPostsPanel } from '../../general-components/publication-lists/PublicationPostsPanel'
import { IconArrowRight } from '../../svg/IconArrow'
import { IconBookMark } from '../../svg/IconBookMark'
import { IconStars } from '../../svg/IconFavor'
import { IconPostModalPin } from '../../svg/IconPostModal'
import {
  IconProfileInfoBookmark,
  IconProfileInfoBookmarkCopy,
} from '../../svg/IconProfileInfo'
import { IconServicesAllPoint } from '../../svg/IconServicesAll'
import { DarkBg } from '../../ui/DarkBg'
import { InputSearch } from '../../ui/InputSearch'
import { success } from '../../ui/LoadSuccess'
import { Loader } from '../../ui/Loader'
import { PostSlick } from '../../ui/PostSlick'
import {
  EventFilter,
  NeibsFilter,
  PostFilter,
  ServiceFilter,
} from '../../ui/SearchFilter'
import { SlickCategories } from '../../ui/SlickCategories'
import { TempNotification } from '../../ui/TempNotification'
import { ActivitiesMyModal } from '../activities/ActivitiesMyModal'
import { ActivitiesNeibModalMany } from '../activities/ActivitiesNeibModalMany'
import { UserHeader } from '../header/UserHeader'
import { ExploreSearchNoResult } from './ExploreSearchNoResult'
import { allActivities } from '../../../redux/activities'
import { allCategories } from '../../../redux/categories'
import { Route, Routes } from 'react-router-dom'
import { getTenPublishActivities } from '../../../redux/activities'
import { getPosts } from '../../../redux/posts'

const catList = ['all', 'neighbors', 'services', 'activities', 'posts']

export const ExploreSearch = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [search, setSearch] = useState<string>(location.state?.search || '')
  const [users, setUsers] = useState<UserInitialStateInterface[]>([])
  const { _id } = useAppSelector((state) => state.user)
  const { publishActivities } = useAppSelector((state) => state.activities)
  const { coordinates } = useAppSelector((state) => state.profile)
  const dispatch = useAppDispatch()
  const [load, setLoad] = useState(false)
  const [services, setServices] = useState<PublishServiceItemInterface[]>([])
  const [servicesNormal, setServicesNormal] = useState<PublishServiceItemInterface[]>([])
  const [posts, setPosts] = useState<PostUserInterface[]>([])
  const [postsNormal, setPostsNormal] = useState<PostUserInterface[]>([])
  const [allPageNumber, setAllPageNumber] = useState(1)
  const [filterBtn, setFilterBtn] = useState<boolean>(true)
  const [identity, setIdentity] = useState<UserIdentityInterface[]>([])
  const [usersNormal, setUsersNormal] = useState<UserInitialStateInterface[]>([])

  const [type, setType] = useState<string>(location.state.category || 'all')

  const { role } = useAppSelector((state) => state.user)
  const [currentSlide, setCurrentSlide] = useState(0)
  useEffect(() => {
    if (location.state) {
      setCurrentSlide(catList.indexOf(location.state.category))
    }
    dispatch(getTenPublishActivities({ userId: _id }))
  }, [])

  const { ref, inView } = useInView({
    threshold: 0,
  })
  const [searchParams] = useSearchParams()

  const [currnetItem, setCurrentItem] = useState<PostUserInterface | null>(null)
  const [myModalOpen, setMyModalOpen] = useState(false)
  const [neibModalOpen, setNeibModalOpen] = useState(false)
  const [activitiesList, setActivitiesList] = useState<PublishEventItemInterface[]>([])
  const [activitiesListNormal, setActivitiesListNormal] = useState<
    PublishEventItemInterface[]
  >([])

  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false)
  const [value, setValue] = useState<OptionsType>([])

  const [viewAllPosts, setViewAllPosts] = useState<number>(2)
  const [viewAllServices, setViewAllServices] = useState<number>(2)
  const [viewAllEvents, setViewAllEvents] = useState<number>(2)
  const [viewAllUsers, setViewAllUsers] = useState<number>(2)

  const openPosts = () => {
    setViewAllPosts(viewAllPosts + 10)
  }
  const openServices = () => {
    setViewAllServices(viewAllServices + 10)
  }
  const openEvents = () => {
    setViewAllEvents(viewAllEvents + 10)
  }
  const openUsers = () => {
    setViewAllUsers(viewAllUsers + 10)
  }

  useEffect(() => {
    IdentityHttp.getUserIdentity({ _id }).then((res) => {
      setValue(res.interests)
    })
  }, [])
  const [filterPost, setFilterPost] = useState<PostFilterType>({
    userId: '',
    when: 'Today',
    where: 'Kiyv',
    seen: false,
    far: 1,
  })
  const [filterEvent, setFilterEvent] = useState<EventFilterType>({
    what: 'act',
    when: 'Today',
    where: 'Kiyv',
    seen: false,
    far: 1,
  })
  const [filterServie, setFilterServie] = useState<ServiceFilterType>({
    category: 'Food',
    rating: 0,
    far: 1,
    onlineTime: 'Today',
  })
  const [filterNeibs, setFilterNeibs] = useState<NeibsFilterType>({
    gender: SEX.MALE,
    orientation: ORIENTATION.HETERO,
    nationality: 'Afghan',
    age: [18, 20],
    profession: 'Actor',
    education: EDUCATION.BACHERLOR,
    skills: 'Cooking and Meal Preparation',
    interests: 'Reading',
    far: 1,
  })
  const [usedFilterNeibs, setUsedFilterNeibs] = useState(false)
  const [usedServicesFilter, setUsedServicesFilter] = useState(false)
  const [usedPostsFilter, setUsedPostsFilter] = useState(false)
  const [usedEventsFilter, setUsedEventsFilter] = useState(false)
  useEffect(() => {
    if (!usedFilterNeibs) {
      setUsersNormal(users)
    }
    if (!usedServicesFilter) {
      setServicesNormal(services)
    }
    if (!usedPostsFilter) {
      setPostsNormal(posts)
    }
    if (!usedEventsFilter) {
      setActivitiesListNormal(activitiesList)
    }
  }, [users, posts, services, activitiesList])
  useEffect(() => {
    const u: UserInitialStateInterface[] = []
    users.map((item) => {
      if (
        filterNeibs.far &&
        item.coords &&
        Math.round(
          calculateDistance(coordinates, { lat: item.coords.lat, lng: item.coords.lng }) /
            1000,
        ) === filterNeibs.far
      ) {
        u.push(item)
      }
    })
    setUsersNormal(u)
  }, [filterNeibs.far])
  useEffect(() => {
    const u: UserInitialStateInterface[] = []
    users.map((item) => {
      if (
        filterNeibs.age &&
        moment(new Date()).year() - moment(item.identity?.dateBirth).year() >
          filterNeibs.age[0] &&
        moment(new Date()).year() - moment(item.identity?.dateBirth).year() <
          filterNeibs.age[1]
      ) {
        u.push(item)
      }
    })
    setUsersNormal(u)
  }, [filterNeibs.age])
  useEffect(() => {
    if (type === 'all') {
      setFilterBtn(false)
    }
    const timeOutId = setTimeout(() => {
      if (type === 'neighbors' || type === 'all') {
        let array: UserInitialStateInterface[] = []
        users.map((item) => {
          if (!search && usedFilterNeibs) {
            if (
              filterNeibs.orientation === item.identity?.orientation ||
              filterNeibs.gender === item.identity?.sex ||
              (filterNeibs.nationality &&
                filterNeibs.nationality === item.identity?.nationality[0]?.title) ||
              (filterNeibs.education &&
                filterNeibs.education === item.identity?.education) ||
              (filterNeibs.profession &&
                filterNeibs.profession === item.identity?.professions[0]?.title) ||
              (filterNeibs.skills &&
                filterNeibs.skills === item.identity?.skills[0]?.title) ||
              (filterNeibs.interests &&
                filterNeibs.interests === item.identity?.interests[0]?.title)
            ) {
              array.push(item)
            }
          } else {
            if (
              item.fullName
                .toLowerCase()
                .replaceAll(' ', '')
                .includes(search.toLowerCase().replaceAll(' ', ''))
            ) {
              array.push(item)
            }
          }
        })
        setUsersNormal(array)
      }
      if (type === 'services' || type === 'all') {
        let array: PublishServiceItemInterface[] = []
        services.map((item) => {
          if (!search && usedServicesFilter) {
            if (
              (item?.categoryId?.name === filterServie.category &&
                ((filterServie.rating < 3 && calculateRate(item) < 3) ||
                  (filterServie.rating >= 4.5 && calculateRate(item) >= 4.5) ||
                  filterServie.rating === calculateRate(item))) ||
              Math.round(calculateDistance(coordinates, item.coordinates) / 1000) ===
                filterServie.far
            ) {
              array.push(item)
            }
          } else {
            if (item.title.toLowerCase().includes(search.toLowerCase())) {
              array.push(item)
            }
          }
        })
        setServicesNormal(array)
      }
      if (posts && (type === 'posts' || type === 'all')) {
        setLoad(true)
        let array: PostUserInterface[] = []
        posts.map((item) => {
          if (!search && usedPostsFilter) {
            if (
              item.createdPostDate.toString().split('.')[0].replace('T', ' ') ===
                filterPost.when ||
              item.userId.fullName === filterPost.userId ||
              item.addressLocation.includes(filterPost.where) ||
              ((item.viewPost > 0 ? true : false) == filterPost.seen &&
                Math.round(calculateDistance(coordinates, item.coordinates) / 1000) ===
                  filterPost.far)
            ) {
              array.push(item)
            }
          } else {
            if (
              item.title.toLowerCase().includes(search.toLowerCase()) ||
              item.userId?.fullName.toLowerCase().includes(search.toLowerCase()) ||
              item.text.toLowerCase().includes(search.toLowerCase())
            ) {
              array.push(item)
            }
          }
        })
        setPostsNormal(array)
        setLoad(false)
      }
      if (type === 'events' || type === 'all') {
        let array: PublishEventItemInterface[] = []
        console.log(publishActivities)
        activitiesList.map((item) => {
          console.log('item 123')
          if (!search && usedEventsFilter) {
            console.log('item')
            if (
              item.activitiesId.name === filterEvent.what ||
              (item.startDate.toString().split('.')[0].replace('T', ' ') ===
                filterEvent.when &&
                (item.privacyEvent === 'Neibs' ? true : false) == filterEvent.seen &&
                Math.round(calculateDistance(coordinates, item.coordinates) / 1000) ===
                  filterEvent.far)
            ) {
              array.push(item)
            }
          } else {
            if (
              item.title.toLowerCase().includes(search.toLowerCase()) ||
              item.text.toLowerCase().includes(search.toLowerCase()) ||
              item.text.toLowerCase().includes(search.toLowerCase())
            ) {
              array.push(item)
            }
          }
        })
        setActivitiesListNormal(array)
      }
    }, 1000)
    return () => clearTimeout(timeOutId)
  }, [search, filterPost, filterEvent, filterNeibs, filterServie])

  useEffect(() => {
    const effectBody = async () => {
      ServiceHttp.getTenPublishService().then((s) => setServices(s))
      PublishPostHttp.getTenPosts().then((s) => setPosts(s))
    }

    effectBody()
  }, [])

  useEffect(() => {
    setLoad(true)
    if (publishActivities) {
      setActivitiesList(publishActivities)
    }
    setLoad(false)
  }, [publishActivities])

  const getUsers = async () => {
    setLoad(true)
    const res: UserInitialStateInterface[] = await UserHttp.getAllUsers()
    let array: UserIdentityInterface[] = []
    setLoad(false)
    setIdentity(array)
    setUsers(res.filter((it) => it._id !== _id))
    dispatch(setLoader(false))
  }

  const getAllPostReload = async (userId: string) => {
    setLoad(true)
    setPosts([])
    for (let i = 1; i <= allPageNumber; i++) {
      const res = await PublishPostHttp.getPosts({
        pageNumber: i,
        userId,
      })
      setPosts(res.posts)
    }
    setLoad(false)
    dispatch(setLoader(false))
  }

  const openModal = (item: PostUserInterface) => {
    setCurrentItem(item)
    if (item.userId._id === _id) {
      setMyModalOpen(true)
    } else {
      setNeibModalOpen(true)
    }
  }

  const openModalEvent = (item: PublishEventItemInterface) => {
    setCurrentItemEvent(item)
    if (item.userId._id === _id) {
      setIsOpenModal(true)
    } else {
      setIsOpenModal(true)
    }
  }

  const saveSearchEvent = async (
    eventId: string,
    postId?: string,
    serviceId?: string,
  ) => {
    await $api.post('search/update', { eventId, userId: _id })
    success()
  }
  const saveSearchPost = async (postId: string) => {
    await $api.post('search/update', { postId, userId: _id })
    success()
  }
  const saveSearchService = async (serviceId: string) => {
    await $api.post('search/update', { serviceId, userId: _id })
    success()
  }

  const navigateToComments = (postId: string) => {
    navigate(`${indexPathByRole(role)}/comments?postId=${postId}`)
    saveSearchPost(_id)
  }

  const toProfileInfo = (prop: {
    _id: string
    email: string
    role: string
    fullName: string
    avatarFileName: string
  }) => {
    navigate('/profileinfo', {
      state: prop,
    })
  }

  const updatePin = async (repostId: string) => {
    await PublishPostHttp.updatePin({
      repostId: repostId,
      userId: _id,
    })

    setPosts((s) =>
      s.map((p) => {
        if (p.repostId === repostId) {
          const newItem = {
            ...p,
            isPined: !p.isPined,
          }
          setCurrentItem(newItem)
          return newItem
        } else {
          return p
        }
      }),
    )
    getPinPost(_id)
  }

  const hidePost = async (body: { hideUserId?: string; hideRepostId?: string }) => {
    await PublishPostHttp.hidePost({
      ownerId: _id,
      ...body,
    })

    setPosts((s) =>
      s.filter(
        (item) =>
          !(
            item?.repostId === body?.hideRepostId || item?.userId._id === body?.hideUserId
          ),
      ),
    )

    setNeibModalOpen(false)
  }

  const updateLike = async (likeId: string, postId: string) => {
    await FeedBackHttp.updateLike({
      likeId,
      userId: _id,
    })
    setPosts((s) =>
      s.map((p) => {
        if (p._id === postId) {
          return {
            ...p,
            isLiked: !p.isLiked,
            likes: p.isLiked ? p.likes - 1 : p.likes + 1,
          }
        } else {
          return p
        }
      }),
    )
  }

  const getPinPost = async (userId: string) => {
    const pinedList = await PublishPostHttp.getPostPin({ userId })

    if (pinedList.length) {
      const pinedPostList = await PublishPostHttp.getPosts({
        pageNumber: 0,
        userId,
        listPinedPost: pinedList.map((item) => item.repostId),
      })

      setPosts((s) => [...pinedPostList.posts, ...s])
      return
    }

    setPosts((s) => s.filter((item) => !item.isPinedPostFlag))
  }

  useEffect(() => {
    if (_id) getPinPost(_id)
  }, [])

  const toPublishActivities = (_id: string) => {
    navigate(`/user/activities/publish-activities-item?publishActivitiesId=${_id}`)
    saveSearchEvent(_id)
  }

  const updateRepost = async ({
    postId,
    isReposted,
  }: {
    postId: string
    isReposted: boolean
  }) => {
    await PublishPostHttp.updateRepost({
      postId,
      repostedUserId: _id,
    })
  }

  const updateMark = async (postId: string, isMarked: boolean) => {
    if (isMarked) {
      await PublishPostHttp.deleteMark({
        postId,
        marckedUserId: _id,
      })
    } else {
      await PublishPostHttp.addMark({
        postId,
        marckedUserId: _id,
      })
    }

    setPosts((s) =>
      s.map((p) => {
        if (p._id === postId) {
          const newItem = {
            ...p,
            isMarked: !p.isMarked,
          }
          setCurrentItem(newItem)
          return newItem
        } else {
          return p
        }
      }),
    )
  }

  const updateNotification = async (
    postId: string,
    typeNotification: NOTIFICATION_POST,
  ) => {
    await PublishPostHttp.updateNotification({
      postId: postId,
      userId: _id,
      typeNotification,
    })

    setPosts((s) =>
      s.map((p) => {
        if (p._id === postId) {
          const newItem = {
            ...p,
            [NOTIFICATION_POST.COMMENT === typeNotification
              ? 'isNotificatedComment'
              : 'isNotificatedPost']:
              NOTIFICATION_POST.COMMENT === typeNotification
                ? !p.isNotificatedComment
                : !p.isNotificatedPost,
          }
          setCurrentItem(newItem)
          return newItem
        } else {
          return p
        }
      }),
    )
  }

  const [isOpenSave, setIsOpenSave] = useState(false)
  const updateMarkService = async (serviceId: string, isMarked: boolean) => {
    if (isMarked) {
      await ServiceHttp.deleteMark({
        serviceId,
        marckedUserId: _id,
      })
    } else {
      await ServiceHttp.addMark({
        serviceId,
        marckedUserId: _id,
      })
      setIsOpenSave(true)
    }

    if (servicesNormal) {
      let array: PublishServiceItemInterface[] = []
      servicesNormal.map((item) => {
        if (item._id === serviceId) {
          array.push({
            ...item,
            isMarked: !item?.isMarked,
          })
        } else {
          array.push(item)
        }
      })
      setServicesNormal(array)
    }
  }

  const [isOpenModal, setIsOpenModal] = useState(false)
  const [currentItemEvent, setCurrentItemEvent] = useState<PublishEventItemInterface>()

  const updateNotificationEvent = async (serviceId: string) => {
    if (activitiesListNormal) {
      await ActivitiesHttp.updateNotification({
        eventId: serviceId,
        userId: _id,
      })

      let arr: PublishEventItemInterface[] = []
      activitiesListNormal.map((item) => {
        if (item._id === serviceId) {
          arr.push({ ...item, isNotificatedEvent: !item.isNotificatedEvent })
        } else {
          arr.push(item)
        }
      })
      setActivitiesListNormal(arr)
    }
  }

  const updateLikeEvent = async (likeId: string, serviceId: string) => {
    await FeedBackHttp.updateLike({
      likeId,
      userId: _id,
    })
    let arr: PublishEventItemInterface[] = []
    activitiesListNormal.map((item) => {
      if (item._id === serviceId) {
        arr.push({
          ...item,
          isLiked: !item?.isLiked,
          countLikes: item.isLiked ? item?.countLikes - 1 : item?.countLikes + 1,
        })
      } else {
        arr.push(item)
      }
    })
    setActivitiesListNormal(arr)
  }
  const updateMarkEvent = async (eventId: string, isMarked: boolean) => {
    if (isMarked) {
      await ActivitiesHttp.deleteMark({
        eventId,
        marckedUserId: _id,
      })
    } else {
      await ActivitiesHttp.addMark({
        eventId,
        marckedUserId: _id,
      })
      setIsOpenSave(true)
    }
    let arr: PublishEventItemInterface[] = []
    activitiesListNormal.map((item) => {
      if (item._id === eventId) {
        arr.push({
          ...item,
          isMarked: !item?.isMarked,
        })
      } else {
        arr.push(item)
      }
    })
    setActivitiesListNormal(arr)
  }

  const copyLink = async () => {
    const text = window.location.href
    navigate(`${indexPathByRole(role)}/service/share-list`, { state: { text } })
  }
  const deletePost = (item: PostUserInterface) => {
    if (posts) {
      const arr = posts.filter((it) => it !== item)
      setPosts(arr)
    }
  }
  const toComments = (index: number, comment: string) => {
    setSearch('')
    setCurrentSlide(index)
    setType(comment)
    if (comment === 'services') {
      setFilterBtn(true)
    }
    if (comment === 'posts') {
      setFilterBtn(true)
    }
    if (comment === 'events') {
      setFilterBtn(true)
      let array: PublishEventItemInterface[] = []
      activitiesList.map((item) => {
        if (item.userId._id === _id) {
          array.push(item)
        }
      })
    }
    if (comment === 'neighbors') {
      setFilterBtn(true)
    }
    if (comment === 'all') {
      setFilterBtn(false)
    }
    navigate('', { state: { category: comment } })
  }
  const toPublishService = (_id: string) => {
    navigate(`/user/service/publish-service?publishServiceId=${_id}`)
    saveSearchService(_id)
  }
  const [content, setContent] = useState<{ postId: string; content: boolean }[]>([])
  const updateContent = (item: PostUserInterface, val: boolean) => {
    setContent([...content, { postId: item._id, content: val }])
  }

  useEffect(() => {
    if (_id) {
      getAllPostReload(_id)
    }
    getUsers()
    dispatch(allActivities())
    dispatch(allCategories())
  }, [])
  const calculateDateBirth = (userIdentity: UserIdentityInterface) => {
    let birth = new Date(userIdentity?.dateBirth || new Date())
    let today = new Date()
    return today.getMonth() < birth.getMonth() ||
      (today.getMonth() == birth.getMonth() && today.getDate() < birth.getDate())
      ? new Date()?.getFullYear() -
          new Date(userIdentity?.dateBirth || new Date())?.getFullYear() -
          1
      : new Date()?.getFullYear() -
          new Date(userIdentity?.dateBirth || new Date())?.getFullYear()
  }

  return (
    <div>
      <UserHeader>
        <div className="user__header-messages">
          <div className="explore__search__wrapper">
            <div className="explore-search" style={{ margin: 'auto' }}>
              <InputSearch
                placeholder={
                  <>
                    Search <b>NeighborHarbor</b>
                  </>
                }
                value={search}
                changeValue={setSearch}
                onClickFilter={() => {
                  setIsOpenFilter(true)
                  setUsedFilterNeibs(true)
                  setUsedServicesFilter(true)
                  setUsedPostsFilter(true)
                  setUsedEventsFilter(true)
                }}
                filter={filterBtn}
              />
            </div>
            {search.length ? (
              <button onClick={() => setSearch('')} className="explore__cancel">
                cancel
              </button>
            ) : (
              ''
            )}
          </div>
        </div>
      </UserHeader>
      <div className="explore__search__main__wrapper">
        <div
          className="activities__filter explore__filter"
          style={{ padding: '100px 0', maxWidth: '95%' }}
        >
          <SlickCategories>
            {['All', 'Neighbors', 'Services', 'Events', 'Posts'].map((item, index) => (
              <div
                className={`activities__filter-item ${
                  index === currentSlide && 'activities__filter-item--active'
                }`}
                key={index}
                onClick={() => toComments(index, item.toLocaleLowerCase())}
              >
                {item}
              </div>
            ))}
          </SlickCategories>
          {(type === 'neighbors' || type === 'all') && (
            <>
              {usersNormal.length ? (
                <div className="messenger__alluser">
                  {search && <h2>Neighbors({usersNormal.length})</h2>}
                  {usersNormal.slice(0, viewAllUsers).map((item) => (
                    <>
                      {item._id !== _id && (
                        <div
                          className="user__chat-groups-item"
                          style={{ margin: '15px 0', borderRadius: '12px' }}
                          onClick={() =>
                            toProfileInfo({
                              _id: item?._id || '',
                              email: '',
                              role: ROLE.USER,
                              fullName: item?.fullName || '',
                              avatarFileName: item?.avatarFileName || '',
                            })
                          }
                        >
                          <div className="user__chat-groups-item-info">
                            <div className="user__chat-groups-item-info-g">
                              {item?.avatarFileName ? (
                                <img
                                  src={`${apiBaseURL}/uploads/avatar/${item?.avatarFileName}`}
                                />
                              ) : (
                                <div
                                  style={{
                                    backgroundColor: 'rgba(41, 52, 65, 1)',
                                    borderRadius: '100%',
                                    width: '40px',
                                    height: '40px',
                                    color: '#fff',
                                    textAlign: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                  }}
                                >
                                  <span style={{ margin: 'auto', fontSize: '14px' }}>
                                    {item?.fullName.slice(0, 2)}
                                  </span>
                                </div>
                              )}
                              <div className="user__chat-groups-item-info-text">
                                <h5 style={{ fontSize: '16px' }}>
                                  {item.fullName}
                                  {item.identity?.dateBirth
                                    ? `, ${calculateDateBirth(item.identity)}`
                                    : ''}
                                </h5>
                                <h6 style={{ fontSize: '14px' }}>
                                  {item.identity?.professions[0]?.title}
                                </h6>
                              </div>
                            </div>
                          </div>
                          {item.identity?.interests && (
                            <div className="user__chat-groups-item-interests profile__identity-list-body">
                              {item.identity?.interests
                                ?.slice(0, 7)
                                .map((item, index) => (
                                  <div
                                    className={`user__chat-groups-item-interests-item 
                                        ${
                                          value.find((it) => it.title === item.title)
                                            ? 'user__chat-groups-item-interests-item-active'
                                            : ''
                                        }  
                                    `}
                                    key={index}
                                  >
                                    <div className="profile__identity-list-item-text">
                                      {item.title}
                                    </div>
                                  </div>
                                ))}
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  ))}
                </div>
              ) : (
                ''
              )}
            </>
          )}
          {usersNormal.length >= viewAllUsers &&
          usersNormal.length &&
          (type === 'neighbors' || type === 'all') ? (
            <button
              style={{ width: '100%' }}
              className="user__services-last-button user__services-last-button"
              onClick={openUsers}
            >
              View all
            </button>
          ) : (
            ''
          )}
          {(type === 'services' || type === 'all') && (
            <>
              {servicesNormal.length ? (
                <div className="messenger__alluser">
                  {search && <h2>Services({servicesNormal.length})</h2>}
                  {servicesNormal.slice(0, viewAllServices).map((item) => (
                    <div
                      className="user__services-last-item"
                      onClick={() => toPublishService(item._id)}
                    >
                      <div className="user__services-last-item-img">
                        <div className="user__services-last-item-img-1 user__services-last-item-img-info">
                          <div className="user__services-last-item-img-text">
                            {item?.categoryId?.name}
                          </div>
                        </div>
                        {item.filesName.length ? (
                          <img
                            src={`${apiBaseURL}/uploads/publish_services/${item.filesName[0]}`}
                            alt=""
                          />
                        ) : (
                          <img src={'/Images/postsBG.png'} alt="" className="posts__bg" />
                        )}
                        {item.reviews.length ? (
                          <div className="user__services-last-item-img-2 user__services-last-item-img-info">
                            <div className="user__services-last-item-img-text">
                              <IconStars />
                              <b>{calculateRate(item)}</b>
                              {/* <span>{"( 808 )"}</span> */}
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                      <div
                        className="user__services-last-item-info"
                        style={{ position: 'relative' }}
                      >
                        <div className="user__services-last-item-row1">
                          <h5
                            className="user__services-last-item-title"
                            style={{
                              color: 'rgb(41, 45, 50)',
                              fontSize: '16px',
                              fontWeight: '500',
                            }}
                          >
                            {item.title}
                          </h5>
                          <button
                            className={`${'admin__posts-list-row4-repost--active'}`}
                            onClick={(e) => {
                              e.stopPropagation()
                              updateMarkService(item._id, item?.isMarked)
                            }}
                          >
                            {item.isMarked ? (
                              <IconProfileInfoBookmarkCopy fill="#3361FF" />
                            ) : (
                              <IconProfileInfoBookmark />
                            )}
                          </button>
                        </div>
                        <h6
                          style={{ margin: '4px 0' }}
                          className="user__services-last-item-text"
                        >
                          {item.text}
                        </h6>
                        <div
                          style={{ position: 'absolute', right: '10px', bottom: '0' }}
                          className="user__services-last-item-foot"
                        >
                          {Math.floor(
                            calculateDistance(coordinates, item.coordinates) / 1000,
                          )}{' '}
                          km
                          <IconArrowRight />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                ''
              )}
            </>
          )}
          {servicesNormal.length >= viewAllServices &&
          servicesNormal.length &&
          (type === 'services' || type === 'all') ? (
            <button
              className="user__services-last-button user__services-last-button"
              onClick={openServices}
              style={{ width: '100%' }}
            >
              View all
            </button>
          ) : (
            ''
          )}
          {(type === 'events' || type === 'all') && (
            <>
              {activitiesListNormal.length ? (
                <div className="messenger__alluser">
                  {search && <h2>Events({activitiesListNormal.length})</h2>}
                  {activitiesListNormal.slice(0, viewAllEvents).map((item) => (
                    <div
                      className="user__event-item"
                      onClick={() => toPublishActivities(item._id)}
                    >
                      <div className="user__event-item-row1">
                        <div className="user__event-item-row1-text">
                          <h5>{item.title}</h5>
                          <h6>{item.addressLocation}</h6>
                        </div>
                      </div>
                      <div className="explore__event-item-row2" onClick={() => {}}>
                        {item.filesName.length ? (
                          item?.filesName?.map((it) => (
                            <>
                              <div
                                style={{ position: 'relative' }}
                                className="explore__event-item-row2-img"
                                onClick={() => {}}
                              >
                                <div
                                  className="explore__event-item-row2-img-last"
                                  style={{ zIndex: '1' }}
                                >
                                  <div className="explore__event-item-row2-img-last-text">
                                    {moment(item?.startDate).format('DD/MM/YYYY')}
                                  </div>
                                </div>
                                <div
                                  className="user__event-item-row2"
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    openModalEvent(item)
                                  }}
                                >
                                  <IconServicesAllPoint />
                                </div>
                                <img
                                  src={`${apiBaseURL}/uploads/publish_activities/${it}`}
                                  alt=""
                                />
                                <div className="explore__event-item-row2-img-last-bottom">
                                  <div className="explore__event-item-row2-img-last-bottom-text">
                                    <span
                                      className="admin__posts-list-row1-text"
                                      style={{
                                        color: 'rgba(21, 16, 77, 1)',
                                        zIndex: '1',
                                      }}
                                    >
                                      {item.activitiesId.name}
                                    </span>{' '}
                                  </div>
                                </div>
                              </div>
                            </>
                          ))
                        ) : (
                          <div
                            style={{ position: 'relative' }}
                            className="explore__event-item-row2-img"
                            onClick={() => toPublishActivities(item._id)}
                          >
                            <div
                              className="explore__event-item-row2-img-last"
                              style={{ zIndex: '1' }}
                            >
                              <div className="explore__event-item-row2-img-last-text">
                                {moment(item?.startDate).format('DD/MM/YYYY')}
                              </div>
                            </div>
                            <div
                              className="user__event-item-row2"
                              onClick={(e) => {
                                e.stopPropagation()
                                openModalEvent(item)
                              }}
                            >
                              <IconServicesAllPoint />
                            </div>
                            <img
                              className="posts__bg"
                              src={`/Images/postsBG.png`}
                              alt=""
                            />
                            <div className="explore__event-item-row2-img-last-bottom">
                              <div className="explore__event-item-row2-img-last-bottom-text">
                                <span
                                  className="admin__posts-list-row1-text"
                                  style={{ color: 'rgba(21, 16, 77, 1)', zIndex: '1' }}
                                >
                                  {item.activitiesId.name}
                                </span>{' '}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="user__event-item-row3">
                        <h6>{item.text}</h6>
                        {/* <div className="user__event-item-row3-button">
                                 {item.startDate.toString().split(".")[0].split("T")[0].replaceAll("-", "/") + " " }{ <b>{item.startDate.toString().split(".")[0].split("T")[1].split(":")[0] + ":" + item.startDate.toString().split(".")[0].split("T")[1].split(":")[1]}</b> }
                             </div> */}
                      </div>
                      <div className="user__event-item-row4">
                        <PublicationEventPanel
                          item={item}
                          updateLike={() => updateLikeEvent(item.likes, item._id)}
                          navigateToComments={() => {}}
                          updateMark={() => updateMarkEvent(item._id, item.isMarked)}
                          updateRepost={copyLink}
                        />
                        {/* {<button onClick={() => updateLikeEvent(item.likeId, item._id)}>
                             {item.isLiked ? <IconPostsRedLike /> : <IconPostsLike />}
 
                         </button>}
                         <div onClick={()=>updateMarkEvent(item._id, item.isMarked)} className={item.isMarked ? "user__event-item-row4-bookmark-active" :"user__event-item-row4-bookmark"}>
                             <IconProfileInfoBookmark />
                         </div> */}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                ''
              )}
            </>
          )}
          {activitiesListNormal.length >= viewAllEvents &&
          activitiesListNormal.length &&
          (type === 'events' || type === 'all') ? (
            <button
              className="user__services-last-button user__services-last-button"
              onClick={openEvents}
              style={{ width: '100%' }}
            >
              View all
            </button>
          ) : (
            ''
          )}
          {(type === 'posts' || type === 'all') && (
            <>
              {postsNormal.length ? (
                <div className="messenger__alluser">
                  {search && <h2>Posts({postsNormal.length})</h2>}
                  {postsNormal.slice(0, viewAllPosts).map((item) => (
                    <>
                      {item.repostedUserId &&
                      item?.shareList?.find(
                        (it) =>
                          it.friendId._id === _id || item.repostedUserId?._id === _id,
                      ) ? (
                        <div className="admin__posts-list-item" key={item?._id}>
                          <div className="admin__posts-list-repost">
                            <div className="admin__posts-list-repost-profileInfo">
                              <div
                                className="admin__posts-list-row1-img"
                                onClick={() =>
                                  toProfileInfo({
                                    _id: item.repostedUserId?._id || '',
                                    email: '',
                                    role: ROLE.USER,
                                    fullName: item.repostedUserId?.fullName || '',
                                    avatarFileName:
                                      item?.repostedUserId?.avatarFileName || '',
                                  })
                                }
                              >
                                {item.repostedUserId.avatarFileName ? (
                                  <img
                                    src={`${apiBaseURL}/uploads/avatar/${item.repostedUserId.avatarFileName}`}
                                  />
                                ) : (
                                  <div
                                    style={{
                                      backgroundColor: 'rgba(41, 52, 65, 1)',
                                      borderRadius: '100%',
                                      width: '40px',
                                      height: '40px',
                                      color: '#fff',
                                      textAlign: 'center',
                                      position: 'relative',
                                      margin: '5px 0',
                                    }}
                                  >
                                    <span
                                      style={{
                                        position: 'absolute',
                                        left: '10px',
                                        top: '8px',
                                      }}
                                    >
                                      {item.repostedUserId.fullName.slice(0, 2)}
                                    </span>
                                  </div>
                                )}
                              </div>
                              <div onClick={() => navigateToComments(item._id)}>
                                <div className="admin__posts-list-row1-name">
                                  {item.repostedUserId?.fullName}
                                </div>
                                <div>
                                  <span className="admin__posts-list-row1-text">
                                    {moment(item?.createdRepostDate).format(
                                      'DD MMM YYYY HH:mm',
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <button onClick={() => openModal(item)}>
                              <IconServicesAllPoint />
                            </button>
                          </div>
                          <div className="reposted">
                            <div
                              className="admin__posts-list-row1"
                              style={{ padding: '10px' }}
                            >
                              <div
                                className="admin__posts-list-row1-img"
                                onClick={() =>
                                  toProfileInfo({
                                    _id: item.userId?._id,
                                    email: '',
                                    role: ROLE.USER,
                                    fullName: item.userId?.fullName,
                                    avatarFileName: item?.userId?.avatarFileName || '',
                                  })
                                }
                              >
                                {item.userId.avatarFileName ? (
                                  <img
                                    src={`${apiBaseURL}/uploads/avatar/${item.userId.avatarFileName}`}
                                  />
                                ) : (
                                  <div
                                    style={{
                                      backgroundColor: 'rgba(41, 52, 65, 1)',
                                      borderRadius: '100%',
                                      width: '40px',
                                      height: '40px',
                                      color: '#fff',
                                      textAlign: 'center',
                                      position: 'relative',
                                      margin: '5px 0',
                                    }}
                                  >
                                    <span
                                      style={{
                                        position: 'absolute',
                                        left: '10px',
                                        top: '8px',
                                      }}
                                    >
                                      {item.userId.fullName.slice(0, 2)}
                                    </span>
                                  </div>
                                )}
                              </div>
                              <div onClick={() => navigateToComments(item._id)}>
                                <div className="admin__posts-list-row1-name">
                                  {item.userId?.fullName}
                                  {item.isPinedPostFlag && (
                                    <span className="admin__posts-list-row1-pin">
                                      <IconPostModalPin />
                                    </span>
                                  )}
                                </div>
                                <div>
                                  <span className="admin__posts-list-row1-text">
                                    {moment(item?.createdPostDate).format(
                                      'DD MMM YYYY HH:mm',
                                    )}
                                  </span>{' '}
                                  <span className="admin__posts-list-row1-text admin__posts-list-row1-textunder">
                                    {item?.addressLocation}
                                  </span>
                                </div>
                              </div>
                              <button onClick={() => openModal(item)}>
                                <IconServicesAllPoint />
                              </button>
                            </div>
                            {item.filesName.length > 0 ? (
                              <div
                                className="admin__posts-list-row2"
                                style={{ padding: '10px' }}
                                onClick={() => navigateToComments(item._id)}
                              >
                                <PostSlick list={item?.filesName}>
                                  {item?.filesName?.map((it) => (
                                    <div
                                      className="admin__posts-list-row2-img"
                                      onClick={() => navigateToComments(item._id)}
                                    >
                                      <img
                                        src={`${apiBaseURL}/uploads/publish_post/${it}`}
                                        alt=""
                                      />
                                    </div>
                                  ))}
                                </PostSlick>
                              </div>
                            ) : (
                              ''
                            )}
                            <div
                              style={{ padding: '10px' }}
                              className="admin__posts-list-row3"
                              onClick={() => navigateToComments(item._id)}
                            >
                              <h5>{item.title}</h5>
                              <h6>{item.text}</h6>
                            </div>
                          </div>
                          <PublicationPostsPanel
                            item={item}
                            updateLike={updateLike}
                            navigateToComments={navigateToComments}
                            updateMark={updateMark}
                            updateRepost={updateRepost}
                          />
                        </div>
                      ) : (
                        <>
                          <>
                            <div className="admin__posts-list-item" key={item?._id}>
                              <div
                                className="admin__posts-list-row1"
                                style={{ padding: '10px' }}
                              >
                                <div
                                  className="admin__posts-list-row1-img"
                                  onClick={() =>
                                    toProfileInfo({
                                      _id: item.userId?._id,
                                      email: '',
                                      role: ROLE.USER,
                                      fullName: item.userId?.fullName,
                                      avatarFileName: item?.userId?.avatarFileName || '',
                                    })
                                  }
                                >
                                  {item.userId.avatarFileName ? (
                                    <img
                                      src={`${apiBaseURL}/uploads/avatar/${item.userId.avatarFileName}`}
                                    />
                                  ) : (
                                    <div
                                      style={{
                                        backgroundColor: 'rgba(41, 52, 65, 1)',
                                        borderRadius: '100%',
                                        width: '40px',
                                        height: '40px',
                                        color: '#fff',
                                        textAlign: 'center',
                                        position: 'relative',
                                        margin: '5px 0',
                                      }}
                                    >
                                      <span
                                        style={{
                                          position: 'absolute',
                                          left: '10px',
                                          top: '8px',
                                        }}
                                      >
                                        {item.userId.fullName.slice(0, 2)}
                                      </span>
                                    </div>
                                  )}
                                </div>
                                <div onClick={() => navigateToComments(item._id)}>
                                  <div className="admin__posts-list-row1-name">
                                    {item.userId?.fullName}
                                    {item.isPinedPostFlag && (
                                      <span className="admin__posts-list-row1-pin">
                                        <IconPostModalPin />
                                      </span>
                                    )}
                                  </div>
                                  <div>
                                    <span className="admin__posts-list-row1-text">
                                      {moment(item?.createdPostDate).format(
                                        'DD MMM YYYY HH:mm',
                                      )}
                                    </span>{' '}
                                    <span className="admin__posts-list-row1-text admin__posts-list-row1-textunder">
                                      {item?.addressLocation}
                                    </span>
                                  </div>
                                </div>
                                <button onClick={() => openModal(item)}>
                                  <IconServicesAllPoint />
                                </button>
                              </div>
                              {item.filesName.length > 0 ? (
                                <div
                                  className="admin__posts-list-row2"
                                  style={{ padding: '10px' }}
                                  onClick={() => navigateToComments(item._id)}
                                >
                                  <PostSlick list={item?.filesName}>
                                    {item?.filesName?.map((it) => (
                                      <div
                                        className="admin__posts-list-row2-img"
                                        onClick={() => navigateToComments(item._id)}
                                      >
                                        <img
                                          src={`${apiBaseURL}/uploads/publish_post/${it}`}
                                          alt=""
                                        />
                                      </div>
                                    ))}
                                  </PostSlick>
                                </div>
                              ) : (
                                ''
                              )}
                              <div
                                style={{ padding: '10px' }}
                                className="admin__posts-list-row3"
                                onClick={() => navigateToComments(item._id)}
                              >
                                <h5>{item.title}</h5>
                                <h6>{item.text}</h6>
                              </div>
                              <PublicationPostsPanel
                                item={item}
                                updateLike={updateLike}
                                navigateToComments={navigateToComments}
                                updateMark={updateMark}
                                updateRepost={updateRepost}
                              />
                            </div>
                          </>
                        </>
                      )}
                    </>
                  ))}
                </div>
              ) : (
                ''
              )}
            </>
          )}
          {postsNormal.length >= viewAllPosts &&
          postsNormal.length &&
          (type === 'posts' || type === 'all') ? (
            <button
              className="user__services-last-button user__services-last-button"
              onClick={openPosts}
              style={{ width: '100%' }}
            >
              View all
            </button>
          ) : (
            ''
          )}
        </div>
      </div>
      {load && <Loader />}
      {(isOpenFilter || isOpenModal || myModalOpen || neibModalOpen) && <DarkBg />}
      {/* {isOpenFilter && <SearchFilter
                isOpen={isOpenFilter}
                setIsOpen={setIsOpenFilter}
                filter={filter}
                setFilter={setFilter}
             />
            }             */}
      {isOpenModal && currentItemEvent?.userId._id === _id && currentItemEvent && (
        <ActivitiesMyModal
          setIsOpen={setIsOpenModal}
          isOpen={isOpenModal}
          item={currentItemEvent}
          type="activity"
          updateNotification={() => updateNotificationEvent(currentItemEvent._id)}
        />
      )}
      {isOpenModal && currentItemEvent?.userId._id !== _id && currentItemEvent && (
        <ActivitiesNeibModalMany
          setIsOpen={setIsOpenModal}
          isOpen={isOpenModal}
          item={currentItemEvent}
          type="activity"
          updateNotification={() => updateNotificationEvent(currentItemEvent._id)}
          setPublishService={setCurrentItemEvent}
        />
      )}
      {myModalOpen && (
        <PublicationPostMyModal
          deletePostList={deletePost}
          item={currnetItem}
          isOpen={myModalOpen}
          setIsOpen={setMyModalOpen}
          updateMark={updateMark}
          // getAllPostReload={()=>{}}
          updateNotification={updateNotification}
          updatePin={updatePin}
        />
      )}
      {neibModalOpen && (
        <PublicationPostNeibModal
          item={currnetItem}
          isOpen={neibModalOpen}
          setIsOpen={setNeibModalOpen}
          updateMark={updateMark}
          updateNotification={updateNotification}
          toProfileInfo={toProfileInfo}
          hidePost={hidePost}
          updateContent={updateContent}
          content={content}
        />
      )}
      {isOpenFilter && type == 'posts' && (
        <PostFilter
          isOpen={isOpenFilter}
          setIsOpen={setIsOpenFilter}
          filter={filterPost}
          setFilter={setFilterPost}
        />
      )}
      {isOpenFilter && type == 'events' && (
        <EventFilter
          isOpen={isOpenFilter}
          setIsOpen={setIsOpenFilter}
          filter={filterEvent}
          setFilter={setFilterEvent}
        />
      )}
      {isOpenFilter && type == 'services' && (
        <ServiceFilter
          isOpen={isOpenFilter}
          setIsOpen={setIsOpenFilter}
          filter={filterServie}
          setFilter={setFilterServie}
        />
      )}
      {isOpenFilter && type == 'neighbors' && (
        <NeibsFilter
          isOpen={isOpenFilter}
          setIsOpen={setIsOpenFilter}
          filter={filterNeibs}
          setFilter={setFilterNeibs}
          apply={() => setIsOpenFilter(false)}
        />
      )}
      {isOpenSave && (
        <TempNotification setIsOpen={setIsOpenSave} isOpen={isOpenSave}>
          <IconBookMark />
        </TempNotification>
      )}
      {(!postsNormal.length && type === 'posts') ||
      (!servicesNormal.length && type === 'services') ||
      (!activitiesListNormal.length && type === 'events') ||
      (!usersNormal.length &&
        type === 'neighbors' &&
        search.replaceAll(' ', '').length) ? (
        <ExploreSearchNoResult search={search} />
      ) : (
        ''
      )}
      {!postsNormal.length &&
      type === 'all' &&
      !servicesNormal.length &&
      type === 'all' &&
      !activitiesListNormal.length &&
      type === 'all' &&
      !usersNormal.length &&
      type === 'all' &&
      search.replaceAll(' ', '').length ? (
        <ExploreSearchNoResult search={search} />
      ) : (
        ''
      )}
    </div>
  )
}
