import dayjs from 'dayjs'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ActivitiesHttp } from '../../../http/activities-http'
import {
  NotificationHttp,
  NotificationListInterface,
} from '../../../http/notification-http'
import { PublishPostHttp } from '../../../http/publish-post-http'
import { ServiceHttp } from '../../../http/service-http'
import { NOTIFICATION_EVENT } from '../../../types/enum'
import { PublishServiceItemInterface } from '../../../types/types'
import { useAppSelector } from '../../../utils/hooks'
import { notificationTypeTitle } from '../../../utils/titles'
import { IconAdminClose } from '../../svg/IconAdminHeader'
import { IconArrowRight } from '../../svg/IconArrow'
import { IconBottomChevrons } from '../../svg/IconChevrons'
import { DarkBg } from '../../ui/DarkBg'
import { SlickCategories } from '../../ui/SlickCategories'
import DateModal from './DateModal'
import SelectUser from './SelectUser'
import TypeModal from './TypeModal'

export const AdminPanelEvents = () => {
  const [item, setItem] = useState(1)
  const [notificationList, setNotificationList] = useState<NotificationListInterface[]>(
    [],
  )
  const { _id } = useAppSelector((state) => state.user)
  useEffect(() => {
    NotificationHttp.getNotificationList({ userId: _id }).then((s) =>
      setNotificationList(s),
    )
  }, [])
  const handleClick = (it: number) => {
    if (it === item) {
      setItem(0)
      setIsOpen(false)
    } else {
      setItem(it)
      setIsOpen(true)
    }
  }
  const navigate = useNavigate()
  const navigateTo = async (item: NotificationListInterface) => {
    switch (item.event) {
      case NOTIFICATION_EVENT.NOTIFICATION_SERVICE:
        const res: PublishServiceItemInterface[] =
          await ServiceHttp.getUserPublishService({ userId: item.ownerId._id })
        res.find((s) => {
          navigate(`/admin/services/publish-service?publishServiceId=${s._id}`)
        })
        break
      case NOTIFICATION_EVENT.NOTIFICATION_NEWS:
        const posts = await PublishPostHttp.getPostsByUserId({ userId: item.ownerId._id })
        posts.find((s) => {
          navigate(`/admin/comments?postId=${s._id}`)
        })
        break
      case NOTIFICATION_EVENT.NOTIFICATION_ACTIVITIES:
        const events = await ActivitiesHttp.getUserPublishEvent({
          userId: item.ownerId._id,
        })
        events.find((s) => {
          navigate(
            `/admin/${notificationTypeTitle(item.event).link}/publish-activities-item?publishActivitiesId=${s._id}`,
          )
          console.log(
            `/admin/${notificationTypeTitle(item.event).link}/publish-activities-item?publishActivitiesId=${s._id}`,
          )
        })
        break
      default:
        navigate(`/admin/${notificationTypeTitle(item.event).link}/`)
        break
    }
  }
  const filters = ['', 'Date', 'Type', 'User']
  const [type, setType] = useState<NOTIFICATION_EVENT>(
    NOTIFICATION_EVENT.NOTIFICATION_NEWS,
  )
  const [isOpen, setIsOpen] = useState(false)
  const [startDate, setStartDate] = useState<dayjs.Dayjs>(dayjs(new Date()))
  const [user, setUser] = useState<string>()
  return (
    <div className="admin__panel-events">
      <div className="admin__panel-events-title">
        <div className="admin__panel-title"> Latest Events </div>
        <button onClick={() => navigate('/admin/events-all')}>View All</button>
      </div>
      <SlickCategories>
        {[1, 2, 3].map((it) => (
          <div>
            <div
              style={{ width: 'auto' }}
              className="admin__panel-events-filter"
              onClick={() => handleClick(it)}
            >
              {filters[it]}
              {item === it ? <IconAdminClose /> : <IconBottomChevrons />}
            </div>
          </div>
        ))}
      </SlickCategories>
      <div className="admin__panel-events-last">
        {item === 1 &&
          notificationList
            .filter((it) =>
              moment(it.dateNotificationCreated).isSame(startDate.toDate(), 'day'),
            )
            .map((item, index) => (
              <>
                <div
                  className="notification__list-item"
                  onClick={() => navigateTo(item)}
                  style={{ position: 'relative' }}
                >
                  <div className="admin__panel-events-last-date">
                    <div className="notification__list-item-time">
                      {moment(item?.dateNotificationCreated).format('DD MMMM h:mm A')}
                    </div>
                  </div>
                  <div className="notification__list-item-img admin__panel-events-last-img">
                    {notificationTypeTitle(item.event).label()}
                  </div>
                  <div className="notification__list-item-bodyline">
                    <div className="notification__list-item-text admin__panel-events-last-text">
                      <b>
                        {item.ownerId?.fullName
                          ? item.ownerId?.fullName
                          : 'Deleted account'}
                      </b>{' '}
                      <span>{notificationTypeTitle(item.event).text}</span>{' '}
                      <b>{item?.name}</b>
                    </div>
                  </div>
                  <div className="notification__list-item-arrow">
                    <IconArrowRight />
                  </div>
                </div>
              </>
            ))}
        {item === 2 &&
          notificationList
            .filter((it) => it.event === type)
            .map((item, index) => (
              <>
                <div
                  className="notification__list-item"
                  onClick={() => navigateTo(item)}
                  style={{ position: 'relative' }}
                >
                  <div className="admin__panel-events-last-date">
                    <div className="notification__list-item-time">
                      {moment(item?.dateNotificationCreated).format('DD MMMM h:mm A')}
                    </div>
                  </div>
                  <div className="notification__list-item-img admin__panel-events-last-img">
                    {notificationTypeTitle(item.event).label()}
                  </div>
                  <div className="notification__list-item-bodyline">
                    <div className="notification__list-item-text admin__panel-events-last-text">
                      <b>
                        {item.ownerId?.fullName
                          ? item.ownerId?.fullName
                          : 'Deleted account'}
                      </b>{' '}
                      <span>{notificationTypeTitle(item.event).text}</span>{' '}
                      <b>{item?.name}</b>
                    </div>
                  </div>
                  <div className="notification__list-item-arrow">
                    <IconArrowRight />
                  </div>
                </div>
              </>
            ))}
        {item === 3 &&
          user &&
          notificationList
            .filter((it) => it.ownerId?._id === user)
            .map((item, index) => (
              <>
                <div
                  className="notification__list-item"
                  onClick={() => navigateTo(item)}
                  style={{ position: 'relative' }}
                >
                  <div className="admin__panel-events-last-date">
                    <div className="notification__list-item-time">
                      {moment(item?.dateNotificationCreated).format('DD MMMM h:mm A')}
                    </div>
                  </div>
                  <div className="notification__list-item-img admin__panel-events-last-img">
                    {notificationTypeTitle(item.event).label()}
                  </div>
                  <div className="notification__list-item-bodyline">
                    <div className="notification__list-item-text admin__panel-events-last-text">
                      <b>
                        {item.ownerId?.fullName
                          ? item.ownerId?.fullName
                          : 'Deleted account'}
                      </b>{' '}
                      <span>{notificationTypeTitle(item.event).text}</span>{' '}
                      <b>{item?.name}</b>
                    </div>
                  </div>
                  <div className="notification__list-item-arrow">
                    <IconArrowRight />
                  </div>
                </div>
              </>
            ))}
      </div>
      {item === 1 ? (
        <DateModal
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          setStartDate={setStartDate}
          startDate={startDate}
        />
      ) : (
        ''
      )}
      {item === 2 && isOpen ? (
        <TypeModal setIsOpen={setIsOpen} isOpen={isOpen} setType={setType} type={type} />
      ) : (
        ''
      )}
      {item === 3 && isOpen ? (
        <SelectUser setUser={setUser} setIsOpen={setIsOpen} isOpen={isOpen} />
      ) : (
        ''
      )}
      {isOpen && item ? <DarkBg /> : ''}
    </div>
  )
}
