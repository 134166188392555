import { useNavigate } from 'react-router-dom'
//import { ButtonBackRoute } from '../../ui/ButtonBackRoute'
import { useCallback } from 'react'

interface LocationButtonBackProps {
  onButtonClick?: () => void
}

export const LocationButtonBack: React.FC<LocationButtonBackProps> = ({
  onButtonClick,
}) => {
  const navigate = useNavigate()

  const exit = useCallback(() => {
    if (onButtonClick) {
      onButtonClick()
    }
    navigate(-1)
  }, [navigate, onButtonClick])

  const handleClick = (event: React.MouseEvent | React.TouchEvent) => {
    event.preventDefault()
    event.stopPropagation()
    exit()
  }

  return <div className="forget__back">{/*<ButtonBackRoute click={exit} />*/}</div>
}
