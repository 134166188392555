import { TextareaAutosize } from '@mui/material'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { PRIVACY } from '../../../types/enum'
import { indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { PublicationHeader } from '../../general-components/publication/PublishHeader'
import { PublishModalAddFile } from '../../general-components/publication/PublishModalAddFile'
import { IconAddImage } from '../../svg/IconAddImage'
import { DarkBg } from '../../ui/DarkBg'
import $api from '../../../http'
import { IconLeftChevrons } from '../../svg/IconChevrons'
import { UserHeader } from '../header/UserHeader'

const maxLength = 300
export const MessegesGroupEdit = () => {
  const location = useLocation()

  const { chat } = location.state
  const [privacy, setPrivacy] = useState(chat?.privacy || PRIVACY.EVERYONE)
  const [title, setTitle] = useState<string>(chat?.groupName || '')
  const [text, setText] = useState<string>(chat?.groupDescription || '')
  const [isOpen, setIsOpen] = useState(false)
  const [file, setFile] = useState<File | string>(chat?.groupImage || '')
  const navigate = useNavigate()

  const { role } = useAppSelector((state) => state.user)
  const getFile = (f: File) => {
    setFile(f)
  }
  const validate = !Boolean(title && text)

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newText = event.target.value
    if (newText.length <= maxLength) {
      setTitle(newText)
    }
  }
  const handleChangeText = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newText = event.target.value
    if (newText.length <= maxLength) {
      setText(newText)
    }
  }
  const editGroup = async () => {
    if (chat) {
      const formCatData = new FormData()
      let payload: {
        groupName: string
        groupDescription: string
        privacy: string
        chatId: string
      } = {
        groupName: title,
        groupDescription: text,
        privacy: privacy,
        chatId: chat?.chatId,
      }
      formCatData.append('payload', JSON.stringify(payload))

      formCatData.append('file', file)
      $api.post('messenger/edit-group', formCatData).then((res) => {
        if (res.data) {
          navigate(`${indexPathByRole(role)}/messeges`)
        }
      })
    }
  }
  return (
    <div className="group__name">
      <UserHeader>
        <div className="user__header-messages explore__header">
          <button
            onClick={() => navigate(-1)}
            className="user__header-chatuser user__header-messages-exit"
          >
            <IconLeftChevrons />
            <h5 className="user__header-title">
              {privacy === PRIVACY.NEIBS ? 'Group Name' : 'Chat Name'}
            </h5>
          </button>
        </div>
      </UserHeader>
      <div className="group__name-content">
        <div className="group__name-content-addImage">
          <div
            className="group__name-content-addImage-preview"
            onClick={() => {
              setFile('')
              setIsOpen(!isOpen)
            }}
          >
            {!file ? (
              <IconAddImage />
            ) : (
              <img
                src={typeof file === 'string' ? file : URL.createObjectURL(file)}
                alt="group icon"
                style={{ borderRadius: '100%', width: '140px', height: '140px' }}
              />
            )}
          </div>
          <span
            className="group__name-content-addImage-button"
            onClick={() => {
              setFile('')
              setIsOpen(!isOpen)
            }}
          >
            Change
          </span>
        </div>
        <div className="group__name-content-fields">
          <div className="profile__about-body">
            <TextareaAutosize
              value={title}
              onChange={handleChange}
              className="profile__about-autoresize"
              minRows={1}
              placeholder={`Group name`}
            />
          </div>
          <div className="profile__about-body group__name-content-fields-text">
            <TextareaAutosize
              value={text}
              onChange={handleChangeText}
              className="profile__about-autoresize"
              minRows={4}
              placeholder={`Group description`}
            />
          </div>
        </div>
      </div>
      <button
        className={`publish__publish user--footer--button group__name-content-next ${
          validate && 'services__add-button--disabled'
        }`}
        onClick={editGroup}
        disabled={validate}
      >
        Edit
      </button>
      {isOpen && <DarkBg />}
      <PublishModalAddFile isOpen={isOpen} setIsOpen={setIsOpen} getFile={getFile} />
    </div>
  )
}
