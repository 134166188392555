import { AxiosResponse } from 'axios'
import { Feature, GeoJsonObject } from 'geojson'
import L, { LatLng } from 'leaflet'
import 'leaflet-draw/dist/leaflet.draw.css'
import 'leaflet/dist/leaflet.css'
import moment from 'moment'
import { useEffect, useState } from 'react'
import {
  GeoJSON,
  MapContainer,
  Marker,
  Polygon,
  Polyline,
  TileLayer,
} from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-cluster'
import { useLocation, useNavigate } from 'react-router-dom'
import $api from '../../../http'
import { ActivitiesHttp } from '../../../http/activities-http'
import { FeedBackHttp } from '../../../http/feedback-http'
import { PublishPostHttp } from '../../../http/publish-post-http'
import { ServiceHttp } from '../../../http/service-http'
import { PAGES } from '../../../types/enum'
import {
  CoordinatsInterface,
  LocationDistanceType,
  LocationType,
  PostUserInterface,
  PublishEventItemInterface,
  PublishServiceItemInterface,
} from '../../../types/types'
import { apiBaseURL, indexPathByRole } from '../../route/indexPathByRole'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { inside, insideCircle } from '../../../utils/inside'
import { PublicationEventPanel } from '../../general-components/publication-lists/PublicationEventPanel'
import { PublicationPostsPanel } from '../../general-components/publication-lists/PublicationPostsPanel'
import { IconAddMarker } from '../../svg/IconAddMarker'
import { IconArrowRight } from '../../svg/IconArrow'
import { IconBookMark } from '../../svg/IconBookMark'
import { IconLeftChevrons } from '../../svg/IconChevrons'
import { IconStars } from '../../svg/IconFavor'
import { IconLikeMap } from '../../svg/IconLikeMap'
import { IconMessege } from '../../svg/IconMessege'
import { IconNightMode } from '../../svg/IconNightMode'
import { IconNotification } from '../../svg/IconNotification'
import { IconPostsRepost } from '../../svg/IconPosts'
import { IconProfileInfoBookmark } from '../../svg/IconProfileInfo'
import { IconRound } from '../../svg/IconRound'
import { IconSearchMap } from '../../svg/IconSearchMap'
import { IconServicesAllPoint } from '../../svg/IconServicesAll'
import { DarkBg } from '../../ui/DarkBg'
import { DrawModal } from '../../ui/DrawModal'
import { LocationMarker } from '../../ui/LocationMarker'
import { MapUserMarker } from '../../ui/MapUserMarker'
import { Modal } from '../../ui/Modal'
import { PostSlick } from '../../ui/PostSlick'
import { SlickCategories } from '../../ui/SlickCategories'
import { TempNotification } from '../../ui/TempNotification'
import CACities from './CA-regions.json'
import { ExploreHeader } from './ExploreHeader'
import { ExploreModal } from './ExploreModal'
import { ExploreModalSearch } from './ExploreModalSearch'
import FRCities from './FR-regions.json'
import UACities from './UA-regions.json'
import { EventMark } from './EventMark'
import { calculateDistance } from '../../../utils/calculateRate'
import { ExploreFilterModal } from './ExploreFilterModal'
import { getPosts, getTenPosts } from '../../../redux/posts'
import { getTenPublishService } from '../../../redux/categories'
import { getTenPublishActivities } from '../../../redux/activities'
import { PreviewModal } from '../../general-components/preview/PreviewModal'

export const ExploreMap = () => {
  const navigate = useNavigate()
  const { fullName } = useAppSelector((state) => state.user)
  const [Night, setNight] = useState<boolean>(true)
  const location = useLocation()

  const { publishActivities } = useAppSelector((state) => state.activities)
  const { role, avatarFileName } = useAppSelector((state) => state.user)
  const { coordinates, polygonCoords, radiusSize, street, houseNumber, country } =
    useAppSelector((state) => state.profile)
  // const [LocationPostage, setLocationPostage] = useState<LocaTionType>()
  const [LocationTitle, setLocationTitle] = useState<string>('')
  const [LocationFileName, setLocationFileName] = useState<string>('')
  const [MarksList, setMarksList] = useState<LocationType[]>([])
  const [MarksListNormal, setMarksListNormal] = useState<LocationType[]>([])
  const [MarkerMode, setMarkerMode] = useState<boolean>(false)
  const [map, setMap] = useState<L.Map | null>(null)
  const [layer, setLayer] = useState<any | null>(null)

  // modal props
  const [radius, setRadius] = useState<boolean>(false)
  const [radiusValue, setRadiusValue] = useState<number>(1)
  const [draw, setDraw] = useState<boolean>(false)

  const [drawed, setDrawed] = useState<boolean>(false)
  const [mapElement, setmapElement] = useState<Element | null>()

  const [polyline, setPolyline] = useState<L.Polyline | null>()
  const [polygon, setPolygon] = useState<L.Polygon | null>()
  const [paint, setPaint] = useState<boolean>(false)
  const [startPos, setStartPos] = useState<[number, number]>()
  const [drawMode, setDrawMode] = useState<boolean>(false)
  const [polygonCords, setPolygonCords] = useState<
    LatLng[] | LatLng[][] | LatLng[][][] | undefined
  >([])
  const [publishActivitiesNormal, setPublishActivitiesNormal] = useState<
    PublishEventItemInterface[]
  >([])

  const [pageNumber, setPageNumber] = useState(1)
  const [postsNormal, setPostsNormal] = useState<PostUserInterface[]>([])
  const { _id } = useAppSelector((state) => state.user)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [userPoscurrent, setUserPoscurrent] = useState<[number, number]>()
  const [pointlatlngCurrent, setPointlatlngCurrent] = useState<L.LatLng | null>(null)
  const [posCurrent, setPosCurrent] = useState<L.LatLng | null>(null)

  const [publishServiceNormal, setPublishServiceNormal] = useState<
    PublishServiceItemInterface[]
  >([])

  const [postItem, setPostItem] = useState<PostUserInterface | null>(null)
  const [serviceItem, setServiceItem] = useState<PublishServiceItemInterface | null>(null)
  const [eventItem, setEventItem] = useState<PublishEventItemInterface | null>(null)

  const [myPos, setMyPos] = useState<GeolocationPosition>()
  const [focus, setFocus] = useState(false)

  const [cityCoords, setCityCoords] = useState<LatLng[]>()

  const [isOpenMark, setIsOpenMark] = useState(false)
  const [fullScreen, setFullScreen] = useState(true)
  const [markPolygon, setMarkPolygon] = useState<L.Polygon | null>()
  const [markPolygonCoords, setMarkPolygonCoords] = useState<
    LatLng[] | LatLng[][] | LatLng[][][] | null
  >()

  const [isOpenSearch, setIsOpenSearch] = useState(false)
  const [isOpenFilter, setIsOpenFilter] = useState(false)
  const [load, setLoad] = useState<boolean>(false)
  const [filter, setFilter] = useState<string>(PAGES.MAIN)

  const [markItem, setMarkItem] = useState<LocationType | null>()
  const [icon, setIcon] = useState('')

  const dispatch = useAppDispatch()

  const { publishPosts } = useAppSelector((s) => s.posts)
  const { publishServices } = useAppSelector((s) => s.categories)

  useEffect(() => {
    dispatch(getTenPosts())
    dispatch(getTenPublishService())
    dispatch(getTenPublishActivities({ userId: _id }))
  }, [])

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((pos) => {
      setMyPos(pos)
    })
  }, [])

  useEffect(() => {
    if (!isOpenMark) {
      setIcon('')
    }
  }, [isOpenMark])

  useEffect(() => {
    setPublishActivitiesNormal(publishActivities.filter((it) => it.coordinates?.lat))
    setPublishServiceNormal(publishServices.filter((it) => it.coordinates?.lat))
    setPostsNormal(publishPosts.filter((it) => it.coordinates?.lat))
    // marks
    const body = async () => {
      const resMarksList: AxiosResponse<LocationType[]> =
        await $api.post('map/list-marks')
      const marks: LocationType[] = []
      setMarksList(resMarksList.data)
    }
    body()
  }, [publishPosts, publishServices, publishActivities])
  useEffect(() => {
    findMarks(polygonCoords, radiusSize, coordinates)
    findActivities(polygonCoords, radiusSize, coordinates)
    findServices(polygonCoords, radiusSize, coordinates)
    findPosts()
  }, [publishPosts, publishServices, publishActivities])

  const getRegionNearby = () => {
    let arr: LocationDistanceType[] = []
    if (myPos) {
      MarksList.map((item) => {
        arr.push({
          ...item,
          distance: calculateDistance(item.center, {
            lat: myPos?.coords.latitude,
            lng: myPos?.coords.longitude,
          }),
        })
      })
    }
    return arr.filter((it) => it.distance).slice(0, 2)
  }

  const findActivities = (
    coords?: any[] | null,
    radiusSize?: number | null,
    pos?: CoordinatsInterface,
  ) => {
    setPublishActivitiesNormal(publishActivities.filter((it) => it.coordinates?.lat))
  }

  const findServices = (
    coords?: any[] | null,
    radiusSize?: number | null,
    pos?: CoordinatsInterface,
  ) => {
    setPublishServiceNormal(publishServices.filter((it) => it.coordinates?.lat))
  }
  const findMarks = async (
    coords?: any[] | null,
    radiusSize?: number | null,
    pos?: CoordinatsInterface,
  ) => {
    const resMarksList: AxiosResponse<LocationType[]> = await $api.post('map/list-marks')
    setMarksListNormal(resMarksList.data)
  }

  const findPosts = async () => {
    setPostsNormal(publishPosts.filter((it) => it.coordinates?.lat))
  }

  const toPublishActivities = (_id: string) => {
    navigate(`/user/activities/publish-activities-item?publishActivitiesId=${_id}`)
  }

  const toPublishService = (_id: string) => {
    navigate(`/user/service/publish-service?publishServiceId=${_id}`)
  }

  const navigateToComments = (postId: string) => {
    navigate(`${indexPathByRole(role)}/comments?postId=${postId}`)
  }
  const updateLike = async (likeId: string, postId: string) => {
    await FeedBackHttp.updateLike({
      likeId,
      userId: _id,
    })
    // setIsOpen(false)
    // setPosts((s) =>
    //     s.map((p) => {
    //         if (p._id === postId) {
    //             return {
    //                 ...p,
    //                 isLiked: !p.isLiked,
    //                 likes: p.isLiked ? p.likes - 1 : p.likes + 1,
    //             }
    //         } else {
    //             return p
    //         }
    //     })
    // )
    let arr: PostUserInterface[] = []
    postsNormal.map((item) => {
      if (item._id === postId) {
        arr.push({
          ...item,
          isLiked: !item.isLiked,
          likes: item.isLiked ? item.likes - 1 : item.likes + 1,
        })
      } else {
        arr.push(item)
      }
    })
    setPostsNormal(arr)
  }

  if (map && draw && !polygonCords?.length)
    L.DomEvent.on(map?.getContainer(), 'touchstart', (e) => {
      let touch = (e as TouchEvent).targetTouches[0],
        rect = map?.getContainer().getBoundingClientRect(),
        lat = touch.clientX - rect.left - map?.getContainer().clientLeft,
        lng = touch.clientY - rect.top - map?.getContainer().clientTop,
        containerPoint = L.point(lat, lng)

      let p = map?.containerPointToLayerPoint(containerPoint)
      let pointlatlng
      // console.log(pointlatlng)
      if (!paint && draw && !polyline && map && !polygon && !polygonCords?.length) {
        // e.stopPropagation()

        // let point = L.point((e as TouchEvent).targetTouches[0].clientX, (e as TouchEvent).targetTouches[0].clientY);

        if (p) pointlatlng = map?.layerPointToLatLng(p)
        if (pointlatlng) setPosCurrent(pointlatlng)
        // setPolygonCords([]);
      }
    })
  if (!draw && map)
    L.DomEvent.off(map?.getContainer(), 'touchstart', (e) => {
      e.stopPropagation()
    })
  useEffect(() => {
    if (drawed && !polygonCords?.length && map) {
      setPolygonCords(polyline?.getLatLngs())
      polyline?.setLatLngs([])
      polyline?.removeFrom(map)
      setPolyline(undefined)
      setDraw(false)
      map?.dragging.enable()
    }
  }, [drawed])

  if (map && draw && !polygonCords?.length)
    L.DomEvent.on(map?.getContainer(), 'touchend', (e) => {
      if (
        map &&
        polyline?.getLatLngs().length &&
        draw &&
        !polygon &&
        !polygonCords?.length
      ) {
        // setPolygonCords(polyline?.getLatLngs())
        //   polyline?.setLatLngs([])
        //   polyline?.removeFrom(map)
        //   setPolyline(undefined)
        // setDraw(false);
        // map?.dragging.enable()
        setDrawed(true)
        e.stopImmediatePropagation()
        // setDrawMode(true)
      }
    })
  if (drawed && map) L.DomEvent.off(map?.getContainer(), 'touchmove', (e) => {})
  useEffect(() => {
    if (pointlatlngCurrent && draw) {
      polyline?.addLatLng(pointlatlngCurrent)
    }
  }, [pointlatlngCurrent])

  if (map && draw && !polygonCords?.length)
    L.DomEvent.on(map?.getContainer(), 'touchmove', (e) => {
      if (!polygonCords?.length && paint && draw && !polygon && !polygonCords?.length) {
        let touch = (e as TouchEvent).targetTouches[0],
          rect = map?.getContainer().getBoundingClientRect(),
          lat = touch.clientX - rect.left - map?.getContainer().clientLeft,
          lng = touch.clientY - rect.top - map?.getContainer().clientTop,
          containerPoint = L.point(lat, lng)

        let p = map?.containerPointToLayerPoint(containerPoint)
        let pointlatlng

        if (p) pointlatlng = map?.layerPointToLatLng(p)
        if (startPos?.length && pointlatlng && !drawed) {
          if (!polyline?.getLatLngs().length && map) setPolyline(L.polyline([startPos]))
          setPointlatlngCurrent(pointlatlng)
          // polyline?.addLatLng(pointlatlng)
          if (
            (insideCircle([pointlatlng.lat, pointlatlng.lng], 0.01, startPos) ||
              (pointlatlng.lat === startPos[0] && pointlatlng.lng === startPos[1])) &&
            map &&
            polyline?.getLatLngs().length
          ) {
            setPolygonCords(polyline?.getLatLngs())
            polyline?.setLatLngs([])
            polyline?.removeFrom(map)
            setPolyline(undefined)
            setDraw(false)
            map?.dragging.enable()
            setDrawed(true)
            e.stopImmediatePropagation()
          }
        }
      }
    })
  useEffect(() => {
    if (radius && map) {
      setUserPoscurrent([map?.getCenter().lat, map?.getCenter().lng])
    }
  }, [radius])

  map?.on('moveend', () => {
    if (radius) {
      setUserPoscurrent([map?.getCenter().lat, map?.getCenter().lng])
    }
  })
  useEffect(() => {
    setmapElement(document.querySelector('.leaflet-container'))
  }, [])
  polyline?.setStyle({
    weight: 5,
  })
  const updateRepost = async ({
    postId,
    isReposted,
  }: {
    postId: string
    isReposted: boolean
  }) => {
    await PublishPostHttp.updateRepost({
      postId,
      repostedUserId: _id,
      shareList: [],
    })
  }
  const updateMark = async (postId: string, isMarked: boolean) => {
    if (isMarked) {
      await PublishPostHttp.deleteMark({
        postId,
        marckedUserId: _id,
      })
    } else {
      await PublishPostHttp.addMark({
        postId,
        marckedUserId: _id,
      })
      setIsOpen(true)
    }

    setPostsNormal((s) =>
      s.map((p) => {
        if (p._id === postId) {
          const newItem = {
            ...p,
            isMarked: !p.isMarked,
          }
          setPostItem(newItem)
          return newItem
        } else {
          return p
        }
      }),
    )
  }
  // map?.on("click", (e)=>{
  //   map.flyTo(e.latlng, 18)
  // })
  const [city, setCity] = useState<string>('Kyiv')
  useEffect(() => {}, [])
  const onEachCity = (city: Feature, layer: L.Layer) => {
    layer.on({
      click: (e) => {
        if (city.properties) {
          setCity(city.properties.name)
        }
      },
    })
  }
  const onEachCaCity = (city: Feature, layer: L.Layer) => {
    layer.on({
      click: (e) => {
        if (city.properties) {
          setCity(city.properties.prov_name_en[0])
        }

        // e.target.setStyle({
        //   stroke:true,
        //     color: '#fff',
        // })
      },
    })
  }
  const [showMe, setShowMe] = useState(true)

  return (
    <>
      {postsNormal.length && publishActivitiesNormal && publishServiceNormal ? (
        <div className="explore__map">
          <ExploreHeader
            style={
              !Night
                ? {
                    background:
                      'linear-gradient(rgba(245, 246, 247, 1), rgba(245, 246, 247, 0))',
                  }
                : {
                    background:
                      'linear-gradient(rgba(15, 20, 25, 1), rgba(15, 20, 25, 0))',
                  }
            }
          >
            <div className="explore__main-header">
              <div className="explore__main-header-user" style={{ margin: 'auto 0' }}>
                <button onClick={() => navigate(-1)}>
                  {Night ? (
                    <IconLeftChevrons />
                  ) : (
                    <IconLeftChevrons fill="rgba(38, 38, 38, 1)" />
                  )}
                </button>
                {avatarFileName ? (
                  <img src={`${apiBaseURL}/uploads/avatar/${avatarFileName}`} />
                ) : (
                  <div
                    style={{
                      backgroundColor: 'rgba(41, 52, 65, 1)',
                      borderRadius: '100%',
                      width: '40px',
                      height: '40px',
                      color: '#fff',
                      textAlign: 'center',
                      position: 'relative',
                    }}
                  >
                    <span style={{ position: 'absolute', left: '10px', top: '8px' }}>
                      {fullName.slice(0, 2)}
                    </span>
                  </div>
                )}
                {!Night ? (
                  <div className="explore__main-header-user-location">
                    <h6 style={{ color: 'rgba(99, 106, 117, 1)' }}>current location</h6>
                    <h5 style={{ color: 'rgba(31, 32, 36, 1)' }}>
                      {houseNumber ? houseNumber + ',' : ''} {street ? street + ',' : ''}{' '}
                      {country}
                    </h5>
                  </div>
                ) : (
                  <div className="explore__main-header-user-location">
                    <h6>current location</h6>
                    <h5>
                      {houseNumber ? houseNumber + ',' : ''} {street ? street + ',' : ''}{' '}
                      {country}
                    </h5>
                  </div>
                )}
              </div>
              <div
                className={
                  !MarkerMode
                    ? 'admin__panel-addMarker explore__location__follow'
                    : 'admin__panel-addMarker explore__location__follow clicked'
                }
                onClick={() => {
                  setMarkerMode(!MarkerMode)
                  if (myPos)
                    map?.flyTo(
                      { lat: myPos?.coords.latitude, lng: myPos?.coords.longitude },
                      16,
                    )
                  setFocus(true)
                }}
              >
                <IconAddMarker />
              </div>
            </div>
          </ExploreHeader>
          {myPos && (
            <MapContainer
              tap={false}
              zoomControl={false}
              center={
                location.state?.center || {
                  lat: myPos?.coords.latitude,
                  lng: myPos?.coords.longitude,
                }
              }
              zoom={13}
              maxZoom={18}
              minZoom={4}
              scrollWheelZoom={true}
              ref={setMap}
            >
              {!Night ? (
                <>
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                    url="https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png"
                    subdomains="abcd"
                  />
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                    url="https://{s}.basemaps.cartocdn.com/dark_only_labels/{z}/{x}/{y}{r}.png"
                    subdomains="abcd"
                  />
                </>
              ) : (
                <>
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                    url="https://{s}.basemaps.cartocdn.com/dark_nolabels/{z}/{x}/{y}{r}.png"
                  />
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                    url="https://{s}.basemaps.cartocdn.com/dark_only_labels/{z}/{x}/{y}{r}.png"
                    subdomains="abcd"
                  />
                </>
              )}
              {myPos && showMe && (
                <MapUserMarker
                  night={Night}
                  avatarFileName={avatarFileName}
                  fullName={fullName}
                  coords={{ lat: myPos?.coords.latitude, lng: myPos?.coords.longitude }}
                />
              )}
              {
                <MarkerClusterGroup
                  chunkedLoading
                  spiderLegPolylineOptions={{
                    opacity: 0,
                  }}
                >
                  {MarksListNormal.map((item) => (
                    <LocationMarker
                      markerData={item}
                      handler={() => {
                        setIsOpenMark(true)
                        setFullScreen(true)
                        setMarkPolygonCoords(item.polygonCoords)
                        setMarkItem(item)
                      }}
                    />
                  ))}
                </MarkerClusterGroup>
              }

              {isOpenMark && markPolygonCoords && (
                <Polygon positions={markPolygonCoords} />
              )}
              {cityCoords && <Polyline positions={cityCoords} />}
              <GeoJSON
                data={FRCities as GeoJsonObject}
                key={map?.getZoom()}
                style={
                  Night
                    ? {
                        fillOpacity: 0,
                        opacity: 0.1,
                        stroke: true,
                        fillColor: '#fff',
                        color: '#fff',
                      }
                    : {
                        fillOpacity: 0,
                        opacity: 0.1,
                        stroke: true,
                        fillColor: 'blue',
                        color: 'blue',
                      }
                }
                onEachFeature={onEachCity}
              />
              <GeoJSON
                data={CACities as GeoJsonObject}
                key={map?.getZoom()}
                style={
                  Night
                    ? {
                        fillOpacity: 0,
                        opacity: 0.1,
                        stroke: true,
                        fillColor: '#fff',
                        color: '#fff',
                      }
                    : {
                        fillOpacity: 0,
                        opacity: 0.1,
                        stroke: true,
                        fillColor: 'blue',
                        color: 'blue',
                      }
                }
                onEachFeature={onEachCaCity}
              />
              <GeoJSON
                data={UACities as GeoJsonObject}
                key={map?.getZoom()}
                style={
                  Night
                    ? {
                        fillOpacity: 0,
                        opacity: 0.1,
                        stroke: true,
                        fillColor: '#fff',
                        color: '#fff',
                      }
                    : {
                        fillOpacity: 0,
                        opacity: 0.1,
                        stroke: true,
                        fillColor: 'blue',
                        color: 'blue',
                      }
                }
                onEachFeature={onEachCity}
              />

              {
                <MarkerClusterGroup
                  chunkedLoading
                  spiderLegPolylineOptions={{
                    opacity: 0,
                  }}
                >
                  {(icon === 'events' || icon === '') &&
                    publishActivitiesNormal &&
                    publishActivitiesNormal?.map((item) => (
                      <>
                        {item?.coordinates && (
                          <EventMark
                            position={[item?.coordinates?.lat, item?.coordinates?.lng]}
                            title={item.title}
                            img="/Images/eventMark.svg"
                            file={item.filesName[0]}
                            type="publish_activities"
                            handler={() => {
                              toPublishActivities(item._id)
                            }}
                            onclick={() => {
                              setIsOpen(true)
                              setEventItem(item)
                              setPostItem(null)
                              setServiceItem(null)
                            }}
                          />
                        )}
                      </>
                    ))}
                  {(icon === 'posts' || icon === '') &&
                    postsNormal &&
                    postsNormal?.map((item) => (
                      <>
                        {item?.coordinates && (
                          <EventMark
                            position={[item?.coordinates?.lat, item?.coordinates?.lng]}
                            title={item.title}
                            img="/Images/postMark.svg"
                            file={item.filesName[0]}
                            type="publish_post"
                            handler={() => {
                              navigateToComments(item._id)
                            }}
                            onclick={() => {
                              setIsOpen(true)
                              setPostItem(item)
                              setServiceItem(null)
                              setEventItem(null)
                            }}
                          />
                        )}
                      </>
                    ))}
                  {(icon === 'services' || icon === '') &&
                    publishServiceNormal &&
                    publishServiceNormal?.map((item) => (
                      <>
                        {item?.coordinates && (
                          <EventMark
                            position={[item?.coordinates?.lat, item?.coordinates?.lng]}
                            title={item.title}
                            img="/Images/serviceMark.svg"
                            file={item.filesName[0]}
                            type="publish_services"
                            handler={() => {
                              toPublishService(item._id)
                            }}
                            onclick={() => {
                              setIsOpen(true)
                              setServiceItem(item)
                              setEventItem(null)
                              setPostItem(null)
                            }}
                          />
                        )}
                      </>
                    ))}
                </MarkerClusterGroup>
              }
            </MapContainer>
          )}
          {!isOpen && (
            <div className="map__menu" style={{ marginTop: '10%' }}>
              <div
                style={
                  Night
                    ? { backgroundColor: 'rgba(41, 52, 65, 1)' }
                    : { backgroundColor: '#fff' }
                }
                className="map__menu-item"
                onClick={() => {
                  setNight(!Night)
                }}
              >
                <IconNightMode fill={Night ? '#fff' : '#262626'} />
              </div>
              <div
                style={
                  Night
                    ? { backgroundColor: 'rgba(41, 52, 65, 1)' }
                    : { backgroundColor: '#fff' }
                }
                className="map__menu-item"
                onClick={() => setIsOpenSearch(true)}
              >
                <IconSearchMap fill={Night ? '#fff' : '#262626'} />
              </div>
              <div
                style={
                  Night
                    ? { backgroundColor: 'rgba(41, 52, 65, 1)', color: '#fff' }
                    : { backgroundColor: '#fff', color: '#262626' }
                }
                className="map__menu-item"
                onClick={() =>
                  navigate(`${indexPathByRole(role)}/explore/map-chats`, {
                    state: { reg: MarksListNormal, city },
                  })
                }
              >
                <b>
                  <span>
                    <IconMessege
                      fill={Night ? 'rgba(255, 255, 255, 1)' : 'rgba(38, 38, 38, 1)'}
                    />
                  </span>
                </b>
                <div className="blue"></div>
              </div>
              <div
                style={
                  Night
                    ? { backgroundColor: 'rgba(41, 52, 65, 1)' }
                    : { backgroundColor: '#fff' }
                }
                className="map__menu-item"
                onClick={() => navigate(`${indexPathByRole(role)}/explore/map-invites`)}
              >
                <IconLikeMap fill={Night ? '#fff' : '#262626'} />
              </div>
              <div
                style={
                  Night
                    ? { backgroundColor: 'rgba(41, 52, 65, 1)' }
                    : { backgroundColor: '#fff' }
                }
                className="map__menu-item"
                onClick={() => navigate(`${indexPathByRole(role)}/notification`)}
              >
                <IconNotification fill={Night ? '#fff' : '#262626'} />
              </div>
            </div>
          )}
          {drawMode && map && (
            <DrawModal
              map={map}
              setPolyline={setPolyline}
              draw={draw}
              setDraw={setDraw}
              setRadius={setRadius}
              radius={radius}
              setRadiusSize={setRadiusValue}
              radiusSize={radiusValue}
              isOpen={drawMode}
              setIsOpen={setDrawMode}
            />
          )}
          {/* {isOpen &&
            <ExploreModal 
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            postItem={postItem}
            serviceItem={serviceItem}
            eventItem={eventItem}
            updateLike={updateLike}
            updateMark={updateMark}
            updateRepost={updateRepost}
            />
          } */}
          {isOpen && map && eventItem && (
            <ExploreSlider
              type="events"
              setIsOpen={setIsOpen}
              isOpen={isOpen}
              posts={null}
              events={publishActivitiesNormal}
              services={null}
              updateLike={updateLike}
              updateMark={updateMark}
              updateRepost={updateRepost}
              map={map}
            />
          )}
          {isOpen && map && serviceItem && (
            <ExploreSlider
              type="services"
              setIsOpen={setIsOpen}
              isOpen={isOpen}
              posts={null}
              events={null}
              services={publishServiceNormal}
              updateLike={updateLike}
              updateMark={updateMark}
              updateRepost={updateRepost}
              map={map}
            />
          )}
          {isOpen && map && postItem && (
            <ExploreSlider
              type="posts"
              setIsOpen={setIsOpen}
              isOpen={isOpen}
              posts={postsNormal}
              events={null}
              services={null}
              updateLike={updateLike}
              updateMark={updateMark}
              updateRepost={updateRepost}
              map={map}
            />
          )}
          {/* {isOpen && 
          <Slider {...settings}>
            <ExploreModal 
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            postItem={postItem}
            serviceItem={serviceItem}
            eventItem={eventItem}
            updateLike={updateLike}
            updateMark={updateMark}
            updateRepost={updateRepost}
          />
          </Slider>
        } */}
          {isOpenMark && markItem && myPos && (
            <RegionModal
              posts={postsNormal}
              setPosts={setPostsNormal}
              icon={icon}
              setIcon={setIcon}
              item={markItem}
              isOpen={isOpenMark}
              setIsOpen={setIsOpenMark}
              services={publishServiceNormal}
              events={publishActivitiesNormal}
              setFullScreen={setFullScreen}
              fullScreen={fullScreen}
              userPos={myPos}
              setItem={setMarkItem}
              setServices={setPublishServiceNormal}
            />
          )}
          {isOpenSearch && map && (
            <ExploreModalSearch
              setIsOpen={setIsOpenSearch}
              isOpen={isOpenSearch}
              setIsOpenFilter={setIsOpenFilter}
              setLoad={setLoad}
              filter={filter}
              regions={getRegionNearby()}
              map={map}
            />
          )}
          {isOpenFilter && (
            <ExploreFilterModal
              isOpen={isOpenFilter}
              setIsOpen={setIsOpenFilter}
              setFilter={setFilter}
              filter={filter}
              setIsOpenSearch={setIsOpenSearch}
            />
          )}
          {(isOpenFilter || isOpenSearch) && <DarkBg />}
        </div>
      ) : (
        ''
      )}
    </>
  )
}
const RegionModal = ({
  isOpen,
  setIsOpen,
  item,
  setItem,
  icon,
  setIcon,
  services,
  events,
  posts,
  setPosts,
  setFullScreen,
  fullScreen,
  userPos,
  setServices,
}: {
  isOpen: boolean
  setIsOpen: (s: boolean) => void
  item: LocationType
  setItem: (s: LocationType) => void
  icon: string
  setIcon: (s: string) => void
  services: PublishServiceItemInterface[]
  events: PublishEventItemInterface[]
  posts: PostUserInterface[]
  setPosts: (s: PostUserInterface[]) => void
  setFullScreen: (s: boolean) => void
  fullScreen: boolean
  userPos: GeolocationPosition
  setServices: (s: PublishServiceItemInterface[]) => void
}) => {
  const { _id, role } = useAppSelector((state) => state.user)
  const [isSubscriped, setIsSubscriped] = useState<boolean>(false)
  const [isOpenBook, setisOpenBook] = useState(false)
  const [currnetItem, setCurrentItem] = useState<PostUserInterface | null>(null)
  const navigate = useNavigate()
  useEffect(() => {
    item.subscribers.map((userId) => {
      console.log(item.subscribers)
      if (userId === _id) {
        setIsSubscriped(true)
      }
    })
  }, [])

  const toNeibs = () => {
    setIcon('events')
  }
  const toMedia = () => {
    setIcon('services')
  }
  const toAudio = () => {
    setIcon('posts')
  }
  const updateSubscribe = async () => {
    if (!isSubscriped) {
      $api
        .post('map/update-subscribe', {
          subscribers: [...item.subscribers, _id],
          markId: item._id,
          isSubscribed: isSubscriped,
        })
        .then((res) => {
          setIsSubscriped(!isSubscriped)
          let el: LocationType = { ...item, subscribers: [...item.subscribers, _id] }
          setItem(el)
        })
    } else {
      let s = item.subscribers
      let i = s.indexOf(_id)
      s.splice(i)
      $api
        .post('map/update-subscribe', {
          subscribers: s,
          markId: item._id,
          isSubscribed: isSubscriped,
        })
        .then((res) => {
          setIsSubscriped(!isSubscriped)
        })
    }
  }
  const navigateToComments = (postId: string) => {
    navigate(`${indexPathByRole(role)}/comments?postId=${postId}`)
  }
  const updateLike = async (likeId: string, postId: string) => {
    await FeedBackHttp.updateLike({
      likeId,
      userId: _id,
    })
  }
  const updateMark = async (postId: string, isMarked: boolean) => {
    if (isMarked) {
      await PublishPostHttp.deleteMark({
        postId,
        marckedUserId: _id,
      })
    } else {
      await PublishPostHttp.addMark({
        postId,
        marckedUserId: _id,
      })
      setIsOpen(true)
    }
  }
  const updateRepost = async ({
    postId,
    isReposted,
  }: {
    postId: string
    isReposted: boolean
  }) => {
    await PublishPostHttp.updateRepost({
      postId,
      repostedUserId: _id,
      shareList: [],
    })

    // getAllPostReload()
  }
  const validContent = (
    it: PostUserInterface | PublishEventItemInterface | PublishServiceItemInterface,
  ) => {
    return inside([it.coordinates.lat, it.coordinates.lng], item.polygonCoords)
  }
  const countInside = (
    its:
      | PostUserInterface[]
      | PublishEventItemInterface[]
      | PublishServiceItemInterface[],
  ) => {
    let count = 0
    its.map((it) => {
      if (inside([it.coordinates.lat, it.coordinates.lng], item.polygonCoords)) {
        count++
      }
    })
    return count
  }
  const calculateRate = (item: PublishServiceItemInterface) => {
    let rate: number[] = []
    item.reviews.map((review) => {
      rate.push(review.rate)
    })
    return rate.reduce((partialSum, a) => partialSum + a, 0) / rate.length
  }
  const toPublishActivities = (_id: string) => {
    navigate(`/user/activities/publish-activities-item?publishActivitiesId=${_id}`)
  }
  const toPublishService = (_id: string) => {
    navigate(`/user/service/publish-service?publishServiceId=${_id}`)
  }
  const updateLikeEvent = async (likeId: string, serviceId: string) => {
    await FeedBackHttp.updateLike({
      likeId,
      userId: _id,
    })
  }

  const copyLink = (url?: string) => {
    if (url) {
      let text = url
      navigator.clipboard.writeText(text)
    } else {
      let text = window.location.href
      navigator.clipboard.writeText(text)
    }
  }

  const updateMarkEvent = async (eventId: string, isMarked: boolean) => {
    if (isMarked) {
      await ActivitiesHttp.deleteMark({
        eventId,
        marckedUserId: _id,
      })
    } else {
      await ActivitiesHttp.addMark({
        eventId,
        marckedUserId: _id,
      })
      setisOpenBook(true)
    }
  }
  const [isOpenSave, setIsOpenSave] = useState(false)
  const updateMarkService = async (
    serviceId: PublishServiceItemInterface,
    isMarked: boolean,
  ) => {
    if (isMarked) {
      await ServiceHttp.deleteMark({
        serviceId: serviceId._id,
        marckedUserId: _id,
      })
    } else {
      await ServiceHttp.addMark({
        serviceId: serviceId._id,
        marckedUserId: _id,
      })
      setIsOpenSave(true)
    }

    if (services) {
      let arr: PublishServiceItemInterface[] = [...services]
      arr.map((it) => {
        if (it._id === serviceId._id) {
          arr[arr.indexOf(it)] = { ...it, isMarked: !it.isMarked }
        }
      })
      setServices(arr)
    }
  }
  return (
    <>
      {fullScreen && (
        <div
          className={`activities__favor-modal  ${
            isOpen ? 'activities__favor-modal--open' : ''
          }`}
        >
          <Modal className={'explore__regModal'} setIsOpen={setIsOpen}>
            <div
              onClick={(e) => {
                e.stopPropagation()
                setFullScreen(false)
              }}
              className="activities__favor-modal-linebody"
            >
              <button className="activities__favor-modal-line" />
            </div>
            <div className="explore__region">
              <img src={`${apiBaseURL}/uploads/map/${item.file}`} />
              <div>
                <div className="explore__region-text">
                  <h5>{item.title}</h5>
                  <h6>
                    <b>{item.subscribers.length}</b> neibs in this area
                  </h6>
                </div>
                <div className="explore__region-btns">
                  <button
                    className={`messenger__alluser-item-button `}
                    style={{ width: '96px' }}
                    onClick={() => updateSubscribe()}
                  >
                    <div style={{ display: 'flex' }}>
                      {!isSubscriped && <IconNotification fill={'#fff'} />}
                      {!isSubscriped ? (
                        <span style={{ padding: '0 10px' }}>Follow</span>
                      ) : (
                        <span style={{ padding: '0 10px' }}>Followed</span>
                      )}
                    </div>
                  </button>
                  <button
                    className={`messenger__alluser-item-button `}
                    style={{ background: '#fff', width: '96px' }}
                    onClick={() => {}}
                  >
                    <div style={{ display: 'flex' }}>
                      <IconPostsRepost color="rgba(21, 16, 77, 1)" />
                      <span style={{ padding: '0 10px', color: 'rgba(21, 16, 77, 1)' }}>
                        Share
                      </span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <div style={{ margin: '20px' }}>
              {icon === 'posts' && (
                <div className="explore__region-lists" style={{ overflowY: 'scroll' }}>
                  <h5>
                    <b>Posts</b> ({countInside(posts)})
                  </h5>
                  {posts.map((item) => (
                    <>
                      {validContent(item) && (
                        <div
                          style={{
                            margin: '10px 0',
                            backgroundColor: '#fff',
                            padding: '10px',
                          }}
                        >
                          <div
                            className="admin__posts-list-row2"
                            onClick={() => navigateToComments(item._id)}
                          >
                            <PostSlick list={item?.filesName}>
                              {item.filesName.length ? (
                                item?.filesName?.map((it) => (
                                  <div
                                    className="admin__posts-list-row2-img"
                                    onClick={() => navigateToComments(item._id)}
                                  >
                                    <img
                                      src={`${apiBaseURL}/uploads/publish_post/${it}`}
                                      alt=""
                                    />
                                  </div>
                                ))
                              ) : (
                                <div
                                  className="admin__posts-list-row2-img"
                                  onClick={() => navigateToComments(item._id)}
                                >
                                  <img
                                    className="posts__bg"
                                    src={`/Images/postsBG.png`}
                                    alt=""
                                  />
                                </div>
                              )}
                            </PostSlick>
                          </div>
                          <div
                            className="admin__posts-list-row3"
                            onClick={() => navigateToComments(item._id)}
                          >
                            <h5>{item.title}</h5>
                            <h6>{item.text}</h6>
                          </div>
                          <PublicationPostsPanel
                            item={item}
                            updateLike={updateLike}
                            navigateToComments={navigateToComments}
                            updateMark={updateMark}
                            updateRepost={updateRepost}
                            isOpen={isOpenBook}
                            setIsOpen={setisOpenBook}
                          />
                        </div>
                      )}
                    </>
                  ))}
                </div>
              )}
              {icon === 'events' && (
                <div className="explore__region-lists">
                  <h5>
                    <b>Events</b> ({countInside(events)})
                  </h5>
                  {events.map((item) => (
                    <>
                      {validContent(item) && (
                        <>
                          <div
                            className="user__event-item"
                            style={{ backgroundColor: 'rgba(247, 248, 250, 1)' }}
                            onClick={() => toPublishActivities(item._id)}
                          >
                            <div className="user__event-item-row1">
                              <div className="user__event-item-row1-text">
                                <h5>{item.title}</h5>
                                <h6>{item.addressLocation}</h6>
                              </div>
                            </div>
                            <div className="admin__posts-list-row2" onClick={() => {}}>
                              <PostSlick off={true} list={item?.filesName}>
                                {item.filesName.length ? (
                                  item?.filesName?.map((it) => (
                                    <>
                                      <div
                                        className="admin__posts-list-row2-img"
                                        onClick={() => {}}
                                      >
                                        <div className="user__event-item-row2">
                                          <IconServicesAllPoint />
                                        </div>
                                        <div className="user__services-last-item-img-1 user__services-last-item-img-info">
                                          <span
                                            className="admin__posts-list-row1-text"
                                            style={{
                                              color: 'rgba(21, 16, 77, 1)',
                                              padding: '20px',
                                            }}
                                          >
                                            {moment(item?.startDate).format(
                                              'DD/MM/YYYY HH:mm',
                                            )}
                                          </span>{' '}
                                        </div>
                                        <img
                                          src={`${apiBaseURL}/uploads/publish_post/${it}`}
                                          alt=""
                                        />
                                        <div
                                          className="user__services-last-item-img-1 user__services-last-item-img-info"
                                          style={{
                                            width: '100px',
                                            height: '35px',
                                            top: '85%',
                                          }}
                                        >
                                          <div className="user__services-last-item-img-text">
                                            <span
                                              className="admin__posts-list-row1-text"
                                              style={{ color: 'rgba(21, 16, 77, 1)' }}
                                            >
                                              {item.activitiesId.name}
                                            </span>{' '}
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ))
                                ) : (
                                  <div
                                    className="admin__posts-list-row2-img"
                                    onClick={() => toPublishActivities(item._id)}
                                  >
                                    <div className="user__event-item-row2">
                                      <IconServicesAllPoint />
                                    </div>
                                    <div
                                      className="user__services-last-item-img-1 user__services-last-item-img-info"
                                      style={{
                                        width: 'auto',
                                        height: 'auto',
                                        padding: '0 10px',
                                      }}
                                    >
                                      <div className="user__services-last-item-img-text">
                                        <span
                                          className="admin__posts-list-row1-text"
                                          style={{
                                            color: 'rgba(21, 16, 77, 1)',
                                            margin: '10px 0',
                                          }}
                                        >
                                          {moment(item?.startDate).format(
                                            'DD/MM/YYYY HH:mm',
                                          )}
                                        </span>{' '}
                                      </div>
                                    </div>
                                    <img
                                      className="posts__bg"
                                      src={`/Images/postsBG.png`}
                                      alt=""
                                    />
                                    <div
                                      className="user__services-last-item-img-1 user__services-last-item-img-info"
                                      style={{
                                        width: '100px',
                                        height: '35px',
                                        top: '85%',
                                      }}
                                    >
                                      <div className="user__services-last-item-img-text">
                                        <span
                                          className="admin__posts-list-row1-text"
                                          style={{ color: 'rgba(21, 16, 77, 1)' }}
                                        >
                                          {item.activitiesId.name}
                                        </span>{' '}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </PostSlick>
                            </div>
                            <div className="user__event-item-row3">
                              <h6>{item.text}</h6>
                              {/* <div className="user__event-item-row3-button">
                                {item.startDate.toString().split(".")[0].split("T")[0].replaceAll("-", "/") + " " }{ <b>{item.startDate.toString().split(".")[0].split("T")[1].split(":")[0] + ":" + item.startDate.toString().split(".")[0].split("T")[1].split(":")[1]}</b> }
                            </div> */}
                            </div>
                            <div className="user__event-item-row4">
                              <PublicationEventPanel
                                item={item}
                                updateLike={() => {}}
                                navigateToComments={() => {}}
                                updateMark={() => {}}
                                updateRepost={() => {}}
                                isOpen={isOpenBook}
                                setIsOpen={setisOpenBook}
                              />
                              {/* {<button onClick={() => updateLikeEvent(item.likeId, item._id)}>
                            {item.isLiked ? <IconPostsRedLike /> : <IconPostsLike />}

                        </button>}
                        <div onClick={()=>updateMarkEvent(item._id, item.isMarked)} className={item.isMarked ? "user__event-item-row4-bookmark-active" :"user__event-item-row4-bookmark"}>
                            <IconProfileInfoBookmark />
                        </div> */}
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ))}
                </div>
              )}
              {icon === 'services' && (
                <div className="explore__region-lists">
                  <h5>
                    <b>Services</b> ({countInside(services)})
                  </h5>
                  {services.map((item) => (
                    <>
                      {validContent(item) && (
                        <div
                          className="user__services-last-item"
                          onClick={() => toPublishService(item._id)}
                        >
                          <div className="user__services-last-item-img">
                            <div className="user__services-last-item-img-1 user__services-last-item-img-info">
                              <div className="user__services-last-item-img-text">
                                {item.categoryId.name}
                              </div>
                            </div>
                            {item.filesName.length > 0 ? (
                              <img
                                src={`${apiBaseURL}/uploads/publish_services/${item.filesName[0]}`}
                                alt=""
                              />
                            ) : (
                              <img
                                className="posts__bg"
                                src={`/Images/postsBG.png`}
                                alt=""
                              />
                            )}

                            <div className="user__services-last-item-img-2 user__services-last-item-img-info">
                              <div className="user__services-last-item-img-text">
                                {item.reviews.length ? (
                                  <>
                                    <IconStars />
                                    <b>{calculateRate(item)}</b>
                                    <span>{`(${item.reviews.length})`}</span>
                                  </>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="user__services-last-item-info">
                            <div className="user__services-last-item-row1">
                              <h5
                                className="user__services-last-item-title"
                                style={{ margin: '0' }}
                              >
                                {item.title}
                              </h5>
                              <button
                                style={{
                                  backgroundColor: 'transparent',
                                  boxShadow: 'none',
                                  border: '0',
                                  padding: '0',
                                  margin: '0 -10px',
                                }}
                                className={`ui-button-back-route ${
                                  item?.isMarked &&
                                  'admin__posts-list-row4-repost--active'
                                }`}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  updateMarkService(item, item.isMarked)
                                }}
                              >
                                <IconProfileInfoBookmark />
                              </button>
                            </div>
                            <h6 className="user__services-last-item-text">{item.text}</h6>
                            <div className="user__services-last-item-foot">
                              {Math.floor(
                                calculateDistance(
                                  {
                                    lat: userPos.coords.latitude,
                                    lng: userPos.coords.longitude,
                                  },
                                  item.coordinates,
                                ) / 1000,
                              )}{' '}
                              km
                              <IconArrowRight />
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ))}
                </div>
              )}
            </div>
            <div style={{ padding: '30px 0' }} />
            <div
              className="group__info-menu"
              style={!icon ? { top: '70%' } : { top: '80%' }}
            >
              <div
                className={
                  icon === 'events'
                    ? 'group__info-menu-item group__info-menu-item-neibs'
                    : 'group__info-menu-item'
                }
                onClick={toNeibs}
              >
                <IconRound fill={icon === 'events' ? 'white' : '#039162'} />
                Events
              </div>
              <div
                className={
                  icon === 'services'
                    ? 'group__info-menu-item group__info-menu-item-media'
                    : 'group__info-menu-item'
                }
                onClick={toMedia}
              >
                <IconRound fill={icon === 'services' ? 'white' : '#6938D3'} />
                Services
              </div>
              <div
                className={
                  icon === 'posts'
                    ? 'group__info-menu-item group__info-menu-item-audio'
                    : 'group__info-menu-item'
                }
                onClick={toAudio}
              >
                <IconRound fill={icon === 'posts' ? 'white' : '#E8753D'} />
                Posts
              </div>
            </div>
            {isOpenSave && (
              <TempNotification setIsOpen={setIsOpenSave} isOpen={isOpenSave}>
                <IconBookMark />
              </TempNotification>
            )}
          </Modal>
        </div>
      )}
    </>
  )
}
const ExploreSlider = ({
  type,
  isOpen,
  setIsOpen,
  posts,
  events,
  services,
  updateLike,
  updateMark,
  updateRepost,
  map,
}: {
  type: string
  isOpen: boolean
  setIsOpen: (s: boolean) => void
  posts: PostUserInterface[] | null
  events: PublishEventItemInterface[] | null
  services: PublishServiceItemInterface[] | null
  updateLike: (likeId: string, postId: string) => void
  updateMark: (postId: string, isMarked: boolean) => void
  updateRepost: ({ postId, isReposted }: { postId: string; isReposted: boolean }) => void
  map: L.Map
}) => {
  const [it, setIt] = useState<CoordinatsInterface>()
  return (
    <>
      <SlickCategories
        show={1}
        scroll={1}
        mapChange={(n: number) => {
          if (events) {
            // map.flyTo(events[n].coordinates, 18)
            map.panTo(events[n].coordinates, {
              animate: true,
              duration: 1,
              easeLinearity: 0.1,
            })
          }
          if (posts) {
            map.panTo(posts[n].coordinates)
          }
          if (services) {
            map.panTo(services[n].coordinates)
          }
        }}
        className="explore__slider"
      >
        {type === 'events' &&
          events &&
          events.map((item) => (
            <ExploreModal
              setIsOpen={setIsOpen}
              isOpen={isOpen}
              eventItem={item}
              setIt={setIt}
              updateLike={() => {}}
              updateMark={() => {}}
              updateRepost={() => {}}
            />
          ))}
        {type === 'posts' &&
          posts &&
          posts.map((item) => (
            <ExploreModal
              setIsOpen={setIsOpen}
              isOpen={isOpen}
              postItem={item}
              setIt={setIt}
              updateLike={updateLike}
              updateMark={updateMark}
              updateRepost={updateRepost}
            />
          ))}
        {type === 'services' &&
          services &&
          services.map((item) => (
            <ExploreModal
              setIsOpen={setIsOpen}
              isOpen={isOpen}
              serviceItem={item}
              setIt={setIt}
              updateLike={() => {}}
              updateMark={() => {}}
              updateRepost={() => {}}
            />
          ))}
      </SlickCategories>
    </>
  )
}
