import { useState } from 'react'
import { Form, Input, Button, Checkbox, notification, Typography, Row, Flex } from 'antd'
import { attemptRegistration } from '../../../redux/auth'
import { addListener } from '@reduxjs/toolkit'
import { useAppDispatch } from '../../../utils/hooks'
import { AUTH_AGREE_TYPES } from '../../../types/enum'
import { AuthModalAgree } from './AuthModalAgree'
import { DarkBg } from '../../ui/DarkBg'
import { useAttemptListener } from 'dev-masters-react-kit'
import { useTranslation } from 'react-i18next'
import { RegistrationDTO } from '../../../types/server-communication/auth'
import { useStrongPassword } from 'dev-masters-react-kit'
import { AuthLayout } from './AuthLayout'
import { store } from '../../../store'

const { Link } = Typography
interface RegistrationForm extends RegistrationDTO {
  termsAgreement: boolean
}
const SignUp = () => {
  const dispatch = useAppDispatch()
  const [isOpenTerms, setIsOpenTerms] = useState(false)
  const [isOpenPolicy, setIsOpenPolicy] = useState(false)
  const [form] = Form.useForm<RegistrationForm>()

  const { t } = useTranslation('auth')

  useAttemptListener({
    store,
    attempt: attemptRegistration,
    onRejected: (action) => {
      notification.error({ message: t(action.error.message) })
    },
  })

  const onFinish = (values: RegistrationForm) => {
    //TODO: check if we need to send termsAgreement to server, for the moment it is omitted
    dispatch(attemptRegistration(values))
  }

  const isTermsAgreed = Form.useWatch('termsAgreement', form)

  const { verifyProvidedPassword } = useStrongPassword()

  return (
    <AuthLayout isLogin={false}>
      <Form form={form} onFinish={onFinish} className="registration" requiredMark={false}>
        <Form.Item
          name="email"
          rules={[
            { required: true, message: 'Please input your email!' },
            { type: 'email', message: 'Invalid email, example: user@gmail.com!' },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>

        <Form.Item
          name="fullName"
          rules={[{ required: true, message: 'Please input your full name!' }]}
        >
          <Input placeholder="Full Name" />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            { required: true, message: 'Please input your password!' },
            {
              validator(_, value) {
                const notPassed = verifyProvidedPassword(value)
                if (notPassed) return Promise.reject(new Error(notPassed))
                return Promise.resolve()
              },
            },
          ]}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>

        <Form.Item
          name="confirmPassword"
          dependencies={['password']}
          rules={[
            { required: true, message: 'Please confirm your password!' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error(t('The passwords are different')))
              },
            }),
          ]}
        >
          <Input.Password placeholder="Confirm Password" />
        </Form.Item>

        <Flex align="center">
          <Form.Item
            name="termsAgreement"
            valuePropName="checked"
            rules={[{ required: true }]}
            style={{ marginBottom: 0 }}
          >
            <Checkbox style={{ marginRight: '0.5em' }} />
          </Form.Item>

          <span>
            By Signing up, you agree to the
            <Link onClick={() => setIsOpenTerms(true)}> Terms of Service </Link>
            and
            <Link onClick={() => setIsOpenPolicy(true)}> Privacy Policy</Link>
          </span>
        </Flex>

        <Button
          type="primary"
          // className="login__button"
          htmlType="submit"
          disabled={!isTermsAgreed}
          block
        >
          Sign Up
        </Button>
      </Form>
      <AuthModalAgree
        isOpen={isOpenTerms}
        setIsOpen={setIsOpenTerms}
        typeAgree={AUTH_AGREE_TYPES.TERMS}
      />
      <AuthModalAgree
        isOpen={isOpenPolicy}
        setIsOpen={setIsOpenPolicy}
        typeAgree={AUTH_AGREE_TYPES.POLICY}
      />
      {(isOpenPolicy || isOpenTerms) && <DarkBg />}
    </AuthLayout>
  )
}

export default SignUp
