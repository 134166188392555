import { IconCopy } from '../../svg/IconCopy'
import { IconFroward } from '../../svg/IconForward'
import { Modal } from '../../ui/Modal'

export const PreviewModal = ({
  isOpen,
  setIsOpen,
  apply,
  copy,
}: {
  isOpen: boolean
  setIsOpen: (s: boolean) => void
  apply: () => Promise<void>
  copy: () => void
}) => {
  return (
    <>
      <div
        className={`activities__favor-modal  ${
          isOpen ? 'activities__favor-modal--open' : ''
        }`}
      >
        <Modal className="" setIsOpen={(s: boolean) => setIsOpen(s)}>
          <div
            onClick={(e) => {
              e.stopPropagation()
              setIsOpen(false)
            }}
            className="activities__favor-modal-linebody"
          >
            <button className="activities__favor-modal-line" />
          </div>
          <div
            className="sendmenu"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              margin: 'auto',
            }}
          >
            <div
              className="sendmenu-item"
              onClick={(e) => {
                e.stopPropagation()
                apply()
                setIsOpen(false)
              }}
            >
              <span className="sendmenu-item-text">Share</span>
              <div>
                <IconFroward />
              </div>
            </div>
            <div
              className="sendmenu-item"
              onClick={(e) => {
                e.stopPropagation()
                copy()
                setIsOpen(false)
              }}
            >
              <span className="sendmenu-item-text">Copy</span>
              <div>
                <IconCopy />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  )
}
