export const IconPrise = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.45459 9.8553V2.24436C0.45459 1.25589 1.25589 0.45459 2.24436 0.45459H9.8553C10.33 0.454592 10.7852 0.643159 11.1209 0.978807L19.0213 8.87924C19.7202 9.57818 19.7202 10.7114 19.0213 11.4104L11.4104 19.0213C10.7114 19.7202 9.57818 19.7202 8.87924 19.0213L0.978807 11.1209C0.643159 10.7852 0.454592 10.33 0.45459 9.8553ZM4.63073 2.84095C3.64225 2.84095 2.84095 3.64225 2.84095 4.63073C2.84095 5.6192 3.64225 6.4205 4.63073 6.4205C5.6192 6.4205 6.4205 5.6192 6.4205 4.63073C6.4205 3.64225 5.6192 2.84095 4.63073 2.84095Z"
        fill="#C3CAD9"
      />
    </svg>
  )
}
