export const IconProfileTextareaCorner = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9888 15.9989H13.5098C13.2453 15.9986 12.9915 16.1036 12.8045 16.2906C12.6174 16.4777 12.5125 16.7314 12.5128 16.996C12.5128 17.5475 12.9582 17.993 13.5098 17.993L17.9999 18.0001C18.5515 18.0001 18.9969 17.5546 18.9969 17.003L18.9969 12.52C18.9969 11.9684 18.5515 11.523 17.9999 11.523C17.7354 11.5227 17.4816 11.6276 17.2946 11.8146C17.1075 12.0017 17.0026 12.2555 17.0029 12.52L16.9888 15.9989Z"
        fill="#C3CAD9"
      />
    </svg>
  )
}

export const IconProfileCircle = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 17.6C15.0928 17.6 17.6 15.0928 17.6 12C17.6 8.90723 15.0928 6.40002 12 6.40002C8.90723 6.40002 6.40002 8.90723 6.40002 12C6.40002 15.0928 8.90723 17.6 12 17.6ZM12 8.80002C10.2327 8.80002 8.80002 10.2327 8.80002 12C8.80002 13.7673 10.2327 15.2 12 15.2C13.7673 15.2 15.2 13.7673 15.2 12C15.2 10.2327 13.7673 8.80002 12 8.80002Z"
        fill="#C3CAD9"
      />
    </svg>
  )
}
export const IconProfileCircleFilled = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 17.6C15.0928 17.6 17.6 15.0928 17.6 12C17.6 8.90723 15.0928 6.40002 12 6.40002C8.90723 6.40002 6.40002 8.90723 6.40002 12C6.40002 15.0928 8.90723 17.6 12 17.6ZM12 8.80002C10.2327 8.80002 8.80002 10.2327 8.80002 12C8.80002 13.7673 10.2327 15.2 12 15.2C13.7673 15.2 15.2 13.7673 15.2 12C15.2 10.2327 13.7673 8.80002 12 8.80002Z"
        fill="#000"
      />
    </svg>
  )
}
