export const IconVoice = (props: { className?: string; fill?: string }) => {
  return (
    <svg
      fill={props.fill ? props.fill : '#000000'}
      height="40px"
      width="40px"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 297 297"
      xmlSpace="preserve"
      className={props.className}
    >
      <path
        d="M148.5,0C66.486,0,0,66.486,0,148.5S66.486,297,148.5,297S297,230.514,297,148.5S230.514,0,148.5,0z M202.79,161.734
      l-78.501,45.322c-2.421,1.398-5.326,2.138-8.083,2.138c-8.752,0-16.039-7.12-16.039-15.872v-90.645
      c0-8.752,7.287-15.872,16.039-15.872c2.758,0,5.579,0.739,8.001,2.138l78.542,45.322c4.966,2.867,7.951,8.001,7.951,13.734
      S207.756,158.867,202.79,161.734z"
      />
    </svg>
  )
}
