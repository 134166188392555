export const IconsChat = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66699 16.52C2.66699 9.49617 8.28033 3.16667 16.027 3.16667C23.6003 3.16667 29.3337 9.37599 29.3337 16.48C29.3337 24.719 22.6137 29.8333 16.0003 29.8333C13.8137 29.8333 11.387 29.2458 9.44033 28.0974C8.76033 27.6834 8.18699 27.3763 7.45366 27.6167L4.76033 28.4179C4.08033 28.6315 3.46699 28.0974 3.66699 27.3763L4.56033 24.3852C4.70699 23.9712 4.68033 23.5306 4.46699 23.1834C3.32033 21.0735 2.66699 18.7634 2.66699 16.52ZM14.267 16.52C14.267 17.4681 15.027 18.2293 15.9737 18.2426C16.9203 18.2426 17.6803 17.4681 17.6803 16.5334C17.6803 15.5853 16.9203 14.8242 15.9737 14.8242C15.0403 14.8108 14.267 15.5853 14.267 16.52ZM20.4137 16.5334C20.4137 17.4681 21.1737 18.2426 22.1203 18.2426C23.067 18.2426 23.827 17.4681 23.827 16.5334C23.827 15.5853 23.067 14.8242 22.1203 14.8242C21.1737 14.8242 20.4137 15.5853 20.4137 16.5334ZM9.82699 18.2426C8.89366 18.2426 8.12033 17.4681 8.12033 16.5334C8.12033 15.5853 8.88033 14.8242 9.82699 14.8242C10.7737 14.8242 11.5337 15.5853 11.5337 16.5334C11.5337 17.4681 10.7737 18.2293 9.82699 18.2426Z"
        fill="#C3CAD9"
      />
    </svg>
  )
}

export const IconsMessage = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5857 4.5C24.3737 4.5 26.0937 5.20667 27.359 6.47467C28.6257 7.74 29.3337 9.44667 29.3337 11.2333V21.7667C29.3337 25.4867 26.307 28.5 22.5857 28.5H9.41366C5.69233 28.5 2.66699 25.4867 2.66699 21.7667V11.2333C2.66699 7.51333 5.67899 4.5 9.41366 4.5H22.5857ZM24.707 13.22L24.8137 13.1133C25.1323 12.7267 25.1323 12.1667 24.799 11.78C24.6137 11.5813 24.359 11.46 24.0937 11.4333C23.8137 11.4187 23.547 11.5133 23.3457 11.7L17.3337 16.5C16.5603 17.1413 15.4523 17.1413 14.667 16.5L8.66699 11.7C8.25233 11.3933 7.67899 11.4333 7.33366 11.7933C6.97366 12.1533 6.93366 12.7267 7.23899 13.1267L7.41366 13.3L13.4803 18.0333C14.227 18.62 15.1323 18.94 16.0803 18.94C17.0257 18.94 17.947 18.62 18.6923 18.0333L24.707 13.22Z"
        fill="#C3CAD9"
      />
    </svg>
  )
}
