import { useEffect, useState } from 'react'
import {
  IconAdminClose,
  IconAdminBurger,
  IconAdminImage,
} from '../../svg/IconAdminHeader'
import MenuHeader from './MenuHeader'
import { headerTitle } from '../../../utils/titles'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { useAppSelector } from '../../../utils/hooks'
import { apiBaseURL, uploadedBaseURL } from '../../route/indexPathByRole'
const AdminHeader = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [title, setTitle] = useState(headerTitle(''))
  const location = useLocation()
  const { avatarFileName } = useAppSelector((state) => state.user)

  useEffect(() => {
    if (isOpen) {
      setTitle('Menu')
    } else {
      const localRoute = location.pathname
      setTitle(headerTitle(localRoute))
    }
  }, [isOpen, location])

  return (
    <div
      className="admin__header"
      style={
        location.pathname === '/admin/advertisement/prise-map' ? { display: 'none' } : {}
      }
    >
      <button onClick={() => setIsOpen((s) => !s)} className="admin__header-button">
        {isOpen ? <IconAdminClose /> : <IconAdminBurger />}
      </button>
      <h4 className="admin__header-title">{title}</h4>
      <Link to="/profile">
        <button className="admin__header-button">
          {avatarFileName ? (
            <img src={`${uploadedBaseURL}/avatar/${avatarFileName}`} alt="" />
          ) : (
            <IconAdminImage />
          )}
        </button>
      </Link>
      <MenuHeader isOpen={isOpen} setIsOpen={(o: boolean) => setIsOpen(o)} />
    </div>
  )
}

export default AdminHeader
