import { ReactElement } from 'react'
import { Navigate } from 'react-router-dom'
import { ROLE } from '../../types/enum'
import { useAppSelector } from '../../utils/hooks'
import { indexPathByRole } from './indexPathByRole'

interface Props {
  roles?: ROLE[]
  granted: ReactElement
}

function Role({
  roles = [
    ROLE.USER,
    ROLE.COORDINATORS,
    ROLE.LOCAL_ADMIN,
    ROLE.REGIONAL_ADMIN,
    ROLE.ADMIN,
  ],
  granted,
}: Props) {
  const { role } = useAppSelector((state) => state.userNew)
  if (!role) {
    return <Navigate to={'/auth'} />
  }

  if (Object.values(roles).includes(role)) {
    return granted
  }

  return <Navigate to={indexPathByRole(role)} />
}

function Anon({ granted }: Props) {
  const { role } = useAppSelector((state) => state.userNew)
  if (!role) {
    //TODO: fix, it will not work as initial state is undefined
    return granted
  }
  return <Navigate to={indexPathByRole(role)} />
}

const Require = {
  Role,
  Anon,
}

export default Require
