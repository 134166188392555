import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { IconProfileInfoNotification } from '../../svg/IconProfileInfo'
import { IconsNewsfeedMessenger, IconsNewsfeedPlus } from '../../svg/IconsNewsfeed'
import { UserHeader } from './UserHeader'
import { IconAddNeib } from '../../svg/IconAddNeib'
import { indexPathByRole } from '../../route/indexPathByRole'

export const UserHeaderMain = (props: {
  isOpenPublish: boolean
  setIsOpenPublish: (s: boolean) => void
}) => {
  const navigate = useNavigate()
  const { role } = useAppSelector((s) => s.user)
  const { chats } = useAppSelector((s) => s.chats)

  const messenger = () => {
    navigate('/user/messeges')
  }
  const publish = () => {
    props.setIsOpenPublish(true)
  }

  const notification = () => {
    navigate('/user/notification')
  }
  const toAllUserChatList = () => {
    navigate(`${indexPathByRole(role)}/messeges/all-user-chat`)
  }

  return (
    <UserHeader>
      <div className="user__header-main">
        <h5 className="user__header-title" onClick={() => navigate(`/user`)}>
          NeighborHarbor
        </h5>
        <div className="user__header-main-buttons">
          <button className="user__header-main-button" onClick={toAllUserChatList}>
            <IconAddNeib />
          </button>
          <button className="user__header-main-button" onClick={notification}>
            {/* <div className="user__header-main-button-new" /> */}
            <IconProfileInfoNotification />
          </button>
          <button className="user__header-main-button" onClick={messenger}>
            {chats.find((it) => it.notReadingMessage.length) ? (
              <div className="user__header-main-button-new" />
            ) : (
              ''
            )}
            <IconsNewsfeedMessenger />
          </button>
          <button className="user__header-main-button" onClick={publish}>
            <IconsNewsfeedPlus />
          </button>
        </div>
      </div>
    </UserHeader>
  )
}
