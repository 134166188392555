import React, { useEffect, useState } from 'react'
import { Flex, Input, InputNumber, Row, Select } from 'antd'
import {
  parsePhoneNumber,
  getCountries,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from 'react-phone-number-input'
import { CountryCode } from 'libphonenumber-js'

import { useTranslation } from 'react-i18next'

type PhoneNumberFormItemProps = {
  value?: any
  onChange?: (value: any) => void
}

export const PhoneNumberInput: React.FC<PhoneNumberFormItemProps> = ({
  value,
  onChange,
}) => {
  const { t } = useTranslation()
  const [countryCode, setCountryCode] = useState<CountryCode>()

  useEffect(() => {
    if (!value) return
    setCountryCode(parsePhoneNumber(value)?.country)
  }, [value])

  const countryOptions: { value: string; label: React.ReactElement }[] =
    getCountries().map((code) => {
      return {
        value: code,
        label: (
          <span className="phone-icon-container">
            {' '}
            <img src={`/Images/flags/${code}.svg`} className="country-flag-icon" /> {code}
          </span>
        ),
      }
    })

  function numberInputChanged(phone: string) {
    let parsed = parsePhoneNumber(phone, countryCode)

    if (typeof onChange === 'function') {
      onChange(parsed ? formatPhoneNumberIntl(parsed.number) : phone)
    }
  }

  function selectCountry(code: CountryCode) {
    setCountryCode(code)
    let parsed = parsePhoneNumber(value, code)
    if (typeof onChange === 'function')
      onChange(parsed && formatPhoneNumberIntl(parsed.number))
  }

  return (
    <Input
      style={{ width: '100%' }}
      placeholder={t('Phone number')}
      onChange={(e) => numberInputChanged(e.target.value)}
      value={value}
      addonBefore={
        <Select
          style={{ width: '5.5em' }}
          showSearch
          options={countryOptions}
          onSelect={selectCountry}
          value={countryCode}
          placeholder={
            <img
              src={'/Images/flags/unknown.svg'}
              className="unknown-country-flag-icon"
            />
          }
        />
      }
    />
  )
}

export default PhoneNumberInput
