import { useNavigate } from 'react-router-dom'
import { attemptUpdateSkills } from '../../../../redux/userNew'
import { PROFILE_STEP } from '../../../../types/server-communication/user'
import { SKILLS_LIMIT } from '../../../../utils/appConstants'
import { useAppSelector } from '../../../../utils/hooks'
import skillsJson from '../../../../utils/localization/locales/datasets/skills.en.json'
import {
  ProfileSearchSelect,
  ProfileSearchSelectFormValues,
} from '../layout/ProfileSearchSelect'
import { useAttemptListener } from 'dev-masters-react-kit'
import { stepDefinitions } from '../../../../utils/hooks/useNextSetUpProfilePath'
import { Form } from 'antd'
import { StandaloneScreenLayout } from '../../../ui/Layout/StandaloneScreenLayout'
import { store } from '../../../../store'

export const ProfileSkills = () => {
  const { skills } = useAppSelector((state) => state.userNew)
  const navigate = useNavigate()
  const isLoading = useAttemptListener({
    store,
    attempt: attemptUpdateSkills,
    onFulfilled: () => {
      navigate('/profile/' + stepDefinitions[PROFILE_STEP.INTERESTS].path)
    },
  })
  const [form] = Form.useForm<ProfileSearchSelectFormValues<'skills'>>()
  const currentSelection = Form.useWatch('skills', form)

  return (
    <StandaloneScreenLayout
      title="Skills"
      subTitle="Highlight your expertise: Choose & showcase your skills."
      onPrimaryButtonClicked={form.submit}
      isDisabled={isLoading || !currentSelection || currentSelection?.length === 0}
    >
      <ProfileSearchSelect
        translationNamespace="skillsList"
        formFieldName="skills"
        form={form}
        optionList={skillsJson}
        attemptUpdateAction={attemptUpdateSkills}
        placeholderLabel="Skills"
        initialValues={skills}
        maxCount={SKILLS_LIMIT}
      />
    </StandaloneScreenLayout>
  )
}
