import { AxiosResponse } from 'axios'
import $api from '.'
import { UserIdentityInterface } from '../redux/profile'

export class IdentityHttp {
  static async getUserIdentity(payload: {
    _id: string
    options?: string[]
  }): Promise<UserIdentityInterface> {
    const res: AxiosResponse<UserIdentityInterface> = await $api.post(
      'identity/get-user-identity',
      payload,
    )
    return { ...res.data, userIdentityId: res.data._id }
  }

  static async updateUserOnlineDate(payload: { email: string }) {
    const res = await $api.post('identity/update-online-date', payload)
    return res.data
  }
}
