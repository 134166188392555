import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { PostUserInterface } from '../../../types/types'
import { indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconBookMark } from '../../svg/IconBookMark'
import { IconComment } from '../../svg/IconFavor'
import { IconPostsLike, IconPostsRedLike, IconPostsRepost } from '../../svg/IconPosts'
import { IconProfileInfoBookmark } from '../../svg/IconProfileInfo'
import { TempNotification } from '../../ui/TempNotification'

export const PublicationPostsPanel = ({
  item,
  updateLike,
  navigateToComments,
  updateMark,
  updateRepost,
  setIsOpen,
  isOpen,
  active,
  down,
}: {
  item: PostUserInterface
  updateLike: (likeId: string, postId: string) => void
  navigateToComments: (postId: string) => void
  updateMark: (postId: string, isMarked: boolean, item?: PostUserInterface) => void
  updateRepost: ({ postId, isReposted }: { postId: string; isReposted: boolean }) => void
  setIsOpen?: ((o: boolean) => void) | any
  isOpen?: boolean
  active?: boolean
  down?: boolean
}) => {
  const { _id, role } = useAppSelector((state) => state.user)
  const navigate = useNavigate()
  useEffect(() => {
    console.log(item)
  }, [])

  return (
    <div className="admin__posts-list-row4">
      {
        <button
          onMouseDown={(e) => {
            if (down) {
              console.log(down)
              e.stopPropagation()
              updateLike(item.likeId, item._id)
            }
          }}
          onClick={() => {
            if (!down) {
              updateLike(item.likeId, item._id)
            }
          }}
        >
          {item.isLiked ? <IconPostsRedLike /> : <IconPostsLike />}

          <span>{item?.likes || item.countLikes}</span>
        </button>
      }
      <button
        onMouseDown={(e) => {
          if (down) {
            e.stopPropagation()
            navigateToComments(item?._id)
          }
        }}
        onClick={() => {
          if (!down) {
            navigateToComments(item?._id)
          }
        }}
      >
        <IconComment />
        <span>{item?.countComments}</span>
      </button>
      <button
        onMouseDown={(e) => {
          if (down) {
            e.stopPropagation()
            if (item?.isReposted) {
              updateRepost({
                postId: item?._id,
                isReposted: item.isReposted,
              })
            } else {
              navigate(`${indexPathByRole(role)}/share`, {
                state: { postId: item?._id, isReposted: item.isReposted },
              })
            }
          }
        }}
        onClick={() => {
          if (!down) {
            if (item?.isReposted) {
              updateRepost({
                postId: item?._id,
                isReposted: item.isReposted,
              })
            } else {
              navigate(`${indexPathByRole(role)}/share`, {
                state: { postId: item?._id, isReposted: item.isReposted },
              })
            }
          }
        }}
        className={`${
          item.repostedUserId?._id === _id && 'admin__posts-list-row4-repost--active'
        }`}
      >
        <IconPostsRepost />
        <span>{item.countReposts}</span>
      </button>
      <div
        onMouseDown={(e) => {
          if (down) {
            e.stopPropagation()
            if (active) {
              updateMark(item._id, active, item)
            } else {
              updateMark(item._id, item.isMarked, item)
            }
          }
        }}
        onClick={() => {
          if (!down) {
            if (active) {
              updateMark(item._id, active, item)
            } else {
              updateMark(item._id, item.isMarked, item)
            }
          }
        }}
        className={`${
          (item.isMarked || active) && 'admin__posts-list-row4-repost--active'
        }`}
      >
        <IconProfileInfoBookmark />
      </div>
      {isOpen && (
        <TempNotification setIsOpen={setIsOpen} isOpen={isOpen}>
          <IconBookMark />
        </TempNotification>
      )}
    </div>
  )
}
