import { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import $api from '../../../http'
import { ActivitiesHttp } from '../../../http/activities-http'
import { UserHttp } from '../../../http/user-http'
import { UserInitialStateInterface } from '../../../redux/users'
import { ROLE } from '../../../types/enum'
import { ChatType, MessageType, PublishEventItemInterface } from '../../../types/types'
import { useAppSelector } from '../../../utils/hooks'
import { Loader } from '../../ui/Loader'
import { AdminPanelEvents } from './AdminPanelEvents'
import { AdminPanelMap } from './AdminPanelMap'
import { AdminPanelShortcuts } from './AdminPanelShortcuts'
import { AdminPanelStatistic } from './AdminPanelStatistic'
import { inside } from '../../../utils/inside'

export const AdminPanel = () => {
  const location = useLocation()
  const { role, polygonCoords, fullName, _id } = useAppSelector((state) => state.user)
  const { publishActivities } = useAppSelector((state) => state.activities)
  const { publishServices } = useAppSelector((state) => state.categories)
  const { publishPosts } = useAppSelector((state) => state.posts)
  const [load, setLoad] = useState(true)
  const dispatch = useDispatch()
  const [users, setUsers] = useState<UserInitialStateInterface[]>([])
  const [admins, setAdmins] = useState<UserInitialStateInterface[]>([])
  const [count, setCount] = useState({
    countServices: 0,
    countActivities: 0,
    countUsers: 0,
    countMessages: 0,
  })
  const [chats, setChats] = useState<ChatType[]>()

  // local admin
  useEffect(() => {
    const effect = async () => {
      if (role === ROLE.LOCAL_ADMIN && polygonCoords) {
        setLoad(true)
        //users
        let usersList: UserInitialStateInterface[] = []
        let u = await UserHttp.getAllUsers()
        u.filter((it) => {
          if (it.coords && inside([it.coords.lat, it.coords.lng], polygonCoords)) {
            usersList.push(it)
          }
        })
        //messages
        let c: ChatType[] = []
        let messages: MessageType[] = []
        $api
          .post('messenger/list-chat', { _id, isSupport: false })
          .then(async (r: AxiosResponse<ChatType[]>) => {
            const list = r.data.map((item) => ({
              ...item,
              participants: item.participants.filter((p) => p.userId._id !== _id),
            }))
            list.map(async (it) => {
              if (inside([it.coordinates.lat, it.coordinates.lng], polygonCoords)) {
                c.push(it)
              }
            })
            setChats(c)
            for (let i = 0; i < c.length; i++) {
              await $api
                .post('messenger/list-message', {
                  chatId: c[i].chatId,
                })
                .then((res) => {
                  messages.push(res.data)
                })
              console.log(messages)
            }
          })
        setUsers(usersList)
        setCount({
          countActivities: publishActivities.length,
          countServices: publishServices.length,
          countUsers: usersList.length,
          countMessages: messages.length,
        })
        setLoad(false)
      }
    }
    effect()
  }, [])

  useEffect(() => {
    const effect = async () => {
      if (role === ROLE.REGIONAL_ADMIN) {
        let localAdmins: UserInitialStateInterface[] = []
        ;(await UserHttp.getAllUsers()).filter(async (item) => {
          setLoad(true)
          if (item?.regionAdmin?.fullName === fullName) {
            localAdmins.push(item)
            //events
            let eventsList: PublishEventItemInterface[] = []
            let e = await ActivitiesHttp.getTenPublishActivities({ userId: _id })
            e.filter((i) => {
              if (
                item.polygonCoords &&
                inside([i.coordinates.lat, i.coordinates.lng], item.polygonCoords)
              ) {
                eventsList.push(i)
              }
            })
            //users
            let usersList: UserInitialStateInterface[] = []
            let u = await UserHttp.getAllUsers()
            u.filter((it) => {
              if (
                item.polygonCoords &&
                it.coords &&
                inside([it.coords.lat, it.coords.lng], item.polygonCoords)
              ) {
                usersList.push(it)
              }
            })
            //messages
            let c: ChatType[] = []
            let messages: MessageType[] = []
            $api
              .post('messenger/list-chat', { _id, isSupport: false })
              .then(async (r: AxiosResponse<ChatType[]>) => {
                const list = r.data.map((it) => ({
                  ...it,
                  participants: it.participants.filter((p) => p.userId._id !== _id),
                }))
                list.map(async (it) => {
                  if (
                    it.coordinates &&
                    item.polygonCoords &&
                    inside([it.coordinates.lat, it.coordinates.lng], item.polygonCoords)
                  ) {
                    c.push(it)
                  }
                })
                setChats(c)
                for (let i = 0; i < c.length; i++) {
                  await $api
                    .post('messenger/list-message', {
                      chatId: c[i].chatId,
                    })
                    .then((res) => {
                      messages.push(res.data)
                    })
                }
              })
            setAdmins(localAdmins)
            setUsers(usersList)
            setCount({
              countActivities: eventsList.length,
              countServices: publishServices.length,
              countUsers: usersList.length,
              countMessages: messages.length,
            })
          }
        })
      }
    }
    effect()
  }, [])
  useEffect(() => {
    setLoad(false)
  }, [count])

  return (
    <>
      {load ? (
        <Loader />
      ) : (
        <div className="admin">
          {location.pathname !== '' && (
            <div className="admin__panel">
              <AdminPanelMap
                admins={admins}
                users={users}
                posts={publishPosts}
                publishService={publishServices}
                events={publishActivities}
                setLoad={setLoad}
              />
              <AdminPanelShortcuts setLoad={setLoad} countL={count} />
              <AdminPanelEvents />
              <AdminPanelStatistic />
            </div>
          )}
        </div>
      )}
    </>
  )
}
