import { lazy } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { AdminNotificationList } from '../admin-components/admin-panel/AdminNotificationList'
import { AdminPanel } from '../admin-components/admin-panel/AdminPanel'
import { AdminPanelMapPage } from '../admin-components/admin-panel/AdminPanelMapPage'
import { AdvertisementTransactions } from '../admin-components/advertisement/AdvertisementTransactions'
import AdminHeader from '../admin-components/header/AdminHeader'
import { PublicationPostComments } from '../general-components/publication-lists/PublicationPostComments'
import Require from './Require'
// import { Users } from '../admin-components/users/Users';
// import { Services } from '../admin-components/services/Services';
// import { Posts } from '../admin-components/posts/Posts';
// import { HelpCenter } from '../admin-components/help-center/HelpCenter';
// import { Messeges } from '../admin-components/messeges/Messeges';
// import { Advertisement } from '../admin-components/advertisement/Advertisement';
// import {Activities} from '../admin-components/activities/Activities';
// import { Activities } from '../admin-components/activities/Activities';
const Users = lazy(() =>
  import('../admin-components/users/Users').then(({ Users }) => ({ default: Users })),
)
const Services = lazy(() =>
  import('../admin-components/services/Services').then(({ Services }) => ({
    default: Services,
  })),
)
const Posts = lazy(() =>
  import('../admin-components/posts/Posts').then(({ Posts }) => ({ default: Posts })),
)
const Messeges = lazy(() =>
  import('../admin-components/messeges/Messeges').then(({ Messeges }) => ({
    default: Messeges,
  })),
)
const HelpCenter = lazy(() =>
  import('../admin-components/help-center/HelpCenter').then(({ HelpCenter }) => ({
    default: HelpCenter,
  })),
)
const Advertisement = lazy(() =>
  import('../admin-components/advertisement/Advertisement').then(({ Advertisement }) => ({
    default: Advertisement,
  })),
)
const Activities = lazy(() =>
  import('../admin-components/activities/Activities').then(({ Activities }) => ({
    default: Activities,
  })),
)

const AdminRouter = () => {
  const location = useLocation()
  return (
    <>
      {location.pathname !== '/admin/advertisement/add-community-selection/map' && (
        <AdminHeader />
      )}
      <Routes>
        <Route path="users/*" element={<Require.Role granted={<Users />} />} />
        <Route path="services/*" element={<Require.Role granted={<Services />} />} />
        <Route
          path="comments"
          element={<Require.Role granted={<PublicationPostComments />} />}
        />
        <Route path="posts/*" element={<Require.Role granted={<Posts />} />} />
        <Route path="messenger/*" element={<Require.Role granted={<Messeges />} />} />
        <Route path="helpcenter/*" element={<Require.Role granted={<HelpCenter />} />} />
        <Route
          path="advertisement/*"
          element={<Require.Role granted={<Advertisement />} />}
        />
        <Route path="activities/*" element={<Require.Role granted={<Activities />} />} />
        <Route path="adminpanel" element={<Require.Role granted={<AdminPanel />} />} />
        <Route path="map" element={<AdminPanelMapPage />} />
        <Route path="events-all" element={<AdminNotificationList />} />
        <Route path="payment" element={<AdvertisementTransactions />} />
        <Route path="*" element={<Require.Role granted={<AdminPanel />} />} />
      </Routes>
    </>
  )
}

export default AdminRouter
