import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROLE } from '../../../types/enum'
import {
  PostUserInterface,
  PublishActivitiesOneItemInterface,
  PublishServiceItemInterface,
} from '../../../types/types'
import { apiBaseURL, indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconArrowRight } from '../../svg/IconArrow'
import { IconLeftChevrons } from '../../svg/IconChevrons'
import { IconStars } from '../../svg/IconFavor'
import { IconProfileInfoBookmark } from '../../svg/IconProfileInfo'
import { PostSlick } from '../../ui/PostSlick'
import { UserHeader } from '../header/UserHeader'

export const ExploreLocation = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [isMyFriend, setisMyFriend] = useState<boolean>(false)

  const [posts, setPosts] = useState<PostUserInterface[]>(location.state.item.posts)
  const [services, setServices] = useState<PublishServiceItemInterface[]>(
    location.state.item.services,
  )
  const [activities, setActivities] = useState<PublishActivitiesOneItemInterface[]>(
    location.state.item.activities,
  )

  const { role } = useAppSelector((state) => state.user)

  const toPublishService = (_id: string) => {
    navigate(`/user/service/publish-service?publishServiceId=${_id}`)
  }

  const toPublishActivities = (_id: string) => {
    navigate(`/user/activities/publish-activities-item?publishActivitiesId=${_id}`)
  }

  const navigateToComments = (postId: string) => {
    navigate(`${indexPathByRole(role)}/comments?postId=${postId}`)
  }

  const toProfileInfo = (prop: {
    _id: string
    email: string
    role: string
    fullName: string
    avatarFileName: string
  }) => {
    navigate('/profileinfo', {
      state: prop,
    })
  }

  return (
    <div className="explore__location">
      <UserHeader>
        <div className="user__header-messages explore__header">
          <>
            <button
              onClick={() => navigate(-1)}
              className="user__header-chatuser user__header-messages-exit"
            >
              <IconLeftChevrons />
            </button>
          </>
        </div>
      </UserHeader>
      <div className="explore__location-item">
        <img
          className="explore__location-img"
          src={`${apiBaseURL}/uploads/map/${location.state.item.file}`}
          alt={location.state.item.title}
        />
        <div className="explore__location-text">
          <span className="explore__location-title">
            <b>{location.state.item.title}</b>
          </span>
          <span className="explore__location-desc">
            <b>6 647</b> neibs in this area
          </span>
          <button
            style={{ maxWidth: '70px', margin: '10px 0' }}
            className={`messenger__alluser-item-button ${
              !isMyFriend && 'messenger__alluser-item-button--active'
            }`}
            onClick={() => setisMyFriend(!isMyFriend)}
          >
            {!isMyFriend ? 'Follow' : 'Neib'}
          </button>
        </div>
      </div>
      {activities && (
        <span className="explore__location-title">
          <b>Events ({activities.length / 2})</b>
        </span>
      )}
      {activities &&
        activities.slice(0, activities.length / 2).map((item) => (
          <div
            className="user__services-last-item event"
            onClick={() => toPublishActivities(item._id)}
          >
            <div className="user__services-last-item-img">
              <div className="user__services-last-item-img-1 user__services-last-item-img-info">
                <div className="user__services-last-item-img-text">
                  {item.activitiesId.name}
                </div>
              </div>
              <img
                src={`${apiBaseURL}/uploads/publish_activities/${item.filesName[0]}`}
                alt=""
              />
              <div className="user__services-last-item-img-2 user__services-last-item-img-info">
                <div className="user__services-last-item-img-text">
                  <IconStars />
                  <b>4.5</b>
                  <span>{'( 808 )'}</span>
                </div>
              </div>
            </div>
            <div className="user__services-last-item-info">
              <div className="user__services-last-item-row1">
                <h5 className="user__services-last-item-title">{item.title}</h5>
                <button>
                  <IconProfileInfoBookmark />
                </button>
              </div>
              <h6 className="user__services-last-item-text">{item.text}</h6>
              <div className="user__services-last-item-foot">
                1 km
                <IconArrowRight />
              </div>
            </div>
          </div>
        ))}
      {services && (
        <span className="explore__location-title">
          <b>Services ({services.length})</b>
        </span>
      )}
      {services &&
        services.map((item) => (
          <div
            className="user__services-last-item service"
            onClick={() => toPublishService(item._id)}
          >
            <div className="user__services-last-item-img">
              <div className="user__services-last-item-img-1 user__services-last-item-img-info">
                <div className="user__services-last-item-img-text">Cleaning</div>
              </div>
              <img
                src={`${apiBaseURL}/uploads/publish_services/${item.filesName[0]}`}
                alt=""
              />
              <div className="user__services-last-item-img-2 user__services-last-item-img-info">
                <div className="user__services-last-item-img-text">
                  <IconStars />
                  <b>4.5</b>
                  <span>{'( 808 )'}</span>
                </div>
              </div>
            </div>
            <div className="user__services-last-item-info">
              <div className="user__services-last-item-row1">
                <h5 className="user__services-last-item-title">{item.title}</h5>
                <button>
                  <IconProfileInfoBookmark />
                </button>
              </div>
              <h6 className="user__services-last-item-text">{item.text}</h6>
              <div className="user__services-last-item-foot">
                1 km
                <IconArrowRight />
              </div>
            </div>
          </div>
        ))}
      {posts && (
        <span className="explore__location-title">
          <b>Posts ({posts.length})</b>
        </span>
      )}
      {posts &&
        posts.map((item) => (
          <div className="admin__posts-list-item" key={item?._id}>
            <div className="admin__posts-list-row1">
              <div
                className="admin__posts-list-row1-img"
                onClick={() =>
                  toProfileInfo({
                    _id: item.userId?._id,
                    email: '',
                    role: ROLE.USER,
                    fullName: item.userId?.fullName,
                    avatarFileName: item?.userId?.avatarFileName || '',
                  })
                }
              >
                <img
                  src={`${apiBaseURL}/uploads/avatar/${item.userId.avatarFileName}`}
                  alt=""
                />
              </div>
              <span className="admin__posts-list-row1-text admin__posts-list-row1-textunder">
                {item?.addressLocation}
              </span>
            </div>
            <div className="admin__posts-list-row2">
              <PostSlick list={item?.filesName}>
                {item?.filesName?.map((it) => (
                  <div
                    className="admin__posts-list-row2-img"
                    onClick={() => navigateToComments(item._id)}
                  >
                    <img src={`${apiBaseURL}/uploads/publish_post/${it}`} alt="" />
                  </div>
                ))}
              </PostSlick>
            </div>
            <div className="admin__posts-list-row3">
              <h5>{item.title}</h5>
              <h6>{item.text}</h6>
            </div>
          </div>
        ))}
    </div>
  )
}
