import { AxiosResponse } from 'axios'
import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import $api from '../../../http'
import { ChatType, MessageType } from '../../../types/types'
import { indexPathByRole, apiBaseURL } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconLeftChevrons } from '../../svg/IconChevrons'
import { UserHeader } from '../header/UserHeader'

export const MessegesMediaInfo = ({ chat }: { chat: ChatType }) => {
  const location = useLocation()
  const navigate = useNavigate()

  const { role } = useAppSelector((state) => state.user)

  const [messageList, setMessageList] = useState<MessageType[]>([])

  const toMedia = () => {
    navigate(`${indexPathByRole(role)}/messeges/chat/chat-info/media`, {
      state: {
        chat: location.state.chat,
      },
    })
  }

  useEffect(() => {
    const Effect = async () => {
      let array: MessageType[] = []
      const resMessageList: AxiosResponse<MessageType[]> = await $api.post(
        'messenger/list-message',
        {
          chatId: location.state.chat.chatId,
        },
      )
      resMessageList.data.filter((message) => {
        if (!message.audio && message.file) {
          array.push(message)
        }
      })
      setMessageList(array)
    }
    Effect()
  }, [])

  return (
    <div>
      <UserHeader>
        <div
          className="user__header-messages"
          style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
        >
          {chat && (
            <div style={{ display: 'flex', gap: '10px' }}>
              <button
                onClick={() =>
                  navigate(
                    `${indexPathByRole(role)}/messeges/chat?chatId=${chat.chatId}`,
                    {
                      state: { chat },
                    },
                  )
                }
                className="user__header-chatuser user__header-messages-exit"
              >
                <IconLeftChevrons />
              </button>
              <div className="user__header-messages-img">
                {chat?.participants.length > 1 ? (
                  <img
                    src={
                      chat.groupImage
                        ? `${apiBaseURL}/uploads/messenger/${chat.groupImage}`
                        : '/Images/postsBG.png'
                    }
                    alt=""
                  />
                ) : (
                  <>
                    {chat.participants[0]?.userId?.avatarFileName ? (
                      <img
                        style={{
                          width: '40px',
                          height: '40px',
                          objectFit: 'cover',
                          borderRadius: '50%',
                        }}
                        src={`${apiBaseURL}/uploads/avatar/${chat.participants[0]?.userId?.avatarFileName}`}
                      />
                    ) : (
                      <div
                        style={{
                          backgroundColor: 'rgba(41, 52, 65, 1)',
                          borderRadius: '100%',
                          width: '100%',
                          height: '100%',
                          color: '#fff',
                          textAlign: 'center',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        <span style={{ margin: 'auto', color: '#fff', fontSize: '14px' }}>
                          {chat.participants[0]?.userId?.fullName.slice(0, 2)}
                        </span>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div>
                {chat.name ? (
                  <div
                    className="user__header-messages-name"
                    style={{ margin: '10px 0' }}
                  >
                    {chat.name}
                  </div>
                ) : (
                  <>
                    <div
                      className="user__header-messages-name"
                      style={{ margin: '10px 0' }}
                    >
                      {chat.participants[0].userId.fullName}
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </UserHeader>
      <div className="group__info-media">
        {messageList.map((item) => (
          <div className="group__info-media-item">
            <img src={`${apiBaseURL}/uploads/messenger/${item.file}`} alt="" />
          </div>
        ))}
      </div>
    </div>
  )
}
