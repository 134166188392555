import { Button, Flex, Form, Select } from 'antd'
import { useNavigate } from 'react-router-dom'
import { attemptUpdateNationality } from '../../../../redux/userNew'
import {
  EditUserProfileDTO,
  PROFILE_STEP,
} from '../../../../types/server-communication/user'
import { useAppDispatch, useAppSelector } from '../../../../utils/hooks'
import { useAttemptListener } from 'dev-masters-react-kit'
import { stepDefinitions } from '../../../../utils/hooks/useNextSetUpProfilePath'
import { getNationalityOptions } from '../../../../utils/localization/i18n'
import { indexPathByRole } from '../../../route/indexPathByRole'
import { FaRegCircle } from 'react-icons/fa'
import { StandaloneScreenLayout } from '../../../ui/Layout/StandaloneScreenLayout'
import { store } from '../../../../store'

interface FormValues extends Pick<EditUserProfileDTO, 'nationality'> {}

export const ProfileNationality = () => {
  const [nationalityForm] = Form.useForm<FormValues>()
  const { nationality, role } = useAppSelector((state) => state.userNew)
  const nationalityCurrent = Form.useWatch('nationality', nationalityForm)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const isLoading = useAttemptListener({
    store,
    attempt: attemptUpdateNationality,
    onFulfilled: () => {
      navigate('/profile/' + stepDefinitions[PROFILE_STEP.SEX].path)
    },
  })
  function handleSubmitForm(values: FormValues) {
    dispatch(attemptUpdateNationality(values))
  }

  return (
    <StandaloneScreenLayout
      title="Nationality"
      subTitle="Embrace diversity and connect with people from all over the world"
      onPrimaryButtonClicked={nationalityForm.submit}
      isDisabled={isLoading || !nationalityCurrent}
    >
      <Form
        form={nationalityForm}
        name="basic"
        layout="vertical"
        initialValues={{ nationality: nationality }}
        onFinish={handleSubmitForm}
      >
        <Form.Item name="nationality">
          <Select
            showSearch
            options={getNationalityOptions()}
            optionRender={({ label }) => (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <FaRegCircle style={{ marginRight: '0.75em' }} />
                <span>{label}</span>
              </div>
            )}
            dropdownAlign={{ offset: [0, 30] }}
            virtual
            optionFilterProp="label"
            placeholder="Select your nationality"
          />
        </Form.Item>
      </Form>
    </StandaloneScreenLayout>
  )
}
