import { TextareaAutosize } from '@mui/material'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { PublishModalAddFile } from '../../general-components/publication/PublishModalAddFile'
import { IconAddImage } from '../../svg/IconAddImage'
import { IconLeftChevrons } from '../../svg/IconChevrons'
import { DarkBg } from '../../ui/DarkBg'
import { InputSearch } from '../../ui/InputSearch'
import { UserHeader } from '../header/UserHeader'
import { PRIVACY } from '../../../types/enum'

const maxLength = 30
export const AddGroupName = () => {
  const location = useLocation()
  const [title, setTitle] = useState<string>('')
  const [text, setText] = useState<string>('')
  const [isOpen, setIsOpen] = useState(false)
  const [file, setFile] = useState<File | string>()
  const [privacy, setPrivacy] = useState<PRIVACY>(
    location?.state?.privacy || PRIVACY.EVERYONE,
  )
  const navigate = useNavigate()
  const { role } = useAppSelector((state) => state.user)
  const getFile = (f: File) => {
    setFile(f)
  }
  const validate = !Boolean(title)

  const handleChangeText = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newText = event.target.value
    if (newText.length <= maxLength) {
      setText(newText)
    }
  }
  return (
    <div className="group__name">
      <UserHeader>
        <div className="user__header-messages explore__header">
          <button
            onClick={() => navigate(-1)}
            className="user__header-chatuser user__header-messages-exit"
          >
            <IconLeftChevrons />
            <h5 className="user__header-title">
              {privacy === PRIVACY.NEIBS ? 'Group Name' : 'Chat Name'}
            </h5>
          </button>
        </div>
      </UserHeader>
      <div className="group__name-content">
        <div className="group__name-content-addImage">
          <div
            className="group__name-content-addImage-preview"
            onClick={() => {
              setFile('')
              setIsOpen(!isOpen)
            }}
          >
            {!file ? (
              <IconAddImage />
            ) : (
              <img
                src={typeof file === 'string' ? file : URL.createObjectURL(file)}
                alt="group icon"
                style={{ borderRadius: '100%', width: '140px', height: '140px' }}
              />
            )}
          </div>
          <span
            className="group__name-content-addImage-button"
            onClick={() => {
              setFile('')
              setIsOpen(!isOpen)
            }}
          >
            Change
          </span>
        </div>
      </div>
      <div className="group__name-content-fields">
        <InputSearch
          value={title}
          changeValue={setTitle}
          limit={maxLength}
          placeholder={privacy === PRIVACY.NEIBS ? `group name` : 'chat name'}
          search={false}
        />
        <div className="profile__about-body group__name-content-fields-text">
          <TextareaAutosize
            value={text}
            onChange={handleChangeText}
            className="profile__about-autoresize"
            minRows={4}
            placeholder={
              privacy === PRIVACY.NEIBS ? `group description` : 'chat description'
            }
          />
        </div>
      </div>
      <button
        className={`publish__publish user--footer--button group__name-content-next ${
          validate && 'services__add-button--disabled'
        }`}
        onClick={() => {
          navigate(
            location.state.privacy === PRIVACY.NEIBS
              ? `${indexPathByRole(role)}/messeges/group-interests`
              : `${indexPathByRole(role)}/messeges/group-map`,
            {
              state: {
                title: title || '',
                text,
                file,
                privacy: location.state.privacy,
              },
            },
          )
        }}
        disabled={validate}
      >
        Next
      </button>
      {isOpen && <DarkBg />}
      <PublishModalAddFile isOpen={isOpen} setIsOpen={setIsOpen} getFile={getFile} />
    </div>
  )
}
