import { SEX } from '../../../types/enum'
import { Image } from 'antd'
import MaleIcon from '../../../assets/images/male.png'
import FemaleIcon from '../../../assets/images/female.png'
import OtherIcon from '../../../assets/images/other.png'
import Icon from '@ant-design/icons'

const icons: Record<SEX, string> = {
  [SEX.MALE]: MaleIcon,
  [SEX.FEMALE]: FemaleIcon,
  [SEX.OTHER]: OtherIcon,
}

export default function GenderIcon({ sex }: { sex: SEX }) {
  return <img src={icons[sex]} />
}
