import { Route, Routes } from 'react-router-dom'
import { ExploreFilters } from './ExploreFilters'
import { ExploreSearch } from './ExploreSearch'
import { ExploreSearchMain } from './ExploreSearchMain'

export const ExploreSearchRouter = () => {
  return (
    <Routes>
      <Route index element={<ExploreSearchMain />} />
      <Route path="find/*" element={<ExploreSearch />} />
      <Route path="saved-search" element={<ExploreFilters />} />
    </Routes>
  )
}
