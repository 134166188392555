import { AxiosResponse } from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import $api from '../../../http'
import { IdentityHttp } from '../../../http/identity-http'
import { UserIdentityInterface } from '../../../redux/profile'
import { ChatType, FriendTypeResponse } from '../../../types/types'
import { apiBaseURL, indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { InputSearch } from '../../ui/InputSearch'
import { Loader } from '../../ui/Loader'
import { Modal } from '../../ui/Modal'
import Slider from '@mui/material/Slider'

const UserItemChat = React.memo((props: ChatType) => {
  const [userIdentity, setUserIdentity] = useState<UserIdentityInterface | null>(null)
  const navigate = useNavigate()
  const { role, _id } = useAppSelector((state) => state.user)

  useEffect(() => {
    const effectBody = async () => {
      const res = await IdentityHttp.getUserIdentity({
        _id: props?.participants[0]?.userId?._id,
        options: ['online'],
      })
      if (props?.participants[0]?.userId?._id) {
        setUserIdentity(res)
      }
    }

    effectBody()
  }, [props])

  const toProfileInfo = async () => {
    navigate('/profileinfo', {
      state: {
        ...props?.participants[0]?.userId,
      },
    })
  }

  // const addRequest = async() => {
  //     await $api.post("messenger/add-request", {
  //         myId:props.friendId._id,
  //         senderId:_id
  //     })
  //     success()
  //     navigate(-1)
  // }

  const userClick = async () => {
    const chat = await $api.post('messenger/get-chat', {
      participants: [
        {
          userId: props?.participants[0]?.userId?._id,
        },
        {
          userId: _id,
        },
      ],
    })
    if (chat && chat?.data?.participants?.length < 3) {
      openChat()
    }
  }

  const openChat = async () => {
    await $api
      .post('messenger/open-chat', {
        participants: [
          {
            userId: props?.participants[0]?.userId?._id,
          },
          {
            userId: _id,
          },
        ],
      })
      .then((res) => {
        console.log(res)
        if (res.data) {
          navigate(`${indexPathByRole(role)}/messeges/chat`, {
            state: {
              chat: res.data,
            },
          })
        }
      })
  }

  return (
    <div className="messenger__alluser-item">
      <div className="messenger__alluser-item-img" onClick={toProfileInfo}>
        <>
          {props?.participants[0]?.userId?.avatarFileName ? (
            <img
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              src={`${apiBaseURL}/uploads/avatar/${props?.participants[0]?.userId?.avatarFileName}`}
            />
          ) : (
            <div
              style={{
                backgroundColor: 'rgba(41, 52, 65, 1)',
                borderRadius: '50%',
                width: '100%',
                height: '100%',
                color: '#fff',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <span style={{ margin: 'auto', color: '#fff', fontSize: '14px' }}>
                {props?.participants[0]?.userId?.fullName.slice(0, 2)}
              </span>
            </div>
          )}
        </>
      </div>
      <div className="messenger__alluser-item-text" onClick={userClick}>
        <h5>{props?.participants[0]?.userId?.fullName}</h5>
        <h5 className="messenger__alluser-item-subtitle">{userIdentity?.online}</h5>
      </div>
    </div>
  )
})
UserItemChat.displayName = 'UserItemChat'
export const FriendUserChatList = () => {
  const [search, setSearch] = useState('')
  const [friend, setFriend] = useState<ChatType[]>([])
  const [friendFiltered, setFriendFiltered] = useState<ChatType[]>([])
  const { _id } = useAppSelector((state) => state.user)
  const [letters, setLetters] = useState<string[]>([])
  const [load, setLoad] = useState(false)

  const rightPanelLetter = (res: ChatType[]) => {
    if (res) {
      setLetters(
        res
          .map((it) => it?.participants[0]?.userId?.fullName[0].toLocaleLowerCase())
          .filter((it, id) => {
            if (!id) {
              return true
            }
            if (
              it !== res[id - 1].participants[0]?.userId?.fullName[0].toLocaleLowerCase()
            ) {
              return true
            }
            return false
          }),
      )
    }
  }

  const getFrined = async () => {
    setLoad(true)
    $api
      .post('messenger/list-chat', { _id, isSupport: false })
      .then((r: AxiosResponse<ChatType[]>) => {
        const list = r.data.map((item) => ({
          ...item,
          participants: item.participants.filter((p) => p.userId._id !== _id),
        }))
        setFriend(list.filter((it) => it.participants.length < 2))
        setLoad(false)
      })
      .catch(() => {
        setLoad(false)
      })
    setLoad(false)
  }

  useEffect(() => {
    getFrined()
  }, [])

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      const friendsSearch = friend.filter((item) =>
        item?.participants[0]?.userId?.fullName.includes(search),
      )
      setFriendFiltered(friendsSearch)
      rightPanelLetter(friendsSearch)
    }, 1000)
    return () => clearTimeout(timeOutId)
  }, [search])

  return (
    <>
      {load ? (
        <Loader />
      ) : (
        <div>
          <div className="user__newsfeed-search">
            <InputSearch
              placeholder={
                <>
                  Search <b>NightborChats</b>
                </>
              }
              value={search}
              changeValue={setSearch}
            />
          </div>
          <div className="messenger__alluser--letters">
            {letters.map((item) => (
              <div>{item?.toUpperCase()}</div>
            ))}
          </div>
          <div className="messenger__alluser">
            {friendFiltered.length
              ? friendFiltered.map((item, index) => (
                  <>
                    {!index && (
                      <div className="messenger__alluser--letter">
                        {item.participants[0]?.userId?.fullName[0].toUpperCase()}
                      </div>
                    )}

                    {index &&
                    item.participants[0]?.userId?.fullName[0].toLocaleLowerCase() !==
                      friend[
                        index - 1
                      ].participants[0]?.userId?.fullName[0].toLocaleLowerCase() ? (
                      <div className="messenger__alluser--letter">
                        {item.participants[0]?.userId?.fullName[0].toUpperCase()}
                      </div>
                    ) : (
                      <></>
                    )}

                    <UserItemChat {...item} />
                  </>
                ))
              : friend.map((item, index) => (
                  <>
                    {!index && (
                      <div className="messenger__alluser--letter">
                        {item.participants[0]?.userId?.fullName[0].toUpperCase()}
                      </div>
                    )}

                    {index &&
                    item.participants[0]?.userId?.fullName[0].toLocaleLowerCase() !==
                      friend[
                        index - 1
                      ].participants[0]?.userId?.fullName[0].toLocaleLowerCase() ? (
                      <div className="messenger__alluser--letter">
                        {item.participants[0]?.userId?.fullName[0].toUpperCase()}
                      </div>
                    ) : (
                      <></>
                    )}

                    <UserItemChat {...item} />
                  </>
                ))}
          </div>
        </div>
      )}
    </>
  )
}
