import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { EDUCATION, FAMILY_STATUS, ORIENTATION, ROLE, SEX } from '../types/enum'

import { createAsyncThunk } from '@reduxjs/toolkit'
import $api from '../http'
import {
  EditUserProfileDTO,
  PROFILE_STEP,
  UpdateAvatarResponseDTO,
} from '../types/server-communication/user'
import {
  attemptFacebook,
  attemptForgotPasswordClaimCode,
  attemptGoogle,
  attemptLogin,
  attemptSetPassword,
  forgotPasswordSendCode,
  refreshAuthTokens,
  registrationConfirmAccount,
  registrationSetAddress,
} from './auth'
import { type Interests, type Professions, type Skills } from '../utils/localization/i18n'
import { SecurityFormValues } from '../components/general-components/profile-info/ProfileInfoSecurity'
import { NominatimSearchResponse } from './statelessRequests/address'
import { AddressDTO } from '../components/general-components/location/AddressDTO'
import { notification } from 'antd'

export interface UserInterface {
  id: string
  email: string
  phone?: string
  role: ROLE | null
  avatarFileName?: null | string
  fullName: string
  aboutMe: string
  dateBirth: string // interface of dayjs().toISOString(). in form it has dayjs.Dayjs interface
  cityBirth: string
  studySchool: string
  education: EDUCATION
  sex: SEX
  orientation: ORIENTATION
  familyStatus: FAMILY_STATUS
  certificates: string[] //array of filenames saved in back-end upload folder
  nationality: string
  stepsToComplete: PROFILE_STEP[]
  professions: (Professions | string)[]
  skills: (Skills | string)[]
  interests: (Interests | string)[]
  address: AddressDTO
  rate: number
  reviews?: any[]
  interestCircle?: {
    center: {
      type: 'Point',
      coordinates: [number, number]
    }
    radius: number
  }
  interestZone?: {
    type: 'Polygon'
    coordinates: [number, number][][]
  }
}

export type State = Partial<UserInterface> & {}

export const initialState: State = {
  //TODO: make real data
  rate: 4.5,
  //TODO: make real data
  reviews: [
    {
      comment_id: 1,
      user_id: 101,
      username: 'johndoe',
      comment: 'Great profile! Looking forward to your new posts.',
      timestamp: '2024-08-08T12:34:56Z',
    },
    {
      comment_id: 2,
      user_id: 102,
      username: 'janedoe',
      comment: 'Awesome content, keep it up!',
      timestamp: '2024-08-08T13:45:22Z',
    },
    {
      comment_id: 3,
      user_id: 103,
      username: 'alexsmith',
      comment: 'Thanks for sharing such insightful information.',
      timestamp: '2024-08-08T14:55:10Z',
    },
  ],
}

export const attemptUpdateUserProfile = createAsyncThunk<
  EditUserProfileDTO,
  EditUserProfileDTO
>('profile/update', async (payload) => {
  await $api.post(`profile`, payload) //TODO [Vlad]: Interfaces does not match
  return payload
})

// export const attemptUpdateUserAddress = createAsyncThunk<
//   Pick<UserInterface, 'address'>,
//   Pick<UserInterface, 'address'>
// >('profile/update-address', async (payload) => {
//   //TODO: Finish call to endpoint
//   console.log('!!!payload:', payload)
//   return payload
// })

export const attemptUpdateAvatar = createAsyncThunk<
  Pick<UserInterface, 'avatarFileName'>,
  Pick<UserInterface, 'avatarFileName'>
>('profile/update-avatar', async (payload) => {
  await await $api.post(`profile/avatar`, payload)
  return payload
})

export const attemptUpdatePrivacy = createAsyncThunk<
  any, // Pick<UserInterface, 'privacy'>,
  any // Pick<UserInterface, 'privacy'>
>('profile/update-privacy', async (payload) => {
  console.log('attemptUpdatePrivacy payload:', payload)
  return payload
})

export const attemptUpdateAboutMe = createAsyncThunk<
  Pick<UserInterface, 'aboutMe'>,
  Pick<UserInterface, 'aboutMe'>
>('profile/update-aboutMe', async (payload) => {
  await $api.post(`profile/about-me`, payload)
  return payload
})

export const attemptUpdateNationality = createAsyncThunk<
  Pick<UserInterface, 'nationality'>,
  Pick<UserInterface, 'nationality'>
>('profile/update-nationality', async (payload) => {
  await $api.post(`profile/nationality`, payload)
  return payload
})

export const attemptUpdateSexIdentity = createAsyncThunk<
  Pick<UserInterface, 'sex' | 'orientation'>,
  Pick<UserInterface, 'sex' | 'orientation'>
>('profile/update-sex-identity', async (payload) => {
  await $api.post(`profile/sex-identity`, payload)
  return payload
})

export const attemptUpdateEducation = createAsyncThunk<
  Pick<UserInterface, 'education' | 'studySchool'>,
  Pick<UserInterface, 'education' | 'studySchool'>
>('profile/update-education', async (payload) => {
  await $api.post(`profile/education`, payload)
  return payload
})

export const attemptUpdateFamilyStatus = createAsyncThunk<
  Pick<UserInterface, 'familyStatus'>,
  Pick<UserInterface, 'familyStatus'>
>('profile/update-family-status', async (payload) => {
  await $api.post(`profile/family-status`, payload)
  return payload
})

export const attemptUpdateBirth = createAsyncThunk<
  Pick<UserInterface, 'cityBirth' | 'dateBirth'>,
  Pick<UserInterface, 'cityBirth' | 'dateBirth'>
>('profile/update-birth', async (payload) => {
  await $api.post(`profile/birth`, payload)
  return payload
})

export const attemptUpdateProfessions = createAsyncThunk<
  Pick<UserInterface, 'professions'>,
  Pick<UserInterface, 'professions'>
>('profile/update-professions', async (payload) => {
  await $api.post(`profile/professions`, payload)
  return payload
})

export const attemptUpdateSkills = createAsyncThunk<
  Pick<UserInterface, 'skills'>,
  Pick<UserInterface, 'skills'>
>('profile/update-skills', async (payload) => {
  await $api.post(`profile/skills`, payload)
  return payload
})

export const attemptUpdateInterests = createAsyncThunk<
  Pick<UserInterface, 'interests'>,
  Pick<UserInterface, 'interests'>
>('profile/update-interests', async (payload) => {
  await $api.post(`profile/interests`, payload)
  return payload
})

export const attemptUpdateCertificates = createAsyncThunk<
  Pick<UserInterface, 'certificates'>,
  Pick<UserInterface, 'certificates'>
>('profile/update-certificates', async (payload) => {
  await $api.post(`profile/certificates`, payload)
  return payload
})

export const attemptUpdateInterestZone = createAsyncThunk<
  {
    polygon?: [number, number][][]
    circle?: {
      radius: number
      center: [number, number]
    }
  },
  {
    polygon?: [number, number][][]
    circle?: {
      radius: number
      center: [number, number]
    }
  }
>('profile/update-interest-zone', async (payload) => {
  await $api.post(`profile/interest-zone`, payload)
  return payload
})

interface UpdateProfileSecurityDTO {
  email?: string
  phone?: string
  address?: AddressDTO
  oldPassword?: string
  newPassword?: string
}

export const attemptUpdateSecurity = createAsyncThunk<void, UpdateProfileSecurityDTO>(
  'profile/update-security',
  async (payload) => {
    await $api.post(`profile/security`, payload)
  },
)

export interface UserIdentityInterface {}

export const userReducer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateAvatar: (
      state,
      { payload }: PayloadAction<UserInterface['avatarFileName']>,
    ) => {
      state.avatarFileName = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(attemptUpdateUserProfile.fulfilled, (state, { payload }) => {
        return { ...state, ...payload } //keep existing keys
      })
      // .addCase(attemptUpdateUserAddress.fulfilled, (state, { payload }) => {
      //   state.address = payload.address
      // })
      .addCase(attemptUpdateAvatar.fulfilled, (state, { payload }) => {
        state.avatarFileName = payload.avatarFileName
        state.stepsToComplete = state.stepsToComplete?.filter(
          (step) => step !== PROFILE_STEP.AVATAR,
        )
      })
      .addCase(attemptUpdatePrivacy.fulfilled, (state, { payload }) => {
        // state.privacy = payload.privacy
        //TODO:
        notification.warning({ message: 'in maintenance' })
        console.log('add logic to save privacy setting in the backend.')
        state.stepsToComplete = state.stepsToComplete?.filter(
          (step) => step !== PROFILE_STEP.PRIVACY,
        )
      })
      .addCase(attemptUpdateAboutMe.fulfilled, (state, { payload }) => {
        state.aboutMe = payload.aboutMe
        state.stepsToComplete = state.stepsToComplete?.filter(
          (step) => step !== PROFILE_STEP.ABOUT_ME,
        )
      })
      .addCase(attemptUpdateNationality.fulfilled, (state, { payload }) => {
        state.nationality = payload.nationality
        state.stepsToComplete = state.stepsToComplete?.filter(
          (step) => step !== PROFILE_STEP.NATIONALITY,
        )
      })
      .addCase(attemptUpdateSexIdentity.fulfilled, (state, { payload }) => {
        state.sex = payload.sex
        state.orientation = payload.orientation
        state.stepsToComplete = state.stepsToComplete?.filter(
          (step) => step !== PROFILE_STEP.SEX,
        )
      })
      .addCase(attemptUpdateEducation.fulfilled, (state, { payload }) => {
        state.education = payload.education
        state.studySchool = payload.studySchool
        state.stepsToComplete = state.stepsToComplete?.filter(
          (step) => step !== PROFILE_STEP.EDUCATION,
        )
      })
      .addCase(attemptUpdateFamilyStatus.fulfilled, (state, { payload }) => {
        state.familyStatus = payload.familyStatus
        state.stepsToComplete = state.stepsToComplete?.filter(
          (step) => step !== PROFILE_STEP.FAMILY_STATUS,
        )
      })
      .addCase(attemptUpdateBirth.fulfilled, (state, { payload }) => {
        state.dateBirth = payload.dateBirth
        state.cityBirth = payload.cityBirth
        state.stepsToComplete = state.stepsToComplete?.filter(
          (step) => step !== PROFILE_STEP.DATE_PLACE_OF_BIRTH,
        )
      })
      .addCase(attemptUpdateProfessions.fulfilled, (state, { payload }) => {
        state.professions = payload.professions
        state.stepsToComplete = state.stepsToComplete?.filter(
          (step) => step !== PROFILE_STEP.PROFESSION,
        )
      })
      .addCase(attemptUpdateSkills.fulfilled, (state, { payload }) => {
        state.skills = payload.skills
        state.stepsToComplete = state.stepsToComplete?.filter(
          (step) => step !== PROFILE_STEP.SKILLS,
        )
      })
      .addCase(attemptUpdateInterests.fulfilled, (state, { payload }) => {
        state.interests = payload.interests
        state.stepsToComplete = state.stepsToComplete?.filter(
          (step) => step !== PROFILE_STEP.INTERESTS,
        )
      })
      .addCase(attemptUpdateCertificates.fulfilled, (state, { payload }) => {
        state.certificates = payload.certificates
        state.stepsToComplete = state.stepsToComplete?.filter(
          (step) => step !== PROFILE_STEP.CERTIFICATES,
        )
      })
      //#region auth actions handler. It should completely overwrite state
      .addCase(attemptLogin.fulfilled, (state, { payload }) => {
        return {
          reviews: state.reviews,
          rate: state.rate,
          ...payload.user,
        }
      })
      .addCase(attemptGoogle.fulfilled, (state, { payload }) => {
        //TODO: remove profile keep user
        const userWithStepsToComplete: Partial<UserInterface> = {
          ...payload.user,
          stepsToComplete: payload.profile.stepsToComplete,
        }
        return userWithStepsToComplete
      })
      .addCase(attemptFacebook.fulfilled, (state, { payload }) => {
        //TODO: remove profile keep user
        const userWithStepsToComplete: Partial<UserInterface> = {
          ...payload.user,
          stepsToComplete: payload.profile.stepsToComplete,
        }
        return userWithStepsToComplete
      })
      .addCase(refreshAuthTokens.fulfilled, (state, { payload }) => {
        return payload.user
      })
      .addCase(registrationConfirmAccount.fulfilled, (state, { payload }) => {
        return payload.user
      })
      .addCase(registrationSetAddress.fulfilled, (state, { payload }) => {
        return payload.user
      })
      .addCase(forgotPasswordSendCode.fulfilled, (state, { payload }) => {
        if (payload) {
          return payload.user
        }
      })
      .addCase(attemptForgotPasswordClaimCode.fulfilled, (state, { payload }) => {
        return payload.user
      })
      .addCase(attemptSetPassword.fulfilled, (state, { payload }) => {
        return payload.user
      })
      .addCase(attemptUpdateInterestZone.fulfilled, (state, { payload }) => {
        if (payload.polygon) {
          state.interestZone = {
            type: 'Polygon',
            coordinates: payload.polygon,
          }
          state.interestCircle = undefined
        }
        else if (payload.circle) {
          state.interestZone = undefined
          state.interestCircle = {
            center: {
              type: 'Point',
              coordinates: payload.circle.center,
            },
            radius: payload.circle.radius,
          }
        }
      })
      .addCase(attemptUpdateSecurity.fulfilled, (state, action) => {
        state.email = action.meta.arg.email
        state.phone = action.meta.arg.phone
        state.address = action.meta.arg.address
      })
    //#endregion
  },
})

export const { updateAvatar } = userReducer.actions
export default userReducer.reducer
