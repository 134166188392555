import React from 'react'
import { Button, notification, Space, Tabs, Image, Flex } from 'antd'
import type { TabsProps } from 'antd'
import FormSectionDivider from '../../../ui/new-components/FormSectionDivider'
import { IoStar } from 'react-icons/io5'

import { useAppSelector } from '../../../../utils/hooks'
import { useTranslation } from 'react-i18next'
import { UnderDevelopmentComponent } from '../../../../utils/dev'

const onChange = (key: string) => {
  console.log(key)
}

const items: TabsProps['items'] = [
  {
    key: 'personal',
    label: 'Personal',
    children: <Personal />,
  },
  {
    key: 'professional',
    label: 'Professional',
    children: <Professional />,
  },
]

export const TabInfo = () => {
  return (
    <Tabs
      defaultActiveKey="personal"
      items={items}
      onChange={onChange}
      style={{ marginTop: '2em' }}
      tabBarGutter={0}
    />
  )
}

function Personal() {
  const { aboutMe, skills, interests } = useAppSelector((state) => state.userNew)
  const { t } = useTranslation(['skillsList', 'interestsList'])
  return (
    <>
      <FormSectionDivider title={'About me'} />
      {aboutMe}
      <FormSectionDivider title={`Skills (${skills?.length || 0})`} />
      <Button.Group>
        <Space wrap>
          {skills?.map((skill) => (
            <Button
              key={skill}
              onClick={() =>
                notification.warning({ message: 'what does this button should do?' })
              }
            >
              {t(skill, { ns: 'skillsList' })}
            </Button>
          ))}
        </Space>
      </Button.Group>
      <FormSectionDivider title={`Interests (${interests?.length || 0})`} />
      <Button.Group>
        <Space wrap>
          {interests?.map((interest) => (
            <Button key={interest}>{t(interest, { ns: 'interestsList' })}</Button>
          ))}
        </Space>
      </Button.Group>
    </>
  )
}

function Professional() {
  const { rate, reviews, certificates } = useAppSelector((state) => state.userNew)
  return (
    <>
      <FormSectionDivider title={'Services (0)'} />
      <UnderDevelopmentComponent />
      <FormSectionDivider title={`Certificates (${certificates?.length || 0})`} />
      <Flex wrap gap="small" justify="space-around">
        {certificates?.map((filename) => (
          <Image
            width={'45%'}
            src={`${process.env.REACT_APP_UPLOADED_BASE_URL}/certificates/${filename}`}
          />
        ))}
      </Flex>
      <FormSectionDivider
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IoStar />
            {`${rate} (${reviews?.length || 0} reviews)`}
          </div>
        }
      />
    </>
  )
}
