import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { apiBaseURL, indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconProfileInfoBookmark } from '../../svg/IconProfileInfo'
import { IconsNewsfeedPlus } from '../../svg/IconsNewsfeed'
import { InputSearch } from '../../ui/InputSearch'
import { UserHeader } from '../header/UserHeader'

export const ExploreHeaderMain = ({
  search,
  setSearch,
  setIsOpenPublish,
}: {
  search: string
  setSearch: (s: string) => void
  setIsOpenPublish?: (s: boolean) => void
}) => {
  const { avatarFileName, fullName } = useAppSelector((state) => state.user)
  const { country, street, houseNumber } = useAppSelector((state) => state.profile)

  const [isActiveSearch, setIsActiveSearch] = useState(false)

  const onFocus = () => {
    setIsActiveSearch(true)
  }
  const onBlur = () => {
    setIsActiveSearch(false)
  }
  const navigate = useNavigate()
  const { role } = useAppSelector((state) => state.user)
  return (
    <UserHeader>
      <div className="explore__main-header">
        <div className="explore__main-header-user">
          {avatarFileName ? (
            <img src={`${apiBaseURL}/uploads/avatar/${avatarFileName}`} />
          ) : (
            <div
              style={{
                backgroundColor: 'rgba(41, 52, 65, 1)',
                borderRadius: '100%',
                width: '40px',
                height: '40px',
                color: '#fff',
                textAlign: 'center',
                position: 'relative',
              }}
            >
              <span style={{ position: 'absolute', left: '10px', top: '8px' }}>
                {fullName.slice(0, 2)}
              </span>
            </div>
          )}
          <div className="explore__main-header-user-location">
            <h6>current location</h6>
            <h5>
              {houseNumber ? houseNumber + ',' : ''} {street ? street + ',' : ''}{' '}
              {country}
            </h5>
          </div>
        </div>
        <div className="explore__main-header-btns">
          <button
            className="user__header-main-button"
            onClick={() =>
              navigate(`${indexPathByRole(role)}/notification/notification-mark`)
            }
          >
            <IconProfileInfoBookmark />
          </button>
          <button
            className="user__header-main-button"
            onClick={() => {
              if (setIsOpenPublish) setIsOpenPublish(true)
            }}
          >
            <IconsNewsfeedPlus />
          </button>
        </div>
      </div>
      <div
        className={`user__header-main-search ${
          isActiveSearch && 'user__header-main-search--active'
        }`}
      >
        <div className="user__header-main-search-input">
          <InputSearch
            placeholder={
              <>
                Search <span>NeighborHarbor</span>
              </>
            }
            value={search}
            changeValue={setSearch}
            onFocus={onFocus}
            onBlur={onBlur}
            onClick={() => navigate(`${indexPathByRole(role)}/search`)}
          />
          {isActiveSearch && (
            <button className="user__header-main-search--cancel">Cancel</button>
          )}
        </div>
      </div>
    </UserHeader>
  )
}
