import L, { LatLngExpression } from 'leaflet'
import { renderToString } from 'react-dom/server'
import { Marker } from 'react-leaflet'
import { useNavigate } from 'react-router-dom'
import { indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { RegInterface } from './ExploreCity'

export const AddressMarkerCity = ({
  text,
  coords,
  reg,
  night,
}: {
  text: string
  coords: LatLngExpression
  reg?: RegInterface
  night: boolean
}) => {
  const navigate = useNavigate()
  const { role } = useAppSelector((state) => state.user)
  const markDivIcon = new L.DivIcon({
    html: renderToString(
      <div
        style={
          night
            ? {
                borderRadius: '10px',
                padding: '0px',
                height: '30px',
                width: '52px',
                position: 'absolute',
                left: '-10px',
                top: '-22px',
                backgroundColor: '#fff',
                color: 'rgba(21, 16, 77, 1)',
                textAlign: 'center',
              }
            : {
                borderRadius: '10px',
                padding: '0px',
                height: '30px',
                width: '52px',
                position: 'absolute',
                left: '-10px',
                top: '-22px',
                backgroundColor: 'blue',
                color: '#fff',
                textAlign: 'center',
              }
        }
      >
        <p style={{ margin: '6px 0' }}>{text}</p>
      </div>,
    ),
    iconSize: [52, 0],
    className: 'leaflet-div-icon',
  })
  return (
    <Marker
      eventHandlers={{
        click: () => navigate(`${indexPathByRole(role)}/explore/map`, { state: { reg } }),
      }}
      position={coords}
      icon={markDivIcon}
    ></Marker>
  )
}
