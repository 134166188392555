import { useNavigate } from 'react-router-dom'
import { indexPathByRole } from '../route/indexPathByRole'
import { useAppSelector } from '../../utils/hooks'
import { Modal } from './Modal'

export const ModalDiscard = (props: {
  setIsOpen: (s: boolean) => void
  text: string
  setSave?: (s: boolean) => void
  subText?: string
  isOpen: boolean
}) => {
  const navigate = useNavigate()
  const { role } = useAppSelector((state) => state.user)
  return (
    <>
      <div
        className={`activities__favor-modal  ${
          props.isOpen ? 'activities__favor-modal--open ' : ''
        }`}
      >
        <Modal setIsOpen={props.setIsOpen} className={''}>
          <div
            onClick={(e) => {
              e.stopPropagation()
              props.setIsOpen(false)
            }}
            className="activities__favor-modal-linebody"
          >
            <button className="activities__favor-modal-line" />
          </div>
          <div className="discard__modal-container">
            <h5 className="user__newsfeed__getstarted-title">
              {props.subText ? props.text : `Discard ${props.text.slice(9)}?`}
            </h5>
            <h6 className="user__newsfeed__getstarted-subtitle">
              {props.subText ? props.subText : 'If you go back, it won’t be saved'}
            </h6>
            <div className="discard__modal-btns">
              <button
                className={`profile__method-btlater`}
                style={{ margin: '15px 0' }}
                onClick={() => {
                  props.setIsOpen(false)
                  navigate(`${indexPathByRole(role)}`)
                }}
              >
                Continue
              </button>
              <button
                className="profile__method-btlater profile__method-btlater--inherit"
                style={{ boxShadow: 'none' }}
                onClick={() => props.setIsOpen(false)}
              >
                <span>Cancel</span>
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  )
}
