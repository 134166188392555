import { ReactNode } from 'react'
import Slider from 'react-slick'

export const SlickCategoriesCustom = ({
  children,
  changeCurrentSlide = () => {},
}: {
  children: ReactNode
  changeCurrentSlide?: (n: number) => void
}) => {
  var settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    afterChange: (n: number) => changeCurrentSlide(n),
  }
  return <Slider {...settings}>{children}</Slider>
}
