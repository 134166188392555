import { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { Loader } from '../../ui/Loader'

import { allActivities, getTenPublishActivities } from '../../../redux/activities'
import { USER_LIST_APP } from '../../../types/enum'
import { UserActivitiesCategory } from './UserActivitiesCategory'
import { UserActivitiesMain } from './UserActivitiesMain'
import { UserActivitiesSpecialOffers } from './UserActivitiesSpecialOffers'
import { UserActivitiesTimeLine } from './UserActivitiesTimeLine'
import { UserPublishActivitiesItem } from './UserPublishActivitiesItem'
import { UserPublishActivitiesList } from './UserPublishActivitiesList'

export const UserActivities = () => {
  const { isLoad } = useAppSelector((state) => state.categories)
  const dispatch = useAppDispatch()
  const location = useLocation()
  const { _id } = useAppSelector((state) => state.user)

  useEffect(() => {
    dispatch(allActivities())
    dispatch(getTenPublishActivities({ userId: _id }))
  }, [])

  return (
    <div
      className={`user user--body ${location.pathname === '/user/activities/publish-activities-item' && 'user--none'}`}
    >
      <Routes>
        <Route path="publish-activities-item" element={<UserPublishActivitiesItem />} />
        <Route
          path="user-activities-special-offers"
          element={<UserActivitiesSpecialOffers />}
        />
        <Route
          path="user-last-publish-activities"
          element={<UserPublishActivitiesList event={USER_LIST_APP.LAST} />}
        />

        <Route
          path="user-publish-activities-list"
          element={<UserPublishActivitiesList event={USER_LIST_APP.ALL} />}
        />
        <Route path="timeline" element={<UserActivitiesTimeLine />} />
        <Route path="user-activities-categories" element={<UserActivitiesCategory />} />
        <Route path="*" element={<UserActivitiesMain />} />
      </Routes>
      {isLoad && <Loader />}
    </div>
  )
}
