export const IconCalendar = () => {
  return (
    <svg
      width="18"
      height="21"
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6438 7.42114H0.428583C0.231849 7.42114 0.0722656 7.58016 0.0722656 7.7769V20.1371C0.0722656 20.3336 0.231849 20.4929 0.428583 20.4929H17.6436C17.8414 20.4929 18.0002 20.3336 18.0002 20.1371V7.7769C18.0002 7.58016 17.8414 7.42114 17.6438 7.42114ZM6.04523 17.8869C5.45109 17.8869 4.79474 17.7676 4.28813 17.5672C4.21185 17.5371 4.16513 17.4588 4.17667 17.3775L4.34273 16.1689C4.34948 16.1155 4.38101 16.0682 4.42716 16.0406C4.47501 16.0127 4.53186 16.0079 4.5814 16.0268C5.13839 16.237 5.60673 16.3232 6.19806 16.3232C6.81781 16.3232 7.24956 16.0305 7.24956 15.6117C7.24956 15.0918 6.99879 14.8137 5.62784 14.7355C5.53355 14.7302 5.46037 14.6522 5.46037 14.5579V13.3496C5.46037 13.2562 5.53214 13.1785 5.62502 13.1718C6.80571 13.0884 6.94221 12.7254 6.94221 12.4597C6.94221 12.2798 6.94221 11.9787 6.14289 11.9787C5.71537 11.9787 5.26139 12.0952 4.79418 12.3237C4.74239 12.3482 4.68216 12.3471 4.63262 12.3212C4.58168 12.2942 4.54734 12.2447 4.53974 12.1884L4.37425 10.9804C4.36299 10.903 4.40493 10.8275 4.47529 10.7943C5.01934 10.5427 5.7058 10.4152 6.51694 10.4152C7.96276 10.4152 8.93433 11.0868 8.93433 12.0862C8.93433 12.8357 8.54677 13.3874 7.75167 13.7622C8.43419 14.0254 9.13163 14.503 9.13163 15.5134C9.13163 16.955 7.92082 17.8869 6.04523 17.8869ZM13.565 17.599C13.565 17.6972 13.4851 17.7769 13.3869 17.7769H11.9157C11.817 17.7769 11.7376 17.6972 11.7376 17.599V12.7518L10.7708 13.1205C10.719 13.1397 10.663 13.1352 10.6166 13.107C10.5696 13.0797 10.538 13.0322 10.5313 12.9784L10.3666 11.7698C10.3562 11.6941 10.3953 11.6201 10.464 11.5866L12.5729 10.5436C12.5977 10.5317 12.6247 10.5253 12.6523 10.5253H13.3872C13.4854 10.5253 13.5653 10.6049 13.5653 10.7034L13.565 17.599Z"
        fill="#0F1419"
      />
      <path
        d="M17.6438 1.85596H15.8091V2.71467C15.8091 3.62516 15.2898 4.59758 13.8305 4.59758C12.3711 4.59758 11.8516 3.62516 11.8516 2.71467V1.85596H6.24422V2.71467C6.24422 3.62516 5.72494 4.59758 4.26561 4.59758C2.806 4.59758 2.28532 3.62516 2.28532 2.71467V1.85596H0.428583C0.231849 1.85596 0.0722656 2.01498 0.0722656 2.21171V5.8309C0.0722656 6.02791 0.231849 6.18721 0.428583 6.18721H17.6436C17.8414 6.18721 18.0002 6.02791 18.0002 5.8309V2.21171C18.0002 2.01498 17.8414 1.85596 17.6438 1.85596Z"
        fill="#0F1419"
      />
      <path
        d="M4.26639 3.44773C4.85603 3.44773 5.09555 3.23524 5.09555 2.71427V1.85584V0.997416C5.09555 0.475886 4.85603 0.263672 4.26639 0.263672C3.67506 0.263672 3.43555 0.476168 3.43555 0.997416V1.85584V2.71455C3.43555 3.23552 3.67506 3.44773 4.26639 3.44773Z"
        fill="#0F1419"
      />
      <path
        d="M13.8292 3.44773C14.4188 3.44773 14.6583 3.23524 14.6583 2.71427V1.85584V0.997416C14.6583 0.475886 14.4188 0.263672 13.8292 0.263672C13.2378 0.263672 13 0.476168 13 0.997416V1.85584V2.71455C12.9997 3.23552 13.2378 3.44773 13.8292 3.44773Z"
        fill="#0F1419"
      />
    </svg>
  )
}
