import { NOTIFICATION_EVENT } from '../../../types/enum'
import { Modal } from '../../ui/Modal'

const TypeModal = ({
  setIsOpen,
  isOpen,
  setType,
  type,
}: {
  setIsOpen: (s: boolean) => void
  isOpen: boolean
  setType: (s: NOTIFICATION_EVENT) => void
  type: string
}) => {
  return (
    <div
      className={`activities__favor-modal  ${
        isOpen ? 'activities__favor-modal--open' : ''
      }`}
    >
      <Modal className="" setIsOpen={(s: boolean) => setIsOpen(s)}>
        <div
          onClick={(e) => {
            e.stopPropagation()
            setIsOpen(false)
          }}
          className="activities__favor-modal-linebody"
        >
          <button className="activities__favor-modal-line" />
        </div>
        <div className="publish__modaladd">
          <h5 className="publish__modaladd-title">Where to publish?</h5>
          <h6 className="publish__modaladd-subtitle">Choose your medium</h6>
          <div className="publish__routemodal-btns">
            <button
              onClick={() => {
                setType(NOTIFICATION_EVENT.NOTIFICATION_NEWS)
                setIsOpen(false)
              }}
            >
              <div className="publish__routemodal-button">
                <div className="publish__routemodal-button-img">
                  <img src="/Images/PublishPost.png" alt="" />
                </div>
                <p className="publish__routemodal-text">Newsfeed</p>
              </div>
            </button>
            <button
              onClick={() => {
                setType(NOTIFICATION_EVENT.NOTIFICATION_SERVICE)
                setIsOpen(false)
              }}
            >
              <div className="publish__routemodal-button">
                <div className="publish__routemodal-button-img">
                  <img src="/Images/PublishService.png" alt="" />
                </div>
                <p className="publish__routemodal-text">Service</p>
              </div>
            </button>
            <button
              onClick={() => {
                setType(NOTIFICATION_EVENT.NOTIFICATION_ACTIVITIES)
                setIsOpen(false)
              }}
            >
              <div className="publish__routemodal-button">
                <div className="publish__routemodal-button-img">
                  <img src="/Images/PublishEvent.png" alt="" />
                </div>
                <p className="publish__routemodal-text">Activity</p>
              </div>
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}
export default TypeModal
