import dayjs from 'dayjs'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ActivitiesHttp } from '../../../http/activities-http'
import { FeedBackHttp } from '../../../http/feedback-http'
import { PRIVACY, ROLE } from '../../../types/enum'
import { PublishEventItemInterface } from '../../../types/types'
import { apiBaseURL, indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { PublicationEventPanel } from '../../general-components/publication-lists/PublicationEventPanel'
import { PublishModalRoute } from '../../general-components/publication/PublishModalRoute'
import { IconBookMark } from '../../svg/IconBookMark'
import { IconServicesAllPoint } from '../../svg/IconServicesAll'
import { DarkBg } from '../../ui/DarkBg'
import { InputSearch } from '../../ui/InputSearch'
import { TempNotification } from '../../ui/TempNotification'
import { UserHeaderMain } from '../header/UserHeaderMain'
import { ActivitiesMyModal } from './ActivitiesMyModal'
import { ActivitiesNeibModalMany } from './ActivitiesNeibModalMany'

const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']

export const UserActivitiesMain = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [searsh, setSearch] = useState('')
  const { publishActivities } = useAppSelector((state) => state.activities)
  const [PublishActivities, setPublishActivities] = useState<PublishEventItemInterface[]>(
    [],
  )
  const [currentSlideFirst, setCurrentSlideFirst] = useState(0)
  const { activities } = useAppSelector((s) => s.activities)
  const [todaysActivity, setTodaysActivity] = useState<PublishEventItemInterface>()
  const navigate = useNavigate()

  const [week, setWeek] = useState<number[]>()
  const [currentDay, setCurrentDay] = useState<number>()
  const [currentDate, setCurrentDate] = useState<Date>()
  useEffect(() => {
    setPublishActivities(publishActivities)
  }, [publishActivities])

  useEffect(() => {
    let d = new Date()
    let array: number[] = []
    let s = moment().startOf('isoWeek').toDate().getDate()
    let e = moment().endOf('isoWeek').toDate().getDate()
    for (let i = s; i < e + 1; i++) {
      array.push(i)
    }
    setCurrentDate(d)
    setCurrentDay(d.getDate())
    setWeek(array)
  }, [])

  useEffect(() => {
    if (PublishActivities && currentDate) {
      PublishActivities.map((item) => {
        if (
          dayjs(item.startDate).isSame(currentDate, 'year') &&
          dayjs(item.startDate).isSame(currentDate, 'month') &&
          dayjs(item.startDate).isSame(currentDate, 'day')
        ) {
          setTodaysActivity(item)
        }
      })
    }
  }, [PublishActivities, currentDate])

  const { role, polygonCoords, fullName, _id } = useAppSelector((state) => state.user)

  const validatePrivacy = (item: PublishEventItemInterface) => {
    if (role === ROLE.USER) {
      if (item.privacyEvent === PRIVACY.NEIBS) {
        return item.allowList.map((id) => id === _id) || item.userId?._id === _id
      }
      if (item.privacyEvent === PRIVACY.ONLYME) {
        return item.userId?._id === _id
      }
      if (item.privacyEvent === PRIVACY.EVERYONE) {
        return true
      }
    } else {
      return true
    }
  }

  const updateLikeEvent = async (likeId: string, serviceId: string) => {
    await FeedBackHttp.updateLike({
      likeId,
      userId: _id,
    })
    let arr: PublishEventItemInterface[] = []
    PublishActivities.map((item) => {
      if (item._id === serviceId) {
        arr.push({
          ...item,
          isLiked: !item?.isLiked,
          countLikes: item.isLiked ? item?.countLikes - 1 : item?.countLikes + 1,
        })
      } else {
        arr.push(item)
      }
    })
    setPublishActivities(arr)
  }
  const [isOpenSave, setIsOpenSave] = useState(false)
  const updateMarkEvent = async (eventId: string, isMarked: boolean) => {
    if (isMarked) {
      await ActivitiesHttp.deleteMark({
        eventId,
        marckedUserId: _id,
      })
    } else {
      await ActivitiesHttp.addMark({
        eventId,
        marckedUserId: _id,
      })
      setIsOpenSave(true)
    }
    let arr: PublishEventItemInterface[] = []
    PublishActivities.map((item) => {
      if (item._id === eventId) {
        arr.push({
          ...item,
          isMarked: !item?.isMarked,
        })
      } else {
        arr.push(item)
      }
    })
    setPublishActivities(arr)
  }

  const copyLink = async (item: string) => {
    const text = `${window.location.href}/publish-activities-item?publishActivitiesId=${item}`
    navigate(`${indexPathByRole(role)}/service/share-list`, { state: { text } })
  }

  const toPublishActivities = (_id: string) => {
    navigate(`/user/activities/publish-activities-item?publishActivitiesId=${_id}`)
  }

  const [isOpenPublish, setIsOpenPublish] = useState(false)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [currentItem, setCurrentItem] = useState<PublishEventItemInterface>()
  const updateNotification = async (serviceId: string) => {
    if (PublishActivities) {
      await ActivitiesHttp.updateNotification({
        eventId: serviceId,
        userId: _id,
      })

      let arr: PublishEventItemInterface[] = []
      PublishActivities.map((item) => {
        if (item._id === serviceId) {
          arr.push({ ...item, isNotificatedEvent: !item.isNotificatedEvent })
        } else {
          arr.push(item)
        }
      })
      setPublishActivities(arr)
    }
  }
  return (
    <>
      <UserHeaderMain isOpenPublish={isOpenPublish} setIsOpenPublish={setIsOpenPublish} />
      <div className="user__newsfeed-search">
        <InputSearch
          placeholder={
            <>
              Search<span> NeighborHarbor</span>
            </>
          }
          value={searsh}
          changeValue={setSearch}
          onClick={() => navigate(`${indexPathByRole(role)}/search`)}
        />
      </div>
      {week && currentDay && (
        <div className="user__event-calender" onClick={() => navigate('timeline')}>
          <h5>{moment().format('MMMM') + ', ' + currentDate?.getFullYear()}</h5>
          <div className="user__event-calender-dayline">
            {days.map((item) => (
              <span>{item}</span>
            ))}
          </div>
          <div className="user__event-calender-daynum">
            {week.map((day) => (
              <span
                className={
                  day !== currentDay
                    ? 'user__event-calender-daynum-item'
                    : 'user__event-calender-daynum-item-active'
                }
              >
                {day}
              </span>
            ))}
          </div>
        </div>
      )}
      <div className="user__services-last">
        {PublishActivities?.slice(0, 2).map((item) => (
          <>
            {validatePrivacy(item) && (
              <div
                className="user__event-item"
                onClick={() => toPublishActivities(item._id)}
              >
                <div className="user__event-item-row1">
                  <div className="user__event-item-row1-text">
                    <h5>{item.title}</h5>
                    <h6>{item.addressLocation}</h6>
                  </div>
                </div>
                <div className="explore__event-item-row2" onClick={() => {}}>
                  {item.filesName.length ? (
                    item?.filesName?.map((it) => (
                      <>
                        <div
                          style={{ position: 'relative' }}
                          className="explore__event-item-row2-img"
                          onClick={() => {}}
                        >
                          <div className="explore__event-item-row2-img-last">
                            <div className="explore__event-item-row2-img-last-text">
                              {moment(item?.startDate).format('DD/MM/YYYY')}
                            </div>
                          </div>
                          <div
                            className="user__event-item-row2"
                            onClick={(e) => {
                              e.stopPropagation()
                              setIsOpenModal(true)
                              setCurrentItem(item)
                            }}
                          >
                            <IconServicesAllPoint />
                          </div>
                          <img
                            src={`${apiBaseURL}/uploads/publish_activities/${it}`}
                            alt=""
                          />
                          <div className="explore__event-item-row2-img-last-bottom">
                            <div className="explore__event-item-row2-img-last-bottom-text">
                              {item.activitiesId.name}
                            </div>
                          </div>
                        </div>
                      </>
                    ))
                  ) : (
                    <div
                      style={{ position: 'relative' }}
                      className="explore__event-item-row2-img"
                      onClick={() => toPublishActivities(item._id)}
                    >
                      <div className="explore__event-item-row2-img-last">
                        <div className="explore__event-item-row2-img-last-text">
                          {moment(item?.startDate).format('DD/MM/YYYY')}
                        </div>
                      </div>
                      <div
                        className="user__event-item-row2"
                        onClick={(e) => {
                          e.stopPropagation()
                          setIsOpenModal(true)
                          setCurrentItem(item)
                        }}
                      >
                        <IconServicesAllPoint />
                      </div>
                      <img className="posts__bg" src={`/Images/postsBG.png`} alt="" />
                      <div className="explore__event-item-row2-img-last-bottom">
                        <div className="explore__event-item-row2-img-last-bottom-text">
                          <span
                            className="admin__posts-list-row1-text"
                            style={{ color: 'rgba(21, 16, 77, 1)' }}
                          >
                            {item.activitiesId.name}
                          </span>{' '}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="user__event-item-row3">
                  <h6>{item.text}</h6>
                  {/* <div className="user__event-item-row3-button">
                                     {item.startDate.toString().split(".")[0].split("T")[0].replaceAll("-", "/") + " " }{ <b>{item.startDate.toString().split(".")[0].split("T")[1].split(":")[0] + ":" + item.startDate.toString().split(".")[0].split("T")[1].split(":")[1]}</b> }
                                 </div> */}
                </div>
                <div className="user__event-item-row4">
                  <PublicationEventPanel
                    item={item}
                    updateLike={() => updateLikeEvent(item.likes, item._id)}
                    navigateToComments={() => toPublishActivities(item._id)}
                    updateMark={() => updateMarkEvent(item._id, item.isMarked)}
                    updateRepost={() => copyLink(item._id)}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                  />
                  {/* {<button onClick={() => updateLikeEvent(item.likeId, item._id)}>
                                 {item.isLiked ? <IconPostsRedLike /> : <IconPostsLike />}
     
                             </button>}
                             <div onClick={()=>updateMarkEvent(item._id, item.isMarked)} className={item.isMarked ? "user__event-item-row4-bookmark-active" :"user__event-item-row4-bookmark"}>
                                 <IconProfileInfoBookmark />
                             </div> */}
                </div>
              </div>
            )}
          </>
        ))}
        <button
          className="user__services-last-button user__services-last-button"
          onClick={() => navigate(`/user/activities/user-last-publish-activities`)}
        >
          View all
        </button>
      </div>
      {todaysActivity && (
        <div className="user__event-today">
          <h5>Today’s Activities</h5>
          <h6>Seize the day and make the most of every moment</h6>
          <div
            className="user__event-item"
            onClick={() => toPublishActivities(todaysActivity._id)}
          >
            <div className="user__event-item-row1">
              <div className="user__event-item-row1-text">
                <h5>{todaysActivity.title}</h5>
                <h6>{todaysActivity.addressLocation}</h6>
              </div>
            </div>
            <div className="explore__event-item-row2" onClick={() => {}}>
              {todaysActivity.filesName.length ? (
                todaysActivity?.filesName?.map((it) => (
                  <>
                    <div
                      style={{ position: 'relative' }}
                      className="explore__event-item-row2-img"
                      onClick={() => {}}
                    >
                      <div className="explore__event-item-row2-img-last">
                        <div className="explore__event-item-row2-img-last-text">
                          {moment(todaysActivity?.startDate).format('DD/MM/YYYY')}
                        </div>
                      </div>
                      <div
                        className="user__event-item-row2"
                        onClick={(e) => {
                          e.stopPropagation()
                          setIsOpenModal(true)
                          setCurrentItem(todaysActivity)
                        }}
                      >
                        <IconServicesAllPoint />
                      </div>
                      <img
                        src={`${apiBaseURL}/uploads/publish_activities/${it}`}
                        alt=""
                      />
                      <div className="explore__event-item-row2-img-last-bottom">
                        <div className="explore__event-item-row2-img-last-bottom-text">
                          {todaysActivity.activitiesId.name}
                        </div>
                      </div>
                    </div>
                  </>
                ))
              ) : (
                <div
                  style={{ position: 'relative' }}
                  className="explore__event-item-row2-img"
                  onClick={() => toPublishActivities(todaysActivity._id)}
                >
                  <div className="explore__event-item-row2-img-last">
                    <div className="explore__event-item-row2-img-last-text">
                      {moment(todaysActivity?.startDate).format('DD/MM/YYYY')}
                    </div>
                  </div>
                  <div
                    className="user__event-item-row2"
                    onClick={(e) => {
                      e.stopPropagation()
                      setIsOpenModal(true)
                      setCurrentItem(todaysActivity)
                    }}
                  >
                    <IconServicesAllPoint />
                  </div>
                  <img className="posts__bg" src={`/Images/postsBG.png`} alt="" />
                  <div className="explore__event-item-row2-img-last-bottom">
                    <div className="explore__event-item-row2-img-last-bottom-text">
                      <span
                        className="admin__posts-list-row1-text"
                        style={{ color: 'rgba(21, 16, 77, 1)' }}
                      >
                        {todaysActivity.activitiesId.name}
                      </span>{' '}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="user__event-item-row3">
              <h6>{todaysActivity.text}</h6>
              {/* <div className="user__event-item-row3-button">
                                     {item.startDate.toString().split(".")[0].split("T")[0].replaceAll("-", "/") + " " }{ <b>{item.startDate.toString().split(".")[0].split("T")[1].split(":")[0] + ":" + item.startDate.toString().split(".")[0].split("T")[1].split(":")[1]}</b> }
                                 </div> */}
            </div>
            <div className="user__event-item-row4">
              <PublicationEventPanel
                item={todaysActivity}
                updateLike={() =>
                  updateLikeEvent(todaysActivity.likes, todaysActivity._id)
                }
                navigateToComments={() => toPublishActivities(todaysActivity._id)}
                updateMark={() =>
                  updateMarkEvent(todaysActivity._id, todaysActivity.isMarked)
                }
                updateRepost={() => copyLink(todaysActivity._id)}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              />
              {/* {<button onClick={() => updateLikeEvent(item.likeId, item._id)}>
                                 {item.isLiked ? <IconPostsRedLike /> : <IconPostsLike />}
     
                             </button>}
                             <div onClick={()=>updateMarkEvent(item._id, item.isMarked)} className={item.isMarked ? "user__event-item-row4-bookmark-active" :"user__event-item-row4-bookmark"}>
                                 <IconProfileInfoBookmark />
                             </div> */}
            </div>
          </div>
          <button
            style={{ width: '100%' }}
            className="user__services-last-button user__services-last-button"
            onClick={() => navigate(`/user/activities/user-last-publish-activities`)}
          >
            View all
          </button>
        </div>
      )}
      {(isOpenPublish || isOpenModal) && <DarkBg />}
      {isOpenModal && currentItem?.userId._id === _id && currentItem && (
        <ActivitiesMyModal
          setIsOpen={setIsOpenModal}
          isOpen={isOpenModal}
          item={currentItem}
          type="activity"
          updateNotification={() => updateNotification(currentItem._id)}
        />
      )}
      {isOpenModal && currentItem?.userId._id !== _id && currentItem && (
        <ActivitiesNeibModalMany
          setIsOpen={setIsOpenModal}
          isOpen={isOpenModal}
          item={currentItem}
          type="activity"
          updateNotification={() => updateNotification(currentItem._id)}
          setPublishService={setCurrentItem}
        />
      )}
      {isOpenSave && (
        <TempNotification setIsOpen={setIsOpenSave} isOpen={isOpenSave}>
          <IconBookMark />
        </TempNotification>
      )}
      {isOpenPublish && setIsOpenPublish && (
        <PublishModalRoute isOpen={isOpenPublish} setIsOpen={setIsOpenPublish} />
      )}
    </>
  )
}
