import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { apiBaseURL } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { isNotShowFooterNavUser } from '../../../utils/titles'
import { IconAdminImage } from '../../svg/IconAdminHeader'
import {
  IconFooterNavActivities,
  IconFooterNavExplore,
  IconFooterNavHome,
  IconFooterNavServices,
} from '../../svg/IconFooterNav'

export const FooterNav = () => {
  const [active, setActive] = useState(1)
  const navigate = useNavigate()
  const location = useLocation()
  const { _id, email, role, fullName, avatarFileName } = useAppSelector(
    (state) => state.user,
  )

  const toProfile = () => {
    navigate('/profileinfo', {
      state: {
        _id,
        email,
        role,
        fullName,
        avatarFileName,
        url: location.pathname, //FIXME seems as it should be -1
      },
    })
  }

  useEffect(() => {
    //TODO change to react-router-dom Link with active
    const path = location.pathname
    if (path.includes('service')) {
      setActive(3)
    } else if (path.includes('explore')) {
      setActive(2)
    } else if (path.includes('activities')) {
      setActive(4)
    } else if (path.includes('profileinfo')) {
      setActive(5)
    } else {
      setActive(1)
    }
  }, [location])

  return (
    <div
      className="user__footer"
      style={{
        display: `${isNotShowFooterNavUser(location.pathname) ? 'grid' : 'none'}`,
      }}
    >
      <div className={`user__footer--border user__footer--border-${active}`} />
      <button
        className={active === 1 ? 'user__footer--active' : ''}
        onClick={() => {
          setActive(1)
          navigate('/user')
        }}
      >
        <IconFooterNavHome />
        <p>Newsfeed</p>
      </button>
      <button
        className={active === 2 ? 'user__footer--active' : ''}
        onClick={() => {
          setActive(2)
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              (pos) => {
                navigate('/user/explore', {
                  state: [pos.coords.latitude, pos.coords.longitude],
                })
              },
              () => console.log('Cannot get user location'),
            )
          }
        }}
      >
        <IconFooterNavExplore />
        <p>Explore</p>
      </button>
      <button
        className={active === 3 ? 'user__footer--active' : ''}
        onClick={() => {
          setActive(3)
          navigate('/user/service')
        }}
      >
        <IconFooterNavServices />
        <p>Service</p>
      </button>
      <button
        className={active === 4 ? 'user__footer--active' : ''}
        onClick={() => {
          setActive(4)
          navigate('/user/activities')
        }}
      >
        <IconFooterNavActivities />
        <p>Activities</p>
      </button>
      <button
        className={active === 5 ? 'user__footer--active' : ''}
        onClick={() => {
          setActive(5)
          navigate('/profileinfo')
        }}
      >
        <div className="user__footer-profile" onClick={toProfile}>
          {avatarFileName ? (
            <img src={`${apiBaseURL}/uploads/avatar/${avatarFileName}`} alt="" />
          ) : (
            <IconAdminImage />
          )}
        </div>

        <p>Profile</p>
      </button>
    </div>
  )
}
