import { useEffect, useState } from 'react'
import { Circle, MapContainer, Polygon, TileLayer } from 'react-leaflet'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../utils/hooks'
import { MapUserMarker } from '../../ui/MapUserMarker'
import { inside } from '../../../utils/inside'

export const ExploreMiniMap = () => {
  const { radiusSize, coordinates, polygonCoords } = useAppSelector(
    (state) => state.profile,
  )
  const { avatarFileName, fullName } = useAppSelector((state) => state.user)
  const [show, setShow] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    if (polygonCoords && inside([coordinates.lat, coordinates.lng], polygonCoords)) {
      setShow(true)
    }
  }, [coordinates])

  return (
    <div className="explore__mini">
      {show && (
        <MapContainer
          dragging={false}
          tap={false}
          zoomControl={false}
          center={coordinates}
          zoom={8}
          maxZoom={8}
          minZoom={8}
          scrollWheelZoom={true}
        >
          <>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
              url="https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png"
              subdomains="abcd"
            />
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
              url="https://{s}.basemaps.cartocdn.com/dark_only_labels/{z}/{x}/{y}{r}.png"
              subdomains="abcd"
            />
          </>
          <MapUserMarker
            fullName={fullName}
            avatarFileName={avatarFileName}
            coords={coordinates}
          />
          {!polygonCoords && radiusSize && (
            <Circle center={coordinates} radius={radiusSize * 1000} />
          )}
          {polygonCoords && <Polygon positions={polygonCoords} />}
        </MapContainer>
      )}
      <div className="explore__mini-btn" onClick={() => navigate('map')}>
        Explore Map
      </div>
    </div>
  )
}
