import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Advertise } from '../../../types/types'
import { indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconDelete } from '../../svg/IconDelete'
import { IconEdit } from '../../svg/IconEdit'
import { IconProfileInfoBookmarkCopy } from '../../svg/IconProfileInfo'
import { IosTougle } from '../../ui/IosTougle'
import { Modal } from '../../ui/Modal'

export const ActiveModal = ({
  isOpen,
  setIsOpen,
  item,
  active,
  ads,
  setAds,
}: {
  isOpen: boolean
  setIsOpen: (s: boolean) => void
  item: Advertise
  active?: boolean
  ads: Advertise[]
  setAds: (s: Advertise[]) => void
}) => {
  const [isTougle, setIsTougle] = useState(false)
  const navigate = useNavigate()
  const { role } = useAppSelector((state) => state.user)
  const toAdvertiseActive = (_id: string) => {
    navigate(`${indexPathByRole(role)}/advertisement/active?adId=${_id}`, {
      state: { adId: _id },
    })
  }
  const toAdvertisePast = (_id: string) => {
    navigate(`${indexPathByRole(role)}/advertisement/past?adId=${_id}`, {
      state: { adId: _id },
    })
  }
  return (
    <>
      <div
        className={`activities__favor-modal  ${
          isOpen ? 'activities__favor-modal--open ' : ''
        }`}
      >
        <Modal setIsOpen={setIsOpen} className={''}>
          <div
            onClick={(e) => {
              e.stopPropagation()
              setIsOpen(false)
            }}
            className="activities__favor-modal-linebody"
          >
            <button className="activities__favor-modal-line" />
          </div>
          <div style={{ margin: '20px' }}>
            <div className="post__modal_itembg">
              <div
                className="post__modal_item"
                style={{ position: 'relative' }}
                onClick={() => {}}
              >
                <div>
                  <h5 className="post__modal_title">{item.title}</h5>
                  <h6 className="post__modal-subtitle">Turn on/off campaign</h6>
                </div>
                <div style={{ position: 'absolute', right: '0', top: '0px' }}>
                  <IosTougle
                    isTougle={isTougle}
                    setIsTougle={() => setIsTougle(!isTougle)}
                  />
                </div>
              </div>
            </div>
            <div className="post__modal_itembg" style={{ margin: '10px 0' }}>
              <div
                className="post__modal_item"
                onClick={() => {
                  if (active) {
                    toAdvertiseActive(item._id)
                  } else {
                    toAdvertisePast(item._id)
                  }
                }}
              >
                <div style={{ padding: '0 5px' }}>
                  <IconEdit />
                </div>
                <div>
                  <h5 className="post__modal_title">Edit</h5>
                </div>
              </div>
              <div
                className="post__modal_item"
                onClick={() => {
                  if (active) {
                    toAdvertiseActive(item._id)
                  } else {
                    toAdvertisePast(item._id)
                  }
                }}
              >
                <div style={{ padding: '0 0px' }}>
                  <IconProfileInfoBookmarkCopy fill="#000" />
                </div>
                <div>
                  <h5 className="post__modal_title">Statistics</h5>
                </div>
              </div>
              <div
                className="post__modal_item"
                onClick={() => {
                  const array = [...ads]
                  const index = array.indexOf(item)
                  if (index !== -1) {
                    array.splice(index, 1)
                    setAds(array)
                    setIsOpen(false)
                  }
                }}
              >
                <div style={{ padding: '0 5px' }}>
                  <IconDelete fill="#000" />
                </div>
                <div>
                  <h5 className="post__modal_title">Delete</h5>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  )
}
