import { useEffect } from 'react'
import {
  Route,
  Routes,
  useLocation,
  createBrowserRouter,
  PathRouteProps,
  useNavigate,
} from 'react-router-dom'
import { UserInitialStateInterface } from '../../../redux/users'
import {
  UserPublishEvent,
  allActivities,
  getEventsWithMark,
} from '../../../redux/activities'
import { UserIdentityInterface, getIdentity } from '../../../redux/profile'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { ProfileInfoAboutNH } from './ProfileInfoAboutNH'
import { ProfileInfoBookMark } from './ProfileInfoBookMark'
import { ProfileInfoEdit } from './ProfileInfoEdit'
import { ProfileInfoHelpCenter } from './ProfileInfoHelpCenter'
import { ProfileInfoNotification } from './ProfileInfoNotification'
import { ProfileInfoPassword } from './ProfileInfoPassword'
import { ProfileInfoPrivacy } from './ProfileInfoPrivacy'
import { ProfileInfoPrivacyPolicy } from './ProfileInfoPrivacyPolicy'
import { ProfileInfoSecurity } from './ProfileInfoSecurity'
import { ProfileInfoSkillsInterests } from './ProfileInfoSkillsInterests'
import { ProfileInfoTermsOfService } from './ProfileInfoTermsOfService'
import { ProfileSettings } from './ProfileSettings'
import { Overview } from './Overview/Overview'
import { Flex, Layout } from 'antd'
import { IoChevronBackOutline } from 'react-icons/io5'
import { PiDotsThreeOutlineFill } from 'react-icons/pi'

const { Header, Content, Footer } = Layout

export interface UserItem extends UserInitialStateInterface {
  userIdentity: UserIdentityInterface
}

export interface ProfileRouteProps {
  headerTitle?: string
  backPath?: string //| number
}

const profileRoutes: (PathRouteProps & ProfileRouteProps)[] = [
  {
    path: 'settings',
    element: <ProfileSettings />,
    headerTitle: 'Settings',
    backPath: '/profileinfo',
  },
  { path: 'edit', element: <ProfileInfoEdit />, headerTitle: 'Edit Profile' },
  {
    path: 'aboutneightborharbor',
    element: <ProfileInfoAboutNH />,
    headerTitle: 'About NeightborHarbor',
  },
  {
    path: 'privacypolicy',
    element: <ProfileInfoPrivacyPolicy />,
    headerTitle: 'Privacy Policy',
  },
  {
    path: 'termsofservice',
    element: <ProfileInfoTermsOfService />,
    headerTitle: 'Terms of Service',
  },
  {
    path: 'aboutneightborharbor',
    element: <ProfileInfoAboutNH />,
    headerTitle: 'About NeightborHarbor',
  },
  {
    path: 'helpsupport',
    element: <ProfileInfoHelpCenter />,
    headerTitle: 'Help & Support',
  },
  { path: 'privacy', element: <ProfileInfoPrivacy />, headerTitle: 'Privacy' },
  {
    path: 'notifications',
    element: <ProfileInfoNotification />,
    headerTitle: 'Notifications',
  },
  {
    path: 'interestsskills',
    element: <ProfileInfoSkillsInterests />,
    headerTitle: 'Interests & Skills',
  },
  { path: 'bookmark', element: <ProfileInfoBookMark />, headerTitle: 'Bookmark' },
  { path: 'security', element: <ProfileInfoSecurity />, headerTitle: 'Security' },
  { path: 'password', element: <ProfileInfoPassword />, headerTitle: 'Edit Profile' },
  { path: '*', element: <Overview /> },
]
export const ProfileInfo = () => {
  const location = useLocation()
  const props: UserItem = location.state
  const dispatch = useAppDispatch()
  const { id: userId, role } = useAppSelector((state) => state.userNew)
  const navigate = useNavigate()
  const currentRoute = profileRoutes.find(
    (route) => route.path && location.pathname.endsWith(route.path),
  )

  useEffect(() => {
    dispatch(allActivities())
    // dispatch(UserPublishEvent({ userId: props?._id || userId }))
    // dispatch(getPostsByUserId({ userId: props?._id || userId }))
    // dispatch(getEventsWithMark({ userId: userId }))
    // dispatch(getPostsWithMark({ marckedUserId: userId }))
    // dispatch(getServicesWithMark({ userId: userId }))
    // dispatch(getUserPublishService({ userId: props?._id }))
    // dispatch(getUserPublishServiceReview({ ownerId: props?._id || userId }))
  }, [])

  return (
    <>
      <Layout className="profileinfo">
        <Header>
          <Flex align="center" justify="space-between">
            <Flex align="center">
              <IoChevronBackOutline
                size={25}
                style={{ marginRight: '1em' }}
                onClick={() => navigate(-1)}
              />
              {currentRoute?.headerTitle}
            </Flex>
            <PiDotsThreeOutlineFill
              size={25}
              onClick={() => navigate('/profileinfo/settings')}
            />
          </Flex>
        </Header>
        <Content className="profileinfo__edit">
          <Routes>
            {profileRoutes.map((route) => (
              <Route key={route.path} path={route.path} element={route.element} />
            ))}
          </Routes>
        </Content>
      </Layout>
    </>
  )
}
