import { Unstable_NumberInput as NumberInput } from '@mui/base/Unstable_NumberInput'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconDollar } from '../../svg/IconDollar'
import { IconMaster } from '../../svg/IconMaster'
import { IconPlus } from '../../svg/IconPlus'
import { IconVisa } from '../../svg/IconVisa'
import { CARD, ROLE } from '../../../types/enum'
import { BankCard } from '../../../types/types'
import { StripeHttp } from '../../../http/stripe-http'
import { Loader } from '../../ui/Loader'

export const AdvertisementRequestMoney = () => {
  const navigate = useNavigate()
  const [value, setValue] = useState<number>(10)
  const { role, _id } = useAppSelector((state) => state.user)
  const [cards, setCards] = useState<BankCard[]>()

  useEffect(() => {
    const body = async () => {
      const res: BankCard[] = await StripeHttp.getCards({ userId: _id })
      setCards(res)
    }
    body()
  }, [])

  const validate = !Boolean()
  return (
    <>
      {cards ? (
        <div className="advertisement__add">
          {/* <div className="advertisement__add-header">
                <div className="advertisement__add-header-btn" onClick={()=>navigate(-1)}>
                    <IconLeftChevrons fill="rgba(21, 16, 77, 1)"/>
                    <span style={{textTransform:"capitalize"}}>Request Money</span>
                </div>
            </div> */}
          {role !== ROLE.USER && (
            <>
              <h5
                className="advertisement__add-main-title-bold"
                onClick={() =>
                  navigate(`${indexPathByRole(role)}/advertisement/transactions`)
                }
              >
                Payment Amount
              </h5>
              <div className="ui-input-search" style={{ margin: '20px 0' }}>
                {<IconDollar />}
                <div className="ui-input-search-container">
                  <NumberInput
                    aria-label="Demo number input"
                    placeholder="1500.0"
                    value={value}
                    min={10}
                    max={10000}
                    onChange={(event, val) => {
                      if (val) {
                        if (val < 10) {
                          setValue(10)
                        } else {
                          setValue(val)
                        }
                        if (val > 10000) {
                          setValue(100000)
                        } else {
                          setValue(val)
                        }
                      }
                    }}
                  />
                </div>
              </div>
            </>
          )}
          {role !== ROLE.USER && (
            <h5 className="advertisement__add-main-title-bold">Choose card</h5>
          )}
          <div className="advertisement__add-cards">
            {cards.map((card) => (
              <div
                className={
                  'advertisement__add-cards-item advertisement__add-cards-item-active'
                }
              >
                {card.cardType === CARD.MASTER ? <IconMaster /> : <IconVisa />}
                <p>**** {`${card.cardNumber}`.slice(11, 15)}</p>
              </div>
            ))}
          </div>
          <div
            className="advertisement__add-cards-button"
            onClick={() =>
              navigate(`${indexPathByRole(role)}/advertisement/payment-method`)
            }
          >
            <IconPlus />
            Add new payment method
          </div>
          {role !== ROLE.USER && (
            <button
              className={`advertisement__add-main-btn 
                    ${validate && 'advertisement__add-main-btn-disabled'}
                    `}
              style={{ margin: '60px 0' }}
              onClick={() => {}}
              disabled={validate}
            >
              Continue
            </button>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </>
  )
}
