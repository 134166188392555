import { useEffect, useRef } from 'react'
import { Circle, MapContainer, TileLayer } from 'react-leaflet'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../utils/hooks'
import { ProfileButtonSetupLater } from './ProfileButtonSetupLater'
import { StandaloneScreenLayout } from '../../ui/Layout/StandaloneScreenLayout'

export const ProfileInterestZone = () => {
  const containerMap = useRef<HTMLDivElement | null>(null)

  const { coordinates, polygonCoords } = useAppSelector((state) => state.profile)
  const navigate = useNavigate()

  return (
    <>
      <StandaloneScreenLayout
        title="Interests"
        subTitle="Discover like-minded individuals who share similar interests"
        secondButtonTitle="DEV skip"
        onSecondButtonClicked={() => {
          navigate('/profile/privacy') //stepDefinitions[PROFILE_STEP.PRIVACY].path)
        }}
      >
        <div className="profile__method-body">
          <div className="profile__zone-body">
            {/* <div style={mapContainerStyle} ref={containerMap} /> */}
            <div className="profile__zone-preview">
              {
                <MapContainer
                  dragging={false}
                  zoomControl={false}
                  center={coordinates}
                  minZoom={8}
                  maxZoom={8}
                  zoom={8}
                  scrollWheelZoom={false}
                >
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                    url="https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png"
                    subdomains="abcd"
                  />
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                    url="https://{s}.basemaps.cartocdn.com/dark_only_labels/{z}/{x}/{y}{r}.png"
                    subdomains="abcd"
                  />
                  {coordinates && <Circle center={coordinates} radius={5000} />}
                </MapContainer>
              }
            </div>
            <button
              className="profile__zone-button"
              onClick={() => navigate('/profile/interest-zone/setup')}
            >
              {'Setup Interest Zone'}
            </button>
          </div>
        </div>
      </StandaloneScreenLayout>
    </>
  )
}
