import { LatLng } from 'leaflet'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserHttp } from '../../../http/user-http'
import { UserInitialStateInterface } from '../../../redux/users'
import { useAppSelector } from '../../../utils/hooks'
import { InputSearch } from '../../ui/InputSearch'
import { success } from '../../ui/LoadSuccess'
import { Modal } from '../../ui/Modal'

export const AddLocalAdmin = ({
  setIsOpen,
  isOpen,
  regionalId,
  polygonCoords,
}: {
  setIsOpen: (s: boolean) => void
  isOpen: boolean
  regionalId: string
  polygonCoords: LatLng[] | LatLng[][] | LatLng[][][]
}) => {
  const navigate = useNavigate()
  const [search, setSearch] = useState<string>('')
  const [users, setUsers] = useState<UserInitialStateInterface[]>([])
  const [usersNormal, setUsersNormal] = useState<UserInitialStateInterface[]>([])
  const [ownerId, setOwnerId] = useState<UserInitialStateInterface | null>()
  const { _id } = useAppSelector((state) => state.user)
  const validate = !Boolean(ownerId && regionalId && polygonCoords)
  useEffect(() => {
    UserHttp.getAllUsers().then((res) => {
      res.filter((item) => item._id !== _id)
      setUsers(res)
      console.log(res)
    })
  }, [])
  useEffect(() => {
    let array: UserInitialStateInterface[] = []

    if (search.replace('@', '').length && !ownerId) {
      users
        // .filter((it)=>it.role===ROLES.LOCAL_ADMIN)
        .map((item) => {
          if (
            item.fullName.toLowerCase().includes(search.replace('@', '').toLowerCase())
          ) {
            array.push(item)
          }
        })
    }
    setUsersNormal(array)
  }, [search])
  const addLocalAdmin = async () => {
    try {
      if (ownerId && polygonCoords) {
        await UserHttp.addLocalAdminInfo({
          userId: ownerId._id,
          polygonCoords,
          regionalId,
        })
      }

      window.location.reload()
      navigate(-1)
      success()
    } catch (error) {
      console.log(`New server error + ${error}`)
    }
  }
  return (
    <div
      className={`activities__favor-modal  ${
        isOpen ? 'activities__favor-modal--open' : ''
      }`}
    >
      <Modal className="" setIsOpen={setIsOpen}>
        <></>
        <div
          onClick={(e) => {
            e.stopPropagation()
            setIsOpen(false)
          }}
          className="activities__favor-modal-linebody"
        >
          <button className="activities__favor-modal-line" />
        </div>
        <div className="location__modal">
          <h5 style={{ margin: '0 5%' }} className="advertisement__add-main-title">
            Set local admin:{' '}
            {ownerId ? (
              <span
                onClick={() => setOwnerId(null)}
                style={{ color: 'rgba(51, 97, 255, 1)' }}
              >
                @{ownerId.fullName}
              </span>
            ) : (
              ''
            )}
          </h5>
          {!ownerId && (
            <>
              <InputSearch
                value={search}
                changeValue={setSearch}
                placeholder={'@ Username'}
              />
              <div className="advertisement__add-main-results">
                {usersNormal
                  .filter((it) => it._id !== _id)
                  .map((item) => (
                    <span
                      onClick={() => {
                        setOwnerId(item)
                        setSearch(item.fullName)
                      }}
                    >
                      {item.fullName}
                    </span>
                  ))}
              </div>
            </>
          )}
          <button
            style={{ maxWidth: '90%', margin: '50px 5%' }}
            className={`advertisement__add-main-btn ${
              validate && 'advertisement__add-main-btn-disabled'
            }`}
            onClick={addLocalAdmin}
            disabled={validate}
          >
            Continue
          </button>
        </div>
      </Modal>
    </div>
  )
}
