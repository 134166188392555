import { renderToString } from 'react-dom/server'
import {
  PostUserInterface,
  PublishActivitiesOneItemInterface,
  PublishServiceItemInterface,
} from '../../types/types'
import L from 'leaflet'
import { IconMapPost } from '../svg/IconMapPost'
import { Marker } from 'react-leaflet'
import { apiBaseURL } from '../route/indexPathByRole'

export const MapPostMarker = ({
  item,
  type,
}: {
  item:
    | PublishServiceItemInterface
    | PublishActivitiesOneItemInterface
    | PostUserInterface
  type?: string
}) => {
  const markDivIcon = new L.DivIcon({
    html: renderToString(
      <div
        style={{
          borderRadius: '3px',
          padding: '3px',
          height: '42px',
          width: '42px',
          position: 'absolute',
          left: '-10px',
          top: '-22px',
        }}
      >
        <div className="image" style={{ backgroundColor: 'rgb(15, 20, 25)' }}>
          {type ? (
            <img
              style={{ margin: 'auto' }}
              src={
                item.filesName.length
                  ? `${apiBaseURL}/uploads/${type}/${item.filesName[0]}`
                  : '/Images/postsBG.png'
              }
            />
          ) : (
            <img
              style={{ margin: 'auto' }}
              src={
                item.filesName.length
                  ? `${apiBaseURL}/uploads/publish_post/${item.filesName[0]}`
                  : '/Images/postsBG.png'
              }
            />
          )}
        </div>
        <IconMapPost />
      </div>,
    ),
    iconSize: [32, 32],
    className: 'leaflet-div-icon',
  })
  return <Marker position={item.coordinates} icon={markDivIcon}></Marker>
}
