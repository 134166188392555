import { Rating, TextareaAutosize } from '@mui/material'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ServiceHttp } from '../../../http/service-http'
import { apiBaseURL } from '../../route/indexPathByRole'
import { IconStar } from '../../svg/IconStar'
import { IconStarEmpty } from '../../svg/IconStarEmpty'
import { UserHeaderUserChatList } from '../header/UserHeaderChatList'

export const UserPublishServicesAddReview = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const [text, setText] = useState<string>('')
  const [value, setValue] = useState<number>(0)

  const validate = !Boolean(text && value)

  const sendReview = async () => {
    ServiceHttp.addPublishServiceReview({
      serviceId: location.state.service?._id,
      userId: location.state.user._id,
      rate: value,
      text: text,
      ownerId: location.state.service?.userId._id,
      createdPublishServiceDate: new Date(),
    })
    navigate(-1)
  }

  return (
    <div className="review">
      <UserHeaderUserChatList headerTitle="Add Review" />
      <div className="review__main">
        <div className="review__main-title">
          <h6 className="user__publish-service-location-sub review__main-title-text">
            How did the service went?
          </h6>
          <h5 className="user__publish-service-location-cat review__main-title-sub">
            Let everybody know how did it go
          </h5>
        </div>
        <div className="review__main-user">
          <div className="review__main-user-img">
            {location.state.user.avatarFileName ? (
              <img
                src={`${apiBaseURL}/uploads/avatar/${location.state.user.avatarFileName}`}
              />
            ) : (
              <div
                style={{
                  backgroundColor: 'rgba(41, 52, 65, 1)',
                  borderRadius: '100%',
                  width: '60px',
                  height: '60px',
                  color: '#fff',
                  textAlign: 'center',
                  position: 'relative',
                }}
              >
                <span style={{ position: 'absolute', left: '18px', top: '20px' }}>
                  {location.state.user.fullName.slice(0, 2)}
                </span>
              </div>
            )}
          </div>
          <div>
            <p className="review__main-user-name">
              <b>{location.state.user?.fullName}</b>
            </p>
          </div>
          <div className="review__main-rating">
            <Rating
              name="hover-feedback"
              value={value}
              precision={0.5}
              onChange={(event, newValue) => {
                newValue && setValue(newValue)
              }}
              emptyIcon={<IconStarEmpty />}
              icon={<IconStar />}
            />
          </div>
        </div>
        <div className="review__main-field">
          <TextareaAutosize
            value={text}
            onChange={(e) => setText(e.target.value)}
            className="review__main-field-input"
            minRows={1}
            placeholder={`Please write your honest review`}
          />
        </div>
        <button
          className={`publish__publish user--footer--button review__main-button ${
            validate && 'services__add-button--disabled'
          }`}
          onClick={sendReview}
          disabled={validate}
        >
          Submit
        </button>
      </div>
    </div>
  )
}
