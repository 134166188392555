export const IconMapPost = ({ fill }: { fill?: string }) => {
  return (
    <svg
      width="24"
      height="28"
      viewBox="0 0 24 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.08021 3.48036C8.45408 -0.893516 15.5455 -0.893517 19.9194 3.48036C24.2933 7.85423 24.2933 14.9457 19.9194 19.3195L11.9998 27.2391L4.08021 19.3195C-0.293664 14.9457 -0.293664 7.85423 4.08021 3.48036ZM11.9998 14.6C13.7671 14.6 15.1998 13.1673 15.1998 11.4C15.1998 9.63264 13.7671 8.19995 11.9998 8.19995C10.2325 8.19995 8.7998 9.63264 8.7998 11.4C8.7998 13.1673 10.2325 14.6 11.9998 14.6Z"
        fill={fill ? fill : '#039162'}
      />
    </svg>
  )
}
