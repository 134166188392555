import moment from 'moment'
import { useEffect, useState } from 'react'
import CsvDownload from 'react-csv-downloader'
import { Datas } from 'react-csv-downloader/dist/esm/lib/csv'
import { GetShortcutsResponse, StatisticHttp } from '../../../http/statistic-http'
import { IconAdminClose } from '../../svg/IconAdminHeader'
import { IconBottomChevrons } from '../../svg/IconChevrons'
import { SlickCategories } from '../../ui/SlickCategories'
import {
  AdminPanelStatisticBar1,
  CountUserOneDayInterface,
} from './AdminPanelStatisticBar1'

const dataMock = [
  {
    name: 'All Users',
    value: 0,
    fill: '#FF6633',
  },
  {
    name: 'New Users',
    value: 0,
    fill: '#3361FF',
  },
  {
    name: 'Active Users',
    value: 0,
    fill: '#8833FF',
  },
  {
    name: 'Non Active Users',
    value: 0,
    fill: '#2EE6CA',
  },
]
const dataMockPublications = [
  {
    name: 'Activities',
    value: 0,
    fill: '#FF6633',
  },
  {
    name: 'Posts',
    value: 0,
    fill: '#3361FF',
  },
  {
    name: 'Services',
    value: 0,
    fill: '#8833FF',
  },
]
const dataMockOther = [
  {
    name: 'Messages',
    value: 0,
    fill: '#FF6633',
  },
  {
    name: 'Advertisement',
    value: 0,
    fill: '#3361FF',
  },
]
const columns = [
  {
    id: 'All Users',
    displayName: 'All Users',
  },
  {
    id: 'New Users',
    displayName: 'New Users',
  },
  {
    id: 'Active Users',
    displayName: 'Active Users',
  },
  {
    id: 'Non Active Users',
    displayName: 'Non Active Users',
  },
]
const dataMockNormal: Datas = [
  {
    'All Users': `${0}`,
  },
  {
    'All Users': `${0}`,
  },
  {
    'All Users': `${0}`,
  },
  {
    'All Users': `${0}`,
  },
]

export const AdminPanelStatistic = () => {
  const [item, setItem] = useState(1)
  const handleClick = (it: number) => {
    if (it === item) {
      setItem(0)
    } else {
      setItem(it)
    }
  }

  const [data, setData] = useState(dataMock)
  const [dataNormal, setDataNormal] = useState<Datas>(dataMockNormal)

  const [dataPublication, setDataPublication] = useState(dataMockPublications)
  const [dataPublicationNormal, setDataPublicationNormal] =
    useState<Datas>(dataMockNormal)

  const [dataOther, setDataOther] = useState(dataMockOther)
  const [dataOtherNormal, setDataOtherNormal] = useState<Datas>(dataMockNormal)

  useEffect(() => {
    const effectBody = async () => {
      const res: CountUserOneDayInterface[] = await StatisticHttp.getStatisticUsersOne()
      const dataServer = [
        res[0]?.totalUsers || 0,
        res[0]?.newUsers || 0,
        res[0]?.activeUsers || 0,
        res[0]?.nonActiveUsers || 0,
      ].map((item, index) => ({ ...dataMock[index], value: item }))

      setData(dataServer)
    }
    effectBody()
  }, [])
  useEffect(() => {
    const effectBody = async () => {
      const res: GetShortcutsResponse = await StatisticHttp.getShortcuts()
      const dataServer = [res.countActivities, res.countPosts, res.countServices].map(
        (item, index) => ({ ...dataMockPublications[index], value: item }),
      )
      setDataPublication(dataServer)
      const dataO = [res.countAds, res.countMessages].map((item, index) => ({
        ...dataMockOther[index],
        value: item,
      }))
      setDataOther(dataO)
    }
    effectBody()
  }, [])
  useEffect(() => {
    if (dataOther) {
      let arr: Datas = []
      dataOther.map((item) => {
        arr.push({
          [`${item.name}`]: `${item.value}`,
        })
      })
      setDataOtherNormal(arr)
    }
  }, [dataOther])
  useEffect(() => {
    if (dataPublication) {
      let arr: Datas = []
      dataPublication.map((item) => {
        arr.push({
          [`${item.name}`]: `${item.value}`,
        })
      })
      setDataPublicationNormal(arr)
    }
  }, [dataPublication])
  useEffect(() => {
    if (data) {
      let arr: Datas = []
      data.map((item) => {
        arr.push({
          [`${item.name}`]: `${item.value}`,
        })
      })
      setDataNormal(arr)
    }
  }, [data])
  const categories = ['', 'User', 'Public', 'Data']
  return (
    <div className="admin__panel-events">
      <div className="admin__panel-title"> Statistics </div>
      <SlickCategories>
        {[1, 2, 3].map((it) => (
          <div>
            <div className="admin__panel-events-filter" onClick={() => handleClick(it)}>
              {categories[it]}
              {item === it ? <IconAdminClose /> : <IconBottomChevrons />}
            </div>
          </div>
        ))}
      </SlickCategories>

      {item === 1 && (
        <div className="admin__panel__statistic-body">
          <div className="admin__panel__statistic-title">
            <h6 className="admin__panel__statistic-title-main">User Stat</h6>
            {/* <button className="admin__panel__statistic-title-download">
                        Download
                    </button> */}
            <CsvDownload
              filename={`user_stat(${moment(new Date()).format('DD_MM_YYYY')})`}
              datas={dataNormal}
              extension=".csv"
              className="admin__panel__statistic-title-download"
              bom={false}
              separator=";"
              columns={columns}
              wrapColumnChar={''}
              chunkSize={1}
              newLineAtEnd={true}
            >
              Download
            </CsvDownload>
          </div>
          <AdminPanelStatisticBar1 />
        </div>
      )}

      {item === 2 && (
        <div className="admin__panel__statistic-body">
          <div className="admin__panel__statistic-title">
            <h6 className="admin__panel__statistic-title-main">Publication Stat</h6>
            <CsvDownload
              filename={`publication_stat(${moment(new Date()).format('DD_MM_YYYY')})`}
              datas={dataPublicationNormal}
              extension=".csv"
              className="admin__panel__statistic-title-download"
              bom={false}
              separator=";"
              wrapColumnChar={''}
              chunkSize={1}
              newLineAtEnd={true}
            >
              Download
            </CsvDownload>
          </div>
          <AdminPanelStatisticBar1 dataRender={dataPublication} />
        </div>
      )}

      {item === 3 && (
        <div className="admin__panel__statistic-body">
          <div className="admin__panel__statistic-title">
            <h6 className="admin__panel__statistic-title-main">Data Stat</h6>
            <CsvDownload
              filename={`data_stat(${moment(new Date()).format('DD_MM_YYYY')})`}
              datas={dataOtherNormal}
              extension=".csv"
              className="admin__panel__statistic-title-download"
              bom={false}
              separator=";"
              wrapColumnChar={''}
              chunkSize={1}
              newLineAtEnd={true}
            >
              Download
            </CsvDownload>
          </div>
          <AdminPanelStatisticBar1 dataRender={dataOther} />
        </div>
      )}
    </div>
  )
}
