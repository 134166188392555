import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ServiceHttp } from '../../../http/service-http'
import { CategoriesItemInterface } from '../../../redux/categories'
import { apiBaseURL } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconLeftChevrons } from '../../svg/IconChevrons'
import { UserHeader } from '../../user-components/header/UserHeader'

export const PublishSelectCategory = () => {
  const settingsSecond = {
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    className: 'user__services-category-slick-ins',
  }
  const navigate = useNavigate()
  const location = useLocation()
  const { publishServices } = useAppSelector((state) => state.categories)
  const [categories, setCategories] = useState<CategoriesItemInterface[]>([])
  useEffect(() => {
    const EffectBody = async () => {
      const res: CategoriesItemInterface[] = await ServiceHttp.getAllService()
      setCategories(res)
    }
    EffectBody()
  }, [])
  const calculateServices = (category: CategoriesItemInterface) => {
    let count = 0
    publishServices.find((item) => {
      if (item?.categoryId?._id === category?._id) {
        count++
      }
    })
    return count
  }

  return (
    <div className="">
      <UserHeader>
        <div className="profileinfo__header">
          <button
            className="profileinfo__header-chevron"
            onClick={() => {
              navigate(-1)
            }}
          >
            <IconLeftChevrons />
          </button>
          <h5 className="profileinfo__header-text">Select Category</h5>
        </div>
      </UserHeader>
      <div className="user__category" style={{ margin: '120px 10px' }}>
        {categories.map((item) => (
          <div
            className="user__services-category-item-body"
            onClick={() =>
              navigate(`/publish/service/sub-category?id=${item._id}`, {
                state: { ...location.state, category: item },
              })
            }
          >
            <div className="user__services-category-item">
              <div className="user__services-category-item-img">
                <img src={`${apiBaseURL}/uploads/categories/${item.fileName}`} alt="" />
              </div>
              <div className="user__services-category-item-text">
                <b>{item.name}</b> <span>{calculateServices(item)}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
