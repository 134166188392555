import React from 'react'

export const IconPostsLike = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0636 5.98216L11.5745 5.4106C9.60661 3.44268 6.46827 3.57678 4.50035 5.5447C2.53243 7.51263 2.91116 11.3173 4.87908 13.2852C5.67778 14.0839 6.87388 15.1897 8.46738 16.6028L9.80797 17.7821L11.3137 19.0891C11.7283 19.447 12.3408 19.4524 12.7617 19.1021L13.9815 18.0794C16.1176 16.2741 17.7027 14.8522 18.7369 13.8137L19.0053 13.5399L19.2463 13.2852C21.0791 11.3088 21.5501 7.46686 19.6279 5.5447L19.4887 5.4106C17.5123 3.57785 14.532 3.48845 12.6098 5.4106L12.0636 5.98216ZM5.29218 6.33642C6.8483 4.7803 9.25569 4.70735 10.7538 6.1737L12.0177 7.65085L13.4194 6.18419C14.8571 4.74684 17.1485 4.76762 18.7273 6.2316L18.8511 6.35119C20.2094 7.70975 19.9783 10.8488 18.4252 12.5236L18.1919 12.7701L17.9331 13.034C17.0758 13.8944 15.8052 15.0471 14.13 16.4827L13.2587 17.224L12.0453 18.2413L10.542 16.9364L9.46609 15.9912C7.95 14.6513 6.78043 13.5795 5.96155 12.7802L5.67091 12.4932C4.04976 10.8721 3.84699 7.78161 5.29218 6.33642Z"
        fill="#C3CAD9"
      />
    </svg>
  )
}

export const IconPostsRedLike = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0636 5.98216L11.5745 5.4106C9.60661 3.44268 6.46827 3.57678 4.50035 5.5447C2.53243 7.51263 2.91116 11.3173 4.87908 13.2852C5.67778 14.0839 6.87388 15.1897 8.46738 16.6028L9.80797 17.7821L11.3137 19.0891C11.7283 19.447 12.3408 19.4524 12.7617 19.1021L13.9815 18.0794C16.1176 16.2741 17.7027 14.8522 18.7369 13.8137L19.0053 13.5399L19.2463 13.2852C21.0791 11.3088 21.5501 7.46686 19.6279 5.5447L19.4887 5.4106C17.5123 3.57785 14.532 3.48845 12.6098 5.4106L12.0636 5.98216ZM5.29218 6.33642C6.8483 4.7803 9.25569 4.70735 10.7538 6.1737L12.0177 7.65085L13.4194 6.18419C14.8571 4.74684 17.1485 4.76762 18.7273 6.2316L18.8511 6.35119C20.2094 7.70975 19.9783 10.8488 18.4252 12.5236L18.1919 12.7701L17.9331 13.034C17.0758 13.8944 15.8052 15.0471 14.13 16.4827L13.2587 17.224L12.0453 18.2413L10.542 16.9364L9.46609 15.9912C7.95 14.6513 6.78043 13.5795 5.96155 12.7802L5.67091 12.4932C4.04976 10.8721 3.84699 7.78161 5.29218 6.33642Z"
        fill="#E64B17"
      />
      <path
        d="M10.6733 5.89218C9.11912 4.37237 6.62157 4.44798 5.00718 6.06084C3.50788 7.55872 3.71823 10.7619 5.40009 12.4422L5.70161 12.7396C6.55117 13.5681 7.76453 14.6789 9.33739 16.0677L10.4536 17.0473L12.0132 18.3998L13.272 17.3454L14.1759 16.5771C15.9139 15.0891 17.232 13.8944 18.1215 13.0026L18.39 12.7291L18.632 12.4737C20.2433 10.7377 20.483 7.48424 19.0739 6.07615L18.9454 5.9522C17.3075 4.43484 14.9303 4.4133 13.4387 5.90306L11.9845 7.4232L10.6733 5.89218Z"
        fill="#E64B17"
        stroke="#E64B17"
      />
    </svg>
  )
}

export const IconPostsRepost = (props: { color?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M3.91 17.181C3.72 17.181 3.53 17.111 3.38 16.961C2.70492 16.2881 2.16927 15.4886 1.80376 14.6083C1.43826 13.728 1.25007 12.7842 1.25 11.831C1.25 7.821 4.5 4.561 8.5 4.561L14.57 4.581L13.48 3.541C13.4087 3.47297 13.3515 3.39147 13.3118 3.30121C13.2722 3.21096 13.2508 3.11374 13.2489 3.01517C13.2471 2.9166 13.2648 2.81865 13.301 2.72696C13.3372 2.63527 13.3913 2.55167 13.46 2.481C13.75 2.181 14.22 2.171 14.52 2.461L16.96 4.801C17.18 5.011 17.25 5.341 17.14 5.621C17.03 5.901 16.75 6.091 16.44 6.091L8.5 6.071C5.33 6.071 2.75 8.661 2.75 11.841C2.75 13.371 3.35 14.821 4.44 15.911C4.73 16.201 4.73 16.681 4.44 16.971C4.29 17.111 4.1 17.181 3.91 17.181ZM10 21.75C9.80603 21.7499 9.61966 21.6746 9.48 21.54L7.04 19.2C6.93277 19.0961 6.85851 18.9629 6.8265 18.8171C6.79449 18.6713 6.80614 18.5192 6.86 18.38C6.97 18.1 7.25 17.91 7.56 17.91L15.51 17.93C18.68 17.93 21.26 15.34 21.26 12.16C21.26 10.63 20.66 9.18 19.57 8.09C19.4305 7.94886 19.3523 7.75843 19.3523 7.56C19.3523 7.36157 19.4305 7.17114 19.57 7.03C19.86 6.74 20.34 6.74 20.63 7.03C21.3051 7.70291 21.8407 8.50242 22.2062 9.38273C22.5717 10.263 22.7599 11.2068 22.76 12.16C22.76 16.17 19.51 19.43 15.51 19.43L9.44 19.41L10.53 20.45C10.83 20.74 10.84 21.21 10.55 21.51C10.39 21.67 10.2 21.75 10 21.75Z"
        fill={props.color ? props.color : '#C3CAD9'}
      />
      <path
        d="M9 15.5H15C16.92 15.5 18.5 13.93 18.5 12C18.5 10.08 16.93 8.5 15 8.5H9C8.07255 8.50264 7.18385 8.87223 6.52804 9.52804C5.87223 10.1838 5.50264 11.0726 5.5 12C5.5 13.93 7.07 15.5 9 15.5Z"
        fill={props.color ? props.color : '#C3CAD9'}
      />
    </svg>
  )
}
