export const IconGoogle = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M20.6449 10.3123C20.7573 10.9148 20.8186 11.5447 20.8186 12.2021C20.8186 17.3439 17.3773 21 12.1797 21C10.9977 21.0004 9.82721 20.7678 8.73511 20.3157C7.64302 19.8635 6.65072 19.2006 5.81492 18.3648C4.97913 17.529 4.3162 16.5367 3.86404 15.4446C3.41187 14.3525 3.17933 13.182 3.17969 12C3.17933 10.818 3.41187 9.64752 3.86404 8.55543C4.3162 7.46333 4.97913 6.47104 5.81492 5.63524C6.65072 4.79944 7.64302 4.13652 8.73511 3.68435C9.82721 3.23219 10.9977 2.99964 12.1797 3C14.6098 3 16.6404 3.89415 18.1983 5.346L15.6612 7.88308V7.87668C14.7168 6.97704 13.5182 6.51534 12.1797 6.51534C9.21017 6.51534 6.79651 9.02408 6.79651 11.9945C6.79651 14.964 9.21017 17.4783 12.1797 17.4783C14.874 17.4783 16.708 15.9377 17.0847 13.8221H12.1797V10.3123H20.6458H20.6449Z"
        fill="#040504"
      />
    </svg>
  )
}
