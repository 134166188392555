import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ActivitiesHttp } from '../../../http/activities-http'
import { ROLE } from '../../../types/enum'
import { PublishEventItemInterface } from '../../../types/types'
import { indexPathByRole, apiBaseURL } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconPostModalHide } from '../../svg/IconPostModal'
import { IconPostsRepost } from '../../svg/IconPosts'
import { IconProfileInfoFlag, IconProfileInfoBookmark } from '../../svg/IconProfileInfo'
import { Modal } from '../../ui/Modal'

export const ActivitiesNeibModalMany = ({
  isOpen,
  setIsOpen,
  item,
  type,
  setPublishService,
  updateNotification,
}: {
  isOpen: boolean
  setIsOpen: (s: boolean) => void
  item: PublishEventItemInterface | null
  type: string
  setPublishService: (s: PublishEventItemInterface) => void
  updateNotification: (serviceId: string) => {}
}) => {
  const navigate = useNavigate()
  const [isOpenSave, setIsOpenSave] = useState(false)
  const { _id, role } = useAppSelector((state) => state.user)
  const toProfileInfo = (prop: {
    _id: string
    email: string
    role: string
    fullName: string
    avatarFileName: string
  }) => {
    navigate('/profileinfo', {
      state: prop,
    })
  }

  const copyLink = async () => {
    const text = window.location.href
    navigate(`${indexPathByRole(role)}/service/share-list`, { state: { text } })
  }

  const hideService = async () => {
    if (item) {
      await ActivitiesHttp.hideService({
        userId: _id,
        eventId: item?._id,
      })
      navigate(-1)
    }
  }

  const updateMark = async (eventId: string, isMarked: boolean) => {
    if (isMarked) {
      await ActivitiesHttp.deleteMark({
        eventId,
        marckedUserId: _id,
      })
    } else {
      await ActivitiesHttp.addMark({
        eventId,
        marckedUserId: _id,
      })
      setIsOpenSave(true)
    }

    if (item) {
      setPublishService({
        ...item,
        isMarked: !item.isMarked,
      })
    }
  }
  return (
    <div
      className={`activities__favor-modal  ${
        isOpen ? 'activities__favor-modal--open' : ''
      }`}
    >
      <Modal className="" setIsOpen={(s: boolean) => setIsOpen(s)}>
        <div
          onClick={(e) => {
            e.stopPropagation()
            setIsOpen(false)
          }}
          className="activities__favor-modal-linebody"
        >
          <button className="activities__favor-modal-line" />
        </div>
        <div className="publish__modaladd post__modal">
          {item ? (
            <div
              className="post__modal_itembg"
              onClick={() =>
                toProfileInfo({
                  _id: item?.userId?._id || '',
                  email: '',
                  role: ROLE.USER,
                  fullName: item?.userId?.fullName || '',
                  avatarFileName: item?.userId?.avatarFileName || '',
                })
              }
            >
              <div className="post__modal_item post__modal_item-img">
                <div>
                  {item.userId.avatarFileName ? (
                    <img
                      src={`${apiBaseURL}/uploads/avatar/${item.userId.avatarFileName}`}
                    />
                  ) : (
                    <div
                      style={{
                        backgroundColor: 'rgba(41, 52, 65, 1)',
                        borderRadius: '100%',
                        width: '100%',
                        height: '100%',
                        color: '#fff',
                        textAlign: 'center',
                        position: 'relative',
                      }}
                    >
                      <span style={{ position: 'absolute', left: '13px', top: '12px' }}>
                        {item?.userId?.fullName?.slice(0, 2)}
                      </span>
                    </div>
                  )}
                </div>
                <div>
                  <h5 className="post__modal_title">Connect</h5>
                  <h6 className="post__modal-subtitle">
                    Follow <b>{item?.userId.fullName}</b> activities
                  </h6>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}

          <div className="post__modal_itembg">
            <div className="post__modal_item" onClick={copyLink}>
              <div className={`post__modal_item-button-1 `}>
                <IconPostsRepost color="rgba(15, 20, 25, 1)" />
              </div>
              <div>
                <h5 className="post__modal_title">Share</h5>
                <h6 className="post__modal-subtitle">Share {type} to your neighbors</h6>
              </div>
            </div>

            <div
              className="post__modal_item"
              onClick={() => {
                if (item) updateNotification(item._id)
              }}
            >
              <div
                className={`post__modal_item-button-1 `}
                onClick={() => {
                  if (item) updateNotification(item._id)
                }}
              >
                <IconProfileInfoFlag />
              </div>
              <div>
                <h5 className="post__modal_title">Report</h5>
                <h6 className="post__modal-subtitle">Flag for review</h6>
              </div>
            </div>

            <div className="post__modal_item" onClick={hideService}>
              <div className="post__modal_item-button-1">
                <IconPostModalHide />
              </div>
              <div>
                <h5 className="post__modal_title">Hide</h5>
                <h6 className="post__modal-subtitle">Remove {type} from your feed</h6>
              </div>
            </div>

            <div
              className={`post__modal_item`}
              onClick={() => {
                if (item) updateMark(item?._id, item?.isMarked)
              }}
            >
              <div
                style={{ background: 'none', boxShadow: 'none' }}
                className={`post__modal_item ui-button-back-route 
                                 ${
                                   item?.isMarked &&
                                   'admin__posts-list-row4-repost--active'
                                 }
                             `}
              >
                <IconProfileInfoBookmark />
              </div>
              <div>
                <h5 className="post__modal_title">Bookmark</h5>
                <h6 className="post__modal-subtitle">Save {type} for later</h6>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}
