import { Link, useNavigate } from 'react-router-dom'
import { Modal } from './Modal'
import { DarkBg } from './DarkBg'
import { IconsNewsfeedPlus } from '../svg/IconsNewsfeed'
import {
  Activities,
  EventFilterType,
  NeibsFilterType,
  OptionsType,
  PostFilterType,
  ServiceFilterType,
} from '../../types/types'
import { CalendarIcon } from '@mui/x-date-pickers'
import { ReactElement, useEffect, useState } from 'react'
import { InputSearch } from './InputSearch'
import UiDateTimePicker, { UiDateTimePickerLocal } from './UiDataTimePicker'
import dayjs from 'dayjs'
import { IosTougle } from './IosTougle'
import { Unstable_NumberInput as NumberInput } from '@mui/base/Unstable_NumberInput'
import Slider from '@mui/material/Slider'
import $api from '../../http'
import { useAppSelector } from '../../utils/hooks'
import { IconLeftChevrons } from '../svg/IconChevrons'
import { apiBaseURL } from '../route/indexPathByRole'
import { IconStar } from '../svg/IconStar'
import { IconStarEmpty } from '../svg/IconStarEmpty'
import { IconProfileCircle, IconProfileCircleFilled } from '../svg/IconProfile'
import { Countries, EDUCATION, ORIENTATION, QUALITYENUM, SEX } from '../../types/enum'
import { toOneKind } from '../../utils/titles'
import { CityType } from '../admin-components/admin-panel/AdminPanelMapPage'
import CA from './../admin-components/admin-panel/ca.json'
import FR from './../admin-components/admin-panel/fr.json'
import UA from './../admin-components/admin-panel/ua.json'
import { polygon } from 'leaflet'
import { UserHttp } from '../../http/user-http'
import { UserInitialStateInterface } from '../../redux/users'

export const PostFilter = ({
  setIsOpen,
  isOpen,
  filter,
  setFilter = () => {},
}: {
  setIsOpen: (s: boolean) => void
  isOpen: boolean
  filter: PostFilterType
  setFilter: (s: PostFilterType) => void
}) => {
  const [when, setWhen] = useState(false)
  const [postedBy, setPostedBy] = useState(false)
  const [where, setWhere] = useState(false)
  const { _id } = useAppSelector((state) => state.user)
  const savePostFilter = async () => {
    await $api.post('search/update-post-filter', {
      filter,
      ownerId: _id,
    })
  }
  useEffect(() => {
    if (filter) {
      savePostFilter()
    }
  }, [filter])

  return (
    <>
      <div
        className={`activities__favor-modal  ${
          isOpen ? 'activities__favor-modal--open' : ''
        }`}
      >
        <Modal className="" setIsOpen={(s: boolean) => setIsOpen(s)}>
          <div
            onClick={(e) => {
              e.stopPropagation()
              setIsOpen(false)
            }}
            className="activities__favor-modal-linebody"
          >
            <button className="activities__favor-modal-line" />
          </div>
          {!when && !where && !postedBy && (
            <div className="publish__modaladd post__modal">
              <div className="search__filter-menu">
                <b>
                  <h5>Filter</h5>
                </b>
                <span
                  onClick={(e) => {
                    e.stopPropagation()
                    setFilter({
                      seen: false,
                      userId: '',
                      when: 'Today',
                      where: 'Kyiv',
                      far: 1,
                    })
                  }}
                >
                  Reset
                </span>
              </div>
              <div className="post__modal_itembg">
                <div className="post__modal_item">
                  <div>
                    <CalendarIcon />
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h5 className="post__modal_title">Posted by</h5>
                    <div
                      className="search__filter-value"
                      onClick={(e) => {
                        e.stopPropagation()
                        setPostedBy(true)
                      }}
                    >
                      {filter.userId.length ? filter.userId : 'Everyone'}
                    </div>
                  </div>
                </div>
                <div className="post__modal_item">
                  <div>
                    <CalendarIcon />
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h5 className="post__modal_title" style={{ margin: '8px 0' }}>
                      When?
                    </h5>
                    <div
                      className="search__filter-value"
                      onClick={(e) => {
                        e.stopPropagation()
                        setWhen(true)
                      }}
                    >
                      {filter.when.length ? filter.when : '...'}
                    </div>
                  </div>
                </div>
                <div className="post__modal_item">
                  <div>
                    <CalendarIcon />
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h5 className="post__modal_title">Where?</h5>
                    <div
                      className="search__filter-value"
                      onClick={(e) => {
                        e.stopPropagation()
                        setWhere(true)
                      }}
                    >
                      {filter.where.length ? filter.where : '...'}
                    </div>
                  </div>
                </div>
                <div className="post__modal_item">
                  <div>
                    <CalendarIcon />
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h5 className="post__modal_title">Seen?</h5>
                    <div className="search__filter-value" style={{ margin: '-10px 0' }}>
                      <IosTougle
                        isTougle={filter.seen}
                        setIsTougle={() => setFilter({ ...filter, seen: !filter.seen })}
                      />
                    </div>
                  </div>
                </div>
                <div className="post__modal_item">
                  <div>
                    <CalendarIcon />
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h5 className="post__modal_title">How far?</h5>
                    <Slider
                      style={{ margin: '-10px 20px', width: '100px' }}
                      value={filter.far}
                      onChange={(e, newValue) =>
                        setFilter({ ...filter, far: newValue as number })
                      }
                      defaultValue={5}
                      min={1}
                      max={20}
                      aria-label="Default"
                      valueLabelDisplay="auto"
                    />
                  </div>
                </div>
              </div>
              <button
                className={`location__bt-continue login__button `}
                onClick={() => {
                  setIsOpen(false)
                }}
              >
                Apply
              </button>
            </div>
          )}
          {when && <PostWhen setIsOpen={setWhen} filter={filter} setFilter={setFilter} />}
          {where && (
            <WherePost setIsOpen={setWhere} filter={filter} setFilter={setFilter} />
          )}
          {postedBy && (
            <PostedBy filter={filter} setFilter={setFilter} setIsOpen={setPostedBy} />
          )}
        </Modal>
      </div>
    </>
  )
}
const PostWhen = ({
  filter,
  setFilter,
  setIsOpen,
}: {
  filter: PostFilterType
  setFilter: (s: PostFilterType) => void
  setIsOpen: (s: boolean) => void
}) => {
  const variants = ['Today', 'Yesterday', 'This week', 'This month']
  return (
    <div className="search__subfilter">
      <div className="search__subfilter-header">
        <button
          onClick={(e) => {
            e.stopPropagation()
            setIsOpen(false)
          }}
        >
          <IconLeftChevrons fill="#000" />
        </button>
        <h6>Last time online</h6>
        <span
          onClick={(e) => {
            e.stopPropagation()
            setFilter({ ...filter, when: '' })
          }}
        >
          Reset
        </span>
      </div>
      <div className="search__subfilter-container">
        <div className="search__subfilter-container-list">
          {variants.map((item) => (
            <div
              className="search__subfilter-container-list-item"
              onClick={(e) => {
                e.stopPropagation()
                setFilter({ ...filter, when: item })
              }}
            >
              {filter.when === item ? <IconProfileCircleFilled /> : <IconProfileCircle />}
              <h6>{item}</h6>
            </div>
          ))}
        </div>
      </div>
      <button
        style={{ maxWidth: '90%', margin: '5px 5%', zIndex: '9' }}
        className={`location__bt-continue login__button `}
        onClick={(e) => {
          e.stopPropagation()
          setIsOpen(false)
        }}
      >
        Apply
      </button>
    </div>
  )
}
const WherePost = ({
  setIsOpen,
  filter,
  setFilter = () => {},
}: {
  setIsOpen: (s: boolean) => void
  filter: PostFilterType
  setFilter: (s: PostFilterType) => void
}) => {
  const [searchResults, setSearchResults] = useState<CityType[]>()
  const [search, setSearch] = useState('')
  const toSearchType = (item: string) => {
    return item.toLowerCase().replaceAll(' ', '')
  }
  useEffect(() => {
    let array: CityType[] = []
    FR.map((fr) => {
      if (toSearchType(fr.city).match(toSearchType(search))) {
        array.push({
          coords: [Number(fr.lat), Number(fr.lng)],
          name: fr.city,
          country: Countries.FR,
        })
      }
    })
    UA.map((fr) => {
      if (toSearchType(fr.city).match(toSearchType(search))) {
        array.push({
          coords: [Number(fr.lat), Number(fr.lng)],
          name: fr.city,
          country: Countries.UA,
        })
      }
    })
    CA.map((fr) => {
      if (toSearchType(fr.capital).match(toSearchType(search))) {
        array.push({
          coords: [Number(fr.latitude), Number(fr.longitude)],
          name: fr.capital,
          country: Countries.CA,
        })
      }
    })
    setSearchResults(array)
  }, [search])
  return (
    <div className="search__subfilter" style={{ overflowY: 'scroll' }}>
      <div className="search__subfilter-header">
        <button
          onClick={(e) => {
            e.stopPropagation()
            setIsOpen(false)
          }}
        >
          <IconLeftChevrons fill="#000" />
        </button>
        <h6>Where?</h6>
        <span
          onClick={(e) => {
            e.stopPropagation()
            setFilter({ ...filter, where: 'Kyiv' })
          }}
        >
          Reset
        </span>
      </div>
      <div style={{ maxWidth: '90%', margin: '5px 5%' }}>
        <InputSearch
          search={true}
          value={search}
          changeValue={setSearch}
          placeholder={
            <>
              Search on <b>NeighborHarbour</b>
            </>
          }
          bg={'rgba(247, 248, 250, 1)'}
        />
      </div>
      <div className="explore__searching-results">
        <div className="explore__searching-results-list">
          {searchResults?.slice(0, 4)?.map((item) => (
            <div
              className="explore__searching-results-list-item"
              onClick={(e) => {
                e.stopPropagation()
                setFilter({ ...filter, where: item.name })
                setIsOpen(false)
              }}
            >
              <div>
                <h5>{item.name}</h5>
                <h6>{item.country}</h6>
              </div>
            </div>
          ))}
        </div>
      </div>
      <button
        style={{ maxWidth: '90%', margin: '5px 5%' }}
        className={`location__bt-continue login__button `}
        onClick={(e) => {
          e.stopPropagation()
          setIsOpen(false)
        }}
      >
        Apply
      </button>
    </div>
  )
}
const PostedBy = ({
  setIsOpen,
  filter,
  setFilter = () => {},
}: {
  setIsOpen: (s: boolean) => void
  filter: PostFilterType
  setFilter: (s: PostFilterType) => void
}) => {
  const [search, setSearch] = useState<string>('')
  const [users, setUsers] = useState<UserInitialStateInterface[]>([])
  const [usersNormal, setUsersNormal] = useState<UserInitialStateInterface[]>([])
  const [ownerId, setOwnerId] = useState<UserInitialStateInterface | null>()
  const { _id } = useAppSelector((state) => state.user)
  const navigate = useNavigate()

  useEffect(() => {
    UserHttp.getAllUsers().then((res) => {
      res.filter((item) => item._id !== _id)
      setUsers(res)
    })
  }, [])
  useEffect(() => {
    let array: UserInitialStateInterface[] = []

    if (search.replace('@', '').length && !ownerId) {
      users.map((item) => {
        if (item.fullName.toLowerCase().includes(search.replace('@', '').toLowerCase())) {
          array.push(item)
        }
      })
    }
    setUsersNormal(array)
  }, [search])

  return (
    <div className="search__subfilter" style={{ overflowY: 'scroll' }}>
      <div className="search__subfilter-header">
        <button
          onClick={(e) => {
            e.stopPropagation()
            setIsOpen(false)
          }}
        >
          <IconLeftChevrons fill="#000" />
        </button>
        <h6>Posted By</h6>
        <span
          onClick={(e) => {
            e.stopPropagation()
            setFilter({ ...filter, userId: '' })
          }}
        >
          Reset
        </span>
      </div>
      <div className="location__modal">
        {!ownerId && (
          <>
            <InputSearch
              value={search}
              changeValue={setSearch}
              placeholder={'@ Username'}
            />
            <div className="advertisement__add-main-results">
              {usersNormal.map((item) => (
                <span
                  onClick={() => {
                    setOwnerId(item)
                    setSearch(item.fullName)
                    setFilter({ ...filter, userId: item.fullName })
                    setIsOpen(false)
                  }}
                >
                  {item.fullName}
                </span>
              ))}
            </div>
          </>
        )}
        <button
          style={{ maxWidth: '90%', margin: '50px 5%' }}
          className={`advertisement__add-main-btn`}
          onClick={(e) => {
            e.stopPropagation()
            setIsOpen(false)
          }}
        >
          Apply
        </button>
      </div>
    </div>
  )
}
export const EventFilter = ({
  setIsOpen,
  isOpen,
  filter,
  setFilter = () => {},
}: {
  setIsOpen: (s: boolean) => void
  isOpen: boolean
  filter: EventFilterType
  setFilter: (s: EventFilterType) => void
}) => {
  const [startDate, setStartDate] = useState<dayjs.Dayjs>(dayjs(new Date()))
  const { activities } = useAppSelector((state) => state.activities)
  const [what, setWhat] = useState<string>('')
  const [isOpenWhat, setIsOpenWhat] = useState(false)
  const [when, setWhen] = useState(false)
  const [where, setWhere] = useState(false)
  useEffect(() => {
    setFilter({ ...filter, when: startDate.toString() })
    console.log(startDate.toString())
  }, [startDate])
  useEffect(() => {
    setWhat(activities[0]?.name)
  }, [activities])
  useEffect(() => {
    setFilter({ ...filter, what })
  }, [what])

  const { _id } = useAppSelector((state) => state.user)
  const savePostFilter = async () => {
    await $api.post('search/update-event-filter', {
      filter,
      ownerId: _id,
    })
  }
  useEffect(() => {
    if (filter) {
      savePostFilter()
    }
  }, [filter])

  return (
    <>
      <div
        className={`activities__favor-modal  ${
          isOpen ? 'activities__favor-modal--open' : ''
        }`}
      >
        <Modal className="" setIsOpen={(s: boolean) => setIsOpen(s)}>
          <div
            onClick={(e) => {
              e.stopPropagation()
              setIsOpen(false)
            }}
            className="activities__favor-modal-linebody"
          >
            <button className="activities__favor-modal-line" />
          </div>
          {!isOpenWhat && !when && !where && (
            <div className="publish__modaladd post__modal">
              <div className="search__filter-menu">
                <b>
                  <h5>Filter</h5>
                </b>
                <span
                  onClick={(e) => {
                    e.stopPropagation()
                    setFilter({
                      seen: false,
                      what: '',
                      when: '',
                      where: '',
                      far: 1,
                    })
                  }}
                >
                  Reset
                </span>
              </div>
              <div className="post__modal_itembg">
                <div className="post__modal_item">
                  <div>
                    <CalendarIcon />
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h5 className="post__modal_title">What</h5>
                    <div
                      className="search__filter-value"
                      onClick={(e) => {
                        e.stopPropagation()
                        setIsOpenWhat(true)
                      }}
                    >
                      {what.length ? what : '...'}
                    </div>
                  </div>
                </div>
                <div className="post__modal_item">
                  <div>
                    <CalendarIcon />
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h5 className="post__modal_title" style={{ margin: '8px 0' }}>
                      When?
                    </h5>
                    <div
                      className="search__filter-value"
                      onClick={(e) => {
                        e.stopPropagation()
                        setWhen(true)
                      }}
                    >
                      {filter.when.length ? filter.when : '...'}
                    </div>
                  </div>
                </div>
                <div className="post__modal_item">
                  <div>
                    <CalendarIcon />
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h5 className="post__modal_title">Where?</h5>
                    <div
                      className="search__filter-value"
                      onClick={(e) => {
                        e.stopPropagation()
                        setWhere(true)
                      }}
                    >
                      {filter.where.length ? filter.where : '...'}
                    </div>
                  </div>
                </div>
                <div className="post__modal_item">
                  <div>
                    <CalendarIcon />
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h5 className="post__modal_title">Neighbors are coming?</h5>
                    <div className="search__filter-value" style={{ margin: '-10px 0' }}>
                      <IosTougle
                        isTougle={filter.seen}
                        setIsTougle={() => setFilter({ ...filter, seen: !filter.seen })}
                      />
                    </div>
                  </div>
                </div>
                <div className="post__modal_item">
                  <div>
                    <CalendarIcon />
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h5 className="post__modal_title">How far?</h5>
                    <Slider
                      style={{ margin: '-10px 20px', width: '100px' }}
                      value={filter.far}
                      onChange={(e, newValue) =>
                        setFilter({ ...filter, far: newValue as number })
                      }
                      defaultValue={5}
                      min={1}
                      max={20}
                      aria-label="Default"
                      valueLabelDisplay="auto"
                    />
                  </div>
                </div>
              </div>
              <button
                className={`location__bt-continue login__button `}
                onClick={() => {
                  setIsOpen(false)
                }}
              >
                Apply
              </button>
            </div>
          )}
          {isOpenWhat && (
            <SelectActivity setIsOpen={setIsOpenWhat} setWhat={setWhat} what={what} />
          )}
          {when && (
            <EventWhen setIsOpen={setWhen} setFilter={setFilter} filter={filter} />
          )}
          {where && (
            <WhereEvent setIsOpen={setWhere} setFilter={setFilter} filter={filter} />
          )}
        </Modal>
      </div>
    </>
  )
}
const EventWhen = ({
  filter,
  setFilter,
  setIsOpen,
}: {
  filter: EventFilterType
  setFilter: (s: EventFilterType) => void
  setIsOpen: (s: boolean) => void
}) => {
  const variants = ['Today', 'Yesterday', 'This week', 'This month']
  return (
    <div className="search__subfilter">
      <div className="search__subfilter-header">
        <button
          onClick={(e) => {
            e.stopPropagation()
            setIsOpen(false)
          }}
        >
          <IconLeftChevrons fill="#000" />
        </button>
        <h6>Last time online</h6>
        <span
          onClick={(e) => {
            e.stopPropagation()
            setFilter({ ...filter, when: '' })
          }}
        >
          Reset
        </span>
      </div>
      <div className="search__subfilter-container">
        <div className="search__subfilter-container-list">
          {variants.map((item) => (
            <div
              className="search__subfilter-container-list-item"
              onClick={(e) => {
                e.stopPropagation()
                setFilter({ ...filter, when: item })
              }}
            >
              {filter.when === item ? <IconProfileCircleFilled /> : <IconProfileCircle />}
              <h6>{item}</h6>
            </div>
          ))}
        </div>
      </div>
      <button
        style={{ maxWidth: '90%', margin: '5px 5%', zIndex: '9' }}
        className={`location__bt-continue login__button `}
        onClick={(e) => {
          e.stopPropagation()
          setIsOpen(false)
        }}
      >
        Apply
      </button>
    </div>
  )
}
const WhereEvent = ({
  filter,
  setFilter,
  setIsOpen,
}: {
  filter: EventFilterType
  setFilter: (s: EventFilterType) => void
  setIsOpen: (s: boolean) => void
}) => {
  const [searchResults, setSearchResults] = useState<CityType[]>()
  const [search, setSearch] = useState('')
  const toSearchType = (item: string) => {
    return item.toLowerCase().replaceAll(' ', '')
  }
  useEffect(() => {
    let array: CityType[] = []
    FR.map((fr) => {
      if (toSearchType(fr.city).match(toSearchType(search))) {
        array.push({
          coords: [Number(fr.lat), Number(fr.lng)],
          name: fr.city,
          country: Countries.FR,
        })
      }
    })
    UA.map((fr) => {
      if (toSearchType(fr.city).match(toSearchType(search))) {
        array.push({
          coords: [Number(fr.lat), Number(fr.lng)],
          name: fr.city,
          country: Countries.UA,
        })
      }
    })
    CA.map((fr) => {
      if (toSearchType(fr.capital).match(toSearchType(search))) {
        array.push({
          coords: [Number(fr.latitude), Number(fr.longitude)],
          name: fr.capital,
          country: Countries.CA,
        })
      }
    })
    setSearchResults(array)
  }, [search])
  return (
    <div className="search__subfilter" style={{ overflowY: 'scroll' }}>
      <div className="search__subfilter-header">
        <button
          onClick={(e) => {
            e.stopPropagation()
            setIsOpen(false)
          }}
        >
          <IconLeftChevrons fill="#000" />
        </button>
        <h6>Where?</h6>
        <span
          onClick={(e) => {
            e.stopPropagation()
            setFilter({ ...filter, where: '' })
          }}
        >
          Reset
        </span>
      </div>
      <div style={{ maxWidth: '90%', margin: '5px 5%' }}>
        <InputSearch
          search={true}
          value={search}
          changeValue={setSearch}
          placeholder={
            <>
              Search on <b>NeighborHarbour</b>
            </>
          }
          bg={'rgba(247, 248, 250, 1)'}
        />
      </div>
      <div className="explore__searching-results">
        <div className="explore__searching-results-list">
          {searchResults?.slice(0, 4)?.map((item) => (
            <div
              className="explore__searching-results-list-item"
              onClick={(e) => {
                e.stopPropagation()
                setFilter({ ...filter, where: item.name })
                setIsOpen(false)
              }}
            >
              <div>
                <h5>{item.name}</h5>
                <h6>{item.country}</h6>
              </div>
            </div>
          ))}
        </div>
      </div>
      <button
        style={{ maxWidth: '90%', margin: '5px 5%' }}
        className={`location__bt-continue login__button `}
        onClick={(e) => {
          e.stopPropagation()
          setIsOpen(false)
        }}
      >
        Apply
      </button>
    </div>
  )
}
const SelectActivity = ({
  setWhat,
  what,
  setIsOpen,
}: {
  setWhat: (s: string) => void
  what: string
  setIsOpen: (s: boolean) => void
}) => {
  const { activities } = useAppSelector((state) => state.activities)
  return (
    <div className="search__subfilter" style={{ overflowY: 'scroll' }}>
      <div className="search__subfilter-header">
        <button
          onClick={(e) => {
            e.stopPropagation()
            setIsOpen(false)
          }}
        >
          <IconLeftChevrons fill="#000" />
        </button>
        <h6>What?</h6>
        <span
          onClick={(e) => {
            e.stopPropagation()
            setWhat('')
          }}
        >
          Reset
        </span>
      </div>
      <div className="search__subfilter-container">
        {activities.map((item) => (
          <div
            className={`search__subfilter-container-item publish__activities-list-item ${
              what === item.name && 'publish__activities-list-item--active'
            }`}
            onClick={() => {
              setWhat(item.name)
              setIsOpen(false)
            }}
          >
            <div className="publish__activities-list-item-img">
              <img src={`${apiBaseURL}/uploads/activities/${item.fileName}`} alt="" />
            </div>
            <h6 className="publish__activities-list-item-title">{item.name}</h6>
          </div>
        ))}
      </div>
      <button
        style={{
          maxWidth: '90%',
          margin: '5px 5%',
          zIndex: '9',
          position: 'relative',
          bottom: '-100px',
        }}
        className={`location__bt-continue login__button `}
        onClick={(e) => {
          e.stopPropagation()
          setIsOpen(false)
        }}
      >
        Apply
      </button>
    </div>
  )
}
const SelectCategory = ({
  setWhat,
  what,
  setIsOpen,
}: {
  setWhat: (s: string) => void
  what: string
  setIsOpen: (s: boolean) => void
}) => {
  const { categories } = useAppSelector((state) => state.categories)
  return (
    <div className="search__subfilter" style={{ overflowY: 'scroll' }}>
      <div className="search__subfilter-header">
        <button
          onClick={(e) => {
            e.stopPropagation()
            setIsOpen(false)
          }}
        >
          <IconLeftChevrons fill="#000" />
        </button>
        <h6>Category</h6>
        <span
          onClick={(e) => {
            e.stopPropagation()
            setWhat('')
          }}
        >
          Reset
        </span>
      </div>
      <div className="search__subfilter-container" style={{ height: 'auto' }}>
        {categories.map((item) => (
          <div
            className={`search__subfilter-container-item publish__activities-list-item ${
              what === item.name && 'publish__activities-list-item--active'
            }`}
            onClick={() => {
              setWhat(item.name)
              setIsOpen(false)
            }}
          >
            <div className="publish__activities-list-item-img">
              <img src={`${apiBaseURL}/uploads/categories/${item.fileName}`} alt="" />
            </div>
            <h6 className="publish__activities-list-item-title">{item.name}</h6>
          </div>
        ))}
      </div>
      <button
        style={{
          maxWidth: '90%',
          margin: '5px 5%',
          zIndex: '9',
          position: 'relative',
          bottom: '-250px',
        }}
        className={`location__bt-continue login__button `}
        onClick={(e) => {
          e.stopPropagation()
          setIsOpen(false)
        }}
      >
        Apply
      </button>
    </div>
  )
}
export const ServiceFilter = ({
  setIsOpen,
  isOpen,
  filter,
  setFilter = () => {},
}: {
  setIsOpen: (s: boolean) => void
  isOpen: boolean
  filter: ServiceFilterType
  setFilter: (s: ServiceFilterType) => void
}) => {
  const { _id } = useAppSelector((state) => state.user)
  const { categories } = useAppSelector((state) => state.categories)
  const [cat, setCat] = useState<string>('')
  const [isOpenCat, setIsOpenCat] = useState(false)
  const [isOpenRate, setIsOpenRate] = useState(false)
  const [timeOnline, setTimeOnline] = useState(false)
  const savePostFilter = async () => {
    await $api.post('search/update-service-filter', {
      filter,
      ownerId: _id,
    })
  }
  useEffect(() => {
    setCat(categories[0]?.name)
    console.log(categories)
  }, [categories])
  useEffect(() => {
    setFilter({ ...filter, category: cat })
  }, [cat])

  useEffect(() => {
    if (filter) {
      savePostFilter()
    }
  }, [filter])

  return (
    <>
      <div
        className={`activities__favor-modal  ${
          isOpen ? 'activities__favor-modal--open' : ''
        }`}
      >
        <Modal className="" setIsOpen={(s: boolean) => setIsOpen(s)}>
          <div
            onClick={(e) => {
              e.stopPropagation()
              setIsOpen(false)
            }}
            className="activities__favor-modal-linebody"
          >
            <button className="activities__favor-modal-line" />
          </div>
          {!isOpenCat && !isOpenRate && !timeOnline && (
            <div className="publish__modaladd post__modal">
              <div className="search__filter-menu">
                <b>
                  <h5>Filter</h5>
                </b>
                <span
                  onClick={(e) => {
                    e.stopPropagation()
                    setFilter({
                      category: categories[0]?.name,
                      rating: 0,
                      far: 1,
                      onlineTime: 'Today',
                    })
                  }}
                >
                  Reset
                </span>
              </div>
              <div className="post__modal_itembg">
                <div className="post__modal_item">
                  <div>
                    <CalendarIcon />
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h5 className="post__modal_title">Category</h5>
                    <div
                      className="search__filter-value"
                      onClick={(e) => {
                        e.stopPropagation()
                        setIsOpenCat(true)
                      }}
                    >
                      {cat.length ? cat : '...'}
                    </div>
                  </div>
                </div>
                <div className="post__modal_item">
                  <div>
                    <CalendarIcon />
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h5 className="post__modal_title">Rating?</h5>
                    <div
                      className="post__modal_title search__filter-value"
                      onClick={(e) => {
                        e.stopPropagation()
                        setIsOpenRate(true)
                      }}
                    >
                      {filter.rating < 3.5 || filter.rating > 4.5 ? 'any' : filter.rating}
                    </div>
                  </div>
                </div>
                <div className="post__modal_item">
                  <div>
                    <CalendarIcon />
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h5 className="post__modal_title">How far?</h5>
                    <Slider
                      style={{ margin: '-10px 20px', width: '100px' }}
                      value={filter.far}
                      onChange={(e, newValue) =>
                        setFilter({ ...filter, far: newValue as number })
                      }
                      defaultValue={5}
                      min={1}
                      max={20}
                      aria-label="Default"
                      valueLabelDisplay="auto"
                    />
                  </div>
                </div>
                <div className="post__modal_item">
                  <div>
                    <CalendarIcon />
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h5 className="post__modal_title">Last time online</h5>
                    <div
                      className="post__modal_title search__filter-value"
                      onClick={(e) => {
                        e.stopPropagation()
                        setTimeOnline(true)
                      }}
                    >
                      {filter.onlineTime.length ? filter.onlineTime : '...'}
                    </div>
                  </div>
                </div>
              </div>
              <button
                className={`location__bt-continue login__button `}
                onClick={() => {
                  setIsOpen(false)
                }}
              >
                Apply
              </button>
            </div>
          )}
          {isOpenCat && (
            <SelectCategory setWhat={setCat} setIsOpen={setIsOpenCat} what={cat} />
          )}
          {isOpenRate && (
            <SelectRate setFilter={setFilter} filter={filter} setIsOpen={setIsOpenRate} />
          )}
          {timeOnline && (
            <LastTimeOnline
              setFilter={setFilter}
              filter={filter}
              setIsOpen={setTimeOnline}
            />
          )}
        </Modal>
      </div>
    </>
  )
}
const SelectRate = ({
  filter,
  setFilter,
  setIsOpen,
}: {
  filter: ServiceFilterType
  setFilter: (s: ServiceFilterType) => void
  setIsOpen: (s: boolean) => void
}) => {
  const rateList = [0, 3.5, 4.0, 4.5]
  return (
    <div className="search__subfilter">
      <div className="search__subfilter-header">
        <button
          onClick={(e) => {
            e.stopPropagation()
            setIsOpen(false)
          }}
        >
          <IconLeftChevrons fill="#000" />
        </button>
        <h6>Rating</h6>
        <span
          onClick={(e) => {
            e.stopPropagation()
            setFilter({ ...filter, rating: 0 })
          }}
        >
          Reset
        </span>
      </div>
      <div className="search__subfilter-rate">
        {rateList?.map((item) => (
          <div
            onClick={(e) => {
              e.stopPropagation()
              setFilter({ ...filter, rating: item })
            }}
            className={
              filter.rating === item
                ? 'search__subfilter-rate-item search__subfilter-rate-item-active'
                : 'search__subfilter-rate-item'
            }
          >
            {filter.rating !== item ? <IconStar /> : <IconStarEmpty />}
            <h6>{item < 3 ? 'Any' : item}</h6>
          </div>
        ))}
      </div>
      <button
        style={{ maxWidth: '90%', margin: '5px 5%' }}
        className={`location__bt-continue login__button `}
        onClick={(e) => {
          e.stopPropagation()
          setIsOpen(false)
        }}
      >
        Apply
      </button>
    </div>
  )
}
const LastTimeOnline = ({
  filter,
  setFilter,
  setIsOpen,
}: {
  filter: ServiceFilterType
  setFilter: (s: ServiceFilterType) => void
  setIsOpen: (s: boolean) => void
}) => {
  const variants = ['Today', 'Yesterday', 'This week', 'This month']
  return (
    <div className="search__subfilter">
      <div className="search__subfilter-header">
        <button
          onClick={(e) => {
            e.stopPropagation()
            setIsOpen(false)
          }}
        >
          <IconLeftChevrons fill="#000" />
        </button>
        <h6>Last time online</h6>
        <span
          onClick={(e) => {
            e.stopPropagation()
            setFilter({ ...filter, onlineTime: '' })
          }}
        >
          Reset
        </span>
      </div>
      <div className="search__subfilter-container">
        <div className="search__subfilter-container-list">
          {variants.map((item) => (
            <div
              className="search__subfilter-container-list-item"
              onClick={(e) => {
                e.stopPropagation()
                setFilter({ ...filter, onlineTime: item })
              }}
            >
              {filter.onlineTime === item ? (
                <IconProfileCircleFilled />
              ) : (
                <IconProfileCircle />
              )}
              <h6>{item}</h6>
            </div>
          ))}
        </div>
      </div>
      <button
        style={{ maxWidth: '90%', margin: '5px 5%', zIndex: '9' }}
        className={`location__bt-continue login__button `}
        onClick={(e) => {
          e.stopPropagation()
          setIsOpen(false)
        }}
      >
        Apply
      </button>
    </div>
  )
}
export const NeibsFilter = ({
  setIsOpen,
  isOpen,
  filter,
  setFilter = () => {},
  reset = () => {},
  start = () => {},
  apply,
}: {
  setIsOpen: (s: boolean) => void
  isOpen: boolean
  filter: NeibsFilterType
  setFilter: (s: NeibsFilterType) => void
  reset?: () => void
  start?: () => void
  apply?: () => void
}) => {
  const [gender, setGender] = useState(false)
  const [education, setEducation] = useState(false)
  const [orientation, setOrientation] = useState(false)
  const [skills, setSkills] = useState(false)
  const [profession, setProfession] = useState(false)
  const [nationality, setNationality] = useState(false)
  const [interests, setInterests] = useState(false)
  const handleChange = (event: Event, newValue: number | number[]) => {
    setFilter({ ...filter, age: newValue as number[] })
    start()
  }
  const { _id } = useAppSelector((state) => state.user)
  const savePostFilter = async () => {
    await $api.post('search/update-neibs-filter', {
      filter,
      ownerId: _id,
    })
  }
  useEffect(() => {
    if (filter) {
      savePostFilter()
    }
  }, [filter])

  return (
    <>
      <div
        className={`activities__favor-modal  ${
          isOpen ? 'activities__favor-modal--open' : ''
        }`}
      >
        <Modal className="" setIsOpen={(s: boolean) => setIsOpen(s)}>
          <div
            onClick={(e) => {
              e.stopPropagation()
              setIsOpen(false)
            }}
            className="activities__favor-modal-linebody"
          >
            <button className="activities__favor-modal-line" />
          </div>
          {!gender &&
            !orientation &&
            !education &&
            !profession &&
            !skills &&
            !nationality &&
            !interests && (
              <div className="publish__modaladd post__modal">
                <div className="search__filter-menu">
                  <b>
                    <h5>Filter</h5>
                  </b>
                  <span
                    onClick={(e) => {
                      e.stopPropagation()
                      setFilter({
                        gender: '',
                        orientation: '',
                        nationality: '',
                        age: [18, 60],
                        profession: '',
                        education: '',
                        skills: '',
                        interests: '',
                        far: 1,
                      })
                      reset()
                    }}
                  >
                    Reset
                  </span>
                </div>
                <div className="post__modal_itembg">
                  <div className="post__modal_item">
                    <div>
                      <CalendarIcon />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <h5
                        className="post__modal_title"
                        onClick={(e) => {
                          e.stopPropagation()
                          setGender(true)
                        }}
                      >
                        Looking for
                      </h5>
                      <div
                        className="search__filter-value search__filter-value"
                        onClick={(e) => {
                          e.stopPropagation()
                          setGender(true)
                        }}
                      >
                        {filter.gender.length ? filter.gender : '...'}
                      </div>
                    </div>
                  </div>
                  <div className="post__modal_item">
                    <div>
                      <CalendarIcon />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <h5
                        className="post__modal_title"
                        onClick={(e) => {
                          e.stopPropagation()
                          setOrientation(true)
                        }}
                      >
                        Orientation
                      </h5>
                      <div
                        className="post__modal_title search__filter-value"
                        onClick={(e) => {
                          e.stopPropagation()
                          setOrientation(true)
                        }}
                      >
                        {filter.orientation.length ? filter.orientation : '...'}
                      </div>
                    </div>
                  </div>
                  <div className="post__modal_item">
                    <div>
                      <CalendarIcon />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <h5
                        className="post__modal_title"
                        onClick={(e) => {
                          e.stopPropagation()
                          setNationality(true)
                        }}
                      >
                        Nationality
                      </h5>
                      <div
                        className="post__modal_title search__filter-value"
                        onClick={(e) => {
                          e.stopPropagation()
                          setNationality(true)
                        }}
                      >
                        {filter.nationality.slice(0, 10) + '...'}
                      </div>
                    </div>
                  </div>
                  <div className="post__modal_item">
                    <div>
                      <CalendarIcon />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <h5 className="post__modal_title">Age</h5>
                      <div>
                        <Slider
                          getAriaLabel={() => 'Temperature range'}
                          value={filter.age}
                          onChange={handleChange}
                          valueLabelDisplay="auto"
                          min={18}
                          max={70}
                          style={{ margin: '0 20px', width: '100px' }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="post__modal_item">
                    <div>
                      <CalendarIcon />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <h5
                        className="post__modal_title"
                        onClick={(e) => {
                          e.stopPropagation()
                          setProfession(true)
                        }}
                      >
                        Profession
                      </h5>
                      <div
                        className="post__modal_title search__filter-value"
                        onClick={(e) => {
                          e.stopPropagation()
                          setProfession(true)
                        }}
                      >
                        {filter.profession.slice(0, 10) + '...'}
                      </div>
                    </div>
                  </div>
                  <div className="post__modal_item">
                    <div>
                      <CalendarIcon />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <h5
                        className="post__modal_title"
                        onClick={(e) => {
                          e.stopPropagation()
                          setEducation(true)
                        }}
                      >
                        Education
                      </h5>
                      <div
                        className="post__modal_title search__filter-value"
                        onClick={(e) => {
                          e.stopPropagation()
                          setEducation(true)
                        }}
                      >
                        {filter.education.length ? filter.education : '...'}
                      </div>
                    </div>
                  </div>
                  <div className="post__modal_item">
                    <div>
                      <CalendarIcon />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <h5
                        className="post__modal_title"
                        onClick={(e) => {
                          e.stopPropagation()
                          setSkills(true)
                        }}
                      >
                        Skills
                      </h5>
                      <div
                        className="post__modal_title search__filter-value"
                        onClick={(e) => {
                          e.stopPropagation()
                          setSkills(true)
                        }}
                      >
                        {filter.skills.slice(0, 10) + '...'}
                      </div>
                    </div>
                  </div>
                  <div className="post__modal_item">
                    <div>
                      <CalendarIcon />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <h5
                        className="post__modal_title"
                        onClick={(e) => {
                          e.stopPropagation()
                          setInterests(true)
                        }}
                      >
                        Interests
                      </h5>
                      <div
                        className="post__modal_title search__filter-value"
                        onClick={(e) => {
                          e.stopPropagation()
                          setInterests(true)
                        }}
                      >
                        {filter.interests.slice(0, 10) + '...'}
                      </div>
                    </div>
                  </div>
                  <div className="post__modal_item">
                    <div>
                      <CalendarIcon />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <h5 className="post__modal_title">How far?</h5>
                      <Slider
                        style={{ margin: '-10px 20px', width: '100px' }}
                        value={filter.far}
                        onChange={(e, newValue) =>
                          setFilter({ ...filter, far: newValue as number })
                        }
                        defaultValue={5}
                        min={1}
                        max={20}
                        aria-label="Default"
                        valueLabelDisplay="auto"
                      />
                    </div>
                  </div>
                </div>
                {!gender && apply && (
                  <button
                    className={`location__bt-continue login__button `}
                    onClick={apply}
                  >
                    Apply
                  </button>
                )}
              </div>
            )}
          {gender && (
            <LookingFor filter={filter} setFilter={setFilter} setIsOpen={setGender} />
          )}
          {orientation && (
            <Orientation
              filter={filter}
              setFilter={setFilter}
              setIsOpen={setOrientation}
            />
          )}
          {education && (
            <Education filter={filter} setFilter={setFilter} setIsOpen={setEducation} />
          )}
          {skills && (
            <SearchList
              filter={filter}
              setFilter={setFilter}
              setIsOpen={setSkills}
              type={QUALITYENUM.SKILLS}
            />
          )}
          {interests && (
            <SearchList
              filter={filter}
              setFilter={setFilter}
              setIsOpen={setInterests}
              type={QUALITYENUM.INTERESTS}
            />
          )}
          {nationality && (
            <SearchList
              filter={filter}
              setFilter={setFilter}
              setIsOpen={setNationality}
              type={QUALITYENUM.NATIONALITY}
            />
          )}
          {profession && (
            <SearchList
              filter={filter}
              setFilter={setFilter}
              setIsOpen={setProfession}
              type={QUALITYENUM.PROFESSION}
            />
          )}
        </Modal>
      </div>
    </>
  )
}
const LookingFor = ({
  setIsOpen,
  filter,
  setFilter = () => {},
}: {
  setIsOpen: (s: boolean) => void
  filter: NeibsFilterType
  setFilter: (s: NeibsFilterType) => void
}) => {
  return (
    <div className="search__subfilter">
      <div className="search__subfilter-header">
        <button
          onClick={(e) => {
            e.stopPropagation()
            setIsOpen(false)
          }}
        >
          <IconLeftChevrons fill="#000" />
        </button>
        <h6>Looking for</h6>
        <span
          onClick={(e) => {
            e.stopPropagation()
            setFilter({ ...filter, gender: '' })
          }}
        >
          Reset
        </span>
      </div>
      <div className="search__subfilter-gender">
        <div
          className={`search__subfilter-gender-item ${filter.gender === SEX.MALE && 'search__subfilter-gender-item-active'}`}
          onClick={(e) => {
            e.stopPropagation()
            setFilter({ ...filter, gender: SEX.MALE })
          }}
        >
          <img src="/Images/male.png" />
          <h6>Male</h6>
        </div>
        <div
          className={`search__subfilter-gender-item ${filter.gender === SEX.FEMALE && 'search__subfilter-gender-item-active'}`}
          onClick={(e) => {
            e.stopPropagation()
            setFilter({ ...filter, gender: SEX.FEMALE })
          }}
        >
          <img src="/Images/woman.png" />
          <h6>Female</h6>
        </div>
        <div
          className={`search__subfilter-gender-item ${filter.gender === SEX.OTHER && 'search__subfilter-gender-item-active'}`}
          onClick={(e) => {
            e.stopPropagation()
            setFilter({ ...filter, gender: SEX.OTHER })
          }}
        >
          <img src="/Images/other.png" />
          <h6>Other</h6>
        </div>
      </div>
      <button
        style={{ maxWidth: '90%', margin: '5px 5%' }}
        className={`location__bt-continue login__button `}
        onClick={(e) => {
          e.stopPropagation()
          setIsOpen(false)
        }}
      >
        Apply
      </button>
    </div>
  )
}
const Orientation = ({
  setIsOpen,
  filter,
  setFilter = () => {},
}: {
  setIsOpen: (s: boolean) => void
  filter: NeibsFilterType
  setFilter: (s: NeibsFilterType) => void
}) => {
  const list = Object.values(ORIENTATION)
  return (
    <div className="search__subfilter">
      <div className="search__subfilter-header">
        <button
          onClick={(e) => {
            e.stopPropagation()
            setIsOpen(false)
          }}
        >
          <IconLeftChevrons fill="#000" />
        </button>
        <h6>Orientation</h6>
        <span
          onClick={(e) => {
            e.stopPropagation()
            setFilter({ ...filter, orientation: '' })
          }}
        >
          Reset
        </span>
      </div>
      <div
        className="search__subfilter-container"
        style={{ gap: '5px', paddingRight: '0' }}
      >
        {list.map((item, index) => (
          <div
            key={index}
            style={
              item === filter.orientation
                ? {}
                : { backgroundColor: 'rgba(247, 248, 250, 1)' }
            }
            className={`search__subfilter-container-orientation profile__sex-orintation-list-item ${
              item === filter.orientation
                ? 'profile__sex-orintation-list-item--active'
                : ''
            }`}
            onClick={() => setFilter({ ...filter, orientation: item })}
          >
            {item}
          </div>
        ))}
      </div>
      <button
        style={{ maxWidth: '90%', margin: '5px 5%' }}
        className={`location__bt-continue login__button `}
        onClick={(e) => {
          e.stopPropagation()
          setIsOpen(false)
        }}
      >
        Apply
      </button>
    </div>
  )
}
const Education = ({
  setIsOpen,
  filter,
  setFilter = () => {},
}: {
  setIsOpen: (s: boolean) => void
  filter: NeibsFilterType
  setFilter: (s: NeibsFilterType) => void
}) => {
  const list = Object.values(EDUCATION)
  return (
    <div className="search__subfilter">
      <div className="search__subfilter-header">
        <button
          onClick={(e) => {
            e.stopPropagation()
            setIsOpen(false)
          }}
        >
          <IconLeftChevrons fill="#000" />
        </button>
        <h6>Education</h6>
        <span
          onClick={(e) => {
            e.stopPropagation()
            setFilter({ ...filter, education: '' })
          }}
        >
          Reset
        </span>
      </div>
      <div
        className="search__subfilter-container"
        style={{ gap: '5px', paddingRight: '0' }}
      >
        {list.map((item, index) => (
          <div
            key={index}
            style={
              item === filter.education
                ? {}
                : { backgroundColor: 'rgba(247, 248, 250, 1)' }
            }
            className={`search__subfilter-container-orientation profile__sex-orintation-list-item ${
              item === filter.education ? 'profile__sex-orintation-list-item--active' : ''
            }`}
            onClick={() => setFilter({ ...filter, education: item })}
          >
            {item}
          </div>
        ))}
      </div>
      <button
        style={{ maxWidth: '90%', margin: '5px 5%' }}
        className={`location__bt-continue login__button `}
        onClick={(e) => {
          e.stopPropagation()
          setIsOpen(false)
        }}
      >
        Apply
      </button>
    </div>
  )
}
const SearchList = ({
  setIsOpen,
  filter,
  setFilter = () => {},
  type,
}: {
  setIsOpen: (s: boolean) => void
  filter: NeibsFilterType
  setFilter: (s: NeibsFilterType) => void
  type: QUALITYENUM
}) => {
  const [popularOptions, setPopularOptions] = useState<OptionsType>([])
  const [search, setSearch] = useState('')
  useEffect(() => {
    $api
      .get(`identity/${toOneKind(type)}-popular`)
      .then((res) => setPopularOptions(res.data))
      .catch((e) => console.log(e + 'error'))
  }, [type])

  return (
    <div className="search__subfilter">
      <div className="search__subfilter-header">
        <button
          onClick={(e) => {
            e.stopPropagation()
            setIsOpen(false)
          }}
        >
          <IconLeftChevrons fill="#000" />
        </button>
        <h6>{type}</h6>
        <span
          onClick={(e) => {
            e.stopPropagation()
            switch (type) {
              case QUALITYENUM.SKILLS:
                setFilter({ ...filter, skills: '' })
                break
              case QUALITYENUM.PROFESSION:
                setFilter({ ...filter, profession: '' })
                break
              case QUALITYENUM.INTERESTS:
                setFilter({ ...filter, interests: '' })
                break
              case QUALITYENUM.NATIONALITY:
                setFilter({ ...filter, nationality: '' })
                break
              default:
                break
            }
          }}
        >
          Reset
        </span>
      </div>
      <div style={{ maxWidth: '90%', margin: '5px 5%' }}>
        <InputSearch
          placeholder={
            <>
              Search <b>{type}</b>
            </>
          }
          changeValue={setSearch}
          value={search}
        />
      </div>
      <div className="search__subfilter-container" style={{ paddingRight: '10px' }}>
        {type === QUALITYENUM.SKILLS &&
          popularOptions
            .filter((it) =>
              it.title
                .toLowerCase()
                .replaceAll(' ', '')
                .includes(search.toLowerCase().replaceAll(' ', '')),
            )
            .slice(0, 10)
            .map((item, index) => (
              <div
                className={`profile__identity-list-item 
                    ${
                      filter.skills === item.title
                        ? 'profile__identity-list-item-active'
                        : ''
                    }  
                    `}
                key={index}
                onClick={() => setFilter({ ...filter, skills: item.title })}
              >
                <div className="profile__identity-list-item-text">{item.title}</div>
              </div>
            ))}
        {type === QUALITYENUM.INTERESTS &&
          popularOptions
            .filter((it) =>
              it.title
                .toLowerCase()
                .replaceAll(' ', '')
                .includes(search.toLowerCase().replaceAll(' ', '')),
            )
            .slice(0, 20)
            .map((item, index) => (
              <div
                className={`profile__identity-list-item 
                    ${
                      filter.interests === item.title
                        ? 'profile__identity-list-item-active'
                        : ''
                    }  
                    `}
                key={index}
                onClick={() => setFilter({ ...filter, interests: item.title })}
              >
                <div className="profile__identity-list-item-text">{item.title}</div>
              </div>
            ))}
        {type === QUALITYENUM.PROFESSION &&
          popularOptions
            .filter((it) =>
              it.title
                .toLowerCase()
                .replaceAll(' ', '')
                .includes(search.toLowerCase().replaceAll(' ', '')),
            )
            .slice(0, 20)
            .map((item, index) => (
              <div
                className={`profile__identity-list-item 
                    ${
                      filter.profession === item.title
                        ? 'profile__identity-list-item-active'
                        : ''
                    }  
                    `}
                key={index}
                onClick={() => setFilter({ ...filter, profession: item.title })}
              >
                <div className="profile__identity-list-item-text">{item.title}</div>
              </div>
            ))}
        {type === QUALITYENUM.NATIONALITY &&
          popularOptions
            .filter((it) =>
              it.title
                .toLowerCase()
                .replaceAll(' ', '')
                .includes(search.toLowerCase().replaceAll(' ', '')),
            )
            .slice(0, 20)
            .map((item, index) => (
              <div
                className={`profile__identity-list-item 
                    ${
                      filter.nationality === item.title
                        ? 'profile__identity-list-item-active'
                        : ''
                    }  
                    `}
                key={index}
                onClick={() => setFilter({ ...filter, nationality: item.title })}
              >
                <div className="profile__identity-list-item-text">{item.title}</div>
              </div>
            ))}
      </div>
      <button
        style={{ maxWidth: '90%', margin: '5px 5%' }}
        className={`location__bt-continue login__button `}
        onClick={(e) => {
          e.stopPropagation()
          setIsOpen(false)
        }}
      >
        Apply
      </button>
    </div>
  )
}
export const FilterInput = ({
  placeholder,
  onClickFilter,
  value,
  changeValue,
  onFocus = () => {},
  onBlur = () => {},
  onChange = () => {},
  onClick = () => {},
}: {
  placeholder: string
  onClickFilter?: () => void | undefined
  value: string
  changeValue: (s: string) => void

  onFocus?: () => void
  onBlur?: () => void
  onChange?: () => void
  onClick?: () => void
}) => {
  return (
    <>
      <div className="input__filter">
        <input
          type="text"
          value={value}
          onChange={(e) => {
            changeValue(e.target.value)
            onChange()
          }}
          onFocus={onFocus}
          onBlur={onBlur}
          onClick={onClick}
          placeholder={placeholder}
        />
      </div>
    </>
  )
}
export const FilterInputNumber = ({
  placeholder,
  onClickFilter,
  value,
  changeValue,
  onFocus = () => {},
  onBlur = () => {},
  onChange = () => {},
  onClick = () => {},
}: {
  placeholder: string
  onClickFilter?: () => void | undefined
  value: number
  changeValue: (s: number) => void

  onFocus?: () => void
  onBlur?: () => void
  onChange?: () => void
  onClick?: () => void
}) => {
  return (
    <>
      <div
        style={{
          backgroundColor: '#fff',
          padding: '5px 10px',
          maxHeight: '30px',
          margin: '0 0',
          display: 'flex',
          gap: '0',
          borderRadius: '8px',
          maxWidth: '130px',
        }}
      >
        <NumberInput
          aria-label="Demo number input"
          placeholder={placeholder}
          value={value}
          onChange={(event, val) => {
            if (val) changeValue(val)
          }}
        />
      </div>
    </>
  )
}
