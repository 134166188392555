import { AxiosResponse } from 'axios'
import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import $api from '../../http'
import { ChatType, ParticipantType } from '../../types/types'
import { apiBaseURL } from '../route/indexPathByRole'
import { useAppSelector } from '../../utils/hooks'
import { Modal } from './Modal'
import { UserElement } from './UserElement'

export const PublishModal = (
  props: { setIsOpen: (o: boolean) => void },
  { isSupport = false }: { isSupport?: boolean },
) => {
  const [chatsList, setChatsList] = useState<ChatType[]>([])
  const { _id, role } = useAppSelector((state) => state.user)
  const { fullName } = useAppSelector((state) => state.user)
  const { avatarFileName } = useAppSelector((state) => state.profile as any)
  const [Participians, setParticipians] = useState<ParticipantType[]>([])
  const [GroupName, setGroupName] = useState<string>('')
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    $api
      .post('messenger/list-chat', { _id, isSupport })
      .then((r: AxiosResponse<ChatType[]>) => {
        const list = r.data.map((item) => ({
          ...item,
          participants: item.participants.filter((p) => p.userId._id !== _id),
        }))
        setChatsList(list)
      })
  }, [])
  const updateParticipians = (obj: ParticipantType[]) => {
    let array = [...Participians]
    let index = array.indexOf(obj[0])
    if (!array.includes(array[index])) {
      if (array.length == 0) {
        array.push(obj[0])
        array.push(obj[1])
        // array.push({userId:_id, avatarFileName:avatarFileName, fullName:fullName});
        setParticipians(array)
      } else {
        setParticipians((s) => [...s, obj[0]])
      }
    }
  }
  const removeUser = (obj: ParticipantType[]) => {
    var array = [...Participians]
    var index = array.indexOf(obj[0])
    if (index !== -1) {
      array.splice(index, 1)
      setParticipians(array)
    }
  }

  // const { socket } = useContext(SocketContext)
  const openChat = () => {
    // navigate(`${indexPathByRole(role)}/messeges/chat`, {
    //     state: {
    //         groupName:GroupName,
    //         participants:Participians,
    //     },
    // })
    // console.log(location.state);
    let array = [...Participians]
    // array.push({userId:_id, avatarFileName:avatarFileName, fullName:fullName});
    // setParticipians(array);
    console.log(array)
    $api
      .post('messenger/new-chat', { participants: array, groupName: GroupName })
      .then(() => {
        navigate(`chat?userId=${JSON.stringify(array)}`, {
          state: {
            participants: array,
          },
        })
        props.setIsOpen(false)
      })
  }
  return (
    <Modal className="group__pannel" setIsOpen={props.setIsOpen}>
      <span>
        <b>Create New Group</b>
      </span>
      <div className="new__group__form">
        <input
          onChange={(e) => setGroupName(e.target.value)}
          type="text"
          name="groupName"
          className="group__chatName__input"
          placeholder="Enter your group name"
        />
        <span className="new__group__title">Select group participians:</span>
        <div className="group__participians__select" style={{ margin: '10px 0' }}>
          {/* <input type="text" name="participians" className="group__chatName__input" placeholder="Select your group participians"/> */}
          <div className="messenger__list">
            {chatsList.map((item) => (
              <UserElement
                removeParticipant={removeUser}
                participants={Participians}
                item={item.participants}
                updateParticipians={updateParticipians}
              >
                <img
                  src={
                    item?.participants[0]?.userId.avatarFileName
                      ? `${apiBaseURL}/uploads/avatar/${item?.participants[0]?.userId.avatarFileName}`
                      : '/Images/Profile.jpg'
                  }
                  alt=""
                />
                <div>
                  <h5 className="messenger__list-item-name">
                    {item?.participants[0]?.userId.fullName}
                  </h5>
                </div>
              </UserElement>
            ))}
          </div>
        </div>
        <button
          className={`${
            Participians.length < 2 || GroupName.length < 3
              ? 'messenger__chat-sender-send--disabled'
              : 'messenger__chat-sender-send'
          }`}
          onClick={() => {
            openChat()
          }}
          disabled={Participians.length < 2 || GroupName.length < 3} // || GroupName !== item.chatName
        >
          Create
        </button>
      </div>
    </Modal>
  )
}
