export default function BugReportFormMonday() {
  return (
    <iframe
      src="https://forms.monday.com/forms/embed/75f9b671fa5da74353cb9f33fa779451?r=euc1"
      width="100%"
      height="100%"
      style={{ border: 0, boxShadow: '5px 5px 56px 0px rgba(0,0,0,0.25)' }}
    />
  )
}
