import { PAGES } from '../../../types/enum'
import { Modal } from '../../ui/Modal'

export const ExploreFilterModal = ({
  isOpen,
  setIsOpen,
  setFilter,
  filter,
  setIsOpenSearch,
}: {
  isOpen: boolean
  setIsOpen: (s: boolean) => void
  setFilter: (s: string) => void
  filter: string
  setIsOpenSearch: (p: boolean) => void
}) => {
  return (
    <div
      className={`activities__favor-modal  ${
        isOpen ? 'activities__favor-modal--open' : ''
      }`}
    >
      <Modal className="" setIsOpen={(s: boolean) => setIsOpen(s)}>
        <div
          onClick={(e) => {
            e.stopPropagation()
            setIsOpen(false)
            setIsOpenSearch(true)
          }}
          className="activities__favor-modal-linebody"
        >
          <button className="activities__favor-modal-line" />
        </div>
        <div className="publish__modaladd">
          <h5 className="publish__modaladd-title">What are you searching for?</h5>
          <h6 className="publish__modaladd-subtitle">Choose your medium</h6>
          <div className="publish__routemodal-btns">
            <div
              style={
                filter === PAGES.MAIN
                  ? { border: '2px solid rgba(15, 20, 25, 1)', borderRadius: '8px' }
                  : { border: '0' }
              }
              onClick={() => {
                setFilter(PAGES.MAIN)
              }}
            >
              <div className="publish__routemodal-button">
                <div className="publish__routemodal-button-img">
                  <img src="/Images/PublishPost.png" alt="" />
                </div>
                <p className="publish__routemodal-text">Newsfeed</p>
              </div>
            </div>
            <div
              style={
                filter === PAGES.SERVICES
                  ? { border: '2px solid rgba(15, 20, 25, 1)', borderRadius: '8px' }
                  : { border: '0' }
              }
              onClick={() => {
                setFilter(PAGES.SERVICES)
              }}
            >
              <div className="publish__routemodal-button">
                <div className="publish__routemodal-button-img">
                  <img src="/Images/PublishService.png" alt="" />
                </div>
                <p className="publish__routemodal-text">Service</p>
              </div>
            </div>
            <div
              style={
                filter === PAGES.ACTIVITIES
                  ? { border: '2px solid rgba(15, 20, 25, 1)', borderRadius: '8px' }
                  : { border: '0' }
              }
              onClick={() => {
                setFilter(PAGES.ACTIVITIES)
              }}
            >
              <div className="publish__routemodal-button">
                <div className="publish__routemodal-button-img">
                  <img src="/Images/PublishEvent.png" alt="" />
                </div>
                <p className="publish__routemodal-text">Activity</p>
              </div>
            </div>
          </div>
          <div style={{ margin: '50px 0' }} />
          <div style={{ margin: '30px 0' }}>
            <button
              className={`publish__publish user--footer--button `}
              style={{ bottom: '10px' }}
              onClick={() => {
                setIsOpen(false)
                setIsOpenSearch(true)
              }}
            >
              Apply
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}
