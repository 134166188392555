export const IconMaster = () => {
  return (
    <svg
      width="20"
      height="12"
      viewBox="0 0 20 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.83333 11.8332C9.05499 11.8332 11.6667 9.2215 11.6667 5.99984C11.6667 2.77818 9.05499 0.166504 5.83333 0.166504C2.61167 0.166504 0 2.77818 0 5.99984C0 9.2215 2.61167 11.8332 5.83333 11.8332Z"
        fill="#EA001B"
      />
      <path
        d="M14.1666 11.8332C17.3882 11.8332 19.9999 9.2215 19.9999 5.99984C19.9999 2.77818 17.3882 0.166504 14.1666 0.166504C10.9449 0.166504 8.33325 2.77818 8.33325 5.99984C8.33325 9.2215 10.9449 11.8332 14.1666 11.8332Z"
        fill="#FFA200"
        fillOpacity="0.8"
      />
    </svg>
  )
}
