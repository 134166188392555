import { IconLeftChevrons } from '../svg/IconChevrons'
import { Button } from 'antd'

export const ButtonLeftChevron = ({ onClick }: { onClick: () => void }) => {
  return (
    <div
      className="forget__back"
      style={{
        padding: '0 1.25em 0 1.25em',
      }}
    >
      <Button onClick={onClick} shape="circle">
        <IconLeftChevrons fill="#000" />
      </Button>
    </div>
  )
}
