import L, { LatLng, LatLngExpression } from 'leaflet'
import { useEffect, useState } from 'react'
import { Circle, MapContainer, Polygon, TileLayer } from 'react-leaflet'
import { useLocation, useNavigate } from 'react-router-dom'
import { AdvertisementHttp } from '../../../http/advertisement'
import { UserHttp } from '../../../http/user-http'
import { ROLE } from '../../../types/enum'
import { indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconLeftChevrons } from '../../svg/IconChevrons'
import { IconPencil } from '../../svg/IconPencil'
import { IconLocationAim } from '../../svg/IconsLocation'
import { success } from '../../ui/LoadSuccess'
import { ExploreCancelIcon } from '../../user-components/explore/ExploreCancelIcon'
import { UserHeader } from '../../user-components/header/UserHeader'
import { insideCircle, inside } from '../../../utils/inside'
import { DrawModal } from '../../ui/DrawModal'
import { Loader } from '../../ui/Loader'
export const AdvertisementMap = ({
  addLoc,
}: {
  addLoc?: (
    polygonCoords?: any[],
    prise?: number,
    radiusSize?: number,
    center?: [number, number],
  ) => {}
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { role } = useAppSelector((state) => state.user)
  const [adId, setAdId] = useState<string>()
  const [map, setMap] = useState<L.Map | null>(null)
  const [circle, setCircle] = useState<L.Circle | null>(null)
  const [drawMode, setDrawMode] = useState<boolean>(false)
  const [radius, setRadius] = useState<boolean>(false)
  const [radiusSize, setRadiusSize] = useState<number>(1)
  const [draw, setDraw] = useState<boolean>(false)
  const [userPos, setUserPos] = useState<LatLngExpression>()
  const [userPoscurrent, setUserPoscurrent] = useState<[number, number]>()
  const [polygonCoords, setPolygonCoords] = useState<
    LatLng[] | LatLng[][] | LatLng[][][] | undefined
  >([])
  const [polyline, setPolyline] = useState<L.Polyline | null>()
  // const [cords, setCords] = useState<L.LatLng[] | L.LatLng[][] | L.LatLng[][][]>()
  const [paint, setPaint] = useState<boolean>(false)
  const [startPos, setStartPos] = useState<[number, number]>()
  const [admin, setAdmin] = useState<string>('')
  const [load, setLoad] = useState(false)

  const [drawed, setDrawed] = useState<boolean>(false)
  const [polygon, setPolygon] = useState<L.Polygon | null>()
  const [pointlatlngCurrent, setPointlatlngCurrent] = useState<L.LatLng | null>(null)
  const [posCurrent, setPosCurrent] = useState<L.LatLng | null>(null)

  const size = 4
  const validateGeoData = !Boolean(
    (userPoscurrent?.length && radiusSize) || (polygonCoords?.length && startPos?.length),
  )
  const getUserPos = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          setUserPos([pos.coords.latitude, pos.coords.longitude])
        },
        () => console.log('Cannot get user location'),
      )
    }
  }
  useEffect(() => {
    getUserPos()
  }, [])

  useEffect(() => {
    setAdId(location.state.adId)
  }, [])

  map?.on('moveend', () => {
    if (radius) {
      setUserPoscurrent([map?.getCenter().lat, map?.getCenter().lng])
    }
  })

  useEffect(() => {
    if (!drawed && !polyline && posCurrent && map) {
      map?.dragging.disable()
      setPaint(true)
      setPolygonCoords([])
      setPolyline(L.polyline([]).addTo(map).addLatLng(posCurrent))
      setStartPos([posCurrent.lat, posCurrent.lng])
    }
  }, [posCurrent])

  if (map && draw && !polygonCoords?.length)
    L.DomEvent.on(map?.getContainer(), 'touchstart', (e) => {
      let touch = (e as TouchEvent).targetTouches[0],
        rect = map?.getContainer().getBoundingClientRect(),
        lat = touch.clientX - rect.left - map?.getContainer().clientLeft,
        lng = touch.clientY - rect.top - map?.getContainer().clientTop,
        containerPoint = L.point(lat, lng)

      let p = map?.containerPointToLayerPoint(containerPoint)
      let pointlatlng
      // console.log(pointlatlng)
      if (!paint && draw && !polyline && map && !polygon && !polygonCoords?.length) {
        // e.stopPropagation()

        // let point = L.point((e as TouchEvent).targetTouches[0].clientX, (e as TouchEvent).targetTouches[0].clientY);

        if (p) pointlatlng = map?.layerPointToLatLng(p)
        if (pointlatlng) setPosCurrent(pointlatlng)
        // setPolygonCords([]);
      }
    })
  if (!draw && map)
    L.DomEvent.off(map?.getContainer(), 'touchstart', (e) => {
      e.stopPropagation()
    })
  useEffect(() => {
    if (drawed && !polygonCoords?.length && map) {
      setPolygonCoords(polyline?.getLatLngs())
      polyline?.setLatLngs([])
      polyline?.removeFrom(map)
      setPolyline(undefined)
      setDraw(false)
      map?.dragging.enable()
    }
  }, [drawed])

  if (map && draw && !polygonCoords?.length)
    L.DomEvent.on(map?.getContainer(), 'touchend', (e) => {
      if (
        map &&
        polyline?.getLatLngs().length &&
        draw &&
        !polygon &&
        !polygonCoords?.length
      ) {
        // setPolygonCords(polyline?.getLatLngs())
        //   polyline?.setLatLngs([])
        //   polyline?.removeFrom(map)
        //   setPolyline(undefined)
        // setDraw(false);
        // map?.dragging.enable()
        setDrawed(true)
        e.stopImmediatePropagation()
        // setDrawMode(true)
      }
    })
  if (drawed && map) L.DomEvent.off(map?.getContainer(), 'touchmove', (e) => {})
  useEffect(() => {
    if (pointlatlngCurrent && draw) {
      polyline?.addLatLng(pointlatlngCurrent)
    }
  }, [pointlatlngCurrent])

  if (map && draw && !polygonCoords?.length)
    L.DomEvent.on(map?.getContainer(), 'touchmove', (e) => {
      if (!polygonCoords?.length && paint && draw && !polygon && !polygonCoords?.length) {
        let touch = (e as TouchEvent).targetTouches[0],
          rect = map?.getContainer().getBoundingClientRect(),
          lat = touch.clientX - rect.left - map?.getContainer().clientLeft,
          lng = touch.clientY - rect.top - map?.getContainer().clientTop,
          containerPoint = L.point(lat, lng)

        let p = map?.containerPointToLayerPoint(containerPoint)
        let pointlatlng

        if (p) pointlatlng = map?.layerPointToLatLng(p)
        if (startPos?.length && pointlatlng && !drawed) {
          if (!polyline?.getLatLngs().length && map) setPolyline(L.polyline([startPos]))
          setPointlatlngCurrent(pointlatlng)
          // polyline?.addLatLng(pointlatlng)
          if (
            (insideCircle([pointlatlng.lat, pointlatlng.lng], 0.01, startPos) ||
              (pointlatlng.lat === startPos[0] && pointlatlng.lng === startPos[1])) &&
            map &&
            polyline?.getLatLngs().length
          ) {
            setPolygonCoords(polyline?.getLatLngs())
            polyline?.setLatLngs([])
            polyline?.removeFrom(map)
            setPolyline(undefined)
            setDraw(false)
            map?.dragging.enable()
            setDrawed(true)
            e.stopImmediatePropagation()
          }
        }
      }
    })

  const geoLocation = () => {
    if (userPos) map?.flyTo(userPos)
  }
  useEffect(() => {
    const effect = async () => {
      ;(await UserHttp.getAllUsers()).filter(async (admin) => {
        if (admin.role === ROLE.REGIONAL_ADMIN || admin.role === ROLE.LOCAL_ADMIN) {
          ;(await UserHttp.getAllUsers()).filter((local) => {
            // if(local.regionAdmin?.fullName===admin.fullName){

            if (polygonCoords?.length && map) {
              if (
                local.polygonCoords &&
                inside(
                  [
                    L.polygon(polygonCoords).addTo(map).getCenter().lat,
                    L.polygon(polygonCoords).addTo(map).getCenter().lng,
                  ],
                  local.polygonCoords,
                )
              ) {
                setAdmin(local._id)
                console.log(local._id)
              }
            }
            if (userPoscurrent?.length) {
              if (local.polygonCoords && inside(userPoscurrent, local.polygonCoords)) {
                setAdmin(local._id)
                console.log(local._id)
              }
            }
            // }
          })
        }
      })
    }
    effect()
  }, [userPoscurrent, polygonCoords])

  const addLocation = async () => {
    try {
      if (addLoc && location.state.value) {
        if (userPoscurrent?.length) {
          addLoc(polygonCoords, location.state.value, radiusSize, userPoscurrent)
        } else {
          addLoc(polygonCoords, location.state.value, radiusSize, startPos)
        }
      } else {
        if (radiusSize && userPoscurrent && adId) {
          await AdvertisementHttp.updateLocation({
            radiusSize,
            center: userPoscurrent,
            adId,
            adminId: admin,
          })
          success()
          navigate(`${indexPathByRole(role)}/advertisement/add-community-selection`, {
            state: { adId: location.state.adId },
          })
        }
        if (polygonCoords?.length && startPos && adId) {
          await AdvertisementHttp.updateLocation({
            polygonCoords: polygonCoords,
            center: startPos,
            adId,
            adminId: admin,
          })
          success()
          navigate(`${indexPathByRole(role)}/advertisement/add-community-selection`, {
            state: { adId: location.state.adId },
          })
        }
      }
    } catch (error) {
      console.log('location error: ' + error)
    }
  }
  useEffect(() => {
    if (userPoscurrent) {
      if (!admin && userPoscurrent.length) {
        setLoad(true)
      } else {
        setLoad(false)
      }
    }
  }, [userPoscurrent, admin])
  useEffect(() => {
    if (polygonCoords) {
      if (!admin && polygonCoords.length) {
        setLoad(true)
      } else {
        setLoad(false)
      }
    }
  }, [polygonCoords, admin])

  useEffect(() => {
    if (radius && map) {
      setUserPoscurrent([map?.getCenter().lat, map?.getCenter().lng])
    }
  }, [radius])
  return (
    <>
      <div className="advertisement__community-map ">
        <UserHeader>
          <div className="advertisement__community-header ">
            <div
              className="advertisement__add-header-btn"
              onClick={() => {
                if (location.state.value) {
                  navigate(-1)
                } else {
                  navigate(-3)
                }
              }}
            >
              <IconLeftChevrons />
              <span>Coordintaors</span>
            </div>
            <div className="location__current-buttons">
              <div
                style={{ backgroundColor: '#fff', borderRadius: '100%' }}
                className={'admin__panel-addMarker explore__location__follow'}
                id="draw"
                onClick={() => {
                  setDrawMode(!drawMode)
                  // if(!drawMode){
                  // setPolygonCoords([]);
                  // }
                }}
              >
                <IconPencil color="#000" />
              </div>
              <button
                className={'admin__panel-addMarker explore__location__follow'}
                style={{ backgroundColor: '#fff', borderRadius: '100%' }}
                onClick={geoLocation}
              >
                <IconLocationAim />
              </button>
            </div>
          </div>
        </UserHeader>
        <div className="advertisement__community-map-container">
          {userPos && (
            <MapContainer
              zoomControl={false}
              center={location.state?.center || userPos}
              minZoom={8}
              maxZoom={18}
              zoom={location.state?.zoom || 8}
              scrollWheelZoom={true}
              ref={setMap}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                url="https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png"
                subdomains="abcd"
              />
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                url="https://{s}.basemaps.cartocdn.com/dark_only_labels/{z}/{x}/{y}{r}.png"
                subdomains="abcd"
              />
              {radius && userPoscurrent && (
                <Circle
                  center={userPoscurrent}
                  radius={radiusSize * 1000}
                  ref={setCircle}
                />
              )}
              {polygonCoords && <Polygon positions={polygonCoords} />}
              {startPos && (
                <ExploreCancelIcon
                  handler={() => {
                    // setPolygonCoords([]);
                    // setDrawed(false);
                    // setadminMode(false);
                    // setDrawMode(false);
                    // setPaint(false);
                    // setMarkerMode(false);
                    // setDraw(false);
                    // setPolygonRef(null);
                    // setPolygon(null);
                    // setPolyline(null)
                    // setStartAdmin(null);
                    // setEndAdmin(null);
                    // setStartPos(undefined);
                    navigate('', {
                      state: { center: map?.getCenter(), zoom: map?.getZoom() },
                    })
                    window.location.reload()
                    // setPosCurrent(null);
                    // setMarkPolygonCoords(null);
                    // map?.dragging.enable()
                  }}
                  position={startPos}
                />
              )}
            </MapContainer>
          )}
          {/* {radius && 
                            <div className="location__current-map--target" style={{width:`${radiusSize*size}vw`, height:`${radiusSize*size}vw`, top:`calc(50% - ${radiusSize*size/1.5}vw)`, left:`calc(50% - ${radiusSize*size/2}vw)`}}>
                            </div>
                        } */}
        </div>
        <button
          className={`advertisement__add-main-btn ${
            validateGeoData && 'advertisement__add-main-btn-disabled'
          }`}
          onClick={addLocation}
          disabled={validateGeoData}
        >
          Continue
        </button>
        {!load ? (
          <>
            {drawMode && map && (
              <DrawModal
                map={map}
                setPolyline={setPolyline}
                draw={draw}
                setDraw={setDraw}
                setRadius={setRadius}
                radius={radius}
                setRadiusSize={setRadiusSize}
                radiusSize={radiusSize}
                isOpen={drawMode}
                setIsOpen={setDrawMode}
                setPolygonCoords={setPolygonCoords}
                setPosUserCurrent={setUserPoscurrent}
              />
            )}
          </>
        ) : (
          <Loader />
        )}
      </div>
    </>
  )
}
