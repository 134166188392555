import { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { IconLeftChevrons } from '../../svg/IconChevrons'
import { UserHeader } from './UserHeader'

export const UserHeaderUserChatList = ({
  headerTitle,
  subTitle,
}: {
  headerTitle: string
  subTitle?: ReactNode
}) => {
  const navigate = useNavigate()

  return (
    <UserHeader>
      <div>
        <button onClick={() => navigate(-1)} className="user__header-chatuser">
          <IconLeftChevrons />
          <div>
            <h5 className="user__header-title">{headerTitle}</h5>
            {subTitle && <h6 className="user__header-subtitle">{subTitle}</h6>}
          </div>
        </button>
      </div>
    </UserHeader>
  )
}
