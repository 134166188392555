import { ReactElement } from 'react'
import { IconInputFilter, IconInputSearch } from '../svg/IconInputSearch'

export const InputSearch = ({
  placeholder,
  onClickFilter,
  value,
  changeValue,
  onFocus = () => {},
  onBlur = () => {},
  onChange = () => {},
  onClick = () => {},
  filter = true,
  search = true,
  bg,
  style,
  limit,
}: {
  placeholder: string | ReactElement
  onClickFilter?: () => void | undefined
  value: string
  changeValue: (s: string) => void

  onFocus?: () => void
  onBlur?: () => void
  onChange?: () => void
  onClick?: () => void
  filter?: boolean
  search?: boolean
  bg?: string
  style?: {}
  limit?: number
}) => {
  return (
    <>
      {bg ? (
        <div style={{ backgroundColor: bg }} className="ui-input-search">
          {search && <IconInputSearch />}
          <div className="ui-input-search-container" style={style}>
            <input
              type="text"
              value={value}
              onChange={(e) => {
                changeValue(e.target.value)
                onChange()
              }}
              onFocus={onFocus}
              onBlur={onBlur}
              onClick={onClick}
              maxLength={100}
            />
            {!value && <span className="ui-input-search-placeholder">{placeholder}</span>}
          </div>

          {onClickFilter && filter ? (
            <button onClick={onClickFilter}>
              <IconInputFilter />
            </button>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div className="ui-input-search">
          {search && <IconInputSearch />}
          <div className="ui-input-search-container" style={style}>
            <input
              type="text"
              value={value}
              onChange={(e) => {
                changeValue(e.target.value)
                onChange()
              }}
              onFocus={onFocus}
              onBlur={onBlur}
              onClick={onClick}
              maxLength={100}
            />
            {!value && <span className="ui-input-search-placeholder">{placeholder}</span>}
          </div>

          {onClickFilter && filter ? (
            <button onClick={onClickFilter}>
              <IconInputFilter />
            </button>
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  )
}
