import { ReactElement } from 'react'

export const IconArrachFile = (): ReactElement => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7564 32.5001H16.1231C12.6397 32.5001 9.53972 29.9501 9.20639 26.4834C8.82305 22.5167 11.9397 19.1667 15.8397 19.1667H36.4397C38.6231 19.1667 40.6064 20.7334 40.8231 22.9001C41.0731 25.4001 39.1231 27.5001 36.6731 27.5001H19.1731C18.2564 27.5001 17.5064 26.7501 17.5064 25.8334C17.5064 24.9167 18.2564 24.1667 19.1731 24.1667H33.7564C34.4397 24.1667 35.0064 23.6001 35.0064 22.9167C35.0064 22.2334 34.4397 21.6667 33.7564 21.6667H19.4064C17.2231 21.6667 15.2397 23.2334 15.0231 25.4001C14.7731 27.9001 16.7231 30.0001 19.1731 30.0001H36.3897C39.8731 30.0001 42.973 27.4501 43.3064 23.9834C43.6897 20.0001 40.5731 16.6667 36.6731 16.6667H16.2231C11.4397 16.6667 7.15639 20.1667 6.70639 24.9334C6.20639 30.4167 10.4731 35.0001 15.8397 35.0001H33.7564C34.4397 35.0001 35.0064 34.4334 35.0064 33.7501C35.0064 33.0667 34.4397 32.5001 33.7564 32.5001Z"
        fill="#C3CAD9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.0002 25.3333C36.3202 25.3333 33.3335 28.3199 33.3335 31.9999C33.3335 35.6799 36.3202 38.6666 40.0002 38.6666C43.6802 38.6666 46.6668 35.6799 46.6668 31.9999C46.6668 28.3199 43.6802 25.3333 40.0002 25.3333ZM42.6668 32.6666H40.6668V34.6666C40.6668 35.0333 40.3668 35.3333 40.0002 35.3333C39.6335 35.3333 39.3335 35.0333 39.3335 34.6666V32.6666H37.3335C36.9668 32.6666 36.6668 32.3666 36.6668 31.9999C36.6668 31.6333 36.9668 31.3333 37.3335 31.3333H39.3335V29.3333C39.3335 28.9666 39.6335 28.6666 40.0002 28.6666C40.3668 28.6666 40.6668 28.9666 40.6668 29.3333V31.3333H42.6668C43.0335 31.3333 43.3335 31.6333 43.3335 31.9999C43.3335 32.3666 43.0335 32.6666 42.6668 32.6666Z"
        fill="#3361FF"
      />
    </svg>
  )
}
