import { Rating } from '@mui/material'
import { AxiosResponse } from 'axios'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import $api from '../../../http'
import { ServiceHttp } from '../../../http/service-http'
import {
  ChatType,
  CommentInterface,
  PublishServiceOneItemInterface,
  Review,
} from '../../../types/types'
import { apiBaseURL, indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconBookMark } from '../../svg/IconBookMark'
import { IconStars } from '../../svg/IconFavor'
import { IconProfileInfoBookmark } from '../../svg/IconProfileInfo'
import { IconServicesAllPoint } from '../../svg/IconServicesAll'
import { IconStar } from '../../svg/IconStar'
import { IconStarEmpty } from '../../svg/IconStarEmpty'
import { ButtonLeftChevron } from '../../ui/ButtonLeftChevron'
import { Loader } from '../../ui/Loader'
import { PostSlick } from '../../ui/PostSlick'
import { TempNotification } from '../../ui/TempNotification'
import { UserServiceMap } from './UserServiceMap'
import { UserServiceMyModal } from './UserServiceMyModal'
import { UserServiceOtherModal } from './UserServiceOtherModal'

export const UserPublishServicesItem = () => {
  // const [myModalOpen, setMyModalOpen] = useState(false)
  // const [neibModalOpen, setNeibModalOpen] = useState(false)

  const [searchParams] = useSearchParams()
  const [publishService, setPublishService] = useState<PublishServiceOneItemInterface>()
  const { _id, fullName, role, avatarFileName } = useAppSelector((state) => state.user)

  const [comments, setComments] = useState<CommentInterface[]>([])
  const [countComments, setCountComments] = useState(0)
  const navigate = useNavigate()
  const [text, setText] = useState('')

  const [reviews, setReviews] = useState<Review[]>([])
  const [Rate, setRate] = useState<number>(0)
  const [isReview, setisReview] = useState<boolean>(false)

  const [isOpen, setIsOpen] = useState(false)

  const [chat, setChat] = useState<ChatType>()
  const [isOpenSave, setIsOpenSave] = useState<boolean>(false)
  const getPublishServiceData = async () => {
    const publishServiceId = searchParams.get('publishServiceId') || ''
    const publishService = await ServiceHttp.getOnePublsihService({
      publishServiceId,
      userId: _id,
    })
    if (publishService) {
      console.log(publishService)
      setPublishService(publishService)
      const res = await ServiceHttp.getPublishServiceReview({
        serviceId: publishService._id,
      })
      console.log(res)
      let rate: number[] = []
      res.map((item) => {
        rate.push(item.rate)
        if (item.userId._id === _id) {
          setisReview(true)
        }
      })
      setRate(rate.reduce((partialSum, a) => partialSum + a, 0) / rate.length)
      setReviews(res)
    }
  }

  const updateMark = async (serviceId: string, isMarked: boolean) => {
    if (isMarked) {
      await ServiceHttp.deleteMark({
        serviceId,
        marckedUserId: _id,
      })
    } else {
      await ServiceHttp.addMark({
        serviceId,
        marckedUserId: _id,
      })
      setIsOpenSave(true)
    }

    if (publishService) {
      setPublishService({
        ...publishService,
        isMarked: !publishService.isMarked,
      })
    }
  }

  // const updatePin = async (repostId: string) => {
  //     setUpdate(true)
  //     if (post) {
  //         await PublishPostHttp.updatePin({
  //             repostId: repostId,
  //             userId: _id,
  //         })

  //         setPost({
  //             ...post,
  //             isPined: !post.isPined,
  //         })
  //     }
  // }

  useEffect(() => {
    const bodyEffect = async () => {
      await getPublishServiceData()

      // await getCommentData()
    }
    bodyEffect()
    console.log(publishService?.phone)
  }, [])

  useEffect(() => {
    const effect = async () => {
      $api
        .post('messenger/list-chat', { _id, isSupport: false })
        .then((res: AxiosResponse<ChatType[]>) => {
          res?.data.filter((item) => {
            if (
              item.participants.find((user) => user.userId._id === _id) &&
              item.participants.find(
                (user) => user.userId._id === publishService?.userId._id,
              ) &&
              item.participants.length == 2
            ) {
              setChat(item)
              console.log(item)
            }
          })
        })
    }
    effect()
  }, [])

  const updateNotification = async (serviceId: string) => {
    if (publishService) {
      await ServiceHttp.updateNotification({
        serviceId: serviceId,
        userId: _id,
      })

      setPublishService({
        ...publishService,
        isNotificatedService: !publishService.isNotificatedService,
      })
    }
  }

  const toProfileInfo = (prop: {
    _id: string
    email: string
    role: string
    fullName: string
    avatarFileName: string
  }) => {
    navigate('/profileinfo', {
      state: prop,
    })
  }
  const createChat = async () => {
    await $api
      .post('messenger/open-chat', {
        participants: [
          {
            userId: publishService?.userId._id,
          },
          {
            userId: _id,
          },
        ],
      })
      .then((res) => {
        console.log(res)
        if (res.data) {
          navigate(`${indexPathByRole(role)}/messeges/chat`, {
            state: {
              chat: res.data,
            },
          })
        }
      })
  }
  const openChat = () => {
    if (chat) {
      navigate(
        `${indexPathByRole(role)}/messeges/chat?chatId=${JSON.stringify(chat.chatId)}`,
        {
          state: {
            participants: [
              {
                userId: publishService?.userId._id,
              },
              {
                userId: _id,
              },
            ],
            chat,
          },
        },
      )
    } else {
      createChat()
    }
  }
  const returnDate = (d: Date) => {
    const date = moment(d)
    return `${date.format('MMM D,')} ${date.hour()}:${date.minute()}`
  }
  return publishService ? (
    <div className="commenst user__publish-service">
      <div className="commenst__slick">
        <div className="commenst__back">
          <ButtonLeftChevron onClick={() => navigate(-1)} />
          <button className="ui-button-back-route" onClick={() => setIsOpen(true)}>
            <IconServicesAllPoint />
          </button>
        </div>
        <PostSlick list={publishService?.filesName}>
          {publishService.filesName.length > 0 ? (
            publishService?.filesName?.map((it) => (
              <div className="commenst__slick-img">
                <img src={`${apiBaseURL}/uploads/publish_services/${it}`} alt="" />
              </div>
            ))
          ) : (
            <div className="commenst__slick-img">
              <img className="posts__bg" src={`/Images/postsBG.png`} alt="" />
            </div>
          )}
        </PostSlick>
        <div className="commenst__slick-bookmark">
          <button
            onClick={() => updateMark(publishService._id, publishService?.isMarked)}
            className={`ui-button-back-route ${
              publishService?.isMarked && 'admin__posts-list-row4-repost--active'
            }`}
          >
            <IconProfileInfoBookmark />
          </button>
        </div>
        {Rate ? (
          <div className="user__publish-service-imgrating">
            <b>
              {Rate ? (
                <>
                  <IconStars />
                  {Rate}
                </>
              ) : (
                ''
              )}
            </b>
            {reviews.length ? `(${reviews.length})` : ''}
          </div>
        ) : (
          ''
        )}
      </div>
      <div className="commenst__content">
        <div className="commenst__user">
          <div className="commenst__user-img">
            {publishService.userId.avatarFileName ? (
              <img
                src={`${apiBaseURL}/uploads/avatar/${publishService.userId.avatarFileName}`}
              />
            ) : (
              <div
                style={{
                  backgroundColor: 'rgba(41, 52, 65, 1)',
                  borderRadius: '100%',
                  width: '100%',
                  height: '100%',
                  color: '#fff',
                  textAlign: 'center',
                  position: 'relative',
                }}
              >
                <span style={{ position: 'absolute', left: '13px', top: '12px' }}>
                  {publishService.userId.fullName.slice(0, 2)}
                </span>
              </div>
            )}
          </div>
          <div>
            <p className="commenst__user-info-name">{publishService.userId.fullName}</p>
            <p className="commenst__user-info-time">
              {returnDate(publishService.createdPublishServiceDate)}
            </p>
          </div>
        </div>
        <h5 className="commenst-title">{publishService.title}</h5>
        <p className="commenst-subtitle">{publishService.text}</p>
        <div className="commenst__viewmap">
          <div className="commenst__map">
            <div className="user__publish-service-location">
              <div className="user__publish-service-location-img">
                <img
                  src={`${apiBaseURL}/uploads/categories/${publishService?.categoryId?.fileName}`}
                  alt=""
                />
              </div>

              <div className="user__publish-service-location-text">
                <h5 className="user__publish-service-location-cat">
                  {publishService?.categoryId?.name}
                </h5>
                <h6 className="user__publish-service-location-sub">
                  {publishService?.subCategoryId?.name}
                </h6>
              </div>
            </div>
            <UserServiceMap type="publish_services" item={publishService} />
          </div>
        </div>
        <div className="user__services-rev">
          <h5 className="commenst-title user__publish-service-review-title">
            {reviews.length ? (
              <>
                <IconStars />
                {Rate}{' '}
                <span>
                  ({reviews.length} {reviews.length > 1 ? 'reviews' : 'review'})
                </span>
              </>
            ) : (
              ''
            )}
            {!isReview && (
              <button
                onClick={() =>
                  navigate(`/user/service/review`, {
                    state: { user: publishService.userId, service: publishService },
                  })
                }
              >
                Add Review
              </button>
            )}
          </h5>
          <div className="reviews__list">
            {reviews.map((item) => (
              <div className="reviews__list-item">
                <div className="reviews__list-item-row1">
                  <div className="reviews__list-item-row1-user">
                    <div className="commenst__user-img">
                      {item.userId.avatarFileName ? (
                        <img
                          src={`${apiBaseURL}/uploads/avatar/${item.userId.avatarFileName}`}
                        />
                      ) : (
                        <div
                          style={{
                            backgroundColor: 'rgba(41, 52, 65, 1)',
                            borderRadius: '100%',
                            width: '100%',
                            height: '100%',
                            color: '#fff',
                            textAlign: 'center',
                            position: 'relative',
                          }}
                        >
                          <span
                            style={{ position: 'absolute', left: '13px', top: '12px' }}
                          >
                            {item.userId.fullName.slice(0, 2)}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="reviews__list-item-row1-user-name">
                      <p className="commenst__user-info-name">{item.userId.fullName}</p>
                      <Rating
                        name="reviews__list-item-row1-user-name-stars"
                        value={item.rate}
                        precision={0.5}
                        emptyIcon={<IconStarEmpty />}
                        icon={<IconStar />}
                        size="small"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="reviews__list-item-row1-date">
                    <p className="commenst__user-info-time">
                      {returnDate(moment(item.dateCreated).toDate())}
                    </p>
                  </div>
                </div>
                <div className="reviews__list-item-row2">
                  <h6 className="reviews__list-item-row2-text">{item.text}</h6>
                </div>
              </div>
            ))}
          </div>
          <div
            className="user__publish-service-phone"
            style={{
              position: 'static',
              width: '100%',
              padding: '13px 0',
              marginBottom: '15px',
            }}
          >
            <a href={`tel:${publishService.phone}`}>
              <div className="user__publish-service-phone-item-call">Call / SMS</div>
            </a>
            <div className="user__publish-service-phone-item-write" onClick={openChat}>
              Write
            </div>
          </div>
        </div>
      </div>
      {publishService.userId._id === _id && (
        <UserServiceMyModal
          item={publishService}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          updateNotification={updateNotification}
          type="service"
        />
      )}
      {publishService.userId._id !== _id && (
        <UserServiceOtherModal
          item={publishService}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          type="service"
          setPublishService={setPublishService}
        />
      )}
      {isOpenSave && (
        <TempNotification setIsOpen={setIsOpenSave} isOpen={isOpenSave}>
          <IconBookMark />
        </TempNotification>
      )}
    </div>
  ) : (
    <Loader />
  )
}
