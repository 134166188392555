import { useState } from 'react'
import {
  Button,
  Input,
  Space,
  Tabs,
  Tag,
  Layout,
  TabsProps,
  Form,
  notification,
  Flex,
} from 'antd'
import {
  ProfileSearchSelect,
  ProfileSearchSelectFormValues,
} from '../profile-standalone/layout/ProfileSearchSelect'
import interestsJson from '../../../utils/localization/locales/datasets/interests.en.json'
import skillsJson from '../../../utils/localization/locales/datasets/skills.en.json'

import { useAppSelector } from '../../../utils/hooks'
import { useNavigate } from 'react-router-dom'
import { useAttemptListener } from 'dev-masters-react-kit'
import { store } from '../../../store'
import { attemptUpdateInterests, attemptUpdateSkills } from '../../../redux/userNew'
import { INTERESTS_LIMIT, SKILLS_LIMIT } from '../../../utils/appConstants'
const { Content, Footer } = Layout

const items: TabsProps['items'] = [
  {
    key: 'skills',
    label: 'Skills',
    children: <Skills />,
  },
  {
    key: 'interests',
    label: 'Interests',
    children: <Interests />,
  },
]

export const ProfileInfoSkillsInterests = () => {
  return (
    <>
      <Tabs defaultActiveKey="skills" items={items} tabBarGutter={0} />
    </>
  )
}

function Skills() {
  const { skills } = useAppSelector((state) => state.userNew)
  const navigate = useNavigate()
  const isLoading = useAttemptListener({
    store,
    attempt: attemptUpdateSkills,
    onFulfilled: () => {
      notification.success({ message: 'Skills has been updated with success' })
    },
  })
  const [form] = Form.useForm<ProfileSearchSelectFormValues<'skills'>>()
  return (
    <>
      <ProfileSearchSelect
        translationNamespace="skillsList"
        formFieldName="skills"
        form={form}
        optionList={skillsJson}
        attemptUpdateAction={attemptUpdateSkills}
        placeholderLabel="Skills"
        initialValues={skills}
        maxCount={SKILLS_LIMIT}
      />
      <Button type="primary" block onClick={form.submit} disabled={isLoading}>
        {'Update Skills'}
      </Button>
    </>
  )
}

function Interests() {
  const [form] = Form.useForm<ProfileSearchSelectFormValues<'interests'>>()
  const { interests } = useAppSelector((state) => state.userNew)

  const navigate = useNavigate()
  const isLoading = useAttemptListener({
    store,
    attempt: attemptUpdateInterests,
    onFulfilled: () => {
      notification.success({ message: 'Interests has been updated with success' })
    },
  })
  return (
    <>
      <ProfileSearchSelect
        translationNamespace="interestsList"
        formFieldName="interests"
        optionList={interestsJson}
        form={form}
        attemptUpdateAction={attemptUpdateInterests}
        placeholderLabel="Interests"
        initialValues={interests}
        maxCount={INTERESTS_LIMIT}
      />

      <Button type="primary" block onClick={form.submit} disabled={isLoading}>
        {'Update Interests'}
      </Button>
    </>
  )
}
