import { createSelector } from '@reduxjs/toolkit'
import { AutoComplete, DatePicker, Form } from 'antd'
import dayjs from 'dayjs'
import { MdOutlineArrowForwardIos } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { attemptUpdateBirth } from '../../../../redux/userNew'
import { RootState, store } from '../../../../store'
import {
  EditUserProfileDTO,
  PROFILE_STEP,
} from '../../../../types/server-communication/user'
import { AGE_LIMIT } from '../../../../utils/appConstants'
import { useAppDispatch } from '../../../../utils/hooks'
import useAddressSearch from '../../../../utils/hooks/useAddressSearch'
import { useAttemptListener } from 'dev-masters-react-kit'
import { stepDefinitions } from '../../../../utils/hooks/useNextSetUpProfilePath'
import { StandaloneScreenLayout } from '../../../ui/Layout/StandaloneScreenLayout'
import FormSectionDivider from '../../../ui/new-components/FormSectionDivider'
import { EditProfileFormValues } from '../../profile-info/ProfileInfoEdit'

interface BirthFormValues
  extends Pick<EditProfileFormValues, 'cityBirth' | 'dateBirth'> {}

export const ProfileBirth = () => {
  const [birthForm] = Form.useForm<BirthFormValues>()

  const selectUser = (state: RootState) => state.userNew
  const userSelector = createSelector([selectUser], ({ cityBirth, dateBirth, role }) => {
    const _dateBirth = dateBirth && dayjs(dateBirth)
    return { cityBirth, dateBirth: _dateBirth, role }
  })
  const { cityBirth, dateBirth, role } = useSelector(userSelector)

  // useEffect(() => {
  //   const defaultValues = getDefaultValues()
  //   editProfileForm.setFieldsValue(defaultValues)
  // }, [
  //   fullName,
  //   aboutMe,
  //   dateBirth,
  //   cityBirth,
  //   education,
  //   studySchool,
  //   sex,
  //   orientation,
  //   familyStatus,
  //   nationality,
  //   certificates,
  // ])

  const currentCityBirth = Form.useWatch('cityBirth', birthForm)
  const currentDateBirth = Form.useWatch('dateBirth', birthForm)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const isLoading = useAttemptListener({
    store,
    attempt: attemptUpdateBirth,
    onFulfilled: () => {
      navigate('/profile/' + stepDefinitions[PROFILE_STEP.NATIONALITY].path)
    },
  })

  const { isLoading: isAddressLoading, options, handleSearchCity } = useAddressSearch()

  function handleSubmitForm(values: BirthFormValues) {
    const valuesDto: Pick<EditUserProfileDTO, 'dateBirth' | 'cityBirth'> = {
      dateBirth: values.dateBirth?.toISOString(),
      cityBirth: values.cityBirth,
    }
    dispatch(attemptUpdateBirth(valuesDto))
  }

  return (
    <StandaloneScreenLayout
      title="Date of birth"
      subTitle="Enter your date of birth for a personalized experience."
      onPrimaryButtonClicked={birthForm.submit}
      isDisabled={isLoading || !currentCityBirth || !currentDateBirth}
    >
      <Form
        form={birthForm}
        name="basic"
        layout="vertical"
        initialValues={{ cityBirth: cityBirth, dateBirth: dateBirth }}
        onFinish={handleSubmitForm}
      >
        <Form.Item name="dateBirth">
          <DatePicker
            className="form-date-picker"
            placeholder="Day of birth"
            format="DD/MM/YYYY"
            allowClear={false}
            showNow={false}
            maxDate={dayjs().subtract(AGE_LIMIT, 'years')}
            inputReadOnly={true}
            // TODO: locale={datePickerLocale}
          />
        </Form.Item>
        <FormSectionDivider title="Place of birth" />

        <Form.Item name="cityBirth">
          <AutoComplete
            placeholder={
              <span>
                Search <b>City</b>
              </span>
            }
            options={options}
            // options={options.filter(option => typeof option.label === 'string' && typeof option.value === 'string')}

            onSearch={handleSearchCity}
            suffixIcon={<MdOutlineArrowForwardIos />}
            allowClear
            dropdownAlign={{ offset: [0, 30] }}
          />
        </Form.Item>
      </Form>
    </StandaloneScreenLayout>
  )
}
