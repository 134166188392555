import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AdvertisementHttp } from '../../../http/advertisement'
import { UserHttp } from '../../../http/user-http'
import { UserInitialStateInterface } from '../../../redux/users'
import { Advertise } from '../../../types/types'
import { apiBaseURL, indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconLeftChevrons } from '../../svg/IconChevrons'
import { IconDrag } from '../../svg/IconDrag'
import { IconNeibs } from '../../svg/IconPassEye'
import { IconPrise } from '../../svg/IconPrise'
import { inside, insideCircle } from '../../../utils/inside'

export const AdvertisementFinish = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const { role } = useAppSelector((state) => state.user)
  const [ad, setAd] = useState<Advertise>()

  const [users, setUsers] = useState<UserInitialStateInterface[]>([])
  useEffect(() => {
    if (ad) {
      UserHttp.getAllUsers().then((res) => {
        res.map((item) => {
          if (
            item.coords &&
            ((ad.polygonCoords &&
              inside([item.coords?.lat, item.coords?.lng], ad.polygonCoords)) ||
              (ad.center &&
                insideCircle(
                  [item.coords.lat, item.coords.lng],
                  ad.radiusSize,
                  ad.center,
                )))
          ) {
            setUsers((s) => [...s, item])
          }
        })
      })
    }
  }, [ad])

  const [people, setPeople] = useState(0)
  useEffect(() => {
    if (users && ad?.budget) {
      setPeople(users.length * ad.budget * location.state.Duration)
    }
  }, [users])

  useEffect(() => {
    const effect = async () => {
      const res = await AdvertisementHttp.getAdvertisementById({
        adId: location.state.adId,
      })
      setAd(res)
    }
    effect()
  }, [])

  return (
    <div className="advertisement__add">
      <div className="advertisement__add-header">
        <div className="advertisement__add-header-btn" onClick={() => navigate(-1)}>
          <IconLeftChevrons fill="rgba(21, 16, 77, 1)" />
          <span>Preview</span>
        </div>
      </div>
      <div className="advertisement__add-budget">
        <div className="advertisement__add-gender-title">
          <h5>Check Details</h5>
          {ad?.budget && (
            <h6>
              Your predicted reach{' '}
              <b>
                {Math.round(people * 0.01)}-{Math.round(people * 0.02)}
              </b>
            </h6>
          )}
        </div>
      </div>
      <div className="advertisement__add-audience">
        <div
          style={{
            borderRadius: '8px',
            color: '#000',
            fontWeight: '500',
            fontSize: '16px',
          }}
          className="advertisement__add-audience-item"
          onClick={() => navigate('preview', { state: { adId: location.state.adId } })}
        >
          <img src={`${apiBaseURL}/uploads/advertisement/${ad?.fileName}`} alt="" />
          Preview advertisement
          <div className="advertisement__add-audience-item-svg">
            <IconLeftChevrons fill="rgba(195, 202, 217, 1)" />
          </div>
        </div>
        <div
          style={{ background: 'none' }}
          className="advertisement__add-audience-item"
          onClick={() => navigate('', { state: { adId: location.state.adId } })}
        >
          <div className="advertisement__add-audience-item-text">
            <IconDrag />
            <div>
              <h5>Adv. position</h5>
              <h6>Discovery | {ad?.position}</h6>
            </div>
          </div>
          <div className="advertisement__add-audience-item-svg">
            <IconLeftChevrons fill="rgba(195, 202, 217, 1)" />
          </div>
        </div>
        <div
          style={{ background: 'none' }}
          className="advertisement__add-audience-item"
          onClick={() => navigate('', { state: { adId: location.state.adId } })}
        >
          <div className="advertisement__add-audience-item-text">
            <IconNeibs />
            <div>
              <h5>Audience</h5>
              {ad?.age && (
                <h6>
                  {ad?.age[0]}-{ad?.age[1]} | Area | {ad.interests?.length} interests
                </h6>
              )}
            </div>
          </div>
          <div className="advertisement__add-audience-item-svg">
            <IconLeftChevrons fill="rgba(195, 202, 217, 1)" />
          </div>
        </div>
        <div
          style={{ background: 'none' }}
          className="advertisement__add-audience-item"
          onClick={() => {}}
        >
          <div className="advertisement__add-audience-item-text">
            <IconPrise />
            <div>
              <h5>Budget and duration</h5>
              <h6>
                {ad?.budget}$ for {location.state.Duration} days
              </h6>
            </div>
          </div>
          <div className="advertisement__add-audience-item-svg">
            <IconLeftChevrons fill="rgba(195, 202, 217, 1)" />
          </div>
        </div>
        <button
          className={`advertisement__add-main-btn`}
          onClick={() =>
            navigate(`${indexPathByRole(role)}/advertisement/payment-method`, {
              state: { adId: location.state.adId, amount: ad?.budget },
            })
          }
        >
          Continue
        </button>
      </div>
      {/* {role === ROLES.USER &&  
        <>
          <h6 className="advertisement__add-main-title">
              My Ads
          </h6>
          {ads.map((item)=>(
              <div className='advertisement-item'>
              <div className='advertisement-item-sponsor'>
                <div style={{display:"flex", gap:"10px"}}>
                <div
                      className="admin__posts-list-row1-img"
                      onClick={() =>{}
                      }
                  >
                      <img
                          src={avatarFileName ? `${baseURL}/uploads/avatar/${avatarFileName}` : "/Images/Profile.jpg"}
                          alt=""
                      />
                  </div>
                  <div className='advertisement-item-sponsor-text'>
                    <h5>
                      {fullName}
                    </h5>
                    <h6>
                      Sponsored
                    </h6>
                  </div>
                </div>
                  <button onClick={() => {}}>
                    <IconServicesAllPoint />
                  </button>
                </div>
                <div className='advertisement-item-img'>
                  <img src={`${baseURL}/uploads/advertisement/${item.fileName}`} />
                </div>
                <div className='advertisement-item-title'>
                  <h5>
                    {item.title}
                  </h5>
                  <h6>
                    {item.text}
                  </h6>
                </div>
              </div>
          ))}
        </>
      } */}
    </div>
  )
}
