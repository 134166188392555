import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Slider from 'react-slick'
import { ServiceHttp } from '../../../http/service-http'
import {
  CategoriesItemInterface,
  SubCategoriesItemInterface,
} from '../../../redux/categories'
import { apiBaseURL } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconLeftChevrons } from '../../svg/IconChevrons'
import { UserHeader } from '../../user-components/header/UserHeader'

export const PublishSelectSubCategory = () => {
  const settingsSecond = {
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    className: 'user__services-category-slick-ins',
  }
  const navigate = useNavigate()
  const location = useLocation()
  const [subCategories, setSubCategories] = useState<SubCategoriesItemInterface[]>([])
  const { publishServices } = useAppSelector((state) => state.categories)
  useEffect(() => {
    const EffectBody = async () => {
      const res: SubCategoriesItemInterface[] = await ServiceHttp.getAllSubService({
        id: location.state.category.categoryId,
      })
      setSubCategories(res)
    }
    EffectBody()
  }, [])

  const calculateServices = (category: CategoriesItemInterface) => {
    let count = 0
    publishServices.find((item) => {
      if (
        item?.categoryId?._id === category._id ||
        item?.subCategoryId?._id === category._id
      ) {
        count++
      }
    })
    return count
  }

  return (
    <div className="selectcategory" style={{ zIndex: '28' }}>
      <UserHeader>
        <div className="profileinfo__header">
          <button
            className="profileinfo__header-chevron"
            onClick={() => {
              navigate(-1)
            }}
          >
            <IconLeftChevrons />
          </button>
          <h5 className="profileinfo__header-text">Select Subcategory</h5>
        </div>
      </UserHeader>
      <div className="user__category" style={{ margin: '120px 10px' }}>
        <Slider {...settingsSecond}>
          {subCategories.map((item) => (
            <div
              className="user__services-category-item-body"
              onClick={() =>
                navigate('/publish/service', {
                  state: {
                    subCategory: item,
                    ...location.state,
                  },
                })
              }
            >
              <div className="user__services-category-item">
                <div className="user__services-category-item-img">
                  <img src={`${apiBaseURL}/uploads/categories/${item.fileName}`} alt="" />
                </div>

                <div className="user__services-category-item-text">
                  <b>{item.name}</b> <span>{calculateServices(item)}</span>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}
