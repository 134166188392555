import { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import $api from '../../../http'
import { UserInitialStateInterface } from '../../../redux/users'
import { LocationType } from '../../../types/types'
import { indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconLeftChevrons } from '../../svg/IconChevrons'
import { UserHeader } from '../header/UserHeader'
import { SliderItem } from './SliderItem'

export const MessegesGroupLocation = () => {
  const locationPath = useLocation()
  const navigate = useNavigate()

  const [MarksList, setMarksList] = useState<LocationType[]>([])
  const [users, setUsers] = useState<UserInitialStateInterface[]>([])
  const [location, setLocation] = useState<LocationType>()

  const { role } = useAppSelector((state) => state.user)

  const validate = !Boolean(location)

  var settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    vertical: true,
    verticalSwiping: true,
    arrows: false,
    centerMode: true,
  }
  useEffect(() => {
    const effectBody = async () => {
      const resMarksList: AxiosResponse<LocationType[]> =
        await $api.post('map/list-marks')
      // const res = await UserHttp.getAllUsers()

      // setUsers(res);
      setLocation(resMarksList.data[0])
      setMarksList(resMarksList.data)
    }
    effectBody()
  }, [])

  // const calculateUsers = (item:LocationType) => {
  //     let array:UserIdentityInterface[] = [];
  //     users.map(async(user)=>{
  //         const i = await IdentityHttp.getUserIdentity({_id:user._id})
  //         if(inside(item.coords, [i.polygonCoords]) || insideCircle(item.coords, i.radiusSize, [i.coordinates.lat, i.coordinates.lng])){
  //             array.push(i)
  //         }
  //     })
  //     if(array.length){
  //         return array.length
  //     }
  // }

  return (
    <div className="group">
      <UserHeader>
        <div className="user__header-messages explore__header">
          <button
            onClick={() => navigate(-1)}
            className="user__header-chatuser user__header-messages-exit"
          >
            <IconLeftChevrons />
          </button>
          <h5 className="user__header-title" style={{ margin: '40px 0' }}>
            Choose Area
          </h5>
        </div>
      </UserHeader>
      <div className="group__location">
        <div className="group__location-slider">
          {location &&
            MarksList.map((item) => (
              <SliderItem location={location} setLocation={setLocation} item={item} />
            ))}
        </div>
      </div>
      <button
        className={`advertisement__add-main-btn ${
          validate && 'advertisement__add-main-btn-disabled'
        }`}
        style={{
          position: 'fixed',
          zIndex: '33',
          top: '80%',
          maxWidth: '90%',
          left: '5%',
          padding: '14px 0',
        }}
        onClick={() => {
          navigate(`${indexPathByRole(role)}/messeges/group-interests`, {
            state: {
              title: locationPath.state.title,
              text: locationPath.state.text,
              file: locationPath.state.file,
              privacy: locationPath.state.privacy,
              location,
            },
          })
        }}
        disabled={validate}
      >
        next
      </button>
    </div>
  )
}
