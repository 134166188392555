export const IconLikeMap = ({ fill }: { fill: string }) => {
  return (
    <svg
      width="24"
      height="26"
      viewBox="0 0 30 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0792 7.4777L14.4679 6.76325C12.008 4.30335 8.0851 4.47098 5.6252 6.93088C3.1653 9.39078 3.63871 14.1466 6.09861 16.6065C7.09698 17.6049 8.59211 18.9872 10.584 20.7535L12.2597 22.2277L14.1419 23.8614C14.6601 24.3087 15.4258 24.3155 15.9519 23.8776L17.4766 22.5992C20.1467 20.3427 22.1281 18.5653 23.4209 17.2671L23.7563 16.9249L24.0577 16.6065C26.3486 14.136 26.9374 9.33357 24.5347 6.93088L24.3606 6.76325C21.8901 4.47231 18.1647 4.36056 15.762 6.76325L15.0792 7.4777ZM6.61498 7.92053C8.56013 5.97538 11.5694 5.88419 13.442 7.71712L15.0218 9.56356L16.774 7.73023C18.5712 5.93355 21.4354 5.95953 23.4088 7.7895L23.5637 7.93898C25.2615 9.63718 24.9727 13.561 23.0313 15.6546L22.7396 15.9626L22.4161 16.2925C21.3444 17.368 19.7563 18.8089 17.6622 20.6034L16.5731 21.53L15.0564 22.8016L13.1773 21.1705L11.8324 19.989C9.93725 18.3141 8.4753 16.9744 7.45169 15.9753L7.08839 15.6166C5.06195 13.5901 4.8085 9.72701 6.61498 7.92053Z"
        fill={fill}
      />
      <path
        d="M13.3416 7.36547C11.3989 5.4657 8.27697 5.56022 6.25897 7.5763C4.38485 9.44865 4.64779 13.4526 6.75012 15.5529L7.12702 15.9247C8.18896 16.9603 9.70566 18.3489 11.6717 20.0849L13.067 21.3094L15.0166 23L16.59 21.682L17.7199 20.7216C19.8924 18.8617 21.54 17.3682 22.6518 16.2535L22.9875 15.9116L23.29 15.5923C25.3042 13.4224 25.6038 9.35555 23.8424 7.59543L23.6817 7.44049C21.6344 5.54379 18.6629 5.51687 16.7984 7.37907L14.9807 9.27924L13.3416 7.36547Z"
        fill={fill}
        stroke={fill}
      />
    </svg>
  )
}
