import { ReactNode, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  ChatType,
  ParticipantType,
  PostUserInterface,
  PublishEventItemInterface,
  PublishServiceItemInterface,
} from '../../../types/types'
import { indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconAddNeib } from '../../svg/IconAddNeib'
import { IconMark } from '../../svg/IconMark'
import { IconNeibs } from '../../svg/IconPassEye'
import { IconsNewsfeedPlus } from '../../svg/IconsNewsfeed'
import { InputSearch } from '../../ui/InputSearch'
import { PublishModal } from '../../ui/PublishModal'
import { SlickCategories } from '../../ui/SlickCategories'
import { MessegesModalGroup } from '../messeges/MessegesModalGroup'
import { UserHeader } from './UserHeader'

export const UserHeaderChat = ({
  search,
  setSearch,
  isActiveSearch,
  setIsActiveSearch,
  chats,
  index,
}: {
  search: string
  setSearch: (s: string) => void
  chats?:
    | ChatType[]
    | PostUserInterface[]
    | PublishServiceItemInterface[]
    | PublishEventItemInterface[]
  index?: number

  isActiveSearch: boolean
  setIsActiveSearch: (s: boolean) => void
}) => {
  const { role } = useAppSelector((state) => state.user)
  const [currentSlide, setCurrentSlide] = useState(index || 0)
  const [openPublish, setOpenPublish] = useState<boolean>(false)
  const [isOpen, setisOpen] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    if (location.pathname.includes('open-chats')) {
      setCurrentSlide(4)
    }
    if (location.pathname.includes('support')) {
      setCurrentSlide(5)
    }
  }, [location.pathname])

  const toFriendUserChatList = () => {
    navigate(`${indexPathByRole(role)}/messeges/friend-user-chat`)
  }

  const toAllUserChatList = () => {
    navigate(`${indexPathByRole(role)}/messeges/all-user-chat`)
  }

  const onFocus = () => {
    setIsActiveSearch(true)
  }

  const onBlur = () => {
    setIsActiveSearch(false)
  }

  const toComments = (index: number, comment: string) => {
    setCurrentSlide(index)
    navigate(`${indexPathByRole(role)}/messeges/comments-${comment.replaceAll(' ', '-')}`)
    console.log(index, currentSlide)
  }
  return (
    <UserHeader>
      {!isActiveSearch && (
        <div className="user__header-main user__header-main-chat">
          <h5 className="user__header-title" onClick={() => navigate(`/user`)}>
            NeighborHarbor
          </h5>
          <div className="user__header-main-buttons">
            <button className="user__header-main-button" onClick={toAllUserChatList}>
              <IconAddNeib />
            </button>
            <button className="user__header-main-button" onClick={() => setisOpen(true)}>
              <IconNeibs />
            </button>
            <button className="user__header-main-button" onClick={toFriendUserChatList}>
              <IconsNewsfeedPlus />
            </button>
          </div>
        </div>
      )}

      <div
        className={`user__header-main-search ${
          isActiveSearch && 'user__header-main-search--active'
        }`}
      >
        <div className="user__header-main-search-input">
          <InputSearch
            placeholder={
              <>
                Search <span>NeighborChats</span>
              </>
            }
            value={search}
            changeValue={setSearch}
            onFocus={onFocus}
            onBlur={onBlur}
          />
          {isActiveSearch && (
            <button className="user__header-main-search--cancel">Cancel</button>
          )}
        </div>

        {!currentSlide ? (
          <>
            {chats?.length ? (
              <div className="activities__filter">
                <SlickCategories>
                  {[
                    'Personal',
                    'Groups',
                    'Services',
                    'Activities',
                    'Open Chats',
                    'Support',
                  ].map((item, index) => (
                    <div
                      className={`activities__filter-item ${
                        index === currentSlide && 'activities__filter-item--active'
                      }`}
                      key={index}
                      onClick={() => toComments(index, item.toLocaleLowerCase())}
                    >
                      {item}
                    </div>
                  ))}
                </SlickCategories>
              </div>
            ) : (
              ''
            )}
          </>
        ) : (
          <>
            <div className="activities__filter">
              <SlickCategories>
                {[
                  'Personal',
                  'Groups',
                  'Services',
                  'Activities',
                  'Open Chats',
                  'Support',
                ].map((item, index) => (
                  <div
                    className={`activities__filter-item ${
                      index === currentSlide && 'activities__filter-item--active'
                    }`}
                    key={index}
                    onClick={() => toComments(index, item.toLocaleLowerCase())}
                  >
                    {item}
                  </div>
                ))}
              </SlickCategories>
            </div>
          </>
        )}
      </div>
      {openPublish && <PublishModal setIsOpen={setOpenPublish} />}
      {isOpen && <MessegesModalGroup setIsOpen={setisOpen} isOpen={isOpen} />}
    </UserHeader>
  )
}
