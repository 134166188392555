export const IconDollar = () => {
  return (
    <svg
      width="16"
      height="24"
      viewBox="0 0 16 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.75 17C3 18.25 5.5 19.5 8 19.5C11.125 19.5 14.25 18.875 14.25 15.75C14.25 12.625 11.1869 12 8 12C4.875 12 1.75 11.375 1.75 8.25C1.75 5.125 4.875 4.5 8 4.5C10.5 4.5 13 5.75 14.25 7M8 0.75V23.25"
        stroke="black"
        strokeWidth="3"
      />
    </svg>
  )
}

export const IconDollarGray = () => {
  return (
    <svg
      style={{ margin: '0 5px' }}
      width="16"
      height="24"
      viewBox="0 0 16 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.75 17C3 18.25 5.5 19.5 8 19.5C11.125 19.5 14.25 18.875 14.25 15.75C14.25 12.625 11.1869 12 8 12C4.875 12 1.75 11.375 1.75 8.25C1.75 5.125 4.875 4.5 8 4.5C10.5 4.5 13 5.75 14.25 7M8 0.75V23.25"
        stroke="rgb(195, 202, 217)"
        strokeWidth="3"
      />
    </svg>
  )
}
