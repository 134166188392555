import { AxiosResponse } from 'axios'
import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import $api from '../../../http'
import { UserHttp } from '../../../http/user-http'
import { ChatType, MessageTypeNormal, MessageType } from '../../../types/types'
import { indexPathByRole, apiBaseURL } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { VoiceMessageShort } from '../../general-components/messenger/VoiceMessageShort'
import { IconLeftChevrons } from '../../svg/IconChevrons'
import { UserHeader } from '../header/UserHeader'

export const MessegesAudioInfo = ({ chat }: { chat: ChatType }) => {
  const location = useLocation()
  const navigate = useNavigate()

  const [messageList, setMessageList] = useState<MessageTypeNormal[]>([])

  useEffect(() => {
    const Effect = async () => {
      let array: MessageTypeNormal[] = []
      const resMessageList: AxiosResponse<MessageType[]> = await $api.post(
        'messenger/list-message',
        {
          chatId: location.state.chat.chatId,
        },
      )
      const users = await UserHttp.getAllUsers()
      resMessageList.data.map((message) => {
        if (message.audio && message.file) {
          users.find((user) => {
            if (user._id === message.senderId._id) {
              array.push({ ...message, senderName: user.fullName })
            }
          })
        }
      })
      if (array.length) {
        setMessageList(array)
      }
      normalDate(array[0])
    }
    Effect()
  }, [])

  const normalDate = (item: MessageTypeNormal) => {
    return (
      item?.timestamp?.toString().split('T')[1].split('.')[0] +
      '/' +
      item?.timestamp?.toString().split('T')[0]
    )
  }

  const { role } = useAppSelector((state) => state.user)
  return (
    <div>
      <UserHeader>
        <div
          className="user__header-messages"
          style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
        >
          {chat && (
            <div style={{ display: 'flex', gap: '10px' }}>
              <button
                onClick={() =>
                  navigate(
                    `${indexPathByRole(role)}/messeges/chat?chatId=${chat.chatId}`,
                    {
                      state: { chat },
                    },
                  )
                }
                className="user__header-chatuser user__header-messages-exit"
              >
                <IconLeftChevrons />
              </button>
              <div className="user__header-messages-img">
                {chat?.participants.length > 1 ? (
                  <img
                    src={
                      chat.groupImage
                        ? `${apiBaseURL}/uploads/messenger/${chat.groupImage}`
                        : '/Images/postsBG.png'
                    }
                    alt=""
                  />
                ) : (
                  <>
                    {chat.participants[0]?.userId?.avatarFileName ? (
                      <img
                        src={`${apiBaseURL}/uploads/avatar/${chat.participants[0]?.userId?.avatarFileName}`}
                      />
                    ) : (
                      <div
                        style={{
                          backgroundColor: 'rgba(41, 52, 65, 1)',
                          borderRadius: '100%',
                          width: '100%',
                          height: '100%',
                          color: '#fff',
                          textAlign: 'center',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        <span style={{ margin: 'auto', color: '#fff', fontSize: '14px' }}>
                          {chat.participants[0]?.userId?.fullName.slice(0, 2)}
                        </span>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div>
                {chat.name ? (
                  <div
                    className="user__header-messages-name"
                    style={{ margin: '10px 0' }}
                  >
                    {chat.name}
                  </div>
                ) : (
                  <>
                    <div
                      className="user__header-messages-name"
                      style={{ margin: '10px 0' }}
                    >
                      {chat.participants[0].userId.fullName}
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </UserHeader>
      <div className="group__info-audio">
        {messageList.map((item) => (
          <div className="group__info-audio-item">
            {item.file && (
              <VoiceMessageShort
                Audio={`${apiBaseURL}/uploads/messenger/${item.file}`}
                Side="l"
              />
            )}
            <div className="group__info-audio-item-text">
              <h5>{item.senderName}</h5>
              <h6>{normalDate(item)}</h6>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
