import { Link, useNavigate } from 'react-router-dom'
import { Button } from 'antd'
import { useAppSelector } from '../../../../utils/hooks'
import { indexPathByRole } from '../../../route/indexPathByRole'
import { StandaloneScreenLayout } from '../../../ui/Layout/StandaloneScreenLayout'

export const ProfileStayTouch = () => {
  const { role } = useAppSelector((state) => state.userNew)
  const navigate = useNavigate()

  return (
    <StandaloneScreenLayout
      isBackButtonPresented={false}
      centerTitle
      title="I want to stay in touch"
      subTitle="Stay connected with the community and never miss a beat"
      onPrimaryButtonClicked={() =>
        navigate('/profile/welcome-neibs')
      } /* Receive Notifications */
    >
      <div className="profile__method-body">
        <div className="profile__staytouch">
          <img src="/Images/setUpNotification.png" alt="" />
        </div>
      </div>
    </StandaloneScreenLayout>
  )
}
