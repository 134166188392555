import { useEffect } from 'react'
import { attemptGoogle } from '../../../redux/auth'
import { useAppDispatch } from '../../../utils/hooks'

export default function GoogleRedirect() {
  const dispatch = useAppDispatch()
  useEffect(() => {
    const queryParams = window.location.search
    dispatch(attemptGoogle(queryParams))
  }, [])

  return <></>
}
