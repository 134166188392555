import { createSlice } from '@reduxjs/toolkit'
import { hiddenService } from '../components/user-components/services/UserServicesMain'
import {
  GetAllPublishServicetInterface,
  PublishServiceItemInterface,
  PublishServiceOneItemInterface,
  Review,
  ServiceInterfaceMarked,
} from '../types/types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import $api from '../http'
import { ServiceHttp } from '../http/service-http'

export interface CategoriesItemInterface {
  _id: string
  name: string
  numberView: number
  isVisiable: boolean
  fileName: string
  categoryId?: string
}

export interface SubCategoriesItemInterface extends CategoriesItemInterface {
  subCategoryId?: string
}

interface State {
  isLoad: boolean
  categories: SubCategoriesItemInterface[]
  subCategories: CategoriesItemInterface[]
  markedServices: ServiceInterfaceMarked[]
  publishServices: PublishServiceItemInterface[]
  reviews: Review[]
  publishService: PublishServiceOneItemInterface | null
  hiddenService: hiddenService[]
}
export const initialState: State = {
  isLoad: false,
  categories: [],
  subCategories: [],
  markedServices: [],
  publishServices: [],
  reviews: [],
  publishService: null,
  hiddenService: [],
}

//<respons axios, payload >
/////////////////////////////////////get all
export const allCategories = createAsyncThunk<CategoriesItemInterface[], void>(
  'categories/all-categories',
  async () => {
    const response = await ServiceHttp.getAllService()
    return response
  },
)

export const allSubCategories = createAsyncThunk<
  SubCategoriesItemInterface[],
  { id: string }
>(`categories/sub-categories`, async (payload) => {
  const response = await ServiceHttp.getAllSubService(payload)
  return response
})

//////////////////////////// delete

export const deleteCategories = createAsyncThunk<string, { id: string }>(
  `categories/delete-category`,
  async (payload) => {
    const response = await $api.post(`categories/delete-category`, {
      categiryId: payload.id,
    })
    return response.data
  },
)

export const deleteSubCategories = createAsyncThunk<string, { id: string }>(
  `categories/delete-subcategory`,
  async (payload) => {
    const response = await $api.post(`categories/delete-subcategory`, {
      subCategiryId: payload.id,
    })
    return response.data
  },
)

///////////////////////// visiable

export const visiableCategories = createAsyncThunk<
  { id: string; isVisiable: boolean },
  { id: string; isVisiable: boolean }
>(`category/:id/update/visible`, async (payload) => {
  const response = await $api.post(`category/${payload.id}/update/visible`, {
    isVisible: payload.isVisiable,
  })
  return response.data
})

export const visiableSubCategories = createAsyncThunk<
  { id: string; isVisiable: boolean },
  { id: string; isVisiable: boolean }
>(`categories/visiable-subcategory`, async (payload) => {
  const response = await $api.post(`categories/visiable-subcategory`, payload)
  return response.data
})

export const getUserPublishService = createAsyncThunk<
  PublishServiceItemInterface[],
  { userId: any }
>(`categories/get-user-service`, async (payload) => {
  const response = await ServiceHttp.getUserPublishService(payload)
  return response
})
export const getHiddenService = createAsyncThunk<hiddenService[], { userId: any }>(
  `categories/get-hidden-services`,
  async (payload) => {
    const response = await ServiceHttp.getHiddenService(payload)
    return response
  },
)
export const getServicesWithMark = createAsyncThunk<
  ServiceInterfaceMarked[],
  { userId: any }
>('categories/get-publish-mark', async (payload) => {
  const response = await ServiceHttp.getServicesWithMark(payload)
  return response
})
export const getPublishServiceReview = createAsyncThunk<Review[], { serviceId: any }>(
  'categories/get-review',
  async (payload) => {
    const response = await ServiceHttp.getPublishServiceReview(payload)
    return response
  },
)
export const getUserPublishServiceReview = createAsyncThunk<Review[], { ownerId: any }>(
  'categories/get-user-reviews',
  async (payload) => {
    const response = await ServiceHttp.getUserPublishServiceReview(payload)
    return response
  },
)
export const getAllPublishService = createAsyncThunk<
  GetAllPublishServicetInterface,
  { pageNumber: number; subServicesId: string }
>(`categories/get-publish-service`, async (payload) => {
  const response = await ServiceHttp.getAllPublishService(payload)
  return response
})
export const getTenPublishService = createAsyncThunk<PublishServiceItemInterface[], void>(
  `categories/get-ten-publish-service`,
  async () => {
    const response = await ServiceHttp.getTenPublishService()
    return response
  },
)
export const getOnePublsihService = createAsyncThunk<
  PublishServiceOneItemInterface,
  { publishServiceId: string; userId: string }
>(`categories/get-one-publish-service`, async (payload) => {
  const response = await ServiceHttp.getOnePublsihService(payload)
  return response
})

export const categoriesReducer = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    addCategorie: (
      state,
      {
        payload,
      }: {
        payload: CategoriesItemInterface
      },
    ) => {
      state.categories = [...state.categories, payload]
    },
  },
  extraReducers: (builder) => {
    builder
      /////////////////////////////////GET all categories subcategories
      .addCase(allCategories.fulfilled, (state, { payload }) => {
        state.categories = payload
      })
      .addCase(allSubCategories.fulfilled, (state, { payload }) => {
        state.subCategories = payload
      })
      /////////////////////////////////DELETE all categories subcategories
      .addCase(deleteCategories.fulfilled, (state, { payload }) => {
        state.categories = state.categories.filter((item) => item._id !== payload)
      })
      .addCase(deleteSubCategories.fulfilled, (state, { payload }) => {
        state.subCategories = state.subCategories.filter((item) => item._id !== payload)
      })
      /////////////////////////////////// Visiable
      .addCase(visiableCategories.fulfilled, (state, { payload }) => {
        state.categories = state.categories.map((item) =>
          item._id === payload.id ? { ...item, isVisiable: payload.isVisiable } : item,
        )
      })
      .addCase(visiableSubCategories.fulfilled, (state, { payload }) => {
        state.subCategories = state.subCategories.map((item) =>
          item._id === payload.id ? { ...item, isVisiable: payload.isVisiable } : item,
        )
      })
      .addCase(getServicesWithMark.fulfilled, (state, { payload }) => {
        state.markedServices = payload
      })
      .addCase(getUserPublishService.fulfilled, (state, { payload }) => {
        state.publishServices = payload
      })
      .addCase(getTenPublishService.fulfilled, (state, { payload }) => {
        state.publishServices = payload
      })
      //TODO: check it, seems unused
      .addCase(getOnePublsihService.fulfilled, (state, { payload }) => {
        state.publishService = payload
      })
      .addCase(getPublishServiceReview.fulfilled, (state, { payload }) => {
        state.reviews = payload
      })
      .addCase(getUserPublishServiceReview.fulfilled, (state, { payload }) => {
        state.reviews = payload
      })
      .addCase(getHiddenService.fulfilled, (state, { payload }) => {
        state.hiddenService = payload
      })

      // was unused
      // .addCase(getAllPublishService.fulfilled, (state, { payload }) => {
      //   state.publishAllServices = payload
      // })

      .addMatcher(
        (action) => {
          return (
            action.type.endsWith('/pending') ||
            action.type.endsWith('/fulfilled') ||
            action.type.endsWith('/rejected')
          )
        },
        (state, action) => {
          state.isLoad = action.type.endsWith('/pending')
        },
      )
  },
})

export const { addCategorie } = categoriesReducer.actions
export default categoriesReducer.reducer
