import {
  Button,
  Drawer,
  Flex,
  FloatButton,
  InputNumber,
  Radio,
  Slider,
  Space,
} from 'antd'
import React, {
  Dispatch,
  Ref,
  SetStateAction,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react'
import { TbLayoutBottombarExpandFilled } from 'react-icons/tb'
import { INTERESTS_ZONE_RADIUS } from '../../../../utils/appConstants'

export interface ZoneDrawersMethods {
  toggleOpen: (isOpen: boolean) => void
}

export enum ZONE_INPUT_TYPE {
  POLYGON = 'POLYGON',
  CIRCLE = 'CIRCLE',
}

interface Props {
  inputMethod: ZONE_INPUT_TYPE | undefined
  setInputMethod: Dispatch<SetStateAction<ZONE_INPUT_TYPE | undefined>>
  radius: number
  onRadiusChanged: (value: number) => void
  onDrawerClosed: () => void
  shouldCloseOnRadiusChanged?: boolean
  onConfirm: () => void
}

const ZoneRadioDrawer = forwardRef(
  (
    {
      inputMethod,
      setInputMethod,
      radius,
      onRadiusChanged,
      onDrawerClosed,
      shouldCloseOnRadiusChanged,
      onConfirm,
    }: Props,
    ref: Ref<ZoneDrawersMethods>,
  ) => {
    useImperativeHandle(
      ref,
      (): ZoneDrawersMethods => ({
        toggleOpen: setIsDrawerOpen,
      }),
    )
    const [isDrawerOpen, setIsDrawerOpen] = useState(true)

    function onRadiusChange(value: number | null) {
      if (!value) {
        return
      }

      onRadiusChanged(value)
      if (shouldCloseOnRadiusChanged) {
        setIsDrawerOpen(false)
        onDrawerClosed()
      }
      setInputMethod(ZONE_INPUT_TYPE.CIRCLE)
    }

    return (
      <>
        {!isDrawerOpen && (
          <FloatButton
            icon={<TbLayoutBottombarExpandFilled />}
            onClick={() => setIsDrawerOpen(true)}
          />
        )}
        <Drawer
          height={'auto'}
          closable={false}
          onClose={() => {
            setIsDrawerOpen(false)
            onDrawerClosed()
          }}
          open={isDrawerOpen}
          placement="bottom"
          key={'zone-method-selector'}
        >
          <Radio.Group
            className="radio-btn-label-1st"
            value={inputMethod}
            onChange={(e) => {
              setInputMethod(e.target.value)
            }}
          >
            <Space direction="vertical">
              <Radio value={ZONE_INPUT_TYPE.POLYGON}>
                <RadioLabel
                  title="Draw on the map"
                  subtitle="Show data only within the specified zone"
                />
              </Radio>
              <Radio value={ZONE_INPUT_TYPE.CIRCLE}>
                <RadioLabel
                  title="Set local radius"
                  subtitle="Show data only within the specified radius"
                />
              </Radio>
            </Space>
          </Radio.Group>
          <Flex gap={10}>
            <Slider
              min={INTERESTS_ZONE_RADIUS.MIN}
              max={INTERESTS_ZONE_RADIUS.MAX}
              style={{ flex: 1 }}
              onChange={onRadiusChange}
              value={radius}
              tooltip={{ open: false }}
            />
            <InputNumber
              min={INTERESTS_ZONE_RADIUS.MIN}
              max={INTERESTS_ZONE_RADIUS.MAX}
              value={radius}
              type="number"
              onChange={onRadiusChange}
            />
          </Flex>
          <Button
            type="primary"
            block
            onClick={onConfirm}
          >
            Apply
          </Button>
        </Drawer>
      </>
    )
  },
)

function RadioLabel({ title, subtitle }: { title: string; subtitle: string }) {
  return (
    <Space direction="vertical" size={0} style={{ float: 'left' }}>
      <span className="location__modal-item-text-title">{title}</span>
      <span className="location__modal-item-text-sub">{subtitle}</span>
    </Space>
  )
}

ZoneRadioDrawer.displayName = 'ZoneRadioDrawer'
export default ZoneRadioDrawer
