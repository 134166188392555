import { Button, Flex, FormInstance, Layout, Row, Space, Typography } from 'antd'
import { Content, Footer, Header } from 'antd/es/layout/layout'
import { ReactElement, ReactNode } from 'react'
import { IoChevronBackOutline } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import './layouts.scss'
import { indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
const { Title, Paragraph } = Typography

interface LayoutProps {
  children?: ReactNode
  headerIcon?: ReactElement
  onPrimaryButtonClicked?: () => void
  onSecondButtonClicked?: () => void
  onBackButtonClicked?: () => void
  title: string
  subTitle: string | ReactNode
  centerTitle?: true
  isDisabled?: boolean
  isSecondButtonPresented?: boolean
  nextButtonTitle?: string
  secondButtonTitle?: string
  skipPath?: string
  isBackButtonPresented?: boolean
  backgroundImage?: string
}

export const StandaloneScreenLayout = ({
  children,
  headerIcon,
  onPrimaryButtonClicked,
  onSecondButtonClicked,
  onBackButtonClicked,
  title,
  subTitle,
  centerTitle,
  isSecondButtonPresented = true,
  isDisabled,
  nextButtonTitle,
  secondButtonTitle,
  isBackButtonPresented = true,
  backgroundImage,
}: LayoutProps) => {
  const navigate = useNavigate()
  const { role } = useAppSelector((state) => state.user)

  return (
    <div className="standalone-layout">
      {' '}
      {/* TODO: remove padding */}
      <Layout style={{ backgroundImage: backgroundImage }}>
        <Header style={{ textAlign: centerTitle ? 'center' : 'start' }}>
          {isBackButtonPresented && (
            <Button
              shape="circle"
              icon={<IoChevronBackOutline size={25} color="#000" />}
              onClick={() => (onBackButtonClicked ? onBackButtonClicked() : navigate(-1))}
            />
          )}
          {headerIcon && <div style={{ width: '3em' }}>{headerIcon}</div>}
          <Title level={4} style={{ alignItems: 'center' }}>
            {title}
          </Title>
          <Paragraph>{subTitle}</Paragraph>
        </Header>

        <Content>{children}</Content>
        <Footer
          style={{
            width: '100%',
          }}
        >
          <Flex vertical gap={20}>
            {isSecondButtonPresented && (
              <Button
                type="default"
                block
                onClick={() =>
                  onSecondButtonClicked
                    ? onSecondButtonClicked()
                    : navigate(`${indexPathByRole(role)}`)
                }
              >
                {secondButtonTitle ? secondButtonTitle : 'Setup later'}
              </Button>
            )}
            <Button
              type="primary"
              onClick={onPrimaryButtonClicked}
              block
              disabled={isDisabled}
            >
              {nextButtonTitle ? nextButtonTitle : 'Continue'}
            </Button>
          </Flex>
        </Footer>
      </Layout>
    </div>
  )
}
