import { useRef } from 'react'
import { attemptUpdateProfessions } from '../../../../redux/userNew'
import { PROFILE_STEP } from '../../../../types/server-communication/user'
import { PROFESSIONS_LIMIT } from '../../../../utils/appConstants'
import { useAppSelector } from '../../../../utils/hooks'
import professionsJson from '../../../../utils/localization/locales/datasets/professions.en.json'
import {
  ProfileSearchSelect,
  ProfileSearchSelectFormValues,
  SelectSearchKeyField,
} from '../layout/ProfileSearchSelect'
import { useAttemptListener } from 'dev-masters-react-kit'
import { stepDefinitions } from '../../../../utils/hooks/useNextSetUpProfilePath'
import { useNavigate } from 'react-router-dom'
import { Form } from 'antd'
import { StandaloneScreenLayout } from '../../../ui/Layout/StandaloneScreenLayout'
import { store } from '../../../../store'

export const ProfileProfessions = () => {
  const { professions } = useAppSelector((state) => state.userNew)
  const navigate = useNavigate()
  const isLoading = useAttemptListener({
    store,
    attempt: attemptUpdateProfessions,
    onFulfilled: () => {
      navigate('/profile/' + stepDefinitions[PROFILE_STEP.SKILLS].path)
    },
  })
  const [form] = Form.useForm<ProfileSearchSelectFormValues<'professions'>>()
  const currentSelection = Form.useWatch('professions', form)

  return (
    <StandaloneScreenLayout
      title="Profession"
      subTitle="Let others learn more about your professional journey"
      onPrimaryButtonClicked={form.submit}
      isDisabled={isLoading || !currentSelection || currentSelection?.length === 0}
    >
      <ProfileSearchSelect
        translationNamespace="professionsList"
        optionList={professionsJson}
        form={form}
        formFieldName="professions"
        attemptUpdateAction={attemptUpdateProfessions}
        placeholderLabel="Profession"
        initialValues={professions}
        maxCount={PROFESSIONS_LIMIT}
      />
    </StandaloneScreenLayout>
  )
}
