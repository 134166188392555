import { AxiosResponse } from 'axios'
import L, { LatLng } from 'leaflet'
import { useEffect, useState } from 'react'
import { Circle, GeoJSON, MapContainer, Polygon, TileLayer } from 'react-leaflet'
import { useLocation, useNavigate } from 'react-router-dom'
import $api from '../../../http'
import { UserHttp } from '../../../http/user-http'
import { UserInitialStateInterface } from '../../../redux/users'
import { ROLE } from '../../../types/enum'
import { LocationType } from '../../../types/types'
import { apiBaseURL } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconAddMarker } from '../../svg/IconAddMarker'
import { IconLeftChevrons } from '../../svg/IconChevrons'

import CACities from './ca-small-cities.json'
import UACities from '../../user-components/explore/UA-regions.json'

import FRCities from './fr-cities-small.json'

import { Feature, GeoJsonObject } from 'geojson'
import MarkerClusterGroup from 'react-leaflet-cluster'
import { IConAdd } from '../../svg/IconAdd'
import { IconNightMode } from '../../svg/IconNightMode'
import { IconNeibsCopy } from '../../svg/IconPassEye'
import { IconSearchMap } from '../../svg/IconSearchMap'
import { IconTicket } from '../../svg/IconTicket'
import { LocationMarker } from '../../ui/LocationMarker'
import { ExploreCancelIcon } from '../../user-components/explore/ExploreCancelIcon'
import { RegInterface } from '../../user-components/explore/ExploreCity'
import { ExploreHeader } from '../../user-components/explore/ExploreHeader'
import { AddLocalAdmin } from './AddLocalAdmin'
import { AddMarkerModal } from './AddMarkerModal'
import { CitySearch } from './CitySearch'
import { PolygonWithText } from './PolygonWithText'
import { SelectUserModal } from './SelectUserModal'
import { DrawModal } from '../../ui/DrawModal'
import { inside, insideCircle } from '../../../utils/inside'

export const AdminPanelMapPage = () => {
  const { coordinates } = useAppSelector((s) => s.profile)

  const [drawMode, setDrawMode] = useState<boolean>(false)
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)

  const [startAdmin, setStartAdmin] = useState<[number, number] | null>()
  const [endAdmin, setEndAdmin] = useState<[number, number] | null>()
  const [adminMode, setadminMode] = useState(false)
  const [radius, setRadius] = useState<boolean>(false)
  const [radiusValue, setRadiusValue] = useState<number>(1)
  const [draw, setDraw] = useState<boolean>(false)
  const [select, setSelect] = useState<boolean>(false)
  const [polyline, setPolyline] = useState<L.Polyline | null>()
  const [polylineMarker, setPolylineMarker] = useState<L.Polyline | null>()
  const [paint, setPaint] = useState<boolean>(false)
  const [startPos, setStartPos] = useState<[number, number]>()
  const [drawed, setDrawed] = useState<boolean>(false)
  const [polygonCoords, setPolygonCoords] = useState<
    LatLng[] | LatLng[][] | LatLng[][][] | undefined
  >([])
  const [userPoscurrent, setUserPoscurrent] = useState<[number, number]>()

  const [user, setUser] = useState<UserInitialStateInterface>()
  const [users, setUsers] = useState<UserInitialStateInterface[]>()

  const [MarksList, setMarksList] = useState<LocationType[]>([])
  const [isOpenMark, setIsOpenMark] = useState(false)
  const [fullScreen, setFullScreen] = useState(true)
  const [markPolygon, setMarkPolygon] = useState<L.Polygon | null>()
  const [markPolygonCoords, setMarkPolygonCoords] = useState<
    LatLng[] | LatLng[][] | LatLng[][][] | null
  >()
  const [markItem, setMarkItem] = useState<LocationType | null>()

  const [vassals, setVassals] = useState<UserInitialStateInterface[]>([])
  const [MarkerMode, setMarkerMode] = useState(false)
  const [posCurrent, setPosCurrent] = useState<L.LatLng | null>(null)
  const [polygon, setPolygon] = useState<L.Polygon | null>()
  const [pointlatlngCurrent, setPointlatlngCurrent] = useState<L.LatLng | null>(null)
  const [polygonRef, setPolygonRef] = useState<L.Polygon | null>()
  const [circleRef, setCircleRef] = useState<L.Circle | null>()

  const [isOpenSearch, setIsOpenSearch] = useState(false)

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [Night, setNight] = useState<boolean>(true)

  const navigate = useNavigate()

  const [map, setMap] = useState<L.Map | null>(null)

  const validate = !Boolean(userPoscurrent)

  const aplyMarker = () => {
    setDrawed(true)
    // setDraw(false);
    // setRadius(false)
  }

  useEffect(() => {
    const effectBody = async () => {
      const resMarksList: AxiosResponse<LocationType[]> =
        await $api.post('map/list-marks')
      console.log(resMarksList)
      setMarksList(resMarksList.data)
    }
    effectBody()
  }, [])

  const [myReg, setMyReg] = useState<RegInterface | null>(null)
  const [myPolygon, setMyPolygon] = useState<LatLng[]>([])

  const [selectedCity, setSelectedCity] = useState<Feature[]>([])

  map?.on('moveend', () => {
    if (radius) {
      map.dragging.enable()
      setUserPoscurrent([map?.getCenter().lat, map?.getCenter().lng])
    }
  })

  useEffect(() => {
    const effect = async () => {
      const res = (await UserHttp.getAllUsers()).filter(
        (item) => item.role === ROLE.LOCAL_ADMIN || item.role === ROLE.REGIONAL_ADMIN,
      )
      setUsers(res)
    }
    effect()
  }, [])

  useEffect(() => {
    if (vassals.length > 1) {
      setSelect(true)
    }
  }, [vassals])
  useEffect(() => {
    if (!drawed && !polyline && posCurrent && map && drawMode) {
      map?.dragging.disable()
      setPaint(true)
      setPolygonCoords([])
      setPolyline(L.polyline([]).addTo(map).addLatLng(posCurrent))
      setStartPos([posCurrent.lat, posCurrent.lng])
    }
  }, [posCurrent])

  const [isOpenLocal, setIsOpenLocal] = useState(false)
  const [regionalId, setRegionalId] = useState<string>()
  const checkInsideAdmin = () => {
    if (drawed && startAdmin && endAdmin) {
      users
        ?.filter((it) => it.role === ROLE.REGIONAL_ADMIN)
        .map((item) => {
          if (
            item.polygonCoords &&
            inside(startAdmin, item.polygonCoords) &&
            inside(endAdmin, item.polygonCoords)
          ) {
            setIsOpenLocal(true)
            setRegionalId(item._id)
          } else {
            // setDrawed(false);
            // setDrawMode(false);
            setPolygonCoords([])
            setPolygon(L.polygon([]))
          }
        })
    }
  }
  useEffect(() => {
    checkInsideAdmin()
  }, [drawed])

  if (map && draw && !polygonCoords?.length)
    L.DomEvent.on(map?.getContainer(), 'touchstart', (e) => {
      let touch = (e as TouchEvent).targetTouches[0],
        rect = map?.getContainer().getBoundingClientRect(),
        lat = touch.clientX - rect.left - map?.getContainer().clientLeft,
        lng = touch.clientY - rect.top - map?.getContainer().clientTop,
        containerPoint = L.point(lat, lng)

      let p = map?.containerPointToLayerPoint(containerPoint)
      let pointlatlng
      // console.log(pointlatlng)
      if (!paint && draw && !polyline && map && !polygon && !polygonCoords?.length) {
        // e.stopPropagation()

        // let point = L.point((e as TouchEvent).targetTouches[0].clientX, (e as TouchEvent).targetTouches[0].clientY);

        if (p) pointlatlng = map?.layerPointToLatLng(p)
        if (pointlatlng) {
          setPosCurrent(pointlatlng)
          setStartAdmin([pointlatlng.lat, pointlatlng.lng])
        }

        // setPolygonCords([]);
      }
    })
  if (!draw && map) L.DomEvent.off(map?.getContainer(), 'touchstart', (e) => {})
  useEffect(() => {
    if (drawed && !polygonCoords?.length && map) {
      setPolygonCoords(polyline?.getLatLngs())
      polyline?.setLatLngs([])
      polyline?.removeFrom(map)
      setPolyline(undefined)
      setDraw(false)
      map?.dragging.enable()
    }
  }, [drawed])

  if (map && draw && !polygonCoords?.length)
    L.DomEvent.on(map?.getContainer(), 'touchend', (e) => {
      if (
        map &&
        polyline?.getLatLngs().length &&
        draw &&
        !polygon &&
        !polygonCoords?.length
      ) {
        let touch = (e as TouchEvent).changedTouches[0],
          rect = map?.getContainer().getBoundingClientRect(),
          lat = touch.clientX - rect.left - map?.getContainer().clientLeft,
          lng = touch.clientY - rect.top - map?.getContainer().clientTop,
          containerPoint = L.point(lat, lng)

        let p = map?.containerPointToLayerPoint(containerPoint)
        let pointlatlng

        if (p) pointlatlng = map?.layerPointToLatLng(p)

        if (pointlatlng) setEndAdmin([pointlatlng.lat, pointlatlng.lng])

        setDrawed(true)
        e.stopImmediatePropagation()
      }
    })
  if (drawed && map) L.DomEvent.off(map?.getContainer(), 'touchmove', (e) => {})
  useEffect(() => {
    if (pointlatlngCurrent && draw) {
      polyline?.addLatLng(pointlatlngCurrent)
    }
  }, [pointlatlngCurrent])

  if (map && draw && !polygonCoords?.length)
    L.DomEvent.on(map?.getContainer(), 'touchmove', (e) => {
      if (!polygonCoords?.length && paint && draw && !polygon && !polygonCoords?.length) {
        let touch = (e as TouchEvent).targetTouches[0],
          rect = map?.getContainer().getBoundingClientRect(),
          lat = touch.clientX - rect.left - map?.getContainer().clientLeft,
          lng = touch.clientY - rect.top - map?.getContainer().clientTop,
          containerPoint = L.point(lat, lng)

        let p = map?.containerPointToLayerPoint(containerPoint)
        let pointlatlng

        if (p) pointlatlng = map?.layerPointToLatLng(p)
        if (startPos?.length && pointlatlng && !drawed) {
          if (!polyline?.getLatLngs().length && map) setPolyline(L.polyline([startPos]))
          setPointlatlngCurrent(pointlatlng)
          // polyline?.addLatLng(pointlatlng)
          if (
            (insideCircle([pointlatlng.lat, pointlatlng.lng], 0.01, startPos) ||
              (pointlatlng.lat === startPos[0] && pointlatlng.lng === startPos[1])) &&
            map &&
            polyline?.getLatLngs().length
          ) {
            setEndAdmin([pointlatlng.lat, pointlatlng.lng])
            setPolygonCoords(polyline?.getLatLngs())
            polyline?.setLatLngs([])
            polyline?.removeFrom(map)
            setPolyline(undefined)
            setDraw(false)
            map?.dragging.enable()
            setDrawed(true)
            e.stopImmediatePropagation()
          }
        }
      }
    })
  const { fullName, avatarFileName } = useAppSelector((state) => state.user)
  const { houseNumber, street, country } = useAppSelector((state) => state.profile)
  const location = useLocation()
  useEffect(() => {
    if (radius && map) {
      setUserPoscurrent([map?.getCenter().lat, map?.getCenter().lng])
    }
  }, [radius])
  const [myPos, setMyPos] = useState<GeolocationPosition>()
  const [focus, setFocus] = useState(false)
  useEffect(() => {
    navigator.geolocation.getCurrentPosition((pos) => {
      setMyPos(pos)
    })
  }, [])
  const onEachCity = (city: Feature, layer: L.Layer) => {
    layer.on({
      click: (e) => {
        e.target.setStyle({
          fillOpacity: 0.3,
          stroke: true,
        })
      },
    })
  }
  return (
    <div>
      <ExploreHeader
        style={
          !Night
            ? {
                background:
                  'linear-gradient(rgba(245, 246, 247, 1), rgba(245, 246, 247, 0))',
                margin: '100px 0',
              }
            : {
                background: 'linear-gradient(rgba(15, 20, 25, 1), rgba(15, 20, 25, 0))',
                margin: '100px 0',
              }
        }
      >
        <div className="explore__main-header" style={{ gap: '5px' }}>
          <div className="explore__main-header-user" style={{ margin: 'auto 0' }}>
            <button onClick={() => navigate(`/admin`)}>
              {Night ? (
                <IconLeftChevrons />
              ) : (
                <IconLeftChevrons fill="rgba(38, 38, 38, 1)" />
              )}
            </button>
            {avatarFileName ? (
              <img src={`${apiBaseURL}/uploads/avatar/${avatarFileName}`} />
            ) : (
              <div
                style={{
                  backgroundColor: 'rgba(41, 52, 65, 1)',
                  borderRadius: '100%',
                  width: '40px',
                  height: '40px',
                  color: '#fff',
                  textAlign: 'center',
                  position: 'relative',
                }}
              >
                <span style={{ position: 'absolute', left: '10px', top: '8px' }}>
                  {fullName.slice(0, 2)}
                </span>
              </div>
            )}
            {!Night ? (
              <div className="explore__main-header-user-location">
                <h6 style={{ color: 'rgba(99, 106, 117, 1)' }}>current location</h6>
                <h5 style={{ color: 'rgba(31, 32, 36, 1)' }}>
                  {houseNumber ? houseNumber + ',' : ''} {street ? street + ',' : ''}{' '}
                  {country}
                </h5>
              </div>
            ) : (
              <div className="explore__main-header-user-location">
                <h6>current location</h6>
                <h5>
                  {houseNumber ? houseNumber + ',' : ''} {street ? street + ',' : ''}{' '}
                  {country}
                </h5>
              </div>
            )}
          </div>
          <div
            className={'admin__panel-addMarker explore__location__follow'}
            onClick={() => {
              if (myPos)
                map?.flyTo(
                  { lat: myPos?.coords.latitude, lng: myPos?.coords.longitude },
                  16,
                )
              setFocus(true)
            }}
          >
            <IconAddMarker />
          </div>
        </div>
      </ExploreHeader>
      {/* {<div style={{position:"absolute", zIndex:"20", right:"10px", top:"100px"}} className={!drawMode ? "admin__panel-addMarker explore__location__follow":"admin__panel-addMarker explore__location__follow clicked"} id="draw" onClick={()=>{
                      setDrawMode(!drawMode);
                        setadminMode(true);
                  }}>
                      <IconPencil color="#fff"/>
                  </div> }    */}
      {/* {
                    <div style={{ position:"absolute", zIndex:"20", right:"20px", top:"180px"}} className={"admin__panel-addMarker"} onClick={()=>{setMarkerMode(true);setDrawMode(!drawMode)}}>
                    <IconAddMarker />
                </div> 
                  } */}
      <MapContainer
        center={location.state?.center || [coordinates.lat, coordinates.lng]}
        zoom={location.state?.zoom || 19}
        minZoom={4}
        scrollWheelZoom={true}
        zoomControl={false}
        ref={setMap}
      >
        {!Night ? (
          <>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
              url="https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png"
              subdomains="abcd"
            />
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
              url="https://{s}.basemaps.cartocdn.com/dark_only_labels/{z}/{x}/{y}{r}.png"
              subdomains="abcd"
            />
          </>
        ) : (
          <>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
              url="https://{s}.basemaps.cartocdn.com/dark_nolabels/{z}/{x}/{y}{r}.png"
            />
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
              url="https://{s}.basemaps.cartocdn.com/dark_only_labels/{z}/{x}/{y}{r}.png"
              subdomains="abcd"
            />
          </>
        )}

        <GeoJSON
          data={FRCities as GeoJsonObject}
          key={map?.getZoom()}
          style={
            Night
              ? {
                  fillOpacity: 0.1,
                  stroke: true,
                  fillColor: '#fff',
                  color: '#fff',
                }
              : {
                  fillOpacity: 0.1,
                  stroke: true,
                  fillColor: 'blue',
                  color: 'blue',
                }
          }
          onEachFeature={onEachCity}
        />
        <GeoJSON
          data={CACities as GeoJsonObject}
          key={map?.getZoom()}
          style={
            Night
              ? {
                  fillOpacity: 0.1,
                  stroke: true,
                  fillColor: '#fff',
                  color: '#fff',
                }
              : {
                  fillOpacity: 0.1,
                  stroke: true,
                  fillColor: 'blue',
                  color: 'blue',
                }
          }
          onEachFeature={onEachCity}
        />
        <GeoJSON
          data={UACities as GeoJsonObject}
          key={map?.getZoom()}
          style={
            Night
              ? {
                  fillOpacity: 0.1,
                  stroke: true,
                  fillColor: '#fff',
                  color: '#fff',
                }
              : {
                  fillOpacity: 0.1,
                  stroke: true,
                  fillColor: 'blue',
                  color: 'blue',
                }
          }
          onEachFeature={onEachCity}
        />
        {/* <AdminMarker enable={MarkerMode} setCoords={setMousePos} setIsOpen={setIsOpenMarkers}/>  */}
        {polygonCoords?.length && (
          <Polygon positions={polygonCoords} ref={setPolygonRef} />
        )}
        {endAdmin && (
          <ExploreCancelIcon
            handler={() => {
              // setPolygonCoords([]);
              // setDrawed(false);
              // setadminMode(false);
              // setDrawMode(false);
              // setPaint(false);
              // setMarkerMode(false);
              // setDraw(false);
              // setPolygonRef(null);
              // setPolygon(null);
              // setPolyline(null)
              // setStartAdmin(null);
              // setEndAdmin(null);
              // setStartPos(undefined);
              navigate('', { state: { center: map?.getCenter(), zoom: map?.getZoom() } })
              window.location.reload()
              // setPosCurrent(null);
              // setMarkPolygonCoords(null);
              // map?.dragging.enable()
            }}
            position={endAdmin}
          />
        )}
        {radius && userPoscurrent && (
          <Circle
            center={userPoscurrent}
            radius={radiusValue * 1000}
            ref={setCircleRef}
          />
        )}
        {users
          ?.filter((it) => it.role === ROLE.LOCAL_ADMIN)
          ?.map((item) => (
            <>
              {item.polygonCoords?.length && (
                <PolygonWithText
                  vassals={vassals}
                  setVassals={setVassals}
                  item={item}
                  drawMode={drawMode}
                  local={true}
                />
              )}
            </>
          ))}
        {users
          ?.filter((it) => it.role === ROLE.REGIONAL_ADMIN)
          ?.map((item) => (
            <>
              {item.polygonCoords?.length && (
                <PolygonWithText
                  vassals={vassals}
                  setVassals={setVassals}
                  item={item}
                  drawMode={drawMode}
                  setStart={setStartAdmin}
                />
              )}
            </>
          ))}
        <MarkerClusterGroup
          chunkedLoading
          spiderLegPolylineOptions={{
            opacity: 0,
          }}
        >
          {MarksList &&
            MarksList.map((item) => (
              <LocationMarker
                markerData={item}
                handler={() => {
                  setIsOpenMark(true)
                  setFullScreen(true)
                  setMarkPolygonCoords(item.polygonCoords)
                  setMarkItem(item)
                }}
              />
            ))}
        </MarkerClusterGroup>
        {isOpenMark && markPolygonCoords && map && (
          <>
            <Polygon
              fillOpacity={0.3}
              fillColor="red"
              color="white"
              positions={markPolygonCoords}
            />
            <ExploreCancelIcon
              handler={() => {
                if (markItem) {
                  let index = MarksList.indexOf(markItem)
                  let array = [...MarksList]
                  if (index !== -1) {
                    array.splice(index, 1)
                    setMarksList(array)
                    setIsOpenMark(false)
                  }
                }
              }}
              position={[
                L.polygon(markPolygonCoords)
                  .addTo(map)
                  .setStyle({ stroke: false, opacity: 0, fillOpacity: 0 })
                  .getCenter().lat,
                L.polygon(markPolygonCoords)
                  .addTo(map)
                  .setStyle({ stroke: false, opacity: 0, fillOpacity: 0 })
                  .getCenter().lng,
              ]}
            />
          </>
        )}
        {isOpenMark && markItem && markItem.radiusSize && (
          <>
            <Circle
              center={markItem.center}
              fillColor="red"
              color="white"
              radius={markItem.radiusSize}
            />
            <ExploreCancelIcon
              handler={() => {
                if (markItem) {
                  let index = MarksList.indexOf(markItem)
                  let array = [...MarksList]
                  if (index !== -1) {
                    array.splice(index, 1)
                    setMarksList(array)
                    setIsOpenMark(false)
                  }
                }
              }}
              position={[markItem.center.lat, markItem.center.lng]}
            />
          </>
        )}
        {myPolygon.length && (
          <Polygon
            fillOpacity={0.2}
            fillColor="white"
            color="white"
            positions={myPolygon}
          />
        )}
      </MapContainer>
      {!isOpen && (
        <div className="map__menu" style={{ marginTop: '10%' }}>
          <div
            style={
              Night
                ? { backgroundColor: 'rgba(41, 52, 65, 1)' }
                : { backgroundColor: '#fff' }
            }
            className="map__menu-item-admin"
            onClick={() => {
              setNight(!Night)
            }}
          >
            <IconNightMode fill={Night ? '#fff' : '#262626'} />
          </div>
          <div
            style={
              Night
                ? { backgroundColor: 'rgba(41, 52, 65, 1)' }
                : { backgroundColor: '#fff' }
            }
            className="map__menu-item-admin"
            onClick={() => setIsOpenSearch(true)}
          >
            <IconSearchMap fill={Night ? '#fff' : '#262626'} />
          </div>
          <div
            style={
              Night
                ? { backgroundColor: 'rgba(41, 52, 65, 1)', color: '#fff' }
                : { backgroundColor: '#fff', color: '#262626' }
            }
            className="map__menu-item-admin"
            onClick={() => {
              setMarkerMode(true)
              setIsOpenModal(!isOpenModal)
              setDrawMode(true)
            }}
          >
            <b>
              <span>
                <IConAdd
                  fill={Night ? 'rgba(255, 255, 255, 1)' : 'rgba(38, 38, 38, 1)'}
                />
              </span>
            </b>
          </div>
          <div
            style={
              Night
                ? { backgroundColor: 'rgba(41, 52, 65, 1)' }
                : { backgroundColor: '#fff' }
            }
            className="map__menu-item-admin"
            onClick={() => navigate(`/admin/payment`)}
          >
            <IconTicket fill={Night ? '#fff' : '#262626'} />
          </div>
          <div
            style={
              Night
                ? { backgroundColor: 'rgba(41, 52, 65, 1)' }
                : { backgroundColor: '#fff' }
            }
            className="map__menu-item-admin"
            onClick={() => {
              setIsOpenModal(!isOpenModal)
              setadminMode(true)
              setDrawMode(true)
            }}
          >
            <IconNeibsCopy fill={Night ? '#fff' : '#262626'} />
          </div>
        </div>
      )}
      {/* {radius && 
                    <button
                    style={{zIndex:"4", bottom:"20px", position:"fixed", left:"5%", width:"90%"}}
                    className={`location__bt-continue login__button
                    ${
                        validate
                            ? "location__bt-continue--disabled"
                            : ""
                    }`}
                    disabled={validate}
                    onClick={()=>{
                        setDrawed(true)
                    }}
                >
                    Confirm 
                </button>
                } */}
      {isOpenSearch && map && (
        <CitySearch map={map} setIsOpen={setIsOpenSearch} isOpen={isOpenSearch} />
      )}
      {isOpenModal && adminMode && map && (
        <DrawModal
          aply={aplyMarker}
          map={map}
          setPolyline={setPolyline}
          draw={draw}
          setDraw={setDraw}
          setRadius={setRadius}
          radius={radius}
          setRadiusSize={setRadiusValue}
          radiusSize={radiusValue}
          isOpen={isOpenModal}
          setIsOpen={setIsOpenModal}
        />
      )}
      {isOpenModal && MarkerMode && map && (
        <DrawModal
          aply={aplyMarker}
          map={map}
          setPolyline={setPolylineMarker}
          draw={draw}
          setDraw={setDraw}
          setRadius={setRadius}
          radius={radius}
          setRadiusSize={setRadiusValue}
          radiusSize={radiusValue}
          isOpen={isOpenModal}
          setIsOpen={setIsOpenModal}
        />
      )}
      {drawed && MarkerMode && polygonCoords && polygonRef && (
        <AddMarkerModal
          center={polygonRef.getCenter()}
          isOpen={drawed}
          setIsOpen={setDrawed}
          updateMarks={() => {}}
          polygonCoords={polygonCoords}
          setRadius={setRadius}
        />
      )}
      {drawed && MarkerMode && radiusValue && circleRef && (
        <AddMarkerModal
          center={circleRef.getLatLng()}
          isOpen={drawed}
          setIsOpen={setDrawed}
          updateMarks={() => {}}
          radiusSize={circleRef.getRadius()}
          setRadius={setRadius}
        />
      )}
      {drawed && adminMode && polygonCoords && !isOpenLocal && (
        <SelectUserModal
          polygon={polygonCoords}
          setIsOpen={setadminMode}
          setUser={setUser}
          isOpen={adminMode}
          setRadius={setRadius}
        />
      )}
      {isOpenLocal && regionalId && polygonCoords && (
        <AddLocalAdmin
          polygonCoords={polygonCoords}
          regionalId={regionalId}
          setIsOpen={setIsOpenLocal}
          isOpen={isOpenLocal}
        />
      )}
      {/* {
                select &&
                <SelectRegionAdminModal setIsOpen={setSelect} isOpen={select} vassals={vassals}/>
            } */}
    </div>
  )
}
export interface CityType {
  coords: [number, number]
  name: string
  country: string
}
