import { AxiosResponse } from 'axios'
import $api from '.'
import { BankCard } from '../types/types'
import { CARD } from '../types/enum'

export class StripeHttp {
  static async addCard(payload: {
    userId: string
    cardNumber: number
    cardMonth: number
    cardYear: number
    cardCvv: number
    cardHolder: string
    cardType: CARD
  }) {
    const res: AxiosResponse<BankCard> = await $api.post('stripe/add-card', payload)
    return res.data
  }

  static async getCards(payload: { userId: string }): Promise<BankCard[]> {
    const res: AxiosResponse<BankCard[]> = await $api.post('stripe/get-cards', payload)
    return res.data
  }

  static async payWithCard(payload: { adId: string; amount: number }) {
    const res = await $api.post('stripe/pay-with-card', payload)
    return res.data
  }
}
