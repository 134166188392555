import { MuiTelInput } from 'mui-tel-input'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ServiceHttp } from '../../../http/service-http'
import {
  CategoriesItemInterface,
  SubCategoriesItemInterface,
} from '../../../redux/categories'
import { setValueProfileReducer } from '../../../redux/profile'
import { profileTextInfo } from '../../../redux/profile'
import { PRIVACY } from '../../../types/enum'
import { CoordinatsInterface, PublishServiceItemInterface } from '../../../types/types'
import { apiBaseURL, indexPathByRole } from '../../route/indexPathByRole'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { IconMenu } from '../../svg/IconMenu'
import { IconLocationPoint } from '../../svg/IconsLocation'
import { success } from '../../ui/LoadSuccess'
import { PublishAddLocation } from './PublishAddLocation'
import { PublicationMainComponent } from './PublishMainComponent'

export const PublishService = ({
  currentPrivacy,
  allowList,
}: {
  currentPrivacy: PRIVACY
  allowList: string[]
}) => {
  const navigate = useNavigate()
  const location = useLocation()

  const props: {
    item: PublishServiceItemInterface
  } = location.state?.props

  const { _id, role } = useAppSelector((state) => state.user)
  const profile = useAppSelector((state) => state.profile)
  const dispatch = useAppDispatch()

  const [isOpenMap, setisOpenMap] = useState(false)
  const [polygonCoords, setPolygonCoords] = useState<
    L.LatLng[] | L.LatLng[][] | L.LatLng[][][] | null
  >(location?.state?.polygonCoords || null)
  const [radiusSize, setRadiusSize] = useState<number>(location?.state?.radiusSize || 0)
  const [coordinates, setCoordinates] = useState<CoordinatsInterface>(
    location?.state?.coords,
  )
  const [files, setFiles] = useState<(File | string)[]>(
    location?.state?.files ||
      props?.item?.filesName?.map((it) => `${apiBaseURL}/uploads/publish_post/${it}`) ||
      [],
  )
  const [text, setText] = useState(location.state?.text || props?.item?.text || '')
  const [title, setTitle] = useState(location.state?.title || props?.item?.title || '')
  const [addPhone, setAddPhone] = useState(false)
  const [category, setCategory] = useState<CategoriesItemInterface>(
    location.state?.category,
  )
  const [subCategory, setSubCategory] = useState<SubCategoriesItemInterface>(
    location.state?.subCategory,
  )
  const [phone, setPhone] = useState(props?.item.phone || '')
  const [addressLocation, setAddressLocation] = useState(
    `${profile.country}, ${profile.city}, ${profile.street}, ${profile.houseNumber}`,
  )

  const validPhone = (phone: string) => {
    return (
      phone.split(' ').slice(1, phone.split(' ').length).toString().replaceAll(',', '')
        .length == 10
    )
  }

  const validate = !Boolean(
    text && title && location.state?.subCategory?._id && validPhone(phone),
  )

  useEffect(() => {
    if (location.state?.subCategory) {
      setTitle(location.state?.title)
      setText(location.state?.text)
    }
    console.log(location.state)
  }, [])

  const handlerPublish = async () => {
    try {
      const formCatData = new FormData()
      let payload: {
        text: string
        title: string
        userId: string
        coordinates: CoordinatsInterface
        privacyPublishService: PRIVACY
        servicesId: string
        subServicesId: string
        userIdentityId: string
        addressLocation: string
        allowList: string[]
        phone: string
        radiusSize: number
        polygonCoords: L.LatLng[] | L.LatLng[][] | L.LatLng[][][] | null
      } = {
        text,
        title,
        coordinates,
        userId: _id,
        privacyPublishService: currentPrivacy,
        servicesId: location.state.category?._id,
        subServicesId: location.state.subCategory?._id,
        userIdentityId: profile.userIdentityId,
        addressLocation,
        allowList,
        phone,
        radiusSize,
        polygonCoords,
      }
      formCatData.append('payload', JSON.stringify(payload))

      for (let index = 0; index < files.length; index++) {
        formCatData.append('files', files[index])
      }

      await ServiceHttp.addPublishService(formCatData)

      if (!profile.isAddedServices) {
        const res = await profileTextInfo({
          isAddedServices: true,
          _id,
        })
        dispatch(setValueProfileReducer(res))
      }

      success()
      navigate(`${indexPathByRole(role)}/service`)
    } catch (error) {
      console.log('publish post new' + error)
    }
  }
  const handleChange = (newPhone: string) => {
    setPhone(newPhone)
  }
  return (
    <div className="user">
      <PublicationMainComponent
        files={files}
        setFiles={setFiles}
        text={text}
        setText={setText}
        title={title}
        setTitle={setTitle}
        placeholderTitle="Service Name"
        placeholderText="describe your service ?"
      />
      <div className="publish__service-categorie">
        {/* <PublishServiceCategorie
                    servicesValue={servicesValue}
                    setServicesValue={setServicesValue}
                    subServicesValue={subServicesValue}
                    setSubServicesValue={setSubServicesValue}
                /> */}
        {subCategory && (
          <div className="publish__service-category-card">
            <img src={`${apiBaseURL}/uploads/categories/${category?.fileName}`} alt="" />
            <div className="publish__service-category-card-text">
              <h5>{category?.name}</h5>
              <h6>{subCategory?.name}</h6>
            </div>
            <span
              className="publish__service-category-card-btn"
              onClick={() => {
                navigate(`/publish/service/category`, { state: { title, text, files } })
              }}
            >
              Edit
            </span>
          </div>
        )}
        {!subCategory && (
          <button
            className="publish__location-button"
            style={{ margin: '5px 0' }}
            onClick={() => {
              navigate(`/publish/service/category`, { state: { title, text, files } })
            }}
          >
            <IconMenu />
            Select Category
          </button>
        )}
      </div>
      <PublishAddLocation
        setCoordinates={setCoordinates}
        addressLocation={addressLocation}
        setAddressLocation={setAddressLocation}
        polygonCoords={polygonCoords}
        radiusSize={radiusSize}
        coords={coordinates}
        prevStateService={{ title, text, category, subCategory, files }}
        type="service"
      />
      <button
        style={{ margin: '24px 0' }}
        className="publish__location-button"
        onClick={() => setAddPhone(!addPhone)}
      >
        <IconLocationPoint />
        Add Phone
      </button>
      {addPhone && (
        <MuiTelInput
          placeholder="+ 1"
          disableFormatting={false}
          defaultCountry="CA"
          onlyCountries={['FR', 'CA']}
          value={phone}
          onChange={handleChange}
        />
      )}
      <button
        className={`publish__publish user--footer--button ${
          validate && 'services__add-button--disabled'
        }`}
        onClick={handlerPublish}
        disabled={validate}
      >
        Next
      </button>
    </div>
  )
}
