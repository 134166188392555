import { notification } from 'antd'
import {
  attemptSearchAddress,
  type NominatimSearchResponse,
} from '../../redux/statelessRequests/address'
import { useAppDispatch } from '../hooks'
import { useAttemptListener } from 'dev-masters-react-kit'
import { useMemo, useState, useRef } from 'react'
import { debounce } from 'lodash'
import axios from 'axios'
import { store } from '../../store'

export default function useAddressSearch() {
  const dispatch = useAppDispatch()
  const [options, setOptions] = useState<
    { value: string; label: string; data: NominatimSearchResponse }[]
  >([])
  const abortControllerRef = useRef<AbortController | null>(null)

  const isLoading = useAttemptListener({
    store,
    attempt: attemptSearchAddress,
    onRejected: (action) => {
      notification.error({
        message: 'Search service is unavailable',
        description: action.error,
      })
    },
  })

  async function performSearch(
    value: string,
    extractOptions: (payload: NominatimSearchResponse[]) => any,
  ) {
    const previousRequestAborter = () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort()
      }
      const abortController = new AbortController()
      abortControllerRef.current = abortController
      return abortController
    }
    // const abortController = previousRequestAborter()
    try {
      const { payload } = await dispatch(
        attemptSearchAddress({
          searchQuarry: value /* , signal: abortController.signal */,
        }),
      )
      const options = extractOptions((payload || []) as NominatimSearchResponse[])
      setOptions(options)
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled:', error.message)
      } else {
        console.error('perform search error:', error)
      }
    }
  }

  function extractCityNames(payload: NominatimSearchResponse[]) {
    const cityNames = new Set<string>(
      payload
        .map(
          (option) =>
            option.address?.city ||
            option.address?.town ||
            option.address?.village ||
            option.address?.hamlet ||
            option.address?.city_district,
        )
        .filter((option): option is string => option !== undefined),
    )
    // Filter the options to only include unique city names
    return Array.from(cityNames).map((city) => ({
      value: city,
      label: city,
    }))
  }

  const handleSearchCity = (value: string) => {
    performSearch(value, extractCityNames)
  }

  const handleSearch = (value: string) => {
    performSearch(value, (payload) => {
      return payload.map((option) => ({
        data: option,
        value: option.display_name,
        label: option.display_name,
      }))
    })
  }

  const debouncedHandleSearch = useMemo(() => debounce(handleSearch, 300), [handleSearch])
  const debouncedHandleSearchCity = useMemo(
    () => debounce(handleSearchCity, 300),
    [handleSearchCity],
  )

  return {
    isLoading,
    options,
    handleSearch: debouncedHandleSearch,
    handleSearchCity: debouncedHandleSearchCity,
  }
}
