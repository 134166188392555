export const IconMapUser = ({ fill }: { fill?: string }) => {
  return (
    <svg
      width="16"
      height="71"
      viewBox="0 0 16 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00001 0C6.61502 0.000311649 5.25377 1.06239 4.04946 3.08231C2.84514 5.10224 1.83901 8.01082 1.12952 11.5235C0.420017 15.0362 0.0314498 19.0326 0.0018302 23.1217C-0.0277894 27.2109 0.302554 31.2527 0.96054 34.8518L7.56267 70.1126C7.60587 70.3436 7.66925 70.5361 7.7462 70.6702C7.82316 70.8043 7.91087 70.875 8.00022 70.875C8.08957 70.875 8.17729 70.8043 8.25424 70.6702C8.3312 70.5361 8.39457 70.3436 8.43778 70.1126L15.042 34.8394C15.699 31.2401 16.0284 27.199 15.9981 23.1112C15.9678 19.0233 15.5788 15.0286 14.8691 11.5177C14.1595 8.0068 13.1535 5.09981 11.9494 3.08097C10.7454 1.06213 9.38458 0.000541088 8.00001 0V0ZM8.00001 35.4368C7.20891 35.4368 6.43557 34.744 5.77779 33.4461C5.12002 32.1481 4.60734 30.3033 4.30459 28.1449C4.00185 25.9865 3.92264 23.6114 4.07698 21.3201C4.23132 19.0287 4.61227 16.924 5.17166 15.272C5.73106 13.62 6.44377 12.495 7.21967 12.0392C7.99558 11.5835 8.79982 11.8174 9.5307 12.7114C10.2616 13.6055 10.8863 15.1195 11.3258 17.062C11.7653 19.0045 11.9999 21.2883 11.9999 23.6245C11.9987 26.7562 11.5769 29.7586 10.827 31.9731C10.0772 34.1875 9.06048 35.4332 8.00001 35.4368Z"
        fill={fill ? fill : '#0F1419'}
      />
    </svg>
  )
}
