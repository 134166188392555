import { useState, ReactNode } from 'react'
import { METHOD_AUTH } from '../../../types/server-communication/auth'
import { IconFacebook } from '../../svg/IconFacebook'
import { IconGoogle } from '../../svg/IconGoogle'
import { useNavigate } from 'react-router-dom'
import { Button, Divider, Flex } from 'antd'

export const AuthLayout = ({
  isLogin,
  children,
}: {
  isLogin: boolean
  children: ReactNode
}) => {

  const navigate = useNavigate()

  return (
    <>
      <div className="auth">
        <div className="auth__header">
          <div>
            <img src="/Images/logoshort.png" alt="" />
          </div>
          <div>
            <h4 className="auth__title">Neighbor Harbor</h4>
            <h5 className="auth__subtitle">
              {isLogin ? 'Log in to your account' : 'Set up your account'}
            </h5>
          </div>
        </div>

        <div className="auth__tab">
          <button
            className={`auth__tab-but ${!isLogin ? 'auth__tab--pas' : ''}`}
            onClick={() => navigate('/auth/signIn')}
          >
            Login
          </button>
          <button
            className={`auth__tab-but ${isLogin ? 'auth__tab--pas' : ''}`}
            onClick={() => navigate('/auth/signUp')}
          >
            Create Account
          </button>
          <div
            className={`auth__tab-border ${
              isLogin ? 'auth__tab-border--l' : 'auth__tab-border--r'
            }`}
          />
        </div>
        <div className="auth__messenger" style={{justifyContent: 'center'}}>
        <Flex justify="center" gap="large">
              <Button
                type="default"
                size="large"
                shape="circle"
                icon={<IconGoogle />}
                href={`${process.env.REACT_APP_API_BASE_URL}/api/auth/google`}
              />
              <Button
                type="default"
                size="large"
                shape="circle"
                icon={<IconFacebook />}
                href={`${process.env.REACT_APP_API_BASE_URL}/api/auth/facebook`}
              />
            </Flex>
        </div>
        <Divider className="auth-divider">{isLogin ? 'Login' : 'Registration'}</Divider>
        {children}
      </div>
    </>
  )
}
