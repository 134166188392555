import { circle } from 'leaflet'

export function insidePolygon(point: [number, number], vs: number[][]) {
  let x = point[0],
    y = point[1]
  let inside = false
  for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    let xi = vs[i][1],
      yi = vs[i][0]
    let xj = vs[j][1],
      yj = vs[j][0]

    let intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi
    if (intersect) inside = !inside
  }

  return inside
}
export function insideCircle(
  point: [number, number],
  radius: any,
  circleCenter: [number, number],
) {
  const circleEl = circle({ lat: circleCenter[0], lng: circleCenter[1] })
  return circleEl.getLatLng().distanceTo(point) < radius * 1000
}

export function inside(point: [number, number], vs: any[]) {
  let x = point[0],
    y = point[1]
  let inside = false
  for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    let xi = vs[i].lat,
      yi = vs[i].lng
    let xj = vs[j].lat,
      yj = vs[j].lng

    let intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi
    if (intersect) inside = !inside
  }

  return inside
}
