export const IconMute = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect width="30" height="30" fill="url(#pattern0)" />
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image0_1_16172" transform="scale(0.01)" />
        </pattern>
        <image
          id="image0_1_16172"
          width="100"
          height="100"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEzUlEQVR4nO2dTYjdVBSA4y9VF2p15Q/+gLhSEaxL6aLoUgQFf5CKCxeCS1FRd4q67kJxhFI3wqCIuJBaIZN73nvz7plXHPRNc85oHVps3YggWEXqGLkzrzBgbuYlTW7uTc4HZ/VIcnO/uX/JyZ0oEgRBEARBEAThAnG8sQeQF4bH126TWmmZ6XR6JSB9CciZ0nQqGdMdbZept+yUcSFEikcyREqLmDEjT8aO+EnGFIeYyjaV3oaUeLx+C2g+CJpeqTf4oDl3FCqupQB8dz1o/lhp2tzlmpXDnFshHYm/3bguChFXUrQ+cQMgnWhKxP+D1kaj6d4oRFxIAeTP3cmYhaZPo1BpUgqM+T7nMmaxNEnviUKlKSmA/FpbQkDTq1HINCFFafogv7L4LGg6Vk/w2bxrmGtHoZPgD7cqTT8WSSmzogekw/nnocN1ldnFNTojRYR4JkWEeCZFhHgmRYR4JkWEeCZFhHgmRYR4JkWEeCZFhHj2mAWQv5GVukctxR4kj078kkIixC8pJEL8kkIixC8pJEL8kkIixKcpsdJ0qrYUo66/oPLxzWMRIsQzKSAtxC8pIEL8kgIixC8pIEL8kgJ9FTIanb4KkA/khfmtrWQ8KCmkjvvwgng5vd1Wiea3UJLx4pruo3XqvJGkxWQ8EeJZMl6vhSwtr93V9JgiQkoIMZWlNL/kUzJer1vIthD6t0kpIqSkENiuzMakiJAKQqBBKSKkohBoSIoIuQghMJOSaH6urtmXCLlIIVAwA3KRjNf7WRZUFNJUMl53hJh9Siw3MsD07spf4SK/UENLyRPyYd75TFmtQkLaL8XsIWK9+ZV0f9Xv1JUZ6JFerFuK0vx63rkSzQ9ZhYS0T0qWZZcC0l/5f/HrT+cdo5bp3vkqr34pA50+kHeegU6fsZThXJZll0QhoTR/X6Z72D6GPnMtRWn62loe5I9yj0FajULDPmviX0wLyjtmNJruNTv0OJaS+0BycTG7TCH93JmdHxTSs9ZK0PyY7bhjk5PXzp5rbbYpRY35KXv56YkoNLYH9vxxRGlO4zi+vOj40Yhv3pKq6U1AftcWCvmdwfH0pjqlTCaTK6wtVfMfR1dXr4lCBDR9UtBK3nBZlmGpFf2W7KzsGOg9S7h+v+lWLN3NZrJCj/qXS8xn7N0l/WNbRwUDIC8WjAHn1Er6uMvyDCsvHrdmV+9HoWNWtArp96KBGZDecplWM6wmZcNs0Bl1AYX85K43rOk0YPr8ZHLmat+kKM1/Jyv8YNQlAPm9ObuFP5XmL8weu2aWNRivP2xLVtsthkj7apHieALiBPOowcxQqvTdUDGaXjx2Q4qmt20zLxAp7WCmuwr5V5HiEfGEbpx1YeelpXgEIN+pNB1y0WKUjCll/1cJHzBjDCB/BZpOAvJvNUs5b95AJjp9pKgsvR7oqxI3/L7b5dfBnSB2kIAgUkrgKiNEpMyJyxQdkTIHrnOmRMoutJHE1ispCfLLJf/1xMBaMea3Eucy1563nL2ZEhdkqWTNR7nv1HvRUkIS0gspoQnpvJQQhXRaSqhCOivFZANCQUJco1FDJmJvZl8hMaeUhbbL2SuSgu5LIR2N4409bZexdyQ5UkSGR1JEhl9jyoJ0U4IgCIIgCIIQ7eQ/FKUumYrFi0gAAAAASUVORK5CYII="
        />
      </defs>
    </svg>
  )
}
