import L from 'leaflet'
import { useState } from 'react'
import { Marker } from 'react-leaflet'

export const EventMark = (props: {
  position: [number, number]
  title: string
  img: string
  file: string
  type: string
  handler: () => void
  onclick?: () => void
  // setPostItem?:(s:PostUserInterface)=>void,
  // setServiceItem?:(s:PublishServiceItemInterface)=>void,
  // setEventItem?:(s:PublishEventItemInterface)=>void,
}) => {
  const markIcon = new L.Icon({
    iconUrl: props.img,
    iconRetinaUrl: props.img,
    iconAnchor: new L.Point(0, 0),
    popupAnchor: new L.Point(16, 0),
    iconSize: new L.Point(15, 15),
    // className: 'leaflet-div-icon'
  })
  const [m, setM] = useState<L.Marker | null>(null)
  m?.on('click', (e) => {
    if (props?.onclick) {
      props.onclick()
    }
  })
  return (
    <Marker position={props.position} icon={markIcon} ref={setM}>
      {/* <Popup >{props.file ? <img src={`${baseURL}/uploads/${props.type}/${props.file}`} onClick={props.onclick}/> : <img src="/Images/postsBG.png" onClick={props.onclick}/>}</Popup> */}
    </Marker>
  )
}
