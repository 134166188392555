import { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import $api from '../../../http'
import { UserHttp } from '../../../http/user-http'
import { ROLE } from '../../../types/enum'
import { ChatType, ParticipantType, RequestType } from '../../../types/types'
import { useAppSelector } from '../../../utils/hooks'
import { inside } from '../../../utils/inside'
import { IconArrowRight } from '../../svg/IconArrow'
import { IconNeibs } from '../../svg/IconPassEye'
import { DarkBg } from '../../ui/DarkBg'
import { Loader } from '../../ui/Loader'
import { ChatItem } from './ChatItem'
import { ChatModalGlobal } from './ChatModalGlobal'
import { ChooseNewAdmin } from './ChooseNewAdmin'
import { GetStartedMessenger } from './GetStartedMessenger'
import { apiBaseURL, indexPathByRole } from '../../route/indexPathByRole'

export const ChatList = ({
  isSupport = false,
  search,
  setChats,
}: {
  isSupport?: boolean
  search?: string
  setChats?: (s: ChatType[]) => void
}) => {
  const [chatsList, setChatsList] = useState<ChatType[]>([])
  const [chatsListNormal, setChatsListNormal] = useState<ChatType[]>([])
  const { _id, role, polygonCoords, fullName } = useAppSelector((state) => state.user)
  const navigate = useNavigate()
  const location = useLocation()
  const [load, setLoad] = useState(true)
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenAdmin, setIsOpenAdmin] = useState(false)
  const [item, setItem] = useState<ChatType>()
  const [userRequests, setUserRequests] = useState<RequestType[]>([])
  const { chats, requests } = useAppSelector((state) => state.chats)
  useEffect(() => {
    setLoad(true)
    setUserRequests(requests)
    if (
      (role === ROLE.LOCAL_ADMIN || role === ROLE.REGIONAL_ADMIN) &&
      polygonCoords &&
      !isSupport
    ) {
      let c: ChatType[] = []
      const list = chats.map((item) => ({
        ...item,
        participants: item.participants.filter((p) => p.userId._id !== _id),
      }))
      list.filter((item) => {
        if (inside([item.coordinates.lat, item.coordinates.lng], polygonCoords)) {
          c.push(item)
        }
      })
      setChatsList(c)
      setLoad(false)
    } else {
      setLoad(true)
      setChatsList(chats)
      console.log(chats)
      setLoad(false)
    }
    if (role === ROLE.ADMIN || role === ROLE.USER) {
      if (location.state) {
        const list = chats.map((item) => ({
          ...item,
          participants: item.participants.filter((p) => p.userId._id !== _id),
        }))
        setChatsList(list)
        setLoad(false)
      } else {
        const list = chats.map((item) => ({
          ...item,
          participants: item.participants.filter((p) => p.userId._id !== _id),
        }))
        setChatsList(list)
        setLoad(false)
      }
    }
    setLoad(false)
  }, [chats])

  useEffect(() => {
    setLoad(true)
    if (setChats) setChats(chatsList)
    if (chatsList) {
      let pinned: ChatType[] = [...chatsList.filter((it) => it.isPined)]
      let unpinned: ChatType[] = [...chatsList.filter((it) => !it.isPined)]
      setChatsListNormal([...pinned, ...unpinned])
    }
    setLoad(false)
  }, [chatsList])

  const openChat = (participants: ParticipantType[], item: ChatType) => {
    // navigate(`${indexPathByRole(role)}/messeges/chat`, {
    //     state: {
    //         participants: [
    //             {
    //                 userId: _id,
    //             },
    //         ],
    //     },
    // })
    if (role === ROLE.USER) {
      navigate(
        `${indexPathByRole(role)}/messeges/chat?chatId=${JSON.stringify(item.chatId)}`,
        {
          state: {
            participants: participants,
            chat: item,
          },
        },
      )
    } else {
      navigate(
        `${indexPathByRole(role)}/messenger/chat?chatId=${JSON.stringify(item.chatId)}`,
        {
          state: {
            participants: participants,
            chat: item,
          },
        },
      )
    }
  }

  // const openChat = () => {
  //     navigate(`${indexPathByRole(role)}/messeges/chat`, {
  //         state: {
  //             participants: [
  //                 {
  //                     userId: props?._id,
  //                 },
  //             ],
  //         },
  //     })
  // }

  return (
    <>
      {load ? (
        <Loader />
      ) : (
        <>
          {chatsListNormal.length ? (
            <div className="messenger">
              {userRequests.length && !location.pathname.includes('support') ? (
                <div
                  className="messenger__requests-button"
                  onClick={() => navigate(`user-requests`)}
                >
                  <div className="messenger__requests-button-content">
                    <IconNeibs />
                    <div className="messenger__requests-button-text">
                      <h5>
                        Requests <span>New</span>
                      </h5>
                      <h6>You have new requests</h6>
                    </div>
                  </div>
                  <IconArrowRight />
                </div>
              ) : (
                ''
              )}
              {role === ROLE.USER ? (
                <div className="messenger__list">
                  {(search
                    ? chatsListNormal
                        .filter((it) => it.participants.length <= 2)
                        .filter((it) => it.isSupport === isSupport)
                        .filter((item) =>
                          item.participants[0].userId.fullName
                            .toLocaleLowerCase()
                            .includes(search.toLocaleLowerCase()),
                        )
                    : chatsListNormal.filter((it) => it.participants.length <= 1)
                  ).map((item, key) => (
                    <ChatItem
                      openChat={openChat}
                      item={item}
                      setIsOpen={setIsOpen}
                      setItem={setItem}
                    />
                  ))}
                </div>
              ) : (
                <div className="messenger__list">
                  {chatsListNormal.map((item, key) => (
                    <ChatItem
                      openChat={openChat}
                      item={item}
                      setIsOpen={setIsOpen}
                      setItem={setItem}
                      support={true}
                    />
                  ))}
                </div>
              )}
            </div>
          ) : (
            <>
              {userRequests.length ? (
                <div
                  className="messenger__requests-button"
                  style={{ margin: '-20px 0' }}
                  onClick={() => navigate(`user-requests`)}
                >
                  <div className="messenger__requests-button-content">
                    <IconNeibs />
                    <div className="messenger__requests-button-text">
                      <h5>
                        Requests <span>New</span>
                      </h5>
                      <h6>You have new requests</h6>
                    </div>
                  </div>
                  <IconArrowRight />
                </div>
              ) : (
                <GetStartedMessenger />
              )}
            </>
          )}
        </>
      )}
      {isOpen && item?.participants && (
        <>
          <ChatModalGlobal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            item={item}
            setIsOpenAdmin={setIsOpenAdmin}
            setChatList={setChatsListNormal}
            chatList={chatsListNormal}
          />
          <DarkBg />
        </>
      )}
      {isOpenAdmin && item && (
        <>
          <ChooseNewAdmin
            setIsOpen={setIsOpenAdmin}
            isOpen={isOpenAdmin}
            chat={item}
            setChatList={setChatsListNormal}
            chatList={chatsListNormal}
          />
          <DarkBg />
        </>
      )}
    </>
  )
}
