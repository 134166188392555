import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { IdentityHttp } from '../../../../http/identity-http'
import { useAppDispatch, useAppSelector } from '../../../../utils/hooks'
import { Loader } from '../../../ui/Loader'
import { UserItem } from '../ProfileInfo'
import { GeneralInfo } from './GeneralInfo'
import { TabInfo } from './TabInfo'
import { useAttemptListener } from 'dev-masters-react-kit'
import { getIdentity } from '../../../../redux/profile'
import { store } from '../../../../store'
import { LatestActivity } from './LatestActivity'

export const Overview = () => {
  const isLoading = useAttemptListener({
    store,
    attempt: getIdentity,
  })

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getIdentity())
  }, [])

  if (isLoading) return <Loader />
  else
    return (
      <>
        <GeneralInfo />
        <TabInfo />
        <LatestActivity />
      </>
    )
}
