import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Slider from 'react-slick'
import $api from '../../../http'
import { AdvertisementHttp } from '../../../http/advertisement'
import { ServiceHttp } from '../../../http/service-http'
import { CategoriesItemInterface } from '../../../redux/categories'
import { PAGES, PRIVACY } from '../../../types/enum'
import { Advertise, PublishServiceItemInterface } from '../../../types/types'
import { calculateDistance } from '../../../utils/calculateRate'
import { apiBaseURL, indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { PublishModalRoute } from '../../general-components/publication/PublishModalRoute'
import { IconAdminClose } from '../../svg/IconAdminHeader'
import { IconArrowRight } from '../../svg/IconArrow'
import { IconBookMark } from '../../svg/IconBookMark'
import { IconRightChevrons } from '../../svg/IconChevrons'
import { IconStars } from '../../svg/IconFavor'
import { IconNeibs } from '../../svg/IconPassEye'
import {
  IconProfileInfoBookmark,
  IconProfileInfoBookmarkCopy,
} from '../../svg/IconProfileInfo'
import { IconLocationPoint } from '../../svg/IconsLocation'
import { DarkBg } from '../../ui/DarkBg'
import { InputSearch } from '../../ui/InputSearch'
import { TempNotification } from '../../ui/TempNotification'
import { UserHeaderMain } from '../header/UserHeaderMain'

export interface hiddenService {
  hideUserId: string
  serviceId: string
}

export const UserServicesMain = () => {
  const [searsh, setSearch] = useState('')
  const [currentSlideFirst, setCurrentSlideFirst] = useState(0)
  const { categories } = useAppSelector((state) => state.categories)
  const { role, _id } = useAppSelector((state) => state.user)
  const { coordinates } = useAppSelector((state) => state.profile)
  const { publishServices, hiddenService } = useAppSelector((state) => state.categories)
  const [publishService, setPublishService] = useState<PublishServiceItemInterface[]>([])
  const [providers, setProviders] = useState<PublishServiceItemInterface[]>([])
  const [adds, setAdds] = useState<Advertise[]>()
  const [addService, setAddService] = useState<Advertise[]>()
  const navigate = useNavigate()
  const [hiddenServices, setHiddenServices] = useState<hiddenService[]>([])
  useEffect(() => {
    const body = async () => {
      const res = await AdvertisementHttp.getAdvertisementAll({})
      setAdds(res)
    }
    body()
  }, [])
  useEffect(() => {
    setHiddenServices(hiddenService)
    setPublishService(publishServices)
    publishServices
      .slice(publishServices.length - 2, publishServices.length - 1)
      .map((it) => console.log(it?.categoryId?.name, it))
  }, [publishServices, hiddenService])

  useEffect(() => {
    if (adds?.length) {
      let array: Advertise[] = []
      adds.map((item) => {
        if (item.position === PAGES.SERVICES) {
          array.push(item)
        }
      })
      setAddService(array)
    }
  }, [adds])

  useEffect(() => {
    const p = publishService.filter(
      (value, index, self) =>
        index === self.findIndex((obj) => obj.userId?._id === value.userId?._id) &&
        value.userId?._id !== _id,
    )
    setProviders(p)
  }, [publishService])

  const deleteProvider = (item: PublishServiceItemInterface) => {
    let array: PublishServiceItemInterface[] = [...providers]
    let index = array.indexOf(item)
    if (index !== -1) {
      array.splice(index, 1)
      setProviders(array)
    }
  }

  const settingsFirst = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    className: 'user__services-adv-ins',
    afterChange: (n: number) => setCurrentSlideFirst(n),
  }

  const settingsSecond = {
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    className: 'user__services-category-slick-ins',
  }

  const toProfileInfo = (prop: {
    _id: string
    email: string
    role: string
    fullName: string
    avatarFileName: string
  }) => {
    navigate('/profileinfo', {
      state: prop,
    })
  }

  const validatePrivacy = (item: PublishServiceItemInterface) => {
    if (hiddenServices.length) {
      if (hiddenServices.find((it) => it.serviceId !== item._id)) {
        if (item.privacyPublishService === PRIVACY.NEIBS) {
          return item.allowList.find((id) => id === _id) || item.userId._id === _id
        }
        if (item.privacyPublishService === PRIVACY.ONLYME) {
          return item.userId._id === _id
        }
        if (item.privacyPublishService === PRIVACY.EVERYONE) {
          return true
        }
      }
    } else {
      if (item.privacyPublishService === PRIVACY.NEIBS) {
        return item.allowList.find((id) => id === _id) || item.userId._id === _id
      }
      if (item.privacyPublishService === PRIVACY.ONLYME) {
        return item.userId._id === _id
      }
      if (item.privacyPublishService === PRIVACY.EVERYONE) {
        return true
      }
    }
  }

  const openChat = async (item: string) => {
    await $api
      .post('messenger/open-chat', {
        participants: [
          {
            userId: item,
          },
          {
            userId: _id,
          },
        ],
      })
      .then((res) => {
        console.log(res)
        if (res.data) {
          navigate(`${indexPathByRole(role)}/messeges/chat`, {
            state: {
              chat: res.data,
            },
          })
        }
      })
  }
  const [ssOpenSave, setIsOpenSave] = useState(false)
  const updateMark = async (serviceId: string, isMarked: boolean) => {
    if (isMarked) {
      await ServiceHttp.deleteMark({
        serviceId,
        marckedUserId: _id,
      })
    } else {
      await ServiceHttp.addMark({
        serviceId,
        marckedUserId: _id,
      })
      setIsOpenSave(true)
    }

    if (publishService) {
      let array: PublishServiceItemInterface[] = []
      publishService.map((item) => {
        if (item._id === serviceId) {
          array.push({
            ...item,
            isMarked: !item?.isMarked,
          })
        } else {
          array.push(item)
        }
      })

      setPublishService(array)
    }
  }

  const toPublishService = (_id: string) => {
    navigate(`/user/service/publish-service?publishServiceId=${_id}`)
  }
  const [isOpenPublish, setIsOpenPublish] = useState(false)
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false)

  const calculateRate = (item: PublishServiceItemInterface) => {
    let rate: number[] = []
    item.reviews.map((review) => {
      rate.push(review.rate)
    })
    return rate.reduce((partialSum, a) => partialSum + a, 0) / rate.length
  }
  const calculateServices = (category: CategoriesItemInterface) => {
    let count = 0
    publishService.find((item) => {
      if (item?.categoryId?._id === category._id) {
        count++
      }
    })
    return count
  }
  const handleAdClick = async (adId: string, countView: boolean) => {
    AdvertisementHttp.updateClick({ adId, countView })
  }
  return (
    <>
      <UserHeaderMain isOpenPublish={isOpenPublish} setIsOpenPublish={setIsOpenPublish} />
      <div className="user__newsfeed-search">
        <InputSearch
          placeholder={
            <>
              Search<span> NeighborHarbor</span>
            </>
          }
          value={searsh}
          changeValue={setSearch}
          onClick={() => navigate(`${indexPathByRole(role)}/search`)}
          // onClickFilter={()=>{
          //     setIsOpenFilter(true);
          // }}
        />
      </div>
      {addService?.length ? (
        <div className="user__services-adv">
          <div className="user__services-adv-slick">
            <Slider {...settingsFirst}>
              {addService?.map((item, index) => (
                <div
                  className="user__services-adv-item-body"
                  onClick={() => navigate('/user/service/user-special-offers')}
                >
                  <div
                    className={`user__services-adv-item ${
                      index === currentSlideFirst && 'user__services-adv-item--active'
                    }`}
                    onClick={(e) => {
                      e.stopPropagation()
                      handleAdClick(item._id, false)
                    }}
                  >
                    <div>
                      <h6 className="user__services-adv-item-undertitle">
                        Offer AC Service
                      </h6>
                      <h4 className="user__services-adv-item-title">{item.title}</h4>
                      <h5 className="user__services-adv-item-subtitle">{item.text}</h5>
                      <button
                        onClick={(e) => {
                          e.stopPropagation()
                          handleAdClick(item._id, true)
                        }}
                        className="user__services-adv-item-button"
                      >
                        Grab Offer <IconRightChevrons />
                      </button>
                    </div>
                    <div className="user__services-adv-item-img">
                      <img
                        src={`${apiBaseURL}/uploads/advertisement/${item.fileName}`}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      ) : (
        ''
      )}
      <div className="user__newsfeed-steper-body">
        {addService?.map((item, index) => (
          <div
            className={`user__newsfeed-steper-item ${
              index <= currentSlideFirst && 'user__newsfeed-steper-item-active'
            }`}
          />
        ))}
      </div>
      <div className="user__services-category">
        <h5 className="user__services-category-title">Categories</h5>
        <button
          className="user__services-category-button"
          onClick={() => navigate('/user/service/user-categories')}
        >
          View all
        </button>
      </div>
      <div className="user__services-category-slick">
        <Slider {...settingsSecond}>
          {categories.map((item) => (
            <div
              className="user__services-category-item-body"
              onClick={() => navigate(`/user/service/user-sub-categories?id=${item._id}`)}
            >
              <div className="user__services-category-item">
                <div className="user__services-category-item-img">
                  <img src={`${apiBaseURL}/uploads/categories/${item.fileName}`} alt="" />
                </div>

                <div className="user__services-category-item-text">
                  <b>{item.name}</b> <span>{calculateServices(item)}</span>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div className="user__services-category user__services-last-title">
        <h5 className="user__services-category-title">Latest Services</h5>
      </div>
      <div className="user__services-last">
        {publishService.slice(0, 2).map((item) => (
          <>
            {validatePrivacy(item) && (
              <div
                className="user__services-last-item"
                onClick={() => toPublishService(item._id)}
              >
                <div className="user__services-last-item-img">
                  <div className="user__services-last-item-img-1 user__services-last-item-img-info">
                    <div className="user__services-last-item-img-text">
                      {item?.categoryId?.name}
                    </div>
                  </div>
                  {item.filesName.length > 0 ? (
                    <img
                      src={`${apiBaseURL}/uploads/publish_services/${item.filesName[0]}`}
                      alt=""
                    />
                  ) : (
                    <img className="posts__bg" src={`/Images/postsBG.png`} alt="" />
                  )}

                  {item.reviews.length ? (
                    <div className="user__services-last-item-img-2 user__services-last-item-img-info">
                      <div className="user__services-last-item-img-text">
                        {item.reviews.length ? (
                          <>
                            <IconStars />
                            <b>{calculateRate(item)}</b>
                            <span>{`(${item.reviews.length})`}</span>
                          </>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div
                  className="user__services-last-item-info"
                  style={{ position: 'relative' }}
                >
                  <div className="user__services-last-item-row1">
                    <h5
                      className="user__services-last-item-title"
                      style={{
                        color: 'rgb(41, 45, 50)',
                        fontSize: '16px',
                        fontWeight: '500',
                      }}
                    >
                      {item.title}
                    </h5>
                    <button
                      className={`${'admin__posts-list-row4-repost--active'}`}
                      onClick={(e) => {
                        e.stopPropagation()
                        updateMark(item._id, item?.isMarked)
                      }}
                    >
                      {item.isMarked ? (
                        <IconProfileInfoBookmarkCopy fill="#3361FF" />
                      ) : (
                        <IconProfileInfoBookmark />
                      )}
                    </button>
                  </div>
                  <h6
                    style={{ margin: '4px 0' }}
                    className="user__services-last-item-text"
                  >
                    {item.text}
                  </h6>
                  <div
                    style={{ position: 'absolute', right: '10px', bottom: '0' }}
                    className="user__services-last-item-foot"
                  >
                    {Math.floor(calculateDistance(coordinates, item.coordinates) / 1000)}{' '}
                    km
                    <IconArrowRight />
                  </div>
                </div>
              </div>
            )}
          </>
        ))}
      </div>
      <button
        className="user__services-last-button user__services-last-button"
        onClick={() => navigate(`/user/service/user-last-publish-service`)}
      >
        View all
      </button>
      {providers.length ? (
        <div className="user__services-category user__services-last-title user__services-users-header">
          <h5 className="user__services-category-title">Providers nearby</h5>
          <h6 className="user__services-users-subtitle">
            Discover service providers in your <br /> area based on your searches
          </h6>
        </div>
      ) : (
        ''
      )}
      <div className="user__services-category-slick">
        <Slider {...settingsSecond}>
          {providers?.map((item) => (
            <div className="user__services-category-item-body">
              <div className="user__services-users-item">
                <button
                  className="user__services-users-item-close"
                  onClick={(e) => {
                    deleteProvider(item)
                  }}
                >
                  <IconAdminClose />
                </button>
                <div
                  className="user__services-users-item-avatar"
                  onClick={() =>
                    toProfileInfo({
                      ...item?.userId,
                    })
                  }
                >
                  {item?.userId?.avatarFileName ? (
                    <img
                      src={`${apiBaseURL}/uploads/avatar/${item?.userId?.avatarFileName}`}
                    />
                  ) : (
                    <div
                      style={{
                        backgroundColor: 'rgba(41, 52, 65, 1)',
                        borderRadius: '100%',
                        width: '100%',
                        height: '100%',
                        color: '#fff',
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <span style={{ margin: 'auto', fontSize: '16px' }}>
                        {item?.userId?.fullName?.slice(0, 2)}
                      </span>
                    </div>
                  )}
                </div>
                <div>
                  <h5 className="user__services-users-item-name">
                    {item?.userId?.fullName}{' '}
                    {item.userIdentityId.dateBirth ? (
                      <span>
                        {new Date()?.getFullYear() -
                          new Date(
                            item.userIdentityId?.dateBirth || new Date(),
                          )?.getFullYear()}
                      </span>
                    ) : (
                      ''
                    )}
                  </h5>
                  <h6 className="user__services-users-item-subtitle">
                    {item?.userIdentityId?.profession?.[0]?.title || ''}
                  </h6>
                  <div className="user__services-users-item-location">
                    <IconLocationPoint />{' '}
                    <b>
                      {Math.floor(
                        calculateDistance(coordinates, item.coordinates) / 1000,
                      )}{' '}
                      km{' '}
                    </b>
                    from you
                  </div>
                </div>
                <button
                  className="user__services-users-item-message"
                  onClick={() => openChat(item.userId._id)}
                >
                  <IconNeibs />
                  Message
                </button>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      {isOpenFilter}
      {isOpenPublish && <DarkBg />}
      {ssOpenSave && (
        <TempNotification setIsOpen={setIsOpenSave} isOpen={ssOpenSave}>
          <IconBookMark />
        </TempNotification>
      )}
      {isOpenPublish && setIsOpenPublish && (
        <PublishModalRoute isOpen={isOpenPublish} setIsOpen={setIsOpenPublish} />
      )}
    </>
  )
}
