import { useEffect, useState } from 'react'
import { Circle, MapContainer, Polygon, TileLayer } from 'react-leaflet'
import { useLocation, useNavigate } from 'react-router-dom'
import { AdvertisementHttp } from '../../../http/advertisement'
import { Advertise } from '../../../types/types'
import { indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconLeftChevrons } from '../../svg/IconChevrons'
import { AdvertisementStepper } from './AdvertisementStepper'
import { SEX } from '../../../types/enum'

export const AdvertisementAddCommunity = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [adId, setAdId] = useState<string>()
  const [advertise, setAdvertise] = useState<Advertise>()
  const { role } = useAppSelector((state) => state.user)
  const validate = !Boolean(
    advertise?.center &&
      advertise?.age?.length &&
      advertise?.gender?.length &&
      advertise?.interests,
  )
  useEffect(() => {
    setAdId(location.state.adId)
  }, [])
  useEffect(() => {
    const effectBody = async () => {
      if (adId) {
        const res: Advertise = await AdvertisementHttp.getAdvertisementById({ adId })
        setAdvertise(res)
      }
    }
    effectBody()
  }, [adId])
  return (
    <div className="advertisement__add">
      <div className="advertisement__add-header">
        <div className="advertisement__add-header-btn" onClick={() => navigate(-3)}>
          <IconLeftChevrons fill="rgba(21, 16, 77, 1)" />
          <span>Audience</span>
        </div>
      </div>
      <AdvertisementStepper i={2} />
      <div className="advertisement__add-audience">
        <div
          className="advertisement__add-audience-item"
          onClick={() => navigate('map', { state: { adId } })}
        >
          Choose Location
          <div className="advertisement__add-audience-item-svg">
            <IconLeftChevrons fill="rgba(195, 202, 217, 1)" />
          </div>
        </div>
        {advertise?.center?.length ? (
          <div className="advertisement__community-preview">
            {
              <MapContainer
                dragging={false}
                zoomControl={false}
                center={advertise.center}
                minZoom={8}
                maxZoom={8}
                zoom={8}
                scrollWheelZoom={false}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                  url="https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png"
                  subdomains="abcd"
                />
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                  url="https://{s}.basemaps.cartocdn.com/dark_only_labels/{z}/{x}/{y}{r}.png"
                  subdomains="abcd"
                />
                {advertise?.polygonCoords && (
                  <Polygon positions={advertise.polygonCoords} />
                )}
                {advertise?.radiusSize && (
                  <Circle
                    center={advertise.center}
                    radius={advertise.radiusSize * 1000}
                  />
                )}
              </MapContainer>
            }
          </div>
        ) : (
          ''
        )}
        <div
          className="advertisement__add-audience-item"
          onClick={() => navigate('interests', { state: { adId } })}
        >
          Interests
          <div className="advertisement__add-audience-item-svg">
            <IconLeftChevrons fill="rgba(195, 202, 217, 1)" />
          </div>
        </div>
        <div className="advertisement__add-audience-interests">
          {advertise?.interests?.map((item, index) => (
            <div
              className={`profile__identity-list-item advertisement__add-audience-interest`}
              key={index}
            >
              <div className="profile__identity-list-item-text">{item.title}</div>
            </div>
          ))}
        </div>
        <div
          className="advertisement__add-audience-item"
          onClick={() => navigate('age-gender', { state: { adId } })}
        >
          Age & Gender
          <div className="advertisement__add-audience-item-svg">
            <IconLeftChevrons fill="rgba(195, 202, 217, 1)" />
          </div>
        </div>
        {advertise?.age && advertise.gender && (
          <div className="advertisement__add-audience-gender">
            <div className="advertisement__add-main-line">
              {advertise?.gender.map((item) => (
                <div
                  style={
                    item !== SEX.OTHER
                      ? { padding: '44px 22px 0 22px', margin: '0px 5px' }
                      : { margin: '0px 5px' }
                  }
                  className={`advertisement__add-main-line-item `}
                >
                  <img
                    style={
                      item !== SEX.OTHER
                        ? item === SEX.FEMALE
                          ? { padding: '3px 0 15px 0' }
                          : { padding: '3px 0 15px 0' }
                        : {}
                    }
                    width={item === SEX.OTHER ? 98 : 109}
                    height={92}
                    alt=""
                    src={`/Images/${item.toLowerCase()}.png`}
                  />
                  <span>{item}</span>
                </div>
              ))}
            </div>
            {advertise.age.length ? (
              <div className="advertisement__add-audience-gender-age">
                <b>Age</b>
                <span>
                  {advertise?.age[0]}-{advertise?.age[1]}
                </span>
              </div>
            ) : (
              ''
            )}
          </div>
        )}
      </div>
      <button
        className={`advertisement__add-main-btn ${
          validate && 'advertisement__add-main-btn-disabled'
        }`}
        onClick={() =>
          navigate(`${indexPathByRole(role)}/advertisement/budget`, { state: { adId } })
        }
        disabled={validate}
      >
        Continue
      </button>
    </div>
  )
}
