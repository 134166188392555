import { Link } from 'react-router-dom'
import { Modal } from '../../ui/Modal'

export const PublishModalRoute = ({
  setIsOpen,
  isOpen,
}: {
  setIsOpen: (s: boolean) => void
  isOpen: boolean
}) => {
  return (
    <div
      className={`activities__favor-modal  ${
        isOpen ? 'activities__favor-modal--open' : ''
      }`}
    >
      <Modal className="" setIsOpen={(s: boolean) => setIsOpen(s)}>
        <div
          onClick={(e) => {
            e.stopPropagation()
            setIsOpen(false)
          }}
          className="activities__favor-modal-linebody"
        >
          <button className="activities__favor-modal-line" />
        </div>
        <div className="publish__modaladd">
          <h5 className="publish__modaladd-title">Where to publish?</h5>
          <h6 className="publish__modaladd-subtitle">Choose your medium</h6>
          <div className="publish__routemodal-btns">
            <Link to={'/publish/post'}>
              <div className="publish__routemodal-button">
                <div className="publish__routemodal-button-img">
                  <img src="/Images/PublishPost.png" alt="" />
                </div>
                <p className="publish__routemodal-text">Newsfeed</p>
              </div>
            </Link>
            <Link to={'/publish/service'}>
              <div className="publish__routemodal-button">
                <div className="publish__routemodal-button-img">
                  <img src="/Images/PublishService.png" alt="" />
                </div>
                <p className="publish__routemodal-text">Service</p>
              </div>
            </Link>
            <Link to={'/publish/event'}>
              <div className="publish__routemodal-button">
                <div className="publish__routemodal-button-img">
                  <img src="/Images/PublishEvent.png" alt="" />
                </div>
                <p className="publish__routemodal-text">Activity</p>
              </div>
            </Link>
          </div>
        </div>
      </Modal>
    </div>
  )
}
