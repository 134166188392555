import { addListener } from '@reduxjs/toolkit'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom'
import { forgotPasswordSendCode } from '../../../redux/auth'
import { AppDispatch } from '../../../store'
import { METHOD_FORGET_PASSWORD } from '../../../types/enum'
import { IconsChat, IconsMessage } from '../../svg/IconsResetPass'
import { ButtonLeftChevron } from '../../ui/ButtonLeftChevron'
import CheckCode from './CheckCode'

export default function ForgetPass() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const email = searchParams.get('emailAddress') || ''
  const [method, setMethod] = useState(METHOD_FORGET_PASSWORD.EMAIL)

  const back = () => {
    navigate(-1)
  }

  return (
    <div className="forget">
      <div className="forget__back">
        <ButtonLeftChevron onClick={back} />
      </div>
      <Routes>
        <Route path="code" element={<CheckCode email={email} method={method} />} />
        <Route
          path="*"
          element={<ForgetPassBody email={email} method={method} setMethod={setMethod} />}
        />
      </Routes>
    </div>
  )
}

interface ForgetPassBodyProps {
  email: string
  method: METHOD_FORGET_PASSWORD
  setMethod: React.Dispatch<React.SetStateAction<METHOD_FORGET_PASSWORD>>
}

const ForgetPassBody = ({ email, method, setMethod }: ForgetPassBodyProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const [error, setError] = useState<string | undefined>()

  useEffect(() => {
    const removeRejectedListener = dispatch(
      addListener({
        actionCreator: forgotPasswordSendCode.rejected,
        effect: (action, listenerApi) => {
          switch (action.error.message) {
            case 'phone must be a valid phone number':
              setError('Phone must be a valid phone number')
              break

            case 'USER_NOT_FOUND':
              setError('User not found')
              break

            default:
              setError('Could not send verification code.')
          }
        },
      }),
    )

    const removeFulfilledListener = dispatch(
      addListener({
        actionCreator: forgotPasswordSendCode.fulfilled,
        effect: (action, listenerApi) => {
          navigate(`/forget-pass/code?emailAddress=${email}`)
        },
      }),
    )

    return () => {
      removeRejectedListener()
      removeFulfilledListener()
    }
  }, [])

  const sendCode = async () => {
    await dispatch(
      forgotPasswordSendCode({
        email,
        method,
      }),
    )
  }

  return (
    <>
      <h4 className="forget__title">Forgot Password</h4>
      <h5 className="forget__subtitle">
        Select which contact details should we use to reset your password
      </h5>
      <div className="forget__body-img">
        <img src="/Images/YellowKey.png" alt="" />
      </div>

      <div
        className={`forget__body-method ${
          method === METHOD_FORGET_PASSWORD.PHONE ? 'forget__body-method--active' : ''
        }`}
        onClick={() => {
          setMethod(METHOD_FORGET_PASSWORD.PHONE)
        }}
      >
        <div className="forget__body-method-icon">
          <IconsChat />
        </div>
        <div>
          <p className="forget__body-method-title">via SMS:</p>
          <p className="forget__body-method-subtitle">
            If you associated a phone number with your account <strong>{email}</strong> we
            will send you a SMS message.
          </p>
        </div>
      </div>

      <div
        className={`forget__body-method-2 forget__body-method ${
          method === 'email' ? 'forget__body-method--active' : ''
        }`}
        onClick={() => {
          setMethod(METHOD_FORGET_PASSWORD.EMAIL)
        }}
      >
        <div className="forget__body-method-icon">
          <IconsMessage />
        </div>
        <div>
          <p className="forget__body-method-title">via Email:</p>
          <p className="forget__body-method-subtitle">
            We will send a recovery code to <strong>{email}</strong>.
          </p>
        </div>
      </div>

      {error && <div className="forget__error">{error}</div>}

      <button className="login__button" onClick={sendCode}>
        Reset Password
      </button>
    </>
  )
}
