import { useLocation } from 'react-router-dom'
import { ChatMessage } from '../../general-components/messenger/ChatMessage'
import { UserHeaderUserChatMessage } from '../header/UserHeaderUserChatMessage'

export const MessegesChatMessage = () => {
  const location = useLocation()
  return (
    <>
      <UserHeaderUserChatMessage chat={location.state.chat} />
      <ChatMessage />
    </>
  )
}
