import { useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { UserHttp } from '../../../http/user-http'
import { PRIVACY } from '../../../types/enum'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { DarkBg } from '../../ui/DarkBg'
import { ModalDiscard } from '../../ui/ModalDiscard'
import { PublishEvent } from './PublishEvent'
import { PublicationHeader } from './PublishHeader'
import { PublishLocationMap } from './PublishLocationMap'
import { PublishPost } from './PublishPost'
import { PublishSelectCategory } from './PublishSelectCategory'
import { PublishSelectSubCategory } from './PublishSelectSubCategory'
import { PublishService } from './PublishService'
import { getTenPublishService } from '../../../redux/categories'

export const Publish = () => {
  const [currentPrivacy, setCurrentPrivacy] = useState(PRIVACY.EVERYONE)
  const [isOpenDiscard, setisOpenDiscard] = useState<boolean>(false)
  const [save, setSave] = useState<boolean>(true)
  const [allowList, setAllowList] = useState<string[]>([])
  const [friends, setFriends] = useState<string[]>([])
  const { _id } = useAppSelector((state) => state.user)
  const dispatch = useAppDispatch()
  useEffect(() => {
    const EffectBody = async () => {
      const friendsList = await UserHttp.getMyFriends({ _id })
      let array: string[] = []
      friendsList.map((item) => {
        array.push(item?.friendId?._id)
      })
      setFriends(array)
    }
    EffectBody()
  }, [])
  useEffect(() => {
    dispatch(getTenPublishService())
  }, [])
  useEffect(() => {
    if (currentPrivacy === PRIVACY.NEIBS) {
      setAllowList(friends)
    } else {
      setAllowList([])
    }
  }, [currentPrivacy])

  const location = useLocation()

  return (
    <>
      <PublicationHeader
        currentPrivacy={currentPrivacy}
        setCurrentPrivacy={setCurrentPrivacy}
        setIsOpenDiscard={setisOpenDiscard}
        setSave={setSave}
        isOpenDiscard={isOpenDiscard}
        save={save}
      />
      <Routes>
        {/* <Route index element={} /> */}
        <Route
          path="post"
          element={<PublishPost currentPrivacy={currentPrivacy} allowList={allowList} />}
        />
        <Route
          path="service"
          element={
            <PublishService currentPrivacy={currentPrivacy} allowList={allowList} />
          }
        />
        <Route path="service/category" element={<PublishSelectCategory />} />
        <Route path="service/sub-category" element={<PublishSelectSubCategory />} />
        <Route
          path="event"
          element={<PublishEvent currentPrivacy={currentPrivacy} allowList={allowList} />}
        />
        <Route path="map" element={<PublishLocationMap />} />
      </Routes>
      {isOpenDiscard && <DarkBg />}
      {isOpenDiscard && (
        <ModalDiscard
          setIsOpen={setisOpenDiscard}
          text={location.pathname}
          setSave={setSave}
          isOpen={isOpenDiscard}
        />
      )}
    </>
  )
}
