import { TextareaAutosize } from '@mui/material'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { FeedBackHttp } from '../../../http/feedback-http'
import { PublishPostHttp } from '../../../http/publish-post-http'
import { UserHttp } from '../../../http/user-http'
import { NOTIFICATION_POST, ROLE } from '../../../types/enum'
import {
  CommentInterface,
  HeaderMessageType,
  PostUserInterface,
} from '../../../types/types'
import { apiBaseURL } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconAdminClose } from '../../svg/IconAdminHeader'
import { IconBookMark } from '../../svg/IconBookMark'
import { IconComment } from '../../svg/IconFavor'
import { IconPostsLike, IconPostsRedLike, IconPostsRepost } from '../../svg/IconPosts'
import { IconProfileInfoBookmark } from '../../svg/IconProfileInfo'
import { IconServicesAllPoint } from '../../svg/IconServicesAll'
import { ButtonLeftChevron } from '../../ui/ButtonLeftChevron'
import { success } from '../../ui/LoadSuccess'
import { Loader } from '../../ui/Loader'
import { PostSlick } from '../../ui/PostSlick'
import { TempNotification } from '../../ui/TempNotification'
import { UserServiceMap } from '../../user-components/services/UserServiceMap'
import { PublicationPostCommentsList } from './PublicationPostCommentsList'
import { PublicationPostCommentsView } from './PublicationPostCommentsView'
import { PublicationPostMyModal } from './PublicationPostMyModal'
import { PublicationPostNeibModal } from './PublicationPostNeibModal'
import { PreviewModal } from '../preview/PreviewModal'

export const PublicationPostComments = () => {
  const [myModalOpen, setMyModalOpen] = useState(false)
  const [neibModalOpen, setNeibModalOpen] = useState(false)
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const [post, setPost] = useState<PostUserInterface>()
  const [comments, setComments] = useState<CommentInterface[]>([])
  const { _id, fullName } = useAppSelector((state) => state.user)
  const { userIdentityId } = useAppSelector((state) => state.profile)
  const [countComments, setCountComments] = useState(0)
  const navigate = useNavigate()
  const [text, setText] = useState('')
  const [isOpenSave, setIsOpenSave] = useState<boolean>(false)
  const [update, setUpdate] = useState(false)

  const getPostData = async () => {
    const postId = searchParams.get('postId') || ''
    const { post } = await PublishPostHttp.getPost({
      postId,
      userId: _id,
    })
    setPost(post)
  }

  const getCommentData = async () => {
    const postId = searchParams.get('postId') || ''
    const { comments, countComments } = await PublishPostHttp.getComments({
      postId,
      userId: _id,
    })
    console.log(comments)
    setCountComments(countComments)
    setComments(comments)
  }

  useEffect(() => {
    const bodyEffect = async () => {
      await getPostData()

      await getCommentData()
    }
    bodyEffect()
  }, [])

  const sendComment = async () => {
    const postId = searchParams.get('postId') || ''
    await PublishPostHttp.addComment({
      postId,
      userId: _id,
      userIdentityId,
      text,
      createdDateComment: new Date(),
    })
    success()
    getCommentData()
    setText('')
  }
  const replyOnComment = async (data: {
    replyText: string
    commentId: string
    userId: string
  }) => {
    const postId = searchParams.get('postId') || ''
    await PublishPostHttp.replyOnComment({
      commentId: data.commentId,
      replyText: data.replyText,
      userId: data.userId,
      postId,
      createdDateComment: new Date(),
    })
    success()
    getCommentData()
    setText('')
    setIsreply(false)
  }

  const updateLike = async (likeId: string, postId: string) => {
    await FeedBackHttp.updateLike({
      likeId,
      userId: _id,
    })
    setUpdate(true)
    setPost((p) =>
      p
        ? {
            ...p,
            isLiked: !p.isLiked,
            likes: p.isLiked ? p.likes - 1 : p.likes + 1,
          }
        : p,
    )
  }
  const [content, setContent] = useState<{ postId: string; content: boolean }[]>([])
  const updateContent = (item: PostUserInterface, val: boolean) => {
    setContent([...content, { postId: item._id, content: val }])
  }
  const [isOpenShare, setIsOpenShare] = useState(false)
  const copy = () => {
    const url = `${window.location.host}/preview/posts?postId=${post?._id}`
    navigator.clipboard.writeText(url)
  }
  const updateRepost = async ({
    postId,
    isReposted,
  }: {
    postId: string
    isReposted: boolean
  }) => {
    await PublishPostHttp.updateRepost({
      postId,
      repostedUserId: _id,
    })
    setUpdate(true)
    if (post) {
      setPost((s) => ({
        ...post,
        isReposted: !isReposted,
        countReposts: !isReposted ? post.countReposts + 1 : post.countReposts - 1,
      }))
    }
  }

  const updateMark = async (postId: string, isMarked: boolean) => {
    setUpdate(true)
    if (isMarked) {
      await PublishPostHttp.deleteMark({
        postId,
        marckedUserId: _id,
      })
    } else {
      await PublishPostHttp.addMark({
        postId,
        marckedUserId: _id,
      })
      setIsOpenSave(true)
    }

    if (post) {
      setPost({
        ...post,
        isMarked: !post.isMarked,
      })
    }
  }

  const updateNotification = async (
    postId: string,
    typeNotification: NOTIFICATION_POST,
  ) => {
    setUpdate(true)
    if (post) {
      await PublishPostHttp.updateNotification({
        postId: postId,
        userId: _id,
        typeNotification,
      })

      setPost({
        ...post,
        [NOTIFICATION_POST.COMMENT === typeNotification
          ? 'isNotificatedComment'
          : 'isNotificatedPost']:
          NOTIFICATION_POST.COMMENT === typeNotification
            ? !post.isNotificatedComment
            : !post.isNotificatedPost,
      })
    }
  }

  const updatePin = async (repostId: string) => {
    setUpdate(true)
    if (post) {
      await PublishPostHttp.updatePin({
        repostId: repostId,
        userId: _id,
      })

      setPost({
        ...post,
        isPined: !post.isPined,
      })
    }
  }

  const hidePost = async (body: { hideUserId?: string; hideRepostId?: string }) => {
    setUpdate(true)
    if (post) {
      await PublishPostHttp.hidePost({
        ownerId: _id,
        ...body,
      })
      navigate(-1)
    }
  }

  const toProfileInfo = (prop: {
    _id: string
    email: string
    role: string
    fullName: string
    avatarFileName: string
  }) => {
    navigate('/profileinfo', {
      state: prop,
    })
  }

  const openModal = () => {
    if (post) {
      if (post.userId._id === _id) {
        setMyModalOpen(true)
      } else {
        setNeibModalOpen(true)
      }
    }
  }
  const getUser = async (item: string) => {
    const user = await UserHttp.getUserById({ userId: item })
    return user
  }

  const [userAvatars, setUserAvatars] = useState<HeaderMessageType[]>([])
  useEffect(() => {
    const EffectBody = async () => {
      let array: HeaderMessageType[] = []

      if (post) {
        if (post.peopleSaw.length > 3) {
          post?.peopleSaw
            .slice(post?.peopleSaw.length - 3, post?.peopleSaw.length)
            .map(async (item) => {
              let user = getUser(item)
              array.push(await user)
            })
        } else {
          post?.peopleSaw.map(async (item) => {
            let user = getUser(item)
            array.push(await user)
          })
        }
      }
      setUserAvatars(array)
    }
    EffectBody()
  }, [post, update])

  const [isreply, setIsreply] = useState<boolean>(false)
  const [replyData, setReplyData] = useState<{
    user: {
      _id: string
      fullName: string
      avatarFileName: string
    }
    commentText: string
    commentId: string
  }>({
    user: { _id: '', fullName: '', avatarFileName: '' },
    commentText: '',
    commentId: '',
  })
  const { role } = useAppSelector((state) => state.user)
  const returnDate = (d: Date) => {
    const date = moment(d)
    return `${date.format('MMM D,')} ${date.hour()}:${date.minute()}`
  }
  return post ? (
    <div className="commenst">
      <div className="commenst__slick">
        <div className="commenst__back">
          <ButtonLeftChevron onClick={() => navigate(-1)} />
          <button className="ui-button-back-route" onClick={openModal}>
            <IconServicesAllPoint />
          </button>
        </div>
        {role !== ROLE.USER && <div style={{ margin: '100px 0' }} />}
        {post?.filesName && (
          <PostSlick list={post?.filesName}>
            {post.filesName.length > 0 ? (
              post?.filesName?.map((it) => (
                <div className="admin__posts-list-row2-img">
                  <img src={`${apiBaseURL}/uploads/publish_post/${it}`} alt="" />
                </div>
              ))
            ) : (
              <div className="admin__posts-list-row2-img">
                <img className="posts__bg" src={`/Images/postsBG.png`} alt="" />
              </div>
            )}
          </PostSlick>
        )}
        <div className="commenst__slick-bookmark">
          <button
            onClick={() => {
              updateMark(post._id, post.isMarked)
            }}
            className={`ui-button-back-route ${
              post.isMarked && 'admin__posts-list-row4-repost--active'
            }`}
          >
            <IconProfileInfoBookmark />
          </button>
        </div>
      </div>
      <div className="commenst__content">
        <div className="commenst__user">
          <div className="commenst__user-img">
            {post.userId.avatarFileName ? (
              <img src={`${apiBaseURL}/uploads/avatar/${post.userId.avatarFileName}`} />
            ) : (
              <div
                style={{
                  backgroundColor: 'rgba(41, 52, 65, 1)',
                  borderRadius: '100%',
                  width: '100%',
                  height: '100%',
                  color: '#fff',
                  textAlign: 'center',
                  position: 'relative',
                }}
              >
                <span style={{ position: 'absolute', left: '13px', top: '12px' }}>
                  {post.userId.fullName.slice(0, 2)}
                </span>
              </div>
            )}
          </div>
          <div>
            <p className="commenst__user-info-name">{post.userId.fullName}</p>
            <p className="commenst__user-info-time">{returnDate(post.createdPostDate)}</p>
          </div>
        </div>
        <h5 className="commenst-title">{post.title}</h5>
        <p className="commenst-subtitle">{post.text}</p>
        <div className="commenst__viewmap">
          <PublicationPostCommentsView user={userAvatars} />
          <p className="commenst__view-text">
            Views <b>{post.peopleSaw.length}</b>
          </p>
          <UserServiceMap type="publish_post" item={post} />
        </div>
        <div>
          <h5 className="commenst-title">Comments</h5>
          <PublicationPostCommentsList
            comments={comments}
            setComments={setComments}
            setReplyData={setReplyData}
            setIsreply={setIsreply}
          />
        </div>
        <div className="admin__posts-list-row4">
          <button onClick={() => updateLike(post.likeId, post._id)}>
            {post.isLiked ? <IconPostsRedLike /> : <IconPostsLike />}

            <span>{post.likes}</span>
          </button>
          <button>
            <IconComment />
            <span>{countComments}</span>
          </button>
          <button
            onClick={() => setIsOpenShare(true)}
            className={`${
              (post.repostedUserId?._id === _id || post.isReposted) &&
              'admin__posts-list-row4-repost--active'
            }`}
            disabled={!(post.userId._id !== _id)}
          >
            <IconPostsRepost />
            <span>{post.countReposts}</span>
          </button>
          <div
            onClick={() => updateMark(post._id, post.isMarked)}
            className={`${post.isMarked && 'admin__posts-list-row4-repost--active'}`}
          >
            <IconProfileInfoBookmark />
          </div>
        </div>
        {isreply && (
          <div className="commenst__reply">
            <div className="commenst__reply-row1">
              <span className="commenst__user-info-time commenst__reply-row1-text">
                Reply To: <b>{replyData.user.fullName}</b>
              </span>
              <button className="" onClick={() => setIsreply(false)}>
                <IconAdminClose />
              </button>
            </div>
            <div className="commenst__reply-row2">
              <span className="commenst__user-info-time">
                On Comment:{' '}
                <b style={{ fontStyle: 'italic' }}>
                  "
                  {replyData.commentText.length < 20
                    ? replyData.commentText
                    : `${replyData.commentText.slice(0, 20)}...`}
                  "
                </b>
              </span>
            </div>
          </div>
        )}
        <div className="commenst__input">
          <TextareaAutosize
            value={text}
            onChange={(e) => setText(e.target.value)}
            className="messenger__chat-sender-autoresize"
            minRows={1}
            placeholder={`Comment as ${fullName}`}
          />
          <button
            className={`messenger__chat-sender-send ${
              !text && 'messenger__chat-sender-send--disabled'
            }`}
            onClick={
              !isreply
                ? sendComment
                : () =>
                    replyOnComment({
                      replyText: text,
                      commentId: replyData.commentId,
                      userId: _id,
                    })
            }
            disabled={!text}
          >
            Send
          </button>
        </div>
      </div>
      <PublicationPostMyModal
        deletePostList={() => {}}
        item={post}
        isOpen={myModalOpen}
        setIsOpen={setMyModalOpen}
        updateMark={updateMark}
        updateNotification={updateNotification}
        updatePin={updatePin}
      />
      <PublicationPostNeibModal
        item={post}
        isOpen={neibModalOpen}
        setIsOpen={setNeibModalOpen}
        updateMark={updateMark}
        updateNotification={updateNotification}
        toProfileInfo={toProfileInfo}
        hidePost={hidePost}
        updateContent={updateContent}
        content={content}
      />
      <PreviewModal
        apply={() =>
          updateRepost({
            postId: post?._id,
            isReposted: post.isReposted,
          })
        }
        copy={copy}
        setIsOpen={setIsOpenShare}
        isOpen={isOpenShare}
      />
      {isOpenSave && (
        <TempNotification setIsOpen={setIsOpenSave} isOpen={isOpenSave}>
          <IconBookMark />
        </TempNotification>
      )}
    </div>
  ) : (
    <Loader />
  )
}
