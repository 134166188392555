import L from 'leaflet'
import { useState } from 'react'
import { renderToString } from 'react-dom/server'
import { Polygon } from 'react-leaflet'
import { Marker } from 'react-leaflet'
import { UserInitialStateInterface } from '../../../redux/users'

export const PolygonWithText = ({
  item,
  setVassals,
  vassals,
  drawMode,
  setStart,
  setEnd,
  local = false,
}: {
  item: UserInitialStateInterface
  setVassals: (s: UserInitialStateInterface[]) => void
  vassals: UserInitialStateInterface[]
  drawMode: boolean
  setStart?: (s: [number, number]) => void
  setEnd?: (s: [number, number]) => void
  local?: boolean
}) => {
  const [polygon, setPolygon] = useState<L.Polygon | null>()
  const text = L.divIcon({
    html: renderToString(
      <h5 style={{ color: 'rgba(51, 97, 255, 1)' }}>
        @{item.fullName}
        <br />({item?.regionAdmin?.fullName})
      </h5>,
    ),
  })
  const textRegion = L.divIcon({
    html: renderToString(
      <h5 style={{ color: 'rgba(51, 97, 255, 1)' }}>
        @{item.fullName}
        <br />(<b>regional</b>)
      </h5>,
    ),
  })
  polygon?.on('click', (e) => {
    if (setStart) setStart([e.latlng.lat, e.latlng.lng])
    console.log(e + 'test')
  })
  return (
    <>
      {item.polygonCoords && (
        <Polygon positions={item.polygonCoords} ref={setPolygon}>
          {local ? (
            <Marker
              position={L.polygon(item.polygonCoords).getBounds().getCenter()}
              icon={text}
            />
          ) : (
            <Marker
              position={L.polygon(item.polygonCoords).getBounds().getCenter()}
              icon={textRegion}
            />
          )}
        </Polygon>
      )}
    </>
  )
}
