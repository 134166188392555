import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { isPasswordPattern } from '../../../utils/patterns'
import { InputPassword } from '../../ui/InputPassword'
import { useAppDispatch } from '../../../utils/hooks'
import { attemptSetPassword } from '../../../redux/auth'

const SetPassword = () => {
  const [password, setPassword] = useState('')
  const [passwordRepeat, setPasswordRepeat] = useState('')
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [validation, setValidation] = useState({
    password: true,
    passwordRepeat: true,
  })
  const invalid =
    !new RegExp(isPasswordPattern).test(password) ||
    !new RegExp(isPasswordPattern).test(passwordRepeat) ||
    password !== passwordRepeat

  const changePassword = async () => {
    if (invalid) {
      console.log('Invalid password, min 8, numbers and letters')
      return
    }

    dispatch(attemptSetPassword({ password }))
  }

  return (
    <>
      <h4 className="forget__title">Reset Password</h4>
      <h5 className="forget__subtitle">Create a new secured password for your account</h5>
      <div className="forget__password-input">
        <InputPassword
          password={password}
          setPassword={setPassword}
          errorMessage={'Invalid password, min 8, numbers and letters'}
          pattern={isPasswordPattern}
          isValidated={validation.password}
          setIsValidated={(s: boolean) => setValidation({ ...validation, password: s })}
        />
        <InputPassword
          password={passwordRepeat}
          setPassword={setPasswordRepeat}
          errorMessage={'Invalid password, min 8, numbers and letters'}
          pattern={isPasswordPattern}
          isValidated={validation.passwordRepeat}
          setIsValidated={(s: boolean) =>
            setValidation({ ...validation, passwordRepeat: s })
          }
        />
      </div>
      <button
        className={`login__button
            ${invalid && 'login__button--disabled'}
        `}
        onClick={changePassword}
        disabled={invalid}
      >
        Action Button
      </button>
    </>
  )
}

export default SetPassword
