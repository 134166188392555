import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import $api from '../../../http'
import { ActivitiesHttp } from '../../../http/activities-http'
import { PublishPostHttp } from '../../../http/publish-post-http'
import { ServiceHttp } from '../../../http/service-http'
import { Countries, PAGES } from '../../../types/enum'
import {
  LocationDistanceType,
  PostUserInterface,
  PublishEventItemInterface,
  PublishServiceItemInterface,
  SearchType,
} from '../../../types/types'
import { apiBaseURL, indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { CityType } from '../../admin-components/admin-panel/AdminPanelMapPage'
import { IconLocationPoint } from '../../svg/IconsLocation'
import { InputSearch } from '../../ui/InputSearch'
import { Modal } from '../../ui/Modal'
import CA from './../../admin-components/admin-panel/ca.json'
import FR from './../../admin-components/admin-panel/fr.json'
import UA from './../../admin-components/admin-panel/ua.json'

export const ExploreModalSearch = ({
  isOpen,
  setIsOpen,
  setIsOpenFilter,
  setLoad,
  filter,
  regions,
  map,
}: {
  isOpen: boolean
  setIsOpen: (s: boolean) => void
  setIsOpenFilter: (s: boolean) => void
  setLoad: (s: boolean) => void
  filter: string
  regions: LocationDistanceType[]
  map: L.Map
}) => {
  const navigate = useNavigate()
  const [search, setSearch] = useState<string>('')
  const { _id, role } = useAppSelector((state) => state.user)
  const [posts, setPosts] = useState<PostUserInterface[]>([])
  const [services, setServices] = useState<PublishServiceItemInterface[]>([])
  const [events, setEvents] = useState<PublishEventItemInterface[]>([])

  const [postsNormal, setPostsNormal] = useState<PostUserInterface[]>([])
  const [servicesNormal, setServicesNormal] = useState<PublishServiceItemInterface[]>([])
  const [eventsNormal, setEventsNormal] = useState<PublishEventItemInterface[]>([])

  const [latestSearch, setLatestSearch] = useState<SearchType[]>([])

  useEffect(() => {
    $api
      .post('search/get', {
        userId: _id,
      })
      .then((res) => {
        setLatestSearch(res.data)
      })
  }, [])

  useEffect(() => {
    const body = async () => {
      setLoad(true)
      //posts
      await PublishPostHttp.getTenPosts().then((res) => {
        setPosts(res)
      })
      //services
      await ServiceHttp.getTenPublishService().then((res) => {
        setServices(res)
      })
      //events
      await ActivitiesHttp.getTenPublishActivities({ userId: _id }).then((res) => {
        setEvents(res)
      })
    }
    body()
    setLoad(false)
  }, [])
  const toSearchType = (item: string) => {
    return item.toLowerCase().replaceAll(' ', '')
  }
  const toCity = (coords: [number, number], zoom: number) => {
    map.panTo({ lat: coords[0], lng: coords[1] })
    map.setZoom(8)
  }
  const [searchResults, setSearchResults] = useState<CityType[]>()
  useEffect(() => {
    let array: CityType[] = []
    FR.map((fr) => {
      if (toSearchType(fr.city).match(toSearchType(search))) {
        array.push({
          coords: [Number(fr.lat), Number(fr.lng)],
          name: fr.city,
          country: Countries.FR,
        })
      }
    })
    UA.map((fr) => {
      if (toSearchType(fr.city).match(toSearchType(search))) {
        array.push({
          coords: [Number(fr.lat), Number(fr.lng)],
          name: fr.city,
          country: Countries.UA,
        })
      }
    })
    CA.map((fr) => {
      if (toSearchType(fr.capital).match(toSearchType(search))) {
        array.push({
          coords: [Number(fr.latitude), Number(fr.longitude)],
          name: fr.capital,
          country: Countries.CA,
        })
      }
    })
    setSearchResults(array)
  }, [search])

  const navigateToComments = async (postId: string) => {
    navigate(`${indexPathByRole(role)}/comments?postId=${postId}`)
    await $api.post('search/update', {
      userId: _id,
      postId,
    })
  }

  const toPublishActivities = async (eventId: string) => {
    navigate(`/user/activities/publish-activities-item?publishActivitiesId=${eventId}`)
    await $api.post('search/update', {
      userId: _id,
      eventId,
    })
  }
  const toPublishService = async (serviceId: string) => {
    navigate(`/user/service/publish-service?publishServiceId=${serviceId}`)
    await $api.post('search/update', {
      userId: _id,
      serviceId,
    })
  }

  useEffect(() => {
    // posts
    let p: PostUserInterface[] = []
    posts.find((item) => {
      if (
        (item.addressLocation || item.userId.fullName || item.title || item.text)
          .toLowerCase()
          .includes(search.toLowerCase())
      ) {
        p.push(item)
      }
    })
    // services
    let s: PublishServiceItemInterface[] = []
    services.find((item) => {
      if (
        (item.addressLocation || item.userId.fullName || item.title || item.text)
          .toLowerCase()
          .includes(search.toLowerCase())
      ) {
        s.push(item)
      }
    })
    // events
    let e: PublishEventItemInterface[] = []
    events.find((item) => {
      if (
        (item.addressLocation || item.title || item.text || item.url)
          .toLowerCase()
          .includes(search.toLowerCase())
      ) {
        e.push(item)
      }
    })
    setPostsNormal(p)
    setServicesNormal(s)
    setEventsNormal(e)
  }, [search])
  useEffect(() => {
    if (!search.length) {
      setPostsNormal([])
      setServicesNormal([])
      setEventsNormal([])
    }
  }, [search])

  return (
    <div
      className={`activities__favor-modal  ${
        isOpen ? 'activities__favor-modal--open' : ''
      }`}
    >
      <Modal className="explore__search-modal-height" setIsOpen={setIsOpen}>
        <div
          onClick={(e) => {
            e.stopPropagation()
            setIsOpen(false)
          }}
          className="activities__favor-modal-linebody"
        >
          <button className="activities__favor-modal-line" />
        </div>
        <div className="publish__modaladd post__modal explore__searching">
          <InputSearch
            search={true}
            value={search}
            changeValue={setSearch}
            placeholder={
              <>
                Search on <b>NeighborHarbour</b>
              </>
            }
            filter={true}
            onClickFilter={() => setIsOpenFilter(true)}
            bg={'rgba(247, 248, 250, 1)'}
          />
        </div>
        {!search.length && (
          <div className="explore__searching-results">
            <div className="explore__searching-results-list">
              {regions.map((item) => (
                <div className="explore__searching-results-list-item">
                  {item?.file ? (
                    <img src={`${apiBaseURL}/uploads/map/${item.file}`} />
                  ) : (
                    <IconLocationPoint />
                  )}
                  <div>
                    <h5>{item.title}</h5>
                    {/* <h6>{item.text}</h6> */}
                  </div>
                </div>
              ))}
              {latestSearch.slice(0, 2).map((item) => (
                <>
                  {item.postId && (
                    <div
                      className="explore__searching-results-list-item"
                      onClick={() => navigateToComments(item.postId._id)}
                    >
                      {item.postId?.filesName.length ? (
                        <img
                          src={`${apiBaseURL}/uploads/publish_post/${item.postId.filesName}`}
                        />
                      ) : (
                        <img
                          src="/Images/postsBG.png"
                          className="posts__bg"
                          style={{
                            maxWidth: '37.5px',
                            maxHeight: '37.5px',
                            borderRadius: '8px',
                          }}
                        />
                      )}
                      <div>
                        <h5>{item.postId.title} | Post</h5>
                        <h6>{item.postId.text}</h6>
                      </div>
                    </div>
                  )}
                  {item.eventId && (
                    <div
                      className="explore__searching-results-list-item"
                      onClick={() => toPublishActivities(item.eventId._id)}
                    >
                      {item.eventId?.filesName.length ? (
                        <img
                          src={`${apiBaseURL}/uploads/publish_post/${item.eventId.filesName}`}
                        />
                      ) : (
                        <img
                          src="/Images/postsBG.png"
                          className="posts__bg"
                          style={{
                            maxWidth: '37.5px',
                            maxHeight: '37.5px',
                            borderRadius: '8px',
                          }}
                        />
                      )}{' '}
                      <div>
                        <h5>{item.eventId.title} | Event</h5>
                        <h6>{item.eventId.text}</h6>
                      </div>
                    </div>
                  )}
                  {item.serviceId && (
                    <div
                      className="explore__searching-results-list-item"
                      onClick={() => toPublishService(item.serviceId._id)}
                    >
                      {item.serviceId?.filesName.length ? (
                        <img
                          src={`${apiBaseURL}/uploads/publish_post/${item.serviceId.filesName[0]}`}
                        />
                      ) : (
                        <img
                          src="/Images/postsBG.png"
                          className="posts__bg"
                          style={{
                            maxWidth: '37.5px',
                            maxHeight: '37.5px',
                            borderRadius: '8px',
                          }}
                        />
                      )}{' '}
                      <div>
                        <h5>{item.serviceId.title} | Service</h5>
                        <h6>{item.serviceId.text}</h6>
                      </div>
                    </div>
                  )}
                </>
              ))}
            </div>
          </div>
        )}
        <div className="explore__searching-results">
          <div className="explore__searching-results-list">
            {searchResults?.slice(0, 4)?.map((item) => (
              <div
                className="explore__searching-results-list-item"
                onClick={() => toCity(item.coords, 10)}
              >
                <div>
                  <h5>{item.name}</h5>
                  <h6>{item.country}</h6>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="explore__searching-results">
          {eventsNormal.length || postsNormal.length || servicesNormal.length ? (
            <div className="explore__searching-results-list">
              {filter === PAGES.MAIN &&
                postsNormal.map((item) => (
                  <div
                    className="explore__searching-results-list-item"
                    onClick={() => navigateToComments(item._id)}
                  >
                    {item?.filesName.length ? (
                      <img src={`${apiBaseURL}/uploads/publish_post/${item.filesName}`} />
                    ) : (
                      <img
                        src="/Images/postsBG.png"
                        className="posts__bg"
                        style={{
                          maxWidth: '37.5px',
                          maxHeight: '37.5px',
                          borderRadius: '8px',
                        }}
                      />
                    )}
                    <div>
                      <h5>{item.title} | Post</h5>
                      <h6>{item.text}</h6>
                    </div>
                  </div>
                ))}
              {filter === PAGES.ACTIVITIES &&
                eventsNormal.map((item) => (
                  <div
                    className="explore__searching-results-list-item"
                    onClick={() => toPublishActivities(item._id)}
                  >
                    {item?.filesName.length ? (
                      <img src={`${apiBaseURL}/uploads/publish_post/${item.filesName}`} />
                    ) : (
                      <img
                        src="/Images/postsBG.png"
                        className="posts__bg"
                        style={{
                          maxWidth: '37.5px',
                          maxHeight: '37.5px',
                          borderRadius: '8px',
                        }}
                      />
                    )}{' '}
                    <div>
                      <h5>{item.title} | Event</h5>
                      <h6>{item.text}</h6>
                    </div>
                  </div>
                ))}
              {filter === PAGES.SERVICES &&
                servicesNormal.map((item) => (
                  <div
                    className="explore__searching-results-list-item"
                    onClick={() => toPublishService(item._id)}
                  >
                    {item?.filesName.length ? (
                      <img
                        src={`${apiBaseURL}/uploads/publish_post/${item.filesName[0]}`}
                      />
                    ) : (
                      <img
                        src="/Images/postsBG.png"
                        className="posts__bg"
                        style={{
                          maxWidth: '37.5px',
                          maxHeight: '37.5px',
                          borderRadius: '8px',
                        }}
                      />
                    )}{' '}
                    <div>
                      <h5>{item.title} | Service</h5>
                      <h6>{item.text}</h6>
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            ''
          )}
        </div>
      </Modal>
    </div>
  )
}
