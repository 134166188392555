export const isPasswordPattern = /^(?=.*[0-9])(?=.*[a-zA-Z]).{8,}$/

export const isEmptyFiledsObject = (obj: any) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (!obj[key]) {
        return false
      }
    }
  }

  return true
}
