import { AsyncThunk } from '@reduxjs/toolkit'
import { Form, FormInstance, Select, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { FaRegCircle } from 'react-icons/fa'
import { HiOutlineSearch } from 'react-icons/hi'
import { MdOutlineArrowForwardIos } from 'react-icons/md'
import { UserInterface } from '../../../../redux/userNew'
import { useAppDispatch } from '../../../../utils/hooks'

export type SelectSearchKeyField = keyof Pick<
  UserInterface,
  'professions' | 'skills' | 'interests'
>

export type ProfileSearchSelectFormValues<T extends SelectSearchKeyField> = {
  [key in T]: UserInterface[T]
}

interface ProfileSearchSelectProps<T extends SelectSearchKeyField> {
  placeholderLabel: string
  translationNamespace: string
  form: FormInstance<ProfileSearchSelectFormValues<T>>
  formFieldName: SelectSearchKeyField
  optionList: Record<string, string> // json
  attemptUpdateAction: AsyncThunk<Pick<UserInterface, T>, Pick<UserInterface, T>, {}>

  initialValues?: string[]
  maxCount?: number
}

// const debounceCallback = debounce((value, onChanged) => {
//   onChanged(value)
// }, 300)

export const ProfileSearchSelect = <T extends SelectSearchKeyField>({
  placeholderLabel,
  translationNamespace,
  formFieldName,
  optionList,
  attemptUpdateAction,
  initialValues,
  maxCount,
  form,
}: ProfileSearchSelectProps<T>) => {
  // useImperativeHandle(
  //   ref,
  //   () => ({
  //     onPrimaryButtonClicked: form.submit,
  //   }),
  // )

  const { t } = useTranslation(translationNamespace)

  const dispatch = useAppDispatch()

  function handleSubmitForm(values: ProfileSearchSelectFormValues<T>) {
    dispatch(attemptUpdateAction(values))
  }
  //isLoading || !currentSelection
  return (
    <Form
      form={form}
      name="basic"
      layout="vertical"
      initialValues={{ [formFieldName]: initialValues }}
      onFinish={handleSubmitForm}
    >
      <Form.Item name={formFieldName}>
        <Select
          showSearch
          allowClear
          options={Object.keys(optionList).map((key) => ({ value: key, label: t(key) }))}
          // onSearch={(value) => debounceCallback(value, (value) => console.log('debounced: ', value))}
          optionRender={({ label }) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FaRegCircle style={{ marginRight: '0.75em' }} />
              <span>{label}</span>
            </div>
          )}
          dropdownAlign={{ offset: [0, 30] }}
          virtual
          mode="tags"
          tokenSeparators={[',']}
          suffixIcon={<MdOutlineArrowForwardIos />}
          optionFilterProp="label"
          maxCount={maxCount}
          placeholder={
            <Space>
              <HiOutlineSearch size={15} />
              <span>
                Search <b>{placeholderLabel}</b>
              </span>
            </Space>
          }
        />
      </Form.Item>
    </Form>
  )
}
