import { AxiosResponse } from 'axios'
import $api from '.'
import { SEX } from '../types/enum'
import { Advertise, AdvertisePrise, OptionsType, RegionType } from '../types/types'

export class AdvertisementHttp {
  static async addAdvertise(payload: FormData) {
    const res: AxiosResponse<Advertise> = await $api.post(
      'advertisement/add-advertise',
      payload,
    )
    return res.data
  }
  static async deleteAdvertise(payload: { _id: string }) {
    const res: AxiosResponse<Advertise> = await $api.post(
      'advertisement/delete-ad',
      payload,
    )
    return res.data
  }
  static async deleteRegion(payload: { _id: string }) {
    const res: AxiosResponse<RegionType> = await $api.post(
      'advertisement/delete-region',
      payload,
    )
    return res.data
  }
  static async updateText(payload: {
    adId: string
    text: string
    title: string
    ownerId: string
    link: string
  }) {
    const res: AxiosResponse<Advertise> = await $api.post(
      'advertisement/add-text',
      payload,
    )
    return res.data
  }
  static async updateLocation(payload: {
    radiusSize?: number
    polygonCoords?: any[]
    center: [number, number]
    adId: string
    adminId: string
  }) {
    const res: AxiosResponse<Advertise> = await $api.post(
      'advertisement/add-location',
      payload,
    )
    return res.data
  }
  static async updateInterests(payload: { interests: OptionsType; adId: string }) {
    const res: AxiosResponse<Advertise> = await $api.post(
      'advertisement/add-interests',
      payload,
    )
    return res.data
  }
  static async updateGender(payload: { adId: string; gender: SEX[]; age: number[] }) {
    const res: AxiosResponse<Advertise> = await $api.post(
      'advertisement/add-gender',
      payload,
    )
    return res.data
  }
  static async updateBudget(payload: {
    adId: string
    budget: number
    duration: [Date, Date]
  }) {
    const res: AxiosResponse<Advertise> = await $api.post(
      'advertisement/add-budget',
      payload,
    )
    return res.data
  }
  static async addPriseForClick(payload: {
    radiusSize?: number
    polygonCoords?: number[]
    center?: [number, number]
    ownerId: string
    prise?: number
  }) {
    const res: AxiosResponse<Advertise> = await $api.post(
      'advertisement/add-prise',
      payload,
    )
    return res.data
  }
  static async getAdvertisementById(payload: { adId: string }) {
    const res: AxiosResponse<Advertise> = await $api.post(
      'advertisement/get-advertise',
      payload,
    )
    return res.data
  }
  static async updateClick(payload: { adId: string; countView: boolean }) {
    const res: AxiosResponse<Advertise> = await $api.post(
      'advertisement/update-click',
      payload,
    )
    return res.data
  }
  static async getPriseById(payload: { ownerId: string }) {
    const res: AxiosResponse<AdvertisePrise> = await $api.post(
      'advertisement/get-prise-by-user-id',
      payload,
    )
    return res.data
  }
  static async getAdvertisementAll(payload: { ownerId?: string }): Promise<Advertise[]> {
    const res: AxiosResponse<Advertise[]> = await $api.post(
      'advertisement/get-all-advertise',
      payload,
    )
    return res.data
  }
  // region

  static async addRegion(payload: {
    locationId: string
    title: string
    text: string
    regId: string | null
  }) {
    const res: AxiosResponse<RegionType> = await $api.post(
      'advertisement/add-region',
      payload,
    )
    return res.data
  }

  static async addRegionRule(payload: {
    regionId: string
    view: number
    click: number
    cashLocalAdmin: number
    cashRegionAdmin: number
  }) {
    const res: AxiosResponse<RegionType> = await $api.post(
      'advertisement/add-region-rule',
      payload,
    )
    return res.data
  }

  static async getRegionById(payload: { regionId: string }) {
    const res: AxiosResponse<RegionType> = await $api.post(
      'advertisement/get-region',
      payload,
    )
    return res.data
  }

  static async getRegionAll() {
    const res: AxiosResponse<RegionType[]> = await $api.post(
      'advertisement/get-all-region',
    )
    return res.data
  }
}
