import { Route, Routes, useLocation } from 'react-router-dom'
import Require from './Require'

import { PublicationPostComments } from '../general-components/publication-lists/PublicationPostComments'
import { Explore } from '../user-components/explore/Explore'
import { FooterNav } from '../user-components/footer-navigate/FooterNav'
import { Messeges } from '../user-components/messeges/Messeges'
import { NewsFeeds } from '../user-components/newsfeed/NewsFeeds'

import { UserActivities } from '../user-components/activities/UserActivities'

import { UserNotification } from '../user-components/notification/UserNotification'
import { UserServices } from '../user-components/services/UserServices'

import { Promotion } from '../general-components/publication-lists/Promotion'
import { ExploreSearchRouter } from '../user-components/explore/ExploreSearchRouter'

const UserRouter = () => {
  const location = useLocation()

  return (
    <>
      <Routes>
        <Route path="explore/*" element={<Require.Role granted={<Explore />} />} />
        <Route path="service/*" element={<Require.Role granted={<UserServices />} />} />
        <Route
          path="activities/*"
          element={<Require.Role granted={<UserActivities />} />}
        />
        <Route path="messeges/*" element={<Require.Role granted={<Messeges />} />} />
        <Route
          path="/search/*"
          element={<Require.Role granted={<ExploreSearchRouter />} />}
        />
        <Route
          path="notification/*"
          element={<Require.Role granted={<UserNotification />} />}
        />
        <Route path="comments" element={<PublicationPostComments />} />
        <Route path="posts" element={<Require.Role granted={<NewsFeeds />} />} />
        <Route path="*" element={<Require.Role granted={<NewsFeeds />} />} />
        <Route
          path="advertisement/*"
          element={<Require.Role granted={<Promotion />} />}
        />
      </Routes>
      {!location.pathname.includes('map') && <FooterNav />}
    </>
  )
}

export default UserRouter
