import { Slider } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AdvertisementHttp } from '../../../http/advertisement'
import { UserHttp } from '../../../http/user-http'
import { UserInitialStateInterface } from '../../../redux/users'
import { Advertise } from '../../../types/types'
import { indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconLeftChevrons } from '../../svg/IconChevrons'
import { success } from '../../ui/LoadSuccess'
import UiDateTimePicker from '../../ui/UiDataTimePicker'
import { AdvertisementStepper } from './AdvertisementStepper'
import { inside, insideCircle } from '../../../utils/inside'

export const AdvertisementBudget = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { role } = useAppSelector((state) => state.user)
  const [value, setValue] = useState(5)
  const [startDate, setStartDate] = useState<dayjs.Dayjs>(dayjs(new Date()))
  const [endDate, setEndDate] = useState<dayjs.Dayjs>(dayjs(new Date()))
  const [Duration, setDuration] = useState(0)
  const validate = !Boolean(value && Duration && startDate !== endDate)
  function valuetext(value: number) {
    return `${value}$ daily`
  }
  const [advertisement, setAdvertisement] = useState<Advertise>()
  useEffect(() => {
    AdvertisementHttp.getAdvertisementById({ adId: location.state.adId }).then((res) =>
      setAdvertisement(res),
    )
  }, [])
  const [users, setUsers] = useState<UserInitialStateInterface[]>([])
  useEffect(() => {
    if (advertisement) {
      UserHttp.getAllUsers().then((res) => {
        res.map((item) => {
          if (
            item.coords &&
            ((advertisement.polygonCoords &&
              inside(
                [item.coords?.lat, item.coords?.lng],
                advertisement.polygonCoords,
              )) ||
              (advertisement.center &&
                insideCircle(
                  [item.coords.lat, item.coords.lng],
                  advertisement.radiusSize,
                  advertisement.center,
                )))
          ) {
            setUsers((s) => [...s, item])
          }
        })
      })
    }
  }, [advertisement])

  useEffect(() => {
    if (startDate.year() === endDate.year()) {
      if (startDate.month() === endDate.month()) {
        setDuration(endDate.day() - startDate.day())
      }
      if (startDate.month() < endDate.month()) {
        let m = endDate.month() - startDate.month()
        if (startDate.day() === endDate.day()) {
          setDuration(m * 30)
        } else {
          if (endDate.day() > startDate.day()) {
            let d = endDate.day() - startDate.day()
            setDuration(m * d)
          } else {
            let d1 = startDate.day() - endDate.day()
            setDuration(m * d1)
          }
        }
      }
    }
    if (startDate.year() < endDate.year()) {
      const y = endDate.year() - startDate.year()
      if (startDate.month() === endDate.month()) {
        if (startDate.day() === endDate.day()) {
          setDuration(y * 12 * 30)
        } else {
          if (endDate.day() > startDate.day()) {
            let d = endDate.day() - startDate.day()
            setDuration(y * 12 * d)
          } else {
            let d = startDate.day() - endDate.day()
            setDuration(y * 12 * d)
          }
        }
      }
      if (startDate.month() < endDate.month()) {
        let m = endDate.month() - startDate.month()
        if (startDate.day() === endDate.day()) {
          setDuration(m * 30 * y)
        } else {
          if (endDate.day() > startDate.day()) {
            let d = endDate.day() - startDate.day()
            setDuration(m * d * y)
          } else {
            let d1 = startDate.day() - endDate.day()
            setDuration(m * d1 * y)
          }
        }
      }
    }
  }, [startDate, endDate])

  const addBudget = async () => {
    try {
      console.log(location.state.adId)
      AdvertisementHttp.updateBudget({
        adId: location.state.adId,
        budget: value,
        duration: [startDate.toDate(), endDate.toDate()],
      })
      success()
      navigate(`${indexPathByRole(role)}/advertisement/finish`, {
        state: { adId: location.state.adId, Duration },
      })
    } catch (error) {
      console.log('server error ' + error)
    }
  }

  return (
    <div className="advertisement__add">
      <div className="advertisement__add-header">
        <div
          className="advertisement__add-header-btn"
          onClick={() =>
            navigate(`${indexPathByRole(role)}/advertisement/add-community-selection`, {
              state: { adId: location.state.adId },
            })
          }
        >
          <IconLeftChevrons fill="rgba(21, 16, 77, 1)" />
          <span>Budget and duration</span>
        </div>
      </div>
      <AdvertisementStepper i={3} />
      <div className="advertisement__add-budget">
        <div className="advertisement__add-gender-title">
          {Duration > 0 && (
            <h5>
              {value * Duration}$ for {Duration} days
            </h5>
          )}
          <h6>total costs</h6>
        </div>
        <div className="advertisement__add-gender-title">
          <span>
            <b>
              {Duration
                ? Math.round(users.length * value * Duration * 0.01)
                : Math.round(users.length * value * 0.01) + 1}
              -
              {Duration
                ? Math.round(users.length * value * Duration * 0.02)
                : Math.round(users.length * value * 0.02) + 2}
            </b>
          </span>
          <h6>predicted reach</h6>
        </div>
      </div>
      <div className="advertisement__add-budget-selection">
        <h5 className="advertisement__add-main-title">Budget</h5>
        <Slider
          valueLabelFormat={valuetext}
          value={value}
          onChange={(e, newValue) => setValue(newValue as number)}
          defaultValue={20}
          min={5}
          max={100}
          aria-label="Default"
          valueLabelDisplay="auto"
        />
      </div>
      <div className="advertisement__add-budget-selection">
        <h5 className="advertisement__add-main-title">Duration</h5>
        <div className="">
          <div className="publish__activities-picker">
            <UiDateTimePicker startDate={startDate} setStartDate={setStartDate} />
          </div>
          <div className="publish__activities-picker">
            <UiDateTimePicker startDate={endDate} setStartDate={setEndDate} />
          </div>
        </div>
      </div>
      <button
        className={`advertisement__add-main-btn ${
          validate && 'advertisement__add-main-btn-disabled'
        }`}
        onClick={addBudget}
        disabled={validate}
      >
        Continue
      </button>
    </div>
  )
}
