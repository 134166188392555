import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { setValueProfileReducer } from '../../../../redux/profile'
import { setLoader } from '../../../../redux/users'
import { profileTextInfo } from '../../../../redux/profile'
import { PRIVACY } from '../../../../types/enum'
import { useAppDispatch, useAppSelector } from '../../../../utils/hooks'
import { IconClosedEye, IconNeibs, IconOpenEye } from '../../../svg/IconPassEye'
import { IconServicesAllPoint } from '../../../svg/IconServicesAll'
import { PrivacyModal } from '../PrivacyModal'
import { StandaloneScreenLayout } from '../../../ui/Layout/StandaloneScreenLayout'
import { stepDefinitions } from '../../../../utils/hooks/useNextSetUpProfilePath'
import { PROFILE_STEP } from '../../../../types/server-communication/user'
import { useAttemptListener } from 'dev-masters-react-kit'
import { store } from '../../../../store'
import { attemptUpdatePrivacy } from '../../../../redux/userNew'

const PRIVACY_LIST = [
  {
    value: PRIVACY.EVERYONE,
    label: <IconOpenEye />,
  },
  {
    value: PRIVACY.ONLYME,
    label: <IconClosedEye />,
  },
  {
    value: PRIVACY.NEIBS,
    label: <IconNeibs />,
  },
]

export const ProfilePrivacy = () => {
  const initPrivacy = useAppSelector((state) => state.profile).privacy
  const [privacy, setPrivacy] = useState({
    value: PRIVACY.EVERYONE,
    label: <IconOpenEye />,
  })

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { _id } = useAppSelector((state) => state.user)

  useEffect(() => {
    const initState = PRIVACY_LIST.find((item) => item.value === initPrivacy)
    if (initState) {
      setPrivacy(initState)
    }
  }, [initPrivacy])

  const handlerChangePrivacy = async () => {
    try {
      dispatch(setLoader(true))
      const res = await profileTextInfo({
        lastStepChangeProfile: '/profile/interest-zone',
        privacy: privacy.value,
        _id,
      })

      dispatch(setValueProfileReducer(res))
      dispatch(setLoader(false))
      navigate('/profile/interest-zone')
    } catch (error) {
      dispatch(setLoader(false))
      console.log(error + 'privacy')
    }
  }
  const [isOpen, setIsOpen] = useState(false)
  const [text, setText] = useState('')

  const navigateNext = () =>
    navigate('/profile/' + stepDefinitions[PROFILE_STEP.ABOUT_ME].path)

  const isLoading = useAttemptListener({
    store,
    attempt: attemptUpdatePrivacy,
    onFulfilled: navigateNext,
  })

  return (
    <StandaloneScreenLayout
      title="Privacy"
      subTitle="Please setup your privacy settings so we can personalize your experience"
      secondButtonTitle="DEV skip"
      onSecondButtonClicked={() => {
        navigate('/profile/about') //stepDefinitions[PROFILE_STEP.PRIVACY].path)
      }}
      onPrimaryButtonClicked={() =>
        dispatch(
          attemptUpdatePrivacy({
            optionOne: 'boolean',
            optionTwo: 'enum etc...',
          }),
        )
      }
    >
      <div className="profile__method-body" style={{ overflowY: 'scroll' }}>
        <div
          className="profile__method-body-item"
          style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}
        >
          <h6 className="profile__privacy-title">Who can see my profile</h6>
          <button
            onClick={() => {
              setIsOpen(true)
              setText('profile')
            }}
          >
            <IconServicesAllPoint />
          </button>
        </div>
        <div
          className="profile__method-body-item"
          style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}
        >
          <h6 className="profile__privacy-title">Who can see my phone</h6>
          <button
            onClick={() => {
              setIsOpen(true)
              setText('phone')
            }}
          >
            <IconServicesAllPoint />
          </button>
        </div>
        <div
          className="profile__method-body-item"
          style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}
        >
          <h6 className="profile__privacy-title">Who can see my email</h6>
          <button
            onClick={() => {
              setIsOpen(true)
              setText('email')
            }}
          >
            <IconServicesAllPoint />
          </button>
        </div>
        <div
          className="profile__method-body-item"
          style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}
        >
          <h6 className="profile__privacy-title">Who can see my photo</h6>
          <button
            onClick={() => {
              setIsOpen(true)
              setText('photo')
            }}
          >
            <IconServicesAllPoint />
          </button>
        </div>
        <div
          className="profile__method-body-item"
          style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}
        >
          <h6 className="profile__privacy-title">Who can see my interest zone</h6>
          <button
            onClick={() => {
              setIsOpen(true)
              setText('interest zone')
            }}
          >
            <IconServicesAllPoint />
          </button>
        </div>
        <div
          className="profile__method-body-item"
          style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}
        >
          <h6 className="profile__privacy-title">Who can see about me</h6>
          <button
            onClick={() => {
              setIsOpen(true)
              setText('about me')
            }}
          >
            <IconServicesAllPoint />
          </button>
        </div>
        <div
          className="profile__method-body-item"
          style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}
        >
          <h6 className="profile__privacy-title">Who can see my profession</h6>
          <button
            onClick={() => {
              setIsOpen(true)
              setText('profession')
            }}
          >
            <IconServicesAllPoint />
          </button>
        </div>
        <div
          className="profile__method-body-item"
          style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}
        >
          <h6 className="profile__privacy-title">Who can see my skills</h6>
          <button
            onClick={() => {
              setIsOpen(true)
              setText('skills')
            }}
          >
            <IconServicesAllPoint />
          </button>
        </div>
        <div
          className="profile__method-body-item"
          style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}
        >
          <h6 className="profile__privacy-title">Who can see my interests</h6>
          <button
            onClick={() => {
              setIsOpen(true)
              setText('interests')
            }}
          >
            <IconServicesAllPoint />
          </button>
        </div>
        <div
          className="profile__method-body-item"
          style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}
        >
          <h6 className="profile__privacy-title">Who can see my br. date</h6>
          <button
            onClick={() => {
              setIsOpen(true)
              setText('birth date')
            }}
          >
            <IconServicesAllPoint />
          </button>
        </div>
        <div
          className="profile__method-body-item"
          style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}
        >
          <h6 className="profile__privacy-title">Who can see my br. place</h6>
          <button
            onClick={() => {
              setIsOpen(true)
              setText('br. place')
            }}
          >
            <IconServicesAllPoint />
          </button>
        </div>
        <div
          className="profile__method-body-item"
          style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}
        >
          <h6 className="profile__privacy-title">Who can see my nationality</h6>
          <button
            onClick={() => {
              setIsOpen(true)
              setText('nationality')
            }}
          >
            <IconServicesAllPoint />
          </button>
        </div>
        <div
          className="profile__method-body-item"
          style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}
        >
          <h6 className="profile__privacy-title">Who can see my orientation</h6>
          <button
            onClick={() => {
              setIsOpen(true)
              setText('orientation')
            }}
          >
            <IconServicesAllPoint />
          </button>
        </div>
        <div
          className="profile__method-body-item"
          style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}
        >
          <h6 className="profile__privacy-title">Who can see my family status</h6>
          <button
            onClick={() => {
              setIsOpen(true)
              setText('family status')
            }}
          >
            <IconServicesAllPoint />
          </button>
        </div>
        <div
          className="profile__method-body-item"
          style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}
        >
          <h6 className="profile__privacy-title">Who can see my online / offline</h6>
          <button
            onClick={() => {
              setIsOpen(true)
              setText('online / offline')
            }}
          >
            <IconServicesAllPoint />
          </button>
        </div>
      </div>
      {isOpen && <PrivacyModal setIsOpen={setIsOpen} isOpen={isOpen} text={text} />}
    </StandaloneScreenLayout>
  )
}
