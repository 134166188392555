import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import $api from '../../../http'
import { UserHttp } from '../../../http/user-http'
import { ROLE } from '../../../types/enum'
import { indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconRightChevrons } from '../../svg/IconChevrons'
import { IconProfileInfoPen } from '../../svg/IconProfileInfo'
import { InputSearch } from '../../ui/InputSearch'
import { SliderButtons } from '../../ui/SliderButtons'
import { Acerdeon } from './Acerdeon'
import { SlickCategoriesCustom } from './SlickCategoriesCustom'

export interface UserChat {
  avatarFileName: string
  fullName: string
  userId: string
}

export const ProfileInfoHelpCenter = () => {
  const [isFaq, setIsFaq] = useState(true)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [value, setValue] = useState('')
  const navigate = useNavigate()
  const { role, _id } = useAppSelector((state) => state.user)

  const { lat, lng } = useAppSelector((state) => state.profile.coordinates)

  const openChatUser = async (adminId: string) => {
    await $api
      .post('messenger/open-chat', {
        participants: [
          {
            userId: adminId,
          },
          {
            userId: _id,
          },
        ],
        isSupport: true,
      })
      .then((res) => {
        console.log(res)
        if (res.data) {
          navigate(`${indexPathByRole(role)}/messeges/chat`, {
            state: {
              chat: res.data,
            },
          })
        }
      })
  }

  const openChat = async (roleSuppors: ROLE) => {
    const res = await UserHttp.getClosestUser({
      role: roleSuppors,
      myLat: lat,
      myLng: lng,
    })
    openChatUser(res.userId)
    // navigate(`${indexPathByRole(role)}/messeges/chat`, {
    //     state: {
    //         isSupport: true,
    //         participants: [res],
    //     },
    // })
  }

  return (
    <div className="profileinfo__help">
      <SliderButtons
        left={'FAQ'}
        right={'Contact us'}
        value={isFaq}
        changeValue={setIsFaq}
      />
      {isFaq ? (
        <>
          <div className="profileinfo__help-slider">
            <SlickCategoriesCustom>
              {['General', 'Account', 'Services', 'Discover'].map((item, index) => (
                <div
                  className={`activities__filter-item ${
                    index === currentSlide && 'activities__filter-item--active'
                  }`}
                  key={index}
                  onClick={() => setCurrentSlide(index)}
                >
                  {item}
                </div>
              ))}
            </SlickCategoriesCustom>
          </div>
          <div className="profileinfo__help-slider">
            <InputSearch
              placeholder={'Search FAQ'}
              value={value}
              changeValue={setValue}
            />
          </div>
          <div className="profileinfo__aboutnb">
            <Acerdeon />
            <Acerdeon />
            <Acerdeon />
          </div>
        </>
      ) : (
        <div className="profileinfo__aboutnb">
          <div className="profileinfo__aboutnb-item">
            <Link to={'/profileinfo/aboutneightborharbor'}>
              <p>
                <IconProfileInfoPen /> Customer Service
              </p>
            </Link>
            <IconRightChevrons />
          </div>
          <div
            className="profileinfo__aboutnb-item"
            onClick={() => openChat(ROLE.LOCAL_ADMIN)}
          >
            <p>
              <IconProfileInfoPen /> Local Coordinator
            </p>
            <IconRightChevrons />
          </div>
          <div
            className="profileinfo__aboutnb-item"
            onClick={() => openChat(ROLE.REGIONAL_ADMIN)}
          >
            <p>
              <IconProfileInfoPen />
              Regional Admin (Adv)
            </p>
            <IconRightChevrons />
          </div>
          <div className="profileinfo__aboutnb-item" onClick={() => openChat(ROLE.ADMIN)}>
            <p>
              <IconProfileInfoPen /> Head Office
            </p>
            <IconRightChevrons />
          </div>
        </div>
      )}
    </div>
  )
}
