import { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { getEventsWithMark } from '../../../redux/activities'
import { getServicesWithMark } from '../../../redux/categories'
import { getPostsWithMark } from '../../../redux/posts'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { ProfileInfoBookMark } from '../../general-components/profile-info/ProfileInfoBookMark'
import { UserHeaderNotification } from '../header/UserHeaderNotification'
import { UserNotificationList } from './UserNotificationList'

export const UserNotification = () => {
  const dispatch = useAppDispatch()
  const { _id } = useAppSelector((state) => state.user)

  useEffect(() => {
    dispatch(getEventsWithMark({ userId: _id }))
    dispatch(getPostsWithMark({ marckedUserId: _id }))
    dispatch(getServicesWithMark({ userId: _id }))
  }, [])
  return (
    <div className="user user--body">
      <UserHeaderNotification />
      <Routes>
        <Route path="notification-mark" element={<ProfileInfoBookMark />} />
        <Route path="notification-list" element={<UserNotificationList />} />
        <Route path="*" element={<UserNotificationList />} />
      </Routes>
    </div>
  )
}
