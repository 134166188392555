import { ReactNode } from 'react'

export const ExploreHeader = ({
  children,
  style,
}: {
  children: ReactNode
  style: { background: string; margin?: string }
}) => {
  return (
    <div
      style={{ background: style.background, margin: style?.margin }}
      className="explore__header-component"
    >
      {children}
    </div>
  )
}
