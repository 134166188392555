import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { setValueProfileReducer } from '../../../redux/profile'
import { profileTextInfo } from '../../../redux/profile'
import { PRIVACY } from '../../../types/enum'
import {
  CoordinatsInterface,
  PublishActivitiesOneItemInterface,
} from '../../../types/types'
import { apiBaseURL, indexPathByRole } from '../../route/indexPathByRole'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { IconTicket } from '../../svg/IconTicket'
import { success } from '../../ui/LoadSuccess'
import UiDateTimePicker from '../../ui/UiDataTimePicker'
import { PublishAddLocation } from './PublishAddLocation'
import { PublishEventList } from './PublishEventList'
import { PublicationMainComponent } from './PublishMainComponent'
import { ActivitiesHttp } from '../../../http/activities-http'
import { createAddressString } from '../../../utils/createAddressString'
import { IconLocationPoint } from '../../svg/IconsLocation'

export const PublishEvent = ({
  currentPrivacy,
  allowList,
}: {
  currentPrivacy: PRIVACY
  allowList: string[]
}) => {
  const location = useLocation()

  const props: {
    item: PublishActivitiesOneItemInterface
  } = location.state?.props

  const { _id } = useAppSelector((state) => state.user)
  const profile = useAppSelector((state) => state.profile)

  const [files, setFiles] = useState<(File | string)[]>(
    location?.state?.files ||
      props?.item?.filesName?.map((it) => `${apiBaseURL}/uploads/publish_post/${it}`) ||
      [],
  )
  const [text, setText] = useState(props?.item.text || location.state?.text || '')
  const [title, setTitle] = useState(props?.item.title || location.state?.title || '')
  const [Url, setUrl] = useState(props?.item.url || location.state?.Url || '')

  const [polygonCoords, setPolygonCoords] = useState<
    L.LatLng[] | L.LatLng[][] | L.LatLng[][][] | null
  >(location?.state?.polygonCoords || null)
  const [radiusSize, setRadiusSize] = useState<number>(location?.state?.radiusSize || 0)
  const [coordinates, setCoordinates] = useState<CoordinatsInterface>(
    location?.state?.coords,
  )

  const [startDate, setStartDate] = useState<dayjs.Dayjs>(
    dayjs(props?.item.startDate) || dayjs(new Date()),
  )
  const [activitiesId, setActivitiesId] = useState<string>(
    props?.item.activitiesId._id || location.state?.activitiesId || '',
  )
  // const [addressLocation, setAddressLocation] = useState(
  //     `${profile.country}, ${profile.city}, ${profile.street}, ${profile.houseNumber}`||props?.item.addressLocation)
  const [addressLocation, setAddressLocation] = useState(
    props?.item.addressLocation || '',
  )
  const validate = !Boolean(text && title && activitiesId && isValidURL(Url))
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [isShowPlaceholder, setIsShowPlaceholder] = useState(true)

  function isValidURL(str: string) {
    if (!str) {
      return true
    } else {
      let res = str.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
      )
      return res !== null
    }
  }
  const { role } = useAppSelector((state) => state.user)
  const [cityInput, setCityInput] = useState('')
  const [address, setAddress] = useState('')
  useEffect(() => {
    if (cityInput || address) {
      setIsShowPlaceholder(false)
    } else {
      setIsShowPlaceholder(true)
    }
  }, [cityInput, address])
  useEffect(() => {
    if (address) {
      setCityInput(address)
    }
  }, [address])
  useEffect(() => {
    if (cityInput) {
      console.log(1)
      const geocoder = new google.maps.Geocoder()
      geocoder.geocode({ address: cityInput }).then((res) => {
        const address = createAddressString(res.results[0].address_components)
        if (address.country && address.city) {
          setAddress(`${address.country}, ${address.city}`)
        }
      })
    }
  }, [cityInput])
  const handlerPublish = async () => {
    try {
      const formCatData = new FormData()
      let payload: {
        text: string
        title: string
        url: string
        userId: string
        coordinates: CoordinatsInterface
        privacyEvent: PRIVACY
        startDate: Date
        activitiesId: string
        userIdentityId: string
        addressLocation: string
        allowList: string[]
        radiusSize: number
        polygonCoords: L.LatLng[] | L.LatLng[][] | L.LatLng[][][] | null
        eventId?: string
      } = {
        text,
        title,
        coordinates,
        url: Url,
        userId: _id,
        privacyEvent: currentPrivacy,
        startDate: new Date(startDate?.toDate()),
        activitiesId,
        userIdentityId: profile.userIdentityId,
        addressLocation: addressLocation || '',
        allowList,
        radiusSize,
        polygonCoords,
        eventId: props?.item?._id,
      }
      formCatData.append('payload', JSON.stringify(payload))

      for (let index = 0; index < files.length; index++) {
        formCatData.append('files', files[index])
      }

      await ActivitiesHttp.addPublishActivitie(formCatData)

      if (!profile.isExploreDone) {
        const res = await profileTextInfo({
          isExploreDone: true,
          _id,
        })
        dispatch(setValueProfileReducer(res))
      }
      success()
      navigate(`${indexPathByRole(role)}/activities`)
    } catch (error) {
      console.log('publish post new' + error)
    }
  }
  return (
    <div className="user">
      <PublicationMainComponent
        files={files}
        setFiles={setFiles}
        text={text}
        setText={setText}
        title={title}
        setTitle={setTitle}
        placeholderTitle="Activity Name"
        placeholderText="describe your activity"
      />
      <PublishAddLocation
        setCoordinates={setCoordinates}
        addressLocation={addressLocation}
        setAddressLocation={setAddressLocation}
        polygonCoords={polygonCoords}
        radiusSize={radiusSize}
        coords={coordinates}
        prevStateEvent={{ title, text, activitiesId, Url, files }}
        type="event"
      />
      {/* <div className="location__fields">
                    <div className="login__email location__field">
                        <div className="location__field-icon">
                            <IconLocationPoint />
                        </div>
                        <input
                            type="text"
                            id="autocomplete--google"
                            className=" location__field-input"
                            placeholder=""
                            value={cityInput}
                            onChange={(e) => setCityInput(e.target.value)}
                        />
                        {isShowPlaceholder && (
                            <span className="profile__birth-input-placeholder">
                                {
                                    <>
                                        Search <b> city</b>, <b> street</b>,{" "}
                                        <b>index</b>
                                    </>
                                }
                            </span>
                        )}
                    </div>
                </div> */}
      <PublishEventList activitiesId={activitiesId} setActivitiesId={setActivitiesId} />
      <div className="publish__activities-picker">
        <UiDateTimePicker startDate={startDate} setStartDate={setStartDate} />
      </div>
      <div className="publish__activities-ticket">
        <IconTicket />
        <input
          value={Url}
          type="url"
          placeholder="Tickets"
          onChange={(e) => {
            setUrl(e.target.value)
          }}
        />
      </div>
      <button
        className={`publish__publish user--footer--button ${
          validate && 'services__add-button--disabled'
        }`}
        onClick={handlerPublish}
        disabled={validate}
      >
        Publish
      </button>
    </div>
  )
}
