import { AxiosResponse } from 'axios'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import $api from '../../../http'
import { ActivitiesHttp } from '../../../http/activities-http'
import { FeedBackHttp } from '../../../http/feedback-http'
import { PublishPostHttp } from '../../../http/publish-post-http'
import { ServiceHttp } from '../../../http/service-http'
import { UserHttp } from '../../../http/user-http'
import { UserInitialStateInterface, setLoader } from '../../../redux/users'
import { logout } from '../../../redux/auth'
import { UserIdentityInterface } from '../../../redux/profile'
import { NOTIFICATION_POST, ROLE } from '../../../types/enum'
import {
  ChatType,
  OptionsType,
  ParticipantType,
  PostUserInterface,
  PublishEventItemInterface,
  PublishServiceItemInterface,
} from '../../../types/types'
import { calculateDistance, calculateRate } from '../../../utils/calculateRate'
import { apiBaseURL, indexPathByRole } from '../../route/indexPathByRole'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { PublicationEventPanel } from '../../general-components/publication-lists/PublicationEventPanel'
import { PublicationPostMyModal } from '../../general-components/publication-lists/PublicationPostMyModal'
import { PublicationPostNeibModal } from '../../general-components/publication-lists/PublicationPostNeibModal'
import { PublicationPostsPanel } from '../../general-components/publication-lists/PublicationPostsPanel'
import { IconArrowRight } from '../../svg/IconArrow'
import { IconBook } from '../../svg/IconBook'
import { IconBookMark } from '../../svg/IconBookMark'
import { IconBottomChevrons, IconRightChevrons } from '../../svg/IconChevrons'
import { IconStars } from '../../svg/IconFavor'
import { IconNeibsCopy } from '../../svg/IconPassEye'
import { IconPostModalPin } from '../../svg/IconPostModal'
import {
  IconProfileInfoBookmark,
  IconProfileInfoBookmarkCopy,
} from '../../svg/IconProfileInfo'
import { IconServicesAllPoint } from '../../svg/IconServicesAll'
import { DarkBg } from '../../ui/DarkBg'
import { InputSearch } from '../../ui/InputSearch'
import { success } from '../../ui/LoadSuccess'
import { Loader } from '../../ui/Loader'
import { PostSlick } from '../../ui/PostSlick'
import { SlickCategories } from '../../ui/SlickCategories'
import { TempNotification } from '../../ui/TempNotification'
import { ActivitiesMyModal } from '../activities/ActivitiesMyModal'
import { ActivitiesNeibModalMany } from '../activities/ActivitiesNeibModalMany'
import { UserHeader } from '../header/UserHeader'

export const ExploreSearchMain = () => {
  const [search, setSearch] = useState('')
  const navigate = useNavigate()

  const { _id, role } = useAppSelector((state) => state.user)
  const { coordinates } = useAppSelector((state) => state.profile)
  const [load, setLoad] = useState(true)

  const [chats, setChats] = useState<ChatType[]>([])
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenAbout, setIsOpenAbout] = useState(false)
  const [isOpenSettings, setIsOpenSettings] = useState(false)
  const dispatch = useAppDispatch()
  const { publishActivities } = useAppSelector((state) => state.activities)
  const location = useLocation()
  const { fullName } = useAppSelector((state) => state.user)
  const [users, setUsers] = useState<UserInitialStateInterface[]>([])
  const { subCategories, categories } = useAppSelector((state) => state.categories)
  const { activities } = useAppSelector((state) => state.activities)
  const [services, setServices] = useState<PublishServiceItemInterface[]>([])
  const [posts, setPosts] = useState<PostUserInterface[]>([])
  const [allPageNumber, setAllPageNumber] = useState(1)
  const [pageNumber, setPageNumber] = useState(1)
  const [filterBtn, setFilterBtn] = useState<boolean>(true)
  const [identity, setIdentity] = useState<UserIdentityInterface[]>([])
  const [viewAllPosts, setViewAllPosts] = useState<number>(2)
  const [viewAllServices, setViewAllServices] = useState<number>(2)
  const [viewAllEvents, setViewAllEvents] = useState<number>(2)
  const [viewAllUsers, setViewAllUsers] = useState<number>(2)
  const [value, setValue] = useState<OptionsType>([])
  const [activitiesList, setActivitiesList] = useState<PublishEventItemInterface[]>([])
  const [isOpenSave, setIsOpenSave] = useState(false)
  const [currnetItem, setCurrentItem] = useState<PostUserInterface | null>(null)
  const [myModalOpen, setMyModalOpen] = useState(false)
  const [neibModalOpen, setNeibModalOpen] = useState(false)
  const { interests } = useAppSelector((state) => state.profile)

  const [currentSlide, setCurrentSlide] = useState(0)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [currentItemEvent, setCurrentItemEvent] = useState<PublishEventItemInterface>()

  const toProfileInfo = (prop: {
    _id: string
    email: string
    role: string
    fullName: string
    avatarFileName: string
  }) => {
    navigate('/profileinfo', {
      state: prop,
    })
  }

  useEffect(() => {
    setValue(interests)
  }, [])

  useEffect(() => {
    const effectBody = async () => {
      ServiceHttp.getTenPublishService().then((s) => setServices(s))
      PublishPostHttp.getTenPosts().then((s) => setPosts(s))
    }
    getActivities()
    getUsers()
    effectBody()
  }, [])

  const getActivities = async () => {
    setLoad(true)
    setActivitiesList(publishActivities)
    setLoad(false)
  }

  const getUsers = async () => {
    setLoad(true)
    const res: UserInitialStateInterface[] = await UserHttp.getAllUsers()
    let array: UserIdentityInterface[] = []
    setLoad(false)
    setIdentity(array)
    setUsers(res.filter((it) => it._id !== _id))
    dispatch(setLoader(false))
  }

  const getAllPostReload = async (userId: string) => {
    setLoad(true)
    setPosts([])
    for (let i = 1; i <= allPageNumber; i++) {
      const res = await PublishPostHttp.getPosts({
        pageNumber: i,
        userId,
      })
      setPosts(res.posts)
    }
    setLoad(false)
    dispatch(setLoader(false))
  }

  const openModal = (item: PostUserInterface) => {
    setCurrentItem(item)
    if (item.userId._id === _id) {
      setMyModalOpen(true)
    } else {
      setNeibModalOpen(true)
    }
  }

  const openModalEvent = (item: PublishEventItemInterface) => {
    setCurrentItemEvent(item)
    if (item.userId._id === _id) {
      setIsOpenModal(true)
    } else {
      setIsOpenModal(true)
    }
  }

  const saveSearchEvent = async (
    eventId: string,
    postId?: string,
    serviceId?: string,
  ) => {
    await $api.post('search/update', { eventId, userId: _id })
    success()
  }
  const saveSearchPost = async (postId: string) => {
    await $api.post('search/update', { postId, userId: _id })
    success()
  }
  const saveSearchService = async (serviceId: string) => {
    await $api.post('search/update', { serviceId, userId: _id })
    success()
  }

  const navigateToComments = (postId: string) => {
    navigate(`${indexPathByRole(role)}/comments?postId=${postId}`)
    saveSearchPost(_id)
  }

  useEffect(() => {
    const body = async () => {
      $api
        .post('messenger/list-chat', { _id, isSupport: false })
        .then((r: AxiosResponse<ChatType[]>) => {
          const list = r.data.map((item) => ({
            ...item,
            participants: item.participants.filter((p) => p.userId._id !== _id),
          }))
          setChats(list)
          setLoad(false)
        })
        .catch(() => {
          setLoad(false)
        })
    }
    body()
  }, [])

  const openChat = (participants: ParticipantType[], item: ChatType) => {
    navigate(
      `${indexPathByRole(role)}/messeges/chat?chatId=${JSON.stringify(item.chatId)}`,
      {
        state: {
          participants: participants,
          chat: item,
        },
      },
    )
  }

  const updateMarkService = async (serviceId: string, isMarked: boolean) => {
    if (isMarked) {
      await ServiceHttp.deleteMark({
        serviceId,
        marckedUserId: _id,
      })
    } else {
      await ServiceHttp.addMark({
        serviceId,
        marckedUserId: _id,
      })
      setIsOpenSave(true)
    }

    if (services) {
      let array: PublishServiceItemInterface[] = []
      services.map((item) => {
        if (item._id === serviceId) {
          array.push({
            ...item,
            isMarked: !item?.isMarked,
          })
        } else {
          array.push(item)
        }
      })
      setServices(array)
    }
  }

  const updateNotificationEvent = async (serviceId: string) => {
    if (activitiesList) {
      await ActivitiesHttp.updateNotification({
        eventId: serviceId,
        userId: _id,
      })

      let arr: PublishEventItemInterface[] = []
      activitiesList.map((item) => {
        if (item._id === serviceId) {
          arr.push({ ...item, isNotificatedEvent: !item.isNotificatedEvent })
        } else {
          arr.push(item)
        }
      })
      setActivitiesList(arr)
    }
  }

  const updateLikeEvent = async (likeId: string, serviceId: string) => {
    await FeedBackHttp.updateLike({
      likeId,
      userId: _id,
    })
    let arr: PublishEventItemInterface[] = []
    activitiesList.map((item) => {
      if (item._id === serviceId) {
        arr.push({
          ...item,
          isLiked: !item?.isLiked,
          countLikes: item.isLiked ? item?.countLikes - 1 : item?.countLikes + 1,
        })
      } else {
        arr.push(item)
      }
    })
    setActivitiesList(arr)
  }
  const updateMarkEvent = async (eventId: string, isMarked: boolean) => {
    if (isMarked) {
      await ActivitiesHttp.deleteMark({
        eventId,
        marckedUserId: _id,
      })
    } else {
      await ActivitiesHttp.addMark({
        eventId,
        marckedUserId: _id,
      })
      setIsOpenSave(true)
    }
    let arr: PublishEventItemInterface[] = []
    activitiesList.map((item) => {
      if (item._id === eventId) {
        arr.push({
          ...item,
          isMarked: !item?.isMarked,
        })
      } else {
        arr.push(item)
      }
    })
    setActivitiesList(arr)
  }

  const updateNotification = async (
    postId: string,
    typeNotification: NOTIFICATION_POST,
  ) => {
    await PublishPostHttp.updateNotification({
      postId: postId,
      userId: _id,
      typeNotification,
    })

    setPosts((s) =>
      s.map((p) => {
        if (p._id === postId) {
          const newItem = {
            ...p,
            [NOTIFICATION_POST.COMMENT === typeNotification
              ? 'isNotificatedComment'
              : 'isNotificatedPost']:
              NOTIFICATION_POST.COMMENT === typeNotification
                ? !p.isNotificatedComment
                : !p.isNotificatedPost,
          }
          setCurrentItem(newItem)
          return newItem
        } else {
          return p
        }
      }),
    )
  }

  const toPublishService = (_id: string) => {
    navigate(`/user/service/publish-service?publishServiceId=${_id}`)
    saveSearchService(_id)
  }

  const copyLink = async () => {
    const text = window.location.href
    navigate(`${indexPathByRole(role)}/service/share-list`, { state: { text } })
  }

  const openPosts = () => {
    setViewAllPosts(viewAllPosts + 10)
  }
  const openServices = () => {
    setViewAllServices(viewAllServices + 10)
  }
  const openEvents = () => {
    setViewAllEvents(viewAllEvents + 10)
  }
  const openUsers = () => {
    setViewAllUsers(viewAllUsers + 10)
  }

  const updatePin = async (repostId: string) => {
    await PublishPostHttp.updatePin({
      repostId: repostId,
      userId: _id,
    })

    setPosts((s) =>
      s.map((p) => {
        if (p.repostId === repostId) {
          const newItem = {
            ...p,
            isPined: !p.isPined,
          }
          setCurrentItem(newItem)
          return newItem
        } else {
          return p
        }
      }),
    )
    getPinPost(_id)
  }

  const hidePost = async (body: { hideUserId?: string; hideRepostId?: string }) => {
    await PublishPostHttp.hidePost({
      ownerId: _id,
      ...body,
    })

    setPosts((s) =>
      s.filter(
        (item) =>
          !(
            item?.repostId === body?.hideRepostId || item?.userId._id === body?.hideUserId
          ),
      ),
    )

    setNeibModalOpen(false)
  }
  const [content, setContent] = useState<{ postId: string; content: boolean }[]>([])
  const updateContent = (item: PostUserInterface, val: boolean) => {
    setContent([...content, { postId: item._id, content: val }])
  }

  const updateLike = async (likeId: string, postId: string) => {
    await FeedBackHttp.updateLike({
      likeId,
      userId: _id,
    })
    setPosts((s) =>
      s.map((p) => {
        if (p._id === postId) {
          return {
            ...p,
            isLiked: !p.isLiked,
            likes: p.isLiked ? p.likes - 1 : p.likes + 1,
          }
        } else {
          return p
        }
      }),
    )
  }

  const getPinPost = async (userId: string) => {
    const pinedList = await PublishPostHttp.getPostPin({ userId })

    if (pinedList.length) {
      const pinedPostList = await PublishPostHttp.getPosts({
        pageNumber: 0,
        userId,
        listPinedPost: pinedList.map((item) => item.repostId),
      })

      setPosts((s) => [...pinedPostList.posts, ...s])
      return
    }

    setPosts((s) => s.filter((item) => !item.isPinedPostFlag))
  }

  useEffect(() => {
    if (_id) getPinPost(_id)
  }, [])

  const toPublishActivities = (_id: string) => {
    navigate(`/user/activities/publish-activities-item?publishActivitiesId=${_id}`)
    saveSearchEvent(_id)
  }

  const updateRepost = async ({
    postId,
    isReposted,
  }: {
    postId: string
    isReposted: boolean
  }) => {
    await PublishPostHttp.updateRepost({
      postId,
      repostedUserId: _id,
    })
  }
  const deletePost = (item: PostUserInterface) => {
    if (posts) {
      const arr = posts.filter((it) => it !== item)
      setPosts(arr)
    }
  }
  const updateMark = async (postId: string, isMarked: boolean) => {
    if (isMarked) {
      await PublishPostHttp.deleteMark({
        postId,
        marckedUserId: _id,
      })
    } else {
      await PublishPostHttp.addMark({
        postId,
        marckedUserId: _id,
      })
    }

    setPosts((s) =>
      s.map((p) => {
        if (p._id === postId) {
          const newItem = {
            ...p,
            isMarked: !p.isMarked,
          }
          setCurrentItem(newItem)
          return newItem
        } else {
          return p
        }
      }),
    )
  }
  const openChatUser = async (adminId: string) => {
    await $api
      .post('messenger/open-chat', {
        participants: [
          {
            userId: adminId,
          },
          {
            userId: _id,
          },
        ],
        isSupport: true,
      })
      .then((res) => {
        console.log(res)
        if (res.data) {
          navigate(`${indexPathByRole(role)}/messeges/chat`, {
            state: {
              chat: res.data,
            },
          })
        }
      })
  }

  const openChatAdmin = async (roleSuppors: ROLE) => {
    const res = await UserHttp.getClosestUser({
      role: roleSuppors,
      myLat: coordinates.lat,
      myLng: coordinates.lng,
    })
    openChatUser(res.userId)
    // navigate(`${indexPathByRole(role)}/messeges/chat`, {
    //     state: {
    //         isSupport: true,
    //         participants: [res],
    //     },
    // })
  }

  const toFind = (category: string) => {
    navigate('find', { state: { category } })
  }

  const toSearch = (val: string) => {
    return val.toLowerCase().replaceAll(' ', '')
  }

  const calculateDateBirth = (userIdentity: UserIdentityInterface) => {
    let birth = new Date(userIdentity?.dateBirth || new Date())
    let today = new Date()
    return today.getMonth() < birth.getMonth() ||
      (today.getMonth() == birth.getMonth() && today.getDate() < birth.getDate())
      ? new Date()?.getFullYear() -
          new Date(userIdentity?.dateBirth || new Date())?.getFullYear() -
          1
      : new Date()?.getFullYear() -
          new Date(userIdentity?.dateBirth || new Date())?.getFullYear()
  }

  return (
    <>
      <UserHeader>
        <div className="user__header-messages">
          <div className="explore__search__wrapper">
            <div className="explore-search" style={{ margin: '25px auto' }}>
              <InputSearch
                placeholder={
                  <>
                    Search <b>NeighborHarbor</b>
                  </>
                }
                value={search}
                changeValue={setSearch}
              />
            </div>
            {search.replaceAll(' ', '').length ? (
              <button onClick={() => setSearch('')} className="explore__cancel">
                cancel
              </button>
            ) : (
              ''
            )}
          </div>
        </div>
      </UserHeader>
      <div className="search__main">
        {search.length ? (
          <>
            {
              <>
                {users.filter((it) => toSearch(it.fullName).includes(toSearch(search)))
                  .length ? (
                  <div className="messenger__alluser">
                    {search && (
                      <h2>
                        Neighbors(
                        {
                          users.filter((it) =>
                            toSearch(it.fullName).includes(toSearch(search)),
                          ).length
                        }
                        )
                      </h2>
                    )}
                    {users
                      .filter((it) => toSearch(it.fullName).includes(toSearch(search)))
                      .slice(0, viewAllUsers)
                      .map((item) => (
                        <>
                          {item._id !== _id && (
                            <div
                              className="user__chat-groups-item"
                              style={{ margin: '15px 0', borderRadius: '12px' }}
                              onClick={() =>
                                toProfileInfo({
                                  _id: item?._id || '',
                                  email: '',
                                  role: ROLE.USER,
                                  fullName: item?.fullName || '',
                                  avatarFileName: item?.avatarFileName || '',
                                })
                              }
                            >
                              <div className="user__chat-groups-item-info">
                                <div className="user__chat-groups-item-info-g">
                                  {item?.avatarFileName ? (
                                    <img
                                      src={`${apiBaseURL}/uploads/avatar/${item?.avatarFileName}`}
                                    />
                                  ) : (
                                    <div
                                      style={{
                                        backgroundColor: 'rgba(41, 52, 65, 1)',
                                        borderRadius: '100%',
                                        width: '40px',
                                        height: '40px',
                                        color: '#fff',
                                        textAlign: 'center',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <span style={{ margin: 'auto', fontSize: '14px' }}>
                                        {item?.fullName.slice(0, 2)}
                                      </span>
                                    </div>
                                  )}
                                  <div className="user__chat-groups-item-info-text">
                                    <h5 style={{ fontSize: '16px' }}>
                                      {item.fullName}
                                      {item.identity?.dateBirth
                                        ? `, ${calculateDateBirth(item.identity)}`
                                        : ''}
                                    </h5>
                                    <h6 style={{ fontSize: '14px' }}>
                                      {item.identity?.professions[0]?.title}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                              {item.identity?.interests && (
                                <div className="user__chat-groups-item-interests profile__identity-list-body">
                                  {item.identity?.interests
                                    ?.slice(0, 7)
                                    .map((item, index) => (
                                      <div
                                        className={`user__chat-groups-item-interests-item 
                                        ${
                                          value.find((it) => it.title === item.title)
                                            ? 'user__chat-groups-item-interests-item-active'
                                            : ''
                                        }  
                                    `}
                                        key={index}
                                      >
                                        <div className="profile__identity-list-item-text">
                                          {item.title}
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              )}
                            </div>
                          )}
                        </>
                      ))}
                  </div>
                ) : (
                  ''
                )}
              </>
            }
            {users.filter((it) => toSearch(it.fullName).includes(toSearch(search)))
              .length >= viewAllUsers &&
            users.filter((it) => toSearch(it.fullName).includes(toSearch(search)))
              .length ? (
              <button
                style={{ width: '100%' }}
                className="user__services-last-button user__services-last-button"
                onClick={openUsers}
              >
                View all
              </button>
            ) : (
              ''
            )}
            {
              <>
                {services.filter((it) => toSearch(it.title).includes(toSearch(search)))
                  .length ? (
                  <div className="messenger__alluser">
                    {search && (
                      <h2>
                        Services(
                        {
                          services.filter((it) =>
                            toSearch(it.title).includes(toSearch(search)),
                          ).length
                        }
                        )
                      </h2>
                    )}
                    {services
                      .filter((it) => toSearch(it.title).includes(toSearch(search)))
                      .slice(0, viewAllServices)
                      .map((item) => (
                        <div
                          className="user__services-last-item"
                          onClick={() => toPublishService(item._id)}
                        >
                          <div className="user__services-last-item-img">
                            <div className="user__services-last-item-img-1 user__services-last-item-img-info">
                              <div className="user__services-last-item-img-text">
                                {item.categoryId.name}
                              </div>
                            </div>
                            {item.filesName.length ? (
                              <img
                                src={`${apiBaseURL}/uploads/publish_services/${item.filesName[0]}`}
                                alt=""
                              />
                            ) : (
                              <img
                                src={'/Images/postsBG.png'}
                                alt=""
                                className="posts__bg"
                              />
                            )}
                            {item.reviews.length ? (
                              <div className="user__services-last-item-img-2 user__services-last-item-img-info">
                                <div className="user__services-last-item-img-text">
                                  <IconStars />
                                  <b>{calculateRate(item)}</b>
                                </div>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className="user__services-last-item-info">
                            <div className="user__services-last-item-row1">
                              <h5 className="user__services-last-item-title">
                                {item.title}
                              </h5>
                              <button
                                className={`${
                                  item.isMarked && 'admin__posts-list-row4-repost--active'
                                }`}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  updateMarkService(item._id, item?.isMarked)
                                }}
                              >
                                {item.isMarked ? (
                                  <IconProfileInfoBookmarkCopy fill="#3361FF" />
                                ) : (
                                  <IconProfileInfoBookmark />
                                )}
                              </button>
                            </div>
                            <h6 className="user__services-last-item-text">{item.text}</h6>
                            <div className="user__services-last-item-foot">
                              {calculateDistance(coordinates, item.coordinates) >= 1000
                                ? Math.floor(
                                    calculateDistance(coordinates, item.coordinates) /
                                      1000,
                                  ) + 'km'
                                : calculateDistance(coordinates, item.coordinates) +
                                  'm'}{' '}
                              <IconArrowRight />
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                ) : (
                  ''
                )}
              </>
            }
            {services.filter((it) => toSearch(it.title).includes(toSearch(search)))
              .length >= viewAllServices &&
            services.filter((it) => toSearch(it.title).includes(toSearch(search)))
              .length ? (
              <button
                className="user__services-last-button user__services-last-button"
                onClick={openServices}
                style={{ width: '100%' }}
              >
                View all
              </button>
            ) : (
              ''
            )}
            {
              <>
                {activitiesList.filter((it) =>
                  toSearch(it.title).includes(toSearch(search)),
                ).length ? (
                  <div className="messenger__alluser">
                    {search && (
                      <h2>
                        Events(
                        {
                          activitiesList.filter((it) =>
                            toSearch(it.title).includes(toSearch(search)),
                          ).length
                        }
                        )
                      </h2>
                    )}
                    {activitiesList
                      .filter((it) => toSearch(it.title).includes(toSearch(search)))
                      .slice(0, viewAllEvents)
                      .map((item) => (
                        <div
                          className="user__event-item"
                          onClick={() => toPublishActivities(item._id)}
                        >
                          <div className="user__event-item-row1">
                            <div className="user__event-item-row1-text">
                              <h5>{item.title}</h5>
                              <h6>{item.addressLocation}</h6>
                            </div>
                          </div>
                          <div className="explore__event-item-row2" onClick={() => {}}>
                            {item.filesName.length ? (
                              item?.filesName?.map((it) => (
                                <>
                                  <div
                                    style={{ position: 'relative' }}
                                    className="explore__event-item-row2-img"
                                    onClick={() => {}}
                                  >
                                    <div
                                      className="explore__event-item-row2-img-last"
                                      style={{ zIndex: '1' }}
                                    >
                                      <div className="explore__event-item-row2-img-last-text">
                                        {moment(item?.startDate).format('DD/MM/YYYY')}
                                      </div>
                                    </div>
                                    <div
                                      className="user__event-item-row2"
                                      onClick={(e) => {
                                        e.stopPropagation()
                                        openModalEvent(item)
                                      }}
                                    >
                                      <IconServicesAllPoint />
                                    </div>
                                    <img
                                      src={`${apiBaseURL}/uploads/publish_activities/${it}`}
                                      alt=""
                                    />
                                    <div className="explore__event-item-row2-img-last-bottom">
                                      <div className="explore__event-item-row2-img-last-bottom-text">
                                        <span
                                          className="admin__posts-list-row1-text"
                                          style={{
                                            color: 'rgba(21, 16, 77, 1)',
                                            zIndex: '1',
                                          }}
                                        >
                                          {item.activitiesId.name}
                                        </span>{' '}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ))
                            ) : (
                              <div
                                style={{ position: 'relative' }}
                                className="explore__event-item-row2-img"
                                onClick={() => toPublishActivities(item._id)}
                              >
                                <div
                                  className="explore__event-item-row2-img-last"
                                  style={{ zIndex: '1' }}
                                >
                                  <div className="explore__event-item-row2-img-last-text">
                                    {moment(item?.startDate).format('DD/MM/YYYY')}
                                  </div>
                                </div>
                                <div
                                  className="user__event-item-row2"
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    openModalEvent(item)
                                  }}
                                >
                                  <IconServicesAllPoint />
                                </div>
                                <img
                                  className="posts__bg"
                                  src={`/Images/postsBG.png`}
                                  alt=""
                                />
                                <div className="explore__event-item-row2-img-last-bottom">
                                  <div className="explore__event-item-row2-img-last-bottom-text">
                                    <span
                                      className="admin__posts-list-row1-text"
                                      style={{
                                        color: 'rgba(21, 16, 77, 1)',
                                        zIndex: '1',
                                      }}
                                    >
                                      {item.activitiesId.name}
                                    </span>{' '}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="user__event-item-row3">
                            <h6>{item.text}</h6>
                            {/* <div className="user__event-item-row3-button">
                                 {item.startDate.toString().split(".")[0].split("T")[0].replaceAll("-", "/") + " " }{ <b>{item.startDate.toString().split(".")[0].split("T")[1].split(":")[0] + ":" + item.startDate.toString().split(".")[0].split("T")[1].split(":")[1]}</b> }
                             </div> */}
                          </div>
                          <div className="user__event-item-row4">
                            <PublicationEventPanel
                              item={item}
                              updateLike={() => updateLikeEvent(item.likes, item._id)}
                              navigateToComments={() => {}}
                              updateMark={() => updateMarkEvent(item._id, item.isMarked)}
                              updateRepost={copyLink}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                ) : (
                  ''
                )}
              </>
            }
            {activitiesList.filter((it) => toSearch(it.title).includes(toSearch(search)))
              .length >= viewAllEvents &&
            activitiesList.filter((it) => toSearch(it.title).includes(toSearch(search)))
              .length ? (
              <button
                className="user__services-last-button user__services-last-button"
                onClick={openEvents}
                style={{ width: '100%' }}
              >
                View all
              </button>
            ) : (
              ''
            )}
            {
              <>
                {posts.filter((it) => toSearch(it.title).includes(toSearch(search)))
                  .length ? (
                  <div className="messenger__alluser">
                    {search && (
                      <h2>
                        Posts(
                        {
                          posts.filter((it) =>
                            toSearch(it.title).includes(toSearch(search)),
                          ).length
                        }
                        )
                      </h2>
                    )}
                    {posts
                      .filter((it) => toSearch(it.title).includes(toSearch(search)))
                      .slice(0, viewAllPosts)
                      .map((item) => (
                        <>
                          {item.repostedUserId &&
                          item?.shareList?.find(
                            (it) =>
                              it.friendId._id === _id || item.repostedUserId?._id === _id,
                          ) ? (
                            <div className="admin__posts-list-item" key={item?._id}>
                              <div className="admin__posts-list-repost">
                                <div className="admin__posts-list-repost-profileInfo">
                                  <div
                                    className="admin__posts-list-row1-img"
                                    onClick={() =>
                                      toProfileInfo({
                                        _id: item.repostedUserId?._id || '',
                                        email: '',
                                        role: ROLE.USER,
                                        fullName: item.repostedUserId?.fullName || '',
                                        avatarFileName:
                                          item?.repostedUserId?.avatarFileName || '',
                                      })
                                    }
                                  >
                                    {item.repostedUserId.avatarFileName ? (
                                      <img
                                        src={`${apiBaseURL}/uploads/avatar/${item.repostedUserId.avatarFileName}`}
                                      />
                                    ) : (
                                      <div
                                        style={{
                                          backgroundColor: 'rgba(41, 52, 65, 1)',
                                          borderRadius: '100%',
                                          width: '40px',
                                          height: '40px',
                                          color: '#fff',
                                          textAlign: 'center',
                                          position: 'relative',
                                          margin: '5px 0',
                                        }}
                                      >
                                        <span
                                          style={{
                                            position: 'absolute',
                                            left: '10px',
                                            top: '8px',
                                          }}
                                        >
                                          {item.repostedUserId.fullName.slice(0, 2)}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                  <div onClick={() => navigateToComments(item._id)}>
                                    <div className="admin__posts-list-row1-name">
                                      {item.repostedUserId?.fullName}
                                    </div>
                                    <div>
                                      <span className="admin__posts-list-row1-text">
                                        {moment(item?.createdRepostDate).format(
                                          'DD MMM YYYY HH:mm',
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <button onClick={() => openModal(item)}>
                                  <IconServicesAllPoint />
                                </button>
                              </div>
                              <div className="reposted">
                                <div
                                  className="admin__posts-list-row1"
                                  style={{ padding: '10px' }}
                                >
                                  <div
                                    className="admin__posts-list-row1-img"
                                    onClick={() =>
                                      toProfileInfo({
                                        _id: item.userId?._id,
                                        email: '',
                                        role: ROLE.USER,
                                        fullName: item.userId?.fullName,
                                        avatarFileName:
                                          item?.userId?.avatarFileName || '',
                                      })
                                    }
                                  >
                                    {item.userId.avatarFileName ? (
                                      <img
                                        src={`${apiBaseURL}/uploads/avatar/${item.userId.avatarFileName}`}
                                      />
                                    ) : (
                                      <div
                                        style={{
                                          backgroundColor: 'rgba(41, 52, 65, 1)',
                                          borderRadius: '100%',
                                          width: '40px',
                                          height: '40px',
                                          color: '#fff',
                                          textAlign: 'center',
                                          position: 'relative',
                                          margin: '5px 0',
                                        }}
                                      >
                                        <span
                                          style={{
                                            position: 'absolute',
                                            left: '10px',
                                            top: '8px',
                                          }}
                                        >
                                          {item.userId.fullName.slice(0, 2)}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                  <div onClick={() => navigateToComments(item._id)}>
                                    <div className="admin__posts-list-row1-name">
                                      {item.userId?.fullName}
                                      {item.isPinedPostFlag && (
                                        <span className="admin__posts-list-row1-pin">
                                          <IconPostModalPin />
                                        </span>
                                      )}
                                    </div>
                                    <div>
                                      <span className="admin__posts-list-row1-text">
                                        {moment(item?.createdPostDate).format(
                                          'DD MMM YYYY HH:mm',
                                        )}
                                      </span>{' '}
                                      <span className="admin__posts-list-row1-text admin__posts-list-row1-textunder">
                                        {item?.addressLocation}
                                      </span>
                                    </div>
                                  </div>
                                  <button onClick={() => openModal(item)}>
                                    <IconServicesAllPoint />
                                  </button>
                                </div>
                                {item.filesName.length > 0 ? (
                                  <div
                                    className="admin__posts-list-row2"
                                    style={{ padding: '10px' }}
                                    onClick={() => navigateToComments(item._id)}
                                  >
                                    <PostSlick list={item?.filesName}>
                                      {item?.filesName?.map((it) => (
                                        <div
                                          className="admin__posts-list-row2-img"
                                          onClick={() => navigateToComments(item._id)}
                                        >
                                          <img
                                            src={`${apiBaseURL}/uploads/publish_post/${it}`}
                                            alt=""
                                          />
                                        </div>
                                      ))}
                                    </PostSlick>
                                  </div>
                                ) : (
                                  ''
                                )}
                                <div
                                  style={{ padding: '10px' }}
                                  className="admin__posts-list-row3"
                                  onClick={() => navigateToComments(item._id)}
                                >
                                  <h5>{item.title}</h5>
                                  <h6>{item.text}</h6>
                                </div>
                              </div>
                              <PublicationPostsPanel
                                item={item}
                                updateLike={updateLike}
                                navigateToComments={navigateToComments}
                                updateMark={updateMark}
                                updateRepost={updateRepost}
                              />
                            </div>
                          ) : (
                            <>
                              <>
                                <div className="admin__posts-list-item" key={item?._id}>
                                  <div
                                    className="admin__posts-list-row1"
                                    style={{ padding: '10px' }}
                                  >
                                    <div
                                      className="admin__posts-list-row1-img"
                                      onClick={() =>
                                        toProfileInfo({
                                          _id: item.userId?._id,
                                          email: '',
                                          role: ROLE.USER,
                                          fullName: item.userId?.fullName,
                                          avatarFileName:
                                            item?.userId?.avatarFileName || '',
                                        })
                                      }
                                    >
                                      {item.userId.avatarFileName ? (
                                        <img
                                          src={`${apiBaseURL}/uploads/avatar/${item.userId.avatarFileName}`}
                                        />
                                      ) : (
                                        <div
                                          style={{
                                            backgroundColor: 'rgba(41, 52, 65, 1)',
                                            borderRadius: '100%',
                                            width: '40px',
                                            height: '40px',
                                            color: '#fff',
                                            textAlign: 'center',
                                            position: 'relative',
                                            margin: '5px 0',
                                          }}
                                        >
                                          <span
                                            style={{
                                              position: 'absolute',
                                              left: '10px',
                                              top: '8px',
                                            }}
                                          >
                                            {item.userId.fullName.slice(0, 2)}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                    <div onClick={() => navigateToComments(item._id)}>
                                      <div className="admin__posts-list-row1-name">
                                        {item.userId?.fullName}
                                        {item.isPinedPostFlag && (
                                          <span className="admin__posts-list-row1-pin">
                                            <IconPostModalPin />
                                          </span>
                                        )}
                                      </div>
                                      <div>
                                        <span className="admin__posts-list-row1-text">
                                          {moment(item?.createdPostDate).format(
                                            'DD MMM YYYY HH:mm',
                                          )}
                                        </span>{' '}
                                        <span className="admin__posts-list-row1-text admin__posts-list-row1-textunder">
                                          {item?.addressLocation}
                                        </span>
                                      </div>
                                    </div>
                                    <button onClick={() => openModal(item)}>
                                      <IconServicesAllPoint />
                                    </button>
                                  </div>
                                  {item.filesName.length > 0 ? (
                                    <div
                                      className="admin__posts-list-row2"
                                      style={{ padding: '10px' }}
                                      onClick={() => navigateToComments(item._id)}
                                    >
                                      <PostSlick list={item?.filesName}>
                                        {item?.filesName?.map((it) => (
                                          <div
                                            className="admin__posts-list-row2-img"
                                            onClick={() => navigateToComments(item._id)}
                                          >
                                            <img
                                              src={`${apiBaseURL}/uploads/publish_post/${it}`}
                                              alt=""
                                            />
                                          </div>
                                        ))}
                                      </PostSlick>
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                  <div
                                    style={{ padding: '10px' }}
                                    className="admin__posts-list-row3"
                                    onClick={() => navigateToComments(item._id)}
                                  >
                                    <h5>{item.title}</h5>
                                    <h6>{item.text}</h6>
                                  </div>
                                  <PublicationPostsPanel
                                    item={item}
                                    updateLike={updateLike}
                                    navigateToComments={navigateToComments}
                                    updateMark={updateMark}
                                    updateRepost={updateRepost}
                                  />
                                </div>
                              </>
                            </>
                          )}
                        </>
                      ))}
                  </div>
                ) : (
                  ''
                )}
              </>
            }
            {posts.filter((it) => toSearch(it.title).includes(toSearch(search))).length >=
              viewAllPosts &&
            posts.filter((it) => toSearch(it.title).includes(toSearch(search))).length ? (
              <button
                className="user__services-last-button user__services-last-button"
                onClick={openPosts}
                style={{ width: '100%' }}
              >
                View all
              </button>
            ) : (
              ''
            )}
          </>
        ) : (
          ''
        )}
        <>
          {chats.length ? <h5 className="search__main-title">Chats</h5> : ''}
          <div className="search__main-chats">
            <SlickCategories
              centerMode={false}
              show={chats.length < 5 ? chats.length : 5}
              scroll={1}
            >
              {chats.map((item) => (
                <div
                  className="search__main-chats-item"
                  onClick={() => openChat(item?.participants, item)}
                >
                  {item?.participants[0]?.userId?.avatarFileName ? (
                    <img
                      style={{ margin: '5px 0' }}
                      src={`${apiBaseURL}/uploads/avatar/${item?.participants[0]?.userId?.avatarFileName}`}
                    />
                  ) : (
                    <div
                      style={{
                        backgroundColor: 'rgba(41, 52, 65, 1)',
                        borderRadius: '100%',
                        width: '40px',
                        height: '40px',
                        color: '#fff',
                        textAlign: 'center',
                        position: 'relative',
                        margin: '5px 0',
                      }}
                    >
                      <span
                        style={{
                          position: 'absolute',
                          top: '25%',
                          left: '28%',
                          color: '#fff',
                          fontSize: '14px',
                        }}
                      >
                        {item?.participants[0]?.userId?.fullName.slice(0, 2)}
                      </span>
                    </div>
                  )}
                  <h6>{item?.participants[0]?.userId?.fullName}</h6>
                </div>
              ))}
            </SlickCategories>
          </div>
          <h5 className="search__main-title">Shortcuts</h5>
          <div className="search__main-shortcuts">
            <div
              className="search__main-shortcuts-item"
              onClick={() => navigate(`/profileinfo/bookmark`)}
            >
              <div className="search__main-shortcuts-item-img">
                <IconBook />
              </div>
              <h6>Bookmarks</h6>
            </div>
            <div
              className="search__main-shortcuts-item"
              onClick={() => navigate(`saved-search`)}
            >
              <div className="search__main-shortcuts-item-img">
                <img src="/Images/SearchMini.png" alt="" />
              </div>
              <h6>Saved Search</h6>
            </div>
            <div
              className="search__main-shortcuts-item"
              onClick={() => toFind('neighbors')}
            >
              <div className="search__main-shortcuts-item-img">
                <img src="/Images/Detective.png" alt="" />
              </div>
              <h6>Neighbors</h6>
            </div>
            <div
              className="search__main-shortcuts-item"
              onClick={() => toFind('services')}
            >
              <div className="search__main-shortcuts-item-img">
                <img src="/Images/Wrench.png" alt="" />
              </div>
              <h6>Services</h6>
            </div>
            <div
              className="search__main-shortcuts-item"
              onClick={() => toFind('activities')}
            >
              <div className="search__main-shortcuts-item-img">
                <img src="/Images/Party.png" alt="" />
              </div>
              <h6>Activities</h6>
            </div>
            <div className="search__main-shortcuts-item" onClick={() => toFind('posts')}>
              <div className="search__main-shortcuts-item-img">
                <img src="/Images/Pan.png" alt="" />
              </div>
              <h6>Posts</h6>
            </div>
          </div>
          <h5 className="search__main-title">Useful Links</h5>
          <div className="search__main-link" onClick={() => setIsOpen(!isOpen)}>
            <div className="search__main-link-text">
              <IconNeibsCopy fill="rgba(195, 202, 217, 1)" />
              <h6>Help & Support</h6>
            </div>
            {!isOpen ? <IconRightChevrons /> : <IconBottomChevrons fill="#C3CAD9" />}
          </div>
          {isOpen && (
            <div className="search__main-link-list">
              <h5 onClick={() => openChatAdmin(ROLE.LOCAL_ADMIN)}>Local coordinator</h5>
              <h5 onClick={() => openChatAdmin(ROLE.REGIONAL_ADMIN)}>
                Regional admin (advertisement)
              </h5>
              <h5 onClick={() => openChatAdmin(ROLE.ADMIN)}>Head office</h5>
            </div>
          )}
          <div
            className="search__main-link"
            onClick={() => setIsOpenSettings(!isOpenSettings)}
          >
            <div className="search__main-link-text">
              <IconNeibsCopy fill="rgba(195, 202, 217, 1)" />
              <h6>Settings & Privacy</h6>
            </div>
            {!isOpenSettings ? (
              <IconRightChevrons />
            ) : (
              <IconBottomChevrons fill="#C3CAD9" />
            )}
          </div>
          {isOpenSettings && (
            <div className="search__main-link-list">
              <h5
                onClick={(e) => {
                  e.stopPropagation()
                  navigate(`/profileinfo/settings`)
                }}
              >
                Settings
              </h5>
              <h5
                onClick={(e) => {
                  e.stopPropagation()
                  navigate(`/profileinfo/privacy`)
                }}
              >
                Privacy
              </h5>
            </div>
          )}
          <div className="search__main-link" onClick={() => setIsOpenAbout(!isOpenAbout)}>
            <div className="search__main-link-text">
              <IconNeibsCopy fill="rgba(195, 202, 217, 1)" />
              <h6>About</h6>
            </div>
            {!isOpenAbout ? <IconRightChevrons /> : <IconBottomChevrons fill="#C3CAD9" />}
          </div>
          {isOpenAbout && (
            <div className="search__main-link-list">
              <h5
                onClick={(e) => {
                  e.stopPropagation()
                  navigate(`/profileinfo/termsofservice`)
                }}
              >
                Terms of Service
              </h5>
              <h5
                onClick={(e) => {
                  e.stopPropagation()
                  navigate(`/profileinfo/privacypolicy`)
                }}
              >
                Privacy Policy
              </h5>
            </div>
          )}
          <div className="search__main-btns">
            <div onClick={() => dispatch(logout())} className="search__main-btns-logout">
              Log Out
            </div>
          </div>
        </>
      </div>
      {load && <Loader />}
      {isOpenSave && (
        <TempNotification setIsOpen={setIsOpenSave} isOpen={isOpenSave}>
          <IconBookMark />
        </TempNotification>
      )}
      {(isOpenModal || myModalOpen || neibModalOpen) && <DarkBg />}
      {isOpenModal && currentItemEvent?.userId._id === _id && currentItemEvent && (
        <ActivitiesMyModal
          setIsOpen={setIsOpenModal}
          isOpen={isOpenModal}
          item={currentItemEvent}
          type="activity"
          updateNotification={() => updateNotificationEvent(currentItemEvent._id)}
        />
      )}
      {isOpenModal && currentItemEvent?.userId._id !== _id && currentItemEvent && (
        <ActivitiesNeibModalMany
          setIsOpen={setIsOpenModal}
          isOpen={isOpenModal}
          item={currentItemEvent}
          type="activity"
          updateNotification={() => updateNotificationEvent(currentItemEvent?._id)}
          setPublishService={setCurrentItemEvent}
        />
      )}
      {myModalOpen && (
        <PublicationPostMyModal
          deletePostList={deletePost}
          item={currnetItem}
          isOpen={myModalOpen}
          setIsOpen={setMyModalOpen}
          updateMark={updateMark}
          // getAllPostReload={()=>{}}
          updateNotification={updateNotification}
          updatePin={updatePin}
        />
      )}
      {neibModalOpen && (
        <PublicationPostNeibModal
          item={currnetItem}
          isOpen={neibModalOpen}
          setIsOpen={setNeibModalOpen}
          updateMark={updateMark}
          updateNotification={updateNotification}
          toProfileInfo={toProfileInfo}
          hidePost={hidePost}
          updateContent={updateContent}
          content={content}
        />
      )}
    </>
  )
}
