import { useState } from 'react'
import { Slider } from 'antd'
import { IconBoxActive, IconBox } from '../svg/IconBox'
import { Modal } from './Modal'

export const DrawModal = ({
  isOpen,
  setIsOpen,
  radius,
  setRadius,
  radiusSize,
  setRadiusSize,
  draw,
  setDraw,
  setPosUserCurrent,
  setPolygonCoords,
  setPolyline,
  map,
  zIndex,
  aply,
  fake,
}: {
  isOpen: boolean
  setIsOpen: (s: boolean) => void
  radius: boolean
  setRadius: (s: boolean) => void
  radiusSize: number
  setRadiusSize: (s: number) => void
  draw: boolean
  setDraw: (s: boolean) => void
  setPosUserCurrent?: (s: [number, number] | undefined) => void
  setPolygonCoords?: (s: []) => void
  setPolyline: (s: L.Polyline | undefined) => void
  map?: L.Map
  zIndex?: string
  aply?: () => void
  fake?: boolean
}) => {
  const validate = !Boolean(radius || draw)
  const [isOpenFake, setIsOpenFake] = useState(false)
  return (
    <div
      style={zIndex ? { zIndex } : {}}
      className={`activities__favor-modal  ${
        isOpen ? 'activities__favor-modal--open' : ''
      }`}
    >
      <Modal className="" setIsOpen={fake ? setIsOpenFake : setIsOpen}>
        <></>
        <div
          onClick={(e) => {
            e.stopPropagation()
            setIsOpen(false)
          }}
          className="activities__favor-modal-linebody"
        >
          <button className="activities__favor-modal-line" />
        </div>
        <div className="location__modal">
          <div
            className="location__modal-item"
            onClick={() => {
              setDraw(true)
              setRadius(false)
              if (setPosUserCurrent) setPosUserCurrent(undefined)
              setPolyline(undefined)
            }}
          >
            <div className="location__modal-item-text">
              <h5 className="location__modal-item-text-title">Draw on the map</h5>
              <h6 className="location__modal-item-text-sub">
                Show data only within the specified radius
              </h6>
            </div>
            <div className="location__modal-item-check">
              {draw ? <IconBoxActive /> : <IconBox />}
            </div>
          </div>
          <div className="location__modal-block" style={{ padding: '0 20px' }}>
            <div
              className="location__modal-item"
              style={{ padding: '0' }}
              onClick={() => {
                setDraw(false)
                setRadius(true)
                if (setPolygonCoords) setPolygonCoords([])
                setPolyline(undefined)
              }}
            >
              <div className="location__modal-item-text">
                <h5 className="location__modal-item-text-title">Set local radius</h5>
                <h6 className="location__modal-item-text-sub">
                  Show data only within the specified radius
                </h6>
              </div>
              <div className="location__modal-item-check">
                {radius ? <IconBoxActive /> : <IconBox />}
              </div>
            </div>
            <div className="location__modal-block-select">
              <div className="location__modal-block-select-radius">
                <div className="location__modal-block-select-radius__wrapper">
                  <input
                    type="range"
                    value={radiusSize}
                    step={1}
                    min={1}
                    max={20}
                    onChange={(e) => {
                      if (e.target.value) {
                        setRadiusSize(Number(e.target.value))
                      } else {
                        setRadiusSize(Number(e.target.value) + 1)
                      }
                      setRadius(true)
                      setDraw(false)
                    }}
                  />
                </div>
              </div>
              <span>{radiusSize} km</span>
              <button
                style={{ width: '90%', position: 'absolute', margin: '12% 0' }}
                className={`location__bt-continue login__button
                                ${validate && 'advertisement__add-main-btn-disabled'}`}
                disabled={validate}
                onClick={() => {
                  if (aply) {
                    aply()
                  } else {
                    setIsOpen(false)
                  }
                }}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}
