import { AxiosResponse } from 'axios'
import L, { LatLng, LatLngExpression } from 'leaflet'
import { useEffect, useState } from 'react'
import { MapContainer, Polygon, Polyline, TileLayer } from 'react-leaflet'
import { useNavigate } from 'react-router-dom'
import $api from '../../../http'
import { PAGES } from '../../../types/enum'
import { LocationDistanceType, LocationType } from '../../../types/types'
import { apiBaseURL, indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { inside } from '../../../utils/inside'
import { IconAddMarker } from '../../svg/IconAddMarker'
import { IconLeftChevrons } from '../../svg/IconChevrons'
import { IconLikeMap } from '../../svg/IconLikeMap'
import { IconMessege } from '../../svg/IconMessege'
import { IconNightMode } from '../../svg/IconNightMode'
import { IconNotification } from '../../svg/IconNotification'
import { IconSearchMap } from '../../svg/IconSearchMap'
import { DarkBg } from '../../ui/DarkBg'
import { Loader } from '../../ui/Loader'
import { AddressMarker } from './AddressMarker'
import CA from './CA.json'
import { ExploreHeader } from './ExploreHeader'
import { ExploreModalSearch } from './ExploreModalSearch'
import FR from './FR.json'
import UA from './UA.json'
import { calculateDistance } from '../../../utils/calculateRate'
import { ExploreFilterModal } from './ExploreFilterModal'

export const ExploreMapCountry = () => {
  const { role, avatarFileName, fullName } = useAppSelector((state) => state.user)
  const { coordinates, street, houseNumber, country } = useAppSelector(
    (state) => state.profile,
  )
  const navigate = useNavigate()
  const [search, setSearch] = useState('')

  const [UACoords, setUACoords] = useState<LatLngExpression[]>()
  const [CACoords, setCACoords] = useState<LatLngExpression[]>()
  const [FRCoords, setFRCoords] = useState<LatLngExpression[]>()

  const [polygonUA, setPolygonUA] = useState<L.Polygon | null>()
  const [polygonFR, setPolygonFR] = useState<L.Polygon | null>()
  const [polygonCA, setPolygonCA] = useState<L.Polygon | null>()
  const [polylineUA, setPolylineUA] = useState<L.Polyline | null>()
  const [polylineFR, setPolylineFR] = useState<L.Polyline | null>()
  const [polylineCA, setPolylineCA] = useState<L.Polyline | null>()

  const [map, setMap] = useState<L.Map | null>()
  const [Night, setNight] = useState<boolean>(true)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [MarkerMode, setMarkerMode] = useState<boolean>(false)
  const [myPos, setMyPos] = useState<GeolocationPosition>()
  const [focus, setFocus] = useState(false)
  const [isOpenSearch, setIsOpenSearch] = useState(false)
  const [isOpenFilter, setIsOpenFilter] = useState(false)
  const [load, setLoad] = useState<boolean>(false)
  const [filter, setFilter] = useState<string>(PAGES.MAIN)
  const [countryType, setCountry] = useState<L.Polygon | null>()
  const [type, setType] = useState('')
  const [MarksList, setMarksList] = useState<LocationType[]>([])

  const getRegionNearby = () => {
    let arr: LocationDistanceType[] = []
    if (myPos) {
      MarksList.map((item) => {
        arr.push({
          ...item,
          distance: calculateDistance(item.center, {
            lat: myPos?.coords.latitude,
            lng: myPos?.coords.longitude,
          }),
        })
      })
    }
    return arr.filter((it) => it.distance).slice(0, 2)
  }

  useEffect(() => {
    const body = async () => {
      // marks
      const resMarksList: AxiosResponse<LocationType[]> =
        await $api.post('map/list-marks')
      const marks: LocationType[] = []
      setMarksList(resMarksList.data)
    }
    body()
  }, [])

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((pos) => {
      setMyPos(pos)
    })
  }, [])

  useEffect(() => {
    let array: LatLng[] = []
    UA.features[0].geometry.coordinates[0].map((item) => {
      let latlng = new LatLng(item[1], item[0])
      array.push(latlng)
    })
    setUACoords(array)
  }, [])

  useEffect(() => {
    let array: LatLng[] = []
    FR.features[0].geometry.coordinates[1].map((item) => {
      item.map((reg) => {
        let latlng = new LatLng(reg[1], reg[0])
        array.push(latlng)
      })
    })
    setFRCoords(array)
  }, [])

  useEffect(() => {
    let array: LatLng[] = []
    CA.features[0].geometry.coordinates[0].map((item) => {
      item.map((reg) => {
        let latlng = new LatLng(reg[1], reg[0])
        array.push(latlng)
      })
    })
    setCACoords(array)
  }, [])

  useEffect(() => {
    if (
      myPos &&
      UACoords &&
      polygonUA &&
      map &&
      inside([myPos?.coords.latitude, myPos?.coords.longitude], UACoords)
    ) {
      map.setView(polygonUA.getCenter())
    }
  }, [map, polygonUA])
  useEffect(() => {
    if (
      myPos &&
      FRCoords &&
      polygonFR &&
      map &&
      inside([myPos?.coords.latitude, myPos?.coords.longitude], FRCoords)
    ) {
      map.setView(polygonFR.getCenter())
    }
  }, [map, polygonFR])
  useEffect(() => {
    if (
      myPos &&
      CACoords &&
      polygonCA &&
      map &&
      inside([myPos?.coords.latitude, myPos?.coords.longitude], CACoords)
    ) {
      map.setView(polygonCA.getCenter())
    }
  }, [map, polygonCA])

  useEffect(() => {
    if (polygonUA && polylineUA) {
      if (Night) {
        polygonUA.setStyle({ opacity: 0, color: '#fff' })
        polylineUA.setStyle({ color: '#fff' })
      } else {
        polygonUA.setStyle({ opacity: 0, color: 'blue' })
        polylineUA.setStyle({ color: 'blue' })
      }
    }
    if (polygonCA && polylineCA) {
      if (Night) {
        polygonCA.setStyle({ opacity: 0, color: '#fff' })
        polylineCA.setStyle({ color: '#fff' })
      } else {
        polygonCA.setStyle({ opacity: 0, color: 'blue' })
        polylineCA.setStyle({ color: 'blue' })
      }
    }
    if (polygonFR && polylineFR) {
      if (Night) {
        polygonFR.setStyle({ opacity: 0, color: '#fff' })
        polylineFR.setStyle({ color: '#fff' })
      } else {
        polygonFR.setStyle({ opacity: 0, color: 'blue' })
        polylineFR.setStyle({ color: 'blue' })
      }
    }
  }, [Night])

  return (
    <>
      {!load ? (
        <div className="explore__country">
          <ExploreHeader
            style={
              !Night
                ? {
                    background:
                      'linear-gradient(rgba(245, 246, 247, 1), rgba(245, 246, 247, 0))',
                  }
                : {
                    background:
                      'linear-gradient(rgba(15, 20, 25, 1), rgba(15, 20, 25, 0))',
                  }
            }
          >
            <div className="explore__main-header">
              <div className="explore__main-header-user" style={{ margin: 'auto 0' }}>
                <button onClick={() => navigate(-1)}>
                  {Night ? (
                    <IconLeftChevrons />
                  ) : (
                    <IconLeftChevrons fill="rgba(38, 38, 38, 1)" />
                  )}
                </button>
                {avatarFileName ? (
                  <img src={`${apiBaseURL}/uploads/avatar/${avatarFileName}`} />
                ) : (
                  <div
                    style={{
                      backgroundColor: 'rgba(41, 52, 65, 1)',
                      borderRadius: '100%',
                      width: '40px',
                      height: '40px',
                      color: '#fff',
                      textAlign: 'center',
                      position: 'relative',
                    }}
                  >
                    <span style={{ position: 'absolute', left: '10px', top: '8px' }}>
                      {fullName.slice(0, 2)}
                    </span>
                  </div>
                )}
                {!Night ? (
                  <div className="explore__main-header-user-location">
                    <h6 style={{ color: 'rgba(99, 106, 117, 1)' }}>current location</h6>
                    <h5 style={{ color: 'rgba(31, 32, 36, 1)' }}>
                      {houseNumber ? houseNumber + ',' : ''} {street ? street + ',' : ''}{' '}
                      {country}
                    </h5>
                  </div>
                ) : (
                  <div className="explore__main-header-user-location">
                    <h6>current location</h6>
                    <h5>
                      {houseNumber ? houseNumber + ',' : ''} {street ? street + ',' : ''}{' '}
                      {country}
                    </h5>
                  </div>
                )}
              </div>
              <div
                className={
                  !MarkerMode
                    ? 'admin__panel-addMarker explore__location__follow'
                    : 'admin__panel-addMarker explore__location__follow clicked'
                }
                onClick={() => {
                  setMarkerMode(!MarkerMode)
                  if (myPos)
                    map?.flyTo(
                      { lat: myPos?.coords.latitude, lng: myPos?.coords.longitude },
                      16,
                    )
                  setFocus(true)
                }}
              >
                <IconAddMarker />
              </div>
            </div>
          </ExploreHeader>
          <div className="explore__country-map">
            {myPos && (
              <MapContainer
                tap={false}
                zoomControl={false}
                center={{ lat: myPos?.coords.latitude, lng: myPos?.coords.longitude }}
                zoom={5}
                maxZoom={5}
                minZoom={4}
                scrollWheelZoom={true}
                ref={setMap}
              >
                <>
                  {!Night ? (
                    <>
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                        url="https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png"
                        subdomains="abcd"
                      />
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                        url="https://{s}.basemaps.cartocdn.com/dark_only_labels/{z}/{x}/{y}{r}.png"
                        subdomains="abcd"
                      />
                    </>
                  ) : (
                    <>
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                        url="https://{s}.basemaps.cartocdn.com/dark_nolabels/{z}/{x}/{y}{r}.png"
                      />
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                        url="https://{s}.basemaps.cartocdn.com/dark_only_labels/{z}/{x}/{y}{r}.png"
                        subdomains="abcd"
                      />
                    </>
                  )}
                </>

                {UACoords && (
                  <Polyline positions={UACoords} color="#fff" ref={setPolylineUA} />
                )}
                {UACoords && (
                  <Polygon
                    positions={UACoords}
                    opacity={0}
                    color="#fff"
                    ref={setPolygonUA}
                  />
                )}
                {polygonUA && map && (
                  <AddressMarker
                    type={'UA'}
                    coords={polygonUA.getCenter()}
                    text={'Ukraine'}
                  />
                )}

                {FRCoords && (
                  <Polyline positions={FRCoords} color="#fff" ref={setPolylineFR} />
                )}
                {FRCoords && (
                  <Polygon
                    positions={FRCoords}
                    opacity={0}
                    color="#fff"
                    ref={setPolygonFR}
                  />
                )}
                {polygonFR && map && (
                  <AddressMarker
                    type={'FR'}
                    coords={polygonFR.getCenter()}
                    text={'France'}
                  />
                )}

                {CACoords && (
                  <Polyline positions={CACoords} color="#fff" ref={setPolylineCA} />
                )}
                {CACoords && (
                  <Polygon
                    positions={CACoords}
                    opacity={0}
                    color="#fff"
                    ref={setPolygonCA}
                  />
                )}
                {polygonCA && map && (
                  <AddressMarker
                    type={'CA'}
                    coords={polygonCA.getCenter()}
                    text={'Canada'}
                  />
                )}
              </MapContainer>
            )}
            {!isOpen && !isOpenSearch && (
              <div className="map__menu" style={{ marginTop: '10%' }}>
                <div
                  style={
                    Night
                      ? { backgroundColor: 'rgba(41, 52, 65, 1)' }
                      : { backgroundColor: '#fff' }
                  }
                  className="map__menu-item"
                  onClick={() => {
                    setNight(!Night)
                  }}
                >
                  <IconNightMode fill={Night ? '#fff' : '#262626'} />
                </div>
                <div
                  style={
                    Night
                      ? { backgroundColor: 'rgba(41, 52, 65, 1)' }
                      : { backgroundColor: '#fff' }
                  }
                  className="map__menu-item"
                  onClick={() => setIsOpenSearch(true)}
                >
                  <IconSearchMap fill={Night ? '#fff' : '#262626'} />
                </div>
                <div
                  style={
                    Night
                      ? { backgroundColor: 'rgba(41, 52, 65, 1)', color: '#fff' }
                      : { backgroundColor: '#fff', color: '#262626' }
                  }
                  className="map__menu-item"
                >
                  {Night ? (
                    <b>
                      <span>
                        <IconMessege fill="#fff" />
                      </span>
                    </b>
                  ) : (
                    <b>
                      <span>
                        <IconMessege />
                      </span>
                    </b>
                  )}
                  <div className="blue"></div>
                </div>
                <div
                  style={
                    Night
                      ? { backgroundColor: 'rgba(41, 52, 65, 1)' }
                      : { backgroundColor: '#fff' }
                  }
                  className="map__menu-item"
                >
                  <IconLikeMap fill={Night ? '#fff' : '#262626'} />
                </div>
                <div
                  style={
                    Night
                      ? { backgroundColor: 'rgba(41, 52, 65, 1)' }
                      : { backgroundColor: '#fff' }
                  }
                  className="map__menu-item"
                  onClick={() => navigate(`${indexPathByRole(role)}/notification`)}
                >
                  <IconNotification fill={Night ? '#fff' : '#262626'} />
                </div>
              </div>
            )}
          </div>
          {isOpenSearch && map && (
            <ExploreModalSearch
              setIsOpen={setIsOpenSearch}
              isOpen={isOpenSearch}
              setIsOpenFilter={setIsOpenFilter}
              setLoad={setLoad}
              filter={filter}
              regions={getRegionNearby()}
              map={map}
            />
          )}
          {isOpenFilter && (
            <ExploreFilterModal
              isOpen={isOpenFilter}
              setIsOpen={setIsOpenFilter}
              setFilter={setFilter}
              filter={filter}
              setIsOpenSearch={setIsOpenSearch}
            />
          )}
          {(isOpenFilter || isOpenSearch) && <DarkBg />}
        </div>
      ) : (
        <Loader />
      )}
    </>
  )
}
