import { addListener } from '@reduxjs/toolkit'
import { useEffect, useState } from 'react'
import {
  attemptForgotPasswordClaimCode,
  forgotPasswordSendCode,
} from '../../../redux/auth'
import { METHOD_FORGET_PASSWORD } from '../../../types/enum'
import { useAppDispatch } from '../../../utils/hooks'
import { CodeInput } from '../../ui/CodeInput'

interface Props {
  email: string
  method: METHOD_FORGET_PASSWORD
}

const CheckCode = ({ email, method }: Props) => {
  const dispatch = useAppDispatch()
  const [code, setCode] = useState('')
  const [seconds, setSeconds] = useState(30)
  const [showResendButton, setShowResendButton] = useState(false)
  const [error, setError] = useState<string | undefined>()

  useEffect(() => {
    const removeRejectedListener = dispatch(
      addListener({
        actionCreator: attemptForgotPasswordClaimCode.rejected,
        effect: (action, listenerApi) => {
          switch (action.error.message) {
            case 'CODE_NOT_FOUND':
              setError('This code not found')
              break

            case 'CODE_EXPIRED':
              setError('This code has aleady expired. Try to send another one.')
              break

            default:
              setError('Could not send verification code.')
          }
        },
      }),
    )

    return () => {
      removeRejectedListener()
    }
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (seconds > 0 && !showResendButton) {
        setSeconds((prevSeconds) => prevSeconds - 1)
      } else {
        setSeconds(30)
        setShowResendButton(true)
      }
    }, 1000)
    return () => clearTimeout(timer)
  }, [seconds, showResendButton])

  const handleResendClick = async () => {
    dispatch(forgotPasswordSendCode({ email, method }))
    setShowResendButton(false)
  }

  const handlerCheckedCode = async () => {
    dispatch(
      attemptForgotPasswordClaimCode({
        email,
        code: Number(code),
      }),
    )
  }

  return (
    <div className="forget__phone">
      <h4 className="forget__title">Verification code</h4>
      <h5 className="forget__subtitle">
        Please enter the confirmation code from the received message
      </h5>
      <CodeInput change={setCode} />
      {error && <div className="forget__error">{error}</div>}
      <button className="forget__phone-but" onClick={handlerCheckedCode}>
        Submit
      </button>
      {showResendButton ? (
        <button
          className="forget__phone-but"
          style={{ marginTop: '25px' }}
          onClick={handleResendClick}
        >
          Create New Code
        </button>
      ) : (
        <h6 className="forget__phone-resend">
          Re-send code in <span>0:{seconds}</span>
        </h6>
      )}
    </div>
  )
}

export default CheckCode
