export const IconMap = () => {
  return (
    <svg
      width="30"
      height="27"
      viewBox="0 0 30 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 0C11.3755 0 8.4375 2.97475 8.4375 6.64453C8.4375 9.61137 12.7266 15.0187 14.3698 16.9815C14.7026 17.3791 15.2979 17.3791 15.6302 16.9815C17.2734 15.0187 21.5625 9.61137 21.5625 6.64453C21.5625 2.97475 18.6245 0 15 0ZM15 8.85938C13.7917 8.85938 12.8125 7.86797 12.8125 6.64453C12.8125 5.42109 13.7917 4.42969 15 4.42969C16.2083 4.42969 17.1875 5.42109 17.1875 6.64453C17.1875 7.86797 16.2083 8.85938 15 8.85938ZM1.04792 11.388C0.738634 11.5132 0.473505 11.7294 0.286724 12.0087C0.099942 12.2879 7.40733e-05 12.6175 0 12.9547L0 26.1552C0 26.7521 0.595312 27.1603 1.14271 26.9388L8.33333 23.625V11.3337C7.87292 10.491 7.49635 9.67043 7.22656 8.88574L1.04792 11.388ZM15 18.967C14.2672 18.967 13.574 18.6411 13.0984 18.0726C12.0745 16.8492 10.9854 15.4559 10 14.0268V23.6245L20 26.9995V14.0273C19.0146 15.4559 17.926 16.8497 16.9016 18.0731C16.426 18.6411 15.7328 18.967 15 18.967ZM28.8573 8.49867L21.6667 11.8125V27L28.9521 24.0495C29.2614 23.9243 29.5266 23.7081 29.7134 23.4289C29.9002 23.1496 30 22.8201 30 22.4828V9.2823C30 8.68535 29.4047 8.27719 28.8573 8.49867Z"
        fill={'rgba(195, 202, 217, 1)'}
      />
    </svg>
  )
}
