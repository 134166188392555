import { Typography } from 'antd'
import { useNavigate } from 'react-router-dom'
import {
  profileTextInfo,
  setLoader,
  setValueProfileReducer,
} from '../../../../redux/profile'
import { useAppDispatch, useAppSelector } from '../../../../utils/hooks'
import { indexPathByRole } from '../../../route/indexPathByRole'
import { StandaloneScreenLayout } from '../../../ui/Layout/StandaloneScreenLayout'

const { Title, Paragraph } = Typography

export const ProfileWelcomeNeibs = () => {
  const { role } = useAppSelector((state) => state.user)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  return (
    <StandaloneScreenLayout
      title="Welcome to the Neibs"
      subTitle="If the platform rules are violated, your participation may be subject to termination"
      nextButtonTitle="Accept"
      isBackButtonPresented={false}
      isSecondButtonPresented={false}
      onPrimaryButtonClicked={() => navigate(indexPathByRole(role))}
      headerIcon={<img src="/Images/logoshort.png" />}
    >
      <div style={{ margin: '1em' }}>
        <Title level={5}> Be Yourself</Title>
        <Paragraph>
          Your photo, information, age, education, services must be real
        </Paragraph>

        <Title level={5}> Respect Others</Title>
        <Paragraph>
          Be polite and considerate when interacting with fellow users
        </Paragraph>

        <Title level={5}> Protect Privacy</Title>
        <Paragraph>
          Don’t share users personal or confidential information without their explicit
          consent
        </Paragraph>

        <Title level={5}> No Offensive Content</Title>
        <Paragraph>
          Avoid posting unlawful, insulting, discriminatory, or violent content
        </Paragraph>
      </div>
    </StandaloneScreenLayout>
  )
}
