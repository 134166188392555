import { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Slider from 'react-slick'
import { steperBoleanNormalize } from '../../../utils/actions'
import { apiBaseURL, indexPathByRole } from '../../route/indexPathByRole'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { IconCheckMark } from '../../svg/IconCheckMark'
import { YoutubeVideoModal } from '../../ui/YoutubeVideoModal'
import { DarkBg } from '../../ui/DarkBg'
import { RequestInterface, profileTextInfo } from '../../../redux/profile'
import { setValueProfileReducer } from '../../../redux/profile'
import { AxiosResponse } from 'axios'
import $api from '../../../http'
import { LocationType } from '../../../types/types'
import { IconServicesAllPoint } from '../../svg/IconServicesAll'
import { HideGetStartedModal } from './HideGetStartedModal'
import { Avatar } from 'antd'
import { getInitials } from '../../../utils/displayName'

const videos: { url: string; title: string }[] = [
  { url: 'https://www.youtube.com/embed/7MDnzlVAi8k', title: 'NH/Onboarding/CreatePos' },
  { url: 'https://www.youtube.com/embed/d1284fxiHtg', title: 'NH/Onboarding/Search' },
  {
    url: 'https://www.youtube.com/embed/pj1c8fGhDT0',
    title: 'NH/Onboarding/CreateActivity',
  },
  { url: 'https://www.youtube.com/embed/vpy9zzZmlDY', title: 'NH/Onboarding/Activity' },
  {
    url: 'https://www.youtube.com/embed/zXPbXCIzWAE',
    title: 'NH/Onboarding/CreateService',
  },
  { url: 'https://www.youtube.com/embed/m8tfDK7IBJE', title: 'NH/Onboarding/Service' },
  { url: 'https://www.youtube.com/embed/pNiRVpqe_X8', title: 'NH/Onboarding/Explore' },
  { url: 'https://www.youtube.com/embed/H05vJxiwi6g', title: 'NH/Onboarding/OpenChats' },
]

export const GetStarted = () => {
  // const { fullName, phone, avatarFileName, role, _id } = useAppSelector(
  //   (state) => state.user,
  // )

  const { fullName, phone, avatarFileName, role, id } = useAppSelector(
    (state) => state.userNew,
  )

  const {
    isAddedServices,
    isAddedPost,
    isExploreDone,
    isMessageWritten,
    isVideoShown,
    isExploreChatsDone,
  } = useAppSelector((state) => state.profile)

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [list, setList] = useState<boolean[]>([])
  const [index, setIndex] = useState(0)

  var settings = {
    infinite: false,
    speed: 800,
    slidesToShow: 2,
    slidesToScroll: 1,
  }
  const videoFrame = document.getElementById('video')
  const viewVideo = async ({ p, index }: { p: RequestInterface; index: number }) => {
    setIsOpen(true)
    setIndex(index)

    const res = await profileTextInfo(p)
    dispatch(setValueProfileReducer(res))
  }
  // useEffect(() => {
  //     console.log(videoFrame)
  //     if(videoFrame){
  //         if (videoFrame.requestFullscreen) {
  //             videoFrame.requestFullscreen();
  //         }
  //     }
  // }, [videoFrame])

  const [MarksList, setMarksList] = useState<LocationType[]>([])
  const [hideGetStarted, sethideGetStarted] = useState(false)
  const [isHiddenGetStarted, setIsHiddenGetStarted] = useState(false)

  const [isOpen, setIsOpen] = useState(false)
  // Motiur Rahman : Commented because it shows errors in each load
  // useEffect(() => {
  //   const body = async () => {
  //     const resMarksList: AxiosResponse<LocationType[]> =
  //       await $api.post('map/list-marks')
  //     const marks: LocationType[] = []
  //     setMarksList(resMarksList.data)
  //   }
  //   body()
  // }, [])
  const sliderRef = useRef<Slider>(null)
  useEffect(() => {
    setList([
      isAddedServices,
      isAddedPost,
      isExploreDone,
      Boolean(avatarFileName),
      Boolean(phone),
      isMessageWritten,
      isVideoShown,
      isExploreChatsDone,
    ])
  }, [
    isAddedServices,
    isAddedPost,
    isExploreDone,
    avatarFileName,
    phone,
    isMessageWritten,
    isVideoShown,
    isExploreChatsDone,
  ])
  return (
    <div className="user__newsfeed__getstarted">
      <Link to={'/publish/post'}>
        <div className="user__newsfeed__getstarted-mind">
          <div className="user__newsfeed__getstarted-mind-img">
            <Avatar
              size={52}
              icon={fullName && getInitials(fullName)}
              src={`${process.env.REACT_APP_UPLOADED_BASE_URL}/avatar/${avatarFileName}`}
            />
          </div>
          <div className="user__newsfeed__getstarted-mind-text">
            <b>{fullName}</b>, what’s on your mind ?
          </div>
        </div>
      </Link>
      {Boolean(phone) &&
      isAddedPost &&
      isAddedServices &&
      isExploreDone &&
      Boolean(avatarFileName) &&
      isMessageWritten &&
      isVideoShown &&
      isExploreChatsDone ? (
        <></>
      ) : (
        <>
          {!isHiddenGetStarted && (
            <>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <h5 className="user__newsfeed__getstarted-title">Get Started</h5>
                  <h6 className="user__newsfeed__getstarted-subtitle">
                    Here’s how to get started on NeighborHarbor
                  </h6>
                </div>
                <button onClick={() => sethideGetStarted(true)}>
                  <IconServicesAllPoint />
                </button>
              </div>
              <div className="user__newsfeed-slick">
                <Slider
                  className="user__newsfeed-slick-body"
                  {...settings}
                  ref={sliderRef}
                >
                  <div
                    className="user__newsfeed-slick-item"
                    onClick={() => navigate('/profileinfo/security')}
                  >
                    <div className="user__newsfeed-slick-mark">
                      {Boolean(phone) && <IconCheckMark />}
                      Step 1
                    </div>
                    <div className="user__newsfeed-slick-img">
                      <img
                        src="/Images/get1.png"
                        alt=""
                        style={{
                          width: '102px',
                          height: '110px',
                        }}
                      />
                    </div>

                    <button className="user__newsfeed-slick-button">
                      Connect your phone
                    </button>
                  </div>

                  <div
                    className="user__newsfeed-slick-item"
                    onClick={() =>
                      viewVideo({ p: { isAddedPost: true, _id: id || '' }, index: 1 })
                    }
                  >
                    <div className="user__newsfeed-slick-mark">
                      {isAddedPost && <IconCheckMark />}
                      Step 2
                    </div>
                    <div className="user__newsfeed-slick-img">
                      <img
                        src="/Images/get2.png"
                        alt=""
                        style={{
                          width: '139px',
                          height: '100px',
                        }}
                      />
                    </div>

                    <button className="user__newsfeed-slick-button">
                      Write your first post
                    </button>
                  </div>

                  <div
                    className="user__newsfeed-slick-item"
                    onClick={() =>
                      viewVideo({ p: { isAddedServices: true, _id: id || '' }, index: 4 })
                    }
                  >
                    <div className="user__newsfeed-slick-mark">
                      {isAddedServices && <IconCheckMark />}
                      Step 3
                    </div>
                    <div className="user__newsfeed-slick-img">
                      <img
                        src="/Images/get3.png"
                        alt=""
                        style={{
                          width: '87px',
                          height: '87px',
                        }}
                      />
                    </div>

                    <button className="user__newsfeed-slick-button">
                      Provide a service
                    </button>
                  </div>

                  <div
                    className="user__newsfeed-slick-item"
                    onClick={() =>
                      viewVideo({ p: { isExploreDone: true, _id: id || '' }, index: 2 })
                    }
                    // onClick={navigateToServices}
                  >
                    <div className="user__newsfeed-slick-mark">
                      {isExploreDone && <IconCheckMark />}
                      Step 4
                    </div>
                    <div className="user__newsfeed-slick-img">
                      <img
                        src="/Images/get4.png"
                        alt=""
                        style={{
                          width: '159px',
                          height: '74px',
                        }}
                      />
                    </div>

                    <button className="user__newsfeed-slick-button">
                      Make your first discovery
                    </button>
                  </div>

                  <div
                    className="user__newsfeed-slick-item"
                    onClick={() => navigate('/profileinfo/edit')}
                    // onClick={navigateToActivities}
                  >
                    <div className="user__newsfeed-slick-mark">
                      {Boolean(avatarFileName) && <IconCheckMark />}
                      Step 5
                    </div>
                    <div className="user__newsfeed-slick-img">
                      <img
                        src="/Images/get5.png"
                        alt=""
                        style={{
                          width: '101px',
                          height: '101px',
                        }}
                      />
                    </div>
                    <button className="user__newsfeed-slick-button">
                      Add your profile photo
                    </button>
                  </div>
                  <div
                    className="user__newsfeed-slick-item"
                    onClick={() =>
                      viewVideo({ p: { isVideoShown: true, _id: id || '' }, index: 5 })
                    }
                  >
                    <div className="user__newsfeed-slick-mark">
                      {isVideoShown && <IconCheckMark />}
                      Step 6
                    </div>
                    <div className="user__newsfeed-slick-img">
                      <img
                        src="/Images/get6.png"
                        alt=""
                        style={{
                          width: '89px',
                          height: '116px',
                        }}
                      />
                    </div>
                    <button className="user__newsfeed-slick-button">Add Neibs</button>
                  </div>
                  <div
                    className="user__newsfeed-slick-item"
                    onClick={() => role && navigate(`${indexPathByRole(role)}/messeges`)}
                  >
                    <div className="user__newsfeed-slick-mark">
                      {isMessageWritten && <IconCheckMark />}
                      Step 7
                    </div>
                    <div className="user__newsfeed-slick-img">
                      <img
                        src="/Images/get7.png"
                        alt=""
                        style={{
                          width: '101px',
                          height: '101px',
                        }}
                      />
                    </div>
                    <button className="user__newsfeed-slick-button">
                      Write your first message
                    </button>
                  </div>
                  <div
                    className="user__newsfeed-slick-item"
                    onClick={() =>
                      viewVideo({
                        p: { isExploreChatsDone: true, _id: id || '' },
                        index: 7,
                      })
                    }
                  >
                    <div className="user__newsfeed-slick-mark">
                      {isExploreChatsDone && <IconCheckMark />}
                      Step 8
                    </div>
                    <div className="user__newsfeed-slick-img">
                      <img
                        src="/Images/get8.png"
                        alt=""
                        style={{
                          width: '101px',
                          height: '101px',
                        }}
                      />
                    </div>
                    <button className="user__newsfeed-slick-button">
                      Discover OpenChats
                    </button>
                  </div>
                </Slider>
              </div>
              <div className="user__newsfeed-slick-steper">
                <p className="user__newsfeed-steper-title">
                  {list.filter((it) => it).length} of 8 steps
                </p>
                <div className="user__newsfeed-steper-body">
                  {steperBoleanNormalize(list).map((item) => (
                    <div
                      className={`user__newsfeed-steper-item ${
                        item && 'user__newsfeed-steper-item-active'
                      }`}
                    />
                  ))}
                </div>
              </div>
            </>
          )}
        </>
      )}
      {isOpen && (
        <YoutubeVideoModal setIsOpen={setIsOpen} isOpen={isOpen}>
          <iframe
            id="video"
            src={videos[index].url}
            title={videos[index].title}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </YoutubeVideoModal>
      )}
      {hideGetStarted && (
        <HideGetStartedModal
          setIsOpen={sethideGetStarted}
          isOpen={hideGetStarted}
          setHidden={setIsHiddenGetStarted}
        />
      )}
      {isOpen && hideGetStarted && <DarkBg />}
    </div>
  )
}
