import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { PublishPostHttp } from '../../../http/publish-post-http'
import { UserHttp } from '../../../http/user-http'
import { FriendTypeResponse } from '../../../types/types'
import { apiBaseURL } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconProfileCircle, IconProfileCircleFilled } from '../../svg/IconProfile'
import { InputSearch } from '../../ui/InputSearch'

export const PublicationPostShare = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { _id } = useAppSelector((state) => state.user)
  const [Friends, setFriends] = useState<FriendTypeResponse[]>([])
  const [FriendsNormal, setFriendsNormal] = useState<FriendTypeResponse[]>([])
  const [shareList, setShareList] = useState<FriendTypeResponse[]>([])
  const [search, setSearch] = useState<string>('')
  const validate = !Boolean(shareList.length)
  useEffect(() => {
    const EffectBody = async () => {
      const res = await UserHttp.getMyFriends({ _id })
      setFriends(res)
      setFriendsNormal(res)
      console.log(res)
    }
    EffectBody()
  }, [])
  useEffect(() => {
    let array: FriendTypeResponse[] = []
    Friends.map((item) => {
      if (item.friendId.fullName.toLowerCase().includes(search.toLowerCase())) {
        array.push(item)
      }
    })
    setFriendsNormal(array)
    if (!search) {
      setFriendsNormal(Friends)
    }
  }, [search])

  const updateRepost = async ({
    postId,
    isReposted,
    shareList,
  }: {
    postId: string
    isReposted: boolean
    shareList: FriendTypeResponse[]
  }) => {
    await PublishPostHttp.updateRepost({
      postId,
      repostedUserId: _id,
      shareList: shareList,
    })
  }
  return (
    <div className="share">
      <div className="share-search">
        {Friends.length > 0 && (
          <InputSearch
            placeholder={
              <>
                Search<span> Post</span>
              </>
            }
            value={search}
            changeValue={setSearch}
          />
        )}
      </div>
      <div className="share-container">
        {FriendsNormal?.map((item) => (
          <Friend item={item} setShareList={setShareList} shareList={shareList} />
        ))}
        <button
          disabled={validate}
          className={`profile__method-btlater share-container-btn ${validate && 'services__add-button--disabled'}`}
          onClick={() => {
            updateRepost({
              postId: location.state.postId,
              isReposted: location.state.isReposted,
              shareList: shareList,
            })
            navigate(-1)
          }}
        >
          Share
        </button>
      </div>
    </div>
  )
}

const Friend = ({
  item,
  shareList,
  setShareList,
}: {
  item: FriendTypeResponse
  shareList: FriendTypeResponse[]
  setShareList: (s: FriendTypeResponse[]) => void
}) => {
  const [share, setShare] = useState<boolean>(false)
  return (
    <div
      className="share-friend-item"
      onClick={() => {
        if (!share) {
          let array: FriendTypeResponse[] = [...shareList]
          array.push(item)
          setShareList(array)
          setShare(!share)
        } else {
          let array = [...shareList]
          let index = array.indexOf(item)
          if (index !== -1) {
            array.splice(index, 1)
            setShareList(array)
          }
          setShare(!share)
        }
      }}
    >
      <div className="share-friend-item-content">
        {item.friendId.avatarFileName ? (
          <img
            style={{ width: '60px', height: '60px', objectFit: 'cover' }}
            src={`${apiBaseURL}/uploads/avatar/${item.friendId.avatarFileName}`}
          />
        ) : (
          <div
            style={{
              backgroundColor: 'rgba(41, 52, 65, 1)',
              borderRadius: '100%',
              width: '60px',
              height: '60px',
              color: '#fff',
              textAlign: 'center',
              position: 'relative',
            }}
          >
            <span style={{ position: 'absolute', left: '20px', top: '18px' }}>
              {item.friendId.fullName.slice(0, 2)}
            </span>
          </div>
        )}
        <div className="share-friend-item-content-user">
          <h5>{item?.friendId.fullName}</h5>
          {/* <h6>{"online"}</h6> */}
        </div>
      </div>
      <div className="share-friend-item-radio">
        {!share ? <IconProfileCircle /> : <IconProfileCircleFilled />}
      </div>
    </div>
  )
}
