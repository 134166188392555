import L from 'leaflet'
import { renderToString } from 'react-dom/server'
import { useNavigate } from 'react-router-dom'
import { LocationType } from '../../types/types'
import { apiBaseURL } from '../route/indexPathByRole'
import { Marker } from 'react-leaflet'

export const LocationMarker = (props: {
  markerData: LocationType
  handler: () => void
}) => {
  const img: string = `${apiBaseURL}/uploads/map/${props.markerData.file}`
  const navigate = useNavigate()
  const markDivIcon = new L.DivIcon({
    html: renderToString(
      <div
        style={{
          borderRadius: '3px',
          padding: '3px',
          height: '42px',
          width: '42px',
          position: 'absolute',
          left: '-10px',
          top: '-5px',
        }}
      >
        <div className="image">
          <img src={img} />
        </div>
        <span style={{ position: 'absolute', top: '148%', left: '-20%' }}>
          <b>{props.markerData.title}</b>
        </span>
      </div>,
    ),
    iconSize: [32, 32],
    className: 'leaflet-div-icon',
  })
  return (
    <Marker
      eventHandlers={{
        click: (e) => {
          props.handler()
        },
      }}
      position={props.markerData.center}
      icon={markDivIcon}
    ></Marker>
  )
}
