export const IconClock = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4064 13.0314L10.0364 11.0314V6.50139C10.0364 6.10139 9.71638 5.78139 9.31638 5.78139H9.25638C8.85638 5.78139 8.53638 6.10139 8.53638 6.50139V11.2214C8.53638 11.5714 8.71638 11.9014 9.02638 12.0814L12.6764 14.2714C13.0164 14.4714 13.4564 14.3714 13.6564 14.0314C13.8664 13.6814 13.7564 13.2314 13.4064 13.0314ZM18.7164 2.79139L15.6364 0.23139C15.2164 -0.11861 14.5864 -0.0686096 14.2264 0.36139C13.8764 0.78139 13.9364 1.41139 14.3564 1.77139L17.4264 4.33139C17.8464 4.68139 18.4764 4.63139 18.8364 4.20139C19.1964 3.78139 19.1364 3.15139 18.7164 2.79139ZM1.63638 4.33139L4.70638 1.77139C5.13638 1.41139 5.19638 0.78139 4.83638 0.36139C4.48638 -0.0686096 3.85638 -0.11861 3.43638 0.23139L0.356383 2.79139C-0.0636172 3.15139 -0.123617 3.78139 0.236383 4.20139C0.586383 4.63139 1.21638 4.68139 1.63638 4.33139ZM9.53638 1.78139C4.56638 1.78139 0.536383 5.81139 0.536383 10.7814C0.536383 15.7514 4.56638 19.7814 9.53638 19.7814C14.5064 19.7814 18.5364 15.7514 18.5364 10.7814C18.5364 5.81139 14.5064 1.78139 9.53638 1.78139ZM9.53638 17.7814C5.67638 17.7814 2.53638 14.6414 2.53638 10.7814C2.53638 6.92139 5.67638 3.78139 9.53638 3.78139C13.3964 3.78139 16.5364 6.92139 16.5364 10.7814C16.5364 14.6414 13.3964 17.7814 9.53638 17.7814Z"
        fill="#C3CAD9"
      />
    </svg>
  )
}
