import { useNavigate } from 'react-router-dom'
import { indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconAddNeib } from '../../svg/IconAddNeib'
import { IconNeibs } from '../../svg/IconPassEye'
import { IconsNewsfeedPlus } from '../../svg/IconsNewsfeed'

export const GetStartedMessenger = () => {
  const navigate = useNavigate()
  const { role } = useAppSelector((state) => state.user)

  const toUserChatList = () => {
    navigate(`${indexPathByRole(role)}/messeges/user-chat`)
  }
  const toGroup = () => {
    navigate(`${indexPathByRole(role)}/messeges/grouppage`)
  }

  return (
    <div className="messenger__getstarted" style={{ position: 'absolute', left: '0' }}>
      <div className="messenger__getstarted-body">
        <img src="/Images/chatstarted.png" alt="" />
        <h5 className="messenger__getstarted-title">Get Started</h5>
        <h6 className="messenger__getstarted-subtitle messenger__getstarted-subtitle-1">
          Tap
          <button className="messenger__getstarted-button messenger__getstarted-button-1">
            <IconsNewsfeedPlus />
          </button>
          to send a message.
        </h6>
        <h6
          className="messenger__getstarted-subtitle messenger__getstarted-subtitle-2"
          onClick={toGroup}
        >
          Tap
          <button className="messenger__getstarted-button messenger__getstarted-button-2">
            <IconNeibs />
          </button>
          to create a group
        </h6>
        <h6
          className="messenger__getstarted-subtitle messenger__getstarted-subtitle-2"
          onClick={toUserChatList}
        >
          Tap
          <button className="messenger__getstarted-button messenger__getstarted-button-2">
            <IconAddNeib />
          </button>
          to find people
        </h6>
      </div>
    </div>
  )
}
