import { useEffect, useState } from 'react'
import { ChatType } from '../../../types/types'
import { ChatList } from '../../general-components/messenger/ChatList'
import { UserHeaderChat } from '../header/UserHeaderChat'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { getUserChats } from '../../../redux/messanger'

export const MessegesSupport = () => {
  const [search, setSearch] = useState('')
  const { _id } = useAppSelector((state) => state.user)
  const [isActiveSearch, setIsActiveSearch] = useState(false)
  const [chats, setChats] = useState<ChatType[]>([])
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(getUserChats({ _id, isSupport: true }))
  }, [])

  return (
    <>
      <div className={`user__chat ${isActiveSearch && 'user__chat--active'}`}>
        <UserHeaderChat
          chats={chats}
          search={search}
          setSearch={setSearch}
          isActiveSearch={isActiveSearch}
          setIsActiveSearch={setIsActiveSearch}
        />
        <ChatList search={search} setChats={setChats} isSupport={true} />
      </div>
    </>
  )
}
