import { ServiceHttp } from '../http/service-http'
import { CoordinatsInterface, PublishServiceItemInterface } from '../types/types'

export const calculateRate = (item: PublishServiceItemInterface) => {
  let rate: number[] = []
  item.reviews.map((review) => {
    rate.push(review.rate)
  })
  return rate.reduce((partialSum, a) => partialSum + a, 0) / rate.length
}
export function toRadian(degree: number) {
  return (degree * Math.PI) / 180
}
export const calculateDistance = (A: CoordinatsInterface, B: CoordinatsInterface) => {
  let lon1 = toRadian(A.lng),
    lat1 = toRadian(A.lat),
    lon2 = toRadian(B.lng),
    lat2 = toRadian(B.lat)

  let deltaLat = lat2 - lat1
  let deltaLon = lon2 - lon1

  let a =
    Math.pow(Math.sin(deltaLat / 2), 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLon / 2), 2)
  let c = 2 * Math.asin(Math.sqrt(a))
  let EARTH_RADIUS = 6371
  return Math.floor(c * EARTH_RADIUS * 1000)
}
export const calculateUserRate = (userId: string) => {
  ServiceHttp.getUserPublishServiceReview({ ownerId: userId }).then((userReviews) => {
    let rate: number[] = []
    userReviews.map((review) => {
      rate.push(review.rate)
    })
    console.log(rate)
    return rate.reduce((partialSum, a) => partialSum + a, 0) / rate.length
  })
}
