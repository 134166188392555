import axios from 'axios'
import { useState } from 'react'
import { registrationSetAddress } from '../../../redux/auth'
import { useAppDispatch } from '../../../utils/hooks'
import SetAddressAutocomplete from './SetAddressAutocomplete'
import SetAddressConfirm from './SetAddressConfirm'
import SetAddressGeolocation from './SetAddressGeolocation'
import { NominatimSearchResponse } from '../../../redux/statelessRequests/address'
import { convertNominatimResponseToAddressDTO } from './AddressDTO'

const SetAddress = () => {
  const dispatch = useAppDispatch()
  const [addressToConfirm, setAddressToConfirm] = useState<
    NominatimSearchResponse | undefined
  >()
  const [geolocation, setGeolocation] = useState(false)

  function setAddress(address: NominatimSearchResponse) {
    if (address) {
      dispatch(registrationSetAddress(convertNominatimResponseToAddressDTO(address)))
    } else {
    }
  }

  return geolocation ? (
    <SetAddressGeolocation
      onBack={() => setGeolocation(false)}
      onConfirm={(address: NominatimSearchResponse) => setAddress(address)}
    />
  ) : addressToConfirm ? (
    <SetAddressConfirm
      onBack={() => setAddressToConfirm(undefined)}
      onConfirm={() => setAddress(addressToConfirm)}
      place={addressToConfirm}
    />
  ) : (
    <SetAddressAutocomplete
      onConfirm={setAddress}
      onGeolocation={() => {
        setGeolocation(true)
      }}
    />
  )
}

export default SetAddress
