export const IconPencil = (props: { color?: string }) => {
  return (
    <svg
      style={{ margin: '10px' }}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 17.9995H3.75L14.81 6.93951L11.06 3.18951L0 14.2495V17.9995ZM2 15.0795L11.06 6.01952L11.98 6.93951L2.92 15.9995H2V15.0795ZM15.37 0.289514C15.2775 0.196811 15.1676 0.123263 15.0466 0.0730817C14.9257 0.0229003 14.796 -0.00292969 14.665 -0.00292969C14.534 -0.00292969 14.4043 0.0229003 14.2834 0.0730817C14.1624 0.123263 14.0525 0.196811 13.96 0.289514L12.13 2.11951L15.88 5.86951L17.71 4.03951C17.8027 3.947 17.8762 3.83711 17.9264 3.71614C17.9766 3.59517 18.0024 3.46548 18.0024 3.33451C18.0024 3.20355 17.9766 3.07386 17.9264 2.95289C17.8762 2.83192 17.8027 2.72203 17.71 2.62951L15.37 0.289514Z"
        fill={props.color ? props.color : '#C3CAD9'}
      />
    </svg>
  )
}
