import { useContext } from 'react'
import { Modal } from '../../ui/Modal'
import { SocketContext } from '../../../context/SocketContext'
import { SOCKET_MESSENDER_EVENT } from '../../../types/enum'
import { useAppSelector } from '../../../utils/hooks'
import Picker from '@emoji-mart/react'
import data from '@emoji-mart/data'

export const ModalSmileLike = (props: {
  senderId: string
  index: number
  updateList: (index: number, val: string) => void
  fileName: string | null
  setIsOpenSmile: (o: boolean) => void
  timeStamp: Date
  chatId: string
}) => {
  const { socket } = useContext(SocketContext)
  const { _id } = useAppSelector((state) => state.user)
  return (
    <div className="messenger__chat-modal">
      <Modal className="" setIsOpen={props.setIsOpenSmile}>
        <Picker
          data={data}
          onEmojiSelect={(e: any) => {
            if (socket) {
              socket.current?.emit(SOCKET_MESSENDER_EVENT.SEND_PRIVATE_MESSAGE_LIKE, {
                chatId: props.chatId,
                senderId: props.senderId,
                like: e?.native,
                timestamp: props.timeStamp,
                isRead: true,
              })
              props.updateList(props.index, e?.native)
            }
          }}
          theme="light"
        />
      </Modal>
    </div>
  )
}
