import { TextareaAutosize } from '@mui/material'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ActivitiesHttp } from '../../../http/activities-http'
import { FeedBackHttp } from '../../../http/feedback-http'
import { UserHttp } from '../../../http/user-http'
import {
  CommentInterface,
  HeaderMessageType,
  PublishActivitiesOneItemInterface,
} from '../../../types/types'
import { apiBaseURL, indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { PublicationPostCommentsList } from '../../general-components/publication-lists/PublicationPostCommentsList'
import { PublicationPostCommentsView } from '../../general-components/publication-lists/PublicationPostCommentsView'
import { IconAdminClose } from '../../svg/IconAdminHeader'
import { IconComment } from '../../svg/IconFavor'
import { IconPostsLike, IconPostsRedLike, IconPostsRepost } from '../../svg/IconPosts'
import { IconProfileInfoBookmark } from '../../svg/IconProfileInfo'
import { IconServicesAllPoint } from '../../svg/IconServicesAll'
import { IconTicket } from '../../svg/IconTicket'
import { ButtonLeftChevron } from '../../ui/ButtonLeftChevron'
import { success } from '../../ui/LoadSuccess'
import { Loader } from '../../ui/Loader'
import { PostSlick } from '../../ui/PostSlick'
import { UserServiceMap } from '../services/UserServiceMap'
import { ActivitiesMyModal } from './ActivitiesMyModal'
import { ActivitiesNeibModal } from './ActivitiesNeibModal'
import { PreviewModal } from '../../general-components/preview/PreviewModal'

export const UserPublishActivitiesItem = () => {
  // const [myModalOpen, setMyModalOpen] = useState(false)
  // const [neibModalOpen, setNeibModalOpen] = useState(false)

  const [searchParams] = useSearchParams()
  const [publishService, setPublishService] =
    useState<PublishActivitiesOneItemInterface>()
  const { _id, fullName, role } = useAppSelector((state) => state.user)
  const { userIdentityId } = useAppSelector((state) => state.profile)

  const [comments, setComments] = useState<CommentInterface[]>([])
  const [countComments, setCountComments] = useState(0)
  const navigate = useNavigate()
  const [text, setText] = useState('')
  const [isOpenSave, setIsOpenSave] = useState<boolean>(false)

  const [isOpen, setIsOpen] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [update, setUpdate] = useState(false)
  const [userAvatars, setUserAvatars] = useState<HeaderMessageType[]>([])
  const [users, setUsers] = useState<HeaderMessageType[]>([])

  const getPublishServiceData = async () => {
    const publishActivitiesId = searchParams.get('publishActivitiesId') || ''
    const publish = await ActivitiesHttp.getOnePublsihActivities({
      publishActivitiesId,
      userId: _id,
    })
    if (publish) {
      setPublishService(publish)
    }
  }
  const [isOpenShare, setIsOpenShare] = useState(false)
  const copy = () => {
    const url = `${window.location.host}/preview/activity?publishServiceId=${publishService?._id}`
    navigator.clipboard.writeText(url)
  }
  const updateNotification = async (serviceId: string) => {
    if (publishService) {
      await ActivitiesHttp.updateNotification({
        eventId: serviceId,
        userId: _id,
      })

      setPublishService({
        ...publishService,
        isNotificatedEvent: !publishService.isNotificatedEvent,
      })
    }
    setUpdate(true)
  }

  useEffect(() => {
    const bodyEffect = async () => {
      await getPublishServiceData()
      setLoaded(true)
      // await getCommentData()
    }
    bodyEffect()
  }, [])

  const toProfileInfo = (prop: {
    _id: string
    email: string
    role: string
    fullName: string
    avatarFileName: string
  }) => {
    navigate('/profileinfo', {
      state: prop,
    })
  }

  const updateLikeEvent = async (likeId: string, serviceId: string) => {
    await FeedBackHttp.updateLike({
      likeId,
      userId: _id,
    })
    setUpdate(true)
    setPublishService((p) =>
      p
        ? {
            ...p,
            isLiked: !p.isLiked,
            countLikes: p.isLiked ? p?.countLikes - 1 : p?.countLikes + 1,
          }
        : p,
    )
  }

  const copyLink = async () => {
    const text = window.location.href
    navigate(`${indexPathByRole(role)}/service/share-list`, { state: { text } })
  }

  const updateMarkEvent = async (eventId: string, isMarked: boolean) => {
    if (isMarked) {
      await ActivitiesHttp.deleteMark({
        eventId,
        marckedUserId: _id,
      })
    } else {
      await ActivitiesHttp.addMark({
        eventId,
        marckedUserId: _id,
      })
      setIsOpenSave(true)
    }
    setUpdate(true)
    if (publishService) {
      setPublishService({
        ...publishService,
        isMarked: !publishService.isMarked,
      })
    }
  }

  const getCommentData = async () => {
    const eventId = searchParams.get('publishActivitiesId') || ''
    const { comments, countComments } = await ActivitiesHttp.getComments({
      eventId,
      userId: _id,
    })
    setUpdate(true)
    setCountComments(countComments)
    setComments(comments)
  }
  useEffect(() => {
    getCommentData()
  }, [])

  const [isreply, setIsreply] = useState<boolean>(false)
  const [replyData, setReplyData] = useState<{
    user: {
      _id: string
      fullName: string
      avatarFileName: string
    }
    commentText: string
    commentId: string
  }>({
    user: { _id: '', fullName: '', avatarFileName: '' },
    commentText: '',
    commentId: '',
  })

  const replyOnComment = async (data: {
    replyText: string
    commentId: string
    userId: string
  }) => {
    const eventId = searchParams.get('publishActivitiesId') || ''
    await ActivitiesHttp.replyOnComment({
      commentId: data.commentId,
      replyText: data.replyText,
      userId: data.userId,
      eventId,
      createdDateComment: new Date(),
    })
    success()
    getCommentData()
    setText('')
    setIsreply(false)
  }

  const sendComment = async () => {
    const eventId = searchParams.get('publishActivitiesId') || ''
    await ActivitiesHttp.addComment({
      eventId,
      userId: _id,
      userIdentityId,
      text,
      createdDateComment: new Date(),
    })
    success()
    getCommentData()
    setText('')
  }
  useEffect(() => {
    if (publishService && loaded) {
      if (publishService.peopleSaw.length > 3) {
        publishService?.peopleSaw
          .slice(publishService?.peopleSaw.length - 3, publishService?.peopleSaw.length)
          .map((item) => {
            UserHttp.getUserById({ userId: item }).then((res) => {
              setUsers((s) => [...s, res])
            })
          })
      } else {
        publishService?.peopleSaw.map((item) => {
          UserHttp.getUserById({ userId: item }).then((res) => {
            setUsers((s) => [...s, res])
          })
        })
      }
    }
  }, [loaded])
  useEffect(() => {
    if (users.length) setUserAvatars(users)
  }, [users])

  return publishService ? (
    <div className="commenst user__publish-service">
      <div className="commenst__slick">
        <div
          className="user__services-last-item-img-1 user__services-last-item-img-info"
          style={{ width: '100px', height: '35px', top: '95%' }}
        >
          <div className="user__services-last-item-img-text">
            <span
              className="admin__posts-list-row1-text"
              style={{ fontSize: '12px', color: 'rgba(21, 16, 77, 1)' }}
            >
              {moment(publishService?.startDate).format('DD/MM/YYYY')}
            </span>{' '}
          </div>
        </div>
        <div className="commenst__back">
          <ButtonLeftChevron onClick={() => navigate(-1)} />
          <button className="ui-button-back-route" onClick={() => setIsOpen(true)}>
            <IconServicesAllPoint />
          </button>
        </div>
        {publishService?.filesName.length > 0 ? (
          <PostSlick list={publishService?.filesName}>
            {publishService?.filesName?.map((it) => (
              <div className="commenst__slick-img">
                <img src={`${apiBaseURL}/uploads/publish_activities/${it}`} alt="" />
              </div>
            ))}
          </PostSlick>
        ) : (
          <div className="commenst__slick-img">
            <img className="posts__bg" src={`/Images/postsBG.png`} alt="" />
          </div>
        )}
        <div className="commenst__slick-bookmark">
          <button
            onClick={() => updateMarkEvent(publishService._id, publishService.isMarked)}
            className={`ui-button-back-route ${
              publishService.isMarked && 'admin__posts-list-row4-repost--active'
            }`}
          >
            <IconProfileInfoBookmark />
          </button>
        </div>
      </div>
      <div className="commenst__content">
        <div
          className="commenst__user"
          onClick={() => toProfileInfo(publishService.userId)}
        >
          {publishService.userId && (
            <div className="commenst__user-img">
              {publishService.userId.avatarFileName ? (
                <img
                  src={`${apiBaseURL}/uploads/avatar/${publishService.userId.avatarFileName}`}
                />
              ) : (
                <div
                  style={{
                    backgroundColor: 'rgba(41, 52, 65, 1)',
                    borderRadius: '100%',
                    width: '100%',
                    height: '100%',
                    color: '#fff',
                    textAlign: 'center',
                    position: 'relative',
                  }}
                >
                  <span style={{ position: 'absolute', left: '13px', top: '12px' }}>
                    {publishService.userId.fullName.slice(0, 2)}
                  </span>
                </div>
              )}
            </div>
          )}
          <div>
            <p className="commenst__user-info-name">{publishService.userId?.fullName}</p>
            <p className="commenst__user-info-time">
              {moment(publishService.createEventDate).format('MMM D, h:mm a')}
            </p>
          </div>
        </div>
        <h5 className="commenst-title">{publishService.title}</h5>
        <p className="commenst-subtitle">{publishService.text}</p>
        <br />
        <div className="commenst__viewmap">
          {/* {comments.length > 2 ? (
                        <PublicationPostCommentsView
                            avatar={comments
                                .slice(0, 3)
                                .map((item) => item.userId.avatarFileName)}
                        />
                    ) : (
                        <></>
                    )}
                    <p className="commenst__view-text">
                        Views <b>{publishService.views}</b>
                    </p> */}
          <div className="commenst__map">
            <PublicationPostCommentsView user={userAvatars} />
            <p className="commenst__view-text">
              Views <b>{publishService.peopleSaw.length}</b>
            </p>
            <UserServiceMap type="publish_activities" item={publishService} />
          </div>
        </div>
        {publishService.url && (
          <div className="user__event__page-tickets">
            <h5 className="commenst-title">Tickets</h5>
            <div className="user__event__page-tickets-btn" onClick={copyLink}>
              <IconTicket />
              <span>Get Tickets</span>
            </div>
          </div>
        )}
        <div className="user__event__page-comments">
          <div>
            <h5 className="commenst-title">Comments</h5>
            <PublicationPostCommentsList
              comments={comments}
              setComments={setComments}
              setReplyData={setReplyData}
              setIsreply={setIsreply}
            />
          </div>
          <div className="admin__posts-list-row4">
            <button
              onClick={() => updateLikeEvent(publishService.likes, publishService._id)}
            >
              {publishService.isLiked ? <IconPostsRedLike /> : <IconPostsLike />}
              <span>{publishService.countLikes}</span>
            </button>
            <button>
              <IconComment />
              <span>{countComments}</span>
            </button>
            <button onClick={() => setIsOpenShare(true)}>
              <IconPostsRepost />
              <span>{publishService.countShare}</span>
            </button>
            <div
              onClick={() => updateMarkEvent(publishService._id, publishService.isMarked)}
              className={`${
                publishService.isMarked && 'admin__posts-list-row4-repost--active'
              }`}
            >
              <IconProfileInfoBookmark />
            </div>
          </div>
          {isreply && (
            <div className="commenst__reply">
              <div className="commenst__reply-row1">
                <span className="commenst__user-info-time commenst__reply-row1-text">
                  Reply To: <b>{replyData.user.fullName}</b>
                </span>
                <button className="" onClick={() => setIsreply(false)}>
                  <IconAdminClose />
                </button>
              </div>
              <div className="commenst__reply-row2">
                <span className="commenst__user-info-time">
                  On Comment:{' '}
                  <b style={{ fontStyle: 'italic' }}>
                    "
                    {replyData.commentText.length < 20
                      ? replyData.commentText
                      : `${replyData.commentText.slice(0, 20)}...`}
                    "
                  </b>
                </span>
              </div>
            </div>
          )}
          <div className="commenst__input">
            <TextareaAutosize
              value={text}
              onChange={(e) => setText(e.target.value)}
              className="messenger__chat-sender-autoresize"
              minRows={1}
              placeholder={`Comment as ${fullName}`}
            />
            <button
              className={`messenger__chat-sender-send ${
                !text && 'messenger__chat-sender-send--disabled'
              }`}
              onClick={
                !isreply
                  ? sendComment
                  : () =>
                      replyOnComment({
                        replyText: text,
                        commentId: replyData.commentId,
                        userId: _id,
                      })
              }
              disabled={!text}
            >
              Send
            </button>
          </div>
        </div>
        <div style={{ padding: '30px 0' }} />
      </div>
      {publishService.userIdentityId.user === _id && (
        <ActivitiesMyModal
          item={publishService}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          updateNotification={updateNotification}
          type="activity"
        />
      )}
      {publishService.userIdentityId.user !== _id && (
        <ActivitiesNeibModal
          item={publishService}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          setPublishService={setPublishService}
          updateNotification={updateNotification}
          type="activity"
        />
      )}
      <PreviewModal
        setIsOpen={setIsOpenShare}
        isOpen={isOpenShare}
        apply={copyLink}
        copy={copy}
      />
    </div>
  ) : (
    <Loader />
  )
}
