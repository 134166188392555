export const IconMenu = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5998 9.6C6.0398 9.6 6.3998 9.24 6.3998 8.8C6.3998 8.36 6.0398 8 5.5998 8C5.1598 8 4.7998 8.36 4.7998 8.8C4.7998 9.24 5.1598 9.6 5.5998 9.6ZM6.3998 12C6.3998 12.44 6.0398 12.8 5.5998 12.8C5.1598 12.8 4.7998 12.44 4.7998 12C4.7998 11.56 5.1598 11.2 5.5998 11.2C6.0398 11.2 6.3998 11.56 6.3998 12ZM6.3998 15.2C6.3998 15.64 6.0398 16 5.5998 16C5.1598 16 4.7998 15.64 4.7998 15.2C4.7998 14.76 5.1598 14.4 5.5998 14.4C6.0398 14.4 6.3998 14.76 6.3998 15.2ZM19.1998 12C19.1998 12.44 18.8398 12.8 18.3998 12.8H8.7998C8.3598 12.8 7.9998 12.44 7.9998 12C7.9998 11.56 8.3598 11.2 8.7998 11.2H18.3998C18.8398 11.2 19.1998 11.56 19.1998 12ZM18.3998 16C18.8398 16 19.1998 15.64 19.1998 15.2C19.1998 14.76 18.8398 14.4 18.3998 14.4H8.7998C8.3598 14.4 7.9998 14.76 7.9998 15.2C7.9998 15.64 8.3598 16 8.7998 16H18.3998ZM8.7998 9.6C8.3598 9.6 7.9998 9.24 7.9998 8.8C7.9998 8.36 8.3598 8 8.7998 8H18.3998C18.8398 8 19.1998 8.36 19.1998 8.8C19.1998 9.24 18.8398 9.6 18.3998 9.6H8.7998Z"
        fill="#3361FF"
      />
    </svg>
  )
}
