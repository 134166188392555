import { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import { getPosts } from '../../../redux/posts'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { PublicationPostShare } from '../../general-components/publication-lists/PublicationPostShare'
import { PublicationPosts } from '../../general-components/publication-lists/PublicationPosts'
import { FooterNav } from '../footer-navigate/FooterNav'
import { UserHeaderMain } from '../header/UserHeaderMain'
import { setLoader } from '../../../redux/posts'
import { getUserChats } from '../../../redux/messanger'
import { SaveWebsiteModal } from '../../ui/SaveWebsiteModal'
import { DarkBg } from '../../ui/DarkBg'
import { AddToHomeScreen } from 'react-pwa-add-to-homescreen'
import useCompleteProfileNotification from '../../../utils/hooks/useCompleteProfileNotification'

export const NewsFeeds = () => {
  const [isOpenPublish, setIsOpenPublish] = useState(false)
  useCompleteProfileNotification()
  const { _id } = useAppSelector((state) => state.user)
  const dispatch = useAppDispatch()
  const { polygonCoords } = useAppSelector((state) => state.profile)
  const [pageNumber, setPageNumber] = useState(1)
  useEffect(() => {
    dispatch(
      getPosts({
        pageNumber,
        userId: _id,
        isMarkedOption: false,
      }),
    )
    dispatch(getUserChats({ _id, isSupport: false }))
    dispatch(setLoader(true))
  }, [pageNumber])

  return (
    <div className="user">
      <UserHeaderMain isOpenPublish={isOpenPublish} setIsOpenPublish={setIsOpenPublish} />
      <FooterNav />
      <Routes>
        <Route
          path="all"
          element={
            <PublicationPosts
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              userCoords={polygonCoords}
              isOpenPublish={isOpenPublish}
              setIsOpenPublish={setIsOpenPublish}
            />
          }
        />
        <Route
          index
          element={
            <PublicationPosts
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              userCoords={polygonCoords}
              isOpenPublish={isOpenPublish}
              setIsOpenPublish={setIsOpenPublish}
            />
          }
        />
        <Route path="/share" element={<PublicationPostShare />} />
      </Routes>
      {/* <AddToHomeScreen /> */}
      {/* <SaveWebsiteModal 
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                />
                {isOpen && <DarkBg />} */}
    </div>
  )
}
