export const ExploreSearchNoResult = ({ search }: { search: string }) => {
  return (
    <>
      <div className="explore__search-no">
        <img className="explore__search-no-img" src="/Images/search.png" alt="" />
        <img className="explore__search-no-bg" src="/Images/searchBg.png" alt="" />
        <h5>No Results</h5>
        <h6>
          On the request ”{search}” <br /> nothing was found. Please try again
        </h6>
      </div>
    </>
  )
}
