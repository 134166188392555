import { ReactNode } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

export const SlickCategories = ({
  children,
  changeCurrentSlide = () => {},
  className,
  show,
  scroll,
  centerMode = false,
  mapChange,
}: {
  children: ReactNode
  changeCurrentSlide?: (n: number) => void
  className?: string
  show?: number
  scroll?: number
  centerMode?: boolean
  mapChange?: (n: number) => void
}) => {
  var settings = {
    infinite: false,
    speed: 500,
    slidesToShow: show || 4,
    slidesToScroll: scroll || 2,
    arrows: false,
    centerMode,
    afterChange: (n: number) => {
      changeCurrentSlide(n)
      if (mapChange) mapChange(n)
    },
  }
  return (
    <Slider className={className} {...settings}>
      {children}
    </Slider>
  )
}
