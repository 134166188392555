import moment from 'moment'
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Slider from 'react-slick'
import { ActivitiesHttp } from '../../../http/activities-http'
import { FeedBackHttp } from '../../../http/feedback-http'
import { PRIVACY, ROLE, USER_LIST_APP } from '../../../types/enum'
import {
  GetAllPublishActivitiesInterface,
  PublishEventItemInterface,
} from '../../../types/types'
import { apiBaseURL, indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { PublicationEventPanel } from '../../general-components/publication-lists/PublicationEventPanel'
import { IconBookMark } from '../../svg/IconBookMark'
import { IconServicesAllPoint } from '../../svg/IconServicesAll'
import { DarkBg } from '../../ui/DarkBg'
import { InputSearch } from '../../ui/InputSearch'
import { TempNotification } from '../../ui/TempNotification'
import { UserPublishServicesModal } from '../services/UserPublishServicesModal'
import { ActivitiesMyModal } from './ActivitiesMyModal'
import { ActivitiesNeibModalMany } from './ActivitiesNeibModalMany'
import { UserHeaderUserChatList } from '../header/UserHeaderChatList'
// import { UserPublishServicesModal } from "./UserPublishServicesModal"

export const UserPublishActivitiesList = ({ event }: { event: USER_LIST_APP }) => {
  const { _id } = useAppSelector((state) => state.user)
  const [searsh, setSearch] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [searchParams] = useSearchParams()
  const [allPageNumber, setAllPageNumber] = useState(1)
  const [pageNumber, setPageNumber] = useState(1)
  const { publishActivities, activities } = useAppSelector((s) => s.activities)
  const [PublishActivities, setPublishActivities] = useState<PublishEventItemInterface[]>(
    [],
  )
  const navigate = useNavigate()
  const [categories, setCategories] = useState<string[]>(['all'])
  useEffect(() => {
    setPublishActivities(publishActivities)
  }, [publishActivities])
  useEffect(() => {
    if (activities.length) {
      let arr: string[] = [...categories]
      activities.map((it) => {
        arr.push(it.name)
      })
      setCategories(arr)
    }
  }, [activities])
  var settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    // afterChange: (n:number) => setCurrentSlide(n)
  }

  const [isOpenSave, setIsOpenSave] = useState(false)

  const [filter, setFilter] = useState('all')

  const [currentIdActivities, setCurrentIdActivities] = useState('')

  const { ref, inView } = useInView({
    threshold: 0,
  })

  const toPublishActivities = (_id: string) => {
    navigate(`/user/activities/publish-activities-item?publishActivitiesId=${_id}`)
  }
  const { role } = useAppSelector((state) => state.user)

  const validatePrivacy = (item: PublishEventItemInterface) => {
    if (role === ROLE.USER) {
      if (item.privacyEvent === PRIVACY.NEIBS) {
        return item.allowList.find((id) => id === _id) || item?.userId?._id === _id
      }
      if (item.privacyEvent === PRIVACY.ONLYME) {
        return item?.userId?._id === _id
      }
      if (item.privacyEvent === PRIVACY.EVERYONE) {
        return true
      }
    } else {
      return true
    }
  }

  useEffect(() => {
    const effectBody = async () => {
      if (USER_LIST_APP.LAST === event) {
        ActivitiesHttp.getTenPublishActivities({ userId: _id }).then((s) =>
          setPublishActivities(s),
        )
      }
      const activitiesId = searchParams.get('id')

      if (inView && allPageNumber >= pageNumber && activitiesId) {
        const res: GetAllPublishActivitiesInterface =
          await ActivitiesHttp.getAllPublishEvent({
            pageNumber,
            activitiesId,
            userId: _id,
          })
        setPublishActivities((s) => [...s, ...res.publishActivitiesWithComments])
        setAllPageNumber(res.allPageNumber)
        setPageNumber((s) => s + 1)
      }
    }
    effectBody()
  }, [inView])

  const updateNotification = async (serviceId: string) => {
    if (PublishActivities) {
      await ActivitiesHttp.updateNotification({
        eventId: serviceId,
        userId: _id,
      })

      setPublishActivities((s) =>
        s.map((p) => {
          if (p._id === serviceId) {
            return {
              ...p,
              isNotificatedEvent: !p.isNotificatedEvent,
            }
          } else {
            return p
          }
        }),
      )
    }
  }

  const updateLikeEvent = async (likeId: string, serviceId: string) => {
    await FeedBackHttp.updateLike({
      likeId,
      userId: _id,
    })
    setPublishActivities((s) =>
      s.map((p) => {
        if (p._id === serviceId) {
          return {
            ...p,
            isLiked: !p.isLiked,
            countLikes: p.isLiked ? p?.countLikes - 1 : p?.countLikes + 1,
          }
        } else {
          return p
        }
      }),
    )
  }

  const copyLink = (url?: string) => {
    if (url) {
      let text = url
      navigator.clipboard.writeText(text)
      setIsOpen(false)
    } else {
      let text = window.location.href
      navigator.clipboard.writeText(text)
      setIsOpen(false)
    }
  }

  const updateMarkEvent = async (eventId: string, isMarked: boolean) => {
    if (isMarked) {
      await ActivitiesHttp.deleteMark({
        eventId,
        marckedUserId: _id,
      })
    } else {
      await ActivitiesHttp.addMark({
        eventId,
        marckedUserId: _id,
      })
      setIsOpenSave(true)
    }

    if (PublishActivities) {
      setPublishActivities((s) =>
        s.map((p) => {
          if (p._id === eventId) {
            return {
              ...p,
              isMarked: !p.isMarked,
            }
          } else {
            return p
          }
        }),
      )
    }
  }

  const [currentSlide, setCurrentSlide] = useState(0)

  const toComments = (index: number, item: string) => {
    setFilter(item)
    setCurrentSlide(index)
  }

  // const getCommentData = async () => {
  //     const eventId = searchParams.get("publishActivitiesId") || ""
  //     const { comments, countComments } = await ActivitiesHttp.getComments({
  //         eventId,
  //         userId: _id,
  //     })
  //     console.log(comments, countComments)
  //     setCountComments(countComments)
  //     setComments(comments)
  // }
  // useEffect(() => {
  //     getCommentData()
  // }, [])

  const [isOpenModal, setIsOpenModal] = useState(false)
  const [currentItem, setCurrentItem] = useState<PublishEventItemInterface>()
  return (
    <>
      <UserHeaderUserChatList
        headerTitle={
          USER_LIST_APP.LAST === event
            ? 'Latest Event'
            : `${PublishActivities?.[0]?.activitiesId?.name || ''}  `
        }
      />
      <div className="user__newsfeed-search">
        <InputSearch
          placeholder={
            <>
              Search<span> NeighborHarbor</span>
            </>
          }
          value={searsh}
          changeValue={setSearch}
        />
      </div>
      <div className="user__event__filter" style={{ marginTop: '40px' }}>
        <div className="activities__filter">
          <Slider {...settings}>
            {categories.map((item, index) => (
              <div
                className={`activities__filter-item ${
                  index === currentSlide && 'activities__filter-item--active'
                }`}
                key={index}
                onClick={() => toComments(index, item.toLocaleLowerCase())}
              >
                <span style={{ textTransform: 'capitalize' }}>{item}</span>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <div className="user__category-publish">
        {PublishActivities.filter((item) => item.title.includes(searsh))
          .filter(
            (item) =>
              item.activitiesId.name.toLowerCase() === filter.toLowerCase() ||
              filter.toLowerCase() === 'all',
          )
          .map((item) => (
            <>
              {validatePrivacy(item) && (
                <div
                  className="user__event-item"
                  onClick={() => toPublishActivities(item._id)}
                >
                  <div className="user__event-item-row1">
                    <div className="user__event-item-row1-text">
                      <h5>{item.title}</h5>
                      <h6>{item.addressLocation}</h6>
                    </div>
                  </div>
                  <div className="explore__event-item-row2" onClick={() => {}}>
                    {item.filesName.length ? (
                      item?.filesName?.map((it) => (
                        <>
                          <div
                            style={{ position: 'relative' }}
                            className="explore__event-item-row2-img"
                            onClick={() => {}}
                          >
                            <div className="explore__event-item-row2-img-last">
                              <div className="explore__event-item-row2-img-last-text">
                                {moment(item?.startDate).format('DD/MM/YYYY')}
                              </div>
                            </div>
                            <div
                              className="user__event-item-row2"
                              onClick={(e) => {
                                e.stopPropagation()
                                setIsOpenModal(true)
                                setCurrentItem(item)
                              }}
                            >
                              <IconServicesAllPoint />
                            </div>
                            <img
                              src={`${apiBaseURL}/uploads/publish_activities/${it}`}
                              alt=""
                            />
                            <div className="explore__event-item-row2-img-last-bottom">
                              <div className="explore__event-item-row2-img-last-bottom-text">
                                {item.activitiesId.name}
                              </div>
                            </div>
                          </div>
                        </>
                      ))
                    ) : (
                      <div
                        style={{ position: 'relative' }}
                        className="explore__event-item-row2-img"
                        onClick={() => toPublishActivities(item._id)}
                      >
                        <div className="explore__event-item-row2-img-last">
                          <div className="explore__event-item-row2-img-last-text">
                            {moment(item?.startDate).format('DD/MM/YYYY')}
                          </div>
                        </div>
                        <div
                          className="user__event-item-row2"
                          onClick={(e) => {
                            e.stopPropagation()
                            setIsOpenModal(true)
                            setCurrentItem(item)
                          }}
                        >
                          <IconServicesAllPoint />
                        </div>
                        <img className="posts__bg" src={`/Images/postsBG.png`} alt="" />
                        <div className="explore__event-item-row2-img-last-bottom">
                          <div className="explore__event-item-row2-img-last-bottom-text">
                            <span
                              className="admin__posts-list-row1-text"
                              style={{ color: 'rgba(21, 16, 77, 1)' }}
                            >
                              {item.activitiesId.name}
                            </span>{' '}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="user__event-item-row3">
                    <h6>{item.text}</h6>
                    {/* <div className="user__event-item-row3-button">
                                         {item.startDate.toString().split(".")[0].split("T")[0].replaceAll("-", "/") + " " }{ <b>{item.startDate.toString().split(".")[0].split("T")[1].split(":")[0] + ":" + item.startDate.toString().split(".")[0].split("T")[1].split(":")[1]}</b> }
                                     </div> */}
                  </div>
                  <div className="user__event-item-row4">
                    <PublicationEventPanel
                      item={item}
                      updateLike={() => updateLikeEvent(item.likes, item._id)}
                      navigateToComments={() => toPublishActivities(item._id)}
                      updateMark={() => updateMarkEvent(item._id, item.isMarked)}
                      updateRepost={copyLink}
                      isOpen={isOpen}
                      setIsOpen={setIsOpen}
                    />
                    {/* {<button onClick={() => updateLikeEvent(item.likeId, item._id)}>
                                     {item.isLiked ? <IconPostsRedLike /> : <IconPostsLike />}
         
                                 </button>}
                                 <div onClick={()=>updateMarkEvent(item._id, item.isMarked)} className={item.isMarked ? "user__event-item-row4-bookmark-active" :"user__event-item-row4-bookmark"}>
                                     <IconProfileInfoBookmark />
                                 </div> */}
                  </div>
                </div>
              )}
            </>
          ))}
      </div>
      <UserPublishServicesModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        categorieName={
          PublishActivities.find((item) => item.activitiesId._id === currentIdActivities)
            ?.activitiesId.name || 'none'
        }
      />
      <div ref={ref} />
      {isOpenModal && <DarkBg />}
      {isOpenModal && currentItem?.userId._id === _id && (
        <ActivitiesMyModal
          setIsOpen={setIsOpenModal}
          isOpen={isOpenModal}
          item={currentItem}
          type="activity"
          updateNotification={() => updateNotification(currentItem._id)}
        />
      )}
      {isOpenModal && currentItem?.userId._id !== _id && currentItem && (
        <ActivitiesNeibModalMany
          setIsOpen={setIsOpenModal}
          isOpen={isOpenModal}
          item={currentItem}
          type="activity"
          updateNotification={() => updateNotification(currentItem._id)}
          setPublishService={setCurrentItem}
        />
      )}
      {isOpenSave && (
        <TempNotification setIsOpen={setIsOpenSave} isOpen={isOpenSave}>
          <IconBookMark />
        </TempNotification>
      )}
    </>
  )
}
