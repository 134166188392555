export const IconCancel = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className || ''}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9925 12.992C12.449 13.5355 11.571 13.5355 11.0275 12.992L7.00009 8.96455L2.97265 12.992C2.42915 13.5355 1.55119 13.5355 1.0077 12.992C0.746748 12.7316 0.600098 12.3781 0.600098 12.0095C0.600098 11.6409 0.746748 11.2874 1.0077 11.027L5.03514 6.99961L1.0077 2.97217C0.746748 2.71181 0.600098 2.35833 0.600098 1.9897C0.600098 1.62108 0.746748 1.26759 1.0077 1.00723C1.55119 0.463736 2.42915 0.463736 2.97265 1.00723L7.00009 5.03467L11.0275 1.00723C11.571 0.463736 12.449 0.463736 12.9925 1.00723C13.536 1.55073 13.536 2.42868 12.9925 2.97217L8.96503 6.99961L12.9925 11.027C13.522 11.5566 13.522 12.4485 12.9925 12.992Z"
        fill="black"
      />
    </svg>
  )
}
