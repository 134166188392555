import { useNavigate } from 'react-router-dom'
import { PRIVACY } from '../../../types/enum'
import { indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { Modal } from '../../ui/Modal'

export const MessegesModalGroup = ({
  setIsOpen,
  isOpen,
}: {
  setIsOpen: (s: boolean) => void
  isOpen: boolean
}) => {
  const navigate = useNavigate()
  const { role } = useAppSelector((state) => state.user)

  const toGroupCreation = (privacy: string) => {
    navigate(`${indexPathByRole(role)}/messeges/grouppage`, { state: { privacy } })
  }

  return (
    <div
      className={`activities__favor-modal  ${
        isOpen ? 'activities__favor-modal--open' : ''
      }`}
    >
      <Modal className="" setIsOpen={(s: boolean) => setIsOpen(s)}>
        <div
          onClick={(e) => {
            e.stopPropagation()
            setIsOpen(false)
          }}
          className="activities__favor-modal-linebody"
        >
          <button className="activities__favor-modal-line" />
        </div>
        <div className="publish__modaladd">
          <h5 className="publish__modaladd-title">Create...</h5>
          <h6 className="publish__modaladd-subtitle">I want to create</h6>
          <div
            className="publish__routemodal-btns"
            style={{ gap: '30px', padding: '20px' }}
          >
            <div onClick={() => toGroupCreation(PRIVACY.EVERYONE)}>
              <div className="publish__routemodal-button messeges__modal-group messeges__modal-group">
                <div className="publish__routemodal-button-img">
                  <img
                    className="messeges__modal-group-item-bg"
                    src="/Images/gradient1.png"
                    alt=""
                  />
                  <img
                    className="messeges__modal-group-item-bg-img"
                    src="/Images/get8.png"
                    alt=""
                  />
                </div>
                <h5 className="publish__routemodal-text">Chat on the map</h5>
              </div>
            </div>
            <div onClick={() => toGroupCreation(PRIVACY.NEIBS)}>
              <div className="publish__routemodal-button messeges__modal-group">
                <div className="publish__routemodal-button-img">
                  <img src="/Images/get6.png" alt="" />
                </div>
                <h5 className="publish__routemodal-text">Group</h5>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}
