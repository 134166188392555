import { useEffect, useState } from 'react'
import { Countries } from '../../../types/enum'
import { InputSearch } from '../../ui/InputSearch'
import { Modal } from '../../ui/Modal'
import { CityType } from './AdminPanelMapPage'
import CA from './ca.json'
import FR from './fr.json'
import UA from './ua.json'

export const CitySearch = ({
  isOpen,
  setIsOpen,
  map,
}: {
  isOpen: boolean
  setIsOpen: (s: boolean) => void
  map: L.Map
}) => {
  const [search, setSearch] = useState('')
  const toSearchType = (item: string) => {
    return item.toLowerCase().replaceAll(' ', '')
  }
  const toCity = (coords: [number, number], zoom: number) => {
    map.flyTo({ lat: coords[0], lng: coords[1] }, zoom)
  }
  const [searchResults, setSearchResults] = useState<CityType[]>()
  useEffect(() => {
    let array: CityType[] = []
    FR.map((fr) => {
      if (toSearchType(fr.city).match(toSearchType(search))) {
        array.push({
          coords: [Number(fr.lat), Number(fr.lng)],
          name: fr.city,
          country: Countries.FR,
        })
      }
    })
    UA.map((fr) => {
      if (toSearchType(fr.city).match(toSearchType(search))) {
        array.push({
          coords: [Number(fr.lat), Number(fr.lng)],
          name: fr.city,
          country: Countries.UA,
        })
      }
    })
    CA.map((fr) => {
      if (toSearchType(fr.capital).match(toSearchType(search))) {
        array.push({
          coords: [Number(fr.latitude), Number(fr.longitude)],
          name: fr.capital,
          country: Countries.CA,
        })
      }
    })
    setSearchResults(array)
  }, [search])

  return (
    <>
      <div
        className={`activities__favor-modal  ${
          isOpen ? 'activities__favor-modal--open' : ''
        }`}
      >
        <Modal className="" setIsOpen={setIsOpen}>
          <div
            onClick={(e) => {
              e.stopPropagation()
              setIsOpen(false)
            }}
            className="activities__favor-modal-linebody"
          >
            <button className="activities__favor-modal-line" />
          </div>
          <>
            <div className="publish__modaladd post__modal explore__searching">
              <InputSearch
                search={true}
                value={search}
                changeValue={setSearch}
                placeholder={
                  <>
                    Search on <b>Map</b>
                  </>
                }
                filter={true}
                bg={'rgba(247, 248, 250, 1)'}
              />
            </div>
            <>
              {searchResults?.slice(0, 4)?.map((item) => (
                <div
                  className="explore__searching-results-list-item"
                  onClick={() => toCity(item.coords, 10)}
                >
                  <div>
                    <h5>{item.name}</h5>
                    <h6>{item.country}</h6>
                  </div>
                </div>
              ))}
            </>
          </>
        </Modal>
      </div>
    </>
  )
}
