import { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import $api from '../../../http'
import { ActivitiesHttp } from '../../../http/activities-http'
import { ROLE } from '../../../types/enum'
import { ChatType, ParticipantType } from '../../../types/types'
import { indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { ChatItem } from '../../general-components/messenger/ChatItem'
import { IconLeftChevrons } from '../../svg/IconChevrons'
import { InputSearch } from '../../ui/InputSearch'
import { success } from '../../ui/LoadSuccess'
import { UserHeader } from '../header/UserHeader'

export const UserServiceShareList = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const { avatarFileName, fullName, role, _id } = useAppSelector((state) => state.user)

  const [chatsList, setChatsList] = useState<ChatType[]>([])
  const [chats, setChats] = useState<ChatType[]>([])
  const [load, setLoad] = useState(true)
  const [isOpen, setIsOpen] = useState(false)
  const [item, setItem] = useState<ChatType>()
  const [search, setSearch] = useState('')

  const props: {
    text: string
  } = location.state

  const inviteUser = async (item: ChatType) => {
    const e = props.text.split('=')[1]
    await ActivitiesHttp.share({ eventId: e })
    let u = ''
    item.participants.map((item) => {
      if (item.userId._id !== _id) {
        u = item.userId._id
      }
    })
    if (u.length) await ActivitiesHttp.addInvite({ eventId: e, ownerId: _id, userId: u })
    success()
  }

  const openChat = (participants: ParticipantType[], item: ChatType) => {
    if (location.state.text.includes('activities')) {
      inviteUser(item)
    }
    navigate(
      `${indexPathByRole(role)}/messeges/chat?chatId=${JSON.stringify(item.chatId)}`,
      {
        state: {
          participants: participants,
          chat: item,
          text: location.state.text,
        },
      },
    )
  }

  useEffect(() => {
    const EffectBody = async () => {
      if (role === ROLE.ADMIN || role === ROLE.USER) {
        if (location.state) {
          $api
            .post('messenger/list-chat', { _id, isSupport: false })
            .then((r: AxiosResponse<ChatType[]>) => {
              const list = r.data.map((item) => ({
                ...item,
                participants: item.participants.filter((p) => p.userId._id !== _id),
              }))
              setChatsList(list)
              setLoad(false)
            })
            .catch(() => {
              setLoad(false)
            })
        } else {
          $api
            .post('messenger/list-chat', { _id, isSupport: false })
            .then((r: AxiosResponse<ChatType[]>) => {
              const list = r.data.map((item) => ({
                ...item,
                participants: item.participants.filter((p) => p.userId._id !== _id),
              }))
              setChatsList(list)
              setLoad(false)
            })
            .catch(() => {
              setLoad(false)
            })
        }
      }
    }
    EffectBody()
  }, [])

  useEffect(() => {
    if (setChats) setChats(chatsList)
  }, [chatsList])

  return (
    <>
      <UserHeader>
        <div className="user__header-messages explore__header">
          <button
            onClick={() => navigate(-1)}
            className="user__header-chatuser user__header-messages-exit"
          >
            <IconLeftChevrons />
          </button>
          <h5 className="user__header-title" style={{ margin: '40px 0' }}>
            Share Service
          </h5>
        </div>
      </UserHeader>
      <div style={{ margin: '20px 0' }}>
        <InputSearch
          value={search}
          changeValue={setSearch}
          placeholder={
            <>
              Search <b>Neighbours</b>
            </>
          }
        />
        <div style={{ margin: '20px 0' }} className="messenger__list">
          {(search
            ? chatsList.filter((item) =>
                item.participants[0].userId.fullName
                  .toLocaleLowerCase()
                  .includes(search.toLocaleLowerCase()),
              )
            : chatsList
          )
            .filter((it) => it.participants.length < 3)
            .map((item, key) => (
              <ChatItem
                openChat={openChat}
                item={item}
                setIsOpen={setIsOpen}
                setItem={setItem}
              />
            ))}
        </div>
      </div>
    </>
  )
}
