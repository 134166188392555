export const IconDrag = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2636 14.7592L9.57702 17.4457C9.25906 17.7637 8.7436 17.7637 8.42565 17.4457L5.7391 14.7592C5.22622 14.2463 5.58947 13.3694 6.31481 13.3694H8.05036L8.05033 9.94984H4.63078V11.6854C4.63078 12.4107 3.75384 12.774 3.24093 12.2611L0.554386 9.57455C0.236429 9.2566 0.236429 8.74111 0.554386 8.42319L3.24093 5.73665C3.75381 5.22378 4.63078 5.58702 4.63078 6.31235V8.05017H8.05033V4.63064H6.31246C5.58713 4.63064 5.22388 3.75371 5.73676 3.2408L8.42331 0.554263C8.74126 0.236307 9.25672 0.236307 9.57468 0.554263L12.2612 3.2408C12.7741 3.75367 12.4109 4.63064 11.6855 4.63064H9.94996V8.05017H13.3695V6.31462C13.3695 5.58929 14.2464 5.22605 14.7593 5.73892L17.4459 8.42546C17.7638 8.74341 17.7638 9.2589 17.4459 9.57683L14.7593 12.2634C14.2464 12.7762 13.3695 12.413 13.3695 11.6877V9.94984H9.95V13.3694H11.6879C12.4132 13.3694 12.7764 14.2463 12.2636 14.7592Z"
        fill="#C3CAD9"
      />
    </svg>
  )
}
