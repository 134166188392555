import { Rating } from '@mui/material'
import moment from 'moment'
import { useState, useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { ServiceHttp } from '../../../http/service-http'
import { PublishServiceOneItemInterface, Review } from '../../../types/types'
import { apiBaseURL } from '../../route/indexPathByRole'
import { IconStars } from '../../svg/IconFavor'
import { IconProfileInfoBookmark } from '../../svg/IconProfileInfo'
import { IconServicesAllPoint } from '../../svg/IconServicesAll'
import { IconStar } from '../../svg/IconStar'
import { IconStarEmpty } from '../../svg/IconStarEmpty'
import { ButtonLeftChevron } from '../../ui/ButtonLeftChevron'
import { Loader } from '../../ui/Loader'
import { PostSlick } from '../../ui/PostSlick'
import { UserServiceMap } from '../../user-components/services/UserServiceMap'

export const UserPublishServicesItemMedia = () => {
  const [searchParams] = useSearchParams()
  const [publishService, setPublishService] = useState<PublishServiceOneItemInterface>()
  const navigate = useNavigate()

  const [reviews, setReviews] = useState<Review[]>([])
  const [Rate, setRate] = useState<number>(0)

  const getPublishServiceData = async () => {
    const publishServiceId = searchParams.get('publishServiceId') || ''
    const publishService = await ServiceHttp.getOnePublsihServiceMedia({
      publishServiceId,
    })
    if (publishService) {
      console.log(publishService)
      setPublishService(publishService)
      const res = await ServiceHttp.getPublishServiceReview({
        serviceId: publishService._id,
      })
      let rate: number[] = []
      setRate(rate.reduce((partialSum, a) => partialSum + a, 0) / rate.length)
      setReviews(res)
    }
  }

  useEffect(() => {
    const bodyEffect = async () => {
      await getPublishServiceData()
    }
    bodyEffect()
  }, [])
  const returnDate = (d: Date) => {
    const date = moment(d)
    return `${date.format('MMM D,')} ${date.hour()}:${date.minute()}`
  }
  return publishService ? (
    <div className="commenst user__publish-service">
      <div className="commenst__slick">
        <div className="commenst__back">
          <ButtonLeftChevron onClick={() => navigate(-1)} />
        </div>
        <PostSlick list={publishService?.filesName}>
          {publishService.filesName.length > 0 ? (
            publishService?.filesName?.map((it) => (
              <div className="commenst__slick-img">
                <img src={`${apiBaseURL}/uploads/publish_services/${it}`} alt="" />
              </div>
            ))
          ) : (
            <div className="commenst__slick-img">
              <img className="posts__bg" src={`/Images/postsBG.png`} alt="" />
            </div>
          )}
        </PostSlick>
        <div className="commenst__slick-bookmark">
          <button
            className={`ui-button-back-route ${
              publishService?.isMarked && 'admin__posts-list-row4-repost--active'
            }`}
          >
            <IconProfileInfoBookmark />
          </button>
        </div>
        {Rate ? (
          <div className="user__publish-service-imgrating">
            <b>
              {Rate ? (
                <>
                  <IconStars />
                  {Rate}
                </>
              ) : (
                ''
              )}
            </b>
            {reviews.length ? `(${reviews.length})` : ''}
          </div>
        ) : (
          ''
        )}
      </div>
      <div className="commenst__content">
        <div className="commenst__user">
          <div className="commenst__user-img">
            {publishService.userId.avatarFileName ? (
              <img
                src={`${apiBaseURL}/uploads/avatar/${publishService.userId.avatarFileName}`}
              />
            ) : (
              <div
                style={{
                  backgroundColor: 'rgba(41, 52, 65, 1)',
                  borderRadius: '100%',
                  width: '100%',
                  height: '100%',
                  color: '#fff',
                  textAlign: 'center',
                  position: 'relative',
                }}
              >
                <span style={{ position: 'absolute', left: '13px', top: '12px' }}>
                  {publishService.userId.fullName.slice(0, 2)}
                </span>
              </div>
            )}
          </div>
          <div>
            <p className="commenst__user-info-name">{publishService.userId.fullName}</p>
            <p className="commenst__user-info-time">
              {returnDate(publishService.createdPublishServiceDate)}
            </p>
          </div>
        </div>
        <h5 className="commenst-title">{publishService.title}</h5>
        <p className="commenst-subtitle">{publishService.text}</p>
        <div className="commenst__viewmap">
          <div className="commenst__map">
            <div className="user__publish-service-location">
              <div className="user__publish-service-location-img">
                <img
                  src={`${apiBaseURL}/uploads/categories/${publishService?.categoryId?.fileName}`}
                  alt=""
                />
              </div>

              <div className="user__publish-service-location-text">
                <h5 className="user__publish-service-location-cat">
                  {publishService?.categoryId?.name}
                </h5>
                <h6 className="user__publish-service-location-sub">
                  {publishService?.subCategoryId?.name}
                </h6>
              </div>
            </div>
            <UserServiceMap type="publish_services" item={publishService} />
          </div>
        </div>
        <div className="user__services-rev">
          <h5 className="commenst-title user__publish-service-review-title">
            {reviews.length ? (
              <>
                <IconStars />
                {Rate}{' '}
                <span>
                  ({reviews.length} {reviews.length > 1 ? 'reviews' : 'review'})
                </span>
              </>
            ) : (
              ''
            )}
          </h5>
          <div className="reviews__list">
            {reviews.map((item) => (
              <div className="reviews__list-item">
                <div className="reviews__list-item-row1">
                  <div className="reviews__list-item-row1-user">
                    <div className="commenst__user-img">
                      {item.userId.avatarFileName ? (
                        <img
                          src={`${apiBaseURL}/uploads/avatar/${item.userId.avatarFileName}`}
                        />
                      ) : (
                        <div
                          style={{
                            backgroundColor: 'rgba(41, 52, 65, 1)',
                            borderRadius: '100%',
                            width: '100%',
                            height: '100%',
                            color: '#fff',
                            textAlign: 'center',
                            position: 'relative',
                          }}
                        >
                          <span
                            style={{ position: 'absolute', left: '13px', top: '12px' }}
                          >
                            {item.userId.fullName.slice(0, 2)}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="reviews__list-item-row1-user-name">
                      <p className="commenst__user-info-name">{item.userId.fullName}</p>
                      <Rating
                        name="reviews__list-item-row1-user-name-stars"
                        value={item.rate}
                        precision={0.5}
                        emptyIcon={<IconStarEmpty />}
                        icon={<IconStar />}
                        size="small"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="reviews__list-item-row1-date">
                    <p className="commenst__user-info-time">
                      {returnDate(moment(item.dateCreated).toDate())}
                    </p>
                  </div>
                </div>
                <div className="reviews__list-item-row2">
                  <h6 className="reviews__list-item-row2-text">{item.text}</h6>
                </div>
              </div>
            ))}
          </div>
          <div
            className="user__publish-service-phone"
            style={{
              position: 'static',
              width: '100%',
              padding: '13px 0',
              marginBottom: '15px',
            }}
          >
            <a href={`tel:${publishService.phone}`}>
              <div className="user__publish-service-phone-item-call">Call / SMS</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Loader />
  )
}
