export const IconFlag = () => {
  return (
    <svg
      style={{ margin: 'auto 0' }}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0V16H2V0H0ZM4 0V8H8V10H16L12 6.06L16 2H10V0L4 0Z" fill="#0F1419" />
    </svg>
  )
}
