import { LatLng, circle } from 'leaflet'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useLocation, useNavigate } from 'react-router-dom'
import { FeedBackHttp } from '../../../http/feedback-http'
import { PublishPostHttp } from '../../../http/publish-post-http'
import { getPostPin } from '../../../redux/posts'
import { NOTIFICATION_POST, PRIVACY, ROLE } from '../../../types/enum'
import { PostUserInterface } from '../../../types/types'
import { apiBaseURL, indexPathByRole } from '../../route/indexPathByRole'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { IconPostModalPin } from '../../svg/IconPostModal'
import { IconServicesAllPoint } from '../../svg/IconServicesAll'
import { DarkBg } from '../../ui/DarkBg'
import { InputSearch } from '../../ui/InputSearch'
import { Loader } from '../../ui/Loader'
import { PostSlick } from '../../ui/PostSlick'
import { GetStarted } from '../../user-components/newsfeed/GetStarted'
import { PublishModalRoute } from '../publication/PublishModalRoute'
import { PublicationPostMyModal } from './PublicationPostMyModal'
import { PublicationPostNeibModal } from './PublicationPostNeibModal'
import { PublicationPostsPanel } from './PublicationPostsPanel'
import { ServicesFavorListModal } from '../../admin-components/services/ServicesFavorListModal'
import { PostsModal } from '../../admin-components/posts/PostsModal'

export const PublicationPosts = ({
  isOpenPublish,
  isMarkedOptions,
  setIsOpenPublish,
  userCoords,
  pageNumber,
  setPageNumber,
}: {
  isOpenPublish: boolean
  setIsOpenPublish?: (s: boolean) => void
  isMarkedOptions?: boolean
  userCoords?: LatLng[] | LatLng[][] | LatLng[][][] | null
  pageNumber: number
  setPageNumber: (s: number) => void
}) => {
  const [myModalOpen, setMyModalOpen] = useState(false)
  const [neibModalOpen, setNeibModalOpen] = useState(false)

  const { _id, role } = useAppSelector((state) => state.user)
  const { coordinates, radiusSize } = useAppSelector((state) => state.profile)
  const { polygonCoords } = useAppSelector((state) => state.user)
  const [searsh, setSearch] = useState('')
  const [allPageNumber, setAllPageNumber] = useState(1)
  const [posts, setPosts] = useState<PostUserInterface[]>([])
  const [isLoad, setIsLoad] = useState(false)
  const { ref, inView } = useInView({
    threshold: 0,
  })
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [currnetItem, setCurrentItem] = useState<PostUserInterface | null>(null)

  const { publishAllPosts, publishPinPosts } = useAppSelector((s) => s.posts)

  useEffect(() => {
    setAllPageNumber(publishAllPosts.allPageNumber)
  }, [pageNumber])

  useEffect(() => {
    dispatch(getPostPin({ userId: _id }))
  }, [])

  const getPinPosts = () => {
    setPosts((s) => [
      ...s.filter((it) => publishPinPosts.find((item) => item.repostId === it._id)),
      ...s.filter((it) => publishPinPosts.find((item) => item.repostId !== it._id)),
    ])
  }
  const deletePost = (item: PostUserInterface) => {
    const arr = posts.filter((it) => it !== item)
    setPosts(arr)
  }

  const getAllPostReload = () => {
    setIsLoad(true)
    if (role === ROLE.REGIONAL_ADMIN || role === ROLE.LOCAL_ADMIN) {
      if (role === ROLE.REGIONAL_ADMIN) {
        setPosts([])
        if (polygonCoords) {
          setPosts(
            publishAllPosts.posts
              .slice(0, pageNumber * 10)
              .filter(
                (it) =>
                  polygonCoords &&
                  inside([it.coordinates.lat, it.coordinates.lng], polygonCoords),
              ),
          )
        } else {
          setPosts(publishAllPosts.posts.slice(0, pageNumber * 10))
        }
      } else {
        setPosts([])
        setPosts((s) => [
          ...s,
          ...publishAllPosts.posts
            .slice(0, pageNumber * 10)
            .filter(
              (it) =>
                polygonCoords &&
                inside([it.coordinates.lat, it.coordinates.lng], polygonCoords),
            ),
        ])
      }
    } else {
      if (role === ROLE.USER) {
        if (userCoords) {
          if (userCoords?.length) {
            setPosts(() => [
              ...publishAllPosts.posts
                .slice(0, pageNumber * 10)
                .filter(
                  (item) =>
                    inside([item.coordinates.lat, item.coordinates.lng], userCoords) ||
                    item.userId._id === _id,
                ),
            ])
          }
        }
        if (radiusSize) {
          setPosts(() => [
            ...publishAllPosts.posts
              .slice(0, pageNumber * 10)
              .filter(
                (item) =>
                  insideCircle([item.coordinates.lat, item.coordinates.lng], radiusSize, [
                    coordinates.lat,
                    coordinates.lng,
                  ]) || item.userId._id === _id,
              ),
          ])
        }
      } else {
        setPosts((s) => [...s, ...publishAllPosts.posts.slice(0, pageNumber * 10)])
      }
    }
    setPageNumber(pageNumber + 1)
    setIsLoad(false)
  }

  useEffect(() => {
    if (publishAllPosts?.posts?.length) {
      getAllPostReload()
    }
  }, [publishAllPosts])

  useEffect(() => {
    if (inView && allPageNumber >= pageNumber) {
      if (role === ROLE.REGIONAL_ADMIN || role === ROLE.LOCAL_ADMIN) {
        setIsLoad(true)
        if (role === ROLE.REGIONAL_ADMIN) {
          setIsLoad(true)
          setPosts([])
          setPosts((s) => [
            ...s,
            ...publishAllPosts.posts
              .slice(0, pageNumber * 10)
              .filter(
                (it) =>
                  polygonCoords &&
                  inside([it.coordinates.lat, it.coordinates.lng], polygonCoords),
              ),
          ])
          setIsLoad(false)
        } else {
          setIsLoad(true)
          setPosts([])
          setPosts((s) => [
            ...s,
            ...publishAllPosts.posts
              .slice(0, pageNumber * 10)
              .filter(
                (it) =>
                  polygonCoords &&
                  inside([it.coordinates.lat, it.coordinates.lng], polygonCoords),
              ),
          ])
          setIsLoad(false)
        }
        setPageNumber(pageNumber + 1)
        setIsLoad(false)
      } else {
        if (role === ROLE.USER) {
          setIsLoad(true)
          if (userCoords) {
            if (userCoords?.length) {
              setPosts((s) => [
                ...s,
                ...publishAllPosts.posts
                  .slice(0, pageNumber * 10)
                  .filter(
                    (item) =>
                      inside([item.coordinates.lat, item.coordinates.lng], userCoords) ||
                      item.userId._id === _id,
                  ),
              ])
            }
            if (radiusSize) {
              setPosts((s) => [
                ...s,
                ...publishAllPosts.posts
                  .slice(0, pageNumber * 10)
                  .filter(
                    (item) =>
                      insideCircle(
                        [item.coordinates.lat, item.coordinates.lng],
                        radiusSize,
                        [coordinates.lat, coordinates.lng],
                      ) || item.userId._id === _id,
                  ),
              ])
            }
          }

          setPageNumber(pageNumber + 1)
          setIsLoad(false)
        } else {
          setIsLoad(true)

          setPosts(publishAllPosts.posts.slice(0, pageNumber * 10))
          setPageNumber(pageNumber + 1)
          setIsLoad(false)
        }
      }
    }
  }, [inView])

  const updateLike = async (likeId: string, postId: string) => {
    await FeedBackHttp.updateLike({
      likeId,
      userId: _id,
    })
    setPosts((s) =>
      s.map((p) => {
        if (p._id === postId) {
          return {
            ...p,
            isLiked: !p.isLiked,
            likes: p.isLiked ? p.likes - 1 : p.likes + 1,
          }
        } else {
          return p
        }
      }),
    )
  }

  const navigateToComments = (postId: string) => {
    navigate(`${indexPathByRole(role)}/comments?postId=${postId}`)
  }

  const toProfileInfo = (prop: {
    _id: string
    email: string
    role: string
    fullName: string
    avatarFileName: string
  }) => {
    navigate('/profileinfo', {
      state: prop,
    })
  }

  const updateRepost = async ({
    postId,
    isReposted,
  }: {
    postId: string
    isReposted: boolean
  }) => {
    await PublishPostHttp.updateRepost({
      postId,
      repostedUserId: _id,
      shareList: [],
    })

    getAllPostReload()
  }

  const updateMark = async (postId: string, isMarked: boolean) => {
    if (isMarked) {
      await PublishPostHttp.deleteMark({
        postId,
        marckedUserId: _id,
      })
    } else {
      await PublishPostHttp.addMark({
        postId,
        marckedUserId: _id,
      })
      setIsOpen(true)
    }

    setPosts((s) =>
      s.map((p) => {
        if (p._id === postId) {
          const newItem = {
            ...p,
            isMarked: !p.isMarked,
          }
          setCurrentItem(newItem)
          return newItem
        } else {
          return p
        }
      }),
    )
  }

  const updateNotification = async (
    postId: string,
    typeNotification: NOTIFICATION_POST,
  ) => {
    await PublishPostHttp.updateNotification({
      postId: postId,
      userId: _id,
      typeNotification,
    })

    setPosts((s) =>
      s.map((p) => {
        if (p._id === postId) {
          const newItem = {
            ...p,
            [NOTIFICATION_POST.COMMENT === typeNotification
              ? 'isNotificatedComment'
              : 'isNotificatedPost']:
              NOTIFICATION_POST.COMMENT === typeNotification
                ? !p.isNotificatedComment
                : !p.isNotificatedPost,
          }
          setCurrentItem(newItem)
          return newItem
        } else {
          return p
        }
      }),
    )
  }

  const validatePrivacy = (item: PostUserInterface) => {
    if (role === ROLE.USER) {
      if (item.privacyPost === PRIVACY.NEIBS && _id) {
        console.log(item.allowList)
        return item?.allowList.map((id) => id === _id) || item?.userId?._id === _id
      }
      if (item.privacyPost === PRIVACY.ONLYME && _id) {
        return item.userId?._id === _id
      }
      if (item.privacyPost === PRIVACY.EVERYONE) {
        return true
      }
    } else {
      return true
    }
  }

  const updatePin = async (repostId: string) => {
    await PublishPostHttp.updatePin({
      repostId: repostId,
      userId: _id,
    })

    setPosts((s) =>
      s.map((p) => {
        if (p.repostId === repostId) {
          const newItem = {
            ...p,
            isPined: !p.isPined,
          }
          setCurrentItem(newItem)
          return newItem
        } else {
          return p
        }
      }),
    )
  }

  const hidePost = async (body: { hideUserId?: string; hideRepostId?: string }) => {
    await PublishPostHttp.hidePost({
      ownerId: _id,
      ...body,
    })

    setPosts((s) =>
      s.filter(
        (item) =>
          !(
            item?.repostId === body?.hideRepostId || item?.userId._id === body?.hideUserId
          ),
      ),
    )

    setNeibModalOpen(false)
  }

  const openModal = (item: PostUserInterface) => {
    setCurrentItem(item)
    if (item.userId._id === _id) {
      setMyModalOpen(true)
    } else {
      setNeibModalOpen(true)
    }
  }
  const [content, setContent] = useState<{ postId: string; content: boolean }[]>([])
  const updateContent = (item: PostUserInterface, val: boolean) => {
    setContent([...content, { postId: item._id, content: val }])
  }

  function insideCircle(
    point: [number, number],
    radius: any,
    circleCenter: [number, number],
  ) {
    const circleEl = circle({ lat: circleCenter[0], lng: circleCenter[1] })
    return circleEl.getLatLng().distanceTo(point) < radius * 1000
  }
  function inside(point: [number, number], vs: any[]) {
    let x = point[0],
      y = point[1]
    let inside = false
    for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      let xi = vs[i].lat,
        yi = vs[i].lng
      let xj = vs[j].lat,
        yj = vs[j].lng

      let intersect = yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi
      if (intersect) inside = !inside
    }

    return inside
  }
  const [isOpenSave, setIsOpenSave] = useState(true)
  return (
    <div
      className={`user__newsfeed ${
        location.pathname === '/admin/posts' && 'user__newsfeed--admin'
      }`}
    >
      {!isMarkedOptions && role === ROLE.USER && <GetStarted />}

      <div
        className={`${
          !(location.pathname === '/admin/posts') && 'user__newsfeed-search'
        }`}
      >
        {role === ROLE.USER && (
          <InputSearch
            placeholder={
              <>
                Search<span> Post</span>
              </>
            }
            value={searsh}
            changeValue={setSearch}
            // onClickFilter={()=>setIsOpenFilter(true)}
            onClick={() => navigate(`${indexPathByRole(role)}/search`)}
          />
        )}
      </div>
      <h5 className="user__newsfeed-title">Newsfeed</h5>
      <div className="admin__posts-list">
        {posts.map((item) => (
          <>
            {validatePrivacy(item) &&
            item.repostedUserId &&
            item?.shareList?.find(
              (it) => it.friendId._id === _id || item.repostedUserId?._id === _id,
            ) ? (
              <div className="admin__posts-list-item" key={item?._id}>
                <div className="admin__posts-list-repost">
                  <div className="admin__posts-list-repost-profileInfo">
                    <div
                      className="admin__posts-list-row1-img"
                      onClick={() =>
                        toProfileInfo({
                          _id: item.repostedUserId?._id || '',
                          email: '',
                          role: ROLE.USER,
                          fullName: item.repostedUserId?.fullName || '',
                          avatarFileName: item?.repostedUserId?.avatarFileName || '',
                        })
                      }
                    >
                      {item?.repostedUserId?.avatarFileName ? (
                        <img
                          src={`${apiBaseURL}/uploads/avatar/${item?.repostedUserId?.avatarFileName}`}
                        />
                      ) : (
                        <div
                          style={{
                            backgroundColor: 'rgba(41, 52, 65, 1)',
                            borderRadius: '100%',
                            width: '100%',
                            height: '100%',
                            color: '#fff',
                            textAlign: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          <span style={{ margin: 'auto', fontSize: '14px' }}>
                            {item?.repostedUserId?.fullName.slice(0, 2)}
                          </span>
                        </div>
                      )}
                    </div>
                    <div onClick={() => navigateToComments(item._id)}>
                      <div className="admin__posts-list-row1-name">
                        {item.repostedUserId?.fullName}
                      </div>
                      <div>
                        <span className="admin__posts-list-row1-text">
                          {moment(moment.utc(item?.createdRepostDate))
                            .local()
                            .format('DD MMM YYYY HH:mm')}
                        </span>
                      </div>
                    </div>
                  </div>
                  <button onClick={() => openModal(item)}>
                    <IconServicesAllPoint />
                  </button>
                </div>
                <div className="reposted">
                  <div className="admin__posts-list-row1" style={{ padding: '10px' }}>
                    <div
                      className="admin__posts-list-row1-img"
                      onClick={() =>
                        toProfileInfo({
                          _id: item.userId?._id,
                          email: '',
                          role: ROLE.USER,
                          fullName: item.userId?.fullName,
                          avatarFileName: item?.userId?.avatarFileName || '',
                        })
                      }
                    >
                      {item?.userId?.avatarFileName ? (
                        <img
                          src={`${apiBaseURL}/uploads/avatar/${item?.userId?.avatarFileName}`}
                        />
                      ) : (
                        <div
                          style={{
                            backgroundColor: 'rgba(41, 52, 65, 1)',
                            borderRadius: '100%',
                            width: '100%',
                            height: '100%',
                            color: '#fff',
                            textAlign: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          <span style={{ margin: 'auto', fontSize: '14px' }}>
                            {item?.userId?.fullName.slice(0, 2)}
                          </span>
                        </div>
                      )}
                    </div>
                    <div onClick={() => navigateToComments(item._id)}>
                      <div className="admin__posts-list-row1-name">
                        {item.userId?.fullName}
                        {item.isPinedPostFlag && (
                          <span className="admin__posts-list-row1-pin">
                            <IconPostModalPin />
                          </span>
                        )}
                      </div>
                      <div>
                        <span className="admin__posts-list-row1-text">
                          {moment(moment.utc(item?.createdPostDate))
                            .local()
                            .format('DD MMM YYYY HH:mm')}
                        </span>{' '}
                        <span className="admin__posts-list-row1-text admin__posts-list-row1-textunder">
                          {item?.addressLocation}
                        </span>
                      </div>
                    </div>
                    {/* <button onClick={() => openModal(item)}>
                                    <IconServicesAllPoint />
                                </button> */}
                  </div>
                  {item.filesName.length > 0 ? (
                    <div
                      className="admin__posts-list-row2"
                      style={{ padding: '10px' }}
                      onClick={() => navigateToComments(item._id)}
                    >
                      <PostSlick list={item?.filesName}>
                        {item?.filesName?.map((it) => (
                          <div
                            className="admin__posts-list-row2-img"
                            onClick={() => navigateToComments(item._id)}
                          >
                            <img
                              src={`${apiBaseURL}/uploads/publish_post/${it}`}
                              alt=""
                            />
                          </div>
                        ))}
                      </PostSlick>
                    </div>
                  ) : (
                    ''
                  )}
                  <div
                    style={{ padding: '10px' }}
                    className="admin__posts-list-row3"
                    onClick={() => navigateToComments(item._id)}
                  >
                    <h5>{item.title}</h5>
                    <h6>{item.text}</h6>
                  </div>
                </div>
                <PublicationPostsPanel
                  item={item}
                  updateLike={updateLike}
                  navigateToComments={navigateToComments}
                  updateMark={updateMark}
                  updateRepost={updateRepost}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                />
                {/* <PublicationPostsPanel
                                item={item}
                                updateLike={updateLike}
                                navigateToComments={navigateToComments}
                                updateMark={updateMark}
                                updateRepost={updateRepost}
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                            /> */}
              </div>
            ) : (
              <>
                {!item?.shareList?.length && validatePrivacy(item) && (
                  <>
                    <div className="admin__posts-list-item" key={item?._id}>
                      <div className="admin__posts-list-row1" style={{ padding: '10px' }}>
                        <div
                          className="admin__posts-list-row1-img"
                          onClick={() =>
                            toProfileInfo({
                              _id: item.userId?._id,
                              email: '',
                              role: ROLE.USER,
                              fullName: item.userId?.fullName,
                              avatarFileName: item?.userId?.avatarFileName || '',
                            })
                          }
                        >
                          {item?.userId?.avatarFileName ? (
                            <img
                              src={`${apiBaseURL}/uploads/avatar/${item?.userId?.avatarFileName}`}
                            />
                          ) : (
                            <div
                              style={{
                                backgroundColor: 'rgba(41, 52, 65, 1)',
                                borderRadius: '100%',
                                width: '100%',
                                height: '100%',
                                color: '#fff',
                                textAlign: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                              }}
                            >
                              <span style={{ margin: 'auto', fontSize: '14px' }}>
                                {item?.userId?.fullName.slice(0, 2)}
                              </span>
                            </div>
                          )}
                        </div>
                        <div onClick={() => navigateToComments(item._id)}>
                          <div className="admin__posts-list-row1-name">
                            {item.userId?.fullName}
                            {item.isPinedPostFlag && (
                              <span className="admin__posts-list-row1-pin">
                                <IconPostModalPin />
                              </span>
                            )}
                          </div>
                          <div>
                            <span className="admin__posts-list-row1-text">
                              {moment(moment.utc(item?.createdPostDate))
                                .local()
                                .format('DD MMM YYYY HH:mm')}
                            </span>{' '}
                            <span className="admin__posts-list-row1-text admin__posts-list-row1-textunder">
                              {item?.addressLocation}
                            </span>
                          </div>
                        </div>
                        <button onClick={() => openModal(item)}>
                          <IconServicesAllPoint />
                        </button>
                      </div>
                      {item.filesName.length > 0 ? (
                        <div
                          className="admin__posts-list-row2"
                          style={{ padding: '10px' }}
                          onClick={() => navigateToComments(item._id)}
                        >
                          <PostSlick list={item?.filesName}>
                            {item?.filesName?.map((it) => (
                              <div
                                className="admin__posts-list-row2-img"
                                onClick={() => navigateToComments(item._id)}
                              >
                                <img
                                  src={`${apiBaseURL}/uploads/publish_post/${it}`}
                                  alt=""
                                />
                              </div>
                            ))}
                          </PostSlick>
                        </div>
                      ) : (
                        ''
                      )}
                      <div
                        style={{ padding: '10px' }}
                        className="admin__posts-list-row3"
                        onClick={() => navigateToComments(item._id)}
                      >
                        <h5>{item.title}</h5>
                        <h6>{item.text}</h6>
                      </div>
                      <PublicationPostsPanel
                        item={item}
                        updateLike={updateLike}
                        navigateToComments={navigateToComments}
                        updateMark={updateMark}
                        updateRepost={updateRepost}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </>
        ))}
      </div>
      <div ref={ref} />
      {isLoad && <Loader />}
      {myModalOpen || (neibModalOpen && <DarkBg />)}
      {isOpenPublish && <DarkBg />}
      {isOpenPublish && setIsOpenPublish && (
        <PublishModalRoute isOpen={isOpenPublish} setIsOpen={setIsOpenPublish} />
      )}
      <PublicationPostMyModal
        deletePostList={deletePost}
        item={currnetItem}
        isOpen={myModalOpen}
        setIsOpen={setMyModalOpen}
        updateMark={updateMark}
        getAllPostReload={getAllPostReload}
        updateNotification={updateNotification}
        updatePin={updatePin}
      />
      {role === ROLE.USER ? (
        <PublicationPostNeibModal
          item={currnetItem}
          isOpen={neibModalOpen}
          setIsOpen={setNeibModalOpen}
          updateMark={updateMark}
          updateNotification={updateNotification}
          toProfileInfo={toProfileInfo}
          hidePost={hidePost}
          updateContent={updateContent}
          content={content}
        />
      ) : (
        currnetItem && (
          <PostsModal
            item={currnetItem}
            setIsOpen={setNeibModalOpen}
            isOpen={neibModalOpen}
          />
        )
      )}
    </div>
  )
}
