import { useEffect, useState } from 'react'
import { StatisticHttp } from '../../../http/statistic-http'
import { ROLE } from '../../../types/enum'
import { useAppSelector } from '../../../utils/hooks'
import ServicesItemView from '../services/ServicesItemView'

export const AdminPanelShortcuts = ({
  setLoad,
  countL,
}: {
  setLoad: (s: boolean) => void
  countL: {
    countServices: number
    countActivities: number
    countUsers: number
    countMessages: number
  }
}) => {
  const { role, polygonCoords, _id, fullName } = useAppSelector((state) => state.user)
  const [count, setCount] = useState({
    countServices: 0,
    countActivities: 0,
    countUsers: 0,
    countMessages: 0,
    countAds: 0,
    countPosts: 0,
  })

  useEffect(() => {
    const effectBody = async () => {
      if (role === ROLE.ADMIN) {
        const res = await StatisticHttp.getShortcuts()
        setCount(res)
      }
    }

    effectBody()
  }, [])

  return (
    <div className="admin__panel-short">
      <div className="admin__panel-title"> Shortcuts </div>
      {role === ROLE.ADMIN && (
        <div className="services__all">
          <ServicesItemView
            name={'Users'}
            nextListLink={'/admin/users'}
            numberView={count.countUsers}
            setIsOpen={() => {}}
          />
          <ServicesItemView
            name={'Services'}
            nextListLink={'/admin/services'}
            numberView={count.countServices}
            setIsOpen={() => {}}
          />
          <ServicesItemView
            name={'Publications'}
            nextListLink={'/admin/posts'}
            numberView={count.countPosts}
            setIsOpen={() => {}}
          />
          <ServicesItemView
            name={'Events'}
            nextListLink={'/admin/activities'}
            numberView={count.countActivities}
            setIsOpen={() => {}}
          />
          <ServicesItemView
            name={'Adv'}
            nextListLink={'/admin/advertisement'}
            numberView={count.countAds}
            setIsOpen={() => {}}
          />
          <ServicesItemView
            name={'Messeges'}
            nextListLink={'/admin/messenger'}
            numberView={count.countMessages}
            setIsOpen={() => {}}
          />
        </div>
      )}
      {role !== ROLE.ADMIN && (
        <div className="services__all">
          <ServicesItemView
            name={'Users'}
            nextListLink={'/admin/users'}
            numberView={countL.countUsers}
            setIsOpen={() => {}}
          />
          <ServicesItemView
            name={'Services'}
            nextListLink={'/admin/services'}
            numberView={countL.countServices}
            setIsOpen={() => {}}
          />
          <ServicesItemView
            name={'Events'}
            nextListLink={'/admin/activities'}
            numberView={countL.countActivities}
            setIsOpen={() => {}}
          />
          <ServicesItemView
            name={'Messeges'}
            nextListLink={'/admin/messeges'}
            numberView={countL.countMessages}
            setIsOpen={() => {}}
          />
        </div>
      )}
    </div>
  )
}
