import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ActivitiesHttp } from '../../../http/activities-http'
import {
  NotificationHttp,
  NotificationListInterface,
} from '../../../http/notification-http'
import { PublishPostHttp } from '../../../http/publish-post-http'
import { ServiceHttp } from '../../../http/service-http'
import { NOTIFICATION_EVENT } from '../../../types/enum'
import { PublishServiceItemInterface } from '../../../types/types'
import { apiBaseURL } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { notificationTypeTitle } from '../../../utils/titles'
import { IconArrowRight } from '../../svg/IconArrow'
import dayjs from 'dayjs'

export const UserNotificationList = () => {
  const navigate = useNavigate()
  const [notificationList, setNotificationList] = useState<NotificationListInterface[]>(
    [],
  )
  const { _id } = useAppSelector((state) => state.user)
  useEffect(() => {
    NotificationHttp.getNotificationList({ userId: _id }).then((s) =>
      setNotificationList(s),
    )
  }, [])

  const navigateTo = async (item: NotificationListInterface) => {
    switch (item.event) {
      case NOTIFICATION_EVENT.NOTIFICATION_SERVICE:
        const res: PublishServiceItemInterface[] =
          await ServiceHttp.getUserPublishService({ userId: item.ownerId._id })
        const s = res.find(
          (it) => item.title === it.text && item.fileName === it.filesName[0],
        )
        navigate(
          `/user/${notificationTypeTitle(item.event).link}/publish-service?publishServiceId=${s?._id}`,
        )
        break
      case NOTIFICATION_EVENT.NOTIFICATION_NEWS:
        const posts = await PublishPostHttp.getPostsByUser({ userId: item.ownerId._id })

        posts.find((s) => {
          console.log(
            s.createdPostDate.toString().slice(0, 17),
            item.dateNotificationCreated.toString().slice(0, 17),
          )
          if (
            s.createdPostDate.toString().slice(0, 18) ==
            item.dateNotificationCreated.toString().slice(0, 18)
          ) {
            navigate(`/user/comments?postId=${s._id}`)
          }
        })
        break
      case NOTIFICATION_EVENT.NOTIFICATION_ACTIVITIES:
        const events = await ActivitiesHttp.getUserPublishEvent({
          userId: item.ownerId._id,
        })
        const e = events.find(
          (it) => item.title === it.text && item.fileName === it.filesName[0],
        )
        navigate(
          `/user/${notificationTypeTitle(item.event).link}/publish-activities-item?publishActivitiesId=${e?._id}`,
        )
        break
      // case NOTIFICATION_EVENT.NOTIFICATION_MESSAGE:
      //     $api.post("messenger/list-chat", { _id, isSupport:false }).then((res:AxiosResponse<ChatType[]>)=>{
      //         res?.data.filter((s)=>{
      //             if(s?.lastMessage.timestamp.toString().slice(0, 18)==item.dateNotificationCreated.toString().slice(0, 18)){
      //                 navigate(`/user/messeges/chat?chatId=${item}`)
      //             }
      //         })
      //     })
      //     break;
      default:
        navigate(`/user/${notificationTypeTitle(item.event).link}/`)
        break
    }
  }
  return (
    <div className="notification__list">
      {notificationList.map((item, index) => (
        <>
          {!index && (
            <div className="notification__list-date">
              {notificationList[0].dateNotificationCreated === new Date()
                ? 'Today'
                : moment(item?.dateNotificationCreated).format('DD MMM YYYY')}
            </div>
          )}

          {moment(item?.dateNotificationCreated).format('DD MMM YYYY') !==
            moment(notificationList[index - 1]?.dateNotificationCreated).format(
              'DD MMM YYYY',
            ) && index ? (
            <div className="notification__list-date">
              {item?.dateNotificationCreated === new Date()
                ? 'Today'
                : moment(item?.dateNotificationCreated).format('DD MMM YYYY')}
            </div>
          ) : (
            <></>
          )}

          <div className="notification__list-item" onClick={() => navigateTo(item)}>
            <div className="notification__list-item-img">
              {item.ownerId?.avatarFileName ? (
                <img
                  src={`${apiBaseURL}/uploads/avatar/${item.ownerId?.avatarFileName}`}
                />
              ) : (
                <div
                  style={{
                    backgroundColor: 'rgba(41, 52, 65, 1)',
                    borderRadius: '100%',
                    width: '100%',
                    height: '100%',
                    color: '#fff',
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <span style={{ margin: 'auto', fontSize: '24px' }}>
                    {item.ownerId?.fullName.slice(0, 2)}
                  </span>
                </div>
              )}
              <div className="notification__list-item-img-status">
                {notificationTypeTitle(item.event).label()}
              </div>
            </div>
            <div className="notification__list-item-bodyline">
              <div className="notification__list-item-text">
                <b>
                  {item.ownerId?.fullName ? item.ownerId?.fullName : 'Deleted account'}
                </b>{' '}
                <span>{notificationTypeTitle(item.event).text}</span> <b>{item?.name}</b>
              </div>
              <div className="notification__list-item-time">
                {moment(item?.dateNotificationCreated).format('DD MMMM HH:mm')}
              </div>
            </div>
            <div className="notification__list-item-arrow">
              <IconArrowRight />
            </div>
          </div>
        </>
      ))}
    </div>
  )
}
