import { PathRouteProps, Route, Routes } from 'react-router-dom'
import Welcome, { WelcomeProps } from './Welcome'
import {
  IconWelcome1,
  IconWelcome2,
  IconWelcome3,
  IconWelcome4,
} from '../../svg/IconWelcome'

const WELCOME_ROUTES: (PathRouteProps & WelcomeProps)[] = [
  {
    path: 'area',
    image: <IconWelcome4 />,
    nextButtonTitle: 'Sign Up',
    nextPath: '/auth',
    isLastStep: true,
    title: 'All in your area',
    subTitle: 'You never knew how many possibilities are right around you',
  },
  {
    path: 'world',
    image: <IconWelcome3 />,
    nextPath: '/welcome/area',
    title: 'Co-create a real world',
    subTitle:
      "Connect with friends, find your heart's match, or meet neibs with shared interests. Create unforgettable memory",
  },
  {
    path: 'passion',
    image: <IconWelcome2 />,
    nextPath: '/welcome/world',
    title: 'Be paid for your passion',
    subTitle: 'Monetize your hobby without any charges',
  },
  {
    path: '/',
    image: <IconWelcome1 />,
    nextPath: '/welcome/passion',
    isFirstStep: true,
    nextButtonTitle: 'Get to know your neighbors',
    title: 'Your compass of real life',
    subTitle:
      'Connect with like-minded individuals, join local events, find services, food & stay up to date with the latest news',
  },
]

export const WelcomeRouter = () => {
  return (
    <Routes>
      {WELCOME_ROUTES.map(({ path, ...rest }) => (
        <Route key={path} path={path} element={<Welcome {...rest} />} />
      ))}
    </Routes>
  )
}
