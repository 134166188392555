export const IconEducation = () => {
  return (
    <svg
      style={{ margin: 'auto 0' }}
      width="20"
      height="12"
      viewBox="0 0 20 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.448 2.78756L10.7311 0.109452C10.2561 -0.0364841 9.74363 -0.0364841 9.26894 0.109452L0.551721 2.78756C-0.183907 3.0135 -0.183907 3.9863 0.551721 4.21224L2.07141 4.67911C1.73798 5.09129 1.53297 5.5941 1.51266 6.14473C1.21172 6.31722 0.999848 6.62847 0.999848 7.00003C0.999848 7.33691 1.17735 7.62034 1.43297 7.80159L0.635159 11.3916C0.565784 11.7038 0.803284 12 1.12297 12H2.87642C3.19642 12 3.43392 11.7038 3.36454 11.3916L2.56673 7.80159C2.82236 7.62034 2.99986 7.33691 2.99986 7.00003C2.99986 6.63847 2.79767 6.33597 2.51048 6.16035C2.53423 5.69098 2.77423 5.27598 3.15704 5.01286L9.26863 6.89066C9.55176 6.97753 10.0949 7.08597 10.7308 6.89066L19.448 4.21255C20.184 3.9863 20.184 3.01381 19.448 2.78756ZM11.0246 7.84659C10.133 8.12034 9.37332 7.96909 8.97488 7.84659L4.44299 6.45441L3.99986 10C3.99986 11.1047 6.68612 12 9.99988 12C13.3136 12 15.9999 11.1047 15.9999 10L15.5568 6.4541L11.0246 7.84659Z"
        fill="#0F1419"
      />
    </svg>
  )
}
