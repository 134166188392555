import { Divider } from 'antd'
import React, { ReactNode } from 'react'

export default function FormSectionDivider({ title }: { title: ReactNode }) {
  return (
    <div className="form-section-divider">
      <Divider orientation="left" orientationMargin={0}>
        {title}
      </Divider>
    </div>
    //  <h5 className="profileinfo__edit-title">Biography</h5>
  )
}
