import { ReactElement } from 'react'

export const AutoResize = ({
  placeholder,
  onClickFilter,
  value,
  changeValue,
}: {
  placeholder: string | ReactElement
  onClickFilter?: () => void | undefined
  value: string
  changeValue: (s: string) => void
}) => {
  return (
    <div className="profile__about-autoresize">
      <div className="profile__about-autoresize">
        <textarea
          value={value}
          onChange={(e) => {
            changeValue(e.target.value)
          }}
        />
        {!value && <span className="ui-input-search-placeholder">{placeholder}</span>}
      </div>
    </div>
  )
}
