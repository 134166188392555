import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthHttp } from '../../../http/auth'
import { setLoader } from '../../../redux/users'
import { useAppDispatch } from '../../../utils/hooks'
import { isPasswordPattern } from '../../../utils/patterns'
import { IconLeftChevrons } from '../../svg/IconChevrons'
import { InputPassword } from '../../ui/InputPassword'
import { success } from '../../ui/LoadSuccess'

export const ProfileInfoPassword = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [password, setPassword] = useState('')
  const [newPassword1, setNewPassword1] = useState('')
  const [newPassword2, setNewPassword2] = useState('')
  const [validation, setValidation] = useState({
    password: new RegExp(isPasswordPattern).test(password),
    newPassword1: new RegExp(isPasswordPattern).test(password),
    newPassword2: new RegExp(isPasswordPattern).test(password),
  })
  const { login } = location.state
  const uploadToServer = async () => {
    try {
      dispatch(setLoader(true))

      if (password && newPassword1 && newPassword2 && newPassword2 === newPassword1) {
        // changePassword
      }

      dispatch(setLoader(false))
      success()
      navigate(-1)
    } catch (error: any & { message?: string }) {
      dispatch(setLoader(false))
      console.log('upload file is faild' + error?.message)
    }
  }
  return (
    <div className="profileinfo__password-page">
      <div className="profileinfo__password-page-menu">
        <button>
          <IconLeftChevrons fill="#000" />
        </button>
        <h5>Create Password</h5>
        <h6>Create a new secured password for your account</h6>
      </div>
      <div className="profileinfo__edit-body-items">
        <InputPassword
          password={password}
          setPassword={setPassword}
          errorMessage={'Invalid password, min 8, numbers and letters'}
          pattern={isPasswordPattern}
          isValidated={validation.password}
          setIsValidated={(s: boolean) => setValidation({ ...validation, password: s })}
        />
        <InputPassword
          password={newPassword1}
          setPassword={setNewPassword1}
          errorMessage={'Invalid password, min 8, numbers and letters'}
          pattern={isPasswordPattern}
          isValidated={validation.newPassword1}
          placeholder="New Password"
          setIsValidated={(s: boolean) =>
            setValidation({ ...validation, newPassword1: s })
          }
        />
        <InputPassword
          password={newPassword2}
          setPassword={setNewPassword2}
          errorMessage={'Invalid password, min 8, numbers and letters'}
          pattern={isPasswordPattern}
          placeholder="Confirm New Password"
          isValidated={validation.newPassword2}
          setIsValidated={(s: boolean) =>
            setValidation({ ...validation, newPassword2: s })
          }
        />
      </div>
      <button className={`profile__method-btlater`} onClick={uploadToServer}>
        Create Password
      </button>
    </div>
  )
}
