export const IconInputSearch = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5006 17.0006H17.7106L17.4306 16.7306C18.6306 15.3306 19.2506 13.4206 18.9106 11.3906C18.4406 8.61063 16.1206 6.39063 13.3206 6.05063C9.09063 5.53063 5.53063 9.09063 6.05063 13.3206C6.39063 16.1206 8.61063 18.4406 11.3906 18.9106C13.4206 19.2506 15.3306 18.6306 16.7306 17.4306L17.0006 17.7106V18.5006L21.2506 22.7506C21.6606 23.1606 22.3306 23.1606 22.7406 22.7506C23.1506 22.3406 23.1506 21.6706 22.7406 21.2606L18.5006 17.0006ZM12.5006 17.0006C10.0106 17.0006 8.00063 14.9906 8.00063 12.5006C8.00063 10.0106 10.0106 8.00063 12.5006 8.00063C14.9906 8.00063 17.0006 10.0106 17.0006 12.5006C17.0006 14.9906 14.9906 17.0006 12.5006 17.0006Z"
        fill="#15104D"
      />
    </svg>
  )
}

export const IconInputFilter = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M16.5 5.4375H12C11.6925 5.4375 11.4375 5.1825 11.4375 4.875C11.4375 4.5675 11.6925 4.3125 12 4.3125H16.5C16.8075 4.3125 17.0625 4.5675 17.0625 4.875C17.0625 5.1825 16.8075 5.4375 16.5 5.4375Z"
        fill="#15104D"
      />
      <path
        d="M4.5 5.4375H1.5C1.1925 5.4375 0.9375 5.1825 0.9375 4.875C0.9375 4.5675 1.1925 4.3125 1.5 4.3125H4.5C4.8075 4.3125 5.0625 4.5675 5.0625 4.875C5.0625 5.1825 4.8075 5.4375 4.5 5.4375Z"
        fill="#15104D"
      />
      <path
        d="M7.5 8.0625C5.745 8.0625 4.3125 6.63 4.3125 4.875C4.3125 3.12 5.745 1.6875 7.5 1.6875C9.255 1.6875 10.6875 3.12 10.6875 4.875C10.6875 6.63 9.255 8.0625 7.5 8.0625ZM7.5 2.8125C6.36 2.8125 5.4375 3.735 5.4375 4.875C5.4375 6.015 6.36 6.9375 7.5 6.9375C8.64 6.9375 9.5625 6.015 9.5625 4.875C9.5625 3.735 8.64 2.8125 7.5 2.8125Z"
        fill="#15104D"
      />
      <path
        d="M16.5 13.6875H13.5C13.1925 13.6875 12.9375 13.4325 12.9375 13.125C12.9375 12.8175 13.1925 12.5625 13.5 12.5625H16.5C16.8075 12.5625 17.0625 12.8175 17.0625 13.125C17.0625 13.4325 16.8075 13.6875 16.5 13.6875Z"
        fill="#15104D"
      />
      <path
        d="M6 13.6875H1.5C1.1925 13.6875 0.9375 13.4325 0.9375 13.125C0.9375 12.8175 1.1925 12.5625 1.5 12.5625H6C6.3075 12.5625 6.5625 12.8175 6.5625 13.125C6.5625 13.4325 6.3075 13.6875 6 13.6875Z"
        fill="#15104D"
      />
      <path
        d="M10.5 16.3125C8.745 16.3125 7.3125 14.88 7.3125 13.125C7.3125 11.37 8.745 9.9375 10.5 9.9375C12.255 9.9375 13.6875 11.37 13.6875 13.125C13.6875 14.88 12.255 16.3125 10.5 16.3125ZM10.5 11.0625C9.36 11.0625 8.4375 11.985 8.4375 13.125C8.4375 14.265 9.36 15.1875 10.5 15.1875C11.64 15.1875 12.5625 14.265 12.5625 13.125C12.5625 11.985 11.64 11.0625 10.5 11.0625Z"
        fill="#15104D"
      />
    </svg>
  )
}
