import { useSelector } from 'react-redux'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { SocketContextProvider } from './context/SocketContext'
import { RootState } from './store'
import { ROLE } from './types/enum'
import { indexPathByRole } from './components/route/indexPathByRole'
import { AuthRegistrationConfirm } from './components/general-components/auth/AuthRegistrationConfirm'
import { AuthRegistrationConfirmCode } from './components/general-components/auth/AuthRegistrationConfirmCode'
import { AuthWelcome } from './components/general-components/auth/AuthWelcome'
import ForgetPass from './components/general-components/forgot-pass/ForgetPass'
import { Notification } from './components/general-components/notification/Notification'
import { Preview } from './components/general-components/preview/Preview'
import { ProfileInfo } from './components/general-components/profile-info/ProfileInfo'
import { ProfileStandaloneRouter } from './components/general-components/profile-standalone/ProfileStandaloneRouter'
import { Publish } from './components/general-components/publication/Publish'
import { WelcomeRouter } from './components/general-components/welcome/WelcomeRouter'
import AdminRouter from './components/route/AdminRouter'
import UserRouter from './components/route/UserRouter'
import SetPassword from './components/general-components/forgot-pass/SetPassword'
import SignUp from './components/general-components/auth/SignUp'
import SignIn from './components/general-components/auth/SignIn'
import Require from './components/route/Require'
import SetAddress from './components/general-components/location/SetAddress'
import GoogleRedirect from './components/general-components/auth/GoogleRedirect'
import FacebookRedirect from './components/general-components/auth/FacebookRedirect'
import BugReportFormMonday from './utils/BugReportFormMonday'
import useSessionExpiration from './utils/useSessionExpiration'

export default function App() {
  const isWelcome = useSelector((state: RootState) => state.app.isWelcome)
  const { role } = useSelector((state: RootState) => state.user)
  useSessionExpiration()

  const location = useLocation()
  return (
    <SocketContextProvider>
      <Notification>
        <Routes>
          {!isWelcome && !location.pathname.includes('preview') ? (
            <>
              <Route path="/welcome/*" element={<WelcomeRouter />} />
              <Route path="*" element={<Navigate to="/welcome" />} />
            </>
          ) : (
            <>
              <Route
                path="/admin/*"
                element={<Require.Role roles={[ROLE.ADMIN]} granted={<AdminRouter />} />}
              />

              <Route path="/user/*" element={<Require.Role granted={<UserRouter />} />} />

              <Route path="/publish/*" element={<Require.Role granted={<Publish />} />} />
              <Route
                path="/profileinfo/*"
                element={<Require.Role granted={<ProfileInfo />} />}
              />
              <Route
                path="/profile/*"
                element={<Require.Role granted={<ProfileStandaloneRouter />} />}
              />
              <Route
                path="/location/*"
                element={
                  <Require.Role
                    roles={[ROLE.USER_MUST_SET_ADDRESS]}
                    granted={<SetAddress />}
                  />
                }
              />

              <Route
                path="/forget-pass/*"
                element={<Require.Anon granted={<ForgetPass />} />}
              />
              <Route
                path="/reset-password"
                element={
                  <Require.Role
                    roles={[ROLE.USER_MUST_SET_PASSWORD]}
                    granted={<SetPassword />}
                  />
                }
              />
              <Route path="/preview/*" element={<Preview />} />
              <Route path="/auth">
                <Route index element={<AuthWelcome />} />
                <Route
                  path="google-redirect"
                  element={<Require.Anon granted={<GoogleRedirect />} />}
                />
                <Route
                  path="facebook-redirect"
                  element={<Require.Anon granted={<FacebookRedirect />} />}
                />
                <Route path="signIn" element={<Require.Anon granted={<SignIn />} />} />
                <Route path="signUp" element={<Require.Anon granted={<SignUp />} />} />
                <Route
                  path="confirm-code"
                  element={
                    <Require.Role
                      roles={[ROLE.USER_EMAIL_UNCONFIRMED]}
                      granted={<AuthRegistrationConfirmCode />}
                    />
                  }
                />
                <Route
                  path="confirm"
                  element={
                    <Require.Role
                      roles={[ROLE.USER_EMAIL_UNCONFIRMED]}
                      granted={<AuthRegistrationConfirm />}
                    />
                  }
                />
                <Route path="*" element={<Navigate to="/auth" />} />
              </Route>
              <Route path="*" element={<Navigate to={indexPathByRole(role)} />} />
            </>
          )}
          <Route path="bug-report" element={<BugReportFormMonday />} />
        </Routes>
      </Notification>
    </SocketContextProvider>
  )
}
