import { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import {
  allCategories,
  getHiddenService,
  getTenPublishService,
} from '../../../redux/categories'
import { SERVICES_EVENT, USER_LIST_APP } from '../../../types/enum'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { Loader } from '../../ui/Loader'
import { UserCategories } from './UserCategories'
import { UserPublishServicesAddReview } from './UserPublishServicesAddReview'
import { UserPublishServicesItem } from './UserPublishServicesItem'
import { UserPublishServicesList } from './UserPublishServicesList'
import { UserServiceReport } from './UserServiceReport'
import { UserServiceShareList } from './UserServiceShareList'
import { UserServicesMain } from './UserServicesMain'
import { UserServicesSpecialOffers } from './UserServicesSpecialOffers'

export const UserServices = () => {
  const { isLoad } = useAppSelector((state) => state.categories)
  const { _id } = useAppSelector((state) => state.user)
  const dispatch = useAppDispatch()
  const location = useLocation()

  useEffect(() => {
    dispatch(allCategories())
    dispatch(getHiddenService({ userId: _id }))
    dispatch(getTenPublishService())
  }, [])

  return (
    <div
      className={`user user--body ${location.pathname === '/user/service/publish-service' && 'user--none'}`}
    >
      <Routes>
        <Route path="publish-service/*" element={<UserPublishServicesItem />} />
        <Route path="review" element={<UserPublishServicesAddReview />} />
        <Route path="user-special-offers" element={<UserServicesSpecialOffers />} />
        <Route
          path="user-last-publish-service"
          element={<UserPublishServicesList event={USER_LIST_APP.LAST} />}
        />
        <Route path="send-report/*" element={<UserServiceReport />} />
        <Route path="share-list" element={<UserServiceShareList />} />
        <Route
          path="user-publish-service-list"
          element={<UserPublishServicesList event={USER_LIST_APP.ALL} />}
        />
        <Route
          path="user-sub-categories"
          element={<UserCategories event={SERVICES_EVENT.SUB_LIST} />}
        />
        <Route
          path="user-categories"
          element={<UserCategories event={SERVICES_EVENT.LIST} />}
        />
        <Route path="*" element={<UserServicesMain />} />
      </Routes>
      {isLoad && <Loader />}
    </div>
  )
}
