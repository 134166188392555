import { useEffect, useState } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconRound } from '../../svg/IconRound'
import { MessegesAudioInfo } from './MessegesAudionInfo'
import { MessegesGroupInfoMain } from './MessegesGroupInfoMain'
import { MessegesMediaInfo } from './MessegesMediaInfo'

export const MessegesGroupInfo = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [icon, setIcon] = useState('')
  const { role } = useAppSelector((state) => state.user)
  const [isOpen, setIsOpen] = useState(false)

  const toNeibs = () => {
    navigate(`${indexPathByRole(role)}/messeges/chat/chat-info/neibs`, {
      state: {
        chat: location.state.chat,
      },
    })
  }
  const toAudio = () => {
    navigate(`${indexPathByRole(role)}/messeges/chat/chat-info/audio`, {
      state: {
        chat: location.state.chat,
      },
    })
  }
  const toMedia = () => {
    navigate(`${indexPathByRole(role)}/messeges/chat/chat-info/media`, {
      state: {
        chat: location.state.chat,
      },
    })
  }
  useEffect(() => {
    setIcon(location.pathname.split('/')[location.pathname.split('/').length - 1])
  }, [location.pathname])

  return (
    <>
      <Routes>
        <Route path="/neibs" element={<MessegesGroupInfoMain setIsOpen={setIsOpen} />} />
        <Route path="/audio" element={<MessegesAudioInfo chat={location.state.chat} />} />
        <Route path="/media" element={<MessegesMediaInfo chat={location.state.chat} />} />
        <Route path="*" element={<MessegesGroupInfoMain setIsOpen={setIsOpen} />} />
      </Routes>
      {isOpen && (
        <div className="group__info-menu">
          <div
            className={
              icon === 'neibs'
                ? 'group__info-menu-item group__info-menu-item-neibs'
                : 'group__info-menu-item'
            }
            onClick={toNeibs}
          >
            <IconRound fill={icon === 'neibs' ? 'white' : '#039162'} />
            Neibs
          </div>
          <div
            className={
              icon === 'media'
                ? 'group__info-menu-item group__info-menu-item-media'
                : 'group__info-menu-item'
            }
            onClick={toMedia}
          >
            <IconRound fill={icon === 'media' ? 'white' : '#6938D3'} />
            Media
          </div>
          <div
            className={
              icon === 'audio'
                ? 'group__info-menu-item group__info-menu-item-audio'
                : 'group__info-menu-item'
            }
            onClick={toAudio}
          >
            <IconRound fill={icon === 'audio' ? 'white' : '#E8753D'} />
            Audio
          </div>
        </div>
      )}
    </>
  )
}
