import { useState, useContext, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { SocketContext } from '../../../context/SocketContext'
import $api from '../../../http'
import { UserHttp } from '../../../http/user-http'
import { FriendTypeResponse, ParticipantType, OptionsType } from '../../../types/types'
import { calculateDistance } from '../../../utils/calculateRate'
import { indexPathByRole } from '../../route/indexPathByRole'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { IconLeftChevrons } from '../../svg/IconChevrons'
import { InputSearch } from '../../ui/InputSearch'
import { SlickCategories } from '../../ui/SlickCategories'
import { UserHeader } from '../header/UserHeader'
import { MessageUserItem } from './MessageUserItem'
import { getUserChats } from '../../../redux/messanger'
import { PRIVACY } from '../../../types/enum'

export const MessegesGroupMembers = () => {
  const [Participians, setParticipians] = useState<FriendTypeResponse[]>([])
  const [GroupName, setGroupName] = useState<string>('')
  const navigate = useNavigate()
  const location = useLocation()
  const [users, setUsers] = useState<FriendTypeResponse[]>([])
  const { socket } = useContext(SocketContext)
  const [load, setLoad] = useState(true)
  const { _id, role, fullName, avatarFileName } = useAppSelector((state) => state.user)
  const { coordinates } = useAppSelector((state) => state.profile)
  const [search, setSearch] = useState<string>('')
  const validate = !Boolean(Participians.length > 1)
  const dispatch = useAppDispatch()
  const [usersNearby, setUsersNearby] =
    useState<
      { fullName: string; distance: number; avatarFileName: string | null; _id: string }[]
    >()
  const [usersNearbySelected, setUsersNearbySelected] =
    useState<
      { fullName: string; distance: number; avatarFileName: string | null; _id: string }[]
    >()

  useEffect(() => {
    const body = async () => {
      let dist: {
        fullName: string
        distance: number
        avatarFileName: string | null
        _id: string
      }[] = []
      await UserHttp.getUsersWithCoords({
        role,
        myLat: coordinates.lat,
        myLng: coordinates.lng,
      }).then((res) => {
        res.filter((item) => {
          if (item.coords) {
            let distance = calculateDistance(coordinates, item.coords)
            dist.push({
              distance,
              fullName: item.fullName,
              avatarFileName: item.avatarFileName,
              _id: item?._id,
            })
          }
        })
        setUsersNearbySelected(dist.sort((f, s) => f.distance - s.distance).slice(0, 10))
      })
    }
    body()
  }, [])

  const addUser = (item: {
    fullName: string
    distance: number
    avatarFileName: string | null
    _id: string
  }) => {
    if (usersNearbySelected) {
      let array: {
        fullName: string
        distance: number
        avatarFileName: string | null
        _id: string
      }[] = [...usersNearbySelected]
      array.push(item)
      setUsersNearbySelected(array)
    }
  }

  const removeUser = (item: {
    fullName: string
    distance: number
    avatarFileName: string | null
    _id: string
  }) => {
    if (usersNearbySelected) {
      let array: {
        fullName: string
        distance: number
        avatarFileName: string | null
        _id: string
      }[] = [...usersNearbySelected]
      let index = array.indexOf(item)
      if (index !== -1) {
        array.splice(index, 1)
        setUsersNearbySelected(array)
      }
    }
  }

  const openChat = () => {
    let array: ParticipantType[] = []
    Participians.map((item) => {
      array.push({
        userId: {
          _id: item.friendId._id,
          fullName: item.friendId.fullName,
          avatarFileName: item.friendId.avatarFileName,
        },
      })
    })
    array.push({
      userId: {
        _id,
        fullName,
        avatarFileName,
      },
    })
    if (array) {
      const formCatData = new FormData()
      let payload: {
        groupName: string
        groupDescription: string
        participants: ParticipantType[]
        interests: OptionsType[]
        skills: OptionsType[]
        privacy: string
        groupOwner: string
        polygonCoords?: L.LatLng[] | L.LatLng[][] | L.LatLng[][][] | null
        radiusSize?: number
        coordinates?: L.LatLng | null
      } = {
        groupName: location.state.title,
        groupDescription: location.state.text,
        participants: array,
        interests: location.state.interestsList,
        skills: location.state.skillsList,
        privacy: location.state.privacy,
        groupOwner: _id,
        polygonCoords: location?.state?.polygonCoords || null,
        radiusSize: location?.state?.radiusSize || 0,
        coordinates: location?.state?.coords || null,
      }
      formCatData.append('payload', JSON.stringify(payload))

      formCatData.append('file', location.state.file)
      $api.post('messenger/new-group', formCatData).then((res) => {
        dispatch(getUserChats({ _id, isSupport: false }))
        if (res.data) {
          navigate(`${indexPathByRole(role)}/messeges/chat?chatId="${res.data._id}"`, {
            state: { chat: { ...res.data, chatId: res.data._id } },
          })
        }
      })
    }
  }
  const EffectBody = async () => {
    setLoad(true)
    const res = await UserHttp.getMyFriends({ _id })
    setUsers(res)
    setLoad(false)
  }
  useEffect(() => {
    EffectBody()
  }, [])

  useEffect(() => {
    let array: FriendTypeResponse[] = []
    if (search || search.length) {
      users.map((item) => {
        if (item.friendId.fullName?.toLowerCase().includes(search.toLowerCase())) {
          array.push(item)
          console.log(item)
        }
      })
      setUsers(array)
    } else {
      EffectBody()
    }
  }, [search])

  const updateParticipians = (obj: FriendTypeResponse[]) => {
    let array = [...Participians]
    let index = array.indexOf(obj[0])
    if (!array.includes(array[index])) {
      if (array.length == 0) {
        array.push(obj[0])
        array.push(obj[1])
        // array.push({userId:_id, avatarFileName:avatarFileName, fullName:fullName});
        setParticipians(array)
      } else {
        setParticipians((s) => [...s, obj[0]])
      }
    }
    const removeUser = (obj: FriendTypeResponse[]) => {
      var array = [...Participians]
      var index = array.indexOf(obj[0])
      if (index !== -1) {
        array.splice(index, 1)
        setParticipians(array)
      }
    }
  }
  return (
    <div className="group">
      <UserHeader>
        <div className="user__header-messages explore__header">
          <button
            onClick={() => navigate(-1)}
            className="user__header-chatuser user__header-messages-exit"
          >
            <IconLeftChevrons />
            <h5 className="user__header-title">Add Neibs</h5>
          </button>
        </div>
      </UserHeader>
      <div className="group__content">
        <div>
          <div className="user__newsfeed-search">
            <InputSearch
              placeholder={
                <>
                  Search <b>NightborChats</b>
                </>
              }
              value={search}
              changeValue={setSearch}
            />
          </div>
        </div>
        <div className="group__content-near" style={{ display: 'flex', padding: '10px' }}>
          <SlickCategories>
            {usersNearbySelected?.map((item) => (
              <div className="group__content-near-item">{item.fullName}</div>
            ))}
          </SlickCategories>
        </div>
        <div className="group__content-users-list" style={{ margin: '50px 0' }}>
          {users.map((item) => (
            <MessageUserItem
              item={item}
              setParticipians={setParticipians}
              Participians={Participians}
            />
          ))}
        </div>
      </div>
      <button
        className={`publish__publish user--footer--button group__name-content-next ${
          validate && 'services__add-button--disabled'
        }`}
        onClick={openChat}
        disabled={validate}
      >
        {location.state.privacy === PRIVACY.NEIBS ? 'Create Group' : 'Create Chat'}
      </button>
    </div>
  )
}
