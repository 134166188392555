export const IConAdd = ({ fill }: { fill?: string }) => {
  return (
    <svg
      style={{ margin: '4px' }}
      width="29"
      height="25"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 0.666626C6.63999 0.666626 0.666656 6.63996 0.666656 14C0.666656 21.36 6.63999 27.3333 14 27.3333C21.36 27.3333 27.3333 21.36 27.3333 14C27.3333 6.63996 21.36 0.666626 14 0.666626ZM19.3333 15.3333H15.3333V19.3333C15.3333 20.0666 14.7333 20.6666 14 20.6666C13.2667 20.6666 12.6667 20.0666 12.6667 19.3333V15.3333H8.66666C7.93332 15.3333 7.33332 14.7333 7.33332 14C7.33332 13.2666 7.93332 12.6666 8.66666 12.6666H12.6667V8.66663C12.6667 7.93329 13.2667 7.33329 14 7.33329C14.7333 7.33329 15.3333 7.93329 15.3333 8.66663V12.6666H19.3333C20.0667 12.6666 20.6667 13.2666 20.6667 14C20.6667 14.7333 20.0667 15.3333 19.3333 15.3333Z"
        fill={fill || 'white'}
      />
    </svg>
  )
}
