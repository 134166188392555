import { FloatButton, notification } from 'antd'
import { useAttemptListener } from 'dev-masters-react-kit'
import L, { LatLngExpression } from 'leaflet'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoChevronBackOutline, IoTrashOutline } from 'react-icons/io5'
import { PiPolygonLight } from 'react-icons/pi'
import { TbCurrentLocation } from 'react-icons/tb'
import { Circle, FeatureGroup, MapContainer, TileLayer } from 'react-leaflet'
import { EditControl } from 'react-leaflet-draw'
import { useLocation, useNavigate } from 'react-router-dom'
import { attemptUpdateInterestZone } from '../../../../redux/userNew'
import { store } from '../../../../store'
import { useAppDispatch, useAppSelector } from '../../../../utils/hooks'
import ZoneRadioDrawer, { ZONE_INPUT_TYPE, ZoneDrawersMethods } from './ZoneRadioDrawer'
import { GEOLOCATION_STATUS } from '../../../../utils/GeolocationStatus'

const CurrentLocationView = ({
  navigateToConfirmPath,
}: {
  navigateToConfirmPath: string
}) => {
  const dispatch = useAppDispatch()
  const [geolocationStatus, setGeolocationStatus] = useState<GEOLOCATION_STATUS>()
  const { t } = useTranslation('profile')
  const [map, setMap] = useState<L.Map | null>(null)
  const interestZoneCoordinates = useAppSelector((state) => state.userNew.interestZone?.coordinates)
  const interestCircle = useAppSelector((state) => state.userNew.interestCircle)

  const address = useAppSelector((state) => state.userNew.address)
  const [inputMethod, setInputMethod] = useState<ZONE_INPUT_TYPE | undefined>(
    interestZoneCoordinates 
    ? ZONE_INPUT_TYPE.POLYGON
    : (interestCircle
      ? ZONE_INPUT_TYPE.CIRCLE
      : undefined
    )
  )

  let mapCenter: LatLngExpression = [48.7679001, 2.0185928]

  if (interestZoneCoordinates && interestZoneCoordinates[0]) {
    mapCenter = getPolygonCentroid(interestZoneCoordinates[0])
  }
  else if (interestCircle) {
    mapCenter = interestCircle.center.coordinates
  }
  else if (address) {
    mapCenter = [address.lat, address.lon]
  }

  const [mapMovingCenter, setMapMovingCenter] = useState<LatLngExpression>(mapCenter)

  const [currentZone, setCurrentZone] = useState<[number, number][][] | undefined>()
  const [currentRadius, setCurrentRadius] = useState<number>(interestCircle?.radius || 5)
  const featureGroupRef = useRef<L.FeatureGroup>(null)

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (map) {
      map.on('move', (e) => {
        const center = map?.getCenter()
        if (center) {
          setMapMovingCenter(center)
        }
      })

      return () => {
        map?.off('move')
      }
    }
  }, [map])

  useEffect(() => {
    if (interestZoneCoordinates && featureGroupRef.current) {
      const layerGroup = featureGroupRef.current
      clearLayers()

      interestZoneCoordinates.forEach((coords) => {
        const polygon = new L.Polygon([coords])
        polygon.addTo(layerGroup)
      })
    }
  }, [interestZoneCoordinates, featureGroupRef.current])

  useEffect(() => {
    clearLayers()
    if (map && inputMethod === ZONE_INPUT_TYPE.CIRCLE) {
      if (address) {
        const center: LatLngExpression = [address.lat, address.lon]
        map.flyTo(center)
      }
    }
    else if (inputMethod === ZONE_INPUT_TYPE.POLYGON) {
      ZoneRadioDrawerRef.current?.toggleOpen(false)
      startPolygon()
    }
  }, [inputMethod])

  useAttemptListener({
    store,
    attempt: attemptUpdateInterestZone,
    onRejected: (action) => {
      notification.error({
        message: t('Could not save interest zone'),
      })
    },
    onFulfilled(action, listenerApi) {
      ZoneRadioDrawerRef.current?.toggleOpen(false)
      notification.success({
        message: t('Interest zone saved successfully'),
      })
    },
  })

  const geoLocation = () => {
    if (navigator.geolocation) {
      setGeolocationStatus('ONGOING')
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords
          map?.flyTo([latitude, longitude])
          setMapMovingCenter([latitude, longitude])
          setGeolocationStatus('SUCCESS')
        },
        (e) => {
          console.error(e)
          setGeolocationStatus('FAILED')
        },
      )
    }
    else {
      setGeolocationStatus('NOT_SUPPORTED')
    }
  }

  const saveInterestZone = () => {
    const currentCenter = map?.getCenter()

    if (inputMethod === ZONE_INPUT_TYPE.POLYGON && currentZone) {
      dispatch(
        attemptUpdateInterestZone({
          polygon: currentZone,
        }),
      )
    }
    else if (inputMethod === ZONE_INPUT_TYPE.CIRCLE && currentCenter) {
      dispatch(
        attemptUpdateInterestZone({
          circle: {
            center: [currentCenter.lat, currentCenter.lng],
            radius: currentRadius,
          }
        }),
      )
    } else {
      notification.error({
        message: t('Could not save interest zone'),
      })
    }
  }

  const ZoneRadioDrawerRef = useRef<ZoneDrawersMethods>(null)

  const clearLayers = () => {
    try {
      featureGroupRef.current?.clearLayers()
    } catch(e) {
      console.error("clearLayers: ", e)
    }
  }

  const startPolygon = () => {
    clearLayers()
    clickOnElement('leaflet-draw-draw-polygon')
  }

  const clickOnElement = (classname: string) => {
    const e = document.createEvent('Event')
    e.initEvent('click', true, true)
    const elements = document.getElementsByClassName(classname)
    if (elements && elements[0]) {
      elements[0].dispatchEvent(e)
    }
  }

  return (
    <>
      <FloatButton
        icon={<IoChevronBackOutline />}
        style={{ top: '1.5em', left: '1.5em' }}
        onClick={() => navigate(-1)}
      />
      <FloatButton
        icon={<TbCurrentLocation />}
        style={{ top: '1.5em', right: '1.5em' }}
        onClick={geoLocation}
      />
      {inputMethod === ZONE_INPUT_TYPE.POLYGON && (
        <FloatButton.Group shape="circle" style={{ top: '6em', right: '1.5em' }}>
          <FloatButton icon={<PiPolygonLight />} onClick={startPolygon} />
          <FloatButton icon={<IoTrashOutline />} onClick={clearLayers} />
        </FloatButton.Group>
      )}
      <MapContainer
        zoomControl={false}
        center={mapCenter}
        minZoom={8}
        maxZoom={18}
        zoom={location.state?.zoom || 8}
        scrollWheelZoom={true}
        ref={setMap}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
          url="https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png"
          subdomains="abcd"
        />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
          url="https://{s}.basemaps.cartocdn.com/dark_only_labels/{z}/{x}/{y}{r}.png"
          subdomains="abcd"
        />

        {inputMethod === ZONE_INPUT_TYPE.CIRCLE
          && mapMovingCenter
          && currentRadius
          && (
            <>
              <Circle center={mapMovingCenter} radius={5} />
              <Circle
                center={mapMovingCenter}
                radius={currentRadius * 1000}
              />
            </>
          )
        }

        <FeatureGroup ref={featureGroupRef}>
          <EditControl
            position="topright"
            onCreated={(e) => {
              const coordinates = e.layer.getLatLngs()[0].map((v: any) => [v.lat, v.lng])
              coordinates.push(coordinates[0])
              setCurrentZone([coordinates])
            }}
            draw={{
              rectangle: false,
              circle: false,
              circlemarker: false,
              marker: false,
              polyline: false,
              polygon: {
                allowIntersection: false,
              },
            }}
            onDrawStart={clearLayers}
          />
        </FeatureGroup>
      </MapContainer>
      <ZoneRadioDrawer
        ref={ZoneRadioDrawerRef}
        radius={currentRadius}
        onRadiusChanged={setCurrentRadius}
        inputMethod={inputMethod}
        setInputMethod={setInputMethod}
        onConfirm={saveInterestZone}
        onDrawerClosed={() => {}}
      />
    </>
  )
}

export default CurrentLocationView

function getPolygonCentroid(vertices: number[][]): LatLngExpression {
  let x = 0,
    y = 0,
    area = 0
  const n = vertices.length

  for (let i = 0; i < n - 1; i++) {
    const [xi, yi] = vertices[i]
    const [xj, yj] = vertices[(i + 1) % n]

    const cross = xi * yj - xj * yi
    area += cross
    x += (xi + xj) * cross
    y += (yi + yj) * cross
  }

  area /= 2
  x /= 6 * area
  y /= 6 * area

  return [x, y]
}
