import { useState } from 'react'
import { PRIVACY } from '../../../types/enum'
import {
  IconSvgPrivacyPublishCheckCircle,
  IconSvgPrivacyPublishCircle,
} from '../../svg/IconSvgPrivacyPublish'
import { Modal } from '../../ui/Modal'
import { buttonChild } from '../publication/PublishPrivacyModal'

export const PrivacyModal = ({
  isOpen,
  setIsOpen,
  text,
}: {
  isOpen: boolean
  setIsOpen: (s: boolean) => void
  text: string
}) => {
  const [privacy, setPrivacy] = useState(PRIVACY.EVERYONE)
  const list = Object.values(PRIVACY)
  return (
    <>
      <div
        className={`activities__favor-modal  ${
          isOpen ? 'activities__favor-modal--open' : ''
        }`}
      >
        <Modal className={'explore__regModal'} setIsOpen={setIsOpen}>
          <div
            onClick={(e) => {
              e.stopPropagation()
              setIsOpen(false)
            }}
            className="activities__favor-modal-linebody"
          >
            <button className="activities__favor-modal-line" />
          </div>
          <div className="publish__privacy">
            <button className="publish__location-button" onClick={() => setIsOpen(true)}>
              {buttonChild(privacy).label()}
              {buttonChild(privacy).title}
            </button>
          </div>
          <div
            className={`activities__favor-modal  ${
              isOpen ? 'activities__favor-modal--open' : ''
            }`}
          >
            <Modal className="" setIsOpen={(s: boolean) => setIsOpen(s)}>
              <div
                onClick={(e) => {
                  e.stopPropagation()
                  setIsOpen(false)
                }}
                className="activities__favor-modal-linebody"
              >
                <button className="activities__favor-modal-line" />
              </div>
              <div className="publish__modaladd">
                <h5 className="publish__modaladd-title">Privacy</h5>
                <h6 className="publish__modaladd-subtitle">Who can see your {text}</h6>
                <div className="publish__modaladd-body publish__privacy-body">
                  {list.map((item) => (
                    <div
                      className="publish__privacy-item"
                      onClick={() => setPrivacy(item)}
                    >
                      <div
                        className={`${
                          item === privacy && 'publish__privacy-item--active'
                        }`}
                      >
                        {buttonChild(item).label()}
                      </div>

                      <div>
                        <p className="publish__modaladd-subtitle publish__privacy-item-text">
                          {buttonChild(item).title}
                        </p>
                        <p className="publish__modaladd-subtitle">
                          {buttonChild(item).subtitle}
                        </p>
                      </div>
                      <button>
                        {privacy === item ? (
                          <IconSvgPrivacyPublishCheckCircle />
                        ) : (
                          <IconSvgPrivacyPublishCircle />
                        )}
                      </button>
                    </div>
                  ))}
                  <button
                    className="publish__privacy-apply"
                    onClick={() => {
                      setIsOpen(false)
                    }}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </Modal>
          </div>
        </Modal>
      </div>
    </>
  )
}
