import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import $api from '../../../http'
import { OptionsItemType, OptionsType } from '../../../types/types'
import { indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconLeftChevrons } from '../../svg/IconChevrons'
import { InputSearch } from '../../ui/InputSearch'
import { UserHeader } from '../header/UserHeader'

export const MessegesGroupInterests = () => {
  const locationPath = useLocation()
  const navigate = useNavigate()

  const [interests, setInterests] = useState<boolean>(true)
  const [skills, setSkills] = useState<boolean>(false)
  const [search, setSearch] = useState('')
  const [searchSkills, setSearchSkills] = useState('')

  const [popularOptionsNormal, setPopularOptionsNormal] = useState<OptionsType>([])
  const [popularOptions, setPopularOptions] = useState<OptionsType>([])
  const [interestsList, setInterestsList] = useState<OptionsType>([])

  const [popularSkillsOptionsNormal, setPopularSkillsOptionsNormal] =
    useState<OptionsType>([])
  const [popularSkillsOptions, setPopularSkillsOptions] = useState<OptionsType>([])
  const [skillsList, setSkillsList] = useState<OptionsType>([])

  const { role } = useAppSelector((state) => state.user)

  const validate = !Boolean(skillsList && interestsList)
  console.log(locationPath.state)
  useEffect(() => {
    $api
      .get(`identity/interests-popular`)
      .then((res) => {
        setPopularOptions(res.data)
        setPopularOptionsNormal([...popularOptions])
      })
      .catch((e) => console.log(e + 'error'))
  }, [])

  useEffect(() => {
    $api
      .get(`identity/skills-popular`)
      .then((res) => {
        setPopularSkillsOptions(res.data)
        setPopularSkillsOptionsNormal([...popularSkillsOptions])
      })
      .catch((e) => console.log(e + 'error'))
  }, [])

  useEffect(() => {
    let array: OptionsType = []
    setPopularOptionsNormal([])
    if (search.length) {
      popularOptions.map((item) => {
        if (item.title.toLowerCase().includes(search.toLowerCase())) {
          array.push(item)
        }
      })
    } else {
      array = [...popularOptions]
    }
    setPopularOptionsNormal(array)
  }, [search])

  useEffect(() => {
    let array: OptionsType = []
    setPopularSkillsOptionsNormal([])
    if (searchSkills.length) {
      popularSkillsOptions.map((item) => {
        if (item.title.toLowerCase().includes(searchSkills.toLowerCase())) {
          array.push(item)
        }
      })
    } else {
      array = [...popularSkillsOptions]
    }
    console.log(array)
    setPopularSkillsOptionsNormal(array)
  }, [searchSkills])

  const addToInterests = (item: OptionsItemType) => {
    if (interestsList.find((interest) => item == interest)) {
      let array: OptionsType = [...interestsList]
      let index = array.indexOf(item)
      if (index !== -1) {
        array.splice(index, 1)
        setInterestsList(array)
      }
    } else {
      setInterestsList((s) => [...s, item])
    }
  }
  const addToSkills = (item: OptionsItemType) => {
    if (skillsList.find((skill) => item == skill)) {
      let array: OptionsType = [...skillsList]
      let index = array.indexOf(item)
      if (index !== -1) {
        array.splice(index, 1)
        setSkillsList(array)
      }
    } else {
      setSkillsList((s) => [...s, item])
    }
  }
  return (
    <div className="group">
      <UserHeader>
        <div className="user__header-messages explore__header">
          <button
            onClick={() => navigate(-1)}
            className="user__header-chatuser user__header-messages-exit"
          >
            <IconLeftChevrons />
            <h5 className="user__header-title">Add Skills & Interests</h5>
          </button>
        </div>
      </UserHeader>
      <div className="advertisement__menu">
        <h5
          className={
            interests ? 'advertisement__menu-active' : 'advertisement__menu-text'
          }
          onClick={() => {
            setInterests(true)
            setSkills(false)
          }}
        >
          Interests
        </h5>
        <h5
          className={skills ? 'advertisement__menu-active' : 'advertisement__menu-text'}
          onClick={() => {
            setInterests(false)
            setSkills(true)
          }}
        >
          Skills
        </h5>
      </div>
      <div style={{ height: '60vh', position: 'relative', overflow: 'hidden' }}>
        {interests && (
          <>
            <div style={{ margin: '20px 0' }}>
              <InputSearch
                value={search}
                changeValue={setSearch}
                placeholder={
                  <>
                    Search <b>Interests</b>
                  </>
                }
              />
            </div>
            <div className="profile__identity-list">
              <div className="profile__identity-list-body">
                {popularOptionsNormal.slice(0, 12).map((item, index) => (
                  <div
                    className={`profile__identity-list-item 
                                        ${
                                          interestsList.find(
                                            (interest) => interest.title === item.title,
                                          )
                                            ? 'profile__identity-list-item-active'
                                            : ''
                                        }  
                                        `}
                    key={index}
                    onClick={() => addToInterests(item)}
                  >
                    <div className="profile__identity-list-item-text">{item.title}</div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
        {skills && (
          <>
            <div style={{ margin: '20px 0' }}>
              <InputSearch
                value={searchSkills}
                changeValue={setSearchSkills}
                placeholder={
                  <>
                    Search <b>Skills</b>
                  </>
                }
              />
            </div>
            <div className="profile__identity-list">
              <div className="profile__identity-list-body">
                {popularSkillsOptionsNormal.slice(0, 12).map((item, index) => (
                  <div
                    className={`profile__identity-list-item 
                                        ${
                                          skillsList.find(
                                            (interest) => interest.title === item.title,
                                          )
                                            ? 'profile__identity-list-item-active'
                                            : ''
                                        }  
                                        `}
                    key={index}
                    onClick={() => addToSkills(item)}
                  >
                    <div className="profile__identity-list-item-text">{item.title}</div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
        <button
          className={`advertisement__add-main-btn ${
            validate && 'advertisement__add-main-btn-disabled'
          }`}
          style={{ bottom: '0', position: 'absolute' }}
          onClick={() => {
            navigate(`${indexPathByRole(role)}/messeges/groupmembers`, {
              state: {
                ...locationPath.state,
                interestsList,
                skillsList,
              },
            })
          }}
          disabled={validate}
        >
          Next
        </button>
      </div>
    </div>
  )
}
