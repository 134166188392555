import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Form,
  Input,
  Layout,
  Row,
  Col,
  Space,
  Switch,
  AutoComplete,
  Typography,
  Flex,
  Badge,
  notification,
} from 'antd'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { attemptUpdateSecurity, UserInterface } from '../../../redux/userNew'
import { AuthDTO } from '../../../types/server-communication/auth'
import FormSectionDivider from '../../ui/new-components/FormSectionDivider'
import PhoneNumberInput from '../../ui/PhoneNumberInput'
import { useTranslation } from 'react-i18next'
import { useStrongPassword, useAttemptListener } from 'dev-masters-react-kit'
import useAddressSearch from '../../../utils/hooks/useAddressSearch'
import { RootState, store } from '../../../store'
import { convertNominatimResponseToAddressDTO } from '../location/AddressDTO'
import { useSelector } from 'react-redux'

export interface SecurityFormValues {
  email: string
  phone: string
  address: string
  oldPassword: AuthDTO['password']
  newPassword: AuthDTO['password']
  confirmPassword: AuthDTO['password']
}

export const ProfileInfoSecurity = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('profile')
  const { verifyProvidedPassword } = useStrongPassword()
  const user = useSelector((state: RootState) => state.userNew)
  const dispatch = useAppDispatch()
  const [securityForm] = Form.useForm<SecurityFormValues>()
  const currentNewPassword = Form.useWatch('newPassword', securityForm)
  const { isLoading: isAddressLoading, options, handleSearch } = useAddressSearch()

  const getAddress = (value: string) => {
    if (value === user.address?.displayName) {
      return user.address
    }

    const response = options.find((a) => a.value === value)?.data
    return response ? convertNominatimResponseToAddressDTO(response) : undefined
  }

  const handleSubmit = (values: SecurityFormValues) => {
    console.log(values)
    dispatch(
      attemptUpdateSecurity({
        ...values,
        address: values.address ? getAddress(values.address) : undefined,
      }),
    )
  }

  const isLoading = useAttemptListener({
    store: store,
    attempt: attemptUpdateSecurity,
    onRejected: (action) => {
      console.log(action.error.message)
      switch (action.error.message) {
        case 'BAD_PASSWORD':
          securityForm.setFields([
            {
              name: 'oldPassword',
              errors: [t('Invalid password')],
            },
          ])
          break

        case 'EMAIL_ALREADY_TAKEN':
          securityForm.setFields([
            {
              name: 'email',
              errors: [t('EMAIL_ALREADY_TAKEN')],
            },
          ])
          break

        default:
          notification.error({ message: t('An error occured') })
      }
    },
    onFulfilled: () => {
      notification.success({ message: t('Your profile has been successfully updated') })
    },
  })

  useEffect(() => {
    securityForm.setFieldsValue({
      email: user.email,
      phone: user.phone,
      address: user.address?.displayName,
    })
  }, [])

  return (
    <Form
      form={securityForm}
      layout="vertical"
      onFinish={handleSubmit}
      requiredMark={false}
      scrollToFirstError
      autoComplete="off"
    >
      <input
        type="text"
        name="hidden-username"
        id="hidden-username"
        style={{ display: 'none' }}
      />
      <input
        type="password"
        name="hidden-password"
        id="hidden-password"
        style={{ display: 'none' }}
      />

      <FormSectionDivider title="Configure Session" />
      <Form.Item
        label="Remember me"
        name="rememberMe"
        layout={'horizontal'}
        className="form-item-horizontal"
      >
        <Switch />
      </Form.Item>

      <Form.Item
        name="email"
        label="Email"
        className="form-item-label-extra"
        rules={[
          {
            required: true,
            message: 'Invalid login example@example.com',
          },
          { type: 'email', message: 'Invalid email, example: user@gmail.com!' },
        ]}
      >
        <Input placeholder="email@example.com" />
      </Form.Item>

      <Form.Item
        name="phone"
        className="form-item-label-extra"
        label={
          <>
            <span>Phone</span>
            <a onClick={() => console.log('todo')}>Add</a>
          </>
        }
      >
        <PhoneNumberInput />
      </Form.Item>
      <Form.Item name="address" className="form-item-label-extra" label="Address">
        <AutoComplete
          onSearch={handleSearch}
          options={options}
          notFoundContent={isLoading ? 'Loading...' : null}
          placeholder="Set up your address"
        />
      </Form.Item>

      <Form.Item name="oldPassword" label="Change Password">
        <Input.Password placeholder="Old Password" autoComplete="off" />
      </Form.Item>
      <Form.Item
        name="newPassword"
        rules={[
          {
            validator(_, value) {
              const notPassed = verifyProvidedPassword(value)
              if (notPassed) return Promise.reject(new Error(notPassed))
              return Promise.resolve()
            },
          },
        ]}
      >
        <Input.Password placeholder="New Password" autoComplete="off" />
      </Form.Item>
      <Form.Item
        name="confirmPassword"
        dependencies={['newPassword']}
        rules={[
          {
            required: currentNewPassword?.length > 0,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve()
              }
              return Promise.reject(new Error(t('The passwords are different')))
            },
          }),
        ]}
      >
        <Input.Password placeholder="Repeat New Password" autoComplete="off" />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          block
          disabled={isLoading}
          loading={isLoading}
        >
          Update Profile
        </Button>
      </Form.Item>
    </Form>
  )
}
