export const IconHome = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect width="20" height="20" fill="url(#pattern0)" />
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image0_1098_15907" transform="scale(0.01)" />
        </pattern>
        <image
          id="image0_1098_15907"
          width="100"
          height="100"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEgUlEQVR4nO2dW4gcRRSGyxivSaZOz5wz48qiiPFFBC8g3l0QImvmnDFBJngBX4QgIkjyIigaQQQRFBVU4oOioA/7EuOTIBIQhYggiCB5EURETFAkQrwmO1LrmF03Me7M9PTpnv1/+GEfdneq/q/r0jXVXSFAEARBEARBEARBA0kyvZGjvpycfh7sr6Ec1T2dSZ8Wsnkh6y1ad4ew/QxEXaCm1isz6fv/BrHE0T4U2XweoBQgjnoVR/v6P2EsQvm2WbdrAWWMEmrfy1F/+V8YfTPZb0ydhwAlZ20Ms2cJ6asrBXECmGhvTk93zwGYHFSvz04z6f5hYSwZ7D9rRbsIUEYQU+dmJv1+dBj/dGH6Q7NmmwBlcJ2W+n4h/SMvGEtaylGJ9kT6DIBZgUS664V0Ln8Qy2dhujfLuhFQTtVFbWhfwtG+GDuMxdZyoF7vXAooJx0vTIX0p+JgHG8pP0vUOwBlyXgh0R4W0mOFw1j0fFqGScsxqxpMrXZrncnecwTRW+Z9rXVbm2E1SuLmK5jsqxJA6C3rwr5p1PTqsJrUoPY9HO2Ie/h0cnO0XznafWHyNbO2v2Teq4Z1dwjdM8MkqtHonM9RP/YP2QaF8mmW6QVhkiSZ3SBRv/MP14Yykx1qRLslTII42nYh/d07VBndf6bpeaiqLgwzZzPZayUIspero77dam1aF6qk1Oemvtc9PBqPOernUttycaiCONPbhOxH79Bk7C3FDjejbgklVhmWQHo+Sy671oQyaWpKz2WyPSUIqOdhjvpOyiCUQek7BSH7xDsU8YZCur9en62VYPOB7fMOQ0pj/cD1zl7InvUPwUplJnvGBUYz0+tW2QDeW5n1mMsGvWquS1kh5qgfFQuD2jd5V1rK7uz264sDEu0N9wpTuc2krxeEY9caJj3oXWGpwApxITeMrax9mXdlpSIuZIsRZ507vSsqFTHXbNv4gUTd4V1RqYgLeRSCyR7zrqhUxCmrsQNJG5W9KypV8d+bugFEvEEAiPmHDyDmHziAmH/IAGL+wQKI+YcJICUIEEBKEBoBiH9QBCD+4RCA+AdCAOIfApXIWMsyfwgAYv7BA4j5hw0g1n9xjO5l0scXdtqfwv3fedd1M98kjyFMenCY58dbGzrXpB0gAJIzkOYI77mSzGYBJN+m/+WwMI5DIT2ALiu37sr2jAokPVQDILm1Ep3LoYXMObTsSR3UFUAAxNBCBC0EXZZgDMEYkqswqBtmWYJZFlqIYJaF+xCMIYQ7ddyp5y3MsgyzLMEsCy1EMMvCLAtjCGGWhVlW3mLSRx2+eXtr1HKn9+0WXW4meySMW41M73YA8uSo5ZaoTxUOJLO7QhGHsCwcE1RcxeaF2pePWm6OnStPPMh4rBfR4RjbWShCQvZgYVdZtJfyKjdHe6W4C0kfyKvcK6yc7hjkXNohKnRUyJ5LZ47kV+qZtRLthf7/HtcFdMTt/F3mrVPNTO+XqM+nw0/yMEd9kaPulJptHO/xfLozfVZe5U4ZLGSBY8MhCIIgCIIgCIIgKEyO/gKzwONWD5pnRQAAAABJRU5ErkJggg=="
        />
      </defs>
    </svg>
  )
}
