import moment from 'moment'
import { ChatType, ParticipantType } from '../../../types/types'
import { apiBaseURL } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { useLongPress } from '@uidotdev/usehooks'
import { IconPostModalPin } from '../../svg/IconPostModal'

export const ChatItem = ({
  item,
  openChat,
  setIsOpen,
  setItem,
  support,
}: {
  item: ChatType
  openChat: (participants: ParticipantType[], item: ChatType) => void
  setIsOpen: (s: boolean) => void
  setItem: (s: ChatType) => void
  support?: boolean
}) => {
  const { _id } = useAppSelector((state) => state.user)
  const attrs = useLongPress(
    () => {
      setIsOpen(true)
      setItem(item)
    },
    {
      threshold: 500,
    },
  )
  return (
    <>
      {
        <button
          className="messenger__list-item"
          {...attrs}
          onClick={
            () => {
              if (item?.participants) {
                openChat(item?.participants, item)
              }
            }
            // openChat({
            //     userId: item
            //         ?.participants?.[0]
            //         ?.userId._id,
            // })
          }
        >
          {item.participants.length > 1 ? (
            <img
              src={
                item?.groupImage
                  ? `${apiBaseURL}/uploads/messenger/${item?.groupImage}`
                  : '/Images/postsBG.png'
              }
              alt=""
            />
          ) : (
            <>
              {item?.participants[!support ? 0 : 1]?.userId?.avatarFileName ? (
                <img
                  style={{ width: '40px', height: '40px' }}
                  src={`${apiBaseURL}/uploads/avatar/${item?.participants[0]?.userId?.avatarFileName}`}
                />
              ) : (
                <div
                  style={{
                    backgroundColor: 'rgba(41, 52, 65, 1)',
                    borderRadius: '100%',
                    width: '40px',
                    height: '40px',
                    color: '#fff',
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <span style={{ margin: 'auto', color: '#fff', fontSize: '14px' }}>
                    {item?.participants[!support ? 0 : 1]?.userId?.fullName.slice(0, 2)}
                  </span>
                </div>
              )}
            </>
          )}
          <div>
            <h5
              className="messenger__list-item-name"
              style={{ maxWidth: '140px', textAlign: 'left' }}
            >
              {item?.name
                ? item.name.length > 25
                  ? item.name.slice(0, 25) + '...'
                  : item.name
                : item.participants[0]?.userId.fullName.length > 25
                  ? item.participants[!support ? 0 : 1]?.userId.fullName.slice(0, 25) +
                    '...'
                  : item.participants[!support ? 0 : 1]?.userId.fullName}
              {/* {
                                                    item
                                                        ?.participants?.[0]
                                                        ?.userId
                                                        ?.fullName
                                                } */}
              {item.isPined && (
                <span className="admin__posts-list-row1-pin">
                  <IconPostModalPin />
                </span>
              )}
            </h5>
            <p
              className={`messenger__list-item-message
                                             ${
                                               // !item.lastMessage?.isRead &&
                                               // !item.notReadingMessage.length &&
                                               'messenger__list-item-message--notread'
                                             }`}
              style={{ maxWidth: '140px', textAlign: 'left' }}
            >
              {item.lastMessage?.isRead ? (
                item.lastMessage?.content
              ) : (
                <b>{item.lastMessage?.content}</b>
              )}
            </p>
          </div>
          <div>
            <div className="messenger__list-item-time">
              {moment(item.lastMessage?.timestamp).format('MMM D, h:mm A')}
            </div>
            {item.notReadingMessage.length
              ? Boolean(
                  item.notReadingMessage?.find((item) => item.senderId !== _id),
                ) && (
                  <div className="messenger__list-item-nummes">
                    {item.notReadingMessage.length}
                  </div>
                )
              : ''}
          </div>
        </button>
      }
    </>
  )
}
