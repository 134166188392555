import {
  InputHTMLAttributes,
  MouseEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { setLoader, setValueProfileReducer } from '../../../../redux/profile'
import { profileTextInfo, profileUploadCertificates } from '../../../../redux/profile'
import { apiBaseURL } from '../../../route/indexPathByRole'
import { useAppDispatch, useAppSelector } from '../../../../utils/hooks'
import { indexPathByRole } from '../../../route/indexPathByRole'

import { IconAdminClose } from '../../../svg/IconAdminHeader'
import { PublishAttachButton } from '../../publication/PublishAttachButton'
import { PublishModalAddFile } from '../../publication/PublishModalAddFile'
import { MdOutlineAttachment } from 'react-icons/md'
import {
  Button,
  Drawer,
  Flex,
  Form,
  Layout,
  Radio,
  Select,
  Space,
  Upload,
  UploadFile,
} from 'antd'
import { useAttemptListener, useStateCallback } from 'dev-masters-react-kit'
import { stepDefinitions } from '../../../../utils/hooks/useNextSetUpProfilePath'
import {
  EditUserProfileDTO,
  PROFILE_STEP,
} from '../../../../types/server-communication/user'
import { attemptUpdateCertificates } from '../../../../redux/userNew'
import useUploadImages, {
  ExtendedUploadRef,
} from '../../../../utils/hooks/useUploadImages'
import UploaderImagePreview, {
  ImagePreviewMethods,
} from '../../../ui/UploaderImagePreview'
import { CERTIFICATES_LIMIT } from '../../../../utils/appConstants'
import { useSwipeable } from 'react-swipeable'
import { UploadRef } from 'antd/es/upload/Upload'
import RcUpload from 'rc-upload'
import { UploadChangeParam } from 'antd/es/upload'
import { StandaloneScreenLayout } from '../../../ui/Layout/StandaloneScreenLayout'
import { store } from '../../../../store'

const nextPath = stepDefinitions[PROFILE_STEP.DATE_PLACE_OF_BIRTH].path
interface FormValues {
  certificates: UploadFile<any>[]
}

export const ProfileCertificates = () => {
  const UploaderImagePreviewRef = useRef<ImagePreviewMethods>(null)
  const uploadRef = useRef<ExtendedUploadRef>(null)

  const [certificatesForm] = Form.useForm<FormValues>()

  const dispatch = useAppDispatch()
  const { certificates } = useAppSelector((state) => state.userNew)
  const certificatesCurrent = Form.useWatch('certificates', certificatesForm)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const isLoading = useAttemptListener({
    store,
    attempt: attemptUpdateCertificates,
    onFulfilled: () => {
      navigate('/profile/' + nextPath)
    },
  })

  function handleSubmitForm(values: FormValues) {
    console.log('values:', values)
    const getCertificateName = (file: UploadFile) => {
      if (file.response && file.status === 'done') return file.response.filename
    }
    const _certificates: string[] = values.certificates?.map((file) =>
      getCertificateName(file),
    )
    dispatch(attemptUpdateCertificates({ certificates: _certificates }))
  }

  const navigate = useNavigate()

  const {
    uploadProps: uploadCertificatesProps /* isUploading: isCertificatesUploading */,
  } = useUploadImages({
    //TODO: change to tmp folder?
    destination: `${process.env.REACT_APP_API_BASE_URL}/api/user/upload-certificate`,
  })

  function getDefaultValues() {
    const _certificates: UploadFile[] =
      certificates?.map((filename) => ({
        uid: filename,
        name: filename,
        status: 'done',
        url: `${process.env.REACT_APP_UPLOADED_BASE_URL}/certificates/${filename}`,
      })) || []
    return {
      certificates: _certificates,
    }
  }

  const normFile = (e: UploadChangeParam<UploadFile<any>>) => {
    return e?.fileList
  }

  const handlers = useSwipeable({
    onSwipedDown: () => {
      console.log('swiped')
      setIsDrawerOpen(false)
    },
    trackMouse: false,
  })

  const [captureType, setCaptureType] =
    useStateCallback<InputHTMLAttributes<any>['capture']>(undefined)
  function handleGetPhoto(
    e: any /* React.MouseEvent<HTMLButtonElement> */,
    capture: InputHTMLAttributes<any>['capture'],
  ) {
    if (!uploadRef.current?.upload?.uploader) return
    if (captureType === capture) uploadRef.current?.upload.uploader.onClick(e)
    setCaptureType(capture, () => {
      uploadRef.current?.upload.uploader.onClick(e)
    })
  }

  return (
    <>
      <StandaloneScreenLayout
        title="Certificates"
        subTitle="You can prove your expertise by uploading certificates or documents"
        onPrimaryButtonClicked={certificatesForm.submit}
        skipPath={nextPath}
        isDisabled={isLoading || !certificatesCurrent || certificatesCurrent.length <= 0}
      >
        <Form
          form={certificatesForm}
          name="basic"
          layout="vertical"
          initialValues={{ certificates: certificates }}
          onFinish={handleSubmitForm}
          style={{ flex: 1 }}
        >
          <Form.Item name="certificates" getValueFromEvent={normFile}>
            <Upload
              ref={uploadRef}
              {...uploadCertificatesProps}
              showUploadList={true}
              listType="picture-card"
              maxCount={CERTIFICATES_LIMIT}
              accept="image/jpeg, image/png, image/jpg"
              multiple
              defaultFileList={getDefaultValues()?.certificates}
              onPreview={(file) =>
                UploaderImagePreviewRef.current?.handleFilePreview(file)
              }
              onChange={(info) =>
                info.fileList.every((file) => file.status === 'done') &&
                setIsDrawerOpen(false)
              }
              capture={captureType}
              // beforeUpload={() => {
              //   return false
              // }}
              openFileDialogOnClick={false}
            >
              <button
                onClick={() => setIsDrawerOpen(true)}
                type="button"
                style={{ flex: 1, height: '100%' }}
              >
                <MdOutlineAttachment size={30} />
                <div style={{ marginTop: 8, fontSize: '0.75em' }}>
                  Add up to {CERTIFICATES_LIMIT} photos
                </div>
              </button>
            </Upload>
          </Form.Item>
        </Form>
      </StandaloneScreenLayout>
      <Drawer
        title={
          <div {...handlers}>
            <h5 className="publish__modaladd-title">Add certificates</h5>
            <h6 className="publish__modaladd-subtitle">Choose a type of a content</h6>
          </div>
        }
        placement="bottom"
        height={'50%'}
        closable={false}
        onClose={() => setIsDrawerOpen(false)}
        open={isDrawerOpen}
        key={'certificates'}
      >
        <Flex justify="space-around" align="center">
          <Button
            style={{ height: '10em', width: '10em' }}
            onClick={(e) => handleGetPhoto(e, false)}
          >
            <Flex vertical>
              <img src="/Images/CameraRoll.png" alt="" />
              <span>Camera Roll</span>
            </Flex>
          </Button>
          <Button
            style={{ height: '10em', width: '10em' }}
            onClick={(e) => handleGetPhoto(e, 'environment')}
          >
            <Flex vertical>
              <img src="/Images/TakePhotoNow.png" alt="" />
              <span>Take now</span>
            </Flex>
          </Button>
        </Flex>
      </Drawer>
      <UploaderImagePreview ref={UploaderImagePreviewRef} />
    </>
  )
}
