import { NominatimSearchResponse } from '../../../redux/statelessRequests/address'

//TODO remove
export interface AddressDTO {
  readonly displayName: string
  readonly lat: number
  readonly lon: number
  readonly house?: string
  readonly street?: string
  readonly city?: string
  readonly postcode?: string
  readonly country?: string
}

export function convertNominatimResponseToAddressDTO(response: NominatimSearchResponse) {
  return {
    displayName: response.display_name,
    lat: Number(response.lat),
    lon: Number(response.lon),
    house: response.address.house_number,
    city: response.address.city || response.address.town,
    postcode: response.address.postcode,
    country: response.address.country_code,
  } as AddressDTO
}

// export interface NominatimSearchResponse {
//   place_id: string
//   display_name: string
//   lat: string
//   lon: string
//   address: {
//     road?: string
//     city?: string
//     hamlet?: string
//     city_district?: string
//     municipality?: string
//     town?: string
//     village?: string
//     state?: string
//     country?: string
//     postcode?: string
//   }
// }
