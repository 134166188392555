import { useEffect, useState } from 'react'
import { UserHttp } from '../../../http/user-http'
import {
  UserInitialStateInterface,
  UserInitialStateWithDistanceInterface,
  setLoader,
} from '../../../redux/users'
import { calculateDistance } from '../../../utils/calculateRate'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { inside, insideCircle } from '../../../utils/inside'
import { AllUserChatList } from '../../general-components/messenger/AllUserChatList'
import { UserHeaderUserChatList } from '../header/UserHeaderChatList'

export const MessegesUserChatList = () => {
  const [search, setSearch] = useState('')
  const [users, setUsers] = useState<UserInitialStateWithDistanceInterface[]>([])
  const { _id } = useAppSelector((state) => state.user)
  const { polygonCoords, radiusSize, coordinates } = useAppSelector(
    (state) => state.profile,
  )
  const dispatch = useAppDispatch()
  const [load, setLoad] = useState(false)
  const [countUsers, setCountUsers] = useState(0)

  useEffect(() => {
    getUsers()
  }, [])

  useEffect(() => {
    if (!countUsers) {
      users.map((item) => {
        if (item?.coords?.lat) {
          if (polygonCoords) {
            if (
              item.coords &&
              inside([item.coords?.lat, item.coords?.lng], polygonCoords)
            ) {
              setCountUsers((s) => s + 1)
            }
          }
          if (radiusSize) {
            if (
              item.coords &&
              insideCircle([item.coords.lat, item.coords.lng], radiusSize, [
                coordinates.lat,
                coordinates.lng,
              ])
            ) {
              setCountUsers((s) => s + 1)
            }
          }
        }
      })
    }
  }, [users])

  const getUsers = async () => {
    setLoad(true)
    const res: UserInitialStateInterface[] = await UserHttp.getAllUsers()
    let dist: UserInitialStateWithDistanceInterface[] = []
    res.filter((item) => {
      if (item.coords) {
        let distance = calculateDistance(coordinates, item.coords)
        dist.push({ distance, ...item })
      }
    })
    setUsers(dist.sort((f, s) => f.distance - s.distance))
    setLoad(false)
    dispatch(setLoader(false))
  }
  return (
    <>
      <UserHeaderUserChatList
        headerTitle="Invite Friends"
        subTitle={
          <>
            <b>{countUsers}</b> neibs in your interest zone
          </>
        }
      />
      <AllUserChatList
        setCount={setCountUsers}
        load={load}
        setSearch={setSearch}
        search={search}
        users={users}
      />
    </>
  )
}
