import moment from 'moment'
import { useEffect, useState } from 'react'
import CsvDownload from 'react-csv-downloader'
import { Datas } from 'react-csv-downloader/dist/esm/lib/csv'
import { Circle, MapContainer, Polygon, TileLayer } from 'react-leaflet'
import { useLocation, useNavigate } from 'react-router-dom'
import { AdvertisementHttp } from '../../../http/advertisement'
import { Advertise } from '../../../types/types'
import { apiBaseURL } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import {
  IconBottomChevrons,
  IconLeftChevrons,
  IconRightChevrons,
} from '../../svg/IconChevrons'
import { IconClock } from '../../svg/IconClock'
import { IconHome } from '../../svg/IconHome'
import { IconNeibs } from '../../svg/IconPassEye'
import { IconServicesAllPoint } from '../../svg/IconServicesAll'
import { IconSharp } from '../../svg/IconSharp'
import { UserHeader } from '../../user-components/header/UserHeader'
import { AdminPanelStatisticBar1 } from '../admin-panel/AdminPanelStatisticBar1'

export const AdvertisementItem = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const { role, avatarFileName, fullName } = useAppSelector((state) => state.user)

  const [item, setItem] = useState<Advertise>()
  const [info, setInfo] = useState<boolean>(true)
  const [statistics, setStatistics] = useState<boolean>(false)

  useEffect(() => {
    const effect = async () => {
      const res = await AdvertisementHttp.getAdvertisementById({
        adId: location.state.adId,
      })
      setItem(res)
    }
    effect()
  }, [])

  const toDateObject = (date: string) => {
    console.log(date)
    const normalDate = date.split('T')[0]
    const normalTime = date.split('T')[1].split('.')[0]
    return { normalDate, normalTime }
  }
  const dataMockNormal: Datas = [
    {
      Clicks: `${item?.clicks}`,
    },
    {
      Views: `${item?.views}`,
    },
  ]
  const [dataNormal, setDataNormal] = useState<Datas>(dataMockNormal)
  return (
    <>
      <UserHeader>
        <div className="profileinfo__header">
          <button
            className="profileinfo__header-chevron"
            onClick={() => {
              navigate(-1)
            }}
          >
            <IconLeftChevrons />
          </button>
          <h5 className="profileinfo__header-text">{item?.title}</h5>
        </div>
      </UserHeader>
      <div className="advertisement">
        <div className="advertisement__menu">
          <h5
            className={info ? 'advertisement__menu-active' : 'advertisement__menu-text'}
            onClick={() => {
              setInfo(true)
              setStatistics(false)
            }}
          >
            Info
          </h5>
          <h5
            className={
              statistics ? 'advertisement__menu-active' : 'advertisement__menu-text'
            }
            onClick={() => {
              setInfo(false)
              setStatistics(true)
            }}
          >
            Statistics
          </h5>
        </div>
        {item && statistics && (
          <div className="advertisement-statistics">
            <div className="advertisement-statistics-line">
              <h5>
                Total
                <IconBottomChevrons />
              </h5>
              <CsvDownload
                filename={`user_stat(${moment(new Date()).format('DD_MM_YYYY')})`}
                datas={dataNormal}
                extension=".csv"
                className="advertisement-statistics-btn"
                bom={false}
                separator=";"
                wrapColumnChar={''}
                chunkSize={1}
                newLineAtEnd={true}
              >
                Download
              </CsvDownload>
            </div>
            <div className="advertisement-item-views">
              <h5>
                Users <b>{1.232}</b> | Views <b>{item.clicks}</b> | Clicks{' '}
                <b>{item.views}</b>
              </h5>
            </div>
            {item?.center?.length ? (
              <div className="advertisement-item-map">
                {
                  <MapContainer
                    dragging={false}
                    zoomControl={false}
                    center={item.center}
                    minZoom={8}
                    maxZoom={8}
                    zoom={8}
                    scrollWheelZoom={false}
                  >
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                      url="https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png"
                      subdomains="abcd"
                    />
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                      url="https://{s}.basemaps.cartocdn.com/dark_only_labels/{z}/{x}/{y}{r}.png"
                      subdomains="abcd"
                    />
                    {item?.polygonCoords && <Polygon positions={item.polygonCoords} />}
                    {item?.radiusSize && (
                      <Circle center={item.center} radius={item.radiusSize * 1000} />
                    )}
                  </MapContainer>
                }
              </div>
            ) : (
              ''
            )}
            <div className="advertisement-statistics-line">
              <h5>Statistics</h5>
            </div>
            <div className="advertisement-statistics-line">
              <div className="advertisement-statistics-filter">
                Filter 1
                <IconBottomChevrons />
              </div>
              <div className="advertisement-statistics-filter">
                Filter 2
                <IconBottomChevrons />
              </div>
              <div className="advertisement-statistics-filter">
                Filter 3
                <IconBottomChevrons />
              </div>
            </div>
            <div>
              <div className="admin__panel__statistic-body">
                <div className="admin__panel__statistic-title">
                  <h6 className="admin__panel__statistic-title-main">User Stat</h6>
                  <CsvDownload
                    filename={`user_stat(${moment(new Date()).format('DD_MM_YYYY')})`}
                    datas={dataNormal}
                    extension=".csv"
                    className="admin__panel__statistic-title-download"
                    bom={false}
                    separator=";"
                    wrapColumnChar={''}
                    chunkSize={1}
                    newLineAtEnd={true}
                  >
                    Download
                  </CsvDownload>
                </div>
                <AdminPanelStatisticBar1
                  dataRender={[
                    { name: 'Clicks', value: item.clicks, fill: '#FF6633' },
                    { name: 'Views', value: item.views, fill: '#3361FF' },
                  ]}
                />
              </div>
            </div>
          </div>
        )}
        {item && info && (
          <div className="advertisement-item">
            <div className="advertisement-item-img">
              <img src={`${apiBaseURL}/uploads/advertisement/${item.fileName}`} />
              <button className="advertisement-item-allPoint" onClick={() => {}}>
                <IconServicesAllPoint />
              </button>
            </div>
            <div className="advertisement-item-sponsor">
              <div style={{ display: 'flex', gap: '10px' }}>
                <div className="admin__posts-list-row1-img" onClick={() => {}}>
                  <img
                    src={
                      avatarFileName
                        ? `${apiBaseURL}/uploads/avatar/${avatarFileName}`
                        : '/Images/Profile.jpg'
                    }
                    alt=""
                  />
                </div>
                <div className="advertisement-item-sponsor-text">
                  <h6>{fullName}</h6>
                </div>
              </div>
            </div>
            <div className="advertisement-item-title">
              <h5>{item.title}</h5>
              <h6>{item.text}</h6>
            </div>
            <div className="advertisement-item-info">
              <div className="advertisement-item-info-item">
                <IconSharp />
                <span>1271</span>
              </div>
              <div className="advertisement-item-info-item">
                <IconNeibs />
                <span>Mobile</span>
              </div>
              <div className="advertisement-item-info-item">
                <IconHome />
                <span>Discovery | {item.position}</span>
              </div>
              <div className="advertisement-item-info-item">
                <IconClock />
                <h6>{item.link}</h6>
              </div>
            </div>
            <div className="advertisement-item-data">
              <div className="advertisement-item-data-block" style={{}}>
                <h5>start date</h5>
                <h6>
                  {toDateObject(item.duration[0]).normalDate} / <br />{' '}
                  {toDateObject(item.duration[0]).normalTime}
                </h6>
              </div>
              <div className="advertisement-item-data-block-next">
                <IconRightChevrons fill="rgba(195, 202, 217, 1)" />
              </div>
              <div className="advertisement-item-data-block">
                <h5>due date</h5>
                <h6>
                  {toDateObject(item.duration[1]).normalDate} / <br />{' '}
                  {toDateObject(item.duration[1]).normalTime}
                </h6>
              </div>
            </div>
            <div className="advertisement-item-views">
              <h5>
                Users <b>{1.232}</b> | Views <b>{item.clicks}</b> | Clicks{' '}
                <b>{item.views}</b>
              </h5>
            </div>
            {item?.center?.length ? (
              <div className="advertisement-item-map">
                {
                  <MapContainer
                    dragging={false}
                    zoomControl={false}
                    center={item.center}
                    minZoom={8}
                    maxZoom={8}
                    zoom={8}
                    scrollWheelZoom={false}
                  >
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                      url="https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png"
                      subdomains="abcd"
                    />
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                      url="https://{s}.basemaps.cartocdn.com/dark_only_labels/{z}/{x}/{y}{r}.png"
                      subdomains="abcd"
                    />
                    {item?.polygonCoords && <Polygon positions={item.polygonCoords} />}
                    {item?.radiusSize && (
                      <Circle center={item.center} radius={item.radiusSize * 1000} />
                    )}
                  </MapContainer>
                }
              </div>
            ) : (
              ''
            )}
            <div className="advertisement__add-audience-item">
              Interests
              <div className="advertisement__add-audience-item-svg">
                <IconLeftChevrons fill="rgba(195, 202, 217, 1)" />
              </div>
            </div>
            <div className="advertisement__add-audience-interests">
              {item?.interests?.map((it, index) => (
                <div
                  className={`profile__identity-list-item advertisement__add-audience-interest`}
                  key={index}
                >
                  <div className="profile__identity-list-item-text">{it.title}</div>
                </div>
              ))}
            </div>
            <div className="advertisement__add-audience-item">
              Age & Gender
              <div className="advertisement__add-audience-item-svg">
                <IconLeftChevrons fill="rgba(195, 202, 217, 1)" />
              </div>
            </div>
            {item?.age && item.gender && (
              <div className="advertisement__add-audience-gender">
                <div className="advertisement__add-main-line">
                  {item?.gender.map((it) => (
                    <div className={`advertisement__add-main-line-item `}>
                      <img
                        style={{ padding: '3px 0' }}
                        width={109}
                        height={92}
                        alt=""
                        src={`/Images/${it.toLowerCase()}.png`}
                      />
                      <span>{it}</span>
                    </div>
                  ))}
                </div>
                {item.age.length ? (
                  <div className="advertisement__add-audience-gender-age">
                    <b>Age</b>
                    <span>
                      {item?.age[0]}-{item?.age[1]}
                    </span>
                  </div>
                ) : (
                  ''
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  )
}
