import moment from 'moment'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AdvertisementHttp } from '../../../http/advertisement'
import { Advertise } from '../../../types/types'
import { apiBaseURL, indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconLeftChevrons, IconRightChevrons } from '../../svg/IconChevrons'
import { IconPencil } from '../../svg/IconPencil'
import { IconServicesAllPoint } from '../../svg/IconServicesAll'
import { Loader } from '../../ui/Loader'
import { UserHeader } from '../../user-components/header/UserHeader'
import { AdvertisementPine } from './AdvertisementPine'

export const AdvertisementActive = ({ past }: { past?: boolean }) => {
  const location = useLocation()
  const navigate = useNavigate()

  const [ad, setAd] = useState<Advertise>()
  const [load, setLoad] = useState(false)
  useEffect(() => {
    setLoad(true)
    AdvertisementHttp.getAdvertisementById({ adId: location.state.adId }).then((res) => {
      setAd(res)
      setLoad(false)
    })
  }, [])
  const { role } = useAppSelector((state) => state.user)
  return (
    <>
      {!load ? (
        <>
          <UserHeader>
            <div className="profileinfo__header">
              <button
                className="profileinfo__header-chevron"
                onClick={() => {
                  navigate(-1)
                }}
              >
                <IconLeftChevrons />
              </button>
              <h5 className="profileinfo__header-text">{ad?.title}</h5>
              <button style={{ position: 'absolute', right: '20px', margin: '10px 0' }}>
                <IconServicesAllPoint />
              </button>
            </div>
          </UserHeader>
          <div className="advertisement__active-preview">
            <div className="advertisement__active-preview-item">
              <div className="advertisement__active-preview-item-img">
                <img src={`${apiBaseURL}/uploads/advertisement/${ad?.fileName}`} />
                <div className="advertisement__active-preview-item-img-stats">
                  <h5>
                    Users <b>1234</b> |
                  </h5>
                  <h5>
                    Views <b>83212</b> |
                  </h5>
                  <h5>
                    Clicks <b>8912</b>
                  </h5>
                </div>
              </div>
              {!past && (
                <div className="ads__container-item-costs">
                  <div className="ads__container-item-costs-text">
                    <h6>Total Expenses</h6>
                    <h5>{ad?.budget}$</h5>
                  </div>
                  <div className="ads__container-item-costs-text">
                    <h6>CPC</h6>
                    <h5>0.17$</h5>
                  </div>
                  <div className="ads__container-item-costs-text">
                    <h6>Expenses</h6>
                    <h5>0.34$</h5>
                  </div>
                </div>
              )}
            </div>
            <h5 className="advertisement__active-preview-title">Settings</h5>
            <div
              className="advertisement__add-audience-item"
              onClick={() =>
                navigate(`${indexPathByRole(role)}/advertisement/add-text-data`, {
                  state: { adId: ad?._id },
                })
              }
            >
              <div className="advertisement__add-audience-item-dbltext">
                <h5>{ad?.title}</h5>
                <h6>{ad?.type}</h6>
              </div>
              <div
                style={{ transform: 'none' }}
                className="advertisement__add-audience-item-svg"
              >
                <IconPencil color="rgba(125, 143, 179, 1)" />
              </div>
            </div>
            <div
              className="advertisement__add-audience-item"
              onClick={() =>
                navigate(`${indexPathByRole(role)}/advertisement/budget`, {
                  state: { adId: ad?._id },
                })
              }
            >
              <div className="advertisement__add-audience-item-dbltext">
                <h5>Budget & duration</h5>
                <h6>
                  {moment(ad?.duration[0]).format('DD MMMM YYYY')} -{' '}
                  {moment(ad?.duration[1]).format('DD MMMM YYYY')} <br /> {ad?.budget}$
                  USD
                </h6>
              </div>
              <div
                style={{ transform: 'none' }}
                className="advertisement__add-audience-item-svg"
              >
                <IconPencil color="rgba(125, 143, 179, 1)" />
              </div>
            </div>
            <div
              className="advertisement__add-audience-item"
              onClick={() =>
                navigate(
                  `${indexPathByRole(role)}/advertisement/add-community-selection`,
                  {
                    state: { adId: ad?._id },
                  },
                )
              }
            >
              <div className="advertisement__add-audience-item-dbltext">
                <h5>Auditory</h5>
                {ad?.age && ad.interests && (
                  <h6>
                    {ad?.gender?.map((it) => it + ',')} | {ad?.age[0]}-{ad.age[1]} years{' '}
                    <br /> Interests:{' '}
                    {ad.interests?.slice(0, 3).map((it) => it.title + ',')}{' '}
                  </h6>
                )}
              </div>
              <div
                style={{ transform: 'none' }}
                className="advertisement__add-audience-item-svg"
              >
                <IconPencil color="rgba(125, 143, 179, 1)" />
              </div>
            </div>
            <div
              className="advertisement__add-audience-item"
              onClick={() =>
                navigate(`${indexPathByRole(role)}/advertisement/add`, {
                  state: { adId: ad?._id },
                })
              }
            >
              <div className="advertisement__add-audience-item-dbltext">
                <h5>Ad position</h5>
                <h6>{ad?.position}</h6>
              </div>
              <div
                style={{ transform: 'none' }}
                className="advertisement__add-audience-item-svg"
              >
                <IconPencil color="rgba(125, 143, 179, 1)" />
              </div>
            </div>
            <h5 className="advertisement__active-preview-title">Statistics</h5>
            <div className="advertisement__active-preview-stats">
              <div className="advertisement__active-preview-stats-text">
                <h5>{ad?.numberView}</h5>
                <h6>Account Reached</h6>
              </div>
              <AdvertisementPine men={10} women={4} />
              <div className="advertisement__active-preview-stats-countries">
                <div className="advertisement__active-preview-stats-countries-title">
                  <h6>Country</h6>
                  <h6>Visitors</h6>
                </div>
                <div className="advertisement__active-preview-stats-countries-list">
                  <div className="advertisement__active-preview-stats-countries-list-item">
                    <div className="advertisement__active-preview-stats-countries-list-item-name">
                      <img src="/Images/serviceMark.svg" alt="" />
                      <h5>Country</h5>
                    </div>
                    <h5>{ad?.numberView}</h5>
                    <IconRightChevrons />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </>
  )
}
