import TextareaAutosize from '@mui/material/TextareaAutosize'
import { AxiosResponse } from 'axios'
import moment from 'moment'
import { useContext, useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { SocketContext } from '../../../context/SocketContext'
import $api from '../../../http'
import { SOCKET_MESSENDER_EVENT } from '../../../types/enum'
import { ChatType, MessageType, OpenChatData } from '../../../types/types'
import { apiBaseURL, indexPathByRole } from '../../route/indexPathByRole'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { IconAdminClose } from '../../svg/IconAdminHeader'
import { IconArrachFile } from '../../svg/IconArrachFile'
import { IconCopy } from '../../svg/IconCopy'
import { IconDelete } from '../../svg/IconDelete'
import { IconEdit } from '../../svg/IconEdit'
import { IconFroward } from '../../svg/IconForward'
import { IconPostsRepost } from '../../svg/IconPosts'
import { IconSmile } from '../../svg/IconSmile'
import { Blur } from '../../ui/Blur'
import { SelectSticker } from '../../ui/SelectSticker'
import { TempNotification } from '../../ui/TempNotification'
import { Message } from './Message'
import { ModalSmile } from './ModalSmile'
import { VoiceMessage } from './VoiceMessage'
import { VoiceButton } from '../../ui/VoiceButton'

export const ChatMessage = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const props: {
    chat: ChatType
  } = location.state
  const messege: { text: string } = location.state
  const myRef = useRef<null | HTMLDivElement>(null)
  const { _id, role, fullName, avatarFileName } = useAppSelector((state) => state.user)
  const { socket } = useContext(SocketContext)
  const [linkSent, setLinkSent] = useState(false)
  const [messageList, setMessageList] = useState<MessageType[]>([])
  const [message, setMessage] = useState(messege.text && !linkSent ? messege.text : '')
  const [chatId, setChatId] = useState('')
  const [isOpenSmile, setIsOpenSmile] = useState(false)

  const [image, setImage] = useState<File | null>(null)
  const [imageUrl, setImageUrl] = useState<string>('')
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [IsOpenVoice, setIsOpenVoice] = useState<boolean>(false)

  const [blur, setBlur] = useState<boolean>(false)
  const [side, setSide] = useState<string>('r')
  const [messageElement, setMessageElement] = useState<MessageType>()
  const [Index, setIndex] = useState<number>()
  const [SmileSelect, setSmileSelect] = useState<string>('')
  const [isOpenCopy, setisOpenCopy] = useState(false)
  const [Like, setLike] = useState<string>('')
  const dispatch = useAppDispatch()

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value)
  }
  console.log(location.state)
  useEffect(() => {
    const effectBody = async () => {
      const resOpenChat: AxiosResponse<OpenChatData> = await $api.post(
        'messenger/open-chat',
        {
          isSupport: props.chat?.isSupport ? props?.chat.isSupport : false,
          chatId: props.chat.chatId,
        },
      )

      setChatId(resOpenChat.data.chatId)

      const resMessageList: AxiosResponse<MessageType[]> = await $api.post(
        'messenger/list-message',
        {
          chatId: resOpenChat.data.chatId,
        },
      )

      resMessageList.data.forEach((item) => {
        if (!item.isRead && item.senderId._id !== _id) {
          $api.post('messenger/read-message', { messageId: item?._id })
        }
      })

      setMessageList(resMessageList.data)

      if (socket) {
        socket.current?.emit(
          SOCKET_MESSENDER_EVENT.JOIN_ROOM,
          String(resOpenChat.data.chatId),
        )
        socket.current?.on(
          SOCKET_MESSENDER_EVENT.GET_PRIVATE_MESSAGE,
          (
            chatId: string,
            senderId: {
              _id: string
              fullName: string
              avatarFileName: string | null
            },
            content: string,
            timestamp: Date,
            isRead: boolean,
            file: string | null,
            forward: boolean,
            senderIdold: string,
            audio: boolean,
            like: string,
            messageId: string | null,
            _id: string,
          ) => {
            setMessageList((s) => [
              ...s,
              {
                chatId,
                senderId,
                content,
                timestamp,
                isRead,
                file,
                forward,
                senderIdold,
                audio,
                like,
                _id,
                messageId,
              },
            ])
          },
        )
      }
    }

    effectBody()
  }, [])

  useEffect(() => {}, [messageList])

  useEffect(() => {
    if (myRef.current) {
      myRef.current.scrollIntoView()
    }
  }, [messageList, messageList.length])

  const sendMessage = async () => {
    let fileName: null | string = null
    if (image) {
      const formData = new FormData()

      formData.append('file', image)

      const res: AxiosResponse<string> = await $api.post(
        'messenger/file-message',
        formData,
      )
      fileName = res.data
    }

    if (socket) {
      socket.current?.emit(SOCKET_MESSENDER_EVENT.SEND_PRIVATE_MESSAGE, {
        chatId,
        senderId: _id,
        content: message,
        timestamp: new Date(),
        isRead: true,
        file: fileName,
      })
      setMessageList((s) => [
        ...s,
        {
          chatId,
          senderId: {
            _id,
            fullName,
            avatarFileName,
          },
          content: message,
          timestamp: new Date(),
          isRead: true,
          file: fileName,
          forward: false,
          senderIdold: '',
          audio: false,
          like: '',
          messageId: null,
          _id: '',
        },
      ])
      setMessage('')
      setLinkSent(true)
      removeFile()
    }
  }

  const replyMessageSend = async () => {
    let fileName: null | string = null
    if (image) {
      const formData = new FormData()

      formData.append('file', image)

      const res: AxiosResponse<string> = await $api.post(
        'messenger/file-message',
        formData,
      )
      fileName = res.data
    }

    if (socket && messageElement) {
      socket.current?.emit(SOCKET_MESSENDER_EVENT.REPLY_PRIVATE_MESSAGE, {
        chatId,
        senderId: _id,
        content: message,
        timestamp: new Date(),
        isRead: true,
        file: fileName,
        messageId: messageElement?._id,
      })
      setMessageList((s) => [
        ...s,
        {
          chatId,
          senderId: {
            _id,
            fullName,
            avatarFileName,
          },
          content: message,
          timestamp: new Date(),
          isRead: true,
          file: fileName,
          forward: false,
          senderIdold: '',
          audio: false,
          messageId: messageElement?._id,
          like: '',
          _id: '',
        },
      ])
      setMessage('')
      removeFile()
    }
  }

  const updateList = (NewMessage: MessageType) => {
    if (NewMessage) {
      setMessageList((s) => [...s, NewMessage])
    }
  }

  const updateFieldChanged = (index: any, val: string) => {
    let newArr = [...messageList]
    newArr[index].like = val

    setMessageList(newArr)
  }

  const removeFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
    setImage(null)
    setImageUrl('')
  }

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null
    setImage(file)
    if (file) {
      const url = URL.createObjectURL(file)
      setImageUrl(url)
      setMessage('Photo')
    }
  }

  const deleteMessage = async (item: MessageType) => {
    if (socket) {
      socket.current?.emit(SOCKET_MESSENDER_EVENT.DELETE_PRIVATE_MESSAGE, {
        chatId,
        senderId: _id,
        messageId: item._id,
      })
    }
    let array = [...messageList]
    let index = array.indexOf(item)
    if (index !== -1) {
      array.splice(index, 1)
      setMessageList(array)
    }
  }
  const editMessageSend = async (item: MessageType) => {
    if (socket) {
      socket.current?.emit(SOCKET_MESSENDER_EVENT.EDIT_PRIVATE_MESSAGE, {
        chatId,
        senderId: _id,
        content: message,
        messageId: item._id,
      })
    }
    let array = [...messageList]
    let index = array.indexOf(item)
    array[index] = {
      ...item,
      content: message,
    }
    setIsEdit(false)
    setMessage('')
    setMessageList(array)
  }

  const removeLike = async (item: MessageType, index: any) => {
    if (socket) {
      socket.current?.emit(SOCKET_MESSENDER_EVENT.DELETE_PRIVATE_MESSAGE_LIKE, {
        chatId,
        senderId: item.senderId,
        // likeSenderId
        timestamp: item.timestamp,
        like: Like,
        isRead: true,
      })
      updateFieldChanged(index, '')
    }
  }
  const selectSmile = async (smile: string) => {
    if (socket && messageElement) {
      socket.current?.emit(SOCKET_MESSENDER_EVENT.SEND_PRIVATE_MESSAGE_LIKE, {
        chatId: messageElement.chatId,
        senderId: messageElement.senderId,
        like: smile,
        timestamp: messageElement.timestamp,
        isRead: true,
      })
      updateFieldChanged(Index, smile)
    }
  }
  const copyMessage = (url: string) => {
    navigator.clipboard.writeText(url)
    setisOpenCopy(true)
  }
  const [isEdit, setIsEdit] = useState(false)
  const editMessage = () => {
    if (messageElement) {
      setIsEdit(true)
      setBlur(false)
      setMessage(messageElement?.content)
    }
  }
  const [isReply, setIsReply] = useState(false)
  const replyMessage = () => {
    setIsReply(true)
    setBlur(false)
  }
  const getMessageById = (mId: string) => {
    let array = [...messageList]
    return array.find((it) => (it._id === mId ? it.content : ''))
  }
  return (
    <>
      <div className="messenger__chat-messages">
        {messageList.map((item, index) => (
          <div className="messenger__chat-messages-item">
            {item?.senderId._id !== _id && (
              <div className="messenger__chat-messages-sender">
                {item?.senderId?.avatarFileName ? (
                  <img
                    style={{
                      borderRadius: '100%',
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                    src={`${apiBaseURL}/uploads/avatar/${item?.senderId?.avatarFileName}`}
                  />
                ) : (
                  <div
                    style={{
                      backgroundColor: 'rgba(41, 52, 65, 1)',
                      borderRadius: '100%',
                      width: '100%',
                      height: '100%',
                      color: '#fff',
                      textAlign: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <span style={{ margin: 'auto', fontSize: '16px' }}>
                      {item?.senderId?.fullName && item?.senderId?.fullName?.slice(0, 2)}
                    </span>
                  </div>
                )}
              </div>
            )}
            <div
              onClick={() => {
                if (item?.senderId._id !== _id) {
                  setSide('l')
                } else {
                  setSide('r')
                }
                setBlur(true)
                setIndex(index)
                setMessageElement(item)
              }}
              className={`messenger__chat-messages-message messenger__chat-messages-message-${
                item?.senderId._id === _id ? 'r' : 'l'
              }`}
            >
              {item?.senderId._id !== _id && (
                <h6 className="messenger__chat-messages-message-sender">
                  {item?.senderId?.fullName}
                </h6>
              )}
              {item.messageId && (
                <span
                  style={
                    item.senderId._id === _id
                      ? {
                          fontSize: '12px',
                          fontWeight: '700',
                          backgroundColor: '#2947af',
                          borderRadius: '5px',
                          padding: '2px 8px',
                          maxWidth: '100px',
                          minWidth: '60px',
                          borderLeft: '3px solid #15104d',
                        }
                      : {
                          fontSize: '12px',
                          fontWeight: '700',
                          backgroundColor: 'rgba(247, 248, 250, 1)',
                          borderRadius: '5px',
                          padding: '2px 8px',
                          maxWidth: '100px',
                          minWidth: '60px',
                          borderLeft: '3px solid rgba(0, 45, 227, 1)',
                        }
                  }
                >
                  {getMessageById(item.messageId)?.content}
                </span>
              )}
              {item.forward && (
                <span className="forward">{`From: ${item.senderIdold}`}</span>
              )}
              {item.file && !item.audio && (
                <img src={`${apiBaseURL}/uploads/messenger/${item.file}`} alt="" />
              )}
              {item.file && item.audio && (
                <VoiceMessage
                  Side={item?.senderId._id === _id ? 'r' : 'l'}
                  Audio={`${apiBaseURL}/uploads/messenger/${item.file}`}
                />
              )}
              {item.content.toLowerCase().includes('http') ? (
                <Link
                  to={item.content}
                  style={{
                    overflowWrap: 'break-word',
                    wordWrap: 'break-word',
                    maxWidth: '90%',
                    textDecoration: 'underline',
                  }}
                >
                  {item.content}
                </Link>
              ) : (
                <span
                  style={{
                    overflowWrap: 'break-word',
                    wordWrap: 'break-word',
                    maxWidth: '90%',
                  }}
                >
                  {item?.content}
                </span>
              )}
              {item?.like && (
                <div
                  className={`messanger__chat__like`}
                  onClick={(e) => {
                    e.stopPropagation()
                    removeLike(item, index)
                  }}
                >
                  {item.like}
                </div>
              )}
              {/* {item?.like ? <div className={`messanger__chat__like.active`}>{item.like}</div> : ""} */}
              <Message
                senderId={item.senderId._id}
                index={index}
                updateList={updateFieldChanged}
                chatId={chatId}
                Like={Like}
                setLike={setLike}
                audio={item.audio}
                time={moment(item?.timestamp).format('h:mm A')}
                timeStamp={item.timestamp}
                delete={() => deleteMessage(item)}
                message={item.content}
                senderIdold={item.senderId._id}
                fileName={item.file}
                type={item.senderId._id === _id ? 'r' : 'l'}
              />
            </div>
          </div>
        ))}
        <div ref={myRef} />
      </div>

      <div className="messenger__chat-sender-body">
        {isEdit && (
          <div className="messenger__chat-edit">
            <div className="messenger__chat-edit-top">
              <h5>Edit message: </h5>
              <div
                style={{ margin: '0 10px' }}
                onClick={() => {
                  setIsEdit(false)
                  setMessage('')
                }}
              >
                <IconAdminClose />
              </div>
            </div>
            {messageElement?.content.toLowerCase().includes('http') ? (
              <Link
                to={messageElement?.content}
                style={{
                  overflowWrap: 'break-word',
                  wordWrap: 'break-word',
                  maxWidth: '80px',
                  textDecoration: 'underline',
                }}
              >
                {messageElement?.content}
              </Link>
            ) : (
              <h6
                style={{
                  overflowWrap: 'break-word',
                  wordWrap: 'break-word',
                  maxWidth: '80px',
                }}
              >
                {messageElement?.content}
              </h6>
            )}
          </div>
        )}
        {isReply && (
          <div className="messenger__chat-edit">
            <div className="messenger__chat-edit-top">
              <h5>Reply on message: </h5>
              <div style={{ margin: '0 10px' }} onClick={() => setIsReply(false)}>
                <IconAdminClose />
              </div>
            </div>
            {messageElement?.content.toLowerCase().includes('http') ? (
              <Link
                to={messageElement?.content}
                style={{
                  overflowWrap: 'break-word',
                  wordWrap: 'break-word',
                  maxWidth: '80px',
                  textDecoration: 'underline',
                }}
              >
                {messageElement?.content}
              </Link>
            ) : (
              <h6
                style={{
                  overflowWrap: 'break-word',
                  wordWrap: 'break-word',
                  maxWidth: '80px',
                }}
              >
                {messageElement?.content}
              </h6>
            )}
          </div>
        )}
        {imageUrl && (
          <div className="messenger__chat-sender-file">
            <img src={`${imageUrl}`} alt="" />
            <div>{image?.name}</div>
            <button onClick={removeFile}>
              <IconAdminClose />
            </button>
          </div>
        )}
        <div className="messenger__chat-sender">
          <label htmlFor="file-avatar-profile" className="messenger__chat-sender-attach">
            <IconArrachFile />
          </label>
          <input
            multiple={false}
            id="file-avatar-profile"
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileSelect}
          />
          <button
            className="messenger__chat-sender-smile"
            onClick={() => setIsOpenSmile(true)}
          >
            <IconSmile />
          </button>
          <TextareaAutosize
            value={message}
            onChange={handleChange}
            className="messenger__chat-sender-autoresize"
            minRows={1}
            placeholder="Enter message"
          />
          {isEdit && messageElement ? (
            <button
              className={`messenger__chat-sender-send ${
                !message && 'messenger__chat-sender-send--disabled'
              }`}
              onClick={() => editMessageSend(messageElement)}
              disabled={!message}
            >
              Edit
            </button>
          ) : (
            <>
              {isReply && messageElement ? (
                <button
                  className={`messenger__chat-sender-send ${
                    !message && 'messenger__chat-sender-send--disabled'
                  }`}
                  onClick={replyMessageSend}
                  disabled={!message}
                >
                  Reply
                </button>
              ) : (
                <button
                  className={`messenger__chat-sender-send ${
                    !message && 'messenger__chat-sender-send--disabled'
                  }`}
                  onClick={sendMessage}
                  disabled={!message}
                >
                  Send
                </button>
              )}
              <VoiceButton
                updateList={updateList}
                chatId={chatId}
                _id={_id}
                avatarFileName={avatarFileName}
                fullName={fullName}
                setIsOpenVoice={setIsOpenVoice}
                IsOpenVoice={IsOpenVoice}
              />
            </>
          )}
        </div>
      </div>
      {isOpenSmile && (
        <ModalSmile
          setIsOpenSmile={setIsOpenSmile}
          setMessage={setMessage}
          message={message}
        />
      )}
      {blur && messageElement && (
        <Blur setBlur={setBlur}>
          <div className={`blur__modal-${side}`}>
            <SelectSticker selectSmile={selectSmile} />
            <>
              <div
                className={`messenger__chat-messages-message messenger__chat-messages-message-${
                  messageElement?.senderId._id === _id ? 'r' : 'l'
                }`}
                style={{ margin: '10px 0' }}
              >
                {messageElement?.forward && (
                  <span className="forward">{`From: ${messageElement?.senderIdold}`}</span>
                )}
                {messageElement?.file && !messageElement?.audio && (
                  <img
                    src={`${apiBaseURL}/uploads/messenger/${messageElement?.file}`}
                    alt=""
                  />
                )}
                {messageElement?.file && messageElement?.audio && (
                  <VoiceMessage
                    Side={messageElement?.senderId._id === _id ? 'r' : 'l'}
                    Audio={`${apiBaseURL}/uploads/messenger/${messageElement?.file}`}
                  />
                )}
                {messageElement.content.toLowerCase().includes('http') ? (
                  <Link
                    to={messageElement.content}
                    style={{
                      overflowWrap: 'break-word',
                      wordWrap: 'break-word',
                      maxWidth: '90%',
                      textDecoration: 'underline',
                    }}
                  >
                    {messageElement.content}
                  </Link>
                ) : (
                  <span
                    style={{
                      overflowWrap: 'break-word',
                      wordWrap: 'break-word',
                      maxWidth: '90%',
                    }}
                  >
                    {messageElement?.content}
                  </span>
                )}
                {messageElement?.like && (
                  <div
                    className={`messanger__chat__like`}
                    onClick={() => removeLike(messageElement, Index)}
                  >
                    {messageElement.like}
                  </div>
                )}
                {/* {item?.like ? <div className={`messanger__chat__like.active`}>{item.like}</div> : ""} */}
                <Message
                  senderId={messageElement?.senderId._id}
                  index={Index}
                  updateList={updateFieldChanged}
                  chatId={chatId}
                  Like={Like}
                  setLike={setLike}
                  audio={messageElement?.audio}
                  time={moment(messageElement?.timestamp).format('h:mm A')}
                  timeStamp={messageElement.timestamp}
                  delete={() => deleteMessage(messageElement)}
                  message={messageElement?.content}
                  senderIdold={messageElement?.senderId._id}
                  fileName={messageElement?.file}
                  type={messageElement?.senderId._id === _id ? 'r' : 'l'}
                />
              </div>
              <div ref={myRef} />
              <div className="messenger__chat-sender-body">
                {imageUrl && (
                  <div className="messenger__chat-sender-file">
                    <img src={`${imageUrl}`} alt="" />
                    <div>{image?.name}</div>
                    <button onClick={removeFile}>
                      <IconAdminClose />
                    </button>
                  </div>
                )}
              </div>
            </>
            <div className="sendmenu">
              <div
                className="sendmenu-item"
                onClick={(e) => {
                  e.stopPropagation()
                  replyMessage()
                }}
              >
                <span className="sendmenu-item-text">Reply</span>
                <div>
                  <IconFroward />
                </div>
              </div>
              <div
                className="sendmenu-item"
                onClick={(e) => {
                  e.stopPropagation()
                  copyMessage(messageElement.content)
                }}
              >
                <span className="sendmenu-item-text">Copy</span>
                <div>
                  <IconCopy />
                </div>
              </div>
              {side === 'r' ? (
                <div
                  className="sendmenu-item"
                  onClick={(e) => {
                    e.stopPropagation()
                    editMessage()
                  }}
                >
                  <span className="sendmenu-item-text" style={{ margin: '2px 0' }}>
                    Edit
                  </span>
                  <div>
                    <IconEdit />
                  </div>
                </div>
              ) : (
                ''
              )}
              <div
                className={
                  side === 'r' ? 'sendmenu-item' : 'sendmenu-item sendmenu-item-last'
                }
                onClick={(e) => {
                  e.stopPropagation()
                  navigate(`${indexPathByRole(role)}/messeges/forwardpage`, {
                    state: {
                      senderIdold: messageElement.senderId,
                      message: messageElement.content,
                      time: messageElement.timestamp,
                      fileName: messageElement.file,
                      audio: messageElement.audio,
                    },
                  })
                }}
              >
                <span className="sendmenu-item-text">Share</span>
                <div>
                  <IconPostsRepost color="rgba(15, 20, 25, 1)" />
                </div>
              </div>
              {side === 'r' ? (
                <div
                  className="sendmenu-item sendmenu-item-last"
                  onClick={(e) => {
                    e.stopPropagation()
                    deleteMessage(messageElement)
                    setBlur(false)
                  }}
                >
                  <span className="sendmenu-item-text-delete">Delete</span>
                  <div>
                    <IconDelete />
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          {isOpenCopy && (
            <TempNotification setIsOpen={setisOpenCopy} isOpen={isOpenCopy}>
              <div
                style={{
                  backgroundColor: '#fff',
                  padding: '10px 20px',
                  borderRadius: '10px',
                }}
              >
                <h5 style={{ fontWeight: '600' }}>Copied!</h5>
              </div>
            </TempNotification>
          )}
        </Blur>
      )}
    </>
  )
}
