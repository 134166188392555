import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../utils/hooks'
import { IconFacebook } from '../../svg/IconFacebook'
import { IconGoogle } from '../../svg/IconGoogle'
import { Button, Divider, Flex } from 'antd'

import THREE_PEOPLE from '../../../assets/images/auth/3people.png'
import BACKGROUND from '../../../assets/images/auth/welcomebg.png'
import { IconWelcomeLogo } from '../../svg/IconWelcomeLogo'

export const AuthWelcome = () => {
  const navigate = useNavigate()
  const { isLoad, authLoaderMethod } = useAppSelector((state) => state.auth)

  return (
    <>
      {isLoad || authLoaderMethod ? (
        <IconWelcomeLogo />
      ) : (
        <div className="auth">
          <div className="auth__welcome">
            <div className="auth__welcome-logo">
              <img src="/Images/logo.png" alt="" />
            </div>
            <h6 className="auth__welcome-subtitle">
              Your All-in-One <br />
              Social Network
            </h6>
            <Flex
              justify="center"
              style={{ position: 'relative', margin: '1em', maxHeight: '60%' }}
            >
              <img src={BACKGROUND} style={{ position: 'absolute' }} />
              <img src={THREE_PEOPLE} style={{ position: 'relative', zIndex: 2 }} />
            </Flex>
            <Flex gap="large">
              <Button type="primary" block onClick={() => navigate('/auth/signIn')}>
                Log In
              </Button>

              <Button type="default" block onClick={() => navigate('/auth/signUp')}>
                Sign Up
              </Button>
            </Flex>
            <Divider className="auth-divider">Enter via</Divider>
            <Flex justify="center" gap="large">
              <Button
                type="default"
                size="large"
                shape="circle"
                icon={<IconGoogle />}
                href={`${process.env.REACT_APP_API_BASE_URL}/api/auth/google`}
              />
              <Button
                type="default"
                size="large"
                shape="circle"
                icon={<IconFacebook />}
                href={`${process.env.REACT_APP_API_BASE_URL}/api/auth/facebook`}
              />
            </Flex>
          </div>
        </div>
      )}
    </>
  )
}
