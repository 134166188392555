import { LocationType } from '../../../types/types'
import { apiBaseURL } from '../../route/indexPathByRole'

export const SliderItem = ({
  item,
  location,
  setLocation,
}: {
  item: LocationType
  location: LocationType
  setLocation: (s: LocationType) => void
}) => {
  return (
    <div
      className={
        location === item
          ? 'group__location-slider-item group__location-slider-item-active'
          : 'group__location-slider-item'
      }
      onClick={() => setLocation(item)}
    >
      <div className="group__location-slider-item-text">
        <h5>{item.title}</h5>
        <h6>
          <b>{item.subscribers.length}</b>
          {item.subscribers.length > 1 ? ' neibs ' : ' neib '} in this area
        </h6>
      </div>
      <img src={`${apiBaseURL}/uploads/map/${item.file}`} alt="" />
    </div>
  )
}
