import { useState, useRef } from 'react'
import { IconVoice } from '../../svg/IconVoice'
import { IconVoicePause } from '../../svg/IconVoicePause'

export const VoiceMessageShort = (props: { Audio: string; Side: string }) => {
  const [Duration, setDuration] = useState<number>(0)
  const [Moment, setMoment] = useState<number>(0)
  const [IsPlaying, setIsPlaying] = useState<boolean>(false)
  const [CurrentTime, setCurrentTime] = useState<number>(0)
  const [Percentage, setPercentage] = useState<number>(0)
  const audioRef = useRef<HTMLAudioElement>(null!)

  const play = () => {
    const audio = audioRef.current
    audio.volume = 0.1

    if (!IsPlaying) {
      setIsPlaying(true)
      audio.play()
    }
    if (IsPlaying) {
      setIsPlaying(false)
      audio.pause()
    }
  }
  return (
    <div>
      <button
        style={{ background: 'none' }}
        className={`voice__message__listen__button ${props.Side}`}
        onClick={(e) => {
          e.stopPropagation()
          play()
        }}
      >
        {!IsPlaying ? (
          <IconVoice className="voice__icon" />
        ) : (
          <IconVoicePause className="voice__icon" />
        )}
      </button>
      <audio
        src={props.Audio}
        ref={audioRef}
        onLoadedData={(e) => {
          setDuration(Number(e.currentTarget.duration.toFixed(2)))
        }}
        onTimeUpdate={(e) => {
          const time = Number(e.currentTarget.currentTime)
          const p =
            (Number(e.currentTarget.currentTime) / Number(e.currentTarget.duration)) * 100
          if (Number(e.currentTarget.currentTime) == Number(e.currentTarget.duration)) {
            setIsPlaying(false)
          }

          setCurrentTime(time)
          setPercentage(p)
        }}
      ></audio>
    </div>
  )
}
