export const IconFroward = () => {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.55473 0.421852C6.7056 0.576485 6.79034 0.786096 6.79034 1.00464C6.79034 1.22319 6.7056 1.4328 6.55473 1.58744L3.36442 4.85327H10.2821C11.6353 4.85327 12.9331 5.40356 13.89 6.38309C14.8469 7.36262 15.3845 8.69114 15.3845 10.0764V12.8254C15.3845 13.0442 15.2996 13.2539 15.1485 13.4086C14.9974 13.5632 14.7925 13.6501 14.5788 13.6501C14.3652 13.6501 14.1602 13.5632 14.0092 13.4086C13.8581 13.2539 13.7732 13.0442 13.7732 12.8254V10.0764C13.7732 9.1286 13.4054 8.21961 12.7507 7.5494C12.096 6.8792 11.208 6.50268 10.2821 6.50268H3.36442L6.55473 9.76852C6.63388 9.84402 6.69737 9.93507 6.7414 10.0362C6.78543 10.1374 6.80911 10.2466 6.81102 10.3573C6.81292 10.4681 6.79303 10.5781 6.75251 10.6807C6.71199 10.7834 6.65168 10.8767 6.57518 10.955C6.49868 11.0333 6.40755 11.0951 6.30723 11.1366C6.20692 11.178 6.09947 11.1984 5.9913 11.1965C5.88312 11.1945 5.77644 11.1703 5.67762 11.1252C5.5788 11.0801 5.48985 11.0151 5.4161 10.9341L0.850845 6.26077C0.699976 6.10614 0.615234 5.89652 0.615234 5.67798C0.615234 5.45943 0.699976 5.24982 0.850845 5.09518L5.4161 0.421852C5.56715 0.267412 5.77192 0.180664 5.98541 0.180664C6.19891 0.180664 6.40367 0.267412 6.55473 0.421852Z"
        fill="#0F1419"
      />
    </svg>
  )
}
