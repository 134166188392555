import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import $api from '../../../http'
import { UserHttp } from '../../../http/user-http'
import {
  UserInitialStateInterface,
  UserInitialStateWithDistanceInterface,
  setLoader,
} from '../../../redux/users'
import { EDUCATION, ROLE, SEX } from '../../../types/enum'
import { apiBaseURL, indexPathByRole } from '../../route/indexPathByRole'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { InputSearch } from '../../ui/InputSearch'
import { success } from '../../ui/LoadSuccess'
import { Loader } from '../../ui/Loader'
import Slider from '@mui/material/Slider'
import { NeibsFilter } from '../../ui/SearchFilter'
import { NeibsFilterType } from '../../../types/types'
import { DarkBg } from '../../ui/DarkBg'
import { IdentityHttp } from '../../../http/identity-http'
import moment from 'moment'
import { calculateDistance } from '../../../utils/calculateRate'

const UserItem = React.memo((props: UserInitialStateInterface) => {
  const [isMyFriend, setIsMyFriend] = useState(false)
  const { _id, role } = useAppSelector((state) => state.user)
  const navigate = useNavigate()

  const addRequest = async () => {
    await $api.post('messenger/add-request', {
      myId: props?._id,
      senderId: _id,
    })
    success()
    // navigate(-1)
  }

  const toFriend = async () => {
    if (props?._id === _id || !props?._id) {
      return
    }
    const payload = {
      _id,
      friendId: props?._id,
    }
    if (isMyFriend) {
      await UserHttp.deleteMyFriend(payload).then(() => setIsMyFriend(false))
    } else {
      await UserHttp.addMyFriend(payload).then(() => setIsMyFriend(true))
      const chat = await $api.post('messenger/get-chat', {
        participants: [
          {
            userId: props?._id,
          },
          {
            userId: _id,
          },
        ],
      })
      if (chat && chat?.data?.participants?.length < 3) {
        openChat()
      } else {
        addRequest()
      }
    }
  }

  useEffect(() => {
    if (props?._id && props?._id !== _id) {
      UserHttp.checkMyFriend({
        _id,
        friendId: props?._id,
      }).then((res) => setIsMyFriend(res))
    }
  }, [])

  const toProfileInfo = () => {
    navigate('/profileinfo', {
      state: {
        ...props,
      },
    })
  }

  const openChat = async () => {
    await $api
      .post('messenger/open-chat', {
        participants: [
          {
            userId: props._id,
          },
          {
            userId: _id,
          },
        ],
      })
      .then((res) => {
        console.log(res)
        if (res.data) {
          navigate(`${indexPathByRole(role)}/messeges/chat`, {
            state: {
              chat: res.data,
            },
          })
        }
      })
  }

  return (
    <div className="messenger__alluser-item messenger__alluser-item-1">
      <div className="messenger__alluser-item-img" onClick={toProfileInfo}>
        <>
          {props?.avatarFileName ? (
            <img
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              src={`${apiBaseURL}/uploads/avatar/${props?.avatarFileName}`}
            />
          ) : (
            <div
              style={{
                backgroundColor: 'rgba(41, 52, 65, 1)',
                borderRadius: '50%',
                width: '100%',
                height: '100%',
                color: '#fff',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <span style={{ margin: 'auto', color: '#fff', fontSize: '14px' }}>
                {props?.fullName.slice(0, 2)}
              </span>
            </div>
          )}
        </>
      </div>
      <div className="messenger__alluser-item-text" onClick={openChat}>
        <h5 className="messenger__alluser-item-title">{props.fullName}</h5>
        <h5 className="messenger__alluser-item-subtitle">
          {props?.phone ? props?.phone : props?.email}
        </h5>
      </div>
      <button
        className={`messenger__alluser-item-button ${
          !isMyFriend && 'messenger__alluser-item-button--active'
        }`}
        onClick={toFriend}
      >
        {!isMyFriend ? 'Follow' : 'Neib'}
      </button>
    </div>
  )
})

UserItem.displayName = 'UserItem'

const HowFar = ({
  setFar,
  far,
  start,
}: {
  setFar: (s: NeibsFilterType) => void
  far: NeibsFilterType
  start: () => void
}) => {
  return (
    <div className="howfar">
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h5 className="howfar-title">How far?</h5>
          <Slider
            style={{ margin: '-10px 20px', width: '100px' }}
            value={far.far}
            onChange={(e, newValue) => {
              setFar({ ...far, far: newValue as number })
              start()
            }}
            defaultValue={5}
            min={1}
            max={500}
            aria-label="Default"
            valueLabelDisplay="auto"
          />
          <h6 className="howfar-value">{far.far}km</h6>
        </div>
      </>
    </div>
  )
}

export const AllUserChatList = ({
  users,
  search,
  load,
  setSearch,
  setCount,
}: {
  users: UserInitialStateWithDistanceInterface[]
  search: string
  load: boolean
  setSearch: (s: string) => void
  setCount: (s: number) => void
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [usersNormal, setUsersNormal] = useState<UserInitialStateWithDistanceInterface[]>(
    [...users],
  )
  const [filter, setFilter] = useState(false)
  const [filterNeibs, setFilterNeibs] = useState<NeibsFilterType>({
    gender: SEX.MALE,
    orientation: 'Hetero',
    nationality: 'Afghan',
    age: [18, 20],
    profession: 'Actor',
    education: EDUCATION.BACHERLOR,
    skills: 'Cooking and',
    interests: 'Reading',
    far: 5,
  })
  useEffect(() => {
    if (!filter) {
      setUsersNormal(users)
    }
  }, [])
  useEffect(() => {
    const u: UserInitialStateWithDistanceInterface[] = []
    users.map((item) => {
      if (filterNeib(item)) {
        u.push(item)
      }
    })
    setUsersNormal(u)
    setCount(u.length)
  }, [filterNeibs])
  useEffect(() => {
    const u: UserInitialStateWithDistanceInterface[] = []
    users.map((item) => {
      if (filterNeibs.far && Math.round(item.distance / 1000) === filterNeibs.far) {
        u.push(item)
      }
    })
    setUsersNormal(u)
    setCount(u.length)
  }, [filterNeibs.far])
  useEffect(() => {
    const u: UserInitialStateWithDistanceInterface[] = []
    users.map((item) => {
      if (
        filterNeibs.age &&
        moment(new Date()).year() - moment(item.identity?.dateBirth).year() >
          filterNeibs.age[0] &&
        moment(new Date()).year() - moment(item.identity?.dateBirth).year() <
          filterNeibs.age[1]
      ) {
        u.push(item)
      }
    })
    setUsersNormal(u)
    setCount(u.length)
  }, [filterNeibs.age])

  useEffect(() => {
    if (!search.length) {
      setUsersNormal(users)
    } else {
      setUsersNormal(
        users.filter((it) =>
          it.fullName
            .toLowerCase()
            .replaceAll(' ', '')
            .includes(search.toLowerCase().replaceAll(' ', '')),
        ),
      )
    }
  }, [search])

  const filterNeib = (item: UserInitialStateWithDistanceInterface) => {
    return (
      (filterNeibs.orientation &&
        filterNeibs.orientation === item.identity?.orientation) ||
      (filterNeibs.gender &&
        filterNeibs.gender === item.identity?.sex &&
        filterNeibs.nationality &&
        filterNeibs.nationality === item.identity?.nationality[0]?.title) ||
      (filterNeibs.education && filterNeibs.education === item.identity?.education) ||
      (filterNeibs.profession &&
        filterNeibs.profession === item.identity?.professions[0]?.title) ||
      (filterNeibs.skills &&
        filterNeibs.skills === item.identity?.skills[0]?.title &&
        filterNeibs.interests &&
        filterNeibs.interests === item.identity?.interests[0]?.title)
    )
  }

  return (
    <>
      {load ? (
        <Loader />
      ) : (
        <div>
          <div className="user__newsfeed-search">
            <InputSearch
              placeholder={
                <>
                  Search <b>NeighborChats</b>
                </>
              }
              value={search}
              filter={true}
              onClickFilter={() => setIsOpen(true)}
              changeValue={setSearch}
            />
          </div>
          <div className="messenger__alluser">
            {usersNormal.map((item) => (
              <>
                <UserItem {...item} />
              </>
            ))}
          </div>
        </div>
      )}
      <HowFar start={() => setFilter(true)} far={filterNeibs} setFar={setFilterNeibs} />
      {isOpen && (
        <NeibsFilter
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          filter={filterNeibs}
          setFilter={setFilterNeibs}
          reset={() => setFilter(false)}
          start={() => setFilter(true)}
          apply={() => setIsOpen(false)}
        />
      )}
      {isOpen && <DarkBg />}
    </>
  )
}
AllUserChatList.displayName = 'AllUserChatList'
