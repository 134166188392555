/**
 *
 * @param name
 * @returns
 */
export function getInitials(name: string) {
  const splitted = name.split(/[ ]+/, 2)
  if (splitted.length == 0) {
    return '?'
  }
  if (splitted.length == 1) {
    return splitted[0].substring(0, 2).toUpperCase()
  }

  return `${splitted[0].substring(0, 1)}${splitted[1].substring(0, 1)}`.toUpperCase()
}
