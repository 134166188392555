import dayjs from 'dayjs'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PublishEventItemInterface } from '../../../types/types'
import { apiBaseURL } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconCalendar } from '../../svg/IconCalendar'
import { IconLeftChevrons } from '../../svg/IconChevrons'
import { UserHeader } from '../header/UserHeader'

const days = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']

export const UserActivitiesTimeLine = () => {
  const navigate = useNavigate()
  const { publishActivities } = useAppSelector((state) => state.activities)
  const [week, setWeek] = useState<number[]>()
  const [currentDay, setCurrentDay] = useState<number>()
  const [currentDate, setCurrentDate] = useState<Date>()
  const [todaysActivities, setTodaysActivities] = useState<PublishEventItemInterface[]>()
  const [hours, setHours] = useState<number[]>()
  useEffect(() => {
    let d = new Date()
    let array: number[] = []
    let hrs: number[] = []
    let s = moment().startOf('isoWeek').toDate().getDate()
    let e = moment().endOf('isoWeek').toDate().getDate()
    console.log(s, e)
    for (let i = s; i < e + 1; i++) {
      array.push(i)
    }
    for (let i = 0; i < 25; i++) {
      hrs.push(i)
    }
    setHours(hrs)
    setCurrentDate(d)
    setCurrentDay(d.getDate())
    setWeek(array)
  }, [])
  const { _id } = useAppSelector((state) => state.user)
  useEffect(() => {
    if (currentDay) {
      let array: PublishEventItemInterface[] = []
      publishActivities.map((item) => {
        if (
          currentDate &&
          dayjs(item.startDate).isSame(currentDate, 'year') &&
          dayjs(item.startDate).isSame(currentDate, 'month') &&
          dayjs(item.startDate).isSame(
            new Date(currentDate?.getFullYear(), currentDate.getMonth(), currentDay),
            'day',
          )
        ) {
          array.push(item)
          console.log(currentDay)
        }
      })
      setTodaysActivities(array)
    }
  }, [currentDay])

  const toPublishActivities = (_id: string) => {
    navigate(`/user/activities/publish-activities-item?publishActivitiesId=${_id}`)
  }

  return (
    <div className="user__event-timeline">
      <UserHeader>
        <div className="profileinfo__bookmark-header">
          <button onClick={() => navigate(-1)}>
            <IconLeftChevrons />
          </button>
          <h5 onClick={() => navigate(-1)}>Activities Timeline</h5>
        </div>
      </UserHeader>
      <div className="user__event-timeline-container">
        {week && currentDay && (
          <div className="user__event-calender" style={{ margin: 0 }}>
            <h5>{moment().format('MMMM') + ', ' + currentDate?.getFullYear()}</h5>
            <div className="user__event-calender-dayline">
              {days.map((item) => (
                <span>{item}</span>
              ))}
            </div>
            <div className="user__event-calender-daynum">
              {week.map((day) => (
                <span
                  onClick={() => setCurrentDay(day)}
                  className={
                    day !== currentDay
                      ? 'user__event-calender-daynum-item'
                      : 'user__event-calender-daynum-item-active'
                  }
                >
                  {day}
                </span>
              ))}
            </div>
          </div>
        )}
        <div className="user__event-timeline-container-time">
          <div className="user__event-timeline-container-time-column">
            {hours?.map((hour) => (
              <span>
                {hour} {hour < 18 ? 'AM' : 'PM'}
              </span>
            ))}
          </div>
        </div>
        {todaysActivities && (
          <div className="user__event-timeline-container-content">
            {todaysActivities.map((item) => (
              <div
                className="user__event-timeline-container-content-item"
                onClick={() => toPublishActivities(item?._id)}
                style={{
                  top: `${!dayjs(item.startDate).hour ? Math.floor(dayjs(item.startDate).hour() + 1) * 36 + 130 : Math.floor(dayjs(item.startDate).hour()) * 36 + 130}px`,
                }}
              >
                {item.filesName.length ? (
                  <img
                    src={`${apiBaseURL}/uploads/publish_activities/${item.filesName[0]}`}
                  />
                ) : (
                  <IconCalendar />
                )}
                <h5>{item.title}</h5>
                <h6>{dayjs(item.startDate).format('HH:mm')}</h6>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
