import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useLocation, useNavigate } from 'react-router-dom'
import { ServiceHttp } from '../../../http/service-http'
import { PRIVACY, USER_LIST_APP } from '../../../types/enum'
import { PublishServiceItemInterface } from '../../../types/types'
import { calculateDistance } from '../../../utils/calculateRate'
import { apiBaseURL } from '../../route/indexPathByRole'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { IconArrowRight } from '../../svg/IconArrow'
import { IconBookMark } from '../../svg/IconBookMark'
import { IconStars } from '../../svg/IconFavor'
import {
  IconProfileInfoBookmark,
  IconProfileInfoBookmarkCopy,
} from '../../svg/IconProfileInfo'
import { InputSearch } from '../../ui/InputSearch'
import { TempNotification } from '../../ui/TempNotification'
import { UserHeaderUserChatList } from '../header/UserHeaderChatList'
import { UserPublishServicesModal } from './UserPublishServicesModal'
import { hiddenService } from './UserServicesMain'
import {
  allCategories,
  getHiddenService,
  getTenPublishService,
} from '../../../redux/categories'

export const UserPublishServicesList = ({ event }: { event: USER_LIST_APP }) => {
  const [searsh, setSearch] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [publishService, setPublishService] = useState<PublishServiceItemInterface[]>([])
  const { _id } = useAppSelector((state) => state.user)
  const { coordinates } = useAppSelector((state) => state.profile)
  const navigate = useNavigate()
  const location = useLocation()
  const [updated, setUpdated] = useState(false)
  const { publishServices, hiddenService } = useAppSelector((state) => state.categories)

  const [currentIdCategorie, setCurrentIdCategorie] = useState('')

  const { ref } = useInView({
    threshold: 0,
  })
  const [hiddenServices, setHiddenServices] = useState<hiddenService[]>([])

  const toPublishService = (_id: string) => {
    navigate(`/user/service/publish-service?publishServiceId=${_id}`)
  }

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(allCategories())
    dispatch(getHiddenService({ userId: _id }))
    dispatch(getTenPublishService())
  }, [])
  useEffect(() => {
    setHiddenServices(hiddenService)
    if (USER_LIST_APP.LAST === event) {
      setPublishService(publishServices)
    } else {
      const id = location.search.replace('?id=', '')
      setPublishService(publishServices.filter((it) => it.subCategoryId._id === id))
    }
  }, [hiddenService, publishServices])

  const calculateRate = (item: PublishServiceItemInterface) => {
    let rate: number[] = []
    item.reviews.map((review) => {
      rate.push(review.rate)
    })
    return rate.reduce((partialSum, a) => partialSum + a, 0) / rate.length
  }

  const validatePrivacy = (item: PublishServiceItemInterface) => {
    if (hiddenServices.length) {
      if (hiddenServices.find((it) => it.serviceId !== item._id)) {
        if (item.privacyPublishService === PRIVACY.NEIBS) {
          return item.allowList.find((id) => id === _id) || item.userId._id === _id
        }
        if (item.privacyPublishService === PRIVACY.ONLYME) {
          return item.userId._id === _id
        }
        if (item.privacyPublishService === PRIVACY.EVERYONE) {
          return true
        }
      }
    } else {
      if (item.privacyPublishService === PRIVACY.NEIBS) {
        return item.allowList.find((id) => id === _id) || item.userId._id === _id
      }
      if (item.privacyPublishService === PRIVACY.ONLYME) {
        return item.userId._id === _id
      }
      if (item.privacyPublishService === PRIVACY.EVERYONE) {
        return true
      }
    }
  }

  const [isOpenSave, setIsOpenSave] = useState(false)
  const updateMark = async (serviceId: string, isMarked: boolean) => {
    if (isMarked) {
      await ServiceHttp.deleteMark({
        serviceId,
        marckedUserId: _id,
      })
    } else {
      await ServiceHttp.addMark({
        serviceId,
        marckedUserId: _id,
      })
      setIsOpenSave(true)
    }

    if (publishService) {
      let array: PublishServiceItemInterface[] = []
      publishService.map((item) => {
        if (item._id === serviceId) {
          array.push({
            ...item,
            isMarked: !item?.isMarked,
          })
        } else {
          array.push(item)
        }
      })
      setPublishService(array)
    }
  }

  return (
    <>
      <UserHeaderUserChatList
        headerTitle={
          USER_LIST_APP.LAST === event
            ? 'Latest Services'
            : `${publishService?.[0]?.categoryId?.name || ''} | ${
                publishService?.[0]?.subCategoryId?.name || ''
              }`
        }
      />
      <div className="user__newsfeed-search">
        <InputSearch
          placeholder={
            <>
              Search<span> NeighborHarbor</span>
            </>
          }
          value={searsh}
          changeValue={setSearch}
        />
      </div>
      <div className="user__category-publish">
        {publishService
          .filter((item) => item.title.includes(searsh))
          .map((item) => (
            <>
              {validatePrivacy(item) && (
                <div
                  className="user__services-last-item"
                  onClick={() => {
                    toPublishService(item._id)
                  }}
                >
                  <div className="user__services-last-item-img">
                    <div className="user__services-last-item-img-1 user__services-last-item-img-info">
                      <div className="user__services-last-item-img-text">
                        {item.categoryId.name}
                      </div>
                    </div>
                    {item.filesName.length > 0 ? (
                      item?.filesName?.map((it) => (
                        <img
                          src={`${apiBaseURL}/uploads/publish_services/${it}`}
                          alt=""
                          onClick={() => {
                            setIsOpen(true)
                            setCurrentIdCategorie(item?.categoryId?._id)
                          }}
                        />
                      ))
                    ) : (
                      <img className="posts__bg" src={`/Images/postsBG.png`} alt="" />
                    )}
                    {item.reviews.length ? (
                      <div className="user__services-last-item-img-2 user__services-last-item-img-info">
                        <div className="user__services-last-item-img-text">
                          {item.reviews.length ? (
                            <>
                              <IconStars />
                              <b>{calculateRate(item)}</b>
                              <span>{`(${item.reviews.length})`}</span>
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="user__services-last-item-info">
                    <div className="user__services-last-item-row1">
                      <h5 className="user__services-last-item-title">{item.title}</h5>
                      <button
                        className={`${
                          item.isMarked && 'admin__posts-list-row4-repost--active'
                        }`}
                        onClick={(e) => {
                          e.stopPropagation()
                          updateMark(item._id, item?.isMarked)
                        }}
                      >
                        {item.isMarked ? (
                          <IconProfileInfoBookmarkCopy fill="#3361FF" />
                        ) : (
                          <IconProfileInfoBookmark />
                        )}
                      </button>
                    </div>
                    <h6 className="user__services-last-item-text">{item.text}</h6>
                    <div className="user__services-last-item-foot">
                      {Math.floor(
                        calculateDistance(coordinates, item.coordinates) / 1000,
                      )}{' '}
                      km
                      <IconArrowRight />
                    </div>
                  </div>
                </div>
              )}
            </>
          ))}
      </div>
      <UserPublishServicesModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        categorieName={
          publishService.find((item) => item.categoryId._id === currentIdCategorie)
            ?.categoryId.name || 'none'
        }
      />
      <div ref={ref} />
      {isOpenSave && (
        <TempNotification setIsOpen={setIsOpenSave} isOpen={isOpenSave}>
          <IconBookMark />
        </TempNotification>
      )}
    </>
  )
}
