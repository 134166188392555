export const AdvertisementStepper = ({ i }: { i: number }) => {
  const list = [1, 2, 3, 4]
  return (
    <div className="user__newsfeed-steper-body">
      {list.map((item, index) => (
        <div
          style={index <= i ? {} : { backgroundColor: '#E9E8F5' }}
          className={`user__newsfeed-steper-item ${
            index <= i && `user__newsfeed-steper-item-active`
          }`}
        />
      ))}
    </div>
  )
}
