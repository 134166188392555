import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { PRIVACY } from '../../../types/enum'
import { getPublishTitle } from '../../../utils/titles'
import { IconLeftChevrons } from '../../svg/IconChevrons'
import { IconServicesAllPoint } from '../../svg/IconServicesAll'
import { UserHeader } from '../../user-components/header/UserHeader'
import { PublishPrivacyModal } from './PublishPrivacyModal'

export const PublicationHeader = ({
  currentPrivacy,
  setCurrentPrivacy,
  setIsOpenDiscard = () => {},
  setSave = () => {},
  isOpenDiscard = false,
  save = true,
  title = '',
  group,
  type,
}: {
  currentPrivacy: PRIVACY
  setCurrentPrivacy: (p: PRIVACY) => void
  setIsOpenDiscard?: (s: boolean) => void
  setSave?: (s: boolean) => void
  isOpenDiscard?: boolean
  save?: boolean
  title?: string
  group?: boolean
  type?: string
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <UserHeader>
      <div className="profileinfo__header">
        <button
          className="profileinfo__header-chevron"
          onClick={() => {
            setIsOpenDiscard(true)
            if (group) {
              navigate(-1)
            }
          }}
        >
          <IconLeftChevrons />
        </button>
        <h5 className="profileinfo__header-text">
          {!title ? getPublishTitle(location.pathname) : title}
        </h5>
        {location.pathname === '/publish' ? (
          <button className="profileinfo__header-points">
            <IconServicesAllPoint />
          </button>
        ) : (
          <PublishPrivacyModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            currentPrivacy={currentPrivacy}
            setCurrentPrivacy={setCurrentPrivacy}
            type={type}
          />
        )}
      </div>
    </UserHeader>
  )
}
