export const IconNightMode = ({ fill }: { fill: string }) => {
  return (
    <svg
      style={{ left: '28%', top: '26%' }}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.39075 0C3.51671 1.63496 0 6.23136 0 11.6607C0 18.4781 5.52185 24 12.3393 24C17.7686 24 22.365 20.4833 24 15.6093C22.7661 16.0103 21.4396 16.2879 20.0514 16.2879C13.2339 16.2879 7.71208 10.7661 7.71208 3.94859C7.71208 2.56041 7.95887 1.23393 8.39075 0Z"
        fill={fill}
      />
    </svg>
  )
}
