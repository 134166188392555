import { InputHTMLAttributes, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PHOTO_ADD_METHOD } from '../../../../types/enum'
import { apiBaseURL, indexPathByRole } from '../../../route/indexPathByRole'
import { useAppDispatch, useAppSelector } from '../../../../utils/hooks'
import { IconAdminClose } from '../../../svg/IconAdminHeader'
import { attemptUpdateAvatar } from '../../../../redux/userNew'
import { useAttemptListener, useStateCallback } from 'dev-masters-react-kit'
import {
  Avatar,
  Badge,
  Button,
  Flex,
  Form,
  GetProp,
  Image,
  Space,
  Upload,
  UploadFile,
} from 'antd'
import useNextSetUpProfilePath, {
  stepDefinitions,
} from '../../../../utils/hooks/useNextSetUpProfilePath'
import { PROFILE_STEP } from '../../../../types/server-communication/user'
import useUploadImages, {
  ExtendedUploadRef,
} from '../../../../utils/hooks/useUploadImages'
import UploaderImagePreview, {
  ImagePreviewMethods,
} from '../../../ui/UploaderImagePreview'
import { UploadChangeParam } from 'antd/es/upload'
import { UploadProps } from 'antd/lib/upload/interface'
import { IoIosCloseCircle } from 'react-icons/io'

import ImgCrop from 'antd-img-crop'
import { StandaloneScreenLayout } from '../../../ui/Layout/StandaloneScreenLayout'
import { store } from '../../../../store'
// import { Button, Flex, Space, Upload, UploadFile } from 'antd'
// import useUploadImages from '../../../utils/hooks/useUploadImages'
// import { updateAvatar } from '../../../redux/userNew'

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]

interface FormValues {
  avatarFile: [UploadFile<any>]
}
export const ProfilePicture = () => {
  const { avatarFileName, role } = useAppSelector((state) => state.userNew)
  const UploaderImagePreviewRef = useRef<ImagePreviewMethods>(null)
  const dispatch = useAppDispatch()

  const navigate = useNavigate()
  const [avatarForm] = Form.useForm<FormValues>()
  const avatarCurrent = Form.useWatch('avatarFile', avatarForm)

  const uploadRef = useRef<ExtendedUploadRef>(null)

  const [captureType, setCaptureType] =
    useStateCallback<InputHTMLAttributes<any>['capture']>(undefined)

  const nextStep = useNextSetUpProfilePath()

  const isLoading = useAttemptListener({
    store,
    attempt: attemptUpdateAvatar,
    onFulfilled: () => {
      navigate('/profile/' + stepDefinitions[PROFILE_STEP.PRIVACY].path)
    },
  })

  const { uploadProps: uploadAvatarProps } = useUploadImages({
    destination: `${process.env.REACT_APP_API_BASE_URL}/api/user/upload-avatar`,
  })

  function getDefaultFileList(): UploadFile<any>[] {
    return avatarFileName
      ? [
          {
            uid: avatarFileName,
            name: avatarFileName,
            status: 'done',
            url: `${process.env.REACT_APP_UPLOADED_BASE_URL}/avatar/${avatarFileName}`,
          },
        ]
      : []
  }

  function handleGetPhoto(
    e: any /* React.MouseEvent<HTMLButtonElement> */,
    capture: InputHTMLAttributes<any>['capture'],
  ) {
    if (!uploadRef.current?.upload?.uploader) return
    if (captureType === capture) uploadRef.current?.upload.uploader.onClick(e)
    setCaptureType(capture, () => {
      uploadRef.current?.upload.uploader.onClick(e)
    })
  }

  const normFile = (e: UploadChangeParam<UploadFile<any>>) => {
    return e?.fileList
  }

  // const [loading, setLoading] = useState(false);
  // const [imageUrl, setImageUrl] = useState<string>();

  // const getBase64 = (img: FileType, callback: (url: string) => void) => {
  //   const reader = new FileReader()
  //   reader.addEventListener('load', () => callback(reader.result as string))
  //   reader.readAsDataURL(img)
  // }

  // const handleChange: UploadProps['onChange'] = (info) => {
  //   if (info.file.status === 'uploading') {
  //     setLoading(true)
  //     return
  //   }
  //   if (info.file.status === 'done') {
  //     getBase64(info.file.originFileObj as FileType, (url) => {
  //       setLoading(false)
  //       setImageUrl(url)
  //     })
  //   }
  // }
  function handleSubmitForm({ avatarFile }: FormValues) {
    if (!avatarFile[0].response?.filename) {
      console.error('avatarFile[0].response?.filename:', avatarFile[0].response?.filename)
      return
    }
    dispatch(attemptUpdateAvatar({ avatarFileName: avatarFile[0].response?.filename }))
  }
  return (
    <>
      <StandaloneScreenLayout
        title="Profile Picture"
        subTitle="If the platform rules are violated, your participation may be subject to termination"
        onPrimaryButtonClicked={avatarForm.submit}
        // skipPath={nextPath}
        isDisabled={isLoading || avatarCurrent?.every((file) => file.status !== 'done')}
      >
        <Form
          form={avatarForm}
          name="basic"
          layout="vertical"
          initialValues={{
            avatarFile: avatarFileName && [
              {
                uid: avatarFileName,
                name: avatarFileName,
                status: 'done',
                url: `${process.env.REACT_APP_UPLOADED_BASE_URL}/avatar/${avatarFileName}`,
              },
            ],
          }}
          onFinish={handleSubmitForm}
        >
          <Form.Item
            name="avatarFile"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            {/* <ImgCrop rotationSlider showGrid cropShape="round"> */}
            <Upload
              ref={uploadRef}
              {...uploadAvatarProps}
              showUploadList={true}
              listType={'picture-card'}
              accept="image/jpeg, image/png, image/jpg"
              defaultFileList={getDefaultFileList()}
              onPreview={UploaderImagePreviewRef.current?.handleFilePreview}
              capture={captureType}
              openFileDialogOnClick={false}
              // onChange={handleChange}
              itemRender={(originalNode, file, fileList, { remove, preview }) => (
                // <div>{originalNode}</div>
                <Flex justify="center">
                  <div className="avatar-negative-margin">
                    <Badge
                      count={
                        <Button
                          shape="circle"
                          onClick={remove}
                          icon={<IoIosCloseCircle size={30} />}
                        />
                      }
                    >
                      <Avatar
                        size={240}
                        shape={'square'}
                        src={file.url || file.thumbUrl}
                      />
                    </Badge>
                  </div>
                </Flex>
              )}
              maxCount={1}
              className="avatar"
            >
              {avatarCurrent?.length > 0 ? null : (
                <Flex vertical gap={20}>
                  <Button block onClick={(e) => handleGetPhoto(e, 'user')}>
                    {' '}
                    Take now{' '}
                  </Button>
                  <Button block onClick={(e) => handleGetPhoto(e, false)}>
                    {' '}
                    Camera Roll{' '}
                  </Button>
                </Flex>
              )}
            </Upload>
            {/* </ImgCrop> */}
          </Form.Item>
        </Form>
      </StandaloneScreenLayout>
      <UploaderImagePreview ref={UploaderImagePreviewRef} />
    </>
  )
}
