import Slider from '@mui/material/Slider'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AdvertisementHttp } from '../../../http/advertisement'
import { SEX } from '../../../types/enum'
import { indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconLeftChevrons } from '../../svg/IconChevrons'
import { success } from '../../ui/LoadSuccess'

export const AdvertisementGender = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { role } = useAppSelector((state) => state.user)
  const [value, setValue] = useState([20, 24])
  const [gender, setGender] = useState<SEX[]>([SEX.MALE])
  const validate = !Boolean(value.length && gender.length)
  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number[])
  }
  const addGender = async () => {
    try {
      console.log(location.state.adId)
      await AdvertisementHttp.updateGender({
        adId: location.state.adId,
        gender,
        age: value,
      })
      success()
      navigate(`${indexPathByRole(role)}/advertisement/add-community-selection`, {
        state: { adId: location.state.adId },
      })
    } catch (error) {
      console.log('server error' + error)
    }
  }
  return (
    <div className="advertisement__add">
      <div className="advertisement__add-header">
        <div className="advertisement__add-header-btn" onClick={() => navigate(-1)}>
          <IconLeftChevrons fill="rgba(21, 16, 77, 1)" />
          <span>Age & Gender</span>
        </div>
      </div>
      <div className="advertisement__add-gender">
        <div className="advertisement__add-gender-title">
          <h5>Unavaliable</h5>
          <h6>Estimated audience size</h6>
        </div>
        <div className="advertisement__add-gender-age">
          <h6 className="advertisement__add-main-title">Age</h6>
          <Slider
            getAriaLabel={() => 'Temperature range'}
            value={value}
            onChange={handleChange}
            valueLabelDisplay="auto"
            min={18}
            max={70}
            style={{ margin: '15px' }}
          />
        </div>
        <div className="advertisement__add-gender-select">
          <h6 className="advertisement__add-main-title">Gender</h6>
          <div className="advertisement__add-main-line">
            <div
              style={{ padding: '44px 22px 0 22px' }}
              className={`advertisement__add-main-line-item ${gender.includes(SEX.MALE) ? 'advertisement__add-main-line-item-active' : ''}`}
              onClick={() => {
                if (gender.includes(SEX.MALE)) {
                  let array: SEX[] = [...gender]
                  let index = array.indexOf(SEX.MALE)
                  if (index !== -1) {
                    array.splice(index, 1)
                    setGender(array)
                  }
                } else {
                  setGender((s) => [...s, SEX.MALE])
                }
              }}
            >
              <img
                style={{ padding: '3px 0 15px 0' }}
                width={98}
                height={94}
                alt=""
                src="/Images/male.png"
              />
              <span>Male</span>
            </div>
            <div
              style={{ padding: '44px 22px 0 22px', margin: '0px 10px' }}
              className={`advertisement__add-main-line-item ${gender.includes(SEX.FEMALE) ? 'advertisement__add-main-line-item-active' : ''}`}
              onClick={() => {
                if (gender.includes(SEX.FEMALE)) {
                  let array: SEX[] = [...gender]
                  let index = array.indexOf(SEX.FEMALE)
                  if (index !== -1) {
                    array.splice(index, 1)
                    setGender(array)
                  }
                } else {
                  setGender((s) => [...s, SEX.FEMALE])
                }
              }}
            >
              <img
                style={{ padding: '3px 0 22px 0' }}
                width={109}
                height={92}
                alt=""
                src="/Images/female.png"
              />
              <span>Female</span>
            </div>
            <div
              className={`advertisement__add-main-line-item ${gender.includes(SEX.OTHER) ? 'advertisement__add-main-line-item-active' : ''}`}
              onClick={() => {
                if (gender.includes(SEX.OTHER)) {
                  let array: SEX[] = [...gender]
                  let index = array.indexOf(SEX.OTHER)
                  if (index !== -1) {
                    array.splice(index, 1)
                    setGender(array)
                  }
                } else {
                  setGender((s) => [...s, SEX.OTHER])
                }
              }}
            >
              <img width={98} height={94} alt="" src="/Images/other.png" />
              <span>Other</span>
            </div>
          </div>
        </div>
      </div>
      <button
        className={`advertisement__add-main-btn ${
          validate && 'advertisement__add-main-btn-disabled'
        }`}
        onClick={addGender}
        disabled={validate}
      >
        Continue
      </button>
    </div>
  )
}
