import { Ref, forwardRef, useImperativeHandle, useState } from 'react'
import { GetProp, Image, UploadFile, UploadProps } from 'antd'

interface Props {}

export interface ImagePreviewMethods {
  handleFilePreview: (file: UploadFile) => void
}

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]

const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })

const UploaderImagePreview = forwardRef(({}: Props, ref: Ref<ImagePreviewMethods>) => {
  useImperativeHandle(
    ref,
    (): ImagePreviewMethods => ({
      handleFilePreview: handleFilePreview,
    }),
  )

  async function handleFilePreview(file: UploadFile) {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType)
    }

    setPreviewImage(file.url || (file.preview as string))
    setPreviewOpen(true)
  }

  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')

  if (previewImage)
    return (
      <Image
        wrapperStyle={{ display: 'none' }}
        preview={{
          visible: previewOpen,
          onVisibleChange: (visible) => setPreviewOpen(visible),
          afterOpenChange: (visible) => !visible && setPreviewImage(''),
        }}
        src={previewImage}
      />
    )
  else return <></>
})

UploaderImagePreview.displayName = 'UploaderImagePreview'
export default UploaderImagePreview
