import { FriendUserChatList } from '../../general-components/messenger/FriendUserChatList'
import { UserHeaderUserChatList } from '../header/UserHeaderChatList'

export const MessegesFrinedList = () => {
  return (
    <>
      <UserHeaderUserChatList headerTitle="Write message" />
      <FriendUserChatList />
    </>
  )
}
