import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ActivitiesHttp } from '../../../http/activities-http'
import { InviteType } from '../../../types/types'
import { apiBaseURL } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconCancel } from '../../svg/IconCancel'
import { IconLeftChevrons } from '../../svg/IconChevrons'
import { IconLike } from '../../svg/IconFavor'
import { IconServicesAllPoint } from '../../svg/IconServicesAll'
import { PostSlick } from '../../ui/PostSlick'
import { UserHeader } from '../header/UserHeader'

export const ExploreInvite = () => {
  const { fullName, _id } = useAppSelector((state) => state.user)
  const navigate = useNavigate()
  const [invites, setInvites] = useState<InviteType[]>([])
  useEffect(() => {
    ActivitiesHttp.getInvites({ userId: _id }).then((res) => setInvites(res))
  }, [])
  const toPublishActivities = (_id: string) => {
    navigate(`/user/activities/publish-activities-item?publishActivitiesId=${_id}`)
  }
  const [isOpen, setIsOpen] = useState(false)

  // const accept = async() => {
  //     ActivitiesHttp.ad
  // }

  return (
    <div className="explore__invites">
      <UserHeader>
        <div className="explore__invites-header">
          <button onClick={() => navigate(-1)}>
            <IconLeftChevrons />
          </button>
          <div className="explore__invites-header-text">
            <h5>Invites</h5>
            <h6>
              <b>{fullName}</b> there are{' '}
              <b>
                {invites.length} new {invites.length > 1 ? 'results' : 'result'}
              </b>
              {/* <b>{MarksList.length}</b> {MarksList.length>1 ? "areas" : "area"} */}
            </h6>
          </div>
        </div>
      </UserHeader>
      <div className="explore__invites-container" style={{ margin: '120px 10px' }}>
        <h5>
          <b>Events</b> ({invites.length})
        </h5>
        {invites.map((item) => (
          <>
            <div
              className="user__event-item"
              onClick={() => toPublishActivities(item.eventId._id)}
            >
              <div className="user__event-item-row1">
                <div className="user__event-item-row1-text">
                  <h5>{item.eventId.title}</h5>
                  <h6>{item.eventId.addressLocation}</h6>
                </div>
              </div>
              <div className="admin__posts-list-row2" onClick={() => {}}>
                <PostSlick off={true} list={item.eventId?.filesName}>
                  {item.eventId.filesName.length ? (
                    item.eventId?.filesName?.map((it) => (
                      <>
                        <div className="admin__posts-list-row2-img" onClick={() => {}}>
                          <div className="user__event-item-row2">
                            <IconServicesAllPoint />
                          </div>
                          <div className="user__services-last-item-img-1 user__services-last-item-img-info">
                            <span
                              className="admin__posts-list-row1-text"
                              style={{ color: 'rgba(21, 16, 77, 1)', padding: '20px' }}
                            >
                              {moment(item.eventId?.startDate).format('DD/MM/YYYY HH:mm')}
                            </span>{' '}
                          </div>
                          <img src={`${apiBaseURL}/uploads/publish_post/${it}`} alt="" />
                          <div
                            className="user__services-last-item-img-1 user__services-last-item-img-info"
                            style={{ width: '100px', height: '35px', top: '85%' }}
                          >
                            <div className="user__services-last-item-img-text">
                              <span
                                className="admin__posts-list-row1-text"
                                style={{ color: 'rgba(21, 16, 77, 1)' }}
                              >
                                {item.eventId.activitiesId.name}
                              </span>{' '}
                            </div>
                          </div>
                        </div>
                      </>
                    ))
                  ) : (
                    <div
                      className="admin__posts-list-row2-img"
                      onClick={() => toPublishActivities(item.eventId._id)}
                    >
                      <div className="user__event-item-row2">
                        <IconServicesAllPoint />
                      </div>
                      <div
                        className="user__services-last-item-img-1 user__services-last-item-img-info"
                        style={{ width: 'auto', height: 'auto', padding: '0 10px' }}
                      >
                        <div className="user__services-last-item-img-text">
                          <span
                            className="admin__posts-list-row1-text"
                            style={{ color: 'rgba(21, 16, 77, 1)', margin: '10px 0' }}
                          >
                            {moment(item.eventId?.startDate).format('DD/MM/YYYY HH:mm')}
                          </span>{' '}
                        </div>
                      </div>
                      <img className="posts__bg" src={`/Images/postsBG.png`} alt="" />
                      <div
                        className="user__services-last-item-img-1 user__services-last-item-img-info"
                        style={{ width: '100px', height: '35px', top: '85%' }}
                      >
                        <div className="user__services-last-item-img-text">
                          <span
                            className="admin__posts-list-row1-text"
                            style={{ color: 'rgba(21, 16, 77, 1)' }}
                          >
                            {item.eventId.activitiesId.name}
                          </span>{' '}
                        </div>
                      </div>
                    </div>
                  )}
                </PostSlick>
              </div>
              <div className="user__event-item-row3">
                <h6>{item.eventId.text}</h6>
                {/* <div className="user__event-item-row3-button">
                                {item.startDate.toString().split(".")[0].split("T")[0].replaceAll("-", "/") + " " }{ <b>{item.startDate.toString().split(".")[0].split("T")[1].split(":")[0] + ":" + item.startDate.toString().split(".")[0].split("T")[1].split(":")[1]}</b> }
                            </div> */}
              </div>
              <div className="user__event-item-row4">
                {item.accepted.length ? <div></div> : ''}
              </div>
              <div className="user__event-item-row4-btns">
                <div className="user__event-item-row4-btns-accept">
                  <IconLike />
                  <span>Accept</span>
                </div>
                <div className="user__event-item-row4-btns-decline">
                  <IconCancel />
                  <span>Decline</span>
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
    </div>
  )
}
