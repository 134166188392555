import L, { LatLngExpression } from 'leaflet'
import { useEffect, useState } from 'react'
import { Circle, MapContainer, Polygon, TileLayer } from 'react-leaflet'
import { useNavigate } from 'react-router-dom'
import {
  CategoriesItemInterface,
  SubCategoriesItemInterface,
} from '../../../redux/categories'
import { CoordinatsInterface } from '../../../types/types'
import { createAddressString } from '../../../utils/createAddressString'
import { useAppSelector } from '../../../utils/hooks'
import { LocationEditType } from '../../admin-components/users/UserEditMap'
import { IconLocationPoint } from '../../svg/IconsLocation'
import { MapDefaultMarker } from '../../ui/MapDefaultMarker'

export const PublishAddLocation = ({
  setCoordinates,
  addressLocation,
  setAddressLocation,
  isOpenInit = false,
  polygonCoords,
  radiusSize,
  type,
  coords,
  prevStateService,
  prevStateEvent,
  prevStatePost,
}: {
  setCoordinates: (c: CoordinatsInterface) => void
  addressLocation: string
  setAddressLocation: (s: string) => void
  isOpenInit?: boolean
  polygonCoords: L.LatLng[] | L.LatLng[][] | L.LatLng[][][] | null
  radiusSize: number
  type: string
  coords: CoordinatsInterface
  prevStateService?: {
    title: string
    text: string
    category: CategoriesItemInterface
    subCategory: SubCategoriesItemInterface
    files: (string | File)[]
  }
  prevStateEvent?: {
    title: string
    text: string
    activitiesId: string
    Url: string
    files: (string | File)[]
  }
  prevStatePost?: { title: string; text: string; img: (string | File)[] }
}) => {
  const [isOpen, setIsOpen] = useState(isOpenInit)
  const [radius, setRadius] = useState<boolean>(false)
  const [userPos, setUserPos] = useState<LatLngExpression>()
  const [userPoscurrent, setUserPoscurrent] = useState<[number, number]>()
  const [startPos, setStartPos] = useState<[number, number]>()
  const [map, setMap] = useState<L.Map | null>(null)
  const [draw, setDraw] = useState<boolean>(false)
  const [drawed, setDrawed] = useState<boolean>(false)
  const [center, setCenter] = useState<L.LatLng | null>(null)
  const { role } = useAppSelector((state) => state.user)
  const [paint, setPaint] = useState<boolean>(false)
  const [polygon, setPolygon] = useState<L.Polygon | null>()
  const navigate = useNavigate()
  const changeLocation = (l: LocationEditType) => {
    setCoordinates(l.coordinates)
    setAddressLocation(`${l.country}, ${l.city}, ${l.street}, ${l.houseNumber}`)
  }

  useEffect(() => {
    if (userPoscurrent) {
      const geocoder = new google.maps.Geocoder()
      const LatLng = new google.maps.LatLng(userPoscurrent[0], userPoscurrent[1])
      geocoder.geocode({ location: LatLng }, async (results, status) => {
        if (status === 'OK' && results && results[0]) {
          const address = createAddressString(results[0].address_components)
          setAddressLocation(
            `${address.street}, ${address.houseNumber}, ${address.city}, ${address.country}`,
          )
          setCoordinates({ lat: userPoscurrent[0], lng: userPoscurrent[1] })
        }
      })
    }
    if (startPos) {
      const geocoder = new google.maps.Geocoder()
      const LatLng = new google.maps.LatLng(startPos[0], startPos[1])
      geocoder.geocode({ location: LatLng }, async (results, status) => {
        if (status === 'OK' && results && results[0]) {
          const address = createAddressString(results[0].address_components)
          setAddressLocation(
            `${address.street}, ${address.houseNumber}, ${address.city}, ${address.country}`,
          )
          setCoordinates({ lat: startPos[0], lng: startPos[1] })
        }
      })
    }
  }, [userPoscurrent, startPos])

  useEffect(() => {
    if (map && !drawed) {
      setUserPoscurrent([map?.getCenter().lat, map?.getCenter().lng])
      console.log(map?.getCenter())
    }
  }, [map])

  const getUserPos = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          setUserPos([pos.coords.latitude, pos.coords.longitude])
          const geocoder = new google.maps.Geocoder()
          const LatLng = new google.maps.LatLng(pos.coords.latitude, pos.coords.longitude)
          geocoder.geocode({ location: LatLng }, async (results, status) => {
            if (status === 'OK' && results && results[0]) {
              const address = createAddressString(results[0].address_components)
              setAddressLocation(
                `${address.street}, ${address.houseNumber}, ${address.city}, ${address.country}`,
              )
              setCoordinates({ lat: pos.coords.latitude, lng: pos.coords.longitude })
            }
          })
        },
        () => console.log('Cannot get user location'),
      )
    }
  }
  useEffect(() => {
    getUserPos()
  }, [])
  return (
    <>
      <div className="publish__location">
        {(polygonCoords || radiusSize) && coords ? (
          <div>
            <div
              className={'publish__location-map'}
              onClick={() => {
                if (prevStateService) {
                  navigate('/publish/map', { state: { page: type, ...prevStateService } })
                }
                if (prevStateEvent) {
                  navigate('/publish/map', { state: { page: type, ...prevStateEvent } })
                }
                if (prevStatePost) {
                  navigate('/publish/map', { state: { page: type, ...prevStatePost } })
                }
              }}
            >
              <MapContainer
                zoomControl={false}
                center={coords}
                minZoom={10}
                maxZoom={18}
                zoom={18}
                scrollWheelZoom={true}
                ref={setMap}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                  url="https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png"
                  subdomains="abcd"
                />
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                  url="https://{s}.basemaps.cartocdn.com/dark_only_labels/{z}/{x}/{y}{r}.png"
                  subdomains="abcd"
                />
                {coords && !polygon && (
                  <Circle center={coords} radius={radiusSize * 1000} />
                )}
                {polygonCoords && <Polygon positions={polygonCoords} ref={setPolygon} />}
                {coords && !polygon && (
                  <MapDefaultMarker coords={[coords.lat, coords.lng]} />
                )}
                {polygon && (
                  <MapDefaultMarker
                    coords={[polygon.getCenter().lat, polygon.getCenter().lng]}
                  />
                )}
              </MapContainer>
            </div>
            <p className="publish__location-map-address">{addressLocation}</p>
          </div>
        ) : (
          <button
            className="publish__location-button"
            onClick={() => {
              if (prevStateService) {
                navigate('/publish/map', { state: { page: type, ...prevStateService } })
              }
              if (prevStateEvent) {
                navigate('/publish/map', { state: { page: type, ...prevStateEvent } })
              }
              if (prevStatePost) {
                navigate('/publish/map', { state: { page: type, ...prevStatePost } })
              }
            }}
          >
            <IconLocationPoint />
            Add Location
          </button>
        )}
      </div>
    </>
  )
}
