import { AxiosResponse } from 'axios'
import { LatLng } from 'leaflet'
import { useState, useRef, useEffect } from 'react'
import $api from '../../../http'
import { Countries } from '../../../types/enum'
import { useAppSelector } from '../../../utils/hooks'
import { IconArrachFile } from '../../svg/IconArrachFile'
import { IconArrowRight } from '../../svg/IconArrow'
import { IconBottomChevrons } from '../../svg/IconChevrons'
import { InputSearch } from '../../ui/InputSearch'
import { success } from '../../ui/LoadSuccess'
import { Modal } from '../../ui/Modal'
import CA from './ca.json'
import FR from './fr.json'
import UA from './ua.json'

export const AddMarkerModal = ({
  isOpen,
  setIsOpen,
  updateMarks,

  radiusSize,
  polygonCoords,
  center,
  setRadius,
}: {
  isOpen: boolean
  setIsOpen: (s: boolean) => void
  updateMarks: () => void

  polygonCoords?: LatLng[] | LatLng[][] | LatLng[][][]
  radiusSize?: number
  center?: LatLng
  setRadius: (s: boolean) => void
}) => {
  const list = [Countries.CA, Countries.FR, Countries.UA]
  const [LocationName, setLocationName] = useState<string>('')
  const [image, setImage] = useState<File | null>(null)
  const [imageUrl, setImageUrl] = useState<string>('')

  const [search, setSearch] = useState('')

  const [country, setCountry] = useState(Countries.CA)
  const [city, setCity] = useState(CA[8].capital)

  const [isOpenList, setIsOpenList] = useState(false)
  const [isOpenCity, setIsOpenCity] = useState(false)

  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const { _id } = useAppSelector((state) => state.user)
  const validate = !Boolean(
    image && LocationName.replaceAll(' ', '').length && center && country,
  )
  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null
    setImage(file)
    if (file) {
      const url = URL.createObjectURL(file)
      setImageUrl(url)
    }
  }
  const makeAdminMarker = async () => {
    let fileName: null | string = null
    if (image) {
      const formData = new FormData()

      formData.append('file', image)

      const res: AxiosResponse<string> = await $api.post('map/file-mark', formData)
      fileName = res.data
      if (polygonCoords && LocationName && center) {
        await $api
          .post('map/create-mark', {
            file: fileName,
            title: LocationName,
            adminId: _id,
            polygonCoords,
            radiusSize: 0,
            center,
            country,
            city,
          })
          .then(() => {
            updateMarks()
            success()
            setIsOpen(false)
          })
      }
      if (radiusSize && LocationName && center) {
        await $api
          .post('map/create-mark', {
            file: fileName,
            title: LocationName,
            adminId: _id,
            polygonCoords: null,
            radiusSize,
            center,
            country,
            city,
          })
          .then(() => {
            updateMarks()
            success()
            setIsOpen(false)
          })
      }
    }
  }
  useEffect(() => {
    if (country === Countries.UA) {
      setCity(UA[0].city)
    }
    if (country === Countries.FR) {
      setCity(FR[0].city)
    }
    if (country === Countries.CA) {
      setCity(CA[8].capital)
    }
  }, [country])

  return (
    <div
      className={`activities__favor-modal  ${
        isOpen ? 'activities__favor-modal--open' : ''
      }`}
    >
      <Modal className="" setIsOpen={setIsOpen}>
        <div
          onClick={(e) => {
            e.stopPropagation()
            setIsOpen(false)
            setRadius(false)
          }}
          className="activities__favor-modal-linebody"
        >
          <button className="activities__favor-modal-line" />
        </div>
        <div className="new__group__form" style={{ padding: '0 20px' }}>
          <span>
            <b>Create Location</b>
          </span>
          <p>
            <span className="new__group__title">Set location name</span>
          </p>
          <input
            onChange={(e) => setLocationName(e.target.value)}
            type="text"
            name="groupName"
            className="group__chatName__input"
            placeholder="Enter your Location name"
          />
          <p>
            <span className="new__group__title">Select location icon</span>
          </p>
          <div className="admin__image__container">
            <label htmlFor="file-avatar-profile" className="admin__panel__file">
              <IconArrachFile />
            </label>
            <input
              multiple={false}
              id="file-avatar-profile"
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileSelect}
            />
            <img src={`${imageUrl}`} alt="" />
            <div>{image?.name}</div>
          </div>

          <p>
            <span className="new__group__title">Select coutry</span>
          </p>
          <div
            className="select__country"
            onClick={(e) => {
              e.preventDefault()
              setIsOpenList(true)
            }}
          >
            <h5>{country}</h5>
            {isOpenList ? <IconBottomChevrons /> : <IconArrowRight />}
          </div>
          {isOpenList && (
            <div className="select__country-list">
              {list.map((item) => (
                <div
                  className="select__country-list-item"
                  onClick={(e) => {
                    setCountry(item)
                    setIsOpenList(false)
                  }}
                >
                  <h5>{item}</h5>
                </div>
              ))}
            </div>
          )}

          <p>
            <span className="new__group__title">Select city</span>
          </p>
          <div
            className="select__country"
            onClick={(e) => {
              e.preventDefault()
              setIsOpenCity(true)
            }}
          >
            <h5>{city}</h5>
            {isOpenCity ? <IconBottomChevrons /> : <IconArrowRight />}
          </div>
          {isOpenCity && (
            <InputSearch
              value={search}
              changeValue={setSearch}
              placeholder={'City name'}
            />
          )}
          {isOpenCity && (
            <div className="select__country-list">
              {country === Countries.CA &&
                CA.filter((it) =>
                  it.capital
                    .toLowerCase()
                    .replaceAll(' ', '')
                    .includes(search.toLowerCase().replaceAll(' ', '')),
                )
                  .slice(0, 3)
                  .map((item) => (
                    <div
                      className="select__country-list-item"
                      onClick={(e) => {
                        setCity(item.capital)
                        setIsOpenCity(false)
                      }}
                    >
                      <h5>{item.capital}</h5>
                    </div>
                  ))}
              {country === Countries.FR &&
                FR.filter((it) =>
                  it.city
                    .toLowerCase()
                    .replaceAll(' ', '')
                    .includes(search.toLowerCase().replaceAll(' ', '')),
                )
                  .slice(0, 3)
                  .map((item) => (
                    <div
                      className="select__country-list-item"
                      onClick={(e) => {
                        setCity(item.city)
                        setIsOpenCity(false)
                      }}
                    >
                      <h5>{item.city}</h5>
                    </div>
                  ))}
              {country === Countries.UA &&
                UA.filter((it) =>
                  it.city
                    .toLowerCase()
                    .replaceAll(' ', '')
                    .includes(search.toLowerCase().replaceAll(' ', '')),
                )
                  .slice(0, 3)
                  .map((item) => (
                    <div
                      className="select__country-list-item"
                      onClick={(e) => {
                        setCity(item.city)
                        setIsOpenCity(false)
                      }}
                    >
                      <h5>{item.city}</h5>
                    </div>
                  ))}
            </div>
          )}

          <button
            style={{ maxWidth: '90%', margin: '50px 5%' }}
            className={`advertisement__add-main-btn ${
              validate && 'advertisement__add-main-btn-disabled'
            }`}
            onClick={makeAdminMarker}
            disabled={validate}
          >
            Continue
          </button>
        </div>
      </Modal>
    </div>
  )
}
