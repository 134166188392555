export const IconProfileInfoPen = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M3 21H6.75L17.81 9.94L14.06 6.19L3 17.25V21ZM5 18.08L14.06 9.02L14.98 9.94L5.92 19H5V18.08ZM18.37 3.29C18.2775 3.1973 18.1676 3.12375 18.0466 3.07357C17.9257 3.02339 17.796 2.99756 17.665 2.99756C17.534 2.99756 17.4043 3.02339 17.2834 3.07357C17.1624 3.12375 17.0525 3.1973 16.96 3.29L15.13 5.12L18.88 8.87L20.71 7.04C20.8027 6.94749 20.8762 6.8376 20.9264 6.71663C20.9766 6.59565 21.0024 6.46597 21.0024 6.335C21.0024 6.20403 20.9766 6.07435 20.9264 5.95338C20.8762 5.83241 20.8027 5.72252 20.71 5.63L18.37 3.29Z"
        fill="#3361FF"
      />
    </svg>
  )
}

export const IconProfileInfoKey = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M15.0001 2.93408C11.5629 2.93408 8.83203 5.67439 8.83203 9.0927C8.83203 11.6258 10.3199 13.867 12.6741 14.8087V23.9337C12.6741 24.2068 12.7589 24.4516 12.966 24.6588L14.6046 26.2408C14.8117 26.448 15.2072 26.495 15.4709 26.2314L18.4372 23.2651C18.7103 22.992 18.7009 22.5965 18.4372 22.3328L16.7328 20.6472L19.1247 18.2553C19.3695 18.001 19.3789 17.6055 19.1058 17.3324L16.7799 14.9971C19.5955 13.8011 21.1587 11.6447 21.1587 9.0927C21.1587 5.6838 18.4184 2.93408 15.0001 2.93408ZM15.0001 8.65953C14.0866 8.65953 13.3427 7.9156 13.3427 7.00216C13.3427 6.07931 14.0772 5.3448 15.0001 5.3448C15.9135 5.3448 16.6574 6.08873 16.6574 7.00216C16.6574 7.9156 15.9135 8.65953 15.0001 8.65953Z"
        fill="#C3CAD9"
      />
    </svg>
  )
}

export const IconProfileInfoBookmark = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 6H10C8.9 6 8 6.9 8 8V24L15 21L22 24V8C22 6.9 21.1 6 20 6Z"
        stroke={'#C3CAD9'}
        strokeWidth="2"
      />
    </svg>
  )
}

export const IconProfileInfoBookmarkCopy = ({ fill }: { fill?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 6H10C8.9 6 8 6.9 8 8V24L15 21L22 24V8C22 6.9 21.1 6 20 6Z"
        stroke={fill || '#C3CAD9'}
        strokeWidth="2"
      />
    </svg>
  )
}

export const IconProfileInfoNotification = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M7.46652 20.8542H22.5241C23.494 20.8542 24.0873 20.3269 24.0873 19.5359C24.0873 18.5377 23.1832 17.6807 22.3357 16.8709C21.6671 16.2211 21.5165 14.8651 21.394 13.6127C21.2434 10.1661 20.2358 7.73654 17.7968 6.86077C17.4107 5.61775 16.3843 4.65723 14.9906 4.65723C13.6063 4.65723 12.5799 5.61775 12.1844 6.86077C9.75481 7.73654 8.73779 10.1661 8.59654 13.6127C8.47412 14.8651 8.31403 16.2211 7.65485 16.8709C6.80734 17.6807 5.90332 18.5377 5.90332 19.5359C5.90332 20.3269 6.49658 20.8542 7.46652 20.8542ZM14.9906 24.734C16.6668 24.734 17.8816 23.538 18.004 22.1726H11.9866C12.109 23.538 13.3238 24.734 14.9906 24.734Z"
        fill="#C3CAD9"
      />
    </svg>
  )
}

export const IconProfileInfoFlag = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4 9L17.16 7.8C17.07 7.34 16.66 7 16.18 7H9C8.45 7 8 7.45 8 8V23C8 23.55 8.45 24 9 24C9.55 24 10 23.55 10 23V17H15.6L15.84 18.2C15.93 18.67 16.34 19 16.82 19H22C22.55 19 23 18.55 23 18V10C23 9.45 22.55 9 22 9H17.4Z"
        fill="#C3CAD9"
      />
    </svg>
  )
}

export const IconProfileInfoComments = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 5H7C5.9 5 5.01 5.9 5.01 7L5 25L9 21H23C24.1 21 25 20.1 25 19V7C25 5.9 24.1 5 23 5ZM20 17H10C9.45 17 9 16.55 9 16C9 15.45 9.45 15 10 15H20C20.55 15 21 15.45 21 16C21 16.55 20.55 17 20 17ZM20 14H10C9.45 14 9 13.55 9 13C9 12.45 9.45 12 10 12H20C20.55 12 21 12.45 21 13C21 13.55 20.55 14 20 14ZM20 11H10C9.45 11 9 10.55 9 10C9 9.45 9.45 9 10 9H20C20.55 9 21 9.45 21 10C21 10.55 20.55 11 20 11Z"
        fill="#C3CAD9"
      />
    </svg>
  )
}
