import L from 'leaflet'
import { renderToString } from 'react-dom/server'
import { Marker } from 'react-leaflet'
import { CoordinatsInterface } from '../../types/types'
import { apiBaseURL } from '../route/indexPathByRole'
import { IconMapUser } from '../svg/IconMapUser'

export const MapUserMarker = ({
  coords,
  avatarFileName,
  fullName,
  night,
}: {
  coords: CoordinatsInterface
  avatarFileName: string | null
  fullName: string
  night?: boolean
}) => {
  const markDivIcon = new L.DivIcon({
    html: renderToString(
      <>
        <div
          style={{
            borderRadius: '3px',
            padding: '3px',
            height: '42px',
            width: '42px',
            position: 'absolute',
            left: '-10px',
            top: '-22px',
          }}
        >
          {!night ? (
            <div
              className="image"
              style={{
                backgroundColor: 'rgba(15, 20, 25, 1)',
                border: 'solid rgba(15, 20, 25, 1) 4px;',
              }}
            >
              {avatarFileName ? (
                <img src={`${apiBaseURL}/uploads/avatar/${avatarFileName}`} />
              ) : (
                <div
                  style={{
                    backgroundColor: 'rgba(41, 52, 65, 1)',
                    borderRadius: '100%',
                    width: '100%',
                    height: '100%',
                    color: '#fff',
                    textAlign: 'center',
                    position: 'relative',
                    top: '0',
                    left: '0',
                  }}
                >
                  <span style={{ position: 'absolute', left: '32%', top: '32%' }}>
                    {fullName.slice(0, 2)}
                  </span>
                </div>
              )}
            </div>
          ) : (
            <div
              className="image"
              style={{ backgroundColor: '#fff', border: 'solid #fff 4px' }}
            >
              {avatarFileName ? (
                <img src={`${apiBaseURL}/uploads/avatar/${avatarFileName}`} />
              ) : (
                <div
                  style={{
                    backgroundColor: 'rgba(41, 52, 65, 1)',
                    borderRadius: '100%',
                    width: '100%',
                    height: '100%',
                    color: '#fff',
                    textAlign: 'center',
                    position: 'relative',
                    top: '0',
                    left: '0',
                  }}
                >
                  <span style={{ position: 'absolute', left: '32%', top: '32%' }}>
                    {fullName.slice(0, 2)}
                  </span>
                </div>
              )}
            </div>
          )}
          {!night ? <IconMapUser /> : <IconMapUser fill={'#fff'} />}
        </div>
      </>,
    ),
    iconSize: [32, 32],
    className: 'leaflet-user-icon',
  })
  return <Marker position={coords} icon={markDivIcon}></Marker>
}
