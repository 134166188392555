import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import $api from '../../../http'
import { ActivitiesHttp } from '../../../http/activities-http'
import { InviteType, LocationType } from '../../../types/types'
import { apiBaseURL, indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconAdminClose } from '../../svg/IconAdminHeader'
import { IconLeftChevrons } from '../../svg/IconChevrons'
import { IconNeibsCopy } from '../../svg/IconPassEye'
import { UserHeader } from '../header/UserHeader'

export const ExploreInvites = () => {
  const { fullName, _id, role } = useAppSelector((state) => state.user)
  const [MarksList, setMarksList] = useState<LocationType[]>([])
  const navigate = useNavigate()
  const [banList, setBanList] = useState<string[]>([])
  const banLocation = (item: string) => {
    let array: string[] = [...banList]
    array.push(item)
    setBanList(array)
  }
  const [invites, setInvites] = useState<InviteType[]>([])
  useEffect(() => {
    ActivitiesHttp.getInvites({ userId: _id }).then((res) => setInvites(res))
  }, [])
  useEffect(() => {
    const body = async () => {
      let array: LocationType[] = []
      $api
        .post('map/list-marks')
        .then((res) => {
          res.data.map((item: LocationType) => {
            if (item.subscribers.includes(_id)) {
              array.push(item)
            }
          })
        })
        .then(() => {
          setMarksList(array)
        })
    }
    body()
  }, [])

  const toDistrict = (_id: string) => {
    navigate(`${indexPathByRole(role)}/explore/district?regId=${_id}`, {
      state: { regId: _id },
    })
  }

  return (
    <div className="explore__invites">
      <UserHeader>
        <div className="explore__invites-header">
          <button onClick={() => navigate(-1)}>
            <IconLeftChevrons />
          </button>
          <div className="explore__invites-header-text">
            <h5>My areas</h5>
            <h6>
              <b>{fullName}</b> you are following <b>{MarksList.length}</b>{' '}
              {MarksList.length > 1 ? 'areas' : 'area'}
            </h6>
          </div>
        </div>
      </UserHeader>
      <div className="explore__invites-container">
        {!banList.includes('invites') && (
          <div
            className="explore__region"
            style={{
              position: 'relative',
              backgroundColor: '#fff',
              maxWidth: '95%',
              margin: '20px 2.5%',
              borderRadius: '12px',
            }}
            onClick={() => navigate(`${indexPathByRole(role)}/explore/invites`)}
          >
            <div className="explore__region-img">
              <IconNeibsCopy fill="rgba(31, 32, 36, 1)" />
            </div>
            <div>
              <div className="explore__region-text">
                <h5>Invites</h5>
                <h6>
                  <b>{invites.length}</b> neibs in this area
                </h6>
                {invites.length ? (
                  <div
                    className="explore__region-text-updates"
                    style={{ maxWidth: '110px' }}
                  >
                    {invites.length} new {invites.length > 1 ? 'invites' : 'invite'}
                  </div>
                ) : (
                  ''
                )}
                {!invites.length ? (
                  <div className="explore__region-text-updates-no">no new invites</div>
                ) : (
                  ''
                )}
              </div>
            </div>
            <button
              style={{ position: 'absolute', left: '90%' }}
              onClick={(e) => {
                e.stopPropagation()
                banLocation('invites')
              }}
            >
              <IconAdminClose />
            </button>
          </div>
        )}
        <div className="explore__invites-list">
          {MarksList.filter((it) => !banList.includes(it.title)).map((item) => (
            <div
              className="explore__region"
              style={{
                position: 'relative',
                backgroundColor: '#fff',
                maxWidth: '95%',
                margin: '20px 2.5%',
                borderRadius: '12px',
              }}
              onClick={() => toDistrict(item?._id)}
            >
              <img src={`${apiBaseURL}/uploads/map/${item.file}`} />
              <div>
                <div className="explore__region-text">
                  <h5>{item.title}</h5>
                  <h6>
                    <b>{item.subscribers.length}</b>{' '}
                    {item.subscribers.length > 1 ? 'neibs' : 'neib'} in this area
                  </h6>
                  <div className="explore__region-text-updates">
                    {item.subscribers.length} new{' '}
                    {item.subscribers.length > 1 ? 'results' : 'result'}
                  </div>
                  {!item.subscribers.length ? (
                    <div className="explore__region-text-updates-no">no new results</div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <button
                style={{ position: 'absolute', left: '90%' }}
                onClick={(e) => {
                  e.stopPropagation()
                  banLocation(item.title)
                }}
              >
                <IconAdminClose />
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
