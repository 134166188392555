import L, { LatLngExpression } from 'leaflet'
import { useEffect, useState } from 'react'
import { Circle, MapContainer, Polygon, TileLayer } from 'react-leaflet'
import { useLocation, useNavigate } from 'react-router-dom'
import { IconLeftChevrons } from '../../svg/IconChevrons'
import { IconPencil } from '../../svg/IconPencil'
import { ExploreCancelIcon } from '../../user-components/explore/ExploreCancelIcon'
import { DrawModal } from '../../ui/DrawModal'
import { MapDefaultMarker } from '../../ui/MapDefaultMarker'
import { indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'

export const MessegesMap = () => {
  const [isOpenMap, setIsOpenMap] = useState(false)
  const [radius, setRadius] = useState<boolean>(false)
  const [userPos, setUserPos] = useState<LatLngExpression>()
  const [userPoscurrent, setUserPoscurrent] = useState<[number, number]>()
  const [startPos, setStartPos] = useState<[number, number]>()
  const [map, setMap] = useState<L.Map | null>(null)
  const [polyline, setPolyline] = useState<L.Polyline | null>()
  const [draw, setDraw] = useState<boolean>(false)
  const [drawed, setDrawed] = useState<boolean>(false)
  const [polygon, setPolygon] = useState<L.Polygon | null>()
  const [polygonMarker, setPolygonMarker] = useState<L.Polygon | null>()
  const [pointlatlngCurrent, setPointlatlngCurrent] = useState<L.LatLng | null>(null)
  const [posCurrent, setPosCurrent] = useState<L.LatLng | null>(null)
  const [center, setCenter] = useState<L.LatLng | null>(null)
  const [polygonCoords, setPolygonCoords] = useState<
    L.LatLng[] | L.LatLng[][] | L.LatLng[][][] | null
  >()
  const [radiusSize, setRadiusSize] = useState(0)
  const navigate = useNavigate()
  const location = useLocation()
  const [paint, setPaint] = useState<boolean>(false)
  const [coordinates, setCoordinates] = useState<LatLngExpression>()
  map?.on('moveend', () => {
    if (radius && !drawed) {
      setUserPoscurrent([map?.getCenter().lat, map?.getCenter().lng])
      setCoordinates({ lat: map?.getCenter().lat, lng: map?.getCenter().lng })
    }
  })
  useEffect(() => {
    if (radius && userPos) {
      setCoordinates(userPos)
    }
  }, [radius])

  useEffect(() => {
    if (!drawed && !polyline && posCurrent && map) {
      map?.dragging.disable()
      setPaint(true)
      setPolygonCoords([])
      setPolyline(L.polyline([]).addTo(map).addLatLng(posCurrent))
      setStartPos([posCurrent.lat, posCurrent.lng])
    }
  }, [posCurrent])

  if (map && draw && !polygonCoords?.length)
    L.DomEvent.on(map?.getContainer(), 'touchstart', (e) => {
      let touch = (e as TouchEvent).targetTouches[0],
        rect = map?.getContainer().getBoundingClientRect(),
        lat = touch.clientX - rect.left - map?.getContainer().clientLeft,
        lng = touch.clientY - rect.top - map?.getContainer().clientTop,
        containerPoint = L.point(lat, lng)

      let p = map?.containerPointToLayerPoint(containerPoint)
      let pointlatlng
      // console.log(pointlatlng)
      if (!paint && draw && !polyline && map && !polygon && !polygonCoords?.length) {
        // e.stopPropagation()

        // let point = L.point((e as TouchEvent).targetTouches[0].clientX, (e as TouchEvent).targetTouches[0].clientY);

        if (p) pointlatlng = map?.layerPointToLatLng(p)
        if (pointlatlng) setPosCurrent(pointlatlng)
        // setPolygonCords([]);
      }
    })
  if (!draw && map) L.DomEvent.off(map?.getContainer(), 'touchstart', (e) => {})
  useEffect(() => {
    if (drawed && !polygonCoords?.length && map && polyline) {
      setPolygonCoords(polyline?.getLatLngs())
      polyline?.setLatLngs([])
      polyline?.removeFrom(map)
      setPolyline(undefined)
      map?.dragging.enable()
    }
  }, [drawed])
  useEffect(() => {
    if (polygon && drawed) {
      setCenter(polygon.getCenter())
    }
  }, [polygon])

  if (map && draw && !polygonCoords?.length)
    L.DomEvent.on(map?.getContainer(), 'touchend', (e) => {
      if (
        map &&
        polyline?.getLatLngs().length &&
        draw &&
        !polygon &&
        !polygonCoords?.length
      ) {
        // setPolygonCords(polyline?.getLatLngs())
        //   polyline?.setLatLngs([])
        //   polyline?.removeFrom(map)
        //   setPolyline(undefined)
        // setDraw(false);
        // map?.dragging.enable()
        setDrawed(true)
        e.stopImmediatePropagation()
        // setDrawMode(true)
      }
    })
  if (drawed && map) L.DomEvent.off(map?.getContainer(), 'touchmove', (e) => {})
  useEffect(() => {
    if (pointlatlngCurrent && draw) {
      polyline?.addLatLng(pointlatlngCurrent)
    }
  }, [pointlatlngCurrent])

  if (map && draw && !polygonCoords?.length)
    L.DomEvent.on(map?.getContainer(), 'touchmove', (e) => {
      if (!polygonCoords?.length && paint && draw && !polygon && !polygonCoords?.length) {
        let touch = (e as TouchEvent).targetTouches[0],
          rect = map?.getContainer().getBoundingClientRect(),
          lat = touch.clientX - rect.left - map?.getContainer().clientLeft,
          lng = touch.clientY - rect.top - map?.getContainer().clientTop,
          containerPoint = L.point(lat, lng)

        let p = map?.containerPointToLayerPoint(containerPoint)
        let pointlatlng

        if (p) pointlatlng = map?.layerPointToLatLng(p)
        if (startPos?.length && pointlatlng && !drawed) {
          if (!polyline?.getLatLngs().length && map) setPolyline(L.polyline([startPos]))
          setPointlatlngCurrent(pointlatlng)
          // polyline?.addLatLng(pointlatlng)
          if (
            pointlatlng.lat === startPos[0] &&
            pointlatlng.lng === startPos[1] &&
            map &&
            polyline?.getLatLngs().length
          ) {
            setPolygonCoords(polyline?.getLatLngs())
            polyline?.setLatLngs([])
            polyline?.removeFrom(map)
            setPolyline(undefined)
            map?.dragging.enable()
            setDrawed(true)
            e.stopImmediatePropagation()
          }
        }
      }
    })
  const [isOpenModal, setIsOpenModal] = useState(true)

  const getUserPos = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          setUserPos([pos.coords.latitude, pos.coords.longitude])
        },
        () => console.log('Cannot get user location'),
      )
    }
  }
  const { role } = useAppSelector((state) => state.user)
  useEffect(() => {
    getUserPos()
  }, [])
  const aply = () => {
    if (radiusSize || polygon) {
      navigate(`${indexPathByRole(role)}/messeges/group-interests`, {
        state: {
          ...location.state,
          polygonCoords,
          radiusSize,
          coords: coordinates ? coordinates : polygon?.getCenter(),
        },
      })
    }
  }
  return (
    <>
      <div className="location__current">
        <div className="location__current-map">
          <div
            onClick={() => setIsOpenModal(true)}
            style={{
              position: 'absolute',
              right: '10px',
              top: '50px',
              backgroundColor: '#000',
              zIndex: '95',
              borderRadius: '100%',
            }}
          >
            <IconPencil />
          </div>
          {userPos && (
            <MapContainer
              zoomControl={false}
              center={location.state?.center || userPos}
              minZoom={8}
              maxZoom={18}
              zoom={location.state?.zoom || 12}
              scrollWheelZoom={true}
              ref={setMap}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                url="https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png"
                subdomains="abcd"
              />
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                url="https://{s}.basemaps.cartocdn.com/dark_only_labels/{z}/{x}/{y}{r}.png"
                subdomains="abcd"
              />
              {radius && (
                <Circle center={userPoscurrent || userPos} radius={radiusSize * 1000} />
              )}
              {polygonCoords && <Polygon positions={polygonCoords} ref={setPolygon} />}
              {drawed && center && <MapDefaultMarker coords={center} />}
              {userPoscurrent && radius && <MapDefaultMarker coords={userPoscurrent} />}
              {startPos && (
                <ExploreCancelIcon
                  handler={() => {
                    navigate('', {
                      state: {
                        ...location.state,
                        center: map?.getCenter(),
                        zoom: map?.getZoom(),
                      },
                    })
                    window.location.reload()
                  }}
                  position={startPos}
                />
              )}
            </MapContainer>
          )}
        </div>
      </div>
      {isOpenModal && (
        <DrawModal
          radiusSize={radiusSize}
          isOpen={isOpenModal}
          setIsOpen={setIsOpenModal}
          setPolygonCoords={setPolygonCoords}
          setRadiusSize={setRadiusSize}
          setPolyline={setPolyline}
          setDraw={setDraw}
          draw={draw}
          radius={radius}
          setRadius={setRadius}
          zIndex={'96'}
          aply={aply}
        />
      )}
    </>
  )
}
