export const IconCheckMark = () => {
  return (
    <svg
      style={{
        filter:
          'brightness(0) saturate(100%) invert(51%) sepia(99%) saturate(2648%) hue-rotate(128deg) brightness(95%) contrast(106%)',
      }}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect width="18" height="18" fill="url(#pattern0)" />
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image0_1_37641" transform="scale(0.0111111)" />
        </pattern>
        <image
          id="image0_1_37641"
          width="90"
          height="90"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEt0lEQVR4nO2dS4gcVRSGPyeJM5LoStCoICJZig/MSpNo8JGIYkR8RYN5aYQEjIq6UXClEhBEMDtx5874JIrBjQsFiYmt0uIiGiEPNSYxbiZjZubIhdPQNN3T1VP3carqfvBverqrbv19+ta95566A5lMJpPJZDKZTCaTKcwCYDmwBdgFfAj8ABwCTgFTwFngL31tP/Ap8DrwGHA9sKj46ZrFFcBO4BPgDCAl9S/wPrAVuJyGcwGwAdgHzHgwd5BmgS+B+4GFNIgLgReAPwKaO0hHgZeAJdSYRcDzwMkEBvfKfclP1THCVwE/GTC4Vz8DK6kBE8Db2k+KUc3oiOX8Ea9tXD93XDWfY3hhGXDAgJFSUAe0zUVN3tvnGK8Rmds8DdMksv4GbirwK+1nstMxInIfMGnANJmn3GTokQHX5rqGj4eMaqLwZOAxsUSSu4aNI0RyR6/GMPnBmpgsqnPAuhFM3qt9d1BW609OaqYp4B5NDcz1vs/1ywjKsore+KSA3L3mYU1eJY1kd4LvDBgiAaPZceOAX2yUSEYnI3WN5DU917ozRSQ7Vhif8YmHSO5mDPg6diS7ZEyrQSZ3uEZvjFFMRrNw0oDuohf398eJxBKdqjYpkh136Jfxe6zk0YsNNllUbj0zKBOaOGmyyQIcDh3VGwyYI5H75EEJsodCGr2vQZF855AspBviBSsJqEPSaLJkJHfkvFgawujemVGTTRbVthBGD8tgNc1k0Qop72Vapw2YJQn75H46o954Y7kBs8RQJHfrOp9GbzVgmBiK5G5t9mn0LgOmiUGTnd7wafRHBowTz93FWk+r9W6Q4I0qpUQnI5rcKbzxxm8lG/OVFhXu0MLxupjsvWimTFr0PV2d6F40eKcmJneGeN6YKtGQK/scb8yz2alMdvrPgtEzczxPMubJ7JQmi66Om+g6bp3juGXNTm2y0wkrN8NfgIsDmG3BZKdfLQ3vWp7NLmpyjDK1g9YmLG3g0jnOcR6w29CMr6j2WJyCt0uabc1k71X+Wzw2rD1Psy2aLH3qqE2lSVsj9tmW+uRe3WA98d8uGNlWI1nUE6+Jf4Y8uxEysq8d0q67EhbBe70Rdng6UGNbQ8y2arLTdgJwGTAdqMHtId2I7+UnH5qeR5sL80XAhrdHaHhqk50+o8IlYe0CZlsw2Wl96CLHownNXmPE5OO630jli9BbwCU9573b0CN2zxJpY5MYe278CbysFfbvGqr7c2nRxUTiOQMXLInkhrnRcOt+3xu4aImsH1PsQHZzTR9/kwGa1Z10kvCWAQMkkt4kIeOB6zTEiL5NtY1PN1cD/xgwQwJm6K7CCLcYmUiIZ7kU7e0Y496ASSdJIDdufwCjPGFoYiElNK014aZZV/Fu5KxuW1QJVlf0Bnla7zeVwhU3fmPAPCmo/TqCqiTjOqmZNWDkIM3qZCT5ONnXdL1lwNRetQrs3Fg5FgLPaJoxtcEnNAtXu22Nu1msF3kkgcEux/0KcBENYgJ4VHcJCDnRmdaF1PUx90KyylJ9kOgD/c8UZc09pcfaHrIkoOos0Hq2zbr59R6tQT6kS2hTqpP62kF9j3vvJv2s9zKtTCaTyWQymUwmk6Gu/A/hqXoLSPHpdgAAAABJRU5ErkJggg=="
        />
      </defs>
    </svg>
  )
}
