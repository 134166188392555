import moment from 'moment'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ActivitiesHttp } from '../../../http/activities-http'
import { PublishEventItemInterface } from '../../../types/types'
import { apiBaseURL, indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { UserHeaderChat } from '../header/UserHeaderChat'

export const MessegesEvents = () => {
  const navigate = useNavigate()
  const [search, setSearch] = useState('')
  const [isActiveSearch, setIsActiveSearch] = useState(false)
  const [chats, setChats] = useState<PublishEventItemInterface[]>([])
  const { _id, role } = useAppSelector((state) => state.user)
  const { publishActivities } = useAppSelector((state) => state.activities)
  useEffect(() => {
    setChats(publishActivities)
  }, [publishActivities])
  const toPublishActivities = (_id: string) => {
    navigate(
      `${indexPathByRole(role)}/activities/publish-activities-item?publishActivitiesId=${_id}`,
    )
  }

  return (
    <div className={`user__chat ${isActiveSearch && 'user__chat--active'}`}>
      <UserHeaderChat
        search={search}
        setSearch={setSearch}
        isActiveSearch={isActiveSearch}
        setIsActiveSearch={setIsActiveSearch}
        chats={chats}
        index={3}
      />
      {chats
        .filter((it) =>
          it.title
            .toLowerCase()
            .replaceAll(' ', '')
            .includes(search.toLowerCase().replaceAll(' ', '')),
        )
        .map((item) => (
          <div
            className="user__chat-events-item"
            onClick={() => toPublishActivities(item._id)}
          >
            <img
              src={
                item.filesName.length
                  ? `${apiBaseURL}/uploads/publish_activities/${item.filesName[0]}`
                  : '/Images/postsBG.png'
              }
            />
            <div className="user__chat-events-item-text">
              <div className="user__chat-events-item-text-info">
                <h5>{item.title}</h5>
                <h6>
                  {moment(item.startDate).format('DD/MM/YY HH:mm').split(' ')[0]}
                  <b> {moment(item.startDate).format('DD/MM/YY HH:mm').split(' ')[1]}</b>
                </h6>
              </div>
              {/* {item.re ? <div className="user__chat-events-item-text-updates">
                            <h6>
                                {item.countComments} new {item.countComments>1 ? "updates" : "update"}
                            </h6>
                        </div> :  */}
              <div className="user__chat-events-item-text-updates">
                <h6 style={{ fontWeight: '600', color: 'rgba(195, 202, 217, 1)' }}>
                  no updates
                </h6>
              </div>
              {/* } */}
            </div>
          </div>
        ))}
    </div>
  )
}
