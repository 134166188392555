export const IconRound = ({ fill }: { fill: string }) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.9999 11.5999C9.0927 11.5999 11.5999 9.0927 11.5999 5.9999C11.5999 2.90711 9.0927 0.399902 5.9999 0.399902C2.90711 0.399902 0.399902 2.90711 0.399902 5.9999C0.399902 9.0927 2.90711 11.5999 5.9999 11.5999ZM5.9999 2.7999C4.23259 2.7999 2.7999 4.23259 2.7999 5.9999C2.7999 7.76721 4.23259 9.1999 5.9999 9.1999C7.76721 9.1999 9.1999 7.76721 9.1999 5.9999C9.1999 4.23259 7.76721 2.7999 5.9999 2.7999Z"
        fill={fill}
      />
    </svg>
  )
}
