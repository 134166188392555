import { ReactNode, useEffect } from 'react'

export const TempNotification = (props: {
  setIsOpen: ((o: boolean) => void) | any
  isOpen: boolean
  children: ReactNode
}) => {
  useEffect(() => {
    const timeId = setTimeout(() => {
      props.setIsOpen(false)
    }, 2000)

    return () => {
      clearTimeout(timeId)
    }
  }, [])
  if (!props.isOpen) {
    return null
  }
  return <div className="tempnotification">{props.children}</div>
}
