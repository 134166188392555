export const IconFamily = () => {
  return (
    <svg
      style={{ margin: 'auto 0' }}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9268 1.6C10.9268 0.712 11.6215 0 12.4878 0C13.3541 0 14.0488 0.712 14.0488 1.6C14.0488 2.488 13.3541 3.2 12.4878 3.2C11.6215 3.2 10.9268 2.488 10.9268 1.6ZM14.0488 16V11.2H16L14.0176 5.096C13.799 4.44 13.2059 4 12.5346 4H12.441C11.7698 4 11.1688 4.44 10.958 5.096L10.2868 7.16C11.1298 7.64 11.7073 8.544 11.7073 9.6V16H14.0488ZM8.19512 7.6C8.84293 7.6 9.36585 7.064 9.36585 6.4C9.36585 5.736 8.84293 5.2 8.19512 5.2C7.54732 5.2 7.02439 5.736 7.02439 6.4C7.02439 7.064 7.54732 7.6 8.19512 7.6ZM2.73171 3.2C3.59805 3.2 4.29268 2.488 4.29268 1.6C4.29268 0.712 3.59805 0 2.73171 0C1.86537 0 1.17073 0.712 1.17073 1.6C1.17073 2.488 1.86537 3.2 2.73171 3.2ZM4.29268 16V10.4H5.46341V5.6C5.46341 4.72 4.76098 4 3.90244 4H1.56098C0.702439 4 0 4.72 0 5.6V10.4H1.17073V16H4.29268ZM9.36585 16V12.8H10.1463V9.6C10.1463 8.944 9.61561 8.4 8.97561 8.4H7.41463C6.77463 8.4 6.2439 8.944 6.2439 9.6V12.8H7.02439V16H9.36585Z"
        fill="#0F1419"
      />
    </svg>
  )
}
