export enum ROLE {
  ADMIN = 'ADMIN',
  REGIONAL_ADMIN = 'REGIONAL_ADMIN',
  LOCAL_ADMIN = 'LOCAL_ADMIN',
  COORDINATORS = 'COORDINATORS',
  TECH_SUPPORT = 'TECH_SUPPORT',
  USER = 'USER',
  USER_EMAIL_UNCONFIRMED = 'USER_EMAIL_UNCONFIRMED',
  USER_MUST_SET_PASSWORD = 'USER_MUST_SET_PASSWORD',
  USER_MUST_SET_ADDRESS = 'USER_MUST_SET_ADDRESS',
  BLOCKED = 'BLOCKED',
}

export enum Countries {
  UA = 'Ukraine',
  FR = 'France',
  CA = 'Canada',
}

export enum PAGES {
  MAIN = 'newsfeed',
  SERVICES = 'services',
  ACTIVITIES = 'activities',
}

export enum ADVERTISEMENT {
  BANNER = 'banner',
  PUBLICATION = 'newsfeed',
  PROFILE = 'profile',
}

export enum PRIVACY {
  EVERYONE = 'Everyone',
  NEIBS = 'Neibs',
  ONLYME = 'Only me',
}

export enum ORIENTATION {
  HETERO = 'HETERO',
  GAY = 'GAY',
  LESBIAN = 'LESBIAN',
  QUEER = 'QUEER',
  ASEXUAL = 'ASEXUAL',
  PANSEXUAL = 'PANSEXUAL',
  DEMISEXUAL = 'DEMISEXUAL',
  BISEXUAL = 'BISEXUAL',
  DIDNOTDECIDE = 'DIDNOTDECIDE',
  OTHER = 'Other',
}
export enum CARD {
  VISA = 'Visa',
  MASTER = 'Master',
}
export enum SEX {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
}

export enum EDUCATION {
  HIGHSCHOOL = 'HIGHSCHOOL',
  TECHCPLLEGE = 'TECHCPLLEGE',
  PHDOTD = 'PHDOTD',
  STUDYCOLLEGE = 'STUDYCOLLEGE',
  PHD = 'PHD',
  BACHERLOR = 'BACHERLOR',
  MASTERDEGREE = 'MASTERDEGREE',
}

export enum FAMILY_STATUS {
  SINGLE = 'SINGLE',
  IN_RELATIONSHIP = 'IN_RELATIONSHIP',
  ENGAGED = 'ENGAGED',
  MARRIED = 'MARRIED',
  CIVIL_MARRIAGE = 'CIVIL_MARRIAGE',
  COMPLICATED = 'COMPLICATED',
  SEPARATE_THREADING = 'SEPARATE_THREADING',
  DIVORCED = 'DIVORCED',
  WIDOW = 'WIDOW',
}

export enum QUALITYENUM {
  INTERESTS = 'Interests',
  SKILLS = 'Skills',
  PROFESSION = 'Profession',
  NATIONALITY = 'Nationality',
}

export enum ACTIVITIE {
  ALL = 'All',
  SERVICES = 'Services',
  EVENTS = 'Events',
  POSTS = 'Posts',
}

export enum SOCKET_MESSENDER_EVENT {
  JOIN_ROOM = 'join_room',
  LEAVE_ROOM = 'leave_room',

  SEND_PRIVATE_MESSAGE = 'send_private_message',
  GET_PRIVATE_MESSAGE = 'get_private_message',
  DELETE_PRIVATE_MESSAGE = 'delete_private_message',
  FORWARD_PRIVATE_MESSAGE = 'forward_private_message',
  SEND_PRIVATE_VOICE_MESSAGE = 'send_private_voice_message',
  SEND_PRIVATE_MESSAGE_LIKE = 'send_private_message_like',
  DELETE_PRIVATE_MESSAGE_LIKE = 'delete_private_message_like',
  EDIT_PRIVATE_MESSAGE = 'edit_private_message',
  REPLY_PRIVATE_MESSAGE = 'reply_private_message',

  NOTIFICATION = 'notification',

  NEW_CREATE_CHAT = 'new_create_chat',
  UPDATE_LIST_CHAT = 'update_list_chat',

  AUTH_SOCKET = 'auth_socket',
}

export enum SERVICES_EVENT {
  LIST = 'list',
  SUB_LIST = 'sub_list',
  FAVOR_LIST = 'favor_list',

  ADD_SERVICES = 'add',
  EDIT_SERVICES = 'edit',
  EDIT_SUB_SERVICES = 'sub_edit',
}

export enum PHOTO_ADD_METHOD {
  CAMERA_ROLL = 'CAMERA_ROLL',
  TAKE_NOW = 'TAKE_NOW',
}

export enum METHOD_FORGET_PASSWORD {
  PHONE = 'phone',
  EMAIL = 'email',
}

export enum SOCKET_NOTIFICATION_EVENT {
  JOIN_ROOM_NOTIFICATION = 'join_room_notification',
  LEAVE_ROOM_NOTIFICATION = 'leave_room_notification',

  NOTIFICATION = 'notification',
}

export enum NOTIFICATION_EVENT {
  NOTIFICATION_SERVICE = 'service',
  NOTIFICATION_ACTIVITIES = 'activities',
  NOTIFICATION_MESSAGE = 'message',
  NOTIFICATION_NEWS = 'news',
}

export enum AUTH_AGREE_TYPES {
  POLICY = 'policy',
  TERMS = 'terms',
}

export enum NOTIFICATION_POST {
  POST = 'post',
  COMMENT = 'comment',
}

export enum USER_LIST_APP {
  ALL = 'all',
  LAST = 'last',
}
