import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ServiceHttp } from '../../../http/service-http'
import {
  CategoriesItemInterface,
  SubCategoriesItemInterface,
} from '../../../redux/categories'
import { allSubCategories } from '../../../redux/categories'
import { SERVICES_EVENT } from '../../../types/enum'
import { PublishServiceItemInterface } from '../../../types/types'
import { apiBaseURL } from '../../route/indexPathByRole'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { InputSearch } from '../../ui/InputSearch'
import { UserHeaderUserChatList } from '../header/UserHeaderChatList'

export const UserCategories = ({ event }: { event: SERVICES_EVENT }) => {
  const { categories, subCategories } = useAppSelector((state) => state.categories)
  const navigate = useNavigate()
  const [searsh, setSearch] = useState('')
  const [categorieId, setCategorieId] = useState('')
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const [publishService, setPublishService] = useState<PublishServiceItemInterface[]>([])
  useEffect(() => {
    ServiceHttp.getTenPublishService().then((s) => setPublishService(s))
  }, [])

  useEffect(() => {
    if (event === SERVICES_EVENT.SUB_LIST) {
      const id = searchParams.get('id')
      setCategorieId(id as string)
      dispatch(allSubCategories({ id: id as string }))
    }
  }, [event])

  const toNextLink = ({ id }: { id: string }) => {
    if (event === SERVICES_EVENT.LIST) {
      navigate(`/user/service/user-sub-categories?id=${id}`)
    } else if (event === SERVICES_EVENT.SUB_LIST) {
      const categorieName = categories.find((item) => item._id === categorieId)?.name
      navigate(`/user/service/user-publish-service-list?id=${id}`)
    }
  }
  const calculateServices = (category: CategoriesItemInterface) => {
    let count = 0
    publishService.find((item) => {
      if (
        item.categoryId._id === category._id ||
        item.subCategoryId._id === category._id
      ) {
        count++
      }
    })
    return count
  }
  const calculateSubServices = (category: SubCategoriesItemInterface) => {
    let count = 0
    publishService.find((item) => {
      if (
        item.categoryId._id === category._id ||
        item.subCategoryId._id === category._id
      ) {
        count++
      }
    })
    return count
  }

  return (
    <>
      <UserHeaderUserChatList
        headerTitle={
          event === SERVICES_EVENT.LIST ? 'Choose Category' : 'Choose Subcategory'
        }
      />
      <div className="user__newsfeed-search">
        <InputSearch
          placeholder={
            <>
              Search<span> NeightborHarbor</span>
            </>
          }
          value={searsh}
          changeValue={setSearch}
        />
      </div>
      <div className="user__category">
        {(event === SERVICES_EVENT.LIST ? categories : subCategories)
          .filter((item) => item.name.includes(searsh))
          .map((item) => (
            <div
              className="user__services-category-item-body"
              onClick={() =>
                toNextLink({
                  id: item._id,
                })
              }
            >
              <div className="user__services-category-item">
                <div className="user__services-category-item-img">
                  <img src={`${apiBaseURL}/uploads/categories/${item.fileName}`} alt="" />
                </div>
                <div className="user__services-category-item-text">
                  <b>{item.name}</b> <span>{calculateServices(item)}</span>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  )
}
