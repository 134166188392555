import { ReactNode } from 'react'

export const Blur = ({
  children,
  setBlur,
}: {
  children: ReactNode
  setBlur: (s: boolean) => void
}) => {
  return (
    <div
      className="blur"
      onClick={(e) => {
        {
          setBlur(false)
        }
      }}
    >
      {children}
    </div>
  )
}
