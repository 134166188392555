import dayjs from 'dayjs'
import moment from 'moment'
import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import $api from '../../../http'
import { ServiceHttp } from '../../../http/service-http'
import { PostUserInterface } from '../../../types/types'
import { indexPathByRole, apiBaseURL } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconBoxComment, IconMicrophone } from '../../svg/IconActivitiesModal'
import { IconPicker } from '../../svg/IconFavor'
import { success } from '../../ui/LoadSuccess'
import { Modal } from '../../ui/Modal'
import UiDateTimePicker from '../../ui/UiDataTimePicker'
import { ObjectDate } from '../users/UserListModule'
import { PublishPostHttp } from '../../../http/publish-post-http'

export const PostsModal = ({
  isOpen,
  setIsOpen,
  item,
}: {
  isOpen: boolean
  setIsOpen: (s: boolean) => void
  item: PostUserInterface
}) => {
  const [isOpenPeriod, setIsOpenPeriod] = useState(false)

  const { role } = useAppSelector((state) => state.user)
  const navigate = useNavigate()
  const location = useLocation()
  const [period, setPeriod] = useState<dayjs.Dayjs>(dayjs(new Date()))
  const blockUser = async (userId: string, period: ObjectDate) => {
    await $api.post('user/block-user', { _id: userId, period: period }).then(() => {
      success()
    })
  }
  const handlerBlockUser = () => {
    if (period) blockUser(item.userId._id, moment(period.toDate()).toObject())
  }
  const deletePost = async () => {
    if (item?._id) {
      await PublishPostHttp.deletePost({ postId: item?._id })
      window.location.reload()
    }
    setIsOpen(false)
    if (location.pathname === `${indexPathByRole(role)}/comments`) {
      navigate(-1)
    }
  }

  useEffect(() => {
    if (period !== dayjs(new Date()) && isOpenPeriod) {
      handlerBlockUser()
    }
  }, [period])
  return (
    <div
      className={`activities__favor-modal ${isOpen ? 'activities__favor-modal--open' : ''}`}
    >
      <Modal className="" setIsOpen={setIsOpen}>
        <div className="activities__favor-modal-body">
          <div
            onClick={(e) => {
              e.stopPropagation()
              setIsOpen(false)
            }}
            className="activities__favor-modal-linebody"
          >
            <button className="activities__favor-modal-line" />
          </div>
          <div className="services__favor-item-row1">
            <img
              src={item.filesName[0] || '/Images/postsBG.png'}
              alt=""
              className="services__favor-item-row1-img"
            />
            <div>
              <h5 className="services__favor-item-row1-title">{item.title}</h5>
              <div className="services__favor-item-row1-footer">
                {item?.userId?.avatarFileName ? (
                  <img
                    className="services__favor-item-row1-userimg"
                    src={`${apiBaseURL}/uploads/avatar/${item?.userId?.avatarFileName}`}
                  />
                ) : (
                  <div
                    style={{
                      backgroundColor: 'rgba(41, 52, 65, 1)',
                      borderRadius: '100%',
                      width: '100%',
                      height: '100%',
                      color: '#fff',
                      textAlign: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <span style={{ margin: 'auto', fontSize: '14px' }}>
                      {item?.userId?.fullName.slice(0, 2)}
                    </span>
                  </div>
                )}
                <h5>{item.userId.fullName}</h5>
              </div>
            </div>
          </div>
          <button
            className="activities__favor-modal-row2"
            onClick={(e) => {
              e.stopPropagation()
              setIsOpen(false)
              success()
            }}
          >
            <IconPicker />

            <p>
              {' '}
              Report <b>{item.userId.fullName}</b>
            </p>
          </button>
          <button
            className="activities__favor-modal-row2"
            onClick={() => setIsOpenPeriod(true)}
          >
            <IconPicker />

            <p>
              {' '}
              Block <b>{item.userId.fullName}</b>
            </p>
          </button>
          {isOpenPeriod && (
            <UiDateTimePicker startDate={period} setStartDate={setPeriod} />
          )}
          <button
            className="activities__favor-modal-row2 activities__favor-modal-row3"
            onClick={() =>
              navigate(`${indexPathByRole(role)}/comments?postId=${item._id}`)
            }
          >
            <IconBoxComment />

            <p>Comment</p>
          </button>
          <button
            className="activities__favor-modal-row2 activities__favor-modal-row3"
            onClick={deletePost}
          >
            <IconMicrophone />

            <p>Delete</p>
          </button>
        </div>
      </Modal>
    </div>
  )
}
