import { useEffect, useRef, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { useNavigate } from 'react-router-dom'
import {
  EDUCATION,
  FAMILY_STATUS,
  ORIENTATION,
  QUALITYENUM,
  SEX,
} from '../../../types/enum'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { IconProfileInfoPen } from '../../svg/IconProfileInfo'
import {
  Avatar,
  Upload,
  Form,
  Spin,
  Button,
  Input,
  DatePicker,
  AutoComplete,
  Radio,
  Space,
  Select,
  notification,
} from 'antd'
import { getInitials } from '../../../utils/displayName'
import useUploadImages from '../../../utils/hooks/useUploadImages'
import { useDev, useAttemptListener } from 'dev-masters-react-kit'
import FormSectionDivider from '../../ui/new-components/FormSectionDivider'
import { MdOutlineArrowForwardIos } from 'react-icons/md'
import GenderIcon from './GenderIcon'
import { MdOutlineAttachment } from 'react-icons/md'
// import {type EventArgs} from 'rc-field-form/lib/interface'
//TODO: move it to i18n config
import UploaderImagePreview, { ImagePreviewMethods } from '../../ui/UploaderImagePreview'
import { updateAvatar } from '../../../redux/userNew'
import { UploadChangeParam, UploadFile } from 'antd/es/upload'
import ImgCrop from 'antd-img-crop'
import { getNationalityOptions } from '../../../utils/localization/i18n'
import { EditUserProfileDTO } from '../../../types/server-communication/user'
import { attemptUpdateUserProfile } from '../../../redux/userNew'
import {
  AGE_LIMIT,
  ABOUT_ME_MAX_LENGTH,
  CERTIFICATES_LIMIT,
} from '../../../utils/appConstants'
import { useTranslation } from 'react-i18next'
import useAddressSearch from '../../../utils/hooks/useAddressSearch'
import { store } from '../../../store'

const nationalityOptions = getNationalityOptions()

const { TextArea } = Input

//TODO: change to edit profile method DTO
export interface EditProfileFormValues
  extends Omit<EditUserProfileDTO, 'dateBirth' | 'certificates'> {
  dateBirth: dayjs.Dayjs
  certificates: UploadFile<any>[]
}

export const ProfileInfoEdit = () => {
  const { avatarFileName, email } = useAppSelector((state) => state.userNew)
  const { t } = useTranslation('profile')
  const {
    isLoading: isAddressLoading,
    options,
    handleSearch,
    handleSearchCity,
  } = useAddressSearch()
  const isLoading = useAttemptListener({
    store,
    attempt: attemptUpdateUserProfile,
    onFulfilled: () => {
      notification.success({ message: 'Your profile has been successfully updated' })
      navigate(-1)
    },
  })
  const {
    fullName,
    aboutMe,
    dateBirth,
    cityBirth,
    education,
    studySchool,
    sex,
    orientation,
    familyStatus,
    nationality,
    certificates,
  } = useAppSelector((state) => state.userNew)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [editProfileForm] = Form.useForm<EditProfileFormValues>()
  const { uploadProps: uploadAvatarProps, isUploading: isAvatarUploading } =
    useUploadImages({
      destination: `${process.env.REACT_APP_API_BASE_URL}/api/user/upload-update-avatar`,
      onUploaded: (info) => dispatch(updateAvatar(info.file.response.filename)),
    })
  const {
    uploadProps: uploadCertificatesProps /* isUploading: isCertificatesUploading */,
  } = useUploadImages({
    //TODO: change to tmp folder?
    destination: `${process.env.REACT_APP_API_BASE_URL}/api/user/upload-certificate`,
  })
  const UploaderImagePreviewRef = useRef<ImagePreviewMethods>(null)

  function handleSubmitForm(values: EditProfileFormValues) {
    const getCertificateName = (file: UploadFile) => {
      if (file.response && file.status === 'done') return file.response.filename
      // if (!file.response && file.status === 'done') return file.name
      // else return file.response?.filename
    }
    const _certificates: string[] = values.certificates?.map((file) =>
      getCertificateName(file),
    )
    const valuesDto: EditUserProfileDTO = {
      ...values,
      dateBirth: values.dateBirth?.toISOString(),
      certificates: _certificates,
    }
    dispatch(attemptUpdateUserProfile(valuesDto))
  }

  function getDefaultValues() {
    const _dateBirth = dateBirth && dayjs(dateBirth)
    const _certificates: UploadFile[] =
      certificates?.map((filename) => ({
        uid: filename,
        name: filename,
        status: 'done',
        url: `${process.env.REACT_APP_UPLOADED_BASE_URL}/certificates/${filename}`,
      })) || []
    return {
      fullName,
      aboutMe,
      dateBirth: _dateBirth,
      cityBirth,
      education,
      studySchool,
      sex,
      orientation,
      familyStatus,
      nationality,
      certificates: _certificates,
    }
  }

  useEffect(() => {
    const defaultValues = getDefaultValues()
    editProfileForm.setFieldsValue(defaultValues)
  }, [
    fullName,
    aboutMe,
    dateBirth,
    cityBirth,
    education,
    studySchool,
    sex,
    orientation,
    familyStatus,
    nationality,
    certificates,
  ])

  const { options: cityOptions, filterOption } = useDev().useFakeAutocomplete([
    'Tokyo',
    'New York City',
    'London',
    'Paris',
    'Shanghai',
    'Los Angeles',
    'Beijing',
    'Moscow',
    'São Paulo',
    'Mexico City',
  ])

  const { options: educationOptions } = useDev().useFakeAutocomplete([
    'Massachusetts Institute of Technology (MIT)',
    'Stanford University',
    'Harvard University',
  ])

  const normFile = (e: UploadChangeParam<UploadFile<any>>) => {
    return e?.fileList
  }

  return (
    <div className="profileinfo__edit-container">
      <div className="profileinfo__avatar-block">
        <Avatar
          size={150}
          icon={fullName && getInitials(fullName)}
          src={
            isAvatarUploading ? (
              <Spin />
            ) : (
              `${process.env.REACT_APP_UPLOADED_BASE_URL}/avatar/${avatarFileName}`
            )
          }
        />
        <div className="profileinfo__edit-pen">
          <ImgCrop rotationSlider showGrid cropShape="round">
            <Upload {...uploadAvatarProps}>
              <IconProfileInfoPen />
            </Upload>
          </ImgCrop>
        </div>
      </div>
      <h5 className="profileinfo__avatar-title">{fullName}</h5>
      <h6 className="profileinfo__edit-subtitle">{email}</h6>
      <Form
        form={editProfileForm}
        name="basic"
        layout="vertical"
        initialValues={() => getDefaultValues()}
        onFinish={handleSubmitForm}
      >
        <FormSectionDivider title="Biography" />
        <Form.Item name="fullName">
          <Input placeholder="Full Name" />
        </Form.Item>
        <Form.Item name="aboutMe">
          <TextArea
            maxLength={ABOUT_ME_MAX_LENGTH}
            placeholder="Be concise, authentic, and feel free to let your personality shine through"
            showCount
            autoSize={{ minRows: 3, maxRows: 8 }}
          />
        </Form.Item>

        <FormSectionDivider title="Date and place of birth" />
        <Form.Item name="dateBirth">
          <DatePicker
            className="form-date-picker"
            placeholder="Day of birth"
            format="DD/MM/YYYY"
            allowClear={false}
            showNow={false}
            maxDate={dayjs().subtract(AGE_LIMIT, 'years')}
            inputReadOnly={true}
            // TODO: locale={datePickerLocale}
          />
        </Form.Item>
        <Form.Item name="cityBirth">
          <AutoComplete
            onSearch={handleSearchCity}
            options={options}
            onSelect={(option) => console.log('option:', option)}
            notFoundContent={isLoading ? 'Loading...' : null}
            suffixIcon={<MdOutlineArrowForwardIos />}
            allowClear
            placeholder="City"
          />
        </Form.Item>

        <FormSectionDivider title="Education" />
        <Form.Item name="studySchool">
          {/* TODO:  https://gitlab.com/neighbour-harbor/front/-/issues/3 */}
          <AutoComplete
            placeholder={'Educational institution'}
            options={educationOptions}
            filterOption={filterOption}
            suffixIcon={<MdOutlineArrowForwardIos />}
            allowClear
          />
        </Form.Item>
        <Form.Item name="education">
          <Radio.Group buttonStyle="solid" optionType="button">
            <Space wrap>
              {Object.entries(EDUCATION).map(([key, value]) => (
                <Radio key={key} value={key}>
                  {t(value)}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>

        <FormSectionDivider title="Gender and orientation" />
        <Form.Item name="sex">
          <Radio.Group
            buttonStyle="solid"
            optionType="button"
            className="form-radio-button-image"
          >
            <Space wrap>
              {Object.entries(SEX).map(([key, value]) => (
                <Radio key={key} value={key}>
                  <GenderIcon sex={value} />
                  {t(value)}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="orientation">
          <Radio.Group buttonStyle="solid" optionType="button">
            <Space wrap>
              {Object.entries(ORIENTATION).map(([key, value]) => (
                <Radio key={key} value={key}>
                  {t(value)}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>

        <FormSectionDivider title="Family status" />
        <Form.Item name="familyStatus">
          <Select
            options={Object.entries(FAMILY_STATUS).map(([key, value]) => ({
              label: t(value),
              value: key,
            }))}
            placeholder="Select your family status"
          />
        </Form.Item>

        <FormSectionDivider title="Nationality" />
        <Form.Item name="nationality">
          <Select
            showSearch
            options={nationalityOptions}
            optionFilterProp="label"
            placeholder="Select your nationality"
          />
          {/* <AutoComplete
            placeholder={'Your nationality'}
            options={nationalityOptions}
            filterOption={filterOption}
            suffixIcon={<MdOutlineArrowForwardIos />}
            allowClear
          /> */}
        </Form.Item>

        <FormSectionDivider title="Certificate" />
        <Form.Item
          name="certificates"
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <Upload
            {...uploadCertificatesProps}
            showUploadList={true}
            listType="picture-card"
            maxCount={CERTIFICATES_LIMIT}
            accept="image/jpeg, image/png, image/jpg"
            multiple
            defaultFileList={getDefaultValues()?.certificates}
            onPreview={(file) => UploaderImagePreviewRef.current?.handleFilePreview(file)}
            // beforeUpload={() => {
            //   return false
            // }}
          >
            <button style={{ border: 0, background: 'none' }} type="button">
              <MdOutlineAttachment size={30} />
              <div style={{ marginTop: 8, fontSize: '0.75em' }}>
                Add up to {CERTIFICATES_LIMIT} photos
              </div>
            </button>
          </Upload>
        </Form.Item>
        <UploaderImagePreview ref={UploaderImagePreviewRef} />
        {/* 
          TODO: [before merge]
            - send form data to server
            - save received data in redux 
            - fix Form Initial state (get from redux)
        */}
        <Button type="primary" htmlType="submit" block disabled={isLoading}>
          Update Profile
        </Button>
      </Form>
    </div>
  )
}
