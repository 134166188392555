import { Empty, notification } from 'antd'
import { FaDev } from 'react-icons/fa'

const message = 'This feature is still under development'
export function underDevelopmentNotification() {
  notification.warning({ message: message })
}

export function UnderDevelopmentComponent() {
  return (
    <Empty
      description={message}
      image={<FaDev size={50} />}
      style={{ backgroundColor: 'lightgray', borderRadius: '1em', padding: '0.25em' }}
      imageStyle={{ height: '50%' }}
    />
  )
}
