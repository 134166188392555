import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../utils/hooks'
import { Button, Flex, Form, Input } from 'antd'
import {
  EditUserProfileDTO,
  PROFILE_STEP,
} from '../../../../types/server-communication/user'
import { indexPathByRole } from '../../../route/indexPathByRole'
import { attemptUpdateAboutMe } from '../../../../redux/userNew'
import { useAttemptListener } from 'dev-masters-react-kit'
import { stepDefinitions } from '../../../../utils/hooks/useNextSetUpProfilePath'
import { ABOUT_ME_MAX_LENGTH } from '../../../../utils/appConstants'
import { StandaloneScreenLayout } from '../../../ui/Layout/StandaloneScreenLayout'
import { store } from '../../../../store'

const { TextArea } = Input
interface EditFormValues extends Pick<EditUserProfileDTO, 'aboutMe'> {}

export const ProfileAbout = () => {
  const [editAboutMeForm] = Form.useForm<EditFormValues>()
  const { aboutMe, role } = useAppSelector((state) => state.userNew)
  const aboutMeCurrent = Form.useWatch('aboutMe', editAboutMeForm)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const navigateNext = () =>
    navigate('/profile/' + stepDefinitions[PROFILE_STEP.PROFESSION].path)
  const isLoading = useAttemptListener({
    store,
    attempt: attemptUpdateAboutMe,
    onFulfilled: navigateNext,
  })
  function handleSubmitForm(values: EditFormValues) {
    dispatch(attemptUpdateAboutMe(values))
  }

  return (
    <StandaloneScreenLayout
      title="About me"
      subTitle="This is your opportunity to create a compelling introduction"
      onPrimaryButtonClicked={editAboutMeForm.submit}
      isDisabled={isLoading || !aboutMeCurrent || aboutMeCurrent?.length === 0}
    >
      <Form
        form={editAboutMeForm}
        name="basic"
        layout="vertical"
        initialValues={{ aboutMe: aboutMe }}
        onFinish={handleSubmitForm}
        // className="profile__method-body"
        // style={{ flex: 1 }}
      >
        <Form.Item name="aboutMe">
          <TextArea
            maxLength={ABOUT_ME_MAX_LENGTH}
            placeholder="Be concise, authentic, and feel free to let your personality shine through"
            showCount
            autoSize={{ minRows: 7 }}
          />
        </Form.Item>
      </Form>
    </StandaloneScreenLayout>
  )
}
