import { useNavigate } from 'react-router-dom'
import { logout } from '../../../redux/auth'
import { useAppDispatch } from '../../../utils/hooks'
import { StandaloneScreenLayout } from '../../ui/Layout/StandaloneScreenLayout'

export const AuthRegistrationConfirm = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  return (
    <StandaloneScreenLayout
      backgroundImage="url(/Images/Megapone.png)"
      title={'Confirm Your Email'}
      subTitle={'Please check your email for the next step to sign up'}
      secondButtonTitle="Back to Login In"
      nextButtonTitle="Confirm Your Email"
      onBackButtonClicked={() => dispatch(logout())}
      onSecondButtonClicked={() => dispatch(logout())}
      onPrimaryButtonClicked={() => navigate('/auth/confirm-code')}
    />
  )
}
