import React from 'react'

export const IconArrowRight = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="15"
      viewBox="0 0 17 15"
      fill="none"
    >
      <path
        d="M6.55671e-08 7.72559C9.87611e-08 7.34589 0.282154 7.0321 0.64823 6.98243L0.75 6.97559L15.75 6.97559C16.1642 6.97559 16.5 7.31137 16.5 7.72559C16.5 8.10528 16.2178 8.41908 15.8518 8.46874L15.75 8.47559L0.75 8.47559C0.335786 8.47559 2.93554e-08 8.1398 6.55671e-08 7.72559Z"
        fill="#0F1419"
      />
      <path
        d="M9.17101 2.23271C8.87749 1.94045 8.87646 1.46558 9.16873 1.17206C9.43442 0.905218 9.85103 0.880116 10.1451 1.09734L10.2294 1.16977L16.2794 7.19377C16.547 7.46026 16.5714 7.87837 16.3524 8.17245L16.2794 8.25667L10.2294 14.2817C9.93593 14.574 9.46105 14.573 9.16877 14.2795C8.90305 14.0127 8.87971 13.5959 9.09817 13.3028L9.17096 13.2188L14.687 7.72494L9.17101 2.23271Z"
        fill="#0F1419"
      />
    </svg>
  )
}
