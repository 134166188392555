import { useNavigate } from 'react-router-dom'
import { ORIENTATION, SEX } from '../../../../types/enum'
import { useAppDispatch, useAppSelector } from '../../../../utils/hooks'
import { Button, Flex, Form, Radio, Space } from 'antd'
import {
  EditUserProfileDTO,
  PROFILE_STEP,
} from '../../../../types/server-communication/user'
import { useAttemptListener } from 'dev-masters-react-kit'
import { stepDefinitions } from '../../../../utils/hooks/useNextSetUpProfilePath'
import { attemptUpdateSexIdentity } from '../../../../redux/userNew'
import { indexPathByRole } from '../../../route/indexPathByRole'
import GenderIcon from '../../profile-info/GenderIcon'
import { useTranslation } from 'react-i18next'
import { StandaloneScreenLayout } from '../../../ui/Layout/StandaloneScreenLayout'
import { store } from '../../../../store'

interface FormValues extends Pick<EditUserProfileDTO, 'sex' | 'orientation'> {}

export const ProfileSex = () => {
  const [sexForm] = Form.useForm<FormValues>()
  const { sex, orientation, role } = useAppSelector((state) => state.userNew)
  const currentOrientation = Form.useWatch('orientation', sexForm)
  const currentSex = Form.useWatch('sex', sexForm)
  const { t } = useTranslation('profile')

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const isLoading = useAttemptListener({
    store,
    attempt: attemptUpdateSexIdentity,
    onFulfilled: () => {
      navigate('/profile/' + stepDefinitions[PROFILE_STEP.EDUCATION].path)
    },
  })
  function handleSubmitForm(values: FormValues) {
    dispatch(attemptUpdateSexIdentity(values))
  }

  return (
    <StandaloneScreenLayout
      title="I am..."
      subTitle="You are valued and respected for who you are"
      onPrimaryButtonClicked={sexForm.submit}
      isDisabled={isLoading || !currentOrientation || !currentSex}
    >
      <Form
        form={sexForm}
        name="basic"
        layout="vertical"
        initialValues={{ sex: sex, orientation: orientation }}
        onFinish={handleSubmitForm}
      >
        <Form.Item name="sex">
          <Radio.Group
            buttonStyle="solid"
            optionType="button"
            className="form-radio-button-image"
          >
            <Space wrap>
              {Object.entries(SEX).map(([key, value]) => (
                <Radio key={key} value={key}>
                  <GenderIcon sex={value} />
                  {t(value)}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="orientation">
          <Radio.Group buttonStyle="solid" optionType="button">
            <Space wrap>
              {Object.entries(ORIENTATION).map(([key, value]) => (
                <Radio key={key} value={key}>
                  {t(value)}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
      </Form>
    </StandaloneScreenLayout>
  )
}
