import { Circle, MapContainer, Polygon, TileLayer } from 'react-leaflet'
import {
  PostUserInterface,
  PublishActivitiesOneItemInterface,
  PublishServiceItemInterface,
} from '../../../types/types'
import { MapPostMarker } from '../../ui/MapPostMarker'
import { indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { useLocation, useNavigate } from 'react-router-dom'

export const UserServiceMap = ({
  item,
  type,
}: {
  item:
    | PublishServiceItemInterface
    | PublishActivitiesOneItemInterface
    | PostUserInterface
  type: string
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { role } = useAppSelector((state) => state.user)
  return (
    <div className="publish__location">
      {item && (
        <div>
          <div
            className="publish__location-map"
            onClick={() => {
              if (!location.pathname.includes('preview')) {
                navigate(`${indexPathByRole(role)}/explore/map`, {
                  state: { center: item.coordinates, item, isOpen: true },
                })
              }
            }}
          >
            {item.coordinates && (
              <MapContainer
                dragging={false}
                zoomControl={false}
                center={item.coordinates}
                minZoom={8}
                maxZoom={8}
                zoom={8}
                scrollWheelZoom={true}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                  url="https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png"
                  subdomains="abcd"
                />
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                  url="https://{s}.basemaps.cartocdn.com/dark_only_labels/{z}/{x}/{y}{r}.png"
                  subdomains="abcd"
                />
                {item.radiusSize > 0 && (
                  <Circle center={item.coordinates} radius={item.radiusSize * 1000} />
                )}
                {item.polygonCoords && <Polygon positions={item.polygonCoords} />}
                <MapPostMarker type={type} item={item} />
                {/* {type==="service" && 
                            <MapServiceMarker item={item} />
                        } */}
              </MapContainer>
            )}
          </div>
          <p className="publish__location-map-address">{item.addressLocation}</p>
        </div>
      )}
    </div>
  )
}
