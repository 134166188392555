import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { PublishPostHttp } from '../../../http/publish-post-http'
import { setValueProfileReducer } from '../../../redux/profile'
import { profileTextInfo } from '../../../redux/profile'
import { PRIVACY } from '../../../types/enum'
import { CoordinatsInterface, PublishPostInterface } from '../../../types/types'
import { apiBaseURL, indexPathByRole } from '../../route/indexPathByRole'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { success } from '../../ui/LoadSuccess'
import { PublishAddLocation } from './PublishAddLocation'
import { PublicationMainComponent } from './PublishMainComponent'

export const PublishPost = ({
  currentPrivacy,
  allowList,
}: {
  currentPrivacy: PRIVACY
  allowList: string[]
}) => {
  const location = useLocation()

  const props: {
    postId: string
    files: string[]
    img: (File | string)[]
    text: string
    title: string
    coordinates: CoordinatsInterface
  } = location.state

  const navigate = useNavigate()
  const { _id, role } = useAppSelector((state) => state.user)
  const profile = useAppSelector((state) => state.profile)
  const dispatch = useAppDispatch()

  const [addressLocation, setAddressLocation] = useState(
    `${profile.country}, ${profile.city}, ${profile.street}, ${profile.houseNumber}`,
  )
  const [files, setFiles] = useState<(File | string)[]>(
    props?.files?.map((item) => `${apiBaseURL}/uploads/publish_post/${item}`) ||
      props?.img ||
      [],
  )
  const [text, setText] = useState(props?.text || '')
  const [title, setTitle] = useState(props?.title || '')
  const [polygonCoords, setPolygonCoords] = useState<
    L.LatLng[] | L.LatLng[][] | L.LatLng[][][] | null
  >(location?.state?.polygonCoords || null)
  const [radiusSize, setRadiusSize] = useState<number>(location?.state?.radiusSize || 0)
  const [coordinates, setCoordinates] = useState<CoordinatsInterface>(
    location?.state?.coords,
  )

  const validate = !Boolean(text && title && addressLocation)
  const handlerPublish = async () => {
    try {
      const formCatData = new FormData()

      const remainedFiles = (
        files.filter((item) => typeof item === 'string') as string[]
      ).map((item) => item.replace(`${apiBaseURL}/uploads/publish_post/`, ''))
      const deletedFiles =
        props?.files?.filter((item) => remainedFiles.includes(item)) || []

      let payload: {
        text: string
        title: string
        userId: string
        userIdentityId: string
        coordinates: CoordinatsInterface
        privacyPost: PRIVACY
        addressLocation: string
        deletedFiles?: string[]
        postId?: string
        allowList: string[]
        radiusSize: number
        polygonCoords: L.LatLng[] | L.LatLng[][] | L.LatLng[][][] | null
        createdPostDate: Date
      } = {
        text,
        title,
        coordinates,
        userId: _id,
        userIdentityId: profile.userIdentityId,
        privacyPost: currentPrivacy,
        addressLocation,
        deletedFiles,
        postId: props?.postId || '',
        allowList,
        radiusSize,
        polygonCoords,
        createdPostDate: new Date(),
      }

      formCatData.append('payload', JSON.stringify(payload))

      for (let index = 0; index < files.length; index++) {
        formCatData.append('files', files[index])
      }

      const resPubPost: PublishPostInterface = await PublishPostHttp.addPost(formCatData)

      if (!profile.isAddedPost) {
        const res = await profileTextInfo({
          isAddedPost: true,
          _id,
        })
        dispatch(setValueProfileReducer(res))
      }

      success()
      navigate(`${indexPathByRole(role)}/posts`)
    } catch (error) {
      throw error
    }
  }
  return (
    <div className="user">
      <PublicationMainComponent
        files={files}
        setFiles={setFiles}
        text={text}
        setText={setText}
        title={title}
        setTitle={setTitle}
        placeholderTitle="Title Name"
        placeholderText="what’s in your mind ?"
      />
      <PublishAddLocation
        setCoordinates={setCoordinates}
        addressLocation={addressLocation}
        setAddressLocation={setAddressLocation}
        polygonCoords={polygonCoords}
        radiusSize={radiusSize}
        coords={coordinates}
        prevStatePost={{ title, text, img: files }}
        type="post"
      />
      <button
        className={`publish__publish user--footer--button ${
          validate && 'services__add-button--disabled'
        }`}
        onClick={handlerPublish}
        disabled={validate}
      >
        Publish
      </button>
    </div>
  )
}
