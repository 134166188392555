import { useNavigate } from 'react-router-dom'
import { indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'

export const ProfileButtonSetupLater = (props: {
  text?: string
  clickFunc?: () => void
}) => {
  const { role } = useAppSelector((state) => state.user)
  const navigate = useNavigate()
  return (
    <button
      className="profile__method-btlater profile__method-btlater--inherit"
      onClick={() => {
        if (props.clickFunc) {
          props.clickFunc()
        } else {
          navigate(`${indexPathByRole(role)}`)
        }
      }}
    >
      {/* <Link to={indexPathByRole(role)}>{props.text ? props.text : "Setup later"}</Link> */}
      <span>{props.text ? props.text : 'Setup later'}</span>
    </button>
  )
}
