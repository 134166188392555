import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import $api from '../../../http'
import { AdvertisementHttp } from '../../../http/advertisement'
import { IdentityHttp } from '../../../http/identity-http'
import { UserHttp } from '../../../http/user-http'
import { UserIdentityInterface } from '../../../redux/profile'
import { OptionsItemType, OptionsType } from '../../../types/types'
import { indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { IconLeftChevrons } from '../../svg/IconChevrons'
import { InputSearch } from '../../ui/InputSearch'
import { success } from '../../ui/LoadSuccess'

export const AdvertisementInterests = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [search, setSearch] = useState('')
  const [popularOptions, setPopularOptions] = useState<OptionsType>([])
  const [popularOptionsNormal, setPopularOptionsNormal] = useState<OptionsType>([])
  const [interests, setInterests] = useState<OptionsType>([])
  const { role } = useAppSelector((state) => state.user)
  const validate = !Boolean(interests.length)
  useEffect(() => {
    $api
      .get(`identity/interests-popular`)
      .then((res) => setPopularOptions(res.data))
      .catch((e) => console.log(e + 'error'))
  }, [])
  useEffect(() => {
    let array: OptionsType = []
    setPopularOptionsNormal([])
    if (search.length) {
      popularOptions.map((item) => {
        if (item.title.toLowerCase().includes(search.toLowerCase())) {
          array.push(item)
        }
      })
    } else {
      array = [...popularOptions]
    }
    setPopularOptionsNormal(array)
  }, [search])

  const [count, setCount] = useState<UserIdentityInterface[]>([])
  const [countNormal, setCountNormal] = useState<UserIdentityInterface[]>([])
  useEffect(() => {
    UserHttp.getAllUsers().then((res) => {
      res.map(async (item) => {
        IdentityHttp.getUserIdentity({ _id: item._id }).then((i) => {
          setCount((s) => [...s, i])
        })
      })
    })
  }, [])

  useEffect(() => {
    if (count) {
      count.map((item) => {
        if (item.interests.length) {
          item.interests.map((it) => {
            if (
              interests.find((i) => i.title === it.title) &&
              !countNormal.includes(item)
            ) {
              setCountNormal((s) => [...s, item])
            }
          })
        }
      })
    }
  }, [count, interests])

  const addToInterests = (item: OptionsItemType) => {
    if (interests.find((interest) => item == interest)) {
      let array: OptionsType = [...interests]
      let index = array.indexOf(item)
      if (index !== -1) {
        array.splice(index, 1)
        setInterests(array)
      }
    } else {
      setInterests((s) => [...s, item])
    }
  }

  const addInterests = async () => {
    try {
      await AdvertisementHttp.updateInterests({ interests, adId: location.state.adId })
      success()
      navigate(`${indexPathByRole(role)}/advertisement/add-community-selection`, {
        state: { adId: location.state.adId },
      })
    } catch (error) {
      console.log('server error' + error)
    }
  }

  return (
    <div className="advertisement__add">
      <div className="advertisement__add-header">
        <div className="advertisement__add-header-btn" onClick={() => navigate(-1)}>
          <IconLeftChevrons fill="rgba(21, 16, 77, 1)" />
          <span>Interests</span>
        </div>
      </div>
      <div className="advertisement__add-interests">
        <div className="advertisement__add-interests-audience">
          <h5>
            {countNormal.length
              ? Math.floor(countNormal.length - 15 / 100)
              : countNormal.length}
            -{countNormal.length + 1}
          </h5>
          <h6>Estimated audience size</h6>
        </div>
        <div className="advertisement__add-interests-all">
          <InputSearch
            value={search}
            changeValue={setSearch}
            placeholder={'Search Interests'}
          />
          <h6>
            It's advisable to include a multitude of interests that resonate with a wider
            audience
          </h6>
          <div className="profile__identity-list">
            <div className="profile__identity-list-body">
              {popularOptionsNormal.slice(0, 12).map((item, index) => (
                <div
                  className={`profile__identity-list-item 
                              ${
                                interests.find(
                                  (interest) => interest.title === item.title,
                                )
                                  ? 'profile__identity-list-item-active'
                                  : ''
                              }  
                            `}
                  key={index}
                  onClick={() => addToInterests(item)}
                >
                  <div className="profile__identity-list-item-text">{item.title}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <button
        className={`advertisement__add-main-btn ${
          validate && 'advertisement__add-main-btn-disabled'
        }`}
        onClick={addInterests}
        disabled={validate}
      >
        Continue
      </button>
    </div>
  )
}
