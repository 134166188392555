import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import $api from '../../../http'
import { useAppSelector } from '../../../utils/hooks'
import { IconLeftChevrons } from '../../svg/IconChevrons'
import { InputSearch } from '../../ui/InputSearch'
import { success } from '../../ui/LoadSuccess'
import { UserHeader } from '../header/UserHeader'

export const UserServiceReport = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [comment, setComment] = useState('')
  const validate = !Boolean(comment.replaceAll(' ', '').length)
  const { _id } = useAppSelector((state) => state.user)
  const reportService = async () => {
    try {
      $api.post('reports/report-service', {
        comment,
        position: 'service',
        serviceId: location.state._id,
        userId: _id,
      })
      success()
      navigate(-1)
    } catch (error) {
      console.log('Server error')
    }
  }
  return (
    <>
      <UserHeader>
        <div className="user__header-messages explore__header">
          <button
            onClick={() => navigate(-1)}
            className="user__header-chatuser user__header-messages-exit"
          >
            <IconLeftChevrons />
          </button>
          <h5 className="user__header-title" style={{ margin: '40px 0' }}>
            Report Service
          </h5>
        </div>
      </UserHeader>
      <div style={{ margin: '20px 0' }}>
        <InputSearch
          search={false}
          value={comment}
          changeValue={setComment}
          placeholder={
            <>
              Report <b>service</b>
            </>
          }
        />
        <button
          className={`publish__publish user--footer--button group__name-content-next ${
            validate && 'services__add-button--disabled'
          }`}
          style={{ marginBottom: '50px' }}
          onClick={reportService}
          disabled={validate}
        >
          Report
        </button>
      </div>
    </>
  )
}
