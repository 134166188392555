import { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import $api from '../../../http'
import { IdentityHttp } from '../../../http/identity-http'
import { PRIVACY, ROLE } from '../../../types/enum'
import { ChatType, OptionsType } from '../../../types/types'
import { apiBaseURL, indexPathByRole } from '../../route/indexPathByRole'
import { useAppSelector } from '../../../utils/hooks'
import { success } from '../../ui/LoadSuccess'
import { Loader } from '../../ui/Loader'
import { UserHeaderChat } from '../header/UserHeaderChat'

export const MessegesGroups = () => {
  const navigate = useNavigate()
  const [search, setSearch] = useState('')
  const [isActiveSearch, setIsActiveSearch] = useState(false)
  const [userChats, setUserChats] = useState<ChatType[]>([])
  const { _id, role } = useAppSelector((state) => state.user)
  const { interests } = useAppSelector((state) => state.profile)
  const [load, setLoad] = useState(false)
  const { chats } = useAppSelector((state) => state.chats)

  useEffect(() => {
    setLoad(true)
    const list = chats
      .filter((it) => it.participants.length > 2)
      .map((item) => ({
        ...item,
        // participants: item.participants.filter(
        //     (p) => p.userId._id !== _id
        // ),
      }))
    setUserChats(list)
    setLoad(false)
  }, [])
  const openChat = async (item: ChatType) => {
    await $api
      .post('messenger/update-chat', {
        participants: [
          ...item.participants.map((it) => {
            return { userId: it.userId._id }
          }),
          {
            userId: _id,
          },
        ],
        chatId: item._id,
      })
      .then(() => {
        navigate(
          `${indexPathByRole(role)}/messeges/chat?chatId=${JSON.stringify(item.chatId)}`,
          {
            state: {
              participants: [
                ...item.participants.map((it) => {
                  return { userId: it.userId._id }
                }),
                {
                  userId: _id,
                },
              ],
              chat: item,
            },
          },
        )
      })
  }
  const addRequest = async (item: ChatType) => {
    await $api.post('messenger/add-request', {
      myId: item.groupOwner,
      senderId: _id,
      chatId: item._id,
    })
    success()
    if (userChats) {
      let array: ChatType[] = [...userChats]
      let index = array.indexOf(item)
      if (index !== -1) {
        array.splice(index, 1)
        setUserChats(array)
      }
    }
  }
  const joinGroup = async (item: ChatType) => {
    // if(item.privacy===PRIVACY.EVERYONE){
    //     openChat(item);
    // }else{
    //     addRequest(item);
    // }
    openChat(item)
  }

  return !load ? (
    <div className={`user__chat ${isActiveSearch && 'user__chat--active'}`}>
      {role !== ROLE.ADMIN && (
        <UserHeaderChat
          search={search}
          setSearch={setSearch}
          isActiveSearch={isActiveSearch}
          setIsActiveSearch={setIsActiveSearch}
          chats={userChats}
          index={1}
        />
      )}
      <div className="user__chat-groups">
        {userChats
          .filter((it) =>
            it.name
              .toLowerCase()
              .replaceAll(' ', '')
              .includes(search.toLowerCase().replaceAll(' ', '')),
          )
          .filter((it) => it.privacy === PRIVACY.NEIBS)
          .map((item) => (
            <>
              <div
                onClick={() => joinGroup(item)}
                className="user__chat-groups-item"
                style={{ margin: '15px 0', borderRadius: '12px' }}
              >
                <div className="user__chat-groups-item-info">
                  <div className="user__chat-groups-item-info-g">
                    <img
                      src={
                        item?.groupImage
                          ? `${apiBaseURL}/uploads/messenger/${item?.groupImage}`
                          : '/Images/postsBG.png'
                      }
                      alt=""
                    />
                    <div className="user__chat-groups-item-info-text">
                      <h5 style={{ fontSize: '16px' }}>
                        {item.name.length > 25
                          ? item.name.slice(0, 18) + '...'
                          : item.name}
                      </h5>
                      <h6 style={{ fontSize: '14px' }}>
                        {item.participants.length} neibs
                      </h6>
                    </div>
                  </div>
                  {!item.participants.find((it) => it.userId._id === _id) && (
                    <span
                      onClick={() => joinGroup(item)}
                      className="user__chat-groups-item-info-join"
                    >
                      Join
                    </span>
                  )}
                </div>
                {item.interests && (
                  <div className="user__chat-groups-item-interests profile__identity-list-body">
                    {item?.interests?.slice(0, 7).map((item, index) => (
                      <div
                        className={`user__chat-groups-item-interests-item 
                                    ${
                                      interests.find((it) => it.title === item.title)
                                        ? 'user__chat-groups-item-interests-item-active'
                                        : ''
                                    }  
                                `}
                        key={index}
                      >
                        <div className="profile__identity-list-item-text">
                          {item.title}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </>
          ))}
      </div>
    </div>
  ) : (
    <Loader />
  )
}
