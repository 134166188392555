import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import $api from '../../../http'
import { logout } from '../../../redux/auth'
import { ROLE } from '../../../types/enum'
import { apiBaseURL } from '../../route/indexPathByRole'
import { profileInfoHelp, profileInfoSettings } from '../../../utils/constant'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { toOneKind } from '../../../utils/titles'
import { IconRightChevrons } from '../../svg/IconChevrons'
import { IconDelete } from '../../svg/IconDelete'
import { IconMap } from '../../svg/IconMap'
import { IconProfileInfoFlag, IconProfileInfoPen } from '../../svg/IconProfileInfo'
import { success } from '../../ui/LoadSuccess'
import { Modal } from '../../ui/Modal'
import { ProfileButtonSetupLater } from '../profile-standalone/ProfileButtonSetupLater'
import { IconCertificate } from '../../svg/IconCertificate'
import { IconProfessions } from '../../svg/IconProfessions'
import { Avatar, notification } from 'antd'
import { getInitials } from '../../../utils/displayName'
import { underDevelopmentNotification } from '../../../utils/dev'

export const ProfileSettings = () => {
  const {
    fullName,
    role,
    avatarFileName,
    email,
    id: userId,
  } = useAppSelector((state) => state.userNew)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [deleteModal, setdeleteModal] = useState<boolean>(false)

  const handlerLogout = () => {
    dispatch(logout())
  }

  const deleteAccount = async () => {
    try {
      await $api.post('user/delete-user', { _id: userId })
      success()
      dispatch(logout())
    } catch (error) {
      console.log('Server error' + error)
    }
  }

  return (
    <div className="profileinfo__settings">
      <div className="profileinfo__settings-main-edit">
        <Avatar
          size={52}
          icon={fullName && getInitials(fullName)}
          src={`${process.env.REACT_APP_UPLOADED_BASE_URL}/avatar/${avatarFileName}`}
        />
        <div>
          <b className="profileinfo__settings-main-edit-title">{fullName}</b>
          <p className="profileinfo__settings-main-edit-subtitle">{email}</p>
        </div>
        <button>
          <Link to={'/profileinfo/edit'}>
            <IconProfileInfoPen />
          </Link>
        </button>
      </div>

      <div className="profileinfo__settings-main-setting ">
        <Link to={'/profile/interest-zone/settings'}>
          <div className="profileinfo__settings-main-setting-item">
            <button>
              <IconMap />
            </button>
            <div>
              <h6 className="profileinfo__settings-main-edit-title profileinfo__settings-main-setting-item-title">
                {'Interest Zone'}
              </h6>
              <p className="profileinfo__settings-main-edit-subtitle">
                {'Manage your interest zone'}
              </p>
            </div>
            <button className="profileinfo__settings-main-setting-item-svgstroke">
              <IconRightChevrons />
            </button>
          </div>
        </Link>
        <div onClick={() => navigate('/profile/certificates', { state: { url: -1 } })}>
          <div className="profileinfo__settings-main-setting-item">
            <div
              style={{
                filter:
                  'brightness(0) saturate(100%) invert(88%) sepia(4%) saturate(680%) hue-rotate(183deg) brightness(92%) contrast(91%)',
              }}
            >
              <IconCertificate />
            </div>
            <div>
              <h6 className="profileinfo__settings-main-edit-title profileinfo__settings-main-setting-item-title">
                {'Certificates'}
              </h6>
              <p className="profileinfo__settings-main-edit-subtitle">
                {'Add your certificates'}
              </p>
            </div>
            <button className="profileinfo__settings-main-setting-item-svgstroke">
              <IconRightChevrons />
            </button>
          </div>
        </div>
        <div onClick={() => navigate('/profile/profession', { state: { url: -1 } })}>
          <div className="profileinfo__settings-main-setting-item">
            <div
              style={{
                filter:
                  'brightness(0) saturate(100%) invert(88%) sepia(4%) saturate(680%) hue-rotate(183deg) brightness(92%) contrast(91%)',
              }}
            >
              <IconProfessions />
            </div>
            <div>
              <h6 className="profileinfo__settings-main-edit-title profileinfo__settings-main-setting-item-title">
                {'Professions'}
              </h6>
              <p className="profileinfo__settings-main-edit-subtitle">
                {'Manage your professions'}
              </p>
            </div>
            <button className="profileinfo__settings-main-setting-item-svgstroke">
              <IconRightChevrons />
            </button>
          </div>
        </div>
        {profileInfoSettings.map((item) => (
          <Link
            to={
              toOneKind(item.name) === 'privacy'
                ? `/profile/${toOneKind(item.name)}`
                : `/profileinfo/${toOneKind(item.name)}`
            }
          >
            <div className="profileinfo__settings-main-setting-item" key={item.name}>
              <button>{item.label()}</button>
              <div>
                <h6 className="profileinfo__settings-main-edit-title profileinfo__settings-main-setting-item-title">
                  {item.name}
                </h6>
                <p className="profileinfo__settings-main-edit-subtitle">{item.subName}</p>
              </div>
              <button className="profileinfo__settings-main-setting-item-svgstroke">
                <IconRightChevrons />
              </button>
            </div>
          </Link>
        ))}
        {/* <>
                        <div
                            className="profileinfo__settings-main-setting-item"
                            onClick={()=>navigate(`${indexPathByRole(role)}/advertisement`)}
                        >
                            <button><IconPrise /></button>
                            <div>
                                <h6 className="profileinfo__settings-main-edit-title profileinfo__settings-main-setting-item-title">
                                    Advertisement
                                </h6>
                                <p className="profileinfo__settings-main-edit-subtitle">
                                    Manage your advertisement
                                </p>
                            </div>
                            <button className="profileinfo__settings-main-setting-item-svgstroke">
                                <IconRightChevrons />
                            </button>
                        </div>
                </> */}
      </div>
      <div className="profileinfo__settings-main-setting profileinfo__settings-main-help">
        {role !== ROLE.ADMIN &&
          profileInfoHelp.map((item) => (
            <Link to={`/profileinfo/${toOneKind(item.name)}`}>
              <div className="profileinfo__settings-main-setting-item" key={item.name}>
                {item.label()}
                <div>
                  <h6 className="profileinfo__settings-main-edit-title profileinfo__settings-main-setting-item-title">
                    {item.name}
                  </h6>
                </div>
                <button className="profileinfo__settings-main-setting-item-svgstroke">
                  <IconRightChevrons />
                </button>
              </div>
            </Link>
          ))}
        <div className="profileinfo__settings-main-setting-item" onClick={handlerLogout}>
          <IconProfileInfoFlag />
          <div>
            <h6 className="profileinfo__settings-main-edit-title profileinfo__settings-main-setting-item-title">
              Log out
            </h6>
          </div>
          <button className="profileinfo__settings-main-setting-item-svgstroke">
            <IconRightChevrons />
          </button>
        </div>
        <>
          <div
            className="profileinfo__settings-main-setting-item"
            onClick={() => {
              underDevelopmentNotification()
              setdeleteModal(true)
            }}
          >
            <button>
              <IconDelete />
            </button>
            <div>
              <h6 className="profileinfo__settings-main-edit-title profileinfo__settings-main-setting-item-title">
                Delete
              </h6>
            </div>
            <button className="profileinfo__settings-main-setting-item-svgstroke">
              <IconRightChevrons />
            </button>
          </div>
        </>
      </div>
      <div className="profileinfo__settings-main-setting"></div>
      {deleteModal && (
        <Modal setIsOpen={setdeleteModal} className={'delete__modal'}>
          <div className="delete__modal-container">
            <Avatar
              size={52}
              icon={fullName && getInitials(fullName)}
              src={
                avatarFileName
                  ? `${process.env.REACT_APP_UPLOADED_BASE_URL}/avatar/${avatarFileName}`
                  : '/Images/Profile.jpg'
              }
            />
            <h5>
              Delete account <b>{fullName}</b> without the possibility of recovery?
            </h5>
            <button className={`profile__method-btlater`} onClick={deleteAccount}>
              Continue
            </button>
          </div>
          <ProfileButtonSetupLater
            clickFunc={() => setdeleteModal(false)}
            text="Cancel"
          />
        </Modal>
      )}
    </div>
  )
}
