import { Navigate, PathRouteProps, Route, Routes } from 'react-router-dom'
import CurrentLocationView from '../location/SelectZone/CurrentLocationView'
import { ProfileAbout } from './screens/ProfileAbout'
import { ProfileBirth } from './screens/ProfileBirth'
import { ProfileCertificates } from './screens/ProfileCertificates'
import { ProfileEducation } from './screens/ProfileEducation'
import { ProfileFamilyStatus } from './screens/ProfileFamilyStatus'
import { ProfileInterestZone } from './ProfileInterestZone'
import { ProfilePicture } from './screens/ProfilePicture'
import { ProfilePrivacy } from './screens/ProfilePrivacy'
import { ProfileSex } from './screens/ProfileSex'
import { ProfileStayTouch } from './screens/ProfileStayTouch'
import { ProfileWelcomeNeibs } from './screens/ProfileWelcomeNeibs'
import useNextSetUpProfilePath, {
  stepDefinitions,
} from '../../../utils/hooks/useNextSetUpProfilePath'
import { PROFILE_STEP } from '../../../types/server-communication/user'
import { ProfileNationality } from './screens/ProfileNationality'
import { ProfileProfessions } from './screens/ProfileProfessions'
import { ProfileSkills } from './screens/ProfileSkills'
import { ProfileInterests } from './screens/ProfileInterests'
import { Button, Flex, Layout } from 'antd'
import { Header } from 'antd/es/layout/layout'
import { IoChevronBackOutline } from 'react-icons/io5'

const profileStandaloneInfoRoutes: PathRouteProps[] = [
  {
    path: 'stay-touch',
    element: <ProfileStayTouch />,
  },
  {
    path: 'welcome-neibs',
    element: <ProfileWelcomeNeibs />,
  },
]

const profileStandaloneEditRoutes: PathRouteProps[] = [
  {
    path: stepDefinitions[PROFILE_STEP.AVATAR].path,
    element: <ProfilePicture />,
  },
  {
    path: stepDefinitions[PROFILE_STEP.FAMILY_STATUS].path,
    element: <ProfileFamilyStatus />,
  },
  {
    path: stepDefinitions[PROFILE_STEP.EDUCATION].path,
    element: <ProfileEducation />,
  },
  {
    path: stepDefinitions[PROFILE_STEP.SEX].path,
    element: <ProfileSex />,
  },

  {
    path: stepDefinitions[PROFILE_STEP.NATIONALITY].path,
    element: <ProfileNationality />,
  },

  {
    path: stepDefinitions[PROFILE_STEP.DATE_PLACE_OF_BIRTH].path,
    element: <ProfileBirth />,
  },

  {
    path: stepDefinitions[PROFILE_STEP.CERTIFICATES].path, //TODO:
    element: <ProfileCertificates />,
  },

  {
    path: stepDefinitions[PROFILE_STEP.INTERESTS].path,
    element: <ProfileInterests />,
  },

  {
    path: stepDefinitions[PROFILE_STEP.SKILLS].path,
    element: <ProfileSkills />,
  },
  {
    path: stepDefinitions[PROFILE_STEP.PROFESSION].path,
    element: <ProfileProfessions />,
  },
  {
    path: stepDefinitions[PROFILE_STEP.ABOUT_ME].path,
    element: <ProfileAbout />,
  },
  {
    path: stepDefinitions[PROFILE_STEP.PRIVACY].path,
    element: <ProfilePrivacy />,
  },
  {
    path: `${stepDefinitions[PROFILE_STEP.INTEREST_ZONE].path}/*`,
    element: (
      <Routes>
        <Route index element={<ProfileInterestZone />} />
        <Route
          path="setup"
          element={
            <CurrentLocationView navigateToConfirmPath={`/profile/interest-zone`} />
          }
        />
        <Route
          path="settings"
          element={
            <CurrentLocationView navigateToConfirmPath={`/profileinfo/settings`} />
          }
        />
      </Routes>
    ),
    // title: 'Interest Zone',
    // subTitle: 'Express and explore the things that truly captivate you',
  },
]

const profileStandaloneRoutes: PathRouteProps[] = [
  {
    path: '*',
    element: <NavigateToUnfilledStep />,
  },
  ...profileStandaloneEditRoutes,
  ...profileStandaloneInfoRoutes,
]

export const ProfileStandaloneRouter = () => {
  return (
    <Routes>
      {profileStandaloneRoutes.map((route) => (
        <Route key={route.path} path={route.path} element={route.element} />
      ))}
    </Routes>
  )
}

function NavigateToUnfilledStep() {
  const path = useNextSetUpProfilePath()
  return <Navigate to={`${path}`} />
}
